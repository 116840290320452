import { Dialog, DialogActions, DialogContent, Grid, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useFetch } from '@react-redux-fetch/hooks';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Button from '../../../../components/Button';
import DelayedLoader from '../../../../components/DelayedLoader';
import Placeholder from '../../../../components/Placeholder';
import { TitleFull } from '../../../../config/api/types';
import { selectTitle } from '../../admin/title/selectors';
import { getTitleRequest } from '../../detail/api';
import { getDate, getFrontCover, getProductForm } from '../../domain';

type Props = {
  titleUrl: string;
  replacedByGtin13?: string;
  onAccept: (title: TitleFull) => void;
  onDecline: () => void;
  onClose: () => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    borderTop: 'solid 1px black',
    margin: '8px -8px',
  },
  image: {
    '& img': { maxWidth: 120, maxHeight: 180 },
  },
  placeholder: {
    height: '100%',
    background: theme.palette.background.appbar,
    display: 'flex',
    alignItems: 'center',
    maxHeight: 176,
  },
  info: {
    flexGrow: 1,
  },
}));

const OrderReplacedByModal = ({
  titleUrl,
  replacedByGtin13,
  onAccept,
  onDecline,
  onClose,
}: Props) => {
  const { t } = useTranslation();
  const [, fetchTitle] = useFetch(getTitleRequest);
  const title = useSelector(selectTitle);
  const frontCover = title ? getFrontCover(title) : undefined;
  const classes = useStyles();

  useEffect(() => {
    if (titleUrl && replacedByGtin13) {
      fetchTitle(titleUrl, replacedByGtin13);
    }
  }, [fetchTitle, titleUrl, replacedByGtin13]);

  return (
    <Dialog open={true} onClose={onClose}>
      <DialogContent>
        <Typography>
          Voor deze titel is onderstaande vervanger gekend. Wil je deze in de plaats bestellen?
        </Typography>
        <DelayedLoader data={title && title.gtin13 === replacedByGtin13}>
          {title && (
            <Grid container spacing={2} className={classes.title}>
              <Grid item className={classes.image}>
                {frontCover ? (
                  <img
                    src={frontCover.thumbnail || frontCover.thumbnailSmall}
                    alt={`Cover ${title.title}`}
                  />
                ) : (
                  <div className={classes.placeholder}>
                    <Placeholder alt={`Cover ${title.title}`} />
                  </div>
                )}
              </Grid>
              <Grid item className={classes.info}>
                <Typography variant="h2">{title.title}</Typography>
                <Typography variant="body2">
                  {getProductForm(title)} - {title.gtin13}
                  {Boolean(title.languages && title.languages[0]) &&
                    ` - ${title.languages![0].label}`}
                  {title.isbnFirstPublished && ` - ${getDate(title).format()}`}
                </Typography>
              </Grid>
            </Grid>
          )}
        </DelayedLoader>
      </DialogContent>

      <DialogActions>
        <Button
          onClick={() => onAccept(title!)}
          color="primary"
          disabled={!title || title.gtin13 !== replacedByGtin13}
        >
          {t('filter_option_yes')}
        </Button>
        <Button onClick={onDecline} color="primary">
          {t('filter_option_no')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default OrderReplacedByModal;
