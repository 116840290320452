import React from 'react';
import { RouteComponentProps } from 'react-router';
import ConstrainedPageContent from '../../../components/ConstrainedPageContent';
import OrdersHistoryProcessedLines from '../components/ordersHistoryLines/OrdersHistoryProcessedLines';
import { OrdersProcessedHeader } from './OrdersProcessedPage';

type Props = RouteComponentProps;

const OrdersLinesProcessedPage = (props: Props) => {
  return (
    <ConstrainedPageContent center={false}>
      <OrdersProcessedHeader {...props} />
      <OrdersHistoryProcessedLines />
    </ConstrainedPageContent>
  );
};

export default OrdersLinesProcessedPage;
