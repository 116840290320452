import { Grid } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PromiseState } from 'react-redux-fetch';
import FileIcon from '../../../../../components/FileIcon';
import Table from '../../../../../components/Table/index';
import { CellType, DataType } from '../../../../../components/Table/types';
import { TitleMediaFiles } from '../../../../../config/api/types';
import getFilename from '../../../../../helpers/getFilename';
import {
  getLink,
  retrieveQueryParameter,
  updateQueryParameters,
} from '../../../../../helpers/hateoas';
import StatusCell from './StatusCell';
import StatusFilter from './StatusFilter';
import TypeFilter from './TypeFilter';

type Props = {
  mediaFilesFetch: PromiseState<TitleMediaFiles>;
  dispatchMediaFilesGet: (url?: string) => void;
  hideFilters?: boolean;
};

const MediaFileTable = ({ mediaFilesFetch, dispatchMediaFilesGet, hideFilters }: Props) => {
  const { t } = useTranslation();
  const pagedMediaFiles = mediaFilesFetch.value;
  const url = getLink(pagedMediaFiles, 'self') || '';

  const headers = [
    { label: '', width: '2rem' },
    { label: t('table_headers_filename'), width: '12rem' },
    { label: t('table_headers_through'), width: '4rem' },
    { label: t('table_headers_uploaded'), width: '10rem' },
    { label: t('table_headers_result'), width: '16rem' },
    { label: t('table_headers_alert') },
  ];

  if (!pagedMediaFiles) {
    return null;
  }

  const data: DataType = {
    resource: 'mediaFiles',
    rows: pagedMediaFiles._embedded.items.map((titleMediaFile) => {
      const key = `${titleMediaFile.path}${titleMediaFile.uploadedAt}`;
      return {
        id: key,
        cells: [
          {
            type: CellType.Action,
            data: <FileIcon fileName={titleMediaFile.path} />,
          },
          {
            type: CellType.Text,
            data: getFilename(titleMediaFile.path),
          },
          {
            type: CellType.Text,
            data: titleMediaFile.source,
          },
          {
            type: CellType.Text,
            data: titleMediaFile.uploadedAt,
          },
          {
            type: CellType.Text,
            data: <StatusCell status={titleMediaFile.status} />,
          },
          {
            type: CellType.Array,
            data:
              titleMediaFile._embedded &&
              titleMediaFile._embedded.importResult &&
              titleMediaFile._embedded.importResult.map((result) => result.message),
          },
        ],
      };
    }),
  };

  return (
    <>
      {!hideFilters && (
        <Grid container>
          <Grid item xs={12} style={{ textAlign: 'right' }}>
            <Grid container spacing={2} justifyContent="flex-end">
              <Grid item>
                <TypeFilter
                  onChange={(event) => {
                    dispatchMediaFilesGet(
                      updateQueryParameters(url, {
                        type: (event.target.value as string) || '',
                        page: 1,
                      })
                    );
                  }}
                  defaultValue={retrieveQueryParameter(url, 'type')}
                />
              </Grid>
              <Grid item>
                <StatusFilter
                  onChange={(event) => {
                    dispatchMediaFilesGet(
                      updateQueryParameters(url, {
                        status: (event.target.value as string) || '',
                        page: 1,
                      })
                    );
                  }}
                  defaultValue={retrieveQueryParameter(url, 'status')}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
      <Table
        data={data}
        headers={headers}
        limit={pagedMediaFiles.limit}
        page={pagedMediaFiles.page}
        pages={pagedMediaFiles.pages}
        total={pagedMediaFiles.total}
        dispatchFn={dispatchMediaFilesGet}
        pagedResource={pagedMediaFiles}
        loading={mediaFilesFetch.pending}
      />
    </>
  );
};

export default MediaFileTable;
