import { Theme, useScrollTrigger } from '@mui/material';
import MUIAppBar from '@mui/material/AppBar';
import { createStyles, makeStyles } from '@mui/styles';
import clx from 'classnames';
import React, { FC, ReactNode } from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      minHeight: 'auto',
      backgroundColor: theme.palette.background.appbar,
      zIndex: theme.zIndex.drawer + 1,
    },
  })
);

function ElevationScroll(props: { children: React.ReactElement<any> }) {
  const { children } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

type Props = {
  className?: string;
  children?: ReactNode;
};

const AppBar: FC<Props> = ({ children, className }) => {
  const classes = useStyles();

  return (
    <ElevationScroll>
      <MUIAppBar className={clx(classes.appBar, className)}>{children}</MUIAppBar>
    </ElevationScroll>
  );
};

export default AppBar;
