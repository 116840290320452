import { Box, Tooltip, Typography } from '@mui/material';
import { FormApi } from 'final-form';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DelayedLoader from '../../../../components/DelayedLoader';
import Table from '../../../../components/Table';
import { CellType, DataType } from '../../../../components/Table/types';
import TextLink from '../../../../components/TextLink';
import formatEuro from '../../../../helpers/formatEuro';
import getIdFromUrl from '../../../../helpers/getIdFromUrl';
import { elasticGetLink } from '../../../../helpers/hateoas';
import organisation from '../../../organisation';
import ElasticIsbnLink from '../../../title/components/ElasticIsbnLink';
import OrdersHistoryForm from '../ordersHistory/OrdersHistoryForm';
import { useOrderHistoryLinesFetch } from './useOrderHistoryLinesFetch';

const OrdersHistoryProcessedLinesForBookstore = () => {
  const { t } = useTranslation();
  const { orderBookstorePath, pagedOrderLines, orderLinesFetch, fetchOrderLines, cancelFetch } =
    useOrderHistoryLinesFetch({ processed: 1 }, 'ordersHistoryProcessedLinesForBookstore');

  const [isRefetching, setIsRefetching] = useState(false);

  const refetch = (url: string) => {
    setIsRefetching(true);
    cancelFetch();
    fetchOrderLines(url);
  };
  useEffect(() => {
    if (orderLinesFetch?.fulfilled && !orderLinesFetch?.pending) {
      setIsRefetching(false);
    }
  }, [orderLinesFetch]);

  const headers = [
    { id: 'date', label: t('table_headers_date'), width: '100px' },
    { id: 'order_ref', label: t('table_headers_library_order_reference') },
    { id: 'line_ref', label: t('table_headers_line_reference') },
    { id: 'library', label: t('table_headers_library'), width: '120px' },
    { id: 'department', label: t('table_headers_department'), width: '100px' },
    { id: 'isbn', label: t('table_headers_isbn'), width: '120px' },
    { id: 'title', label: t('table_headers_title'), width: '180px' },
    { id: 'num_pieces', label: t('table_headers_num_pieces'), width: '80px', align: 'center' },
    { id: 'price', label: t('table_headers_price'), width: '80px', align: 'right' },
    { id: 'price_total', label: t('table_headers_price_total'), width: '80px', align: 'right' },
    { id: 'status', label: t('table_headers_status'), width: '80px' },
  ];

  const data: DataType | null = pagedOrderLines
    ? {
        resource: 'orderlines',
        rows: pagedOrderLines?._embedded.items.map((orderLine) => {
          return {
            id: orderLine.id,
            cells: [
              {
                type: CellType.Text,
                data: moment(orderLine.orderedAt).format(`DD/MM/YYYY`),
              },
              {
                type: CellType.Text,
                data: (
                  <TextLink
                    underline
                    to={{
                      pathname: orderBookstorePath.replace(
                        ':id',
                        getIdFromUrl(elasticGetLink(orderLine, 'order'))
                      ),
                      state: { from: window.location.pathname },
                    }}
                  >
                    {orderLine.orderReference.length > 20 ? (
                      <Tooltip title={orderLine.orderReference}>
                        <Typography noWrap>{orderLine.orderReference}</Typography>
                      </Tooltip>
                    ) : (
                      orderLine.orderReference
                    )}
                  </TextLink>
                ),
              },
              {
                type: CellType.Text,
                data:
                  orderLine.reference?.length > 20 ? (
                    <Tooltip title={orderLine.orderReference}>
                      <Typography noWrap>{orderLine.orderReference}</Typography>
                    </Tooltip>
                  ) : (
                    orderLine.reference
                  ),
              },
              {
                type: CellType.Text,
                data: (
                  <Box width={120}>
                    <Tooltip placement="top-start" title={orderLine.libraryName} enterDelay={500}>
                      <Typography noWrap>{orderLine.libraryName}</Typography>
                    </Tooltip>
                  </Box>
                ),
              },
              {
                type: CellType.Text,
                data: (
                  <Box width={100}>
                    <Tooltip
                      placement="top-start"
                      title={orderLine.departmentName}
                      enterDelay={500}
                    >
                      <Typography noWrap>{orderLine.departmentName}</Typography>
                    </Tooltip>
                  </Box>
                ),
              },
              {
                type: CellType.Text,
                data: (
                  <ElasticIsbnLink
                    orderLine={{
                      ...orderLine,
                      titleId: getIdFromUrl(orderLine._links.title.href),
                    }}
                  />
                ),
              },
              {
                type: CellType.Text,
                data: (
                  <Box width={180}>
                    <Tooltip placement="top-start" title={orderLine.titleTitle} enterDelay={500}>
                      <Typography noWrap>{orderLine.titleTitle}</Typography>
                    </Tooltip>
                  </Box>
                ),
              },
              {
                type: CellType.Text,
                data: (
                  <Box textAlign="center">
                    <Typography>{orderLine.quantity}</Typography>
                  </Box>
                ),
              },
              {
                type: CellType.Text,
                data: (
                  <Box textAlign="right">
                    <Typography>{formatEuro(orderLine.unitPrice)}</Typography>
                  </Box>
                ),
              },
              {
                type: CellType.Text,
                data: (
                  <Box textAlign="right">
                    <Typography>{formatEuro(orderLine.total)}</Typography>
                  </Box>
                ),
              },
              {
                type: CellType.Text,
                data: (
                  <Typography>
                    {t('orderline_status', { context: orderLine.stateBookstore })}
                  </Typography>
                ),
              },
            ],
          };
        }),
      }
    : null;

  return (
    <DelayedLoader data={data}>
      {data != null && (
        <>
          <OrdersHistoryForm
            fetchOrders={refetch}
            url={pagedOrderLines?._links.self.href ?? ''}
            numResults={pagedOrderLines?.total}
            role="library"
            exportButtonLabel={t('orderlines_library_multiple_export')}
          >
            {({ form, values }: { form: FormApi; values: Record<string, string> }) => {
              return (
                <organisation.components.LibrarySelectField
                  onClearValue={form.submit}
                  libraryId={values.library_id}
                  change={form.change}
                />
              );
            }}
          </OrdersHistoryForm>
          <Table
            data={data}
            headers={headers}
            pagedResource={pagedOrderLines}
            loading={isRefetching}
            dispatchFn={refetch}
          />
        </>
      )}
    </DelayedLoader>
  );
};

export default OrdersHistoryProcessedLinesForBookstore;
