import { Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { Languages } from '../../../config/api/types';
import CollectionText from './CollectionText';

type Props = {
  title: string;
  subTitle?: string;
  collectionName?: string;
  collectionNumber?: string;
  productForm: string;
  imprint?: string;
  languages: Languages;
};

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    fontWeight: 500,
  },
  subTitle: {},
  productForm: {},
}));

const IsbnTitleOverview = ({
  title,
  subTitle,
  collectionName,
  collectionNumber,
  productForm,
  imprint,
  languages,
}: Props) => {
  const classes = useStyles();

  const collection = {
    number: collectionNumber,
    title: collectionName,
  };

  return (
    <>
      <Typography className={classes.title}>{title}</Typography>

      {subTitle && <Typography className={classes.subTitle}>{subTitle}</Typography>}

      {collectionName && <CollectionText collection={collection} />}

      <Typography className={classes.productForm}>
        {productForm}
        {languages[0] && ` | ${languages[0].label}`}
        {imprint && ` | ${imprint}`}
      </Typography>
    </>
  );
};

export default IsbnTitleOverview;
