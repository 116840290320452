import React, { ReactElement, ReactNode, useEffect } from 'react';
import Header from '../components/Header';
import { Redirect, RouteComponentProps } from 'react-router';
import { getOrganisationRequest } from '../../api';
import { Organisation } from '../../../../config/api/types';
import { useTranslation } from 'react-i18next';
import { apiDate } from '../../../../helpers/date';
import useApiRoute from '../../../../config/api/useApiRoute';
import { getTabsToShow } from '../helpers';
import { useFetch } from '@react-redux-fetch/hooks';
import { useSelector } from 'react-redux';
import { getOrganisationByRef } from '../../selectors';

type Params = { id: string };
type RenderProps = { organisation: Organisation };
type Props = RouteComponentProps<Params> & {
  children: ReactElement<any> | (({ organisation }: RenderProps) => ReactNode);
};

const OrganisationWrapper = ({ children, match }: Props) => {
  const { t } = useTranslation();
  const orgId = match.params.id;
  const organisationUrl = useApiRoute('organisations', orgId);
  const [, fetchOrganisation] = useFetch(getOrganisationRequest);
  const organisation = useSelector(getOrganisationByRef(organisationUrl || ''));

  useEffect(() => {
    if (organisationUrl) fetchOrganisation(organisationUrl);
  }, [organisationUrl, fetchOrganisation]);

  if (!organisationUrl) {
    return <Redirect to="/" />;
  }

  if (!organisation) return null;

  const companyName = organisation.name || '';
  const organisationId = organisation
    ? t('title_m4bId', {
        m4bId: organisation.meta4BooksId,
      })
    : '';

  const fixtureId = organisation?.fixtureId
    ? t('title_fixtureId', {
        fixtureId: organisation.fixtureId,
      })
    : '';

  const createdAndUpdatedDate = organisation
    ? t('title_created_updated', {
        created: apiDate(organisation.createdAt).toDay(),
        updated: apiDate(organisation.updatedAt).toDay(),
      })
    : '';

  return (
    <>
      <Header
        title={companyName}
        tabsToShow={getTabsToShow(organisation)}
        organisation={organisation}
        subHeader={
          <>
            <span style={{ display: 'inline-block' }}>{organisationId}</span>
            {' - '}
            {fixtureId && (
              <>
                <span style={{ display: 'inline-block' }}>{fixtureId}</span>
                {' - '}
              </>
            )}
            <span style={{ display: 'inline-block' }}>{createdAndUpdatedDate}</span>
          </>
        }
      />
      {typeof children === 'function' ? children({ organisation: organisation }) : children}
    </>
  );
};

export default OrganisationWrapper;
