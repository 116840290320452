import * as React from 'react';
import { useEffect, useState } from 'react';
import {
  retrieveQueryParameter,
  updateQueryParameters,
  getLink,
} from '../../../../helpers/hateoas';
import SearchField from '../../../../components/Form/SearchField';
import useFetch from '../../../../helpers/useFetch';
import { ApiConfig } from '../api';
import useApiRoute from '../../../../config/api/useApiRoute';
import { ROLE } from '../../../security/model';
import { Organisations } from '../../../../config/api/types';
import getIdFromUrl from '../../../../helpers/getIdFromUrl';

type Props = {
  placeholder: string;
  searchCallback: (ids: string[]) => void;
  keepFilters?: boolean;
};

const DistributorFilter = ({ placeholder, searchCallback, keepFilters }: Props) => {
  const organisationsPath = useApiRoute('organisations');
  const [initialRender, setInitialRender] = useState<boolean>(true);
  const [distributorsFetch, fetchDistributors] = useFetch<Organisations>(
    ApiConfig.getDistributors()
  );

  const previousUrl = getLink(distributorsFetch?.value, 'self');
  const searchTermFromUrl = previousUrl ? retrieveQueryParameter(previousUrl, 'name') || '' : '';
  const [searchTerm, setSearchTerm] = useState(keepFilters ? searchTermFromUrl : '');

  useEffect(() => {
    if (searchTerm === searchTermFromUrl && !initialRender) {
      return;
    }

    if (searchTerm && organisationsPath) {
      fetchDistributors(
        updateQueryParameters(organisationsPath, { name: searchTerm, role: ROLE.DISTRIBUTOR })
      );
    } else {
      searchCallback([]);
    }
    setInitialRender(false);
  }, [
    searchTermFromUrl,
    searchTerm,
    organisationsPath,
    fetchDistributors,
    searchCallback,
    initialRender,
  ]);

  useEffect(() => {
    if (
      distributorsFetch &&
      !distributorsFetch.pending &&
      searchTerm !== '' &&
      distributorsFetch.value
    ) {
      const items = distributorsFetch.value._embedded.items;
      searchCallback(
        items.length > 0 ? items.map((item) => getIdFromUrl(item._links.self.href)) : []
      );
    }
  }, [distributorsFetch, searchCallback, searchTerm]);

  if (!organisationsPath) {
    return null;
  }

  return (
    <SearchField
      placeholder={placeholder}
      searchCallback={(term: string) => {
        setSearchTerm(term);
        // dispatchFundsGet(updateQueryParameter(url, 'code', term.toUpperCase()));
      }}
      initialValue={searchTerm}
      disabled={distributorsFetch && distributorsFetch.pending}
      isClearable
    />
  );
};

export default DistributorFilter;
