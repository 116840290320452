import { MenuItem } from '@mui/material';
import { useFetch } from '@react-redux-fetch/hooks';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Field from '../../../../components/Form';
import { BillingType } from '../../../../config/api/types';
import { getBillingTypesRequest } from '../../api';
import { getBillingTypes } from '../../selectors';

type Props = {
  label?: string;
  loadingValue?: BillingType;
};

const BillingTypeSelect = ({ label, loadingValue }: Props) => {
  const [billingTypesRequest, fetchBillingTypes] = useFetch(getBillingTypesRequest);
  const billingTypes = useSelector(getBillingTypes);

  useEffect(() => {
    if (!billingTypes && !billingTypesRequest?.pending) {
      fetchBillingTypes();
    }
  }, [fetchBillingTypes, billingTypes, billingTypesRequest]);

  return (
    <Field type="select" name="billingType" label={label}>
      {billingTypes ? (
        billingTypes.map((type) => (
          <MenuItem key={type.code} value={type.code}>
            {type.label}
          </MenuItem>
        ))
      ) : loadingValue ? (
        <MenuItem key={loadingValue.code} value={loadingValue.code}>
          {loadingValue.label}
        </MenuItem>
      ) : null}
    </Field>
  );
};

export default BillingTypeSelect;
