import DeleteIcon from '@mui/icons-material/Delete';
import { Grid } from '@mui/material';
import { useFetch } from '@react-redux-fetch/hooks';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../../../components/Button';
import ConfirmationDialog from '../../../../../components/ConfirmationDialog';
import Table from '../../../../../components/Table';
import { CellType, DataType } from '../../../../../components/Table/types';
import { Bookstore } from '../../../../../config/api/models/organisation';
import { FavoriteBookstores, Maybe } from '../../../../../config/api/types';
import { getLink } from '../../../../../helpers/hateoas';
import { deleteFavoriteBookstoreRequest } from '../../../api';

type Props = {
  favoriteBookstores: Maybe<FavoriteBookstores>;
  favoritesLink: string;
};

const FavoriteBookstoreList = ({ favoriteBookstores, favoritesLink }: Props) => {
  const { t } = useTranslation();
  const [favoriteToDelete, setFavoriteToDelete] = useState<Bookstore | null>(null);
  const [, removeFavorite] = useFetch(deleteFavoriteBookstoreRequest);

  if (!favoriteBookstores) {
    return null;
  }

  const headers = [
    { id: 'name', label: t('table_headers_bookstore') },
    { id: 'actions', label: '', width: 30 },
  ];

  const data: DataType = {
    resource: 'favorites',
    rows: favoriteBookstores.map((bookstore) => ({
      id: getLink(bookstore, 'self') || '',
      cells: [
        {
          type: CellType.Text,
          data: bookstore._embedded.organisation.name,
        },
        {
          type: CellType.Action,
          data: (
            <Button
              variant="text"
              round
              secondary
              aria-label="delete"
              onClick={() => setFavoriteToDelete(bookstore)}
              size="small"
            >
              <DeleteIcon color="action" />
            </Button>
          ),
        },
      ],
    })),
  };

  return (
    <Grid item xs={6} sm={4} style={{ marginBottom: 16 }}>
      <Table
        data={data}
        headers={headers}
        hidePagination
        limit={1}
        page={1}
        total={favoriteBookstores.length}
        pages={1}
      />
      {favoriteToDelete && (
        <ConfirmationDialog
          onCancel={() => setFavoriteToDelete(null)}
          onConfirm={() => {
            const selfLink = favoritesLink
              ? `${favoritesLink}/${favoriteToDelete.bookstoreId}`
              : '';
            if (selfLink) {
              removeFavorite(selfLink);
            }
            setFavoriteToDelete(null);
          }}
          title={t('confirm_remove_favorite_bookstore_title', {
            name: favoriteToDelete._embedded.organisation.name,
          })}
          content={t('confirm_remove_favorite_bookstore_content')}
        />
      )}
    </Grid>
  );
};

export default FavoriteBookstoreList;
