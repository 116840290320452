import { createSelector } from 'reselect';
import { RootState } from '../../config/store/types';
import {
  Maybe,
  Prefixes,
  Prefix,
  BillingTypes,
  MasterPrefixes,
  IsbnApplications,
  IsbnApplication,
  ReservedIsbnNumbers,
  PrefixApplications,
  PrefixApplication,
} from '../../config/api/types';

export const getPrefixes = (state: RootState): Maybe<Prefixes> => state.data.prefixes;

type getPrefixType = (state: RootState) => Maybe<Prefix>;

export const getPrefixByValue = (prefix: string): getPrefixType =>
  createSelector(getPrefixes, (allPrefixes) => {
    if (!allPrefixes) {
      return null;
    }

    return allPrefixes._embedded.items.find((item) => item.prefix === prefix);
  });

export const getPrefix = (state: RootState): Maybe<Prefix> => state.data.prefix;

export const getMasterPrefixes = (state: RootState): Maybe<MasterPrefixes> =>
  state.data.masterPrefixes;

export const getBillingTypes = (state: RootState): Maybe<BillingTypes> => state.data.billingTypes;

export const getIsbnApplications = (state: RootState): Maybe<IsbnApplications> =>
  state.data.isbnApplications;

export const getIsbnApplication = (state: RootState): Maybe<IsbnApplication> =>
  state.data.isbnApplication;

export const getReservedIsbnNumbers = (state: RootState): Maybe<ReservedIsbnNumbers> =>
  state.data.reservedIsbnNumbers;

export const getPrefixApplications = (state: RootState): Maybe<PrefixApplications> =>
  state.data.prefixApplications;

export const getPrefixApplication = (state: RootState): Maybe<PrefixApplication> =>
  state.data.prefixApplication;
