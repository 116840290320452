import { FormControlLabel, Tooltip } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import getIdFromUrl from '../../../../../helpers/getIdFromUrl';
import { getLink } from '../../../../../helpers/hateoas';
import {
  isOwnDistributionRequired,
  isOwnPublicationRequired,
} from '../../../../organisation/domain';
import security from '../../../../security';
import { FacetCheckBox } from '../../components/Facet/FacetCheckBox';
import FacetWrapper from '../../components/Facet/FacetWrapper';

type PersonalFacets = {
  fundOrgId?: string;
  publisherOrgId?: string;
};

type Props = {
  filters: PersonalFacets;
  onFilter: (values: PersonalFacets) => void;
};

const PersonalFacet: FC<Props> = ({ onFilter, filters }) => {
  const { t } = useTranslation();
  const user = useSelector(security.selectors.getUser);
  const orgLink = getLink(user, 'organisation');
  const orgId = orgLink ? getIdFromUrl(orgLink) : '';
  const userRoles = useSelector(security.selectors.getUserRole) || [];
  const isPublisher = userRoles.includes('ROLE_PUBLISHER');
  const isDistributor = userRoles.includes('ROLE_DISTRIBUTOR');
  const isMember = user?._embedded?.organisation?.hasMembership ?? false;

  if (!isPublisher && !isDistributor) {
    return null;
  }

  const { isRequired: ownDistributionRequired } = isOwnDistributionRequired({
    isPublisher,
    isDistributor,
    isMember,
  });
  const { isRequired: ownPublicationRequired } = isOwnPublicationRequired({
    isPublisher,
    isDistributor,
    isMember,
  });

  const publisherChecked = filters.publisherOrgId === orgId;
  const fundChecked = filters.fundOrgId === orgId;

  return (
    <FacetWrapper title={t('facet_personal')} active={publisherChecked || fundChecked}>
      {isPublisher && (
        <Tooltip title={ownPublicationRequired ? t('facet_personal_tooltip') : ''} arrow>
          <FormControlLabel
            control={
              <FacetCheckBox
                onChange={(e, checked) => {
                  if (ownPublicationRequired) return;
                  onFilter({ ...filters, publisherOrgId: checked ? orgId : '' });
                }}
                checked={publisherChecked || ownPublicationRequired}
                disabled={ownPublicationRequired}
              />
            }
            label={t('facet_own_titles', { context: isDistributor ? 'publisher' : '' })}
            disabled={ownPublicationRequired}
          />
        </Tooltip>
      )}
      {isDistributor && (
        <Tooltip title={ownDistributionRequired ? t('facet_personal_tooltip') : ''} arrow>
          <FormControlLabel
            control={
              <FacetCheckBox
                onChange={(e, checked) => {
                  if (ownDistributionRequired) return;
                  onFilter({ ...filters, fundOrgId: checked ? orgId : '' });
                }}
                checked={filters.fundOrgId === orgId || ownDistributionRequired}
                disabled={ownDistributionRequired}
              />
            }
            label={t('facet_own_titles', { context: isPublisher ? 'distributor' : '' })}
            disabled={ownDistributionRequired}
          />
        </Tooltip>
      )}
    </FacetWrapper>
  );
};

export default PersonalFacet;
