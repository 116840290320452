import { Theme } from '@mui/material';
import { styled } from '@mui/styles';

export default styled('div')(({ theme }: { theme: Theme }) => {
  return {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    overflowY: 'auto',
    height: `calc(100vh - ${theme.toolbar.height}px - ${theme.spacing(6)})`,
    flex: 1,
    // maxWidth: 840,
    // paddingBottom: '20rem',
    '& > div': {
      marginBottom: theme.spacing(3),
    },
  };
});
