import { useFetch } from '@react-redux-fetch/hooks';
import React, { ReactNode, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import RemoteAutocomplete, {
  RemoteAutocompleteProps,
} from '../../../../../../components/Form/RemoteAutocomplete';
import { CollectionItem } from '../../../../../../config/api/models/dataSets';
import { updateQueryParameters } from '../../../../../../helpers/hateoas';
import urlencode from '../../../../../../helpers/urlencode';
import { getCollectionsRequest } from '../../api';
import { getCollections } from '../../selectors';

type Props = Omit<Partial<RemoteAutocompleteProps>, 'label'> & {
  url: string;
  name?: string;
  disabled?: boolean;
  label?: ReactNode;
};

const CollectionField = ({
  url,
  publisherName,
  label = '',
  name,
  disabled,
  isCreatable,
  ...other
}: Props) => {
  const [collectionsFetch, fetchCollections] = useFetch(getCollectionsRequest);
  const collections = useSelector(getCollections);

  const createUrl = useCallback(
    (term: string) => updateQueryParameters(url, { q: urlencode(term) }),
    [url]
  );

  useEffect(() => {
    if (other.inputValue) {
      fetchCollections(createUrl(other.inputValue));
    }
  }, [createUrl, fetchCollections, other.inputValue]);

  const options = isCreatable
    ? collections?.map(({ title }) => ({ label: title, value: title }))
    : collections;

  return (
    <RemoteAutocomplete
      label={label ?? ''}
      {...other}
      name={name || 'collection'}
      isDisabled={disabled}
      createUrlFromInput={createUrl}
      promiseState={collectionsFetch}
      makeRequest={fetchCollections}
      options={options || []}
      getOptionValue={isCreatable ? undefined : (collection: CollectionItem) => collection}
      getOptionLabel={isCreatable ? undefined : (collection: CollectionItem) => collection.title}
      isCreatable={isCreatable}
    />
  );
};

export default CollectionField;
