import React from 'react';
import { useTranslation } from 'react-i18next';
import Header from '../../../../components/Header';
import ConstrainedPageContent from '../../../../components/ConstrainedPageContent';
import ShoppingCart from '../components/shoppingCart/ShoppingCart';

const ShoppingCartPage = () => {
  const { t } = useTranslation();
  return (
    <ConstrainedPageContent center={false}>
      <Header title={t('page_title_shopping_cart')} />
      <ShoppingCart />
    </ConstrainedPageContent>
  );
};

export default ShoppingCartPage;
