import React from 'react';
import OrdersHistory from '../ordersHistory/OrdersHistory';
import OrdersTable from './OrdersTable';

const OrdersHistoryProcessed = () => {
  return (
    <OrdersHistory
      apiRouteName="elasticOrderHistoryProcessed"
      component={OrdersTable}
      apiRouteParams={{ state: 'processed' }}
    />
  );
};

export default OrdersHistoryProcessed;
