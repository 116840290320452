import { Grid, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CheckBox } from '../../../components/Form';
import SubTitle from '../../../components/SubTitle';
import { IsbnPrices } from '../domain';
import { IsbnFormValues } from '../types';
import IsbnTitleOverview from './IsbnTitleOverview';
import { OrganisationInfo } from './OrganisationInfo';

type Props = {
  values: IsbnFormValues;
  showBilling?: boolean;
  purchaseOrderRequired?: boolean;
};

const IsbnRequestOverview = ({ values, showBilling, purchaseOrderRequired }: Props) => {
  const { t } = useTranslation();

  const billingPrice = () => {
    if (Object.keys(IsbnPrices).includes(values.requestIsbnNumbers)) {
      return IsbnPrices[values.requestIsbnNumbers as '1' | '10' | '100'];
    }
    return IsbnPrices.flemishOld;
  };

  return (
    <Grid container direction="column" spacing={3}>
      <Grid item>
        <Typography>{t('form_isbn_overview_subtitle')}</Typography>
      </Grid>

      <Grid item container direction="column">
        <Grid item>
          <SubTitle>{t('form_isbn_overview_organisation_title')}</SubTitle>
        </Grid>

        <Grid item>
          <OrganisationInfo organisation={values.organisation} />
        </Grid>
      </Grid>

      {(values.prefix || values.requestIsbnNumbers !== 'use_existing_prefix') && (
        <Grid item container direction="column">
          <Grid item>
            <SubTitle>
              {t(
                values.prefix
                  ? 'form_isbn_overview_chosen_prefix_title'
                  : 'form_isbn_overview_prefix_title'
              )}
            </SubTitle>
          </Grid>

          <Grid item>
            <Typography>
              {values.prefix
                ? t('form_isbn_overview_chosen_prefix', { prefix: values.prefix })
                : t('form_isbn_overview_requesting_numbers', { count: +values.requestIsbnNumbers })}
            </Typography>
          </Grid>
        </Grid>
      )}

      <Grid item container direction="column">
        <Grid item>
          <SubTitle>{t('form_isbn_overview_title_title')}</SubTitle>
        </Grid>

        <Grid item>
          <IsbnTitleOverview
            title={values.title || ''}
            subTitle={values.subtitle}
            languages={values.languages || []}
            productForm={
              values.selectedProductForm?.shortLabel || values.selectedProductForm?.label || ''
            }
            collectionName={values.collection?.title?.value}
            collectionNumber={values.collection?.number}
            imprint={values.imprint}
          />
        </Grid>
      </Grid>

      {(showBilling || purchaseOrderRequired) && (
        <Grid item container direction="column">
          <Grid item>
            <SubTitle>{t('form_isbn_overview_billing_title')}</SubTitle>
          </Grid>

          {showBilling && (
            <>
              <Grid item>
                <Typography>
                  <b>
                    {t('form_isbn_overview_billing_price', {
                      price: billingPrice(),
                      email: values.organisation.email,
                    })}
                  </b>
                </Typography>
                <Typography>{t('form_isbn_overview_billing_body')}</Typography>
              </Grid>

              <Grid item style={{ marginTop: '1rem' }}>
                <CheckBox
                  name="billingConsent"
                  label={t('form_isbn_overview_billing_confirmation')}
                />
              </Grid>
            </>
          )}

          {purchaseOrderRequired && (
            <Grid item style={{ marginTop: '1rem' }}>
              <CheckBox
                name="purchaseOrderConsent"
                label={t('form_isbn_overview_purchase_order_confirmation')}
              />
            </Grid>
          )}
        </Grid>
      )}
    </Grid>
  );
};

export default IsbnRequestOverview;
