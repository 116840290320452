import { union } from 'ramda';
import { ROLE, Role } from '../security/model';
import { Organisation } from '../../config/api/types';

const forbiddenCombinations = {
  [ROLE.BOOKSTORE]: [ROLE.PUBLISHER, ROLE.DISTRIBUTOR, ROLE.LIBRARY, ROLE.DATA_PRODUCER],
  [ROLE.LIBRARY]: [ROLE.PUBLISHER, ROLE.DISTRIBUTOR, ROLE.BOOKSTORE, ROLE.DATA_PRODUCER],
  [ROLE.PUBLISHER]: [ROLE.BOOKSTORE, ROLE.LIBRARY],
  [ROLE.DISTRIBUTOR]: [ROLE.BOOKSTORE, ROLE.LIBRARY],
  [ROLE.DATA_PRODUCER]: [ROLE.BOOKSTORE, ROLE.LIBRARY],
};

export const recursiveUnion = (...args: any[][]): any[] => {
  const firstArg = args[0];
  if (!Array.isArray(firstArg)) {
    return [];
  }
  if (args.length === 1) {
    return firstArg;
  }

  const argsWithoutFirst = [...args];
  argsWithoutFirst.shift();

  if (!argsWithoutFirst) {
    return [];
  }
  return union(firstArg, recursiveUnion(...argsWithoutFirst));
};

export const getForbiddenCombinations = (roles?: Role[]) => {
  const allRoles = Object.values(ROLE).filter((role) => role !== ROLE.ADMIN);
  if (!roles || roles.length === 0) {
    return [ROLE.ADMIN];
  }

  if (roles[0] === ROLE.ADMIN) {
    return allRoles;
  }

  return recursiveUnion(...(roles.map((role) => forbiddenCombinations[role] || []) || [])).concat([
    ROLE.ADMIN,
  ]);
};

export const validateDistributorCode = (value: string) => {
  const regex = new RegExp(/^[A-Z]{3}$/);
  return !regex.test(value);
};

export const formatDistributorCode = (value?: string) => {
  return value ? value.toUpperCase().slice(0, 3) : '';
};

export const formatAddress = (organisation: Organisation) => {
  const firstPart = `${organisation.street || ''} ${organisation.number || ''} ${
    organisation.addition || ''
  }`.trim();
  const secondPart = `${organisation.postalCode || ''} ${organisation.city || ''}`.trim();
  return firstPart && secondPart ? `${firstPart}, ${secondPart}` : firstPart + secondPart;
};

type OwnXRequiredArgs = {
  isPublisher: boolean;
  isDistributor: boolean;
  isMember: boolean;
};
export const isOwnDistributionRequired = ({
  isPublisher,
  isDistributor,
  isMember,
}: OwnXRequiredArgs): { isDefault: boolean; isRequired: boolean } => {
  const response = { isDefault: false, isRequired: false };

  if (isPublisher && isDistributor && isMember) {
    return {
      isDefault: true,
      isRequired: false,
    };
  }

  return response;
};

export const isOwnPublicationRequired = ({
  isPublisher,
  isDistributor,
  isMember,
}: OwnXRequiredArgs): { isDefault: boolean; isRequired: boolean } => {
  const response = { isDefault: false, isRequired: false };

  if (isPublisher && !isDistributor && isMember) {
    return {
      isDefault: true,
      isRequired: false,
    };
  }

  if (isPublisher && !isMember) {
    return {
      isDefault: true,
      isRequired: true,
    };
  }
  return response;
};
