import { Theme, Typography } from '@mui/material';
import { TypographyProps } from '@mui/material/Typography';
import { styled } from '@mui/styles';
import React from 'react';

const FieldsetTitle = ({ children, ...other }: TypographyProps) => (
  <Typography variant="h4" {...other}>
    {children}
  </Typography>
);

type Props = {
  theme: Theme;
};

export default styled(FieldsetTitle)(({ theme }: Props) => ({
  textTransform: 'uppercase',
  marginBottom: theme.spacing(2),
  // marginTop: theme.spacing(2),
  // '&:first-child': {
  //   marginTop: 0,
  // },
}));
