import { Divider, Grid, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../../components/Button';
import InfoTooltip from '../../../../components/InfoTooltip';
import SubTitle from '../../../../components/SubTitle';
import { IsbnApplication } from '../../../../config/api/types';
import { getCountryNameByShortName } from '../../../../config/data/countries';

type Props = {
  isbnApplication: IsbnApplication;
  onMatch: () => void;
  onCreateOrganisation: () => void;
  overrideApplicantField: (field: keyof IsbnApplication['organisation'], value: string) => void;
  disableCreateOrganisation: boolean;
  onSave: () => void;
  canSaveOrganisationChanges: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  bold: {
    fontWeight: theme.typography.fontWeightMedium,
  },
  verticalPaddingSmall: {
    padding: '4px 0px',
  },
  verticalPaddingMedium: {
    padding: '8px 0px',
  },
  verticalPaddingLarge: {
    padding: '12px 0px',
  },
  differentValue: {
    color: 'red',
  },
}));

const IsbnApplicantReview = ({
  isbnApplication,
  onMatch,
  onCreateOrganisation,
  overrideApplicantField,
  disableCreateOrganisation,
  onSave,
  canSaveOrganisationChanges,
}: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const infoField = (
    label: string,
    value: keyof IsbnApplication['organisation'],
    format?: (value: string) => string
  ) => {
    const applicationValue = isbnApplication.organisation[value];
    const matchedValue = getOrganisationDifference(value);
    return (
      <>
        <Grid item xs={4} className={classes.verticalPaddingSmall}>
          <Typography className={classes.bold}>{label}</Typography>
        </Grid>
        <Grid item xs={5} className={classes.verticalPaddingSmall}>
          <Typography className={matchedValue ? classes.differentValue : ''}>
            <span>{(format ? format(applicationValue) : applicationValue) || t('no_value')}</span>
            {matchedValue && (
              <InfoTooltip title={format ? format(matchedValue) : matchedValue} inline />
            )}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          {matchedValue && (
            <Button onClick={() => overrideApplicantField(value, applicationValue)} size="small">
              {t('use_value')}
            </Button>
          )}
        </Grid>
      </>
    );
  };

  const getOrganisationDifference = (field: keyof IsbnApplication['organisation']) => {
    const matchedOrg = isbnApplication._embedded?.publisherOrganisation;
    if (matchedOrg) {
      return matchedOrg[field] === isbnApplication.organisation[field]
        ? undefined
        : matchedOrg[field] || t('no_value');
    }
  };

  return (
    <Grid container direction="column">
      <Grid item xs container direction="column">
        <Grid item xs className={classes.verticalPaddingMedium}>
          <SubTitle>{t('isbn_applicant_organisation_info')}</SubTitle>
          <Divider />
        </Grid>

        <Grid container item xs className={classes.verticalPaddingMedium}>
          <Grid container item xs={8} spacing={1}>
            {infoField(t('form_organisation_name'), 'name')}
            {infoField(t('form_company_registration_number'), 'companyRegistrationNumber')}
            {infoField(t('form_street'), 'street')}
            {infoField(t('form_number'), 'number')}
            {infoField(t('form_addition'), 'addition')}
            {infoField(t('form_postal_code'), 'postalCode')}
            {infoField(t('form_city'), 'city')}
            {infoField(t('form_country_iso'), 'countryIso', getCountryNameByShortName)}
            {infoField(t('form_phone_number'), 'phoneNumber')}
            {infoField(t('form_email'), 'email')}
            {infoField(t('form_url'), 'url')}
          </Grid>

          <Grid
            container
            item
            xs={4}
            sx={{ justifyContent: 'flex-start', alignItems: 'flex-end', flexDirection: 'column' }}
          >
            <Grid item className={classes.verticalPaddingSmall}>
              <Button onClick={onMatch}>
                {t(
                  isbnApplication.anonymous
                    ? 'isbn_applicant_organisation_match'
                    : 'isbn_applicant_organisation_match_other'
                )}
              </Button>
            </Grid>
            {Boolean(isbnApplication.anonymous && !isbnApplication.prefix) && (
              <Grid item className={classes.verticalPaddingSmall}>
                <Button disabled={disableCreateOrganisation} onClick={onCreateOrganisation}>
                  {t('isbn_applicant_organisation_create')}
                </Button>
              </Grid>
            )}
            {isbnApplication._embedded?.publisherOrganisation && (
              <Grid item>
                <Button onClick={onSave} disabled={!canSaveOrganisationChanges}>
                  {t('form_action_save')}
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs container direction="column">
        <Grid item className={classes.verticalPaddingMedium}>
          <SubTitle>{t('isbn_applicant_personal_info')}</SubTitle>
          <Divider />
        </Grid>
        <Grid container item className={classes.verticalPaddingMedium}>
          <Grid container item xs={8}>
            <Grid item xs={4} className={classes.verticalPaddingSmall}>
              <Typography className={classes.bold}>{t('form_firstName_and_lastName')}</Typography>
            </Grid>
            <Grid item xs={8} className={classes.verticalPaddingSmall}>
              <Typography>
                {isbnApplication.login.firstName
                  ? `${isbnApplication.login.firstName} ${isbnApplication.login.lastName}`
                  : isbnApplication.login.lastName}
              </Typography>
            </Grid>

            <Grid item xs={4} className={classes.verticalPaddingSmall}>
              <Typography className={classes.bold}>{t('form_email')}</Typography>
            </Grid>
            <Grid item xs={8} className={classes.verticalPaddingSmall}>
              <Typography>{isbnApplication.login.email}</Typography>
            </Grid>
          </Grid>

          <Grid
            container
            item
            xs={4}
            sx={{ justifyContent: 'flext-start', alignItems: 'flex-end', flexDirection: 'column' }}
          ></Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default IsbnApplicantReview;
