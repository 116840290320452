import React from 'react';
import { promotionFields } from '../../domain';

type Props = {
  [key: string]: any;
  name: keyof { [key in typeof promotionFields[number]]: true };
};

const PromotionField = ({ component: Component, ...other }: Props) => <Component {...other} />;

export default PromotionField;
