import { Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material';
import { useFetch } from '@react-redux-fetch/hooks';
import React, { FC } from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import Button from '../../../../components/Button';
import Field from '../../../../components/Form';
import { Maybe } from '../../../../config/api/types';
import useApiRoute from '../../../../config/api/useApiRoute';
import { RetourDetail } from '../../../../config/store/retour';
import { useAsyncValidation } from '../../../../helpers/finalFormAsyncValidation';
import { getSubmitRetourRequest } from './api';

type Props = {
  onClose: () => void;
  onSuccess: () => void;
  unconfirmedRetour: Maybe<RetourDetail>;
};
export const ConfirmRetourDialog: FC<Props> = ({ onClose, unconfirmedRetour, onSuccess }) => {
  const { t } = useTranslation();
  const baseUrl = useApiRoute('retours');
  // TODO should be a self _link
  const url = `${baseUrl}/${unconfirmedRetour?.id}`;
  const [submitFetch, submitRetour] = useFetch(getSubmitRetourRequest);
  const { createSubmissionPromise } = useAsyncValidation(submitFetch);

  return (
    <Dialog open onClose={onClose}>
      <DialogTitle>{t('retour_confirm_title')}</DialogTitle>
      <Form
        onSubmit={(values) => {
          submitRetour(url, {
            reference: values.reference,
            confirmed: true,
          });

          return createSubmissionPromise().then((err) => {
            if (err) return err;

            onSuccess();
          });
        }}
        initialValues={{
          reference: '',
        }}
      >
        {({ handleSubmit, values }) => (
          <form onSubmit={handleSubmit}>
            <DialogContent>
              <Field
                name="reference"
                label={t('form_reference')}
                type="text"
                helperText={t('retour_reference_help')}
              />
            </DialogContent>
            <DialogActions>
              <Grid item container spacing={1} alignItems="flex-end" justifyContent="flex-end">
                <Grid item>
                  <Button onClick={onClose} variant="outlined">
                    {t('retour_confirm_cancel')}
                  </Button>
                </Grid>
                <Grid item>
                  <Button type="submit">{t('retour_confirm_submit')}</Button>
                </Grid>
              </Grid>
            </DialogActions>
          </form>
        )}
      </Form>
    </Dialog>
  );
};
