import { Typography } from '@mui/material';
import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import Fieldset from '../Fieldset';
import FieldsetTitle from '../FieldsetTitle';
import SectionWrapper from '../SectionWrapper';

const MediaFilesNotPossible = ({ children }: { children?: ReactNode }) => {
  const { t } = useTranslation();
  return (
    <SectionWrapper id="mediaFiles">
      <FieldsetTitle>{t('title_section_mediaFiles')}</FieldsetTitle>
      <Fieldset>
        <Typography>{children || t('isbn_mediaFiles_info')}</Typography>
      </Fieldset>
    </SectionWrapper>
  );
};

export default MediaFilesNotPossible;
