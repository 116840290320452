import { addIndex, filter, flatten } from 'ramda';
import { ClientRoute, NestedClientRoute, NestedClientRoutes } from './types';
import omit from 'lodash/fp/omit';
import { RouteResourceNames } from '../api/types';

const filterIndexed = addIndex(filter);

export const compareRoutes = (routeA: ClientRoute, routeB: ClientRoute) => {
  const routeAOrder = routeA.order || 9999;
  const routeBOrder = routeB.order || 9999;

  if (routeAOrder <= routeBOrder) {
    return -1;
  }
  if (routeAOrder > routeBOrder) {
    return 1;
  }
  return 0;
};

export const flattenRoutes = (routes: NestedClientRoute[]): ClientRoute[] => {
  // @ts-ignore Error since upgrade to TS 4.5.5
  return flatten(
    routes.map((route: NestedClientRoute) => {
      // @ts-ignore Error since upgrade to TS 4.5.5
      return [filterIndexed(omit('subRoutes'), route), flattenRoutes(route.subRoutes || [])];
    })
  );
};

export const getParentRoute = (
  routes: NestedClientRoute[],
  path: string,
  parent?: ClientRoute
): ClientRoute | undefined => {
  for (const route of routes) {
    if (route.path === path) {
      return parent;
    }
    if (route.subRoutes) {
      const parentRoute = getParentRoute(route.subRoutes, path, route);
      if (parentRoute) {
        return parentRoute;
      }
    }
  }
};

export const removeNestedItems = (
  routes: NestedClientRoutes,
  allowedRoutes: RouteResourceNames[]
) => {
  const finalRoutes: NestedClientRoutes = [];
  routes.forEach((route) => {
    if (
      route.requiredResources &&
      route.requiredResources.every((resourceName) => allowedRoutes.includes(resourceName))
    ) {
      finalRoutes.push({
        ...route,
        subRoutes: route.subRoutes && removeNestedItems(route.subRoutes, allowedRoutes),
      });
    } else if (!route.requiredResources && route.subRoutes) {
      const subRoutes = removeNestedItems(route.subRoutes, allowedRoutes);
      if (subRoutes.length > 0) {
        finalRoutes.push({
          ...route,
          subRoutes,
        });
      }
    } else if (!route.requiredResources) {
      finalRoutes.push(route);
    }
  });
  return finalRoutes;
};
