import { Theme } from '@mui/material';
import { styled } from '@mui/styles';

export default styled('div')(({ theme }: { theme: Theme }) => ({
  width: 304,
  paddingRight: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  flexShrink: 0,
}));
