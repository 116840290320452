import React from 'react';
import useApiRoute from '../../../../config/api/useApiRoute';
import { ApiConfig } from '../api';
import { Organisations } from '../../../../config/api/types';
import { useTranslation } from 'react-i18next';
import { getLink, updateQueryParameters } from '../../../../helpers/hateoas';
import Field from '../../../../components/Form';
import WhenFieldChanges from '../../../../components/Form/WhenFieldChanges';
import { sortByName } from '../../domain';
import useFetch from '../../../../helpers/useFetch';
import { ROLE } from '../../../security/model';
import RemoteAutocomplete from '../../../../components/Form/RemoteAutocomplete';
import urlencode from '../../../../helpers/urlencode';

type Props = {
  isUpdate: boolean;
};

const DistributorSelect = ({ isUpdate }: Props) => {
  const { t } = useTranslation();
  const organisationsPath = useApiRoute('organisations');
  const [distributorsFetch, fetchDistributors] = useFetch<Organisations>(
    ApiConfig.getDistributors()
  );

  const disabledField = (
    <Field label={t('form_distributor')} type="text" name="distributor" disabled />
  );

  if (isUpdate || !organisationsPath) {
    return disabledField;
  }

  const distributors =
    distributorsFetch && distributorsFetch.value
      ? sortByName(distributorsFetch.value._embedded.items)
      : [];
  const options = distributors.map((distributor) => ({
    value: getLink(distributor, 'distributor') || '',
    label: distributor.name,
  }));

  return (
    <>
      <RemoteAutocomplete
        label={t('form_distributor')}
        name="distributorUrl"
        createUrlFromInput={(term) => {
          return updateQueryParameters(organisationsPath, {
            name: urlencode(term),
            role: ROLE.DISTRIBUTOR,
          });
        }}
        promiseState={distributorsFetch}
        makeRequest={fetchDistributors}
        options={options}
        parse={(option) => option.value}
        format={(value) => options.filter((option) => option.value === value)}
      />

      <WhenFieldChanges
        field="distributorUrl"
        set="fundUrl"
        to={(value) => {
          const organisation = distributors.find((org) => getLink(org, 'distributor') === value);
          return organisation ? getLink(organisation, 'funds') || '' : '';
        }}
      />
    </>
  );
};

export default DistributorSelect;
