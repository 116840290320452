import { User, UserRole } from '../../config/api/types';

export type Token = string;

export enum AuthState {
  NOT_AUTHENTICATED,
  AUTHENTICATED,
}

export type ImpersonatedUser = User & { departmentId?: string };

export interface State {
  token: Token;
  authState: AuthState;
  impersonate: ImpersonatedUser | undefined;
}

export const ROLE: Record<string, UserRole> = {
  BOOKSTORE: 'ROLE_BOOKSTORE',
  PUBLISHER: 'ROLE_PUBLISHER',
  DISTRIBUTOR: 'ROLE_DISTRIBUTOR',
  LIBRARY: 'ROLE_LIBRARY',
  DATA_CONSUMER: 'ROLE_DATA_CONSUMER',
  DATA_PRODUCER: 'ROLE_DATA_PRODUCER',
  ADMIN: 'ROLE_ADMIN',
};

export type Role = UserRole;
