import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-final-form';
import FacetWrapper from '../../components/Facet/FacetWrapper';
import useApiRoute from '../../../../../config/api/useApiRoute';
import CollectionField from '../../../admin/title/containers/fields/CollectionField';
import AutoSave from '../../../../../components/Form/AutoSave';
import { QueryObjectValue } from '../../../../../helpers/elasticQueryDSL';
import SetInitialValueWhenLoaded from '../../../../../components/Form/SetInitialValueWhenLoaded';
import { getCollections } from '../../../admin/title/selectors';
import { CollectionItem } from '../../../../../config/api/models/dataSets';
import { StringParam, useQueryParam, withDefault } from 'use-query-params';

type Props = {
  onFilter: (collectionTitle?: string) => void;
  value: QueryObjectValue;
};

type FormValues = {
  collection?: {
    title: string;
  };
};

const CollectionFacet: FC<Props> = ({ onFilter, value }) => {
  const { t } = useTranslation();
  const url = useApiRoute('titleCollections');
  const findCollectionValue = useCallback((item: CollectionItem) => item.title === value, [value]);

  const [fieldValue, setFieldValue] = useQueryParam(
    'collection.title.keyword',
    withDefault(StringParam, value as string)
  );

  if (!url) {
    return null;
  }

  return (
    <FacetWrapper title={t('facet_collection')} collapsible={false} active={Boolean(value)}>
      <Form
        onSubmit={(formValues: FormValues) => {
          setFieldValue(formValues.collection?.title);
          return onFilter(formValues.collection?.title);
        }}
      >
        {({ form: { submit } }) => (
          <>
            <AutoSave save={submit} />
            <CollectionField
              url={url}
              placeholder={t('facet_collection_placeholder')}
              blurInputOnSelect
              isClearable
              onClearValue={submit}
              wideDropdown
              inputValue={fieldValue as string}
              key={fieldValue as string}
            />
            {value && (
              <SetInitialValueWhenLoaded
                name="collection"
                dataSelector={getCollections}
                findValue={findCollectionValue}
              />
            )}
          </>
        )}
      </Form>
    </FacetWrapper>
  );
};

export default CollectionFacet;
