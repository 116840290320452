import Divider from '@mui/material/Divider/Divider';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { WithStyles, withStyles } from '@mui/styles';
import React from 'react';
import { RouteComponentProps, useHistory } from 'react-router';
import Header from '../../../components/Header';
import { tabHeaderStyles } from '../../../components/tabHeaderStyles';

type TabContent = {
  route: string;
  label: string;
};

type Props = RouteComponentProps &
  WithStyles<typeof tabHeaderStyles> & {
    tabs: TabContent[];
    title: string;
  };

const OrdersTabHeader = ({ classes, match, tabs, title }: Props) => {
  const { push } = useHistory();

  const handleTabClick = (event: React.ChangeEvent<{}>, value: string) => {
    if (match.path !== value) {
      push(value);
    }
  };

  return (
    <>
      <Header title={title} />
      <Tabs
        value={match.path}
        classes={{ flexContainer: classes.tabsContainer, indicator: classes.indicator }}
        onChange={handleTabClick}
      >
        {tabs.map(({ route, label }) => (
          <Tab
            value={route}
            label={label}
            classes={{
              root: classes.tab,
              selected: classes.selectedTab,
            }}
            key={route}
          />
        ))}
      </Tabs>
      <Divider className={classes.divider} style={{ marginBottom: 16 }} />
    </>
  );
};

export default withStyles(tabHeaderStyles)(OrdersTabHeader);
