import useApiRoute from '../../../config/api/useApiRoute';
import { useFetch } from '@react-redux-fetch/hooks';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getThemaQualifiersRequest } from '../api';
import { selectThemaQualifiers } from '../selectors';

type Args = {
  initiate?: boolean;
};
export const useThemaQualifiers = ({ initiate }: Args = { initiate: true }) => {
  const themaQualifiersApiRoute = useApiRoute('titleThemaQualifiers');
  const [, fetchThemaQualifiers] = useFetch(getThemaQualifiersRequest);
  const themaQualifiers = useSelector(selectThemaQualifiers);
  const alreadyFetched = Boolean(themaQualifiers);

  useEffect(() => {
    if (!alreadyFetched && themaQualifiersApiRoute && initiate) {
      fetchThemaQualifiers(themaQualifiersApiRoute);
    }
  }, [initiate, themaQualifiersApiRoute, fetchThemaQualifiers, alreadyFetched]);

  return {
    themaQualifiers,
    hasAccess: Boolean(themaQualifiersApiRoute),
  };
};
