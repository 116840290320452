import { useFetch } from '@react-redux-fetch/hooks';
import React from 'react';
import { useSelector } from 'react-redux';
import DelayedLoader from '../../../../components/DelayedLoader';
import { getRetourLinesRequest } from '../api';
import { getPagedRetourLines } from '../selectors';
import { RetourLinesTable } from './RetourLinesTable';

export const RetourLinesHistory = () => {
  const route = 'retourlines';
  const pagedRetourLines = useSelector(getPagedRetourLines);
  const defaultUrl = pagedRetourLines?._links.self.href || route;
  const [retourLinesFetch, fetchRetourLines] = useFetch(
    (url = defaultUrl) => getRetourLinesRequest(url),
    {
      eager: true,
    }
  );

  return (
    <DelayedLoader data={pagedRetourLines}>
      {pagedRetourLines && (
        <RetourLinesTable
          pagedRetourLines={pagedRetourLines}
          fetchRetours={fetchRetourLines}
          isLoading={retourLinesFetch?.pending}
        />
      )}
    </DelayedLoader>
  );
};
