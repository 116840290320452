export const EMPLOYEES = [
  {
    fullName: 'Hannelore Hendrickx',
    emailAddress: 'isbn@meta4books.be',
    phoneNumber: '+3233034348',
    descriptionI18nId: 'hannelore_hendrickx',
  },
  {
    fullName: 'Olivier Fuchs',
    emailAddress: 'boekenbank@meta4books.be',
    phoneNumber: '+3233034347',
    descriptionI18nId: 'olivier_fuchs',
  },
];
