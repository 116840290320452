import { Grid } from '@mui/material';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { MediaCover, MediaSample } from '../../../../../../config/api/types';
import { useStartStop } from '../../../../../../helpers/useStartStop';
import ImageMediaFile from '../../containers/fields/ImageMediaFile';
import MediaFileTable from '../../containers/fields/MediaFileTable';
import SampleMediaFile from '../../containers/fields/SampleMediaFile';
import Fieldset from '../Fieldset';
import FieldsetSubTitle from '../FieldsetSubTitle';
import FieldsetTitle from '../FieldsetTitle';
import SectionWrapper from '../SectionWrapper';

type Props = {
  mediaFrontCover?: MediaCover;
  mediaBackCover?: MediaCover;
  mediaSample?: MediaSample;
  onChangeFormValue: (name: any, value?: any) => void;
  gtin13: string;
  url?: string;
};

const MediaFiles = ({
  mediaFrontCover,
  mediaBackCover,
  mediaSample,
  onChangeFormValue,
  gtin13,
  url = '',
}: Props) => {
  const { t } = useTranslation();
  const { started: pollForChanges, stop: stopPolling, start: startPolling } = useStartStop();

  return (
    <SectionWrapper id="mediaFiles">
      <FieldsetTitle>{t('title_section_mediaFiles')}</FieldsetTitle>
      <Fieldset>
        <Grid container spacing={4} justifyContent="flex-start">
          <Grid item xs={6} sm={4} lg={3}>
            <FieldsetSubTitle>{t('title_heading_frontCover')}</FieldsetSubTitle>
            <ImageMediaFile
              imgSrc={
                (mediaFrontCover && mediaFrontCover.thumbnail) ||
                (mediaFrontCover && mediaFrontCover.thumbnailSmall)
              }
              removeUrl={
                mediaFrontCover &&
                mediaFrontCover._links &&
                mediaFrontCover._links.self &&
                mediaFrontCover._links.self.href
              }
              setFileSrc={(src) => {
                onChangeFormValue('mediaFrontCover.thumbnail', src);
                onChangeFormValue('mediaFrontCover.thumbnailSmall', src);
              }}
              alt="frontCover"
              fileName={gtin13}
              onPollForChanges={startPolling}
            />
          </Grid>
          <Grid item xs={6} sm={4} lg={3}>
            <FieldsetSubTitle>{t('title_heading_backCover')}</FieldsetSubTitle>
            <ImageMediaFile
              imgSrc={mediaBackCover && mediaBackCover.thumbnail}
              removeUrl={
                mediaBackCover &&
                mediaBackCover._links &&
                mediaBackCover._links.self &&
                mediaBackCover._links.self.href
              }
              setFileSrc={(src) => onChangeFormValue('mediaBackCover.thumbnail', src)}
              alt="backCover"
              fileName={`${gtin13}a`}
              onPollForChanges={startPolling}
            />
          </Grid>
        </Grid>
      </Fieldset>
      <Fieldset>
        <FieldsetSubTitle>{t('title_heading_sample')}</FieldsetSubTitle>
        <SampleMediaFile
          fileName={gtin13}
          mediaSample={mediaSample}
          setFileSrc={(src) => onChangeFormValue('mediaSample.original', src)}
          onPollForChanges={startPolling}
        />
      </Fieldset>
      <Fieldset>
        <MediaFileTable pollForChanges={pollForChanges} url={url} onStopPolling={stopPolling} />
      </Fieldset>
    </SectionWrapper>
  );
};

export default MediaFiles;
