import { Typography } from '@mui/material';
import React, { forwardRef } from 'react';
import { NoticeProps } from 'react-select';
import { SelectOption } from './types';

type Props = NoticeProps<SelectOption, any>;

const Message = forwardRef<HTMLDivElement, Props>(({ selectProps, children }, ref) => {
  return (
    <div className={selectProps.classes.noOptionsMessage}>
      <Typography ref={ref}>{children}</Typography>
    </div>
  );
});

export default Message;
