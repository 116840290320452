import { Notifications, NotificationsCounters } from '../../config/api/models/notifications';
import { Maybe } from '../../config/api/types';
import { RootState } from '../../config/store/types';

export enum Channel {
  ALL = 'all',
  INCOMING_ORDERS = 'incoming-orders',
}

export enum OrderFor {
  BOOKSTORE = 'bookstore',
  DISTRIBUTOR = 'distributor',
}

// const channelMessageTypes = {
//   [Channel.ALL]: [
//     'OrderConfirmedNotification',
//     'OrderConfirmedForBookstoreNotification',
//     'OrderWithErrorNotification',
//   ],
//   [Channel.INCOMING_ORDERS]: [
//     'OrderConfirmedNotification',
//     'OrderConfirmedForBookstoreNotification',
//   ],
// };

export const getNotifications = (state: RootState): Maybe<Notifications> =>
  state.data.notifications;

export const createGetNotificationsWithChannel = (state: RootState) => {
  const notifications = state.data.notificationsForChannel;

  return function (channel: Channel) {
    return notifications?.[channel];
  };
};

export const getNotificationsCounters = (state: RootState): Maybe<NotificationsCounters> => {
  return state.data.notificationsCounters;
};
