import React from 'react';
import { useTranslation } from 'react-i18next';
import Header from '../../../components/Header';
import OrdersToProcess from '../components/ordersToProcess/OrdersToProcess';
import ConstrainedPageContent from '../../../components/ConstrainedPageContent';

const OrdersToProcessPage = () => {
  const { t } = useTranslation();
  return (
    <ConstrainedPageContent center={false}>
      <Header title={t('page_title_order_to_process')} />
      <OrdersToProcess />
    </ConstrainedPageContent>
  );
};

export default OrdersToProcessPage;
