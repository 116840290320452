import CancelIcon from '@mui/icons-material/Cancel';
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';
import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../../../components/Button';
import { ErrorButton } from '../../../../../components/ErrorButton';
import Table from '../../../../../components/Table/index';
import { CellType, DataType } from '../../../../../components/Table/types';
import { WarningButton } from '../../../../../components/WarningButton';
import { ProcessResult } from '../../../../../config/api/types';
import {
  getLink,
  retrieveQueryParameter,
  updateQueryParameter,
} from '../../../../../helpers/hateoas';

type Props = {
  processResult: ProcessResult;
  dispatchProcessResultGet: (url: string) => void;
  pending: boolean;
};

const ProcessResultTable = ({ processResult, dispatchProcessResultGet, pending }: Props) => {
  const { t } = useTranslation();

  const pagedProcessResult = processResult.pager;

  const url = getLink(pagedProcessResult, 'self') || '';

  const headers = [
    { label: t('table_headers_lineNr'), width: 60 },
    { label: t('table_headers_isbn_or_ean'), width: 136 },
    { label: t('table_headers_errorcode'), width: 96 },
    { label: t('table_headers_type'), width: 120 },
    { label: t('table_headers_notification') },
  ];

  const data: DataType = {
    resource: 'titleFiles',
    rows: pagedProcessResult._embedded.items.map((item) => ({
      id: getLink(item, 'self') || item.titleFileId + item.lineNumber,
      cells: [
        {
          type: CellType.Text,
          data: item.lineNumber,
        },
        {
          type: CellType.Text,
          data: <>{item.gtin13}</>,
        },
        {
          type: CellType.Text,
          data: item.importMessageCode,
        },
        {
          type: CellType.Text,
          data: t(`result_facet_${item.type}`),
        },
        {
          type: CellType.Text,
          data: item.message,
        },
      ],
    })),
  };

  const handleFilter = (messageType: 'error' | 'warning' | 'info') => () => {
    const firstPageUrl = getLink(pagedProcessResult, 'first') || '';
    dispatchProcessResultGet(updateQueryParameter(firstPageUrl, 'message_type', messageType));
  };

  const activeFilter = retrieveQueryParameter(url, 'message_type');

  return (
    <>
      <Box mt={1}>
        <Typography style={{ fontWeight: 500 }} gutterBottom>
          {t('result_filterByType')}
        </Typography>
      </Box>
      <Grid container spacing={1} style={{ marginBottom: 8 }}>
        <Grid item>
          <ErrorButton
            variant={activeFilter === 'error' ? 'contained' : 'outlined'}
            onClick={handleFilter('error')}
            disabled={pending}
          >
            <CancelIcon style={{ marginRight: 8 }} fontSize="small" />
            {t('result_facet_error')} ({processResult.facets.error})
          </ErrorButton>
        </Grid>
        <Grid item>
          <WarningButton
            variant={activeFilter === 'warning' ? 'contained' : 'outlined'}
            onClick={handleFilter('warning')}
            disabled={pending}
          >
            <WarningIcon style={{ marginRight: 8 }} fontSize="small" />
            {t('result_facet_warning')} ({processResult.facets.warning})
          </WarningButton>
        </Grid>
        <Grid item>
          <Button
            variant={activeFilter === 'info' ? 'contained' : 'outlined'}
            onClick={handleFilter('info')}
            disabled={pending}
          >
            <InfoIcon style={{ marginRight: 8 }} fontSize="small" />
            {t('result_facet_info')} ({processResult.facets.info})
          </Button>
        </Grid>
      </Grid>
      <Table
        data={data}
        headers={headers}
        limit={pagedProcessResult.limit}
        page={pagedProcessResult.page}
        pages={pagedProcessResult.pages}
        total={pagedProcessResult.total}
        onNextPage={() => dispatchProcessResultGet(getLink(pagedProcessResult, 'next') || '')}
        onPrevPage={() => dispatchProcessResultGet(getLink(pagedProcessResult, 'previous') || '')}
        onChangeLimit={(limit: string) => {
          const firstPage = getLink(pagedProcessResult, 'first');
          if (!firstPage) {
            return;
          }

          dispatchProcessResultGet(firstPage.replace(/limit=[0-9]*/, 'limit=' + limit));
        }}
      />
    </>
  );
};

export default ProcessResultTable;
