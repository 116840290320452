import { Box } from '@mui/material';
import { useFetch } from '@react-redux-fetch/hooks';
import React, { FC, useEffect } from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import Button from '../../../../../components/Button';
import Field from '../../../../../components/Form';
import { CreateOrgLoginDTO } from '../../../../../config/api/models/organisation';
import { Organisation } from '../../../../../config/api/types';
import { useAsyncValidation } from '../../../../../helpers/finalFormAsyncValidation';
import DepartmentField from '../../../../title/cart/components/shoppingCart/DepartmentField';
import { createLoginRequest } from '../../../api';
import { useLibraryDepartmentsAndBookstores } from '../../../library/hooks/useLibraryDepartmentsAndBookstores';

type Props = {
  onClose: () => void;
  onAdd: () => void;
  url: string;
  organisation: Organisation;
};

export const AddLoginForm: FC<Props> = ({ onClose, onAdd, url, organisation }) => {
  const { t } = useTranslation();
  const [createLoginFetch, createLogin] = useFetch(createLoginRequest);
  const { createSubmissionPromise } = useAsyncValidation(createLoginFetch);
  const { departments } = useLibraryDepartmentsAndBookstores(organisation);
  const createWasSuccessful = createLoginFetch?.fulfilled;

  useEffect(() => {
    if (createWasSuccessful) {
      onAdd();
      onClose();
    }
  }, [onAdd, onClose, createWasSuccessful]);

  return (
    <Form
      onSubmit={(values: CreateOrgLoginDTO) => {
        createLogin(url, values);
        return createSubmissionPromise();
      }}
    >
      {({ handleSubmit, submitting }) => (
        <form onSubmit={handleSubmit}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            <Box sx={{ maxWidth: 400 }}>
              <Field type="text" name="firstName" label={t('form_firstName')} />
            </Box>
            <Box sx={{ maxWidth: 400 }}>
              <Field type="text" name="lastName" label={t('form_lastName')} />
            </Box>
            <Box sx={{ maxWidth: 400 }}>
              <Field type="text" name="email" label={t('form_emailAddress')} />
            </Box>
            {departments && (
              <Box sx={{ maxWidth: 400 }}>
                <DepartmentField departments={departments} name="departmentId" />
              </Box>
            )}
            <Box sx={{ display: 'flex', gap: 2 }}>
              <div>
                <Button disabled={submitting} type="submit">
                  {t('form_action_save')}
                </Button>
              </div>
              <div>
                <Button disabled={submitting} variant="outlined" onClick={onClose}>
                  {t('form_action_cancel')}
                </Button>
              </div>
            </Box>
          </Box>
        </form>
      )}
    </Form>
  );
};
