import { useFetch } from '@react-redux-fetch/hooks';
import { FetchConfig } from '@react-redux-fetch/core';
import { Data, RootState } from '../../../../config/store/types';
import { HttpMethod, NestedClientRoute } from '../../../../config/routes/types';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useCreateApiRoute from '../../../../config/api/useCreateApiRoute';
import apiRoutes from '../../../../config/api/routes';
import { getImpersonate } from '../../../security/selectors';

const fetchConfig = (url: string, method: HttpMethod, key: string): FetchConfig<Data> => {
  return {
    method: 'OPTIONS',
    url: apiRoutes.create(url),
    transform: (responseValue, rawResponse: Response) => {
      const allowedMethods = rawResponse.headers.get('access-control-allow-methods');
      return {
        access: allowedMethods?.split(',') || [],
      };
    },
    repository: {
      access: (prev: Data['access'], next: HttpMethod[]): Data['access'] => {
        return {
          ...prev,
          [key]: next,
        };
      },
    },
  };
};

type RouteToCheck = {
  url: string;
  method: HttpMethod;
  key: string;
};

const selectAllowedMethods = (state: RootState) => {
  return state.data.access || {};
};
const onlyRequired = (r: NestedClientRoute) => Boolean(r.requiredMethod);

const useNavPermission = (routes: NestedClientRoute[]) => {
  const createApiRoute = useCreateApiRoute();
  const [, checkAccess] = useFetch(fetchConfig);
  const [routesToCheck, setRoutesToCheck] = useState<RouteToCheck[] | null>(null);
  const allowedMethods = useSelector(selectAllowedMethods);
  const impersonate = useSelector(getImpersonate);
  const impersonateEmail = impersonate?.email;

  useEffect(() => {
    setRoutesToCheck(
      routes.filter(onlyRequired).map((r) => ({
        url: createApiRoute(r.requiredMethod!.resource) || '',
        method: r.requiredMethod!.method,
        key: r.requiredMethod!.resource,
      }))
    );
    // eslint-disable-next-line
  }, [onlyRequired, impersonateEmail, createApiRoute]);

  useEffect(() => {
    if (!routesToCheck) return;
    routesToCheck.forEach((route) => {
      if (route.url) {
        checkAccess(route.url, route.method, route.key);
      }
    });
  }, [routesToCheck, checkAccess]);

  return routes.filter((route) => {
    if (!Boolean(route.requiredMethod)) {
      return true;
    }

    return allowedMethods[route.requiredMethod!.resource]?.includes(route.requiredMethod!.method);
  });
};

export default useNavPermission;
