import { Box, Grid, Tooltip, Typography } from '@mui/material';
import moment from 'moment';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Table from '../../../../components/Table';
import { CellType, DataType } from '../../../../components/Table/types';
import TextLink from '../../../../components/TextLink';
import { Paged } from '../../../../config/api/types';
import ROUTE_KEY from '../../../../config/routes/routeKeys';
import usePath from '../../../../config/routes/usePath';
import { RetourLine } from '../../../../config/store/retour';
import getIdFromUrl from '../../../../helpers/getIdFromUrl';
import ExportButton from '../../../core/exports';
import { IsbnLink } from '../../../title';
import { DeliveryNoteIcon } from '../../retourDetail/DeliveryNoteIcon';
import { RetourHistoryFilter } from './RetourHistoryFilter';

type Props = {
  pagedRetourLines: Paged<RetourLine>;
  isLoading?: boolean;
  fetchRetours: (url: string) => void;
};
export const RetourLinesTable: FC<Props> = ({ pagedRetourLines, isLoading, fetchRetours }) => {
  const { t } = useTranslation();
  const url = pagedRetourLines._links.self.href;
  const retourPath = usePath(ROUTE_KEY.RETURN_DETAIL);

  const headers = [
    { id: 'confirmed_at', label: t('table_headers_confirmed_at'), width: '10rem' },
    { id: 'retour_ref', label: t('table_headers_retour_reference'), width: '15%' },
    { id: 'distributor', label: t('table_headers_distributor') },
    { id: 'reason', label: t('table_headers_reason') },
    { id: 'isbn', label: t('table_headers_isbn') },
    { id: 'title', label: t('table_headers_title'), width: '240px' },
    { id: 'num_pieces', label: t('table_headers_num_pieces'), width: '4rem', align: 'center' },
    { id: 'comments', label: t('return_comments'), align: 'center' },
  ];

  const data: DataType = {
    resource: 'retourlines',
    rows: pagedRetourLines._embedded.items.map((retourLine) => {
      const path = retourPath.replace(':id', retourLine.retourId);

      return {
        id: retourLine.id,
        cells: [
          {
            type: CellType.Text,
            data: moment(retourLine.confirmedAt).format('DD/MM/YYYY'),
          },
          {
            type: CellType.Text,
            data: (
              <TextLink
                underline
                to={{
                  pathname: path,
                  state: { from: window.location.pathname },
                }}
              >
                {(retourLine.reference?.length || 0) > 20 ? (
                  <Tooltip title={retourLine.reference}>
                    <Typography noWrap>{retourLine.reference}</Typography>
                  </Tooltip>
                ) : (
                  retourLine.reference
                )}
              </TextLink>
            ),
          },
          {
            type: CellType.Text,
            data: (
              <>
                {retourLine._embedded.distributor._embedded.organisation.name}{' '}
                {retourLine._embedded.distributor?.isCbMember ? t('cb') : ''}
              </>
            ),
          },
          {
            type: CellType.Text,
            data: retourLine._embedded.retourReasons[retourLine.reason]?.shortLabel,
          },
          {
            type: CellType.Text,
            data: (
              <IsbnLink
                orderLine={{
                  ...retourLine,
                  titleId: getIdFromUrl(retourLine._embedded.title._links.self.href),
                }}
              />
            ),
          },
          {
            type: CellType.Text,
            data: (
              <Box width={240}>
                <Tooltip
                  placement="top-start"
                  title={retourLine._embedded.title.title}
                  enterDelay={500}
                >
                  <Typography noWrap>{retourLine._embedded.title.title}</Typography>
                </Tooltip>
              </Box>
            ),
          },
          {
            type: CellType.Text,
            data: (
              <Box textAlign="center">
                <Typography>{retourLine.quantity}</Typography>
              </Box>
            ),
          },
          {
            type: CellType.Text,
            data: (
              <Box textAlign="center">
                {retourLine.comments && <DeliveryNoteIcon note={retourLine.comments} />}
              </Box>
            ),
          },
        ],
      };
    }),
  };

  return (
    <>
      <Grid container justifyContent="flex-end">
        <Grid item style={{ marginTop: -60 }}>
          <ExportButton url={url} size="small" disabled={!pagedRetourLines?.total}>
            {t('retour_lines_export')}
          </ExportButton>
        </Grid>
      </Grid>
      <RetourHistoryFilter url={url} fetchRetours={fetchRetours} />
      <Table
        data={data}
        headers={headers}
        pagedResource={pagedRetourLines}
        loading={isLoading}
        dispatchFn={fetchRetours}
      />
    </>
  );
};
