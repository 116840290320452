import React from 'react';
import MaskedInput from 'react-text-mask';
import { isValidLegalDepotNumber, legalDepotNumberRegex } from '../../domain';

interface TextMaskCustomProps {
  inputRef: (ref: HTMLInputElement | null) => void;
}

const LegalDepotNumberMask = (props: TextMaskCustomProps) => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={(rawValue) => {
        const rest = isValidLegalDepotNumber(rawValue)
          ? rawValue.replace(legalDepotNumberRegex, '')
          : '';

        return ['D', '/', /\d/, /\d/, /\d/, /\d/, '/', ...Array(rest.length).fill(/./)];
      }}
      placeholderChar="_"
      showMask
    />
  );
};

export default LegalDepotNumberMask;
