import React, { FC, ReactNode, useEffect } from 'react';
import { useFetch } from '@react-redux-fetch/hooks';
import { getAviLevelsRequest } from '../../api';
import { useSelector } from 'react-redux';
import { getAviLevels } from '../../selectors';
import BaseReactSelectField, {
  BaseReactSelectFieldProps,
} from '../../../../../../components/Form/BaseReactSelectField';
import TargetAudienceField from '../../components/sections/TargetAudienceField';
import { AviLevel as AviLevelModel } from '../../../../../../config/api/models/dataSets';
import { useIsVisible } from 'react-is-visible';
import MuiTextField, { TextFieldProps } from '../../../../../../components/Form/TextField';
import { Field as FinalFormField, FieldProps } from 'react-final-form';

type Props = Partial<BaseReactSelectFieldProps> & {
  url: string;
  fieldName: 'aviLevelsOld' | 'aviLevelsNew';
  disabled?: boolean;
  label: ReactNode;
};

const AviLevel: FC<Props> = ({ url, fieldName, disabled, label, ...other }) => {
  const [, fetchAviLevels] = useFetch(getAviLevelsRequest);
  const aviLevels = useSelector(getAviLevels(fieldName));
  const nodeRef = React.useRef(null);
  const isVisible = useIsVisible(nodeRef);

  useEffect(() => {
    if (!aviLevels && isVisible) {
      fetchAviLevels(url, fieldName);
    }
  }, [isVisible, fetchAviLevels, url, fieldName, aviLevels]);

  if (!aviLevels) {
    return <div ref={nodeRef}>&nbsp;</div>;
  }

  if (disabled) {
    return (
      <TargetAudienceField
        name={fieldName}
        label={label}
        component={(props: FieldProps<any, any> & TextFieldProps) => {
          return (
            <FinalFormField
              component={(props: TextFieldProps) => {
                const selectedCodes = props.input?.value
                  ?.map?.((value: { code: string }) => value.code)
                  .join(', ');

                return <MuiTextField {...props} input={{ ...props.input, value: selectedCodes }} />;
              }}
              {...props}
            />
          );
        }}
        disabled
      />
    );
  }

  return (
    <TargetAudienceField
      component={BaseReactSelectField}
      label={label}
      options={aviLevels || []}
      isMulti
      getOptionLabel={(aviLevel: AviLevelModel) => aviLevel.code}
      getOptionValue={(aviLevel: AviLevelModel) => aviLevel}
      isClearable
      {...other}
      name={fieldName}
    />
  );
};

export default AviLevel;
