import React, { FC } from 'react';
import { RouteComponentProps } from 'react-router';
import ConstrainedPageContent from '../../../../../components/ConstrainedPageContent';
import TitleForm from '../containers/TitleForm';

type Props = RouteComponentProps<
  {},
  {},
  { path: string; gtin13: string; copyFromId?: string } | undefined
>;

const NewTitlePage: FC<Props> = ({ match, history, location }) => {
  return (
    <ConstrainedPageContent flex>
      <TitleForm
        navigate={history.push}
        previousPath={location.state?.path}
        gtin13={location.state?.gtin13}
        titleId={location.state?.copyFromId}
        isCopy={Boolean(location.state?.copyFromId)}
      />
    </ConstrainedPageContent>
  );
};
export default NewTitlePage;
