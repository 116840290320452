import { FetchConfig } from '@react-redux-fetch/core';
import { Data } from '../../config/store/types';
import apiRoutes from '../../config/api/routes';

export const getNursRequest = (url: string): FetchConfig<Data> => ({
  url: apiRoutes.create(url),
  repository: {
    nurs: (prev, next) => next,
  },
});

export const getThemasRequest = (url: string): FetchConfig<Data> => ({
  url: apiRoutes.create(url),
  repository: {
    themas: (prev, next) => next,
  },
});

export const getThemaQualifiersRequest = (url: string): FetchConfig<Data> => ({
  url: apiRoutes.create(url),
  repository: {
    themaQualifiers: (prev, next) => next,
  },
});
