import { Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ConstrainedPageContent from '../../../components/ConstrainedPageContent';
import Header from '../../../components/Header';
import Multiline from '../../../components/Multiline';
import { OrderReplacements } from '../components/orderReplacements';

const OrderReplacementsPage = () => {
  const { t } = useTranslation();

  return (
    <ConstrainedPageContent center={false}>
      <Header title={t('page_title_order_replacements')} />
      <Typography>
        <Multiline>{t('page_subtitle_order_replacements')}</Multiline>
      </Typography>
      <OrderReplacements />
    </ConstrainedPageContent>
  );
};

export default OrderReplacementsPage;
