import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { Badge, IconButton } from '@mui/material';
import { useFetch } from '@react-redux-fetch/hooks';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, LinkProps } from 'react-router-dom';
import useApiRoute from '../../../../config/api/useApiRoute';
import ROUTE_KEY from '../../../../config/routes/routeKeys';
import usePath from '../../../../config/routes/usePath';
import { getShoppingCartRequest } from '../api';
import { getNumberOfProducts } from '../domain';
import { getShoppingCart } from '../selectors';
import ItemAddedNotification from './ItemAddedNotification';

const CartSummary = () => {
  const { t } = useTranslation();
  const clientUrlToShoppingCart = usePath(ROUTE_KEY.SHOPPING_CART);
  const url = useApiRoute('shoppingCart');
  const [cartFetch, fetchCart] = useFetch(getShoppingCartRequest);
  const shoppingCart = useSelector(getShoppingCart);

  useEffect(() => {
    if (url && !cartFetch) {
      fetchCart(url);
    }
  }, [url, cartFetch, fetchCart]);

  if (!cartFetch) {
    return null;
  }

  const total = getNumberOfProducts(shoppingCart);

  const BtnLink = React.forwardRef<HTMLAnchorElement, LinkProps>((linkProps, ref) => (
    <Link
      innerRef={ref}
      {...linkProps}
      to={clientUrlToShoppingCart}
      children={linkProps.children}
    />
  ));

  return (
    <div>
      <IconButton
        aria-label={t('page_title_shopping_cart')}
        color="inherit"
        // @ts-ignore
        component={BtnLink ? BtnLink : 'button'}
        size="large"
      >
        <Badge badgeContent={total} color="primary">
          <ShoppingCartIcon fontSize="large" />
        </Badge>
      </IconButton>
      <ItemAddedNotification />
    </div>
  );
};
export default CartSummary;
