import { Grid } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Field from '../../../../../../components/Form';
import InfoTooltip from '../../../../../../components/InfoTooltip';
import Fieldset from '../Fieldset';
import FieldsetTitle from '../FieldsetTitle';
import SectionWrapper from '../SectionWrapper';
import EducationField from './EducationField';

const Education = () => {
  const { t } = useTranslation();

  return (
    <SectionWrapper id="education">
      <FieldsetTitle>{t('title_section_education')}</FieldsetTitle>
      <Fieldset>
        <Grid container spacing={1}>
          <Grid xs={12} item>
            <EducationField
              component={Field}
              label={
                <>
                  {t('title_part_title')}{' '}
                  <InfoTooltip title={t('title_part_title_tooltip')} inline />
                </>
              }
              type="text"
              name="partTitle"
            />
          </Grid>
          <Grid xs={12} item>
            <EducationField
              component={Field}
              label={
                <>
                  {t('title_section_title')}{' '}
                  <InfoTooltip title={t('title_section_title_tooltip')} inline />
                </>
              }
              type="text"
              name="sectionTitle"
            />
          </Grid>
        </Grid>
      </Fieldset>
    </SectionWrapper>
  );
};

export default Education;
