import security from '../../security';
import { container, buildActionsFromMappings } from 'react-redux-fetch';
import hasPath from 'ramda/es/hasPath';
import apiRoutes from '../../../config/api/routes';
import { Store } from 'redux';

type LoginAction = ReturnType<typeof security.actions.login>;

const hasToken = hasPath(['payload', 'security', 'token']);

const registerAuthHeader = (token: string) =>
  container.registerRequestHeader('Authorization', `bearer ${token}`);

const setBearerAndFetchUser = (store: any) => (next: any) => (action: any) => {
  next(action);

  function fetchLoggedInUser() {
    const actions = buildActionsFromMappings([
      {
        resource: 'user',
        request: {
          url: apiRoutes.user(),
        },
      },
    ]);
    (store as Store).dispatch(actions.userGet());
  }

  function handleLogin(token: string) {
    if (!token) {
      return;
    }

    registerAuthHeader(token);
    fetchLoggedInUser();
  }

  function handleLogout() {
    registerAuthHeader('');
  }

  switch (action.type) {
    case security.actionTypes.LOGGED_IN:
      handleLogin((action as LoginAction).payload.token);
      break;
    case security.actionTypes.LOGGED_OUT:
      handleLogout();
      break;
    case 'persist/REHYDRATE':
      if (action.payload !== undefined && hasToken(action)) {
        handleLogin(action.payload.security.token);
      }
      break;
  }
};

export default setBearerAndFetchUser;
