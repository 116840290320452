import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { styled } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import Button from '../../../../../../components/Button';
import ImageUploadPlaceholder from '../../../../../../components/ImageUploadPlaceholder';
import useDeleteMedia from '../../hooks/useDeleteMedia';
import useMediaUpload from '../../hooks/useMediaUpload';
import useRenameBeforeUpload from '../../hooks/useRenameBeforeUpload';

type Props = {
  imgSrc?: string;
  removeUrl?: string;
  alt: string;
  fileName: string;
  onPollForChanges: () => void;
  setFileSrc: (src: string) => void;
};

const Root = styled('div')(() => ({
  position: 'relative',
  paddingTop: 16,
  paddingRight: 16,
  marginRight: -16,
  marginTop: -16,
}));
const IconButton = styled(Button)(() => ({
  position: 'absolute',
  right: -4,
  top: -4,
}));

const ImageMediaFile = ({
  imgSrc,
  removeUrl,
  alt,
  fileName,
  onPollForChanges,
  setFileSrc,
}: Props) => {
  if (process.env.NODE_ENV === 'development') {
    imgSrc = imgSrc?.replace(':3000', '');
  }
  const [showDeleteBtn, setShowDeleteButton] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [deleteMediaFetch, deleteMedia] = useDeleteMedia(removeUrl);
  const beforeUpload = useRenameBeforeUpload({ fileName, onload: setFileSrc });
  const { getRootProps, getInputProps, isDragActive } = useMediaUpload({
    beforeUpload: (...args) => {
      onPollForChanges();
      return beforeUpload(...args);
    },
    accept: '.jpg,.jpeg,.png,.gif,.webp',
  });

  useEffect(() => {
    if (
      deleting &&
      deleteMediaFetch &&
      deleteMediaFetch.fulfilled &&
      deleteMediaFetch.meta.removeUrl === removeUrl
    ) {
      setFileSrc('');
      setDeleting(false);
    }
  }, [deleting, removeUrl, setFileSrc, deleteMediaFetch]);

  return (
    <Root
      // @ts-ignore
      onMouseEnter={() => setShowDeleteButton(true)}
      // @ts-ignore
      onMouseLeave={() => setShowDeleteButton(false)}
    >
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        <ImageUploadPlaceholder isDragActive={isDragActive}>
          {imgSrc && <img alt={alt} src={imgSrc} width="100%" />}
        </ImageUploadPlaceholder>
      </div>
      {showDeleteBtn && imgSrc && removeUrl && (
        <IconButton
          error
          round
          size="small"
          onClick={() => {
            setDeleting(true);
            deleteMedia();
          }}
        >
          <DeleteForeverIcon />
        </IconButton>
      )}
    </Root>
  );
};

export default ImageMediaFile;
