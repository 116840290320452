import { relationFields } from '../../domain';
import React from 'react';

type Props = {
  [key: string]: any;
  name: keyof { [key in typeof relationFields[number]]: true };
};

const RelationField = ({ component: Component, ...other }: Props) => <Component {...other} />;

export default RelationField;
