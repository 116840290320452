import { Box, Divider, Typography } from '@mui/material';
import groupBy from 'lodash/groupBy';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { RetourLine } from '../../../../config/store/retour';
import { UnconfirmedRetourLinesForDistributor } from './UnconfirmedRetourLinesForDistributor';

type Props = {
  retourLines: RetourLine[];
};

export const UnconfirmedRetourLines: FC<Props> = ({ retourLines }) => {
  const { t } = useTranslation();

  const retourLinesByDist: Record<string, typeof retourLines> = groupBy(retourLines, 'distributor');

  return (
    <>
      <Box mb={2}>
        <Divider />
      </Box>
      <Box mb={2}>
        <Typography variant="h3" gutterBottom>
          {t('return_lines_title')}
        </Typography>
      </Box>

      {Object.entries(retourLinesByDist).map(([distributorId, retourLinesByDistributor]) => (
        <UnconfirmedRetourLinesForDistributor
          key={distributorId}
          retourLines={retourLinesByDistributor}
        />
      ))}
    </>
  );
};
