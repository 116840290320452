import { Theme } from '@mui/material';
import { styled } from '@mui/styles';

type SC = {
  theme: Theme;
};

export const OverlayRoot = styled('div')(({ theme }: SC) => ({
  marginTop: theme.spacing(2),
  position: 'relative',
}));

export const Overlay = styled('div')(() => ({
  position: 'absolute',
  background: 'rgba(255,255,255,0.4)',
  top: 0,
  width: '100%',
  height: '100%',
  display: 'flex',
  'align-items': 'center',
  'justify-content': 'center',
}));
