export const EXTERNAL_LINKS = {
  home: 'https://www.meta4books.be/boekenbank',
  contact: 'https://www.meta4books.be/contactinfo',
  'meta-data': 'https://www.meta4books.be/metadata-afnemen',
  'isbn-info': 'https://www.meta4books.be/isbn',
  'terms-of-service': 'https://www.meta4books.be/gebruikersvoorwaarden',
  'privacy-declaration': 'https://www.meta4books.be/privacy',
  about: 'https://www.meta4books.be/boekenbank',
  'm4b-home': 'https://www.meta4books.be',
  'regulated-book-price': 'https://www.boekenprijs.be/over-site',
  documentation: 'https://documentation.boekenbank.be',
};
