import { Theme } from '@mui/material';
import { WithStyles, createStyles, withStyles } from '@mui/styles';
import clsx from 'clsx';
import React, { ReactNode } from 'react';
import { RouteComponentProps } from 'react-router';
import { NestedClientRoute } from '../../../config/routes/types';
import Nav from './Nav/index';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    mainWrapper: {
      flex: 1,
    },
    mainWrapperWithToolbar: {
      paddingTop: theme.toolbar.height,
    },
    main: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.default,
    },
    mainPadded: {
      padding: theme.spacing(3),
    },
  });

type Props = WithStyles<typeof styles> &
  RouteComponentProps & {
    children: ReactNode;
    clientRoutes: NestedClientRoute[];
    isAuthenticated: boolean;
    isHomePage: boolean;
  };

const Main = ({ classes, children, match, clientRoutes, isAuthenticated, isHomePage }: Props) => {
  return (
    <div className={classes.root}>
      {/* @ts-ignore Error since upgrade to TS 4.5.5 */}
      {isAuthenticated && <Nav match={match} clientRoutes={clientRoutes} />}
      <div className={clsx(classes.mainWrapper, !isHomePage && classes.mainWrapperWithToolbar)}>
        <main className={clsx(classes.main, !isHomePage && classes.mainPadded)}>{children}</main>
      </div>
    </div>
  );
};

export default withStyles(styles)(Main);
