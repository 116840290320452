import { RootState } from '../../../config/store/types';
import { NotificationInfo, ShoppingCart } from '../../../config/api/models/shop';
import { Department, Maybe } from '../../../config/api/types';

export const getShoppingCart = (state: RootState): Maybe<ShoppingCart> => state.data.shoppingCart;

export const getShoppingCartAlert = (state: RootState): Maybe<NotificationInfo> =>
  state.data.shoppingCartAlert;

export const getUserDepartment = (state: RootState): Maybe<Department> => state.data.userDepartment;
