import { Add } from '@mui/icons-material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../../../../components/Button';
import { Award } from '../../../../../../config/api/types';
import { FormValues } from '../../domain';

const newAward: Award = {
  literaturePrize: {
    id: '',
    name: '',
  },
  year: '',
  result: {
    result: '',
    code: '01',
  },
};

type Props = {
  onChange: (name: keyof FormValues, value?: any) => void;
  name: string;
  formNewAwards: Partial<Award>[];
};
const AddAwardsField: FC<Props> = ({ onChange, formNewAwards }) => {
  const { t } = useTranslation();

  return (
    <Button
      size="small"
      variant="text"
      onClick={() => {
        onChange('awards', [
          ...formNewAwards,
          {
            ...newAward,
          },
        ]);
      }}
      style={{ marginTop: 8 }}
    >
      <Add /> {t(formNewAwards.length ? 'title_add_another_award_btn' : 'title_add_award_btn')}
    </Button>
  );
};

export default AddAwardsField;
