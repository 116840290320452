import { Box, Divider, Grid, Typography } from '@mui/material';
import { useFetch } from '@react-redux-fetch/hooks';
import moment from 'moment';
import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import DelayedLoader from '../../../components/DelayedLoader';
import Header from '../../../components/Header';
import useApiRoute from '../../../config/api/useApiRoute';
import ExportButton from '../../core/exports';
import { Label, Row, Value } from './Atoms';
import RetourDetailTable from './RetourDetailTable';
import { getRetourRequest } from './api';
import { createGetRetour } from './selectors';

type Props = {
  retourId: string;
  goBackPath?: string;
};
export const RetourDetail: FC<Props> = ({ retourId, goBackPath }) => {
  const { t } = useTranslation();
  const [retourFetch, fetchRetour] = useFetch(getRetourRequest);
  const retourRef = useApiRoute('retours', retourId) || '';
  const retour = useSelector(createGetRetour)(retourId);

  useEffect(() => {
    if (retourRef) {
      fetchRetour(retourRef);
    }
  }, [fetchRetour, retourRef]);

  if (!retourRef) {
    return <Redirect to="/" />;
  }

  return (
    <DelayedLoader data={retour}>
      {retour ? (
        <>
          <Header title={t('page_title_retour_detail')} goBackPath={goBackPath} goBack />

          <Grid container justifyContent="flex-end">
            <Grid item>
              <ExportButton url={retourRef} size="small" disabled={retourFetch?.pending}>
                {t('retour_export')}
              </ExportButton>
            </Grid>
          </Grid>

          <Box marginTop={3}>
            <Grid container justifyContent="space-between">
              <Grid item xs={9}>
                <Row>
                  <Label>{t('table_headers_retour_reference')}:</Label>
                  <Value>{retour.reference}</Value>
                </Row>
                <Row>
                  <Label>{t('table_headers_confirmed_at')}:</Label>
                  <Value>
                    {moment(retour.confirmedAt).format(`DD/MM/YYYY [${t('at')}] HH:mm`)}
                  </Value>
                </Row>
              </Grid>
            </Grid>
          </Box>

          <Box marginTop={3}>
            <Grid container spacing={1}>
              <Grid item style={{ width: 120 }}>
                <Typography variant="subtitle2">{t('table_headers_isbn')}</Typography>
              </Grid>
              <Grid item style={{ flex: 1 }}>
                <Typography variant="subtitle2">{t('table_headers_title')}</Typography>
              </Grid>
              <Grid item style={{ textAlign: 'center', width: 80 }}>
                <Typography variant="subtitle2">{t('table_headers_num_pieces')}</Typography>
              </Grid>
              <Grid item style={{ width: 200 }}>
                <Typography variant="subtitle2">{t('table_headers_distributor')}</Typography>
              </Grid>
              <Grid item style={{ width: 200 }}>
                <Typography variant="subtitle2">{t('table_headers_line_reference')}</Typography>
              </Grid>
              <Grid item style={{ width: 120 }}>
                <Typography variant="subtitle2">{t('table_headers_reason')}</Typography>
              </Grid>
              <Grid item style={{ width: 80, textAlign: 'center' }}>
                <Typography variant="subtitle2">{t('table_headers_note')}</Typography>
              </Grid>
            </Grid>
            <Box marginTop={1} marginBottom={1}>
              <Divider />
            </Box>
            <RetourDetailTable lines={retour.lines} />
          </Box>
        </>
      ) : null}
    </DelayedLoader>
  );
};
