import React from 'react';
import { SelectField as Select } from '../../../../../components/Form';
import { SelectFieldProps } from '../../../../../components/Form/SelectField';
import { useTranslation } from 'react-i18next';

type Props = {
  onChange: SelectFieldProps['onChange'];
  initialValue?: SelectFieldProps['value'];
};

const StatusFilter = ({ onChange, initialValue }: Props) => {
  const { t } = useTranslation();

  const items = {
    true: t('filter_option_active'),
    false: t('filter_option_inactive'),
  };

  return (
    <Select items={items} onChange={onChange} label={t('filter_status')} value={initialValue} />
  );
};

export default StatusFilter;
