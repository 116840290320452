import useApiRoute from '../../../config/api/useApiRoute';
import { useFetch } from '@react-redux-fetch/hooks';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getNursRequest } from '../api';
import { selectNurs } from '../selectors';

type Args = {
  initiate?: boolean;
};
export const useNurs = ({ initiate }: Args = { initiate: true }) => {
  const nursApiRoute = useApiRoute('titleNurs');
  const url = nursApiRoute || '';
  const [, fetchNurs] = useFetch(getNursRequest);
  const nurs = useSelector(selectNurs);

  useEffect(() => {
    if (!nurs && initiate) {
      fetchNurs(url);
    }
  }, [initiate, nurs, fetchNurs, url]);

  return {
    nurs,
    hasAccess: Boolean(nursApiRoute),
  };
};
