import { useSnackbar } from 'notistack';
import filter from 'ramda/es/filter';
import forEach from 'ramda/es/forEach';
import keys from 'ramda/es/keys';
import { useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RootState } from '../../../../config/store/types';
import { Actions } from '../actions';
import { State as AlertState } from '../model';
import { getAlerts } from '../selectors';

type MappedState = {
  alerts: AlertState;
};
type MappedDispatch = {
  closeAlert: typeof Actions.closeAlert;
};
type Props = MappedState & MappedDispatch;

// class Alert extends Component<Props> {
//   displayed = [] as any[];

//   storeDisplayed = (id: number) => {
//     this.displayed = [...this.displayed, id];
//   };

//   removeDisplayed = (id: number) => {
//     this.displayed = filter((key) => id !== key)(this.displayed);
//     this.props.closeAlert(id);
//   };

//   shouldComponentUpdate(newProps: Props) {
//     const { alerts } = this.props;
//     const newAlerts = newProps.alerts.messages;
//     let notExists = false;
//     forEach((key: number) => {
//       notExists = notExists || !has(key)(alerts.messages);
//     })(keys(newAlerts));
//     return notExists;
//   }

//   componentDidUpdate() {
//     const { alerts } = this.props;
//     forEach((key: number) => {
//       if (!this.displayed.includes(key)) {
//         const alert = alerts.messages[key];
//         // @ts-ignore
//         this.props.enqueueSnackbar(this.props.t(alert.message), {
//           variant: alert.variant,
//           onClose: () => this.removeDisplayed(key),
//         });
//         this.storeDisplayed(key);
//         return;
//       }
//     })(keys(alerts.messages));
//   }

//   render() {
//     return null;
//   }
// }

const Alert: React.FC<Props> = ({ alerts, closeAlert }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const displayedRef = useRef<number[]>([]);

  const storeDisplayed = (id: number) => {
    displayedRef.current = [...displayedRef.current, id];
  };

  const removeDisplayed = useCallback(
    (id: number) => {
      displayedRef.current = filter((key) => id !== key, displayedRef.current);
      closeAlert(id);
    },
    [closeAlert]
  );

  useEffect(() => {
    forEach((key: number) => {
      if (!displayedRef.current.includes(key)) {
        const alert = alerts.messages[key];
        enqueueSnackbar(t(alert.message), {
          variant: alert.variant,
          onClose: () => removeDisplayed(key),
        });
        storeDisplayed(key);
      }
    })(keys(alerts.messages));
  }, [alerts, enqueueSnackbar, removeDisplayed, t]);

  return null;
};

const mapState = (state: RootState): MappedState => ({
  alerts: getAlerts(state),
});

const mapDispatch: MappedDispatch = {
  closeAlert: Actions.closeAlert,
};

export default connect(mapState, mapDispatch)(Alert);
