import { Grid, Theme, Typography } from '@mui/material';
import Divider from '@mui/material/Divider';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../../../components/Button';
import SubTitle from '../../../../../components/SubTitle';
import { DistributorLine as DistributorLineModel } from '../../../../../config/api/models/shop';
import formatEuro from '../../../../../helpers/formatEuro';
import CartLine from './CartLine';
import LastColumn from './LastColumn';

type Props = {
  distributorLine: DistributorLineModel;
  onOpenDialogInfo: (
    confirmationUrl: string,
    distributorLines?: DistributorLineModel[],
    userLines?: Record<string, DistributorLineModel>
  ) => void;
  disabled?: boolean;
  onInvalidLine: (titleId: string, isInvalid: boolean) => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  distributorLine: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  distributorTitle: {
    marginBottom: theme.spacing(1),
  },
  distributorTotal: {
    marginTop: theme.spacing(1),
  },
}));

const DistributorLine = ({ distributorLine, onOpenDialogInfo, disabled, onInvalidLine }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <>
      <div className={classes.distributorLine}>
        <Grid
          container
          sx={{ justifyContent: 'space-between', alignItems: 'center' }}
          className={classes.distributorTitle}
        >
          <Grid item>
            <SubTitle>
              {t(distributorLine.isCbMember ? 'cart_order_at_cb' : 'cart_order_at')}{' '}
              <strong>{distributorLine._embedded.organisation.name}</strong>
            </SubTitle>
          </Grid>
        </Grid>
        <Divider />
        {distributorLine?.lines?.map((cartLine, index) => (
          <React.Fragment key={`${cartLine._links.shoppingCartLine.href}${index}`}>
            <CartLine cartLine={cartLine} onInvalidLine={onInvalidLine} />
            <Divider />
          </React.Fragment>
        ))}
        <Grid container className={classes.distributorTotal} spacing={1} alignItems="center">
          <Grid
            item
            style={{
              flex: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Typography align="right">
              {t('cart_distributor_total')}{' '}
              <strong>{formatEuro(distributorLine.resaleValue)}</strong>
            </Typography>
          </Grid>
          <LastColumn style={{ textAlign: 'right' }}>
            <Button
              size="small"
              onClick={() => {
                onOpenDialogInfo(distributorLine._links.distributorGroupConfirmation.href, [
                  distributorLine,
                ]);
              }}
              disabled={disabled}
            >
              {t('cart_place_order')}
            </Button>
          </LastColumn>
        </Grid>
      </div>
    </>
  );
};

export default DistributorLine;
