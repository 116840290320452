import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { Title } from '../../../../../config/api/types';
import { getBackCover, getFrontCover, getSampleContent } from '../../../domain';
import Covers from './Covers';
import SampleContent from './SampleContent';

const useStyles = makeStyles((theme: Theme) => ({
  titleMedia: { marginBottom: theme.spacing(2) },
}));

type Props = {
  title: Title;
};

const TitleMedia = ({ title }: Props) => {
  const classes = useStyles();
  const frontCover = getFrontCover(title);
  const backCover = getBackCover(title);
  const sampleContent = getSampleContent(title);

  return (
    <div className={classes.titleMedia}>
      <Covers frontCover={frontCover} backCover={backCover} caption={title.title} />
      <SampleContent sampleContent={sampleContent} />
    </div>
  );
};

export default TitleMedia;
