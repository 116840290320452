import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Table from '../../../../components/Table';
import { Maybe, PublishersList } from '../../../../config/api/types';
import { CellType, DataType } from '../../../../components/Table/types';
import { getCountryNameByShortName } from '../../../../config/data/countries';
import OrganisationDialog from '../../containers/OrganisationDialog';
import { getLink } from '../../../../helpers/hateoas';

type Props = {
  publishers: Maybe<PublishersList>;
  fetchPublishers: (url: string) => void;
};

export const PublishersTable: FC<Props> = ({ publishers, fetchPublishers }) => {
  const { t } = useTranslation();
  const headers = [
    { id: 'publisher', label: t('table_headers_publisher') },
    { id: 'country', label: t('table_headers_country') },
    { id: 'prefixes', label: t('table_headers_prefixes') },
    { id: 'organisationNumber', label: t('table_headers_organisationNumber') },
  ];

  if (!publishers) {
    return null;
  }

  const data: DataType = {
    resource: 'publishers',
    rows: publishers._embedded.items.map((publisher) => {
      return {
        id: publisher.publisherId,
        cells: [
          {
            type: CellType.Text,
            data: (
              <OrganisationDialog organisationUrl={getLink(publisher, 'organisation') || ''}>
                {publisher.organisation.name}
              </OrganisationDialog>
            ),
          },
          {
            type: CellType.Text,
            data: getCountryNameByShortName(publisher.organisation.countryIso),
          },
          {
            type: CellType.Array,
            data: publisher.prefixes,
          },
          {
            type: CellType.Text,
            data: publisher.organisation.meta4BooksId,
          },
        ],
      };
    }),
  };

  return (
    <Table data={data} headers={headers} pagedResource={publishers} dispatchFn={fetchPublishers} />
  );
};
