import { useFetch } from '@react-redux-fetch/hooks';
import apiRoutes from '../../../config/api/routes';
import { useSelector } from 'react-redux';
import security from '../../security';
import { useEffect } from 'react';
import { FetchConfig } from '@react-redux-fetch/core';
import { Data, RootState } from '../../../config/store/types';

export const getRoutesRequest = (): FetchConfig<Data> => ({
  url: apiRoutes.routes(),
  requestKey: 'apiRoutes',
  repository: {
    routes: (prev, next) => next,
  },
});

export const selectApiRoutes = (state: RootState) => state.data.routes;

const useFetchApiRoutes = () => {
  const isAuthenticated = useSelector(security.selectors.isAuthenticated);
  const impersonate = useSelector(security.selectors.getImpersonate);
  const apiRoutes = useSelector(selectApiRoutes);
  const [apiRoutesFetch, fetchApiRoutes] = useFetch(getRoutesRequest);

  useEffect(() => {
    fetchApiRoutes();
  }, [isAuthenticated, fetchApiRoutes, impersonate]);

  return { apiRoutes, isPending: !apiRoutesFetch || apiRoutesFetch.pending };
};

export default useFetchApiRoutes;
