import NotificationsIcon from '@mui/icons-material/Notifications';
import { IconButton } from '@mui/material';
import Badge from '@mui/material/Badge';
import { useFetch } from '@react-redux-fetch/hooks';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { AppNotification } from '../../../config/api/models/notifications';
import useApiRoute from '../../../config/api/useApiRoute';
import security from '../../security';
import { deleteNotificationRequest, patchNotificationRequest } from '../api';
import { useNotificationsCountersFetch } from '../hooks/useNotificationsCountersFetch';
import { useNotificationsFetch } from '../hooks/useNotificationsFetch';
import { OrderFor, getNotifications, getNotificationsCounters } from '../selectors';
import NotificationsMenu from './NotificationsMenu';

const DELAY = parseInt(process.env.REACT_APP_NOTIFICATION_INTERVAL ?? '3000');
const REGEX = /\([0-9]*\)/;

const Notifications = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [page, setPage] = useState(1);
  const ref = useRef<{ redirectPath: string | null }>({ redirectPath: null });
  const { push } = useHistory();
  const userRoles = useSelector(security.selectors.getUserRole) || [];
  const isBookstore = userRoles.includes('ROLE_BOOKSTORE');
  const isDistributor = userRoles.includes('ROLE_DISTRIBUTOR');

  const [, fetchCounters] = useNotificationsCountersFetch(
    isBookstore ? OrderFor.BOOKSTORE : isDistributor ? OrderFor.DISTRIBUTOR : undefined
  );

  const counters = useSelector(getNotificationsCounters);
  const numNotifications = counters?.unread;

  const handleClick = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);
  const handleClose = () => {
    if (page > 1) {
      setPage(1);
      setLoadedNotifications([]);
    }
    setAnchorEl(null);
  };
  const url = useApiRoute('notifications');
  const [notificationsFetch, fetchNotifications] = useNotificationsFetch(
    url && !anchorEl ? DELAY : 0,
    undefined,
    url && !!anchorEl ? page : 1
  );
  const notifications = useSelector(getNotifications);
  const [loadedNotifications, setLoadedNotifications] = useState(
    notifications?._embedded.items ?? []
  );

  const [removeNotificationFetch, removeNotification] = useFetch(deleteNotificationRequest);
  const [patchNotificationFetch, patchNotification] = useFetch(patchNotificationRequest);
  const handleClearNotification = (notification: AppNotification) => {
    removeNotification(`${url}/${notification.id}`, notification.id);
    setLoadedNotifications((ns) => ns.filter(({ id }) => id !== notification.id));
    if (loadedNotifications.length <= 10) {
      setPage((page) => page + 1); // This will trigger another refetch for loading more notifications
    }
  };

  const handleReadNotification = (notification: AppNotification, redirectPath?: string) => {
    if (notification.readAt == null) {
      patchNotification(`${url}/${notification.id}`, notification.id);
      setLoadedNotifications((ns) =>
        ns.map((n) => ({ ...n, ...(notification.id === n.id && { readAt: 'read' }) }))
      ); //we don't have an exact datetime yet, but just for the purpose of showing it as read after clicking, we give readAt a random string value
      if (redirectPath) {
        ref.current.redirectPath = redirectPath;
      }
    } else {
      if (redirectPath) {
        push(redirectPath);
      }
    }
  };

  const handleLoadMore = () => {
    setPage((page) => page + 1);
  };

  useEffect(() => {
    // Niche case: When notifications menu is open and we redirect to another page, we want to reset the loaded notifications since the page will again be 1
    if (anchorEl != null && page === 1) {
      setLoadedNotifications(notifications?._embedded.items ?? []);
    }
  }, [anchorEl, page, notifications]);

  useEffect(() => {
    if (patchNotificationFetch?.fulfilled) {
      if (ref.current.redirectPath) {
        push(ref.current.redirectPath);
      }
      fetchCounters();
    }
  }, [patchNotificationFetch, push, fetchCounters]);

  useEffect(() => {
    // Page changed, load more notifications
    if (notifications && notifications.page !== page && !notificationsFetch?.pending) {
      fetchNotifications();
    }
  }, [page, notifications, notificationsFetch, fetchNotifications]);

  useEffect(() => {
    // Add new incoming notifications to the rest of the loadedNotifications
    if (notificationsFetch?.fulfilled) {
      setLoadedNotifications((n) => _.unionBy(n, notifications?._embedded.items, 'id'));
    }
  }, [notificationsFetch, notifications]);

  useEffect(() => {
    if (numNotifications) {
      document.title = document.title.match(REGEX)
        ? document.title.replace(REGEX, `(${numNotifications})`)
        : `${document.title} (${numNotifications})`;
    } else {
      document.title = document.title.replace(REGEX, '');
    }
  }, [numNotifications]);

  useEffect(() => {
    fetchCounters();
  }, [fetchCounters]);

  if (!url) {
    return null;
  }

  return (
    <>
      <div onClick={handleClick}>
        <IconButton color="inherit" disabled={removeNotificationFetch?.pending} size="large">
          <Badge
            badgeContent={numNotifications}
            color={removeNotificationFetch?.pending ? 'default' : 'primary'}
          >
            <NotificationsIcon fontSize="large" />
          </Badge>
        </IconButton>
      </div>
      {anchorEl && notifications && loadedNotifications.length > 0 && (
        <NotificationsMenu
          anchorEl={anchorEl}
          onClose={handleClose}
          onClearNotification={handleClearNotification}
          onReadNotification={handleReadNotification}
          notifications={loadedNotifications}
          onLoadMore={handleLoadMore}
          hasMore={notifications.page < notifications.pages}
        />
      )}
    </>
  );
};

export default Notifications;
