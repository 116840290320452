import * as actionTypes from './actionTypes';
import { Actions } from './actions';
import PrivateRoute from './components/PrivateRoute';
import PublicRoute from './components/PublicRoute';
import securityReducer from './reducer';
import ActivatePage from './scenes/ActivatePage';
import ForgotPasswordPage from './scenes/ForgotPasswordPage';
import HomePage from './scenes/HomePage';
import * as selectors from './selectors';

const security = {
  components: {
    PublicRoute,
    PrivateRoute,
  },
  scenes: {
    Login: HomePage,
    Activate: ActivatePage,
    ForgotPassword: ForgotPasswordPage,
  },
  reducer: securityReducer,
  selectors,
  actions: Actions,
  actionTypes,
};

export default security;
