import React, { FC } from 'react';
import { RouteComponentProps } from 'react-router';
import OrganisationWrapper from '../containers/OrganisationWrapper';
import DataConsumerForm from '../containers/dataConsumer/DataConsumerForm';
import usePath from '../../../../config/routes/usePath';
import ROUTE_KEY from '../../../../config/routes/routeKeys';

type Params = { id: string };
type Props = RouteComponentProps<Params> & {};

const DataConsumer: FC<Props> = (props) => {
  const path = usePath(ROUTE_KEY.ADMIN_ORG_EDIT, { id: props.match.params.id });

  return (
    <OrganisationWrapper {...props}>
      {({ organisation }) => (
        <DataConsumerForm
          goToOrganisation={() => props.history.push(path)}
          organisation={organisation}
        />
      )}
    </OrganisationWrapper>
  );
};

export default DataConsumer;
