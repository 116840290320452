import apiRoutes from '../../../../config/api/routes';
import { ApiConfigUnion } from '../../../../helpers/apiConfig';

export const ApiConfig = {
  getTitleFile: (url: string) => ({
    resource: 'titleFile',
    method: 'get',
    request: {
      url: apiRoutes.get(url),
      clearValueOnRequest: true,
    },
  }),
  getProcessResult: () => ({
    resource: 'processResult',
    method: 'get',
    request: (url: string) => ({
      url: apiRoutes.get(url),
      clearValueOnRequest: true,
    }),
  }),
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ApiConfig = ApiConfigUnion<typeof ApiConfig>;
