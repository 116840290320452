import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { Grid } from '@mui/material';
import React, { useEffect } from 'react';
import Button from '../../../../../../components/Button';
import SmallUploadPlaceholder from '../../../../../../components/SmallUploadPlaceholder';
import { Media } from '../../../../../../config/api/types';
import SampleContent from '../../../../detail/components/media/SampleContent';
import useDeleteMedia from '../../hooks/useDeleteMedia';
import useMediaUpload from '../../hooks/useMediaUpload';
import useRenameBeforeUpload from '../../hooks/useRenameBeforeUpload';

type Props = {
  mediaSample?: Media;
  fileName: string;
  onPollForChanges: () => void;
  setFileSrc: (src: string) => void;
};

const SampleMediaFile = ({ fileName, mediaSample, onPollForChanges, setFileSrc }: Props) => {
  const mediaSampleUrl =
    mediaSample && mediaSample._links && mediaSample._links.self && mediaSample._links.self.href;
  const [deleteMediaFetch, deleteMedia] = useDeleteMedia(mediaSampleUrl);

  const beforeUpload = useRenameBeforeUpload({ fileName, onload: setFileSrc });
  const { getRootProps, getInputProps, isDragActive } = useMediaUpload({
    beforeUpload: (...args) => {
      onPollForChanges();
      return beforeUpload(...args);
    },
    accept: '.pdf',
  });

  useEffect(() => {
    if (
      deleteMediaFetch &&
      deleteMediaFetch.fulfilled &&
      deleteMediaFetch.meta.removeUrl === mediaSampleUrl
    ) {
      setFileSrc('');
    }
  }, [setFileSrc, mediaSampleUrl, deleteMediaFetch]);

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item>
        <div {...getRootProps()}>
          <input {...getInputProps()} />
          <SmallUploadPlaceholder isDragActive={isDragActive} />
        </div>
      </Grid>
      <Grid item>
        <SampleContent sampleContent={mediaSample} />
      </Grid>
      <Grid item>
        {mediaSample && mediaSample.original && mediaSampleUrl && (
          <Button error round size="small" onClick={() => deleteMedia()}>
            <DeleteForeverIcon fontSize="small" />
          </Button>
        )}
      </Grid>
    </Grid>
  );
};

export default SampleMediaFile;
