import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { TableCell, Theme } from '@mui/material';
import { TableCellProps } from '@mui/material/TableCell';
import { WithStyles, createStyles, withStyles } from '@mui/styles';
import classNames from 'classnames';
import React from 'react';

const styles = (theme: Theme) =>
  createStyles({
    true: {
      color: theme.palette.messages.success,
    },
    false: {
      color: theme.palette.messages.error,
    },
    cell: {
      paddingLeft: 0,
      paddingRight: theme.spacing(2),
    },
  });

type Props = TableCellProps &
  WithStyles<typeof styles> & {
    data: boolean;
    className?: string;
    trueOnly?: boolean;
  };

const BooleanCell = ({ classes, data, trueOnly, className, ...other }: Props) => (
  <TableCell {...other} className={classNames(classes.cell, className)}>
    {data ? (
      <CheckIcon className={classes.true} />
    ) : trueOnly ? null : (
      <CloseIcon className={classes.false} />
    )}
  </TableCell>
);

export default withStyles(styles)(BooleanCell);
