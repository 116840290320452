import { FetchConfig } from '@react-redux-fetch/core';
import { Data } from '../../../config/store/types';
import apiRoutes from '../../../config/api/routes';
import { normalizePagedDistributorsRequest } from './dtoMapper';

export const getDistributorsRequest = (url: string, clearPrevValue = true): FetchConfig<Data> => {
  return {
    method: 'GET',
    url: apiRoutes.create(url),
    transform: normalizePagedDistributorsRequest,
    repository: {
      distributors: (prev, next) => {
        return clearPrevValue
          ? next
          : {
              ...prev,
              ...next,
            };
      },
    },
  };
};
