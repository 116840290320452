import * as React from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useIsVisible } from 'react-is-visible';
import BaseReactSelectField, {
  BaseReactSelectFieldProps,
} from '../../../../../../components/Form/BaseReactSelectField';
import InfoTooltip from '../../../../../../components/InfoTooltip';
import { Language, Languages } from '../../../../../../config/api/types';
import useFetch from '../../../../../../helpers/useFetch';
import { getLanguagesRequest } from '../../api';

type Props = Partial<BaseReactSelectFieldProps> & {
  url: string;
  required?: boolean;
  filterLanguages?: (languages?: Languages) => Languages;
  isBibliographicalVerified?: boolean;
  onClearValue?: () => void;
};

const LanguageField = ({
  url,
  required,
  filterLanguages,
  disabled,
  isBibliographicalVerified,
  ...other
}: Props) => {
  const { t } = useTranslation();
  const [languages, fetchLanguages] = useFetch<Languages>(getLanguagesRequest(url));
  const nodeRef = React.useRef(null);
  const isVisible = useIsVisible(nodeRef);

  useEffect(() => {
    if (!languages && isVisible) {
      fetchLanguages();
    }
  }, [isVisible, languages, fetchLanguages]);

  if (!languages) {
    return <div ref={nodeRef}>&nbsp;</div>;
  }

  return (
    <BaseReactSelectField
      label={
        <>
          {`${t('title_languages')}${required ? ' (*)' : ''}`}
          {isBibliographicalVerified && (
            <InfoTooltip title={t('title_isBibliographicalVerified_disabled_field')} inline />
          )}
        </>
      }
      name="languages"
      options={
        languages ? (filterLanguages ? filterLanguages(languages.value) : languages.value) : []
      }
      noOptionsMessage={() => t('search_no_options')}
      isMulti
      getOptionValue={(option: Language) => option.code}
      isDisabled={!languages || !languages.value || disabled || isBibliographicalVerified}
      {...other}
    />
  );
};

export default LanguageField;
