import React, { FC } from 'react';
import ConstrainedPageContent from '../../../components/ConstrainedPageContent';
import { RetourLinesHistory } from '../retourHistory';
import { RetourHistoryHeader } from './RetourHistoryPage';
import { RouteComponentProps } from 'react-router';

type Props = RouteComponentProps;
export const RetourLinesHistoryPage: FC<Props> = (props) => {
  return (
    <ConstrainedPageContent center={false}>
      <RetourHistoryHeader {...props} />
      <RetourLinesHistory />
    </ConstrainedPageContent>
  );
};
