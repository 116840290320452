import React, { useEffect, useState } from 'react';
import { Redirect, useLocation } from 'react-router';
import DelayedLoaderForFetch from '../../../../components/DelayedLoaderForFetch';
import useApiRoute from '../../../../config/api/useApiRoute';
import { getLink } from '../../../../helpers/hateoas';
import useFetch from '../../../../helpers/useFetch';
import { ApiConfig } from '../api';
import FundsTable from '../components/fundsList/FundsTable';

const FundsList = () => {
  const fundsPath = useApiRoute([
    'fundsDistributor',
    'fundsAdmin',
    'fundsBookstore',
    'fundsDataConsumer',
  ]);
  const [fundsFetch, dispatchFundsGet] = useFetch(ApiConfig.getFunds());
  const [initialRender, setInitialRender] = useState<boolean>(true);

  const locationParams = useLocation<{ keepFilters?: boolean }>();
  const keepFilters = locationParams.state?.keepFilters;

  const currentUrl = getLink(fundsFetch?.value, 'self');
  const url = keepFilters && currentUrl ? currentUrl : fundsPath;

  useEffect(() => {
    if (initialRender) {
      dispatchFundsGet(url);
      setInitialRender(false);
    }
  }, [url, dispatchFundsGet, initialRender]);

  if (!fundsPath) {
    return <Redirect to="/" />;
  }

  return (
    <DelayedLoaderForFetch fetchObj={fundsFetch}>
      {fundsFetch ? (
        <FundsTable fundsFetch={fundsFetch} dispatchFundsGet={dispatchFundsGet} />
      ) : null}
    </DelayedLoaderForFetch>
  );
};

export default FundsList;
