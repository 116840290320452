import { NoSsr, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { FocusEventHandler, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router';
import Select, { InputActionMeta, SelectInstance } from 'react-select';
import { StringParam, useQueryParam, withDefault } from 'use-query-params';
import ClearIndicator from '../../../../../components/Form/ReactSelectComponents/ClearIndicator';
import ROUTE_KEY from '../../../../../config/routes/routeKeys';
import usePath from '../../../../../config/routes/usePath';
import { getDetailLink } from '../../../domain';
import { Control } from './Control';
import { DropdownIndicator } from './DropdownIndicator';
import { Menu } from './Menu';
import Message from './Message';
import { Option } from './Option';
import { SelectOption, SelectValue } from './types';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingRight: 25,
    width: '100%',
    maxWidth: 680,
    minWidth: 400,
  },
  // input: {
  //   display: 'flex',
  //   padding: 11,
  // },
  // root: {
  //   flexGrow: 1,
  //   height: 250,
  // },
  input: {
    display: 'flex',
    padding: 0,
    height: 'auto',
    borderColor: theme.palette.text.primary,
    background: theme.palette.background.default,
    '& input': {
      paddingTop: '6px!important',
      paddingBottom: '6px!important',
      opacity: '1!important',
    },
  },
  noOptionsMessage: {
    padding: theme.spacing(1, 2),
    textAlign: 'center',
    color: theme.palette.text.helpertext,
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    // marginTop: theme.spacing(1)
    left: 0,
    right: 0,
  },
  searchIcon: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    display: 'flex',
  },
  icon: {
    color: theme.palette.text.primary,
  },

  itemContentContainer: { width: '85%' },
  itemTitle: {
    fontSize: 15,
    lineHeight: 1.4,
    fontWeight: 500,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  itemStatus: { fontSize: 15, lineHeight: 1.4, color: 'green' },
  itemInfo: { fontSize: 15, lineHeight: 1.4 },
}));

type Props = RouteComponentProps & {
  loadOptions: (inputValue: string) => void;
  options: SelectOption[];
  withMenu: boolean;
  isLoading: boolean;
  initialInputValue?: string;
  onFocus: FocusEventHandler;
  overrideValue?: string;
  onEnter?: () => void;
};

const SearchAutocomplete = ({
  loadOptions,
  options,
  withMenu,
  isLoading,
  initialInputValue = '',
  history,
  onFocus,
  overrideValue,
  onEnter,
}: Props) => {
  const classes = useStyles();
  const selectRef = useRef<SelectInstance<SelectOption>>(null);
  const detailPath = usePath(ROUTE_KEY.TITLE);
  const { t } = useTranslation();
  const [single, setSingle] = useState<SelectValue>(null);
  const [input, setInputValue] = useState(initialInputValue);
  const hasUsedArrowKeysRef = useRef<boolean>(false);
  const [, setQParamValue] = useQueryParam(
    'q',
    withDefault(StringParam, initialInputValue as string)
  );

  const [initialQ, setInitialQ] = useState(overrideValue); //We put this in a state because overrideValue may only be used once to populate the inputfield initially. After this we set initialQ to undefined

  const components = {
    Control,
    DropdownIndicator,
    Option,
    Menu,
    LoadingMessage: Message,
    NoOptionsMessage: Message,
    ClearIndicator,
  };

  function handleChangeSingle(value: SelectValue) {
    setSingle(value);
  }

  const handleClear = useCallback(() => {
    setInputValue('');
    loadOptions('');
  }, [loadOptions, setInputValue]);

  useEffect(() => {
    if (!single || !(single as SelectOption).title) {
      return;
    }

    const detailLink = getDetailLink(detailPath, (single as SelectOption).title);

    selectRef.current?.clearValue();
    handleClear();
    history.push(detailLink, { from: window.location.pathname });
  }, [handleClear, single, detailPath, history]);

  function handleInputChange(value: string, inputActionMeta: InputActionMeta) {
    if (selectRef.current) {
      selectRef.current.getFocusedOptionRef = () => null;
    }
    if (inputActionMeta.action === 'input-change') {
      hasUsedArrowKeysRef.current = false;
      setInputValue(value);
      loadOptions(value);
    }
  }

  function handleKeyDown(event: React.KeyboardEvent<HTMLDivElement>) {
    // Navigate to search page when enter is pressed without selecting an option with keyboard
    // @ts-ignore
    if (event.key === 'Enter' && overrideValue == null) {
      onEnter?.();
      if (hasUsedArrowKeysRef.current) {
        // handleChangeSingle will be fired, redirecting the user to the detail
      } else {
        history.push('/titels?q=' + encodeURIComponent(input.trim()));
      }
    }
    if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
      hasUsedArrowKeysRef.current = true;
    }
  }

  useEffect(() => {
    /* Check if we are redirected from 'snelzoeken'. In that case we will have a q param in our url which we'll use to populate the input field */
    if (initialQ !== undefined) {
      setInputValue(decodeURIComponent(initialQ));
      setInitialQ(undefined);
      return;
    }
    /* Move the input to the url with a delay of 200ms. This change in url will trigger a new api call for fetching titles */
    const timer = setTimeout(() => setQParamValue(encodeURIComponent(input)), 200);
    return () => clearTimeout(timer);
  }, [input, initialQ, setQParamValue]);

  const handleOnMenuClose = () => {
    hasUsedArrowKeysRef.current = false;
  };

  return (
    <div className={classes.root}>
      <NoSsr>
        <Select
          ref={selectRef}
          inputValue={input}
          onInputChange={handleInputChange}
          onMenuClose={handleOnMenuClose}
          classes={classes}
          // @ts-ignore
          components={components}
          placeholder={t('search_placeholder_minlength')}
          noOptionsMessage={({ inputValue }) => (inputValue ? t('search_no_options') : '')}
          loadingMessage={() => t('search_loading')}
          value={single}
          onChange={handleChangeSingle}
          options={input ? options : []}
          menuIsOpen={withMenu ? undefined : false}
          isLoading={isLoading}
          // We don't need React Select's filter logic, Elastic does that for us. Just display given options.
          filterOption={() => true}
          onFocus={!input ? onFocus : undefined}
          isClearable
          // @ts-ignore
          onClearValue={handleClear}
          onKeyDown={handleKeyDown}
          styles={{
            placeholder: (provided) => ({
              ...provided,
              pointerEvents: 'none',
              userSelect: 'none',
              MozUserSelect: 'none',
              WebkitUserSelect: 'none',
              msUserSelect: 'none',
            }),
            input: (css) => ({
              ...css,
              /* expand the Input Component div */
              width: '100%',

              /* expand the Input Component child div */
              '> div': {
                width: '100%',
              },
              display: 'inline-flex',

              /* expand the Input Component input */
              input: {
                width: '100% !important',
                minWidth: '100% !important',
                textAlign: 'left',
              },
            }),
          }}
        />
      </NoSsr>
    </div>
  );
};

export default withRouter(SearchAutocomplete);
