import { RouteResourceNames } from './types';
import useCreateApiRoute from './useCreateApiRoute';

const useApiRoute = (
  routeKey: RouteResourceNames | RouteResourceNames[],
  id?: string
): string | false => {
  const createApiRoute = useCreateApiRoute();
  const routeKeys = !Array.isArray(routeKey) ? [routeKey] : routeKey;

  const apiRoutes = routeKeys.map((route) => createApiRoute(route, id)).filter(Boolean);

  return apiRoutes?.[0] ?? false;
};

export default useApiRoute;
