import { targetAudienceFields } from '../../domain';
import React, { FC } from 'react';

type Props = {
  [key: string]: any;
  name: keyof { [key in typeof targetAudienceFields[number]]: true };
};

const TargetAudienceField: FC<Props> = ({ component: Component, ...other }) => (
  <Component {...other} />
);

export default TargetAudienceField;
