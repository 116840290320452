import { handleResponse } from '@react-redux-fetch/fetch-request-handler';
import { saveAs } from 'file-saver';

export const getHeaderValue = (
  response: Response,
  headerKey: string,
  headerValueName: string
): string | false => {
  const fullHeaderValue = response.headers.get(headerKey);
  if (!fullHeaderValue) {
    return false;
  }
  const headerValueNameParts = headerValueName.split('.');

  const regex = new RegExp(
    headerValueNameParts.length === 1
      ? `${headerValueNameParts[0]};(.*)`
      : `${headerValueNameParts[0]};.*${headerValueNameParts[1]}="(.*)"`
  );

  const match = fullHeaderValue.match(regex);

  if (!match) {
    return false;
  }

  return match[1];
};

const responseHandler = async (response: Response) => {
  const fileName = getHeaderValue(response, 'content-disposition', 'attachment.filename');
  if (fileName) {
    const blob = await response.blob();
    saveAs(blob, fileName);
    return null;
  }

  return handleResponse(response);
};

export default responseHandler;
