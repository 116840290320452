import security from '../../security';
import { isErrorAction } from '@react-redux-fetch/core';
import { requestAction } from '@react-redux-fetch/core/dist/actions';
import { getRoutesRequest } from '../hooks/useFetchApiRoutes';
import apiRoutes from '../../../config/api/routes';

const logoutOn401 = (store: any) => (next: any) => (action: any) => {
  next(action);

  const isAuthenticated = () => security.selectors.isAuthenticated(store.getState());

  if (isErrorAction(action)) {
    const reason = action.payload.reason;
    const response = action.payload.response;
    const isExpired = reason?.cause?.code === 401 || response?.status === 401;

    if (isAuthenticated() && isExpired) {
      next(security.actions.logout());

      if (action.payload.fetchConfig.url === apiRoutes.routes()) {
        const action = requestAction(getRoutesRequest());
        store.dispatch(action);
      }
    }
    return;
  }
};

export default logoutOn401;
