import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  onClick: () => void;
  invert?: boolean;
  className?: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  moreButton: {
    background: 'none',
    border: 'none',
    ...theme.typography.button,
    color: theme.palette.primary.main,
    textAlign: 'left',
    textTransform: 'none',
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0.5),
    cursor: 'pointer',
    outline: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
    '& .MuiSvgIcon-root': {
      verticalAlign: 'middle',
      marginLeft: '-0.6rem',
    },
  },
}));

const ShowMoreButton: FC<Props> = ({ onClick, invert, className }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <button onClick={onClick} className={`${classes.moreButton} ${className}`}>
      {invert ? (
        <>
          <ExpandLessIcon /> {t('show_less')}
        </>
      ) : (
        <>
          <ExpandMoreIcon /> {t('show_more')}
        </>
      )}
    </button>
  );
};

export default ShowMoreButton;
