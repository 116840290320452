import { Box, Grid } from '@mui/material';
import { FormApi } from 'final-form';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Table from '../../../../components/Table';
import { CellType, DataType } from '../../../../components/Table/types';
import TextLink from '../../../../components/TextLink';
import { Orders } from '../../../../config/api/models/shop';
import ROUTE_KEY from '../../../../config/routes/routeKeys';
import usePath from '../../../../config/routes/usePath';
import formatEuro from '../../../../helpers/formatEuro';
import getIdFromUrl from '../../../../helpers/getIdFromUrl';
import { getLink } from '../../../../helpers/hateoas';
import { trimStringLength } from '../../../../helpers/trimStringLength';
import organisation from '../../../organisation';
import DepartmentField from '../../../title/cart/components/shoppingCart/DepartmentField';
import OrdersHistoryForm from '../ordersHistory/OrdersHistoryForm';

type Props = {
  pagedOrders: Orders;
  fetchOrders: (url: string) => void;
  isLoading: boolean;
};

const OrdersTableForLibrary = ({ pagedOrders, fetchOrders, isLoading }: Props) => {
  const { t } = useTranslation();
  const url = getLink(pagedOrders, 'self') || '';
  const orderPath = usePath(ROUTE_KEY.ORDER_DETAIL);
  const { departments } = organisation.hooks.useLibraryDepartmentsAndBookstores();

  const headers = [
    { id: 'order_ref', label: t('table_headers_library_order_reference'), width: '15%' },
    { id: 'ordered_at', label: t('table_headers_ordered_at'), width: '10rem' },
    { id: 'department', label: t('table_headers_department'), width: '10rem' },
    { id: 'bookstore', label: t('table_headers_bookstore') },
    { id: 'num_titles', label: t('table_headers_num_titles'), width: '4rem', align: 'center' },
    { id: 'delivered', label: t('table_headers_delivered'), width: '6rem', align: 'center' },
    { id: 'cancelled', label: t('table_headers_cancelled'), width: '6rem', align: 'center' },
    { id: 'confirmed', label: t('table_headers_confirmed'), width: '6rem', align: 'center' },
    { id: 'gross_price', label: t('table_headers_gross_price'), width: '6rem', align: 'right' },
  ];

  const data: DataType = {
    resource: 'orders',
    rows: pagedOrders._embedded.items.map((order) => {
      const id = getIdFromUrl(order._links.self.href);
      const path = orderPath.replace(':id', id);

      return {
        id,
        link: path,
        linkState: { from: window.location.pathname },
        cells: [
          {
            type: CellType.Text,
            data: order.orderReference ? (
              <TextLink
                to={{ pathname: path, state: { from: window.location.pathname } }}
                title={order.orderReference}
              >
                {trimStringLength(order.orderReference, 30)}
              </TextLink>
            ) : null,
          },
          {
            type: CellType.Text,
            data: moment(order.orderedAt).format('DD/MM/YYYY'),
          },
          {
            type: CellType.Text,
            data: order.departmentName,
          },
          {
            type: CellType.Text,
            data: order.bookstoreName,
          },
          {
            type: CellType.Text,
            data: <Box textAlign="center">{order.uniqueTitles}</Box>,
          },
          {
            type: CellType.Text,
            data: <Box textAlign="center">{order.deliveredCount}</Box>,
          },
          {
            type: CellType.Text,
            data: <Box textAlign="center">{order.cancelledCount}</Box>,
          },
          {
            type: CellType.Text,
            data: <Box textAlign="center">{order.submittedCount}</Box>,
          },
          {
            type: CellType.Text,
            data: <Box textAlign="right">{formatEuro(order.resaleValue)}</Box>,
          },
        ],
      };
    }),
  };

  return (
    <>
      <OrdersHistoryForm
        fetchOrders={fetchOrders}
        url={url}
        numResults={pagedOrders.total}
        role="library"
        withFreeText={false}
      >
        {({ form, values }: { form: FormApi; values: Record<string, string> }) => (
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <organisation.components.BookstoresSelectField
                label={t('table_headers_bookstore')}
                placeholder={t('table_headers_bookstore')}
                change={form.change}
                bookstoreId={values.bookstore_id}
                onClearValue={form.submit}
              />
            </Grid>
            <Grid item xs={6}>
              <DepartmentField departments={departments} isClearable onClearValue={form.submit} />
            </Grid>
          </Grid>
        )}
      </OrdersHistoryForm>
      <Table
        data={data}
        headers={headers}
        pagedResource={pagedOrders}
        loading={isLoading}
        dispatchFn={fetchOrders}
      />
    </>
  );
};

export default OrdersTableForLibrary;
