import BusinessIcon from '@mui/icons-material/Business';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import PersonIcon from '@mui/icons-material/Person';
import { FormLabel, Grid, Switch, Tooltip } from '@mui/material';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../../../../../components/Button';
import Field from '../../../../../../../components/Form';
import ContributorRoleField from './ContributorRoleField';

const required = (message: string) => (value?: string) => (value ? undefined : message);

type Props = {
  name: String;
  onRemove: () => void;
  RemoveIcon?: typeof CloseIcon;
  onToggleContributor: (isCorporate: boolean) => void;
  isPerson: boolean;
  onClose?: () => void;
};

const ContributorField: FC<Props> = ({
  name,
  isPerson,
  onRemove,
  RemoveIcon = CloseIcon,
  onToggleContributor,
  onClose,
}) => {
  const [isCorporate, setIsCorporate] = useState(!isPerson);
  const { t } = useTranslation();

  return (
    <Grid container spacing={1} alignItems="flex-start">
      <Grid item>
        <div
          style={{
            marginTop: 0,
            marginBottom: 6,
          }}
        >
          <FormLabel className="MuiInputLabel-root">{t('form_isCorporateType')}</FormLabel>
        </div>
        <Grid container alignItems="center">
          <Grid item>
            <Tooltip title={t('form_isNotCorporate')}>
              <PersonIcon />
            </Tooltip>
          </Grid>
          <Grid item>
            <Switch
              checked={isCorporate}
              onClick={() => {
                setIsCorporate((isCorporate) => {
                  onToggleContributor(!isCorporate);
                  return !isCorporate;
                });
              }}
              color="primary"
            />
          </Grid>
          <Grid item>
            <Tooltip title={t('form_isCorporate')}>
              <BusinessIcon />
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
      {isCorporate ? (
        <Grid item>
          <Field
            name={`${name}.corporateName`}
            type="text"
            label={`${t('form_corporateName')} *`}
            validate={required(t('form_contributor_corporateName_required'))}
            autoFocus
          />
        </Grid>
      ) : (
        <>
          <Grid item>
            <Field name={`${name}.firstName`} type="text" label={t('form_firstName')} autoFocus />
          </Grid>
          <Grid item style={{ width: 160 }}>
            <Field
              name={`${name}.lastName`}
              type="text"
              label={`${t('form_lastName')} *`}
              validate={required(t('form_contributor_lastName_required'))}
              validateOnChange
            ></Field>
          </Grid>
        </>
      )}
      <Grid item style={{ width: 240 }}>
        <ContributorRoleField name={`${name}.role`} />
      </Grid>
      <Grid item>
        <Button
          size="small"
          variant={'text'}
          style={{ marginTop: 19, marginRight: 4 }}
          onClick={onClose}
          disabled={!onClose}
        >
          <CheckIcon />
        </Button>
        <Button
          size="small"
          variant={'text'}
          style={{ marginTop: 19 }}
          color="primary"
          onClick={onRemove}
        >
          <RemoveIcon />
        </Button>
      </Grid>
    </Grid>
  );
};

export default ContributorField;
