import ClearIcon from '@mui/icons-material/Clear';
import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { FC, useEffect, useRef, useState } from 'react';
import { StringParam, useQueryParam, withDefault } from 'use-query-params';
import { BaseTextField } from '../../../../../components/Form/TextField';
import { QueryObjectValue } from '../../../../../helpers/elasticQueryDSL';
import useDebounce from '../../../../../helpers/useDebounce';
import FacetWrapper from '../../components/Facet/FacetWrapper';

type Props = {
  label: string;
  placeholder: string;
  onFilter: (value: string) => void;
  value?: QueryObjectValue;
  isClearable?: boolean;
  queryName: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  clearIcon: {
    color: theme.palette.grey['700'],
    padding: 5,
    fontSize: 26,
    '&:hover': {
      color: theme.palette.common.black,
      cursor: 'pointer',
    },
  },
  clearableInput: {
    padding: 0,
    backgroundColor: 'initial',
  },
}));

const ENTER = 13;

// const cleanupValue = (value?: QueryObjectValue) => {
//   return typeof value === 'string' ? value.replace(/\*/g, '') : value || '';
// };

const GenericTextFieldFacet: FC<Props> = ({
  onFilter,
  label,
  placeholder,
  value,
  isClearable,
  queryName,
}) => {
  const textFieldRef = useRef<HTMLInputElement>(null);
  const classes = useStyles();

  const [fieldValue, setFieldValue] = useQueryParam(
    queryName,
    withDefault(StringParam, value as string)
  );
  const [localFieldValue, setLocalFieldValue] = useState(fieldValue);
  const [debouncedFieldValue, setDebouncedFieldValue] = useDebounce(localFieldValue, 300);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLocalFieldValue(event.currentTarget.value);
  };

  const handleClear = () => {
    setFieldValue('');
    setLocalFieldValue('');
    setDebouncedFieldValue('');
    onFilter('');
  };

  useEffect(() => {
    if (debouncedFieldValue || debouncedFieldValue !== fieldValue) {
      onFilter(debouncedFieldValue);
      setFieldValue(debouncedFieldValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedFieldValue]);

  useEffect(() => {
    setLocalFieldValue(fieldValue);
  }, [fieldValue]);

  const clearButton = Boolean(isClearable && localFieldValue) && (
    <ClearIcon onClick={handleClear} className={classes.clearIcon} />
  );

  return (
    <FacetWrapper title={label} collapsible={false} active={Boolean(value)}>
      <BaseTextField
        inputRef={textFieldRef}
        value={localFieldValue}
        onChange={handleChange}
        onBlur={() => {
          onFilter(localFieldValue ? `*${localFieldValue}*` : '');
        }}
        placeholder={placeholder}
        onKeyUp={(e) => {
          if (e.keyCode === ENTER) {
            // eslint-disable-next-line no-unused-expressions
            textFieldRef.current?.blur();
          } else {
            if (!(e.target as HTMLInputElement).value) {
              onFilter(`*${debouncedFieldValue}*`);
            }
          }
        }}
        InputProps={{ endAdornment: clearButton, className: classes.clearableInput }}
      />
    </FacetWrapper>
  );
};

export default GenericTextFieldFacet;
