import { Grid } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router';
import Button from '../../../../../components/Button';
import ROUTE_KEY from '../../../../../config/routes/routeKeys';
import usePath from '../../../../../config/routes/usePath';

const CreateFundButton = ({ location }: RouteComponentProps) => {
  const { t } = useTranslation();
  const path = usePath(ROUTE_KEY.FUNDS_CREATE);

  if (path === '') return null;

  return (
    <Grid container justifyContent="flex-end">
      <Button link={{ pathname: path, state: { from: location.pathname } }}>
        {t('form_fund_create')}
      </Button>
    </Grid>
  );
};

export default withRouter(CreateFundButton);
