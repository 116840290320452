import { PromiseState as ReduxPromiseState } from '@react-redux-fetch/core';
import React, { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PromiseState } from 'react-redux-fetch';
import BaseReactSelectField, { BaseReactSelectFieldProps } from './BaseReactSelectField';

export type RemoteAutocompleteProps = BaseReactSelectFieldProps & {
  label: ReactNode;
  name: string;
  createUrlFromInput: (inputTerm: string) => string;
  minInputLength?: number;
  promiseState?: PromiseState<any> | ReduxPromiseState;
  makeRequest: (...args: any) => void;
  inputValue?: string;
};

const MIN_INPUT_LENGTH = 2;
let urlToFetchWhenDone: null | string = null;

const RemoteAutocomplete = ({
  label,
  name,
  minInputLength = MIN_INPUT_LENGTH,
  createUrlFromInput,
  promiseState,
  makeRequest,
  options = [],
  inputValue: initialInputValue,
  ...rest
}: RemoteAutocompleteProps) => {
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState(initialInputValue);

  useEffect(() => {
    if (urlToFetchWhenDone && promiseState && promiseState.fulfilled) {
      makeRequest(urlToFetchWhenDone);
      urlToFetchWhenDone = null;
    }
  }, [promiseState, makeRequest]);

  const customStyles = {
    singleValue: (provided: any, state: any) => ({
      display: state.selectProps.menuIsOpen ? 'none' : 'block',
    }),
  };

  return (
    <BaseReactSelectField
      label={label}
      type="autocomplete"
      name={name}
      onInputChange={(term: string) => {
        setInputValue(term);
        if (term.length < minInputLength) {
          return;
        }

        const newUrl = createUrlFromInput(term);

        if (promiseState && promiseState.pending) {
          urlToFetchWhenDone = newUrl;
        } else {
          makeRequest(newUrl);
        }
      }}
      options={
        minInputLength === 0 || (inputValue && inputValue.length >= minInputLength) ? options : []
      }
      noOptionsMessage={({ inputValue }: { inputValue: string }) =>
        inputValue.length >= minInputLength
          ? (promiseState && promiseState.pending) || urlToFetchWhenDone
            ? t('search_loading')
            : t('search_no_options')
          : t('search_start_typing', { minLength: minInputLength })
      }
      loadingMessage={() => t('search_loading')}
      inputValue={inputValue}
      styles={customStyles}
      {...rest}
    />
  );
};

export default RemoteAutocomplete;
