import React from 'react';
import { MasterPrefixes, Maybe } from '../../../../config/api/types';
import { useTranslation } from 'react-i18next';
import { DataType, CellType } from '../../../../components/Table/types';
import { getLink } from '../../../../helpers/hateoas';
import Table from '../../../../components/Table';
import { apiDate } from '../../../../helpers/date';

type Props = {
  masterPrefixes: Maybe<MasterPrefixes>;
  dispatchFn: (url: string) => void;
};

const MasterPrefixTable = ({ masterPrefixes, dispatchFn }: Props) => {
  const { t } = useTranslation();
  const headers = [
    { id: 'masterPrefix', label: t('table_headers_master_prefix') },
    { id: 'createdAt', label: t('table_headers_creation_date') },
    { id: 'freeNumbers', label: t('table_headers_master_prefix_free') },
    { id: 'description', label: t('table_headers_description') },
  ];

  if (!masterPrefixes) {
    return null;
  }

  const data: DataType = {
    resource: 'masterPrefixes',
    rows: masterPrefixes._embedded.items.map((mp) => ({
      id: getLink(mp, 'self') || '',
      cells: [
        {
          type: CellType.Text,
          data: mp.masterPrefix,
        },
        {
          type: CellType.Text,
          data: apiDate(mp.createdAt).toDay(),
        },
        {
          type: CellType.Text,
          data: mp.freeIsbnCount,
        },
        {
          type: CellType.Text,
          data: mp.description,
        },
      ],
    })),
  };

  return (
    <>
      <Table
        headers={headers}
        data={data}
        page={masterPrefixes.page}
        pages={masterPrefixes.pages}
        total={masterPrefixes.total}
        limit={masterPrefixes.limit}
        pagedResource={masterPrefixes}
        dispatchFn={dispatchFn}
      />
    </>
  );
};

export default MasterPrefixTable;
