import { Distributors, NormalizedDistributors } from '../../../config/api/models/organisation';
import { Distributor } from '../../../config/api/types';
import normalizePagedResponse from '../../../helpers/normalizePagedResponse';

export const normalizePagedDistributorsRequest = (
  data: Distributors | Distributor[]
): { distributors: NormalizedDistributors } => {
  if (Array.isArray(data)) {
    return {
      distributors: data.reduce((prev, distributor) => {
        return {
          ...prev,
          [distributor._links.self.href]: distributor,
        };
      }, {}),
    };
  }

  const { normalizedData } = normalizePagedResponse(
    {
      ...data,
      _embedded: {
        items: data._embedded.items.map((distributor) => ({
          distributorId:
            distributor.distributorId || distributor._embedded.organisation.organisationId,
          ...distributor,
        })),
      },
    },
    (distributor) => distributor._links.self.href
  );

  return {
    distributors: normalizedData,
  };
};
