import { FetchConfig } from '@react-redux-fetch/core';
import { Data } from '../../config/store/types';
import apiRoutes from '../../config/api/routes';

export const exportStatusRequest = (
  url: string,
  queryKey?: string,
  fetchConfig?: Partial<FetchConfig>
): FetchConfig<Data> => {
  return {
    method: 'GET',
    url: apiRoutes.create(url),
    requestKey: `exportStatusRequest${queryKey || ''}`,
    fetchOptions: {
      ...fetchConfig?.fetchOptions,
      headers: {
        accept: 'application/vnd.ms-excel',
        ...fetchConfig?.fetchOptions?.headers,
      },
    },
    repository: {},
  };
};

export const createExportRequest = (
  url: string,
  fetchConfig?: Partial<FetchConfig>,
  queryKey?: string
): FetchConfig<void> => {
  return {
    method: 'POST',
    url: apiRoutes.create(url),
    requestKey: `createExportRequest${queryKey || ''}`,
    repository: {},
    ...fetchConfig,
  };
};
