import { CSSProperties, ReactNode } from 'react';

export enum CellType {
  Text,
  Action,
  Boolean,
  Array,
}

export type Cell = {
  type: CellType;
  data: ReactNode | string;
  trueOnly?: boolean;
  style?: CSSProperties;
  align?: 'center' | 'left' | 'right' | 'inherit' | 'justify';
};

export type DataType = {
  resource: string;
  rows: Array<{
    id: string | number;
    link?: string;
    linkState?: Record<string, unknown>;
    cells: Cell[];
    variant?: 'red';
  }>;
};
