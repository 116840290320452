import React from 'react';
import { useTranslation } from 'react-i18next';
import { SelectField as Select } from '../../../../../components/Form/index';
import { statisticCodes } from '../../../domain';
import { SelectFieldProps } from '../../../../../components/Form/SelectField';

type Props = {
  onChange: SelectFieldProps['onChange'];
  defaultValue?: string;
};

const StatisticsCodeFilter = ({ onChange, defaultValue }: Props) => {
  const { t } = useTranslation();

  const items = statisticCodes.reduce((agg, code) => {
    return {
      ...agg,
      [code]: t(`filter_option_${code.toLowerCase()}`),
    };
  }, {});

  return (
    <Select
      items={items}
      onChange={onChange}
      label={t('filter_statistics_code')}
      value={defaultValue}
    />
  );
};

export default StatisticsCodeFilter;
