import apiRoutes from '../../../config/api/routes';
import { ApiConfigUnion } from '../../../helpers/apiConfig';
import { FetchConfig } from '@react-redux-fetch/core';
import { Data } from '../../../config/store/types';

export const KEY_DROPDOWN_SEARCH = 'dropdownSearch';
export const KEY_SEARCH = 'search';

export const getSearchRequest = (url: string): FetchConfig<Data> => ({
  url: apiRoutes.create(url),
  repository: {
    titleSearch: (prev, next) => next,
  },
});

export const getProductFormGroupRequest = (url: string): FetchConfig<Data> => ({
  url: apiRoutes.create(url),
  repository: {
    productFormGroups: (prev, next) => next,
  },
});

export const getTitleAggregationsRequest = (url: string): FetchConfig<Data> => ({
  url: apiRoutes.create(url),
  repository: {
    titleAggregations: (prev, next) => next,
  },
});

const createSearchConfig = (resourceName: string) => (basePath: string) => ({
  resource: resourceName,
  request: (path?: string) => {
    const url = `${apiRoutes.get(path || basePath)}`;
    return {
      url,
    };
  },
});

export const ApiConfig = {
  dropdownSearch: createSearchConfig(KEY_DROPDOWN_SEARCH),
  search: createSearchConfig(KEY_SEARCH),
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ApiConfig = ApiConfigUnion<typeof ApiConfig>;
