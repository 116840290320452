import React from 'react';
import { useTranslation } from 'react-i18next';
import ConstrainedPageContent from '../../../components/ConstrainedPageContent';
import Header from '../../../components/Header';
import OrdersToProcessForBookstore from '../components/ordersToProcess/OrdersToProcessForBookstore';

const OrdersToProcessForBoosktorePage = () => {
  const { t } = useTranslation();
  return (
    <ConstrainedPageContent center={false} lg>
      <Header title={t('Page_title_orders_to_process_for_bookstore')} />
      <OrdersToProcessForBookstore />
    </ConstrainedPageContent>
  );
};

export default OrdersToProcessForBoosktorePage;
