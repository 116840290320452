import { Box, Grid, Paper, TextField } from '@mui/material';
import React, { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import NumberStepField from '../../../../components/Form/NumberStepField';

type Props = {
  onClose: () => void;
  quantity: number;
  setQuantity: (quantity: number) => void;
  reference: string;
  setReference: (reference: string) => void;
  refError?: string;
  qtyError?: string;
  children?: ReactNode;
};

export const AddRefModal: FC<Props> = ({
  children,
  onClose,
  quantity,
  setQuantity,
  reference,
  setReference,
  refError,
  qtyError,
}) => {
  const { t } = useTranslation();

  return (
    // <Dialog open={true} onClose={onClose}>
    //   <DialogContent>
    //</DialogContent>
    // </Dialog>
    <Paper style={{ padding: 16 }}>
      <Grid container spacing={2} alignItems="flex-start" justifyContent="flex-end">
        <Grid item style={{ width: 240 }}>
          <TextField
            size="small"
            variant="outlined"
            placeholder={t(`form_your_reference`)}
            value={reference}
            onChange={(e) => setReference(e.target.value)}
            fullWidth
            margin="none"
            error={Boolean(refError)}
            helperText={refError}
            autoFocus
          />
        </Grid>

        <Grid item>
          <Box width={112}>
            <NumberStepField
              value={quantity}
              onChange={setQuantity}
              error={Boolean(qtyError)}
              helperText={qtyError}
            />
          </Box>
        </Grid>

        <Grid item>
          <Box height={36}>{children}</Box>
        </Grid>
      </Grid>
    </Paper>
  );
};
