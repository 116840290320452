import { Typography } from '@mui/material';
import React, { FC } from 'react';
import { Organisation } from '../../../config/api/types';
import { getCountryNameByShortName } from '../../../config/data/countries';
import { formatAddress } from '../../organisation/domain';
import { IsbnFormValues } from '../types';

type Props = {
  organisation: IsbnFormValues['organisation'];
};

export const OrganisationInfo: FC<Props> = ({ organisation }) => {
  return (
    <>
      <Typography>{organisation.name}</Typography>
      <Typography>
        {formatAddress(organisation as Organisation)},{' '}
        {getCountryNameByShortName(organisation.countryIso)}
      </Typography>
      {organisation.phoneNumber && <Typography>{organisation.phoneNumber}</Typography>}
      <Typography>{organisation.email}</Typography>
      {organisation.companyRegistrationNumber && (
        <Typography>{organisation.companyRegistrationNumber}</Typography>
      )}
      {organisation.url && <Typography>{organisation.url}</Typography>}
    </>
  );
};
