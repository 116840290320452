import moment, { Moment } from 'moment';
import { FormattedDate } from '../config/api/types';

export const ONIX_DATE_FORMAT = 'YYYYMMDD';
export const API_DATE_FORMAT = 'YYYY-MM-DD';
export const API_DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';
export const UI_DATE_FORMAT = 'DD-MM-YYYY';

export const apiDate = (formattedDate: FormattedDate) => {
  const date = moment(formattedDate, API_DATE_TIME_FORMAT);

  return {
    toDay: () => date.format('DD/MM/YYYY'),
    toDateTime: () => date.format('DD/MM/YYYY HH:mm'),
  };
};

export const onixToMoment = (onixDate: string): Moment => {
  return moment(onixDate, ONIX_DATE_FORMAT);
};

export const isValidDate = (formattedDate: FormattedDate) => {
  const date = moment(formattedDate, API_DATE_TIME_FORMAT);
  return date.isValid();
};

export const isValidYear = (year?: string) => {
  return year && parseInt(year) >= 1900 && parseInt(year) <= 2050;
};
