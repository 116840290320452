import { Box, Typography } from '@mui/material';
import { TypographyProps } from '@mui/material/Typography';
import React from 'react';
import { Imprint } from '../../../../config/api/types';
import OrganisationDialog from '../../containers/OrganisationDialog';

type Props = {
  publisherName?: string;
  publisherOrganisationUrl?: string;
  imprint?: Imprint;
  withInfoModal?: boolean;
  typographyProps?: TypographyProps;
  showBothIfPossible?: boolean;
};

const ElasticPublisher = ({
  publisherName,
  publisherOrganisationUrl,
  imprint,
  withInfoModal,
  typographyProps,
  showBothIfPossible = true,
}: Props) => {
  const enableModal = withInfoModal && Boolean(publisherOrganisationUrl);

  const publisherElement = enableModal ? (
    <OrganisationDialog
      organisationUrl={publisherOrganisationUrl || ''}
      toggleButtonProps={typographyProps}
    >
      {publisherName}
    </OrganisationDialog>
  ) : (
    <Typography display="inline" {...typographyProps}>
      {publisherName}
    </Typography>
  );

  return imprint ? (
    <>
      <Typography display="inline" {...typographyProps}>
        {imprint}
      </Typography>
      {showBothIfPossible && (
        <Box display="inline-block" pl={0.5}>
          ({publisherElement})
        </Box>
      )}
    </>
  ) : (
    publisherElement
  );
};

export default ElasticPublisher;
