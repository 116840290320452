import { Grid, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { findKey } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SelectField as Select } from '../../../../components/Form';

const useStyles = makeStyles((theme: Theme) => ({
  sortBy: {
    marginBottom: theme.spacing(1),
  },
  label: {
    ...theme.typography.subtitle2,
    color: theme.palette.text.primary,
  },
  selectWrapper: {
    marginTop: -theme.spacing(1),
  },
}));

type Option = { label: string; order: string; orderDir: string };

export type SortByProps = {
  onChange: (option: Option) => void;
  order?: string;
  orderDir?: string;
};

const SortBy = ({ onChange, order = '', orderDir = '' }: SortByProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const items: { [key: string]: Option } = {
    mostRelevant: { label: t('sort_option_most_relevant_first'), order: '', orderDir: '' },
    firstPublished: {
      label: t('sort_option_first_published_asc'),
      order: 'isbnFirstPublishedDate',
      orderDir: 'asc',
    },
    lastPublished: {
      label: t('sort_option_last_published'),
      order: 'isbnFirstPublishedDate',
      orderDir: 'desc',
    },
    collectionAsc: {
      label: t('sort_option_collection_asc'),
      order: 'collection.number.keyword',
      orderDir: 'asc',
    },
    collectionDesc: {
      label: t('sort_option_collection_desc'),
      order: 'collection.number.keyword',
      orderDir: 'desc',
    },
    publishingStatusAsc: {
      label: t('sort_option_publishingStatus_asc'),
      order: 'availability.code.keyword',
      orderDir: 'asc',
    },
    publishingStatusDesc: {
      label: t('sort_option_publishingStatus_desc'),
      order: 'availability.code.keyword',
      orderDir: 'desc',
    },
  };

  const value = findKey(items, (item) => item.order === order && item.orderDir === orderDir);

  return (
    <Grid container alignItems="center" spacing={2} className={classes.sortBy}>
      <Grid item>
        <Typography className={classes.label}>{t('sort_by')}</Typography>
      </Grid>
      <Grid item className={classes.selectWrapper}>
        <Select
          items={items}
          onChange={(ev) => {
            if (items.hasOwnProperty(ev.target.value as string)) {
              onChange(items[ev.target.value as string]);
            }
          }}
          allowEmpty={false}
          value={value}
        />
      </Grid>
    </Grid>
  );
};

export default SortBy;
