import Distributor from './components/Distributor';
import { DistributorsSelectField } from './components/DistributorsSelectField';

const distributor = {
  components: {
    Distributor,
    DistributorsSelectField,
  },
};

export default distributor;
