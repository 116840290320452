import { useCallback, useEffect, useState } from 'react';
import { updateQueryParameters } from './hateoas';

const queryParamsToObject = <T>(queryStr?: string): T => {
  return queryStr
    ? JSON.parse(
        '{"' +
          decodeURI(queryStr.substring(queryStr.indexOf('?') + 1))
            .replace(/\\/g, '/')
            .replace(/"/g, '\\"')
            .replace(/\t/g, '')
            .replace(/&/g, '","')
            .replace(/=/g, '":"') +
          '"}'
      )
    : {};
};

const useQueryParams = <T extends Record<string, any>>(queryStr?: string) => {
  const [query, setQuery] = useState(queryStr);

  useEffect(() => {
    setQuery(queryStr);
  }, [queryStr]);

  const changeParams = useCallback(
    (values: Record<string, string | number>) => {
      setQuery(updateQueryParameters(queryStr || '', values));
    },
    [queryStr]
  );

  return {
    queryObj: queryParamsToObject<T>(query),
    queryStr: query,
    changeParams,
  };
};

export default useQueryParams;
