import { Box } from '@mui/material';
import React from 'react';
import { RouteComponentProps } from 'react-router';
import OrganisationWrapper from '../containers/OrganisationWrapper';
import FTPAccounts from '../containers/users/FTPAccounts';
import Users from '../containers/users/Users';

type Params = { id: string };
type Props = RouteComponentProps<Params> & {};

const UsersTab = (props: Props) => (
  <OrganisationWrapper {...props}>
    {({ organisation }) =>
      organisation && (
        <>
          <Box mb={2}>
            <Users organisation={organisation} />
          </Box>
          <FTPAccounts organisation={organisation} />
        </>
      )
    }
  </OrganisationWrapper>
);

export default UsersTab;
