import { Divider, Grid, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../../../components/Button';
import SubTitle from '../../../../../components/SubTitle';
import { DistributorLine } from '../../../../../config/api/models/shop';
import formatEuro from '../../../../../helpers/formatEuro';
import { getLink } from '../../../../../helpers/hateoas';
import CartLine from './CartLine';
import LastColumn from './LastColumn';

type Props = {
  userLine: DistributorLine;
  userId: string;
  onOpenDialogInfo: (
    confirmationUrl: string,
    distributorLines?: DistributorLine[],
    userLines?: Record<string, DistributorLine>
  ) => void;
  disabled: boolean;
  onInvalidLine: (titleId: string, isInvalid: boolean) => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  distributorLine: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  distributorTitle: {
    marginBottom: theme.spacing(1),
  },
  distributorTotal: {
    marginTop: theme.spacing(1),
  },
}));

const OrderByUserLine = ({
  userId,
  userLine,
  onOpenDialogInfo,
  disabled,
  onInvalidLine,
}: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const user = userLine.lines ? userLine.lines[0]._embedded.login : undefined;
  const userFullname = user ? [user?.firstName, user?.lastName].join(' ') : '';
  const totalPrice = userLine.lines?.reduce((total, line) => total + line.total, 0) || 0;
  const confirmUrl = getLink(userLine, 'userGroupConfirmation') || '';

  return (
    <div className={classes.distributorLine}>
      <Grid
        container
        sx={{ justifyContent: 'space-between', alignItems: 'center' }}
        className={classes.distributorTitle}
      >
        <Grid item>
          <SubTitle>
            {t('added_by')} <strong>{userFullname || userId}</strong>
          </SubTitle>
        </Grid>
      </Grid>
      <Divider />
      {userLine.lines?.map((cartLine, index) => (
        <React.Fragment key={`${cartLine.titleId}${index}`}>
          <CartLine cartLine={cartLine} asLibrary onInvalidLine={onInvalidLine} />
          <Divider />
        </React.Fragment>
      ))}
      <Grid container className={classes.distributorTotal} spacing={1} alignItems="center">
        <Grid item style={{ flex: 1 }}>
          <Typography align="right">
            {t('cart_user_total')} <strong>{formatEuro(totalPrice)}</strong>
          </Typography>
        </Grid>
        <LastColumn style={{ textAlign: 'right', width: 150 }}>
          <Button
            size="small"
            onClick={() => {
              onOpenDialogInfo(confirmUrl, undefined, { userId: userLine });
            }}
            disabled={disabled}
          >
            {t('cart_place_library_order')}
          </Button>
        </LastColumn>
      </Grid>
    </div>
  );
};

export default OrderByUserLine;
