import React, { FC, useCallback } from 'react';
import { Form } from 'react-final-form';
import FacetWrapper from '../../components/Facet/FacetWrapper';
import { useTranslation } from 'react-i18next';
import useApiRoute from '../../../../../config/api/useApiRoute';
import AutoSave from '../../../../../components/Form/AutoSave';
import { QueryObjectValue } from '../../../../../helpers/elasticQueryDSL';
import SetInitialValueWhenLoaded from '../../../../../components/Form/SetInitialValueWhenLoaded';
import { getFunds } from '../../../admin/title/selectors';
import { Fund } from '../../../../../config/api/types';
import FundField from '../../../admin/title/containers/fields/FundField';

type Props = {
  onFilter: (value: string) => void;
  value?: QueryObjectValue;
};

type FormValues = {
  fund?: Fund;
};

const FundsFacet: FC<Props> = ({ onFilter, value }) => {
  const { t } = useTranslation();
  const url = useApiRoute(['fundsDistributor', 'fundsAdmin', 'fundsBookstore']);

  const findFromValue = useCallback((fund: Fund) => fund.code === value, [value]);

  if (!url) {
    return null;
  }

  return (
    <FacetWrapper title={t('facet_fund')} collapsible={false} active={Boolean(value)}>
      <Form
        onSubmit={(formValues: FormValues) => {
          if (formValues.fund?.code !== value) {
            onFilter(formValues.fund?.code || '');
          }
        }}
      >
        {({ handleSubmit, form: { submit } }) => (
          <form onSubmit={handleSubmit}>
            <AutoSave save={submit} />
            <FundField
              label=" "
              blurInputOnSelect
              isClearable
              onClearValue={submit}
              placeholder={t('facet_fund_placeholder')}
            />
            {value && (
              <SetInitialValueWhenLoaded
                name="fund"
                dataSelector={getFunds}
                findValue={findFromValue}
              />
            )}
          </form>
        )}
      </Form>
    </FacetWrapper>
  );
};

export default FundsFacet;
