import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-final-form';
import { useSelector } from 'react-redux';
import useApiRoute from '../../../../../config/api/useApiRoute';
import FacetWrapper from '../../components/Facet/FacetWrapper';
import AutoSave from '../../../../../components/Form/AutoSave';
import { QueryObjectValue } from '../../../../../helpers/elasticQueryDSL';
import LanguageField from '../../../admin/title/containers/fields/LanguageField';
import SetInitialValueWhenLoaded from '../../../../../components/Form/SetInitialValueWhenLoaded';
import { getLanguages } from '../../../admin/title/selectors';
import { Language, Languages } from '../../../../../config/api/types';
import { getTitleAggregationsFor } from '../../selectors';
import { TitleAggregationKey } from '../../../../../config/api/models/titleAggregations';

type Props = {
  onFilter: (value: string) => void;
  value?: QueryObjectValue;
};

const LanguageFacet: FC<Props> = ({ onFilter, value }) => {
  const { t } = useTranslation();
  const url = useApiRoute('titleLanguages');
  const aggregations = useSelector(getTitleAggregationsFor('languages.code'));
  const availableLanguagesDictionary:
    | Record<string, TitleAggregationKey>
    | undefined = aggregations?.keys.reduce((total, agg) => ({ ...total, [agg.key]: agg }), {});

  const findFromValue = useCallback((language: Language) => language.code === value, [value]);
  const filterLanguages = useCallback(
    (languages?: Languages) => {
      if (!aggregations || !languages) {
        return [];
      }
      return aggregations?.keys
        .map((agg) => {
          return languages.find((language) => language.code === agg.key);
        })
        .filter(Boolean) as Languages;
    },
    [aggregations]
  );

  if (!url) {
    return null;
  }

  return (
    <FacetWrapper title={t('facet_languages')} collapsible={false} active={Boolean(value)}>
      <Form
        onSubmit={(formValues) => {
          if (formValues.languages?.code !== value) {
            onFilter(formValues.languages?.code || null);
          }
        }}
      >
        {({ handleSubmit, form: { submit } }) => (
          <form onSubmit={handleSubmit}>
            <AutoSave save={submit} />
            <LanguageField
              url={url}
              label=""
              isMulti={false}
              filterLanguages={filterLanguages}
              getOptionLabel={(option: Language) =>
                `${option.label} (${availableLanguagesDictionary?.[option.code]?.docCount ?? 0})`
              }
              blurInputOnSelect
              isClearable
              onClearValue={submit}
              placeholder={t('facet_languages_placeholder')}
            />
            {value && (
              <SetInitialValueWhenLoaded
                name="languages"
                dataSelector={getLanguages}
                findValue={findFromValue}
              />
            )}
          </form>
        )}
      </Form>
    </FacetWrapper>
  );
};

export default LanguageFacet;
