import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { Badge, Theme } from '@mui/material';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { makeStyles } from '@mui/styles';
import cxs from 'classnames';
import { snakeCase } from 'lodash';
import equals from 'ramda/es/equals';
import split from 'ramda/es/split';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLinkProps, NavLink as RRNavLink, RouteChildrenProps } from 'react-router-dom';
import { NestedClientRoute } from '../../../../config/routes/types';

type Match = RouteChildrenProps['match'];

type Props = Partial<NavLinkProps> & {
  match: Match;
  route: NestedClientRoute;
  isChild?: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  navLink: {
    textDecoration: 'none',
  },
  listItem: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    [theme.breakpoints.down('lg')]: {
      paddingLeft: theme.spacing(2),
    },
  },
  listItemActive: {
    borderLeft: `3px solid ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.background.appbarActive,
  },
  navigationTextRoot: {
    margin: 0,
  },
  navigationText: {
    color: theme.palette.text.navigation,
    fontSize: theme.typography.subtitle2.fontSize,
  },
  icon: {
    color: theme.palette.text.navigation,
  },
  listItemButton: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    color: theme.palette.primary.main,
  },
  navIndent: {
    marginLeft: theme.spacing(4),
  },
  badge: {
    paddingRight: theme.spacing(1),
  },
}));

const isParentRoute = (parentRoute: string, match: Match) =>
  match && match.path ? equals(parentRoute, `/${split('/', match.path)[1]}`) : false;

const currentRoute = (route: string, match: Match) =>
  match && match.path ? match.path === route : false;

const NavLink: FC<Props> = ({ route, match, isChild, ...other }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const isCurrentRoute = currentRoute(route.path, match);
  const isRoute = isParentRoute(route.path, match);

  const subMenuIcon =
    !isChild && route.subRoutes && route.subRoutes.find((r) => !r.hideInNav) ? (
      isRoute ? (
        <ArrowDropUpIcon className={classes.icon} />
      ) : (
        <ArrowDropDownIcon className={classes.icon} />
      )
    ) : null;

  const label = route.badgeValue ? (
    <Badge badgeContent={route.badgeValue} color="primary" className={classes.badge}>
      {t(`nav_${snakeCase(route.key)}`)}
    </Badge>
  ) : (
    t(`nav_${snakeCase(route.key)}`)
  );

  const NavLabel = route.navLabelComponent;

  return (
    <RRNavLink to={route.path} className={classes.navLink} {...other}>
      <ListItem
        button
        className={cxs(classes.listItem, {
          [classes.listItemActive]: isCurrentRoute,
        })}
        classes={{
          button: classes.listItemButton,
        }}
      >
        <ListItemText
          primary={NavLabel ? <NavLabel route={route} /> : label}
          className={isChild ? classes.navIndent : ''}
          classes={{ root: classes.navigationTextRoot, primary: classes.navigationText }}
        />
        {subMenuIcon}
      </ListItem>
    </RRNavLink>
  );
};

export default NavLink;
