import React from 'react';
import slugify from '../../../helpers/slugify';
import TextLink from '../../../components/TextLink';
import usePath from '../../../config/routes/usePath';
import ROUTE_KEY from '../../../config/routes/routeKeys';

type TitleEmbed = {
  titleId: string;
  _embedded: {
    title: {
      title: string;
      gtin13: string;
    };
  };
};

type Props<T> = {
  orderLine: T;
};

const IsbnLink = <T extends TitleEmbed>({ orderLine }: Props<T>) => {
  const detailPath = usePath(ROUTE_KEY.TITLE);

  return (
    <TextLink
      underline
      to={{
        pathname: detailPath
          .replace(':slug', slugify(orderLine._embedded.title.title))
          .replace(':id', orderLine.titleId),
        state: { from: window.location.pathname },
      }}
      onClick={(e) => e.stopPropagation()}
    >
      {orderLine._embedded.title.gtin13}
    </TextLink>
  );
};

export default IsbnLink;
