import React, { useState } from 'react';
import { PrefixApplication, MasterPrefix } from '../../../../config/api/types';
import GeneratePrefix from '../components/GeneratePrefix';
import PrefixApplicationPrefixReview from '../components/PrefixApplicationPrefixReview';

type Props = {
  prefixApplication: PrefixApplication;
  onUpdate: (data: PrefixApplication) => void;
};

const PrefixApplicationPrefixWrapper = ({ prefixApplication, onUpdate }: Props) => {
  const [generatePrefix, setGeneratePrefix] = useState<boolean>(false);

  const handleSelectMasterPrefix = (masterPrefix: MasterPrefix, prefixLength: string) => {
    setGeneratePrefix(false);
    const newApplication = {
      ...prefixApplication,
      prefixSize: prefixLength,
      masterPrefixToUse: masterPrefix.masterPrefix,
    };
    onUpdate(newApplication);
  };

  return generatePrefix ? (
    <GeneratePrefix
      onCancel={() => setGeneratePrefix(false)}
      onSave={handleSelectMasterPrefix}
      requestedLength={prefixApplication.prefixSize}
    />
  ) : (
    <PrefixApplicationPrefixReview
      prefixApplication={prefixApplication}
      generatePrefix={() => setGeneratePrefix(true)}
    />
  );
};

export default PrefixApplicationPrefixWrapper;
