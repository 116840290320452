import CloseIcon from '@mui/icons-material/Close';
import { Theme } from '@mui/material';
import IconButton from '@mui/material/IconButton/IconButton';
import SnackbarContent from '@mui/material/SnackbarContent/SnackbarContent';
import amber from '@mui/material/colors/amber';
import green from '@mui/material/colors/green';
import { WithStyles, createStyles, withStyles } from '@mui/styles';
import classNames from 'classnames';
import React from 'react';

type ContentProps = WithStyles<typeof styles> & {
  onClose: () => void;
  variant: 'success' | 'warning' | 'error' | 'info';
  message: string;
  className: string;
};

const styles = (theme: Theme) =>
  createStyles({
    success: {
      backgroundColor: green[600],
    },
    error: {
      backgroundColor: theme.palette.error.dark,
    },
    info: {
      backgroundColor: theme.palette.primary.dark,
    },
    warning: {
      backgroundColor: amber[700],
    },
    icon: {
      fontSize: 20,
    },
    iconVariant: {
      opacity: 0.9,
      marginRight: theme.spacing(1),
    },
    message: {
      display: 'flex',
      alignItems: 'center',
    },
  });

const Content = ({ variant, onClose, message, className, classes }: ContentProps) => (
  <SnackbarContent
    className={classNames(classes[variant], className)}
    aria-describedby="snackbar-message"
    message={message}
    action={[
      <IconButton key="close" aria-label="Close" color="inherit" onClick={onClose} size="large">
        <CloseIcon />
      </IconButton>,
    ]}
  />
);

export default withStyles(styles)(Content);
