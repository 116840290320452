import React from 'react';

import MuiButton, { ButtonProps as MuiButtonProps } from '@mui/material/Button';
import SvgIcon from '@mui/material/SvgIcon';

import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classnames from 'classnames';
import { Link, LinkProps } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) => ({
  secondary: {
    background: 'transparent',
    color: theme.palette.text.primary,
    borderColor: theme.palette.primary.main,
    '&:hover': {
      background: theme.palette.buttons.secondaryHover,
      borderColor: theme.palette.buttons.secondaryHover,
      color: theme.palette.secondary.contrastText,
    },
  },
  error: {
    background: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    borderColor: theme.palette.error.main,
    '&:hover': {
      background: theme.palette.error.dark,
      borderColor: theme.palette.error.dark,
      color: theme.palette.error.contrastText,
    },
  },
  user: {
    background: 'transparent',
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    borderRadius: '10rem',
    padding: '.4rem 1.5rem .3rem 1.5rem',
    fontSize: '1.2rem',
    textTransform: 'none',
    '&:hover': {
      background: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
  round: {
    borderRadius: '50%',
    minWidth: 0,
    padding: theme.spacing(1),
    // ':hover': {
    //   backgroundColor: theme.palette.buttons.primaryGreen,
    // },
  },
  roundSmall: {
    padding: theme.spacing(0.5),
  },
  iconStyle: {
    marginRight: theme.spacing(1),
    width: '1.6rem',
  },
  // link: {
  //   display: 'flex',
  //   color: 'inherit',
  //   textDecoration: 'none',
  // },
}));

export type ButtonProps = MuiButtonProps & {
  icon?: string;
  secondary?: boolean;
  error?: boolean;
  user?: boolean;
  round?: boolean;
  link?: LinkProps['to'];
  children: React.ReactNode;
  className?: string;
  openInNewWindow?: boolean;
};

const Button = React.forwardRef<HTMLButtonElement | HTMLAnchorElement, ButtonProps>(
  (props, ref) => {
    const {
      icon,
      secondary,
      user,
      children,
      round,
      className,
      link,
      size,
      error,
      openInNewWindow,
      ...other
    } = props;
    const classes = useStyles();

    const groupedClassName = classnames(className, {
      [classes.secondary]: secondary === true,
      [classes.error]: error === true,
      [classes.user]: user === true,
      // [classes.primaryGreen]: primaryGreen === true,
      [classes.round]: round === true,
      [classes.roundSmall]: round === true && size === 'small',
    });

    const BareButton = (
      <>
        {icon && (
          <SvgIcon viewBox="0 0 16 16" className={classes.iconStyle}>
            <path d={icon} />
          </SvgIcon>
        )}

        {children}
      </>
    );

    const BtnLink = link
      ? openInNewWindow
        ? React.forwardRef<HTMLAnchorElement, LinkProps>((linkProps, ref) => (
            <a
              ref={ref}
              {...linkProps}
              href={link as string}
              target="_blank"
              rel="noopener noreferrer"
              children={linkProps.children}
            />
          ))
        : React.forwardRef<HTMLAnchorElement, LinkProps>((linkProps, ref) => (
            <Link ref={ref} {...linkProps} to={link} children={linkProps.children} />
          ))
      : null;

    return (
      <MuiButton
        component={BtnLink ? BtnLink : 'button'}
        {...other}
        className={groupedClassName}
        sx={{ alignItems: 'center' }}
        size={size}
        ref={ref}
      >
        {BareButton}
      </MuiButton>
    );
  }
);

export default Button;
