import { Divider as MuiDivider, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  divider: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
  },
}));

const Divider = () => {
  const classes = useStyles();

  return <MuiDivider className={classes.divider} />;
};

export default Divider;
