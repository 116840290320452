import { useFetch } from '@react-redux-fetch/hooks';
import { difference } from 'ramda';
import React, { useEffect, useState } from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';
import Button from '../../../../components/Button';
import { Organisation, UserRole } from '../../../../config/api/types';
import { useAsyncValidation } from '../../../../helpers/finalFormAsyncValidation';
import { getLink } from '../../../../helpers/hateoas';
import { updateOrganisationRequest } from '../../api';
import { organisationFormToOrganisationModel } from '../../dtoMapper';
import { EditForm } from '../components/Organisation/index';
import RoleConfirmationForm from './RoleConfirmationForm';
import RolesForm from './RolesForm';

type Params = { id: string };
type Props = RouteComponentProps<Params> & {
  organisation: Organisation;
};

const UpdateForm = ({ organisation }: Props) => {
  const { t } = useTranslation();
  const [newRoles, setNewRoles] = useState<string[]>([]);
  const organisationUrl = organisation._links.self.href;
  const [orgFetch, updateOrganisation] = useFetch(updateOrganisationRequest);
  const [needScroll, setNeedScroll] = useState<boolean>(false);
  const { createSubmissionPromise } = useAsyncValidation(orgFetch);

  useEffect(() => {
    if (needScroll && orgFetch?.fulfilled && !orgFetch.pending) {
      window.scrollTo(0, 0);
      setNeedScroll(false);
    }
  }, [needScroll, orgFetch]);

  const organisationRoles = Object.keys(organisation.roles || []) as UserRole[];

  return (
    <Form
      initialValues={{
        ...organisation,
        roles: organisationRoles,
      }}
      keepDirtyOnReinitialize
      onSubmit={(values: { roles: string[] }) => {
        if (!organisation) {
          return;
        }

        const roles = difference(values.roles, organisationRoles);

        if (roles.length > 0) {
          setNewRoles(roles);
          return;
        }

        updateOrganisation(
          organisationUrl,
          organisationFormToOrganisationModel(values, organisation)
        );
        return createSubmissionPromise();
      }}
      key={getLink(organisation, 'self')}
    >
      {({ handleSubmit, submitting, values, form, ...other }) => {
        return (
          <form onSubmit={handleSubmit}>
            <EditForm />
            {/*<DropZone />*/}
            <RolesForm
              selectedRoles={values.roles as UserRole[]}
              organisationRoles={organisationRoles}
            />
            <Button type="submit" disabled={submitting}>
              {t('form_action_save')}
            </Button>

            {organisation && newRoles.length > 0 && (
              <RoleConfirmationForm
                roles={newRoles}
                organisation={organisation}
                onClose={() => {
                  form.change('roles', organisationRoles);
                  setNewRoles([]);
                }}
                onSubmit={() => {
                  updateOrganisation(
                    organisationUrl,
                    organisationFormToOrganisationModel(values, organisation)
                  );
                  setNewRoles([]);
                  setNeedScroll(true);
                }}
              />
            )}
          </form>
        );
      }}
    </Form>
  );
};
export default UpdateForm;
