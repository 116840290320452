const urlencode = (str: string) => {
  str = str + '';

  return (
    encodeURIComponent(str)
      .replace(/!/g, '%21')
      .replace(/'/g, '%27')
      .replace(/\(/g, '%28')
      .replace(/\)/g, '%29')
      .replace(/\*/g, '%2A')
      .replace(/~/g, '%7E')
      // .replace(/%20/g, '+')
      // Hateoas bundle at Symfony side
      .replace(/%3A/g, ':')
  );
};

export default urlencode;
