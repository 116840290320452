import { Add as AddIcon } from '@mui/icons-material';
import { Box } from '@mui/material';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDisclosure } from '../../../helpers/useDisclosure';
import { ConfirmRetourButton } from './confirmRetour';
import { RetourLineForm } from './retourLine/RetourLineForm';
import { CreateUnconfirmedRetourButton, UnconfirmedRetourLines } from './unconfirmedRetour';
import { useFetchUnconfirmedRetour } from './unconfirmedRetour/useFetchUnconfirmedRetour';

export const RetourLines = () => {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure(false);

  const { unconfirmedRetour } = useFetchUnconfirmedRetour();

  const hasLines = Boolean(unconfirmedRetour?.lines.length);

  useEffect(() => {
    if (hasLines) {
      onOpen();
    }
  }, [hasLines, onOpen]);

  return (
    <div>
      <Box mb={3} mt={3}>
        {isOpen && unconfirmedRetour?.id ? (
          <RetourLineForm onClose={onClose} retourId={unconfirmedRetour.id} />
        ) : (
          <CreateUnconfirmedRetourButton onClick={onOpen} retourId={unconfirmedRetour?.id}>
            <AddIcon /> {t('return_add_line_title')}
          </CreateUnconfirmedRetourButton>
        )}
      </Box>
      {unconfirmedRetour && unconfirmedRetour.lines.length > 0 && (
        <UnconfirmedRetourLines retourLines={unconfirmedRetour.lines} />
      )}
      <Box mt={3} textAlign="right">
        <ConfirmRetourButton unconfirmedRetour={unconfirmedRetour} />
      </Box>
    </div>
  );
};
