import ClearIcon from '@mui/icons-material/Clear';
import moment from 'moment';
import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { StringParam, useQueryParam } from 'use-query-params';
import Button from '../../../../components/Button';
import { QueryObject } from '../../../../helpers/elasticQueryDSL';
import mapDateRange from '../../../../helpers/mapDateRange';
import security from '../../../security';
import { FilterObject } from '../useFetchTitles';
import AgeRangeFacet from './facets/AgeRangeFacet';
import AvailabilityFacet from './facets/AvailabilityFacet';
import AviLevelsFacet from './facets/AviLevelsFacet';
import CollectionFacet from './facets/CollectionFacet';
import EditionTypeFacet from './facets/EditionTypeFacet';
import FirstPublishedDateRangeFacet from './facets/FirstPublishedDateRangeFacet';
import FundsFacet from './facets/FundsFacet';
import GenericTextFieldFacet from './facets/GenericTextFieldFacet';
import LanguageFacet from './facets/LanguageFacet';
import PersonalFacet from './facets/PersonalFacet';
import ProductFormGroupFacet from './facets/ProductFormGroupFacet';
import PublisherFacet from './facets/PublisherFacet';
import { ThemaFacet } from './facets/ThemaFacet';
import { ThemaQualifierFacet } from './facets/ThemaQualifierFacet';

type Props = {
  filters: FilterObject;
  onFilter: (queryObj: QueryObject, sortingParams?: Record<string, string | number>) => void;
  changeParams: (values: Record<string, string | number>) => void;
  onClear: () => void;
  hasActiveFilters: boolean;
};

const Facets: FC<Props> = ({ onFilter, filters, onClear, hasActiveFilters, changeParams }) => {
  const { t } = useTranslation();
  const userRoles = useSelector(security.selectors.getUserRole);
  const [, setContributorName] = useQueryParam('contributors.name', StringParam);
  const [, setCollectionTitle] = useQueryParam('collection.title.keyword', StringParam);
  const [, setEditionTypes] = useQueryParam('editionTypes.code', StringParam);
  const [, setImprint] = useQueryParam('imprint', StringParam);

  const firstPublishedDateFilter = mapDateRange(
    filters['isbnFirstPublishedDate'] as string | undefined
  );

  const isBookstore = userRoles.includes('ROLE_BOOKSTORE');
  const isLibrary = userRoles.includes('ROLE_LIBRARY');

  const handleIsOrderableFilter = (isOrderable: boolean) => {
    if (isBookstore) {
      return onFilter({ ...filters, isOrderableByBookstore: isOrderable });
    } else if (isLibrary) {
      return onFilter({ ...filters, isOrderableByLibrary: isOrderable });
    }
    return onFilter({
      ...filters,
      isOrderableByBookstore: isOrderable,
      isOrderableByLibrary: isOrderable,
    });
  };
  const handleNoEbooksFilter = (noEbooks: boolean) => {
    return onFilter({
      ...filters,
      'NOT productForm.group.code': noEbooks ? 'e-book' : '',
    });
  };
  const handleThemaFilter = useCallback(
    (themas: string[]) => onFilter({ ...filters, 'themas.code': themas }),
    [filters, onFilter]
  );
  const handleThemaQualifierFilter = useCallback(
    (themaQualifiers: string[]) =>
      onFilter({ ...filters, 'themaQualifiers.code': themaQualifiers }),
    [filters, onFilter]
  );

  const handleFilterByAuthor = useCallback(
    (value: string) => onFilter({ ...filters, 'contributors.name': value }),
    [filters, onFilter]
  );

  const themaFacet = (
    <>
      <ThemaFacet value={filters['themas.code']} onFilter={handleThemaFilter} />
      <ThemaQualifierFacet
        value={filters['themaQualifiers.code']}
        onFilter={handleThemaQualifierFilter}
      />
    </>
  );
  const productFormFacet = (
    <ProductFormGroupFacet
      onFilter={(values) => onFilter({ ...filters, 'productForm.group.code': values })}
      filters={filters['productForm.group.code']}
    />
  );
  const fundsFacet = (
    <FundsFacet
      value={filters['fund.code']}
      onFilter={(value) => onFilter({ ...filters, 'fund.code': value })}
    />
  );
  const languageFacet = (
    <LanguageFacet
      value={filters['languages.code']}
      onFilter={(values) => onFilter({ ...filters, 'languages.code': values })}
    />
  );

  const editionTypeFacet = (
    <EditionTypeFacet
      value={filters['editionTypes.code']}
      onFilter={(value) =>
        onFilter({
          ...filters,
          'editionTypes.code': value || '',
        })
      }
    />
  );

  return (
    <>
      {hasActiveFilters && (
        <Button
          onClick={() => {
            setContributorName('');
            setCollectionTitle('');
            setImprint('');
            setEditionTypes('');
            onClear();
          }}
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: 6,
          }}
          size="small"
        >
          {t('facet_clearAll')} <ClearIcon fontSize="small" />
        </Button>
      )}
      <AvailabilityFacet
        onFilterIsOrderable={handleIsOrderableFilter}
        isOrderable={
          filters['isOrderableByBookstore'] === 'true' || filters['isOrderableByLibrary'] === 'true'
        }
        onFilterNoEbooks={handleNoEbooksFilter}
        noEbooks={filters['NOT productForm.group.code'] === 'e-book'}
      />
      <PersonalFacet
        onFilter={({ publisherOrgId, fundOrgId }) => {
          onFilter({
            ...filters,
            'fund.organisation.organisationId': fundOrgId || '',
            'publisher.organisation.organisationId': publisherOrgId || '',
          });
        }}
        filters={{
          fundOrgId: filters['fund.organisation.organisationId'] as string,
          publisherOrgId: filters['publisher.organisation.organisationId'] as string,
        }}
      />
      {isBookstore || isLibrary ? languageFacet : fundsFacet}
      {!(isBookstore || isLibrary) && productFormFacet}
      <GenericTextFieldFacet
        label={t('facet_author')}
        placeholder={t('facet_author_placeholder')}
        value={filters['contributors.name']}
        onFilter={handleFilterByAuthor}
        isClearable
        queryName="contributors.name"
      />
      <PublisherFacet
        value={filters['publisher.organisation.organisationId']}
        onFilter={(value) =>
          onFilter({ ...filters, 'publisher.organisation.organisationId': value })
        }
      />
      <GenericTextFieldFacet
        label={t('facet_imprint')}
        placeholder={t('facet_imprint_placeholder')}
        value={filters['imprint']}
        onFilter={(value) => onFilter({ ...filters, imprint: value })}
        isClearable
        queryName="imprint"
      />
      <FirstPublishedDateRangeFacet
        filters={{
          from: Boolean(firstPublishedDateFilter[0])
            ? moment(firstPublishedDateFilter[0])
            : undefined,
          to: Boolean(firstPublishedDateFilter[1])
            ? moment(firstPublishedDateFilter[1])
            : undefined,
        }}
        active={Boolean(firstPublishedDateFilter[0]) || Boolean(firstPublishedDateFilter[1])}
        onFilter={(from, to) =>
          onFilter({
            ...filters,
            isbnFirstPublishedDate:
              from || to
                ? `[${from?.format('YYYY-MM-DD') || '*'} TO ${to?.format('YYYY-MM-DD') || '*'}]`
                : '',
          })
        }
      />
      {(isBookstore || isLibrary) && themaFacet}
      {isBookstore || isLibrary ? fundsFacet : languageFacet}
      <CollectionFacet
        value={
          filters['collection.title.keyword']
            ? decodeURIComponent(filters['collection.title.keyword'] as string)
            : ''
        }
        onFilter={(collectionTitle) => {
          onFilter(
            {
              ...filters,
              'collection.title.keyword': collectionTitle || '',
            },
            {
              ...(collectionTitle != null
                ? { order: 'collection.number.keyword', orderDir: 'desc' }
                : { order: '', orderDir: '' }),
            }
          );
        }}
      />
      {editionTypeFacet}
      {isBookstore || isLibrary ? productFormFacet : themaFacet}
      <AviLevelsFacet
        filters={{
          aviLevelsNewCode: filters['aviLevelsNew.code'],
          aviLevelsOldCode: filters['aviLevelsOld.code'],
        }}
        onFilter={(aviLevelsNewCode, aviLevelsOldCode) =>
          onFilter({
            ...filters,
            'aviLevelsNew.code': aviLevelsNewCode || false,
            'aviLevelsOld.code': aviLevelsOldCode || false,
          })
        }
      />
      <AgeRangeFacet
        filters={{ ageRangeGroup: filters['ageRange.group'] }}
        onFilter={(ageRangeGroup?: string) =>
          onFilter({
            ...filters,
            'ageRange.group': ageRangeGroup || '',
          })
        }
      />
    </>
  );
};

export default Facets;
