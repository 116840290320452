import { Grid, Theme } from '@mui/material';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import cxs from 'classnames';
import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { OrderFile } from '../../../../../config/api/models/shop';
import { TitleFile } from '../../../../../config/api/types';
import DownloadProcessResult from '../containers/DownloadProcessResult';

type Props = {
  fileInfo: TitleFile | OrderFile | undefined;
  positionTweak?: boolean;
};
const useStyles = makeStyles((theme: Theme) => ({
  bold: {
    fontWeight: 500,
  },
  line: {
    '& > td': {
      verticalAlign: 'top',
      paddingTop: 4,
      paddingBottom: 4,
    },
    '& > td:first-child': {
      width: 140,
    },
  },
  feedback: {
    textAlign: 'center',
    width: 270,
    padding: '4px 32px 4px 32px',
    borderRadius: 5,
    fontWeight: 500,
    color: theme.palette.secondary.main,
    marginBottom: theme.spacing(1),
  },
  processed: {
    backgroundColor: theme.palette.buttons.highlight,
  },
  failed: {
    backgroundColor: theme.palette.messages.error,
  },
  positionTweak: {
    position: 'absolute',
    bottom: -70,
  },
}));

const Info = ({ fileInfo, positionTweak }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  if (!fileInfo) {
    return null;
  }

  const renderItem = (header: string, content: string | undefined) => {
    if (!content) {
      return null;
    }

    return (
      <tr className={classes.line}>
        <td>
          <Typography className={classes.bold}>{header}:</Typography>
        </td>
        <td>
          <Typography>{content}</Typography>
        </td>
      </tr>
    );
  };

  return (
    <Grid container spacing={1}>
      <Grid xs={8} lg={9} xl={10} item>
        <table>
          <tbody>
            {renderItem(t('table_headers_filename'), fileInfo.path)}
            {renderItem(t('table_headers_uploaded'), fileInfo.uploadedAt)}
            {renderItem(t('table_headers_processed'), fileInfo.processedAt)}
            {renderItem(t('table_headers_through'), fileInfo.source)}
          </tbody>
        </table>
      </Grid>
      <Grid xs={4} lg={3} xl={2} item style={{ position: 'relative' }}>
        <Grid
          container
          sx={{ justifyContent: 'flex-end' }}
          className={clsx(positionTweak && classes.positionTweak)}
        >
          <DownloadProcessResult titleFile={fileInfo} />

          <Typography className={cxs(classes.feedback, classes.processed)}>
            {t('titles_processed', { count: fileInfo.numSuccess })}
          </Typography>
          <Typography className={cxs(classes.feedback, classes.failed)}>
            {t('titles_not_processed', { count: fileInfo.numTotal - fileInfo.numSuccess })}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Info;
