import CircleIcon from '@mui/icons-material/FiberManualRecord';
import { ListItem, ListItemIcon, ListItemProps, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import cxs from 'classnames';
import React from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    //
  },
  legendCircle: {
    minWidth: 24,
  },
  legendCircleIcon: {
    width: 16,
    height: 16,
  },
}));

type Props = ListItemProps & {
  color?: string;
  children?: React.ReactChild;
};

const LegendListItem = ({ color, children, className, ...props }: Props) => {
  const classes = useStyles();

  return (
    <ListItem disableGutters className={cxs(classes.root, className)}>
      <ListItemIcon className={classes.legendCircle}>
        <CircleIcon className={classes.legendCircleIcon} style={{ color: color }} />
      </ListItemIcon>
      {children}
    </ListItem>
  );
};

export default LegendListItem;
