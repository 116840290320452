import * as React from 'react';
import { SelectField as Select } from '../../../../../../components/Form/index';
import { useTranslation } from 'react-i18next';
import { SelectFieldProps } from '../../../../../../components/Form/SelectField';

type Props = {
  onChange: SelectFieldProps['onChange'];
  initialValue?: SelectFieldProps['value'];
};

const IsCbMember = ({ onChange, initialValue }: Props) => {
  const { t } = useTranslation();

  const items = {
    true: t('filter_option_yes'),
    false: t('filter_option_no'),
  };
  return (
    <div>
      <Select
        items={items}
        onChange={onChange}
        label={t('filter_cb_member')}
        value={initialValue}
      />
    </div>
  );
};

export default IsCbMember;
