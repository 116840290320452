import React, { FC, useEffect, useMemo } from 'react';
import BaseReactSelectField from '../../../../../components/Form/BaseReactSelectField';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import security from '../../../../security';
import { getLink } from '../../../../../helpers/hateoas';
import getIdFromUrl from '../../../../../helpers/getIdFromUrl';
import FacetTitle from '../../../catalog/components/Facet/FacetTitle';

type Props = {
  isDistributor: boolean;
  isPublisher: boolean;
  value?: any;
  onChange: (name: string, value: any) => void;
};
const PersonalFilter: FC<Props> = ({ isDistributor, isPublisher, value, onChange }) => {
  const { t } = useTranslation();
  const user = useSelector(security.selectors.getUser);
  const orgLink = getLink(user, 'organisation');
  const orgId = orgLink ? getIdFromUrl(orgLink) : '';

  const options = useMemo(
    () =>
      [
        isDistributor && {
          label: t('facet_own_titles', { context: 'distributor' }),
          value: { 'fund.organisation.organisationId': orgId },
        },
        isPublisher && {
          label: t('facet_own_titles', { context: 'publisher' }),
          value: { 'publisher.organisation.organisationId': orgId },
        },
      ].filter(Boolean),
    [isDistributor, isPublisher, orgId, t]
  );

  useEffect(() => {
    if (!value) {
      onChange('personal', options[0]);
    }
  }, [onChange, options, value]);

  return (
    <>
      <FacetTitle>{t('facet_personal')}</FacetTitle>
      <BaseReactSelectField name="personal" options={options} disabled={options.length === 1} />
    </>
  );
};

export default PersonalFilter;
