import { Grid } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Field from '../../../../../components/Form';
import FacetTitle from '../../../catalog/components/Facet/FacetTitle';

const FirstPublishedDateRangeFilter = () => {
  const { t } = useTranslation();
  return (
    <>
      <FacetTitle>{t('facet_firstPublishedDateRange')}</FacetTitle>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <Field type="date" name="from" placeholder={t('from')} isClearable />
        </Grid>

        <Grid item xs={6}>
          <Field type="date" name="to" placeholder={t('to')} isClearable />
        </Grid>
      </Grid>
    </>
  );
};

export default FirstPublishedDateRangeFilter;
