import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  lighten,
  useTheme,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import React, { FC, ReactNode, useState } from 'react';
import FacetTitle from './FacetTitle';

type Props = {
  title: string;
  collapsible?: boolean;
  active?: boolean;
  children?: ReactNode;
};

type EPProps = { isActive?: Boolean };
const Accordion = withStyles((theme) => ({
  root: {
    borderRadius: '8px',
    boxShadow: ({ isActive }: EPProps) =>
      isActive ? `-1px 2px 5px ${theme.palette.primary.main}` : 'none',
    backgroundColor: ({ isActive }: EPProps) =>
      isActive ? lighten(theme.palette.primary.light, 0.9) : 'transparent',
    padding: ({ isActive }: EPProps) =>
      isActive
        ? `${theme.spacing(1)} ${theme.spacing(0.75)}`
        : `${theme.spacing(0.5)} ${theme.spacing(0.75)}`,
    '& .MuiFormLabel-root': {
      color: ({ isActive }: EPProps) => (isActive ? theme.palette.primary.main : 'inherit'),
    },
    '&$expanded': {
      marginTop: 0,
      marginBottom: 0,
    },
  },
  expanded: {},
}))(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    padding: 0,
    '&$expanded': {
      minHeight: 0,
    },
  },
  expanded: {
    '& .MuiAccordionSummary-content': {
      marginTop: 4,
      marginBottom: 0,
    },
    '& .MuiIconButton-root': {
      padding: 4,
    },
  },
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: 0,
    flexDirection: 'column',
  },
}))(MuiAccordionDetails);

const FacetWrapper: FC<Props> = ({ title, children, collapsible, active }) => {
  const [expanded, setExpanded] = useState(true);
  const onToggle = () => setExpanded((prevState) => !prevState);
  const theme = useTheme();

  return (
    <Accordion
      expanded={collapsible ? expanded : true}
      onChange={onToggle}
      isActive={active}
      style={{
        marginTop: active ? theme.spacing(1) : 0,
        marginBottom: active ? theme.spacing(1) : 0,
      }}
    >
      <AccordionSummary expandIcon={collapsible ? <ExpandMoreIcon /> : undefined}>
        <FacetTitle>{title}</FacetTitle>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};

export default FacetWrapper;
