import immutable from 'seamless-immutable';
import { Action } from 'redux';
import { FETCH } from 'react-redux-fetch';
// @ts-ignore
import { INIT } from 'react-redux-fetch/lib/constants/request';
// @ts-ignore
import fetchRequest from 'react-redux-fetch/lib/utils/fetchRequest';
// @ts-ignore
import fetchFulfill from 'react-redux-fetch/lib/utils/fetchFulfill';
// @ts-ignore
import fetchReject from 'react-redux-fetch/lib/utils/fetchReject';

const INITIAL_STATE = {
  ...INIT,
  value: null,
  request: { meta: {} },
};

const optionsReducer = (state = immutable(INITIAL_STATE), action: Action) => {
  switch (action.type) {
    case FETCH.for('PATCH').REQUEST:
      return fetchRequest(state, action);
    case FETCH.for('PATCH').FULFILL:
      return fetchFulfill(state, action);
    case FETCH.for('PATCH').REJECT:
      return fetchReject(state, action);
    default:
      return state;
  }
};

export default optionsReducer;
