import React from 'react';
import SmallCenterLayout from '../components/SmallCenterLayout';
import ForgotPasswordForm from '../containers/ForgotPasswordForm';

const ForgotPasswordPage = () => {
  return (
    <SmallCenterLayout>
      <ForgotPasswordForm />
    </SmallCenterLayout>
  );
};

export default ForgotPasswordPage;
