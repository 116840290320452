import { Theme } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import MUITableRow from '@mui/material/TableRow';
import { makeStyles } from '@mui/styles';
import cx from 'classnames';
import { addIndex, map } from 'ramda';
import React from 'react';
import { useHistory } from 'react-router-dom';
import ArrayCell from './cells/ArrayCell';
import BooleanCell from './cells/BooleanCell';
import { Cell } from './types';
const mapWithKeys = addIndex(map);

// type CellType = {
//   type: 'text' | 'action' | 'Boolean' | 'Array';
//   data: ReactNode | string;
// };

type Props = {
  row: Cell[];
  link?: string;
  linkState?: Record<string, unknown>;
  dense?: boolean;
  noBorder?: boolean;
  variant?: 'red';
};

const useStyles = makeStyles((theme: Theme) => ({
  tableRow: {
    height: 48,
  },
  tableRowDense: {
    height: 32,
  },
  clickableRow: {
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
      cursor: 'pointer',
      textDecoration: 'underline',
    },
  },
  redRowVariant: {
    backgroundColor: theme.palette.error.light,
    '&:hover': {
      backgroundColor: theme.palette.error.light,
    },
  },
  cell: {
    paddingLeft: 0,
    paddingRight: theme.spacing(2),
  },
  cellNoBorder: {
    border: 'none',
  },
}));

const TableRow = ({ row, link, linkState, dense, noBorder, variant }: Props) => {
  const classes = useStyles();
  const history = useHistory();

  const tableRowClassName = cx(classes.tableRow, {
    [classes.tableRowDense]: dense === true,
    [classes.clickableRow]: link,
    [classes.redRowVariant]: variant === 'red',
  });

  const tableCellClassName = cx(classes.cell, {
    [classes.cellNoBorder]: noBorder === true,
  });

  const handleClick = () => {
    if (link) {
      history.push(link, linkState);
    }
  };

  return (
    <MUITableRow className={tableRowClassName} onClick={handleClick}>
      {mapWithKeys((cell: any, index: number) => (
        <React.Fragment key={`cell${index}`}>
          {cell.type === 2 ? (
            <BooleanCell
              className={tableCellClassName}
              data={cell.data}
              trueOnly={cell.trueOnly}
              align={cell.align}
              style={cell.style || {}}
            />
          ) : cell.type === 3 ? (
            <ArrayCell
              className={tableCellClassName}
              data={cell.data}
              align={cell.align}
              style={cell.style || {}}
            />
          ) : (
            <TableCell className={tableCellClassName} align={cell.align} style={cell.style || {}}>
              {/*<Typography>{cell.data}</Typography>*/}
              {cell.data}
            </TableCell>
          )}
        </React.Fragment>
      ))(row as Cell[])}
    </MUITableRow>
  );
};

export default TableRow;
