import React from 'react';
import { TitleFull } from '../../../../config/api/types';
import ListHeading from './ListHeading';
import { useTranslation } from 'react-i18next';
import List from './List';
import ListItem from './ListItem';
import { getGroupedContributors } from '../../domain';
import ContributorList from './ContributorList';

type Props = { title: TitleFull };

const TitleContributors = ({ title }: Props) => {
  const { t } = useTranslation();
  const groupedContributors = getGroupedContributors(title);

  return (
    <>
      <ListHeading>{t('title_heading_contributors')}</ListHeading>
      <List>
        <>
          {Object.keys(groupedContributors).map((role) => (
            <ListItem
              label={role}
              value={<ContributorList contributors={groupedContributors[role]} />}
              key={role}
            />
          ))}
        </>
      </List>
    </>
  );
};

export default TitleContributors;
