import { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useForm } from 'react-final-form';
import { Maybe } from '../../config/api/types';

type Props = {
  name: string;
  dataSelector: (state: any) => Maybe<Array<any>>;
  findValue: (item: any) => boolean;
  getValue?: (item: any) => any;
};

const SetInitialValueWhenLoaded: FC<Props> = ({ name, dataSelector, findValue, getValue }) => {
  const { change, getFieldState } = useForm();

  const data = useSelector(dataSelector);
  const touched = getFieldState(name)?.touched;

  useEffect(() => {
    if (!touched && data) {
      const found = data.find(findValue);
      if (found) {
        change(name, getValue ? getValue(found) : found);
      }
    }
  }, [data, change, name, findValue, touched, getValue]);

  return null;
};

export default SetInitialValueWhenLoaded;
