import { Grid, MenuItem, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useFetch } from '@react-redux-fetch/hooks';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Button from '../../../../components/Button';
import DelayedLoader from '../../../../components/DelayedLoader';
import { BaseTextField } from '../../../../components/Form/TextField';
import { MasterPrefix } from '../../../../config/api/types';
import useApiRoute from '../../../../config/api/useApiRoute';
import { updateQueryParameter } from '../../../../helpers/hateoas';
import { getMasterPrefixesRequest } from '../../api';
import { getMasterPrefixes } from '../../selectors';

type Props = {
  onCancel: () => void;
  onSave: (prefix: MasterPrefix, length: string) => void;
  requestedLength?: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  sidebar: {
    borderRight: `2px solid ${theme.palette.background.appbarHover}`,
  },
  mainList: {
    height: 400,
    overflowY: 'scroll',
  },
}));

const prefixLengthOptions = ['1', '10', '100', '1000'];

const GeneratePrefix = ({ onCancel, onSave, requestedLength }: Props) => {
  const { t } = useTranslation();
  const [prefixLength, setPrefixLength] = useState<string>(requestedLength || '1');
  const [selectedPrefix, setSelectedPrefix] = useState<MasterPrefix | undefined>(undefined);
  const [prefixRequest, fetchPrefixes] = useFetch(getMasterPrefixesRequest);
  const masterPrefixUrl = useApiRoute('masterPrefixes');
  const masterPrefixes = useSelector(getMasterPrefixes);
  const classes = useStyles();

  useEffect(() => {
    if (masterPrefixUrl) {
      const url = updateQueryParameter(masterPrefixUrl, 'for_prefix_size', prefixLength);
      fetchPrefixes(url);
    }
  }, [masterPrefixUrl, prefixLength, fetchPrefixes]);

  const handleSave = () => {
    if (selectedPrefix) {
      onSave(selectedPrefix, prefixLength);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item container spacing={1}>
        <Grid item className={classes.sidebar} xs={2}>
          <BaseTextField
            label={t('isbn_prefix_amount_label')}
            variant="outlined"
            select
            value={prefixLength}
            onChange={(event) => setPrefixLength(event.target.value)}
          >
            {prefixLengthOptions.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </BaseTextField>
        </Grid>

        <Grid item className={classes.mainList} xs={10}>
          <DelayedLoader data={!(prefixRequest && prefixRequest.pending)}>
            {masterPrefixes &&
              masterPrefixes._embedded.items.map((mp) => (
                <MenuItem
                  key={mp.masterPrefix}
                  onClick={() => setSelectedPrefix(mp)}
                  selected={selectedPrefix?.masterPrefix === mp.masterPrefix}
                >
                  {t('form_isbn_prefix_radio_label', {
                    prefix: mp.masterPrefix,
                    amount: mp.freeIsbnCount,
                  })}
                </MenuItem>
              ))}
          </DelayedLoader>
        </Grid>
      </Grid>

      <Grid item container spacing={1} sx={{ justifyContent: 'flex-end' }}>
        <Grid item>
          <Button onClick={handleSave} disabled={!selectedPrefix}>
            {t('form_save')}
          </Button>
        </Grid>
        <Grid item>
          <Button onClick={onCancel}>{t('form_cancel')}</Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default GeneratePrefix;
