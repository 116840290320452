import moment, { Moment, isMoment } from 'moment';
import { TreeData } from '../../../../components/Form/TreeSelect/types';
import {
  Code,
  Thema,
  ThemaQualifier,
  ThemaQualifiers,
  Themas,
} from '../../../../config/api/models/dataSets';
import { EBookType, ProductForm, Title, TitleFull } from '../../../../config/api/types';
import { API_DATE_FORMAT, ONIX_DATE_FORMAT } from '../../../../helpers/date';
import formatMomentInvalidDate from '../../../../helpers/formatMomentInvalidDate';
import {
  durationToMinutes,
  isBackCover,
  isFrontCover,
  isMediaSample,
  isNotSelectableThemaQualifier,
  roundedMMToCM,
} from '../../domain';
import { FormValues } from './domain';

export const mapTitleDTO = function (titleDto: TitleFull) {
  const media = titleDto._embedded?.media;
  const frontCover = media && media.find(isFrontCover);
  const backCover = media && media.find(isBackCover);
  const mediaSample = media && media.find(isMediaSample);

  return {
    toFormValues(): FormValues {
      return {
        ...titleDto,
        awards:
          titleDto?.titleLiteraturePrizes?.map((award) => ({
            result: {
              result: award.result,
              code: award.code,
            },
            literaturePrize: award.literaturePrize,
            year: award.year,
          })) ?? [],
        keywords: titleDto.keywords?.map(({ keyword }) => ({ value: keyword, label: keyword })),
        collection:
          titleDto.collection !== undefined
            ? {
                title: {
                  value: titleDto.collection?.title ?? '',
                  label: titleDto.collection?.title ?? '',
                },
                number: titleDto.collection?.number,
              }
            : undefined,
        height: titleDto.height ? roundedMMToCM(titleDto.height) : undefined,
        width: titleDto.width ? roundedMMToCM(titleDto.width) : undefined,
        thickness: titleDto.thickness ? roundedMMToCM(titleDto.thickness) : undefined,
        duration: durationToMinutes(titleDto),
        selectedProductForm: titleDto.productForm,
        selectedEBookType:
          titleDto.productForm.details &&
          titleDto.productForm.details.find((detail) => ['E101', 'E116'].includes(detail.code))
            ?.eBookType,
        selectedProductFormDetails:
          titleDto.productForm.details &&
          titleDto.productForm.details.filter((detail) => !['E101', 'E116'].includes(detail.code)),
        selectedSupplyDate: titleDto.availability.supplyDate,
        themas: titleDto.themas || [],
        themaQualifiers: titleDto.themaQualifiers || [],
        priceDescription: titleDto.priceIsFreeOfCharge
          ? 'priceIsFreeOfCharge'
          : titleDto.priceIsToBeAnnounced
          ? 'priceIsToBeAnnounced'
          : '',
        mediaFrontCover: frontCover,
        mediaBackCover: backCover,
        mediaSample,
        selectedReplacedBy:
          typeof titleDto.replacedBy !== 'undefined'
            ? {
                value: titleDto.replacedBy,
                label: titleDto.replacedBy,
              }
            : undefined,
        selectedReplacementOf:
          typeof titleDto.replacementOf !== 'undefined'
            ? {
                value: titleDto.replacementOf,
                label: titleDto.replacementOf,
              }
            : undefined,
        selectedTitleInOtherLanguages:
          typeof titleDto._embedded?.matchingLanguages !== 'undefined'
            ? titleDto._embedded.matchingLanguages.map((title) => ({
                value: title.gtin13,
                label: `${title.gtin13} - ${title.title}${
                  title.languages?.length
                    ? title.languages.length === 1
                      ? ` - ${title.languages[0].label}`
                      : ` - Meerdere talen`
                    : ''
                }`,
                title: title as Title,
              }))
            : undefined,
        _embedded: undefined,
        _links: undefined,
      };
    },
  };
};

export const mapFormValues = function (data: FormValues) {
  return {
    toTitleDTO(gtin13?: string) {
      return {
        ...data,
        ...transform.dimensions(data),
        titleLiteraturePrizes: data.awards?.map((prize) => ({
          code: prize.result.code,
          literaturePrize: prize.literaturePrize,
          ...(prize.year !== '' && { year: prize.year }),
        })),
        keywords: data.keywords?.map((option) => ({ keyword: option.value })),
        collection:
          data.collection !== undefined
            ? { title: data.collection?.title?.value ?? '', number: data.collection?.number }
            : undefined,
        gtin13: data.gtin13 || gtin13,
        ageRange: data.ageRange && {
          from: data.ageRange.from || undefined,
          to: data.ageRange.to || undefined,
        },
        weight: data.weight || null,
        numPages: data.numPages || null,
        discountCode: data.discountCode && data.discountCode.code ? data.discountCode : undefined,
        editionNumber: transform.toNumber(data.editionNumber),
        editionTypes: data.editionTypes?.length
          ? data.editionTypes.filter(({ code }) => code != null)
          : undefined,
        // fund: transform.fund(data.fund),
        legalDepotNumber: data.legalDepotNumber === 'D/____/' ? undefined : data.legalDepotNumber,
        isbnFirstPublished: transform.date(data.isbnFirstPublished, true),
        availability: {
          ...data.availability,
          supplyDate: transform.date(data.selectedSupplyDate, true),
        },
        orderTime: data.orderTime === '' ? undefined : transform.toNumber(data.orderTime),
        productForm: transform.productForm(
          data.selectedProductForm,
          data.productForm,
          data.selectedEBookType,
          data.selectedProductFormDetails
        ),
        priceIsToBeAnnounced: data.priceDescription === 'priceIsToBeAnnounced',
        priceIsFreeOfCharge: data.priceDescription === 'priceIsFreeOfCharge',
        duration: data.duration ? transform.duration(parseInt(data.duration, 10)) : '',
        price: transform.toNumber(data.price),
        priceAction:
          data.priceAction &&
          (data.priceAction.price || data.priceAction.startDate || data.priceAction.endDate)
            ? {
                ...data.priceAction,
                price: transform.toNumber(data.priceAction.price),
                startDate: transform.date(data.priceAction.startDate),
                endDate: transform.date(data.priceAction.endDate),
              }
            : null,
        priceRegulated:
          data.priceRegulated && (data.priceRegulated.price || data.priceRegulated.startDate)
            ? {
                ...data.priceRegulated,
                price: transform.toNumber(data.priceRegulated.price),
                startDate: transform.date(data.priceRegulated.startDate),
              }
            : null,
        priceRegulatedAction:
          data.priceRegulatedAction &&
          (data.priceRegulatedAction.price ||
            data.priceRegulatedAction.startDate ||
            data.priceRegulatedAction.endDate)
            ? {
                ...data.priceRegulatedAction,
                price: transform.toNumber(data.priceRegulatedAction.price),
                startDate: transform.date(data.priceRegulatedAction.startDate),
                endDate: transform.date(data.priceRegulatedAction.endDate),
              }
            : null,
        replacementOf: data.selectedReplacementOf?.value,
        replacedBy: data.selectedReplacedBy?.value,
        titleInOtherLanguages: data.selectedTitleInOtherLanguages?.map(({ value }) => ({
          gtin13: value,
        })),
        nstc: transform.toNumber(data.nstc),
        contributors: data.contributors?.map((c) => ({
          ...c,
          roleCode: c.role.code,
        })),
        workFirstPublished:
          data.workFirstPublished && transform.date(data.workFirstPublished, true),
      };
    },
  };
};

const transform = {
  date: (date?: string | Moment, onix = false) => {
    if (isMoment(date)) {
      return date.isValid()
        ? date.format(onix ? ONIX_DATE_FORMAT : API_DATE_FORMAT)
        : formatMomentInvalidDate(date);
    }
    return date;
  },
  duration: (duration: number) => {
    const mDuration = moment.duration(duration, 'minutes');
    const hours = Math.floor(mDuration.asHours()).toString();
    const minutes = mDuration.minutes().toString();
    console.log(hours);

    return `${hours.length >= 2 ? hours : '0' + hours}:${
      minutes.length === 2 ? minutes : '0' + minutes
    }:00`;
  },
  productForm: (
    selectedProductForm?: ProductForm,
    productForm?: ProductForm,
    selectedEBookType?: EBookType,
    productFormDetails?: { code: string; label: string }[]
  ) => {
    return {
      ...selectedProductForm,
      additionalDescription: productForm && productForm.additionalDescription,
      taxRate:
        productForm && productForm.taxRate && productForm.taxRate.code
          ? productForm.taxRate
          : undefined,
      details: selectedProductForm?.details?.some((detail) =>
        ['E101', 'E116'].includes(detail.code)
      )
        ? // e-book selected
          selectedProductForm?.details?.map((detail) => ({
            ...detail,
            eBookType: selectedEBookType && selectedEBookType.code ? selectedEBookType : undefined,
          }))
        : // non-e-book selected
        selectedProductForm?.code === 'EA'
        ? selectedProductForm?.details // Special exception: This productform does have some details initially which may not be changed. We send them back as they came.
        : productFormDetails,
    };
  },
  dimensions: (data: FormValues) => {
    const widthInCm = transform.toNumber(data.width);
    const heightInCm = transform.toNumber(data.height);
    const thicknessInCm = transform.toNumber(data.thickness);

    return {
      width: widthInCm ? widthInCm * 10 : null,
      height: heightInCm ? heightInCm * 10 : null,
      thickness: thicknessInCm ? thicknessInCm * 10 : null,
    };
  },
  toNumber: (s?: string | number): number | undefined => {
    return s && typeof s === 'string' ? parseFloat(s.replace(',', '.')) : (s as number | undefined);
  },
  // fund: (fund?: Fund) => {
  //   return fund && { code: fund.code };
  // },
};

export const convertThemaToTree = (
  thema: Thema,
  allThemas: Themas,
  slice: number = 2,
  parentThemaCodes: Code[] = []
): TreeData => {
  return {
    ...thema,
    notSelectable: thema.code.length === 1,
    parents: parentThemaCodes,
    children: allThemas
      .filter(
        (childThema) =>
          childThema.code !== thema.code &&
          childThema.code.slice(0, slice - 1) === thema.code.slice(0, slice - 1) &&
          childThema.code.length === slice
      )
      .map((childThema) =>
        convertThemaToTree(childThema, allThemas, slice + 1, [...parentThemaCodes, thema.code])
      ),
  };
};

const isThemaQualifierChild = (regex: RegExp) => (child: ThemaQualifier) => {
  return Boolean(child.code.match(regex));
};
export const convertThemaQualifierToTree = (
  themaQualifier: ThemaQualifier,
  allThemaQualifiers: ThemaQualifiers,
  parentThemaQualifierCodes: Code[] = []
): TreeData => {
  return {
    ...themaQualifier,
    notSelectable: isNotSelectableThemaQualifier(themaQualifier.code),
    parents: parentThemaQualifierCodes,
    children: allThemaQualifiers
      // A child is parent + 1 extra char OR parent + "-xxxxx"
      .filter(isThemaQualifierChild(new RegExp(`^${themaQualifier.code}(.{1}$|-.{2}-.{1}$)`)))
      .map((childThemaQualifier) =>
        convertThemaQualifierToTree(childThemaQualifier, allThemaQualifiers, [
          ...parentThemaQualifierCodes,
          themaQualifier.code,
        ])
      ),
  };
};
