import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { CheckBox } from '../../../../../../components/Form';
import { DataProducer } from '../../../../../../config/api/types';
import { getLink } from '../../../../../../helpers/hateoas';
import useFetch from '../../../../../../helpers/useFetch';
import { ApiConfig } from '../../../../../organisation/api';
import { getUserOrganisation, getUserRole } from '../../../../../security/selectors';
import BibliographicField from '../../components/sections/BibliographicField';

const Gs1Checkbox = () => {
  const { t } = useTranslation();
  const [dataProducerFetch, fetchDataProducer] = useFetch<DataProducer>(
    ApiConfig.getDataProducer()
  );
  const organisation = useSelector(getUserOrganisation);
  const dataProducerLink = getLink(organisation, 'dataProducer') || '';
  const dataProducer = dataProducerFetch?.value;

  useEffect(() => {
    if (dataProducerLink) {
      fetchDataProducer(dataProducerLink);
    }
  }, [dataProducerLink, fetchDataProducer]);

  const userRoles = useSelector(getUserRole);
  const isAdmin = userRoles && userRoles.includes('ROLE_ADMIN');

  const usesGs1 = dataProducer?.usesGs1 || isAdmin;

  return (
    <BibliographicField
      component={CheckBox}
      name="sendToGs1"
      disabled={!usesGs1}
      label={t('title_gs1')}
    />
  );
};

export default Gs1Checkbox;
