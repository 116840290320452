import SentimentDissatisfied from '@mui/icons-material/SentimentDissatisfied';
import { Box, Theme, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import React, { ErrorInfo, FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { EXTERNAL_LINKS } from '../config/data/external_links';
import ExternalLink from '../packages/app/components/ExternalLink';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      background: theme.palette.background.paper,
      overflowY: 'auto',
    },
    content: {
      margin: 'auto',
    },
    icon: {
      fontSize: 96,
    },
    error: {
      lineHeight: 1.5,
    },
    errorInfo: {
      lineHeight: 1.2,
    },
  })
);

type Props = {
  error?: Error;
  errorInfo?: ErrorInfo;
};

const ErrorPage: FC<Props> = ({ error, errorInfo }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box
      position="absolute"
      top={0}
      right={0}
      bottom={0}
      left={0}
      display="flex"
      alignItems="center"
      justifyContent="center"
      className={classes.root}
    >
      <Box p={3} className={classes.content}>
        <Box display="flex" alignItems="center">
          <Box mr={6}>
            <SentimentDissatisfied className={classes.icon} />
          </Box>
          <Box>
            <Typography variant="h1" gutterBottom>
              {t('errorpage_title')}
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              {t('errorpage_subtitle')}
            </Typography>
            <Typography variant="subtitle1">
              <Trans
                i18nKey="errorpage_contact"
                components={[<ExternalLink href={EXTERNAL_LINKS['contact']} />]}
              />
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ErrorPage;
