import { Organisation } from '../../../config/api/types';
import { getLink } from '../../../helpers/hateoas';
import { ROLE } from '../../security/model';
import ROUTE_KEY from '../../../config/routes/routeKeys';

export const roleMap = {
  [ROLE.DISTRIBUTOR]: ROUTE_KEY.ADMIN_ORG_DISTRIBUTOR,
  [ROLE.PUBLISHER]: ROUTE_KEY.ADMIN_ORG_PUBLISHER,
  [ROLE.DATA_PRODUCER]: ROUTE_KEY.ADMIN_ORG_DATA_PRODUCER,
  [ROLE.DATA_CONSUMER]: ROUTE_KEY.ADMIN_ORG_DATA_CONSUMER,
  [ROLE.BOOKSTORE]: ROUTE_KEY.ADMIN_ORG_BOOKSTORE,
  [ROLE.LIBRARY]: ROUTE_KEY.ADMIN_ORG_LIBRARY,
};

export const getTabsToShow = (organisation: Organisation): string[] => {
  const tabsToShow = [ROUTE_KEY.ADMIN_ORG_MEMBER];
  if (getLink(organisation, 'logins')) {
    tabsToShow.push(ROUTE_KEY.ADMIN_ORG_USERS);
  }

  return [
    ...tabsToShow,
    ...(Object.keys(organisation?.roles || {}).map((role) => {
      return roleMap[role];
    }) || []),
  ];
};
