import { State } from './model';
import { Actions } from './actions';
import { ALERT_CLOSED, ERROR_SHOWN, SUCCESS_SHOWN } from './actionTypes';
import i18next from 'i18next';

type AlertReducer = (state: State | undefined, action: Actions) => State;

const initialState: State = {
  messages: {},
};
const alertReducer: AlertReducer = (state = initialState, action) => {
  const key = new Date().getTime() + Math.random();
  switch (action.type) {
    case SUCCESS_SHOWN:
      return {
        messages: {
          ...state.messages,
          [key]: {
            variant: 'success',
            message: action.payload.message,
          },
        },
      };
    case ERROR_SHOWN:
      return {
        messages: {
          ...state.messages,
          [key]: {
            variant: 'error',
            message: action.payload.message || i18next.t('Internal Server Error'),
          },
        },
      };
    case ALERT_CLOSED:
      const newState = state;
      delete newState.messages[action.payload.key];
      return newState;
    default:
      return state;
  }
};

export default alertReducer;
