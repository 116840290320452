import React, { useState, useEffect } from 'react';
import { IsbnApplication } from '../../../../config/api/types';
import IsbnApplicantReview from '../components/IsbnApplicantReview';
import MatchOrganisations from '../components/MatchOrganisations';
import useApiRoute from '../../../../config/api/useApiRoute';
import { useFetch } from '@react-redux-fetch/hooks';
import {
  createISBNOrganisationRequest,
  updateISBNOrganisationRequest,
} from '../../../organisation/api';
import { useSelector } from 'react-redux';
import getIdFromUrl from '../../../../helpers/getIdFromUrl';
import { getLink } from '../../../../helpers/hateoas';
import { getOrganisation } from '../../../organisation/selectors';

type Props = {
  isbnApplication: IsbnApplication;
  onUpdate: (body: IsbnApplication) => void;
  overrideApplicantField: (field: keyof IsbnApplication['organisation'], value: string) => void;
  hasApplicantOverrides: boolean;
};

const EditIsbnApplicantWrapper = ({
  isbnApplication,
  onUpdate,
  overrideApplicantField,
  hasApplicantOverrides,
}: Props) => {
  const orgUrl = useApiRoute('organisations');
  const orgUrlWithId = useApiRoute('organisations', isbnApplication.publisherOrganisationId) || '';
  const [matchOrganisation, setMatchOrganisation] = useState<boolean>(false);
  const [createOrgRequest, createOrganisation] = useFetch(createISBNOrganisationRequest);
  const [, updateOrganisation] = useFetch(updateISBNOrganisationRequest);
  const organisation = useSelector(getOrganisation);

  useEffect(() => {
    if (createOrgRequest?.fulfilled && !isbnApplication.publisherOrganisationId && organisation) {
      const publisherOrganisationId = getIdFromUrl(getLink(organisation, 'self') || '');
      onUpdate({
        ...isbnApplication,
        publisherOrganisationId,
      });
    }
  }, [
    createOrgRequest,
    isbnApplication.publisherOrganisationId,
    organisation,
    onUpdate,
    isbnApplication,
  ]);

  const handleCreateOrganisation = () => {
    if (orgUrl) {
      const newOrganisation = {
        ...isbnApplication.organisation,
        roles: { ROLE_PUBLISHER: true },
      };
      createOrganisation(orgUrl, newOrganisation);
    }
  };

  const handleUpdateOrganisation = () => {
    if (orgUrl && isbnApplication._embedded?.publisherOrganisation) {
      updateOrganisation(orgUrlWithId, isbnApplication._embedded.publisherOrganisation);
    }
  };

  const handleSelectMatch = (publisherOrganisationId: string, removePrefix?: boolean) => {
    const newApplication = { ...isbnApplication, publisherOrganisationId };
    if (removePrefix) {
      // @ts-ignore
      delete newApplication.prefix;
      newApplication.requestIsbnNumbers = 'use_existing_prefix';
    }
    onUpdate(newApplication);
    setMatchOrganisation(false);
  };

  return matchOrganisation ? (
    <MatchOrganisations
      onCancel={() => setMatchOrganisation(false)}
      onSelect={handleSelectMatch}
      application={isbnApplication}
    />
  ) : (
    <IsbnApplicantReview
      isbnApplication={isbnApplication}
      onMatch={() => setMatchOrganisation(true)}
      onCreateOrganisation={handleCreateOrganisation}
      overrideApplicantField={overrideApplicantField}
      disableCreateOrganisation={Boolean(createOrgRequest && !createOrgRequest.rejected)}
      onSave={handleUpdateOrganisation}
      canSaveOrganisationChanges={hasApplicantOverrides}
    />
  );
};

export default EditIsbnApplicantWrapper;
