import React, { useEffect } from 'react';
import { useFetch } from '@react-redux-fetch/hooks';
import { getMasterPrefixesRequest } from '../../api';
import useApiRoute from '../../../../config/api/useApiRoute';
import MasterPrefixTable from '../components/MasterPrefixTable';
import { useSelector } from 'react-redux';
import { getMasterPrefixes } from '../../selectors';
import DelayedLoader from '../../../../components/DelayedLoader';

const MasterPrefixTableWrapper = () => {
  const [, fetchMasterPrefixes] = useFetch(getMasterPrefixesRequest);
  const masterPrefixesApiLink = useApiRoute('masterPrefixes') || '';

  const masterPrefixes = useSelector(getMasterPrefixes);

  useEffect(() => {
    if (masterPrefixesApiLink) {
      fetchMasterPrefixes(masterPrefixesApiLink);
    }
  }, [masterPrefixesApiLink, fetchMasterPrefixes]);

  return (
    <DelayedLoader data={masterPrefixes}>
      <MasterPrefixTable masterPrefixes={masterPrefixes} dispatchFn={fetchMasterPrefixes} />
    </DelayedLoader>
  );
};

export default MasterPrefixTableWrapper;
