import { Grid } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Field, { TextField } from '../../../../../../components/Form';
import InfoTooltip from '../../../../../../components/InfoTooltip';
import { Language, Title } from '../../../../../../config/api/types';
import useApiRoute from '../../../../../../config/api/useApiRoute';
import { getUserRole } from '../../../../../security/selectors';
import LanguageField from '../../containers/fields/LanguageField';
import SearchTitleField from '../../containers/fields/SearchTitleField';
import SearchTitlesField from '../../containers/fields/SearchTitlesField';
import { SelectedTitle } from '../../domain';
import Fieldset from '../Fieldset';
import FieldsetSubTitle from '../FieldsetSubTitle';
import FieldsetTitle from '../FieldsetTitle';
import SectionWrapper from '../SectionWrapper';
import RelationField from './RelationField';

type Props = {
  initialReplacementOf?: string;
  initialReplacedBy?: string;
  selectedReplacementOf?: SelectedTitle;
  selectedReplacedBy?: SelectedTitle;
  isBibliographicalVerified?: boolean;
  selectedTitleInOtherLanguages?: SelectedTitle[];
  canSelectTitlesInOtherLanguages?: boolean;
  languages?: Language[];
};

const Relations: FC<Props> = ({
  initialReplacementOf,
  initialReplacedBy,
  selectedReplacementOf,
  selectedReplacedBy,
  selectedTitleInOtherLanguages,
  canSelectTitlesInOtherLanguages,
  isBibliographicalVerified = false,
  languages,
}) => {
  const { t } = useTranslation();
  const languagesUrl = useApiRoute('titleLanguages') || '';

  const userRoles = useSelector(getUserRole);

  return (
    <SectionWrapper id="relations">
      <FieldsetTitle>{t('title_section_relations')}</FieldsetTitle>
      <Fieldset>
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <RelationField
              label={t('title_work_first_published')}
              helperText={t('title_work_first_published_tooltip') || ''}
              name="workFirstPublished"
              component={Field}
              type="date"
            />
          </Grid>
        </Grid>
      </Fieldset>
      <Fieldset>
        <FieldsetSubTitle>{t('title_heading_translatedWork')}</FieldsetSubTitle>
        <Grid container spacing={1} direction="column">
          <Grid item>
            <LanguageField
              url={languagesUrl}
              name="originalLanguage"
              label={
                <>
                  {t('title_original_language')}
                  <InfoTooltip
                    title={
                      isBibliographicalVerified
                        ? t('title_isBibliographicalVerified_disabled_field')
                        : t('title_original_language_tooltip')
                    }
                    inline
                  />
                </>
              }
              isMulti={false}
              isClearable
              disabled={isBibliographicalVerified}
            />
          </Grid>
          <Grid item>
            <TextField
              name="originalTitle"
              disabled={isBibliographicalVerified}
              label={
                <>
                  {t('title_original_title')}
                  <InfoTooltip
                    title={
                      isBibliographicalVerified
                        ? t('title_isBibliographicalVerified_disabled_field')
                        : t('title_original_title_tooltip')
                    }
                    inline
                  />
                </>
              }
            />
          </Grid>
          <Grid item>
            <RelationField
              component={SearchTitlesField}
              label={
                <>
                  {t('title_other_language')}
                  <InfoTooltip title={t('title_other_language_tooltip')} inline />
                </>
              }
              name="selectedTitleInOtherLanguages"
              selectedValues={selectedTitleInOtherLanguages}
              disabled={!canSelectTitlesInOtherLanguages}
              isMulti
              showLanguageInOption
              optionsFilter={(title: Title) => {
                if (!title.languages?.length || title.languages.length === 0) return false; // The title to select should have at least 1 language
                if (title.languages.some(({ code }) => code === 'zxx')) return false; // The title to select should not have a language with code zxx
                if (
                  title.languages.length === 1 &&
                  languages?.some(({ code }) => code === title.languages?.[0].code)
                )
                  return false; // The title to select should not have the same language as the original title when it only has 1 language
                return true;
              }}
            />
          </Grid>
        </Grid>
      </Fieldset>
      <Fieldset>
        <FieldsetSubTitle>{t('title_heading_order')}</FieldsetSubTitle>
        <Grid container spacing={1} direction="column">
          <Grid item>
            <RelationField
              initialValue={initialReplacementOf}
              component={SearchTitleField}
              label={
                <>
                  {t('title_replacement_of')}
                  <InfoTooltip title={t('title_replacement_of_tooltip')} inline />
                </>
              }
              name="selectedReplacementOf"
              selectedValue={selectedReplacementOf}
            />
          </Grid>
          <Grid item>
            <RelationField
              initialValue={initialReplacedBy}
              component={SearchTitleField}
              label={
                <>
                  {t('title_replaced_by')}
                  <InfoTooltip title={t('title_replaced_by_tooltip')} inline />
                </>
              }
              name="selectedReplacedBy"
              selectedValue={selectedReplacedBy}
            />
          </Grid>
        </Grid>
      </Fieldset>
      <Fieldset>
        <Grid container spacing={1} direction="column">
          {userRoles.includes('ROLE_ADMIN') && (
            <Grid item>
              <RelationField label={t('title_nstc')} name="nstc" component={TextField} />
            </Grid>
          )}
        </Grid>
      </Fieldset>
    </SectionWrapper>
  );
};

export default Relations;
