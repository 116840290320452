import React, { useState, useReducer, useEffect } from 'react';
import { DropzoneState, FileRejection } from 'react-dropzone';
import DropZone from '../components/StyledDropZone';
import length from 'ramda/es/length';
import mergeDeepWith from 'ramda/es/mergeDeepWith';
import concat from 'ramda/es/concat';
import not from 'ramda/es/not';
import map from 'ramda/es/map';
import createXmlHttpRequest from '../../../../helpers/createXmlHttpRequest';
import FileUploadStatus from '../components/FileUploadStatus';
import { RootState } from '../../../../config/store/types';
import security from '../../../security/index';
import { connect, useSelector } from 'react-redux';
import FileError from '../components/FileError';
import keys from 'ramda/es/keys';
import forEach from 'ramda/es/forEach';
import merge from 'ramda/src/mergeDeepLeft';
import { Actions } from '../Dropzone.types';
import { Actions as AlertActions } from '../../alerts/actions';
import { useTranslation } from 'react-i18next';
import { updateQueryParameter } from '../../../../helpers/hateoas';

type MappedState = {
  jwtToken: string;
};

type MappedDispatch = {
  openSuccess: typeof AlertActions.openSuccess;
};

type Props = MappedState &
  MappedDispatch & {
    message: string;
    activeMessage: string;
    maxFiles?: number;
    maxSize?: number;
    apiRoute: string;
    accept: string;
    onUploadDone?: () => void;
  };

type FileStateProps = {
  acceptedFiles: DropzoneState['acceptedFiles'];
  rejectedFiles: DropzoneState['fileRejections'];
  validationErrors: {
    count: number;
    message: string;
  };
};
type UploadStateProps = {
  progressByItem: { [s: string]: number };
  finalProgress: number;
  errors: { [s: string]: { response: string; code: number } };
};
type OnDrop = (
  acceptedFiles: DropzoneState['acceptedFiles'],
  rejectedFiles: DropzoneState['fileRejections']
) => void;

const initialFileState = {
  acceptedFiles: [],
  rejectedFiles: [],
  validationErrors: { count: 0, message: '' },
};
const initialUploadState = {
  progressByItem: {},
  finalProgress: 0,
  errors: {},
};

const reducer = (state: UploadStateProps, action: Actions): UploadStateProps => {
  const mergeWithCurrentState = merge(state);
  switch (action.type) {
    case 'progress':
      if (action.payload.progressByItem && action.payload.numberOfAcceptedFiles) {
        const progressState = merge(
          {
            progressByItem: action.payload.progressByItem,
          },
          state
        );
        let totalProgress = 0;
        map((item: number) => (totalProgress += item))(progressState.progressByItem);

        return merge(
          {
            finalProgress: totalProgress / action.payload.numberOfAcceptedFiles,
          },
          progressState
        );
      }
      return state;
    case 'error':
      return mergeWithCurrentState(action.payload) as UploadStateProps;
    case 'reset':
      return initialUploadState;
    default:
      return state;
  }
};

const DropZoneContainer = ({
  message,
  activeMessage,
  apiRoute,
  jwtToken,
  maxFiles = 1000000,
  maxSize = 100000000,
  accept,
  onUploadDone,
  openSuccess,
}: Props) => {
  const { t } = useTranslation();
  const impersonate = useSelector(security.selectors.getImpersonate);
  const [fileState, updateFileState] = useState<FileStateProps>(initialFileState);
  const [uploadState, dispatchUploadState] = useReducer(reducer, initialUploadState);
  const { acceptedFiles /*, rejectedFiles*/ } = fileState;
  const numberOfAcceptedFiles = acceptedFiles ? length(acceptedFiles) : 0;
  const renderDropZone = numberOfAcceptedFiles === 0;
  const hasError = length(keys(uploadState.errors)) > 0;

  const itemsFinished = Object.values(uploadState.progressByItem).reduce(
    (total: number, progress) => (progress === 100 ? total + 1 : total),
    0
  );

  const successMessage = t('form_files_upload_success', { count: itemsFinished });

  useEffect(() => {
    if (uploadState.finalProgress === 100) {
      onUploadDone?.();
      openSuccess(successMessage);
      resetUploadProcess();
    }
  }, [onUploadDone, openSuccess, successMessage, uploadState.finalProgress]);

  const calculateProgress = (filename: string, progress: number) => {
    const progressState = {
      numberOfAcceptedFiles,
      progressByItem: {
        [filename]: progress,
      },
    };
    dispatchUploadState({ type: 'progress', payload: progressState });
  };

  const errorOnItem = (filename: string, statusCode: number, response: string) => {
    dispatchUploadState({
      type: 'error',
      payload: {
        errors: {
          [filename]: { code: statusCode, response },
        },
      },
    });
  };

  const sendXhr = (files: File[]) => {
    forEach((file: File) => {
      const formData = new FormData();
      formData.append('file', file);
      createXmlHttpRequest(
        'POST',
        impersonate
          ? updateQueryParameter(apiRoute, '_switch_user', impersonate.username)
          : apiRoute,
        formData,
        jwtToken,
        (progress) => calculateProgress(encodeURI(file.name), progress),
        // () => finishItemLoad(file.name)
        (statusCode, response) => errorOnItem(encodeURI(file.name), statusCode, response)
      );
    })(files);
  };

  const onDropAccepted: OnDrop = (inputFiles: DropzoneState['acceptedFiles']) => {
    if (
      not(inputFiles) ||
      (acceptedFiles && length(acceptedFiles) + length(inputFiles) > maxFiles)
    ) {
      console.error('TOO MANY FILES');
    } else {
      updateFileState(
        mergeDeepWith(concat, fileState, {
          acceptedFiles: inputFiles,
        }) as FileStateProps
      );
    }
  };

  const onDropRejected = (rejectedInputFiles: DropzoneState['fileRejections']) => {
    let count = 0;
    let msg = '';

    forEach(({ file }: FileRejection) => {
      if (file.size > maxSize) {
        count++;
        msg = t('form_files_toobig', { count });
      } else if (accept && not(accept.includes(file.type))) {
        count++;
        msg = t('form_files_wrongtype', { count });
      } else {
        count++;
        msg = t('form_files_validationerror', { count });
      }
    })(rejectedInputFiles);

    updateFileState({
      ...fileState,
      rejectedFiles: rejectedInputFiles,
      validationErrors: { count, message: msg },
    });
  };
  const resetUploadProcess = () => {
    dispatchUploadState({ type: 'reset', payload: {} });
    updateFileState(initialFileState);
  };

  return (
    <>
      {hasError && <FileError errors={uploadState.errors} />}
      {renderDropZone ? (
        <DropZone
          activeMessage={activeMessage}
          message={message}
          onDropAccepted={onDropAccepted}
          onDropRejected={onDropRejected}
          validationErrors={fileState.validationErrors}
          accept={accept}
        />
      ) : (
        <FileUploadStatus
          sendXhr={sendXhr}
          filenameText={numberOfAcceptedFiles > 1 ? t('multiple_files') : acceptedFiles[0].name}
          acceptedFiles={acceptedFiles}
          progress={uploadState.finalProgress}
          itemsFinished={itemsFinished}
          cancelCallback={resetUploadProcess}
        />
      )}
    </>
  );
};

const mapState = (state: RootState): MappedState => ({
  jwtToken: security.selectors.getJwtToken(state),
});

const mapDispatch: MappedDispatch = {
  openSuccess: AlertActions.openSuccess,
};

export default connect(mapState, mapDispatch)(DropZoneContainer);
