import React from 'react';
import { RouteComponentProps } from 'react-router';
import OrganisationWrapper from '../containers/OrganisationWrapper';
import BookstoreForm from '../containers/bookstore/BookstoreForm';

type Params = { id: string };
type Props = RouteComponentProps<Params> & {};

const BookstoreTab = (props: Props) => {
  return (
    <OrganisationWrapper {...props}>
      {({ organisation }) => <BookstoreForm organisation={organisation} />}
    </OrganisationWrapper>
  );
};

export default BookstoreTab;
