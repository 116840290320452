import { Box, Button, FormHelperText, Grid, Typography } from '@mui/material';
import { useFetch } from '@react-redux-fetch/hooks';
import React, { FC } from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { CheckBox, TextField } from '../../../../components/Form';
import { NumberStepFormField } from '../../../../components/Form/NumberStepFormField';
import WhenFieldChanges from '../../../../components/Form/WhenFieldChanges';
import useApiRoute from '../../../../config/api/useApiRoute';
import { useAsyncValidation } from '../../../../helpers/finalFormAsyncValidation';
import distributor from '../../../organisation/distributor';
import title from '../../../title/admin/title';
import {
  canBackorder,
  canReturn,
  canReturnDefaultNo,
  canReturnDefaultYes,
  requiresInvoiceNr,
} from '../../domain';
import { RetourReasonField } from '../retourReason/RetourReasonField';
import { createRetourLineRequest } from './api';
import { RetourLineFormValues, retourLineFormValueToRetourLineRequest } from './dtoMapper';

const defaultValues: RetourLineFormValues = {
  quantity: 1,
  articleReturn: true,
  backorder: false,
  gtin13: null,
};

type Props = {
  onClose: () => void;
  retourId: string;
};
export const RetourLineForm: FC<Props> = ({ onClose, retourId }) => {
  const { t } = useTranslation();
  const url = useApiRoute('retours') || '';
  const [createRetourLineFetch, createRetourLine] = useFetch(createRetourLineRequest);

  const lineUrl = `${url}/${retourId}/lines`;

  const { createSubmissionPromise } = useAsyncValidation(createRetourLineFetch);

  return (
    <>
      <Box mb={2}>
        <Typography variant="h3" gutterBottom>
          {t('return_save_draft')}
        </Typography>
      </Box>
      <Form
        onSubmit={(formValues: RetourLineFormValues, form) => {
          createRetourLine(lineUrl, retourLineFormValueToRetourLineRequest(formValues));
          return createSubmissionPromise().then((errors) => {
            if (errors) {
              return errors;
            }
            // Defer to bottom of call stack
            setTimeout(() => {
              form.reset({
                ...defaultValues,
                articleReturn: formValues.articleReturn,
                backorder: formValues.backorder,
                distributor: formValues.distributor,
                reason: formValues.reason,
                ...(formValues.rememberInvoice
                  ? {
                      deliveryNote: formValues.deliveryNote,
                      invoiceNumber: formValues.invoiceNumber,
                      rememberInvoice: formValues.rememberInvoice,
                    }
                  : {}),
                ...(formValues.rememberReference
                  ? {
                      lineReference: formValues.lineReference,
                      comments: formValues.comments,
                      rememberReference: formValues.rememberReference,
                    }
                  : {}),
              });
            });
          });
        }}
        initialValues={defaultValues}
        keepDirtyOnReinitialize={false}
      >
        {({ handleSubmit, values, form: { change, submit }, submitting }) => {
          return (
            <form onSubmit={handleSubmit}>
              <WhenFieldChanges
                field={'reason'}
                set={'backorder'}
                to={(value: any): boolean =>
                  !!(values.distributor && values.distributor.isCbMember && value.value === 2)
                }
              />
              <WhenFieldChanges
                field={'distributor'}
                set={'backorder'}
                to={(): boolean => !!(values.distributor && values.distributor.isCbMember)}
              />
              <Grid container spacing={2}>
                <Grid item style={{ width: 400 }}>
                  <distributor.components.DistributorsSelectField
                    label={t('form_distributor') + ' *'}
                    urlParams={{
                      can_process_returns: 1,
                      limit: 999999,
                    }}
                    distributorId={values.gtin13?.title.fund.organisation?.organisationId}
                    change={change}
                    placeholder=" "
                  />
                </Grid>
                <Grid item style={{ width: 400 }}>
                  <RetourReasonField />
                </Grid>
                <Grid
                  item
                  style={{
                    marginTop: '1.5rem',
                    display: values.reason && canBackorder(values.reason?.value) ? 'block' : 'none',
                  }}
                >
                  <CheckBox
                    name="backorder"
                    label={t('return_additional_delivery')}
                    disabled={values.reason?.value === 2 && values.distributor?.isCbMember}
                  />
                </Grid>
                {values.reason &&
                  canReturn(values.reason.value) &&
                  !canReturnDefaultNo(values.reason.value) &&
                  !canReturnDefaultYes(values.reason.value) && (
                    <Grid item style={{ marginTop: '1.5rem' }}>
                      <CheckBox name="articleReturn" label={t('return_will_be_returned')} />
                    </Grid>
                  )}
              </Grid>
              <Grid container spacing={2} alignItems="flex-start">
                <Grid item style={{ width: 664 }}>
                  <title.components.SearchTitleField
                    label={t('return_product') + ' *'}
                    name="gtin13"
                  />
                </Grid>
                <Grid item style={{ width: 136 }}>
                  <NumberStepFormField name="quantity" label={t('qty')} />
                </Grid>
              </Grid>
              <Grid container spacing={2} alignItems="flex-start">
                <Grid item md={3}>
                  <TextField name="deliveryNote" label={t('return_deliveryNote')} />
                </Grid>
                <Grid item md={3}>
                  <TextField name="invoiceNumber" label={t('return_invoiceNumber')} />
                </Grid>
                <Grid item md={3}>
                  <TextField name="lineReference" label={t('return_reference')} />
                </Grid>
                <Grid item md={3}>
                  <TextField name="comments" label={t('return_comments')} multiline />
                </Grid>
                {values.reason && requiresInvoiceNr(values.reason?.value) && (
                  <Grid
                    item
                    xs={12}
                    style={{
                      paddingTop: 0,
                      marginTop: -16,
                    }}
                  >
                    <FormHelperText>{t('return_required_invoice')}</FormHelperText>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Grid container alignItems="center" justifyContent="flex-end">
                    <Grid item>
                      <CheckBox name="rememberInvoice" label={t('return_remember_invoice')} />
                    </Grid>
                    <Grid item>
                      <CheckBox name="rememberReference" label={t('return_remember_reference')} />
                    </Grid>

                    <Grid item>
                      {/* setTimeout: Hack for #125551 - reason: when you click "save" after fixing e.g. invoicenr, the validation message is removed and the button moves up, making the click handler not being executed*/}
                      <Button
                        type="submit"
                        disabled={submitting}
                        onMouseDown={() => setTimeout(submit)}
                      >
                        {t('return_save_draft')}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          );
        }}
      </Form>
    </>
  );
};
