import { EditorProps } from 'react-draft-wysiwyg';

export const toolbarConfig: EditorProps['toolbar'] = {
  options: ['inline', 'list', 'link', 'history'],
  inline: {
    options: ['bold', 'italic', 'underline'],
  },
  list: {
    options: ['unordered', 'ordered'],
  },
};
