import { useSelector } from 'react-redux';
import security from '../../../security';
import { getLink } from '../../../../helpers/hateoas';
import { useFetch } from '@react-redux-fetch/hooks';
import { getDepartmentsRequest, getFavoriteBookstoresRequest, getLibraryRequest } from '../../api';
import { useEffect } from 'react';
import { getDepartments, getFavoriteBookstores, getLibrary } from '../../admin/selectors';
import { Organisation } from '../../../../config/api/types';

export const useLibraryDepartmentsAndBookstores = (organisation?: Organisation) => {
  const currentUser = useSelector(security.selectors.getUser);
  const libraryOrganisation = organisation || currentUser?._embedded.organisation;
  const libraryUrl = currentUser ? getLink(libraryOrganisation, 'library') : '';
  const isLibrary = libraryOrganisation?.roles?.['ROLE_LIBRARY'];

  const [, fetchLibrary] = useFetch(getLibraryRequest);
  const library = useSelector(getLibrary);

  const favoritesUrl = getLink(library, 'favorites') || '';
  const [, fetchFavorites] = useFetch(getFavoriteBookstoresRequest);
  const favorites = useSelector(getFavoriteBookstores);

  const departmentsUrl = getLink(library, 'departments') || '';
  const [, fetchDepartments] = useFetch(getDepartmentsRequest);
  const departments = useSelector(getDepartments);

  const isDifferentLibrary = getLink(library, 'self') !== libraryUrl;

  useEffect(() => {
    if (isLibrary && isDifferentLibrary && libraryUrl) {
      fetchLibrary(libraryUrl);
    }
  }, [isLibrary, isDifferentLibrary, libraryUrl, fetchLibrary]);

  useEffect(() => {
    if (isLibrary && favoritesUrl) {
      fetchFavorites(favoritesUrl);
    }
  }, [favoritesUrl, fetchFavorites, isLibrary]);

  useEffect(() => {
    if (isLibrary && departmentsUrl) {
      fetchDepartments(departmentsUrl);
    }
  }, [departmentsUrl, fetchDepartments, isLibrary]);

  return isLibrary
    ? {
        departments,
        favorites,
      }
    : {};
};
