import React from 'react';
import { RouteComponentProps } from 'react-router';
import OrganisationList from '../containers/organisations/OrganisationsList';
import Header from '../../../../components/Header';
import { useTranslation } from 'react-i18next';

type Props = RouteComponentProps & {};

const Organisation = (props: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <Header title={t('page_title_organisations')} />
      <OrganisationList />
    </>
  );
};

export default Organisation;
