import React, { useEffect } from 'react';
import useApiRoute from '../../../config/api/useApiRoute';
import { useFetch } from '@react-redux-fetch/hooks';
import { getOrderFilesRequest } from '../api';
import OrderFilesTable from '../components/orderFiles/OrderFilesTable';
import { useSelector } from 'react-redux';
import { getOrderFiles } from '../selectors';
import PageTitle from '../../../components/PageTitle';
import { useTranslation } from 'react-i18next';

const OrderFilesPage = () => {
  const { t } = useTranslation();
  const orderFilesApiPath = useApiRoute('orderFiles');
  const [orderFilesRequest, fetchOrderFiles] = useFetch(getOrderFilesRequest);

  const orderFiles = useSelector(getOrderFiles);

  useEffect(() => {
    if (orderFilesApiPath) {
      fetchOrderFiles(orderFilesApiPath);
    }
  }, [orderFilesApiPath, fetchOrderFiles]);

  return (
    <>
      <PageTitle>{t('page_title_order_files')}</PageTitle>
      <OrderFilesTable
        orderFiles={orderFiles}
        orderFilesRequest={orderFilesRequest}
        dispatchOrderFilesGet={fetchOrderFiles}
      />
    </>
  );
};

export default OrderFilesPage;
