import React from 'react';
import { RouteComponentProps } from 'react-router';
import Header from '../../../../../components/Header';
import TitleFiles from '../containers/TitleFiles';
import { useTranslation } from 'react-i18next';

type Props = RouteComponentProps & {};

const TitleFilesPage = (props: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <Header title={t('page_title_title_files')} />
      <TitleFiles />
    </>
  );
};

export default TitleFilesPage;
