import React, { FC } from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { StringParam, useQueryParam, withDefault } from 'use-query-params';
import AutoSave from '../../../../../components/Form/AutoSave';
import { EditionType } from '../../../../../config/api/types';
import useApiRoute from '../../../../../config/api/useApiRoute';
import { QueryObjectValue } from '../../../../../helpers/elasticQueryDSL';
import EditionTypeField from '../../../admin/title/containers/fields/EditionType';
import FacetWrapper from '../../components/Facet/FacetWrapper';

type Props = {
  onFilter: (code?: EditionType['code']) => void;
  value: QueryObjectValue;
};

type FormValues = {
  editionTypes?: EditionType;
};

const EditionTypeFacet: FC<Props> = ({ onFilter, value }) => {
  const { t } = useTranslation();
  const url = useApiRoute('titleEditionTypes');

  const [fieldValue, setFieldValue] = useQueryParam(
    'editionTypes.code',
    withDefault(StringParam, value as string)
  );

  if (!url) {
    return null;
  }

  return (
    <FacetWrapper title={t('facet_editionType')} collapsible={false} active={Boolean(value)}>
      <Form
        onSubmit={(formValues: FormValues) => {
          setFieldValue(formValues.editionTypes?.code);
          return onFilter(formValues.editionTypes?.code);
        }}
      >
        {({ form: { submit } }) => (
          <>
            <AutoSave save={submit} />
            <EditionTypeField
              url={url}
              label=""
              placeholder={t('facet_editionType_placeholder')}
              blurInputOnSelect
              onClearValue={submit}
              wideDropdown
              key={fieldValue as string}
              value={fieldValue}
            />
          </>
        )}
      </Form>
    </FacetWrapper>
  );
};

export default EditionTypeFacet;
