import apiRoutes from '../../../config/api/routes';
import { Distributor, FundDto } from '../../../config/api/types';
import i18next from 'i18next';
import memoizeOne from 'memoize-one';
import { FetchConfig } from '@react-redux-fetch/core';
import { Data } from '../../../config/store/types';

export const getDistributorsRequest = (url: string): FetchConfig<Data> => {
  return {
    method: 'GET',
    url: apiRoutes.create(url),
    repository: {
      distributors: (prev, next: Distributor) => ({ ...prev, [url]: next }),
    },
  };
};

export const ApiConfig = {
  getDistributors: memoizeOne(() => ({
    resource: 'distributors',
    request: (url: string) => ({
      url: apiRoutes.create(url),
    }),
  })),
  /** @deprecated see getDistributorsRequest */
  getDistributor: memoizeOne((url?: string) => ({
    resource: 'distributor',
    request: (newUrl?: string) => {
      return {
        url: apiRoutes.create(newUrl || url),
        clearValueOnRequest: true,
      };
    },
  })),
  getFunds: memoizeOne(() => ({
    resource: 'funds',
    request: (url: string) => ({
      url: apiRoutes.create(url),
    }),
  })),
  getFund: (url: string) => ({
    resource: 'fund',
    request: () => ({
      url: apiRoutes.create(url),
      clearValueOnRequest: true,
    }),
  }),
  updateFund: (url?: string) => ({
    resource: 'updateFund',
    method: 'put',
    request: (body: FundDto, newUrl?: string) => ({
      url: apiRoutes.create(url || newUrl),
      body,
      meta: {
        successMsg: i18next.t('form_fund_update_success'),
      },
    }),
  }),
  createFund: () => ({
    resource: 'newFund',
    method: 'post',
    request: (url: string, body: FundDto) => ({
      url: apiRoutes.create(url),
      body,
      meta: {
        successMsg: i18next.t('form_fund_success'),
      },
    }),
  }),
};
