import { Components, Theme } from '@mui/material';
import palette from './palette';

const overrides: Components<Omit<Theme, 'components'>> = {
  MuiCssBaseline: {
    styleOverrides: {
      body: {
        fontSize: '0.875rem',
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
        fontWeight: 400,
        lineHeight: 1.43,
        letterSpacing: '0.01071em',
      },
      'strong, b': {
        fontWeight: 500,
      },
      'p, a': {
        whiteSpace: 'break-spaces',
      },
    },
  },
  MuiTypography: {
    styleOverrides: {
      body1: {
        fontSize: 14,
      },
      h2: {
        fontSize: '1.5rem',
      },
      h3: {
        fontSize: '1.2rem',
      },
      h4: {
        fontSize: '1rem',
      },
      h5: {
        fontSize: '1rem',
      },
      subtitle2: {
        fontWeight: 500,
      },
      caption: {
        //fontSize: '1rem',
      },
    },
  },
  MuiButton: {
    defaultProps: {
      variant: 'contained',
      color: 'primary',
    },
    styleOverrides: {
      root: {
        textTransform: 'none',
      },
      contained: {
        boxShadow: 'none',
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      input: {
        padding: '12px 10px',
        background: palette.background.paper,
        '&[disabled]': {
          background: palette.background.appbar,
        },
      },
      notchedOutline: {},
      adornedStart: {
        paddingLeft: 8,
        background: palette.background.appbar,
      },
      adornedEnd: {
        background: palette.background.appbar,
      },
      multiline: {
        padding: 0,
      },
    },
  },
  MuiInputAdornment: {
    styleOverrides: {
      positionEnd: {
        marginLeft: 14,
      },
    },
  },
  MuiInputBase: {
    styleOverrides: {
      root: {
        fontSize: '0.875rem',
      },
      multiline: {
        padding: 0,
      },
      input: {},
    },
  },
  MuiInputLabel: {
    styleOverrides: {
      root: {
        fontWeight: 500,
        fontSize: '0.875rem',
      },
    },
  },
  MuiFormControl: {
    styleOverrides: {
      marginNormal: {
        marginTop: 8,
        marginBottom: 20,
      },
    },
  },
  MuiFormControlLabel: {
    styleOverrides: {
      label: {
        fontSize: '0.875rem',
      },
    },
  },
  MuiAppBar: {
    styleOverrides: {
      colorPrimary: {
        color: palette.text.primary,
      },
    },
  },
  MuiFormHelperText: {
    styleOverrides: {
      contained: {
        margin: '4px 0 0',
      },
      root: {
        color: palette.text.helpertext,
        margin: 0,
        fontSize: '0.75rem',
        marginTop: '8px',
        minHeight: '1em',
        textAlign: 'left',
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
        fontWeight: 400,
        lineHeight: '1em',
        letterSpacing: '0.03333em',
      },
    },
  },
  MuiTableCell: {
    styleOverrides: {
      root: {
        padding: '4px 40px 4px 16px',
      },
    },
  },
  MuiDrawer: {
    styleOverrides: {
      paper: {
        zIndex: 1,
      },
    },
  },
  MuiMenuItem: {
    styleOverrides: {
      root: {
        '&.MuiMenuItem-root': {
          fontSize: '0.875rem',
          minHeight: 32,
        },
        '& .MuiListItemText-root': {
          marginTop: '4px',
          marginBottom: '4px',
        },
      },
    },
  },
  MuiCircularProgress: {
    styleOverrides: {
      colorSecondary: {
        color: palette.text.primary,
      },
    },
  },
  MuiTextField: {
    styleOverrides: {
      root: {
        '& .clearButton': {
          opacity: '1 !important',
          padding: '0 5px',
          margin: 0,
          position: 'absolute',
          right: 0,
          height: '100%',
          backgroundColor: '#fff !important',
          '& svg': {
            fontSize: '16px',
          },
        },
      },
    },
  },
  MuiTab: {
    styleOverrides: {
      root: {
        fontSize: '0.8125rem',
        minWidth: 160,
        opacity: 0.7,
        '&.Mui-selected': {
          opacity: 1,
        },
      },
    },
  },
};

export default overrides;
