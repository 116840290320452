import { Box } from '@mui/material';
import Grid from '@mui/material/Grid/Grid';
import { useFetch } from '@react-redux-fetch/hooks';
import moment from 'moment';
import * as React from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { CheckBox, DateField, TextField } from '../../../../../components/Form';
import { Organisation } from '../../../../../config/api/types';
import { UI_DATE_FORMAT } from '../../../../../helpers/date';
import { useAsyncValidation } from '../../../../../helpers/finalFormAsyncValidation';
import { getOrganisationFromMembershipForm, updateOrganisationRequest } from '../../../api';
import FormTitle from '../../components/FormTitle';
import SubmitConfirmationButton from '../../components/Member/SubmitConfirmationButton';

type Props = { organisation: Organisation };

function getInitialValues(organisation: Organisation) {
  return {
    membershipRegistrationNumber: organisation.membershipRegistrationNumber,
    membershipFrom: organisation.membershipFrom,
    membershipTo: organisation.membershipTo,
    active: organisation.hasMembership ? ['isMember'] : false,
  };
}

const MembershipForm = ({ organisation }: Props) => {
  const { t } = useTranslation();
  const organisationUrl = organisation._links.self.href;
  const [orgFetch, updateOrganisation] = useFetch(updateOrganisationRequest);

  const { createSubmissionPromise } = useAsyncValidation(orgFetch);

  return (
    <div>
      <FormTitle title={t('organisation_edit_membership_title')} />

      <Form
        onSubmit={(values: any) => {
          const updatedOrg = getOrganisationFromMembershipForm(organisation, values);

          updateOrganisation(organisationUrl, updatedOrg);
          return createSubmissionPromise();
        }}
        initialValues={getInitialValues(organisation)}
        keepDirtyOnReinitialize
      >
        {({ form }) => (
          <form id="membershipForm">
            <CheckBox name="active" value="isMember" label={t('form_is_member')} />

            <Box sx={{ maxWidth: 400 }}>
              <TextField
                name="membershipRegistrationNumber"
                label={t('form_membership_nr')}
                disabled
              />
            </Box>

            <Box style={{ maxWidth: 400 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <DateField
                    name="membershipFrom"
                    label={t('form_membership_from')}
                    helperText={t('keep_format', {
                      format: moment().format(UI_DATE_FORMAT),
                    })}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <DateField name="membershipTo" label={t('form_membership_to')} disabled />
                </Grid>
              </Grid>
            </Box>
            <SubmitConfirmationButton
              onSubmit={form.submit}
              disabled={!form.getState().dirty || orgFetch?.pending || false}
              organisation={organisation}
            />
          </form>
        )}
      </Form>
    </div>
  );
};

export default MembershipForm;
