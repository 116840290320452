import { isRequestAction } from '@react-redux-fetch/core';
import { AnyAction, Middleware } from 'redux';
import security from '../../security';
import set from 'lodash/set';
import { removeQueryParameter, updateQueryParameter } from '../../../helpers/hateoas';
import { Maybe, RouteResourceNames } from '../../../config/api/types';
import { selectApiRoutes } from '../hooks/useFetchApiRoutes';
import { AccessibleApiRoutes } from '../../../config/api/models/routes';
import apiRoutes from '../../../config/api/routes';

type FetchAction = AnyAction & {
  request: {
    url: string;
  };
};

const impersonateRouteNameBlacklist: RouteResourceNames[] = ['login' /*, 'notifications'*/];
const impersonateUrlBlacklist: string[] = [apiRoutes.user()]; // Some endpoints (e.g. /login) are accessed before apiRoutes are fetched

const isBlacklistRoute = (url: string, apiRoutes: Maybe<AccessibleApiRoutes>): boolean => {
  return (
    impersonateUrlBlacklist.some((route) => route === url) ||
    impersonateRouteNameBlacklist.some((routeKey) => apiRoutes?._links[routeKey]?.href === url)
  );
};

const isV0RequestAction = (action: AnyAction): action is FetchAction => {
  return action.type.match(/react-redux-fetch\/(.)*_REQUEST/);
};

const impersonateMiddleware: Middleware = (store) => (next) => (action) => {
  const impersonate = security.selectors.getImpersonate(store.getState());

  if (!impersonate) {
    if (isRequestAction(action) && action.payload.fetchConfig.url.includes('_switch_user')) {
      next(
        set(
          action,
          'payload.fetchConfig.url',
          removeQueryParameter(action.payload.fetchConfig.url, '_switch_user')
        )
      );
    } else {
      next(action);
    }

    return;
  }

  const apiRoutes = selectApiRoutes(store.getState());

  if (isRequestAction(action) && !isBlacklistRoute(action.payload.fetchConfig.url, apiRoutes)) {
    const impersonatedAction = set(
      action,
      'payload.fetchConfig.url',
      updateQueryParameter(action.payload.fetchConfig.url, '_switch_user', impersonate.username)
    );

    next(impersonatedAction);
    return;
  }

  if (isV0RequestAction(action) && !isBlacklistRoute(action.request.url, apiRoutes)) {
    const impersonatedAction = set(
      action,
      'request.url',
      updateQueryParameter(action.request.url, '_switch_user', impersonate.username)
    );

    next(impersonatedAction);
    return;
  }

  next(action);
};

export default impersonateMiddleware;
