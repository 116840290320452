import React from 'react';
import { useTranslation } from 'react-i18next';
import { SelectField as Select } from '../../../../../components/Form/index';
import { MediaType } from '../../../../../config/api/types';
import { SelectFieldProps } from '../../../../../components/Form/SelectField';

type Props = {
  onChange: SelectFieldProps['onChange'];
  defaultValue?: string;
};

type Items = Partial<{ [status in MediaType]: string }>;

const TypeFilter = ({ onChange, defaultValue }: Props) => {
  const { t } = useTranslation();

  const items: Items = {
    MEDIA_TYPE_FRONT: t('filter_mediaType.media_type_front'),
    MEDIA_TYPE_BACK: t('filter_mediaType.media_type_back'),
    MEDIA_TYPE_SAMPLE_CONTENT: t('filter_mediaType.media_type_sample_content'),
    MEDIA_TYPE_TEXT_FLAPCOPY: t('filter_mediaType.media_type_text_flapcopy'),
    MEDIA_TYPE_TEXT_PROMOTIONAL_HEADLINE: t(
      'filter_mediaType.media_type_text_promotional_headline'
    ),
    MEDIA_TYPE_TEXT_ANNOTATION: t('filter_mediaType.media_type_text_annotation'),
  };

  return (
    <Select
      items={items as Record<string, string>}
      onChange={onChange}
      label={t('filter_type')}
      value={defaultValue}
    />
  );
};

export default TypeFilter;
