import { Box, Grid, MenuItem } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import TextField, { CheckBox } from '../../../../../components/Form';
import { DataConsumerFileType } from '../../../../../config/api/models/organisation';
import { GenerateNewKeyButton } from '../../containers/dataConsumer/GenerateNewKeyButton';
import FormTitle from '../FormTitle';

type Props = {
  showFileType: boolean;
  showApiKey: boolean;
  disableNewKeyButton: boolean;
  refreshApiKeyUrl?: string;
  disableFTPConsumer: boolean;
  disableFileTypeSelect?: boolean;
  disableFTPMediaFileConsumer?: boolean;
};

const DataConsumerFormFields: FC<Props> = ({
  showFileType,
  showApiKey,
  disableNewKeyButton,
  refreshApiKeyUrl,
  disableFTPConsumer,
  disableFTPMediaFileConsumer,
  disableFileTypeSelect,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <FormTitle title={t('organisation_edit_data_consumer_ftp_subtitle')} />
      <Box mb={2}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          <Box sx={{ maxWidth: '400px' }}>
            <CheckBox
              name="isFtpTitleMutationConsumer"
              label={t('form_isFtpTitleMutationConsumer')}
              disabled={disableFTPConsumer}
            />
          </Box>
          <Box sx={{ maxWidth: '400px' }}>
            {showFileType && (
              <TextField
                type="select"
                name="fileType"
                label={t('form_fileType')}
                disabled={disableFileTypeSelect}
              >
                <MenuItem value={DataConsumerFileType.CSV}>{t('form_fileType_csv')}</MenuItem>
                <MenuItem value={DataConsumerFileType.ONIX}>{t('form_fileType_onix')}</MenuItem>
              </TextField>
            )}
          </Box>
          <Box sx={{ maxWidth: '400px' }}>
            <CheckBox
              name="isFtpMediaFileConsumer"
              label={t('form_isFtpMediaFileConsumer')}
              disabled={disableFTPMediaFileConsumer}
            />
          </Box>
        </Box>
      </Box>

      <FormTitle title={t('organisation_edit_data_consumer_api_subtitle')} />
      <Box mb={2}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          <Box sx={{ maxWidth: '400px' }}>
            <CheckBox
              name="isWebserviceTitleConsumer"
              label={t('form_isWebserviceTitleConsumer')}
            />
          </Box>
          {showApiKey && (
            <Grid item container spacing={2} alignItems="flex-end">
              <Grid item xs={6}>
                <TextField type="text" name="apiKey" label={t('form_apiKey')} disabled />
              </Grid>
              <Grid item xs={6}>
                <Box mb={1}>
                  <GenerateNewKeyButton disabled={disableNewKeyButton} url={refreshApiKeyUrl} />
                </Box>
              </Grid>
            </Grid>
          )}
        </Box>
      </Box>
    </>
  );
};

export default DataConsumerFormFields;
