import { isRequestAction } from '@react-redux-fetch/core';
import { Middleware } from 'redux';
import set from 'lodash/set';
import security from '../../security';
import { setSentryUser } from '../../../config/sentry/setUser';

const jwtMiddleware: Middleware = (store) => (next) => (action) => {
  if (!isRequestAction(action)) {
    next(action);
    return;
  }

  const actionWithContentType = set(
    action,
    'payload.fetchConfig.fetchOptions.headers.content-type',
    'application/json'
  );

  const actionWithContentTypeAndAccept = actionWithContentType.payload.fetchConfig.fetchOptions
    ?.headers.accept
    ? actionWithContentType
    : set(
        actionWithContentType,
        'payload.fetchConfig.fetchOptions.headers.accept',
        'application/json'
      );

  const actionWithContentTypeAndAcceptAndAPIKey = set(
    actionWithContentTypeAndAccept,
    'payload.fetchConfig.fetchOptions.headers.X-API-CLIENT-KEY',
    process.env.REACT_APP_API_CLIENT_KEY
  );

  const jwtToken = security.selectors.getJwtToken(store.getState());

  if (!jwtToken) {
    next(actionWithContentTypeAndAcceptAndAPIKey);
    return;
  }

  setSentryUser(security.selectors.getUser(store.getState()));

  const actionWithContentTypeAndAcceptAndAPIKeyAndAuth = set(
    actionWithContentTypeAndAcceptAndAPIKey,
    'payload.fetchConfig.fetchOptions.headers.authorization',
    `bearer ${jwtToken}`
  );

  next(actionWithContentTypeAndAcceptAndAPIKeyAndAuth);
};

export default jwtMiddleware;
