import { Box, Theme, Tooltip, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { makeStyles } from '@mui/styles';
import { useFetch } from '@react-redux-fetch/hooks';
import React from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import AutoSave from '../../../../components/Form/AutoSave';
import StatusLabel from '../../../../components/StatusLabel';
import { ElasticShoppingCartLine } from '../../../../config/api/models/shop';
import { Availability } from '../../../../config/api/types';
import formatEuro from '../../../../helpers/formatEuro';
import getIdFromUrl from '../../../../helpers/getIdFromUrl';
import { elasticGetLink } from '../../../../helpers/hateoas';
import CopyToClipboardButton from '../../../app/components/CopyToClipboardButton';
import ElasticAddToCart from '../../../title/cart/components/ElasticAddToCart';
import ElasticIsbnLink from '../../../title/components/ElasticIsbnLink';
import { elasticGetProductForm, getTitleStatus } from '../../../title/domain';
import { patchOrderLineRequest } from '../../api';
import OrderStatusSelect from '../OrderStatusSelect';

type Props = {
  orderLine: ElasticShoppingCartLine;
  orderRef: string;
  onAddToCart?: () => void;
  isCbMember: boolean;
};

type FormValues = {
  state: string;
};

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      padding: `0 ${theme.spacing(1)}`,
    },
    copyToClipboardButton: {
      marginLeft: theme.spacing(0.25),
    },
  }),
  { name: 'LibraryOrderLine' }
);

const LibraryOrderLine = ({ orderLine, orderRef, onAddToCart, isCbMember }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [, patchOrderLine] = useFetch(patchOrderLineRequest);

  const orderLineUrl = elasticGetLink(orderLine, 'changeOrderLineStatus');
  const orderLineId = orderLineUrl ? getIdFromUrl(orderLineUrl) : '';

  const onSubmit = (values: FormValues) => {
    if (orderLineUrl && values.state && values.state !== orderLine.stateBookstore) {
      patchOrderLine(orderLineUrl, values, orderRef, 'stateBookstore');
    }
  };

  // const title = {
  //   ...orderLine._embedded.title,
  //   fund: orderLine._embedded.title._embedded.fund,
  // } as any as TitleFull;

  return (
    <Grid
      container
      spacing={1}
      sx={{ alignItems: 'center', justifyContent: 'space-between' }}
      className={classes.root}
    >
      <Grid xs={2} wrap="nowrap" container>
        <Box mr={1}>
          <StatusLabel
            status={getTitleStatus({
              availability: {
                level: parseInt(orderLine.availabilityLevel) as Availability['level'],
                code: '',
                label: '', // code and label are emitted from the api since the elastic update.
              },
            })}
          />
        </Box>
        <Tooltip placement="top-start" title={orderLine.titleTitle} enterDelay={500}>
          <Typography noWrap>{orderLine.titleTitle}</Typography>
        </Tooltip>
      </Grid>

      <Grid style={{ flexGrow: 0, maxWidth: '19%', flexBasis: '19%' }}>
        <Typography>
          <ElasticIsbnLink
            orderLine={{ ...orderLine, titleId: getIdFromUrl(orderLine._links.title.href) }}
          />
          <CopyToClipboardButton
            textToCopy={orderLine.gtin13}
            tooltipText={t('copy_isbn')}
            className={classes.copyToClipboardButton}
          />
          {' | '}
          {elasticGetProductForm(orderLine)}
        </Typography>
      </Grid>

      <Grid xs={2}>
        <Typography display="inline">
          {orderLine.distributorName} {isCbMember ? t('cb') : ''}
        </Typography>
      </Grid>

      <Grid xs={1}>
        <Tooltip placement="top-start" title={orderLine.reference} enterDelay={500}>
          <Typography noWrap>{orderLine.reference}</Typography>
        </Tooltip>
      </Grid>

      <Grid
        style={{ width: 160 }}
        container
        sx={{ alignItems: 'center', justifyContent: 'flex-end' }}
        spacing={1}
      >
        <Grid style={{ textAlign: 'right' }}>{t('order_qty', { count: orderLine.quantity })}</Grid>
        <Grid style={{ width: 110, textAlign: 'right' }}>
          {formatEuro(orderLine.unitPrice)}
          {orderLine.discountCode && (
            <Tooltip title={orderLine.discountCodeLabel} arrow>
              <Typography display="inline" style={{ marginLeft: 4, position: 'relative', top: -1 }}>
                ({orderLine.discountCode})
              </Typography>
            </Tooltip>
          )}
        </Grid>
      </Grid>

      <Grid style={{ width: 210 }}>
        {orderLine.isOrderableByBookstore && (
          <ElasticAddToCart
            orderLine={orderLine}
            initialValue={orderLine.quantity}
            orderLineRef={orderLineId}
            orderRef={orderRef}
            //onAddToCart={onAddToCart} // Commented out because server updates can have some delay since orders are coming from elastic
          />
        )}
      </Grid>

      <Grid style={{ width: 150 }}>
        <Form initialValues={{ state: orderLine.stateBookstore } as FormValues} onSubmit={onSubmit}>
          {({ handleSubmit }) => (
            <>
              <OrderStatusSelect />
              <AutoSave save={handleSubmit} />
            </>
          )}
        </Form>
      </Grid>
    </Grid>
  );
};

export default LibraryOrderLine;
