import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Tab,
  Tabs,
  Theme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useFetch } from '@react-redux-fetch/hooks';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import Button from '../../../../components/Button';
import ConstrainedPageContent from '../../../../components/ConstrainedPageContent';
import DelayedLoader from '../../../../components/DelayedLoader';
import { BaseTextField } from '../../../../components/Form/TextField';
import Header from '../../../../components/Header';
import { IsbnApplicationState, PrefixApplication } from '../../../../config/api/types';
import useApiRoute from '../../../../config/api/useApiRoute';
import ROUTE_KEY from '../../../../config/routes/routeKeys';
import usePath from '../../../../config/routes/usePath';
import { useAsyncValidation } from '../../../../helpers/finalFormAsyncValidation';
import { getLink } from '../../../../helpers/hateoas';
import AlertStatus from '../../../core/alerts/components/AlertStatus';
import { getPublisher } from '../../../organisation/admin/selectors';
import { getPublisherRequest } from '../../../title/admin/title/api';
import { getPrefixApplicationRequest, updatePrefixApplicationRequest } from '../../api';
import { getPrefixApplication } from '../../selectors';
import EditPrefixApplicantWrapper from '../containers/EditPrefixApplicantWrapper';
import EditPrefixPurchaseOrderWrapper from '../containers/EditPrefixPurchaseOrderWrapper';
import PrefixApplicationPrefixWrapper from '../containers/PrefixApplicationPrefixWrapper';

type TabOption = 'applicant' | 'purchaseOrder' | 'prefix';

const useStyles = makeStyles((theme: Theme) => ({
  indicator: {
    display: 'none',
  },
  tabsContainer: {
    paddingLeft: 1,
  },
  tab: {
    borderRight: `1px solid ${theme.palette.background.appbarHover}`,
    backgroundColor: theme.palette.background.appbar,
    color: theme.palette.text.primary,
  },
  selectedTab: {
    marginLeft: '-1px',
    backgroundColor: '#fff',
    borderTop: `2px solid ${theme.palette.background.appbarHover}`,
    borderLeft: `2px solid ${theme.palette.background.appbarHover}`,
    borderRight: `2px solid ${theme.palette.background.appbarHover}`,
    zIndex: 10,
  },
  divider: {
    marginTop: '-2px',
    height: 2,
    backgroundColor: theme.palette.background.appbarHover,
    zIndex: 9,
    position: 'relative',
    marginBottom: theme.spacing(4),
  },
  dialogContent: {
    width: 400,
  },
}));

const EditPrefixApplication = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const prefixOverviewLink = usePath(ROUTE_KEY.ADMIN_PREFIX_APPLICATIONS);
  const [, fetchPrefixApplication] = useFetch(getPrefixApplicationRequest);
  const [, fetchPublisher] = useFetch(getPublisherRequest);
  const [updateApplicationRequest, updatePrefixApplication] = useFetch(
    updatePrefixApplicationRequest
  );
  const applicationUrl = useApiRoute('prefixApplication', id);
  const prefixApplication = useSelector(getPrefixApplication);
  const [selectedTab, setSelectedTab] = useState<TabOption>('applicant');
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [onHoldComment, setOnHoldComment] = useState<string>('');
  const [applicantOverrides, setApplicantOverrides] = useState<
    Partial<PrefixApplication['organisation']>
  >({});
  const { createSubmissionPromise } = useAsyncValidation(updateApplicationRequest);
  const classes = useStyles();

  const isCorrectApplication = (application?: PrefixApplication): Boolean => {
    return Boolean(application && (getLink(application, 'self') || '').includes(id));
  };

  const publisherUrl = prefixApplication?._embedded.publisherOrganisation
    ? getLink(prefixApplication._embedded.publisherOrganisation, 'publisher')
    : '';
  const publisher = useSelector(getPublisher);

  const showPurchaseOrderTab = !!publisher?.isPurchaseOrderRequired;

  useEffect(() => {
    if (publisherUrl) {
      fetchPublisher(publisherUrl);
    }
  }, [fetchPublisher, publisherUrl]);

  const disableApprove =
    !prefixApplication?.prefix || (showPurchaseOrderTab && !prefixApplication.purchaseOrderNumber);

  useEffect(() => {
    if (applicationUrl) {
      fetchPrefixApplication(applicationUrl);
    }
  }, [fetchPrefixApplication, applicationUrl]);

  const handleUpdate = useCallback(
    (data: PrefixApplication) => {
      if (applicationUrl) {
        updatePrefixApplication(applicationUrl, data);
      }
    },
    [applicationUrl, updatePrefixApplication]
  );

  const overrideApplicantField = (
    field: keyof PrefixApplication['organisation'],
    value: string
  ) => {
    const newOverrides = { ...applicantOverrides };
    newOverrides[field] = value;
    setApplicantOverrides(newOverrides);
  };

  const augmentedApplication = (application: PrefixApplication): PrefixApplication => {
    if (application._embedded?.publisherOrganisation) {
      return {
        ...application,
        _embedded: {
          publisherOrganisation: {
            ...application._embedded.publisherOrganisation,
            ...applicantOverrides,
          },
        },
      };
    }
    return application;
  };

  const replaceOrganisation = (
    values: PrefixApplication,
    application: PrefixApplication
  ): PrefixApplication => {
    const result = {
      ...values,
      organisation: {
        ...prefixApplication?._embedded.publisherOrganisation,
        ...applicantOverrides,
      },
    };
    const orgKeys = Object.keys(application.organisation) as Array<
      keyof PrefixApplication['organisation']
    >;
    orgKeys.forEach((key) => {
      if (!result.organisation[key]) {
        result.organisation[key] = application.organisation[key];
      }
    });
    return result as PrefixApplication;
  };

  const updatePrefixApplicationState = (state: IsbnApplicationState) => {
    if (prefixApplication) {
      setModalOpen(false);
      const isbnApplicationUrl = getLink(prefixApplication, 'self') || '';
      let newApplication = {
        ...prefixApplication,
        state,
      };
      if (state === 'approved') {
        newApplication = replaceOrganisation(newApplication, prefixApplication);
      }
      updatePrefixApplication(isbnApplicationUrl, newApplication);
    }
    createSubmissionPromise().then((result) => {
      if (result) {
      } else {
        history.push(prefixOverviewLink);
      }
    });
  };

  return (
    <ConstrainedPageContent center={false}>
      <Grid container alignItems="center">
        <Grid item xs={4}>
          <Header title={t('page_title_prefix_application_edit')} goBack />
        </Grid>

        <Grid
          item
          container
          spacing={1}
          sx={{ justifyContent: 'flex-end', alignItems: 'center' }}
          xs={6}
        >
          {prefixApplication && (
            <>
              <Grid item>
                <Button
                  onClick={() => updatePrefixApplicationState('approved')}
                  disabled={disableApprove}
                >
                  {t('isbn_application_accept')}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  onClick={() => updatePrefixApplicationState('rejected')}
                  disabled={Boolean(prefixApplication.prefix)}
                >
                  {t('isbn_application_reject')}
                </Button>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>

      <DelayedLoader data={prefixApplication && isCorrectApplication(prefixApplication)}>
        {prefixApplication && (
          <Grid container direction="column" spacing={2}>
            <Grid item xs={10}>
              <Tabs
                value={selectedTab}
                onChange={(event, value: TabOption) => setSelectedTab(value)}
                classes={{ flexContainer: classes.tabsContainer, indicator: classes.indicator }}
              >
                <Tab
                  value={'applicant'}
                  label={t('table_headers_applicant')}
                  classes={{
                    root: classes.tab,
                    selected: classes.selectedTab,
                  }}
                />
                {showPurchaseOrderTab && (
                  <Tab
                    value={'purchaseOrder'}
                    label={t('table_headers_purchase_order')}
                    classes={{
                      root: classes.tab,
                      selected: classes.selectedTab,
                    }}
                  />
                )}
                <Tab
                  value={'prefix'}
                  label={t('table_headers_prefix')}
                  classes={{
                    root: classes.tab,
                    selected: classes.selectedTab,
                  }}
                />
              </Tabs>
              <Divider className={classes.divider} />

              {publisher?.isDefaulter && (
                <AlertStatus label={t('prefix_application_is_defaulter_alert')}></AlertStatus>
              )}

              {selectedTab === 'applicant' && (
                <EditPrefixApplicantWrapper
                  prefixApplication={augmentedApplication(prefixApplication)}
                  onUpdate={handleUpdate}
                  overrideApplicantField={overrideApplicantField}
                />
              )}
              {selectedTab === 'purchaseOrder' && (
                <EditPrefixPurchaseOrderWrapper
                  prefixApplication={prefixApplication}
                  onUpdate={handleUpdate}
                />
              )}
              {selectedTab === 'prefix' && (
                <PrefixApplicationPrefixWrapper
                  prefixApplication={prefixApplication}
                  onUpdate={handleUpdate}
                />
              )}
              <Dialog open={modalOpen}>
                <DialogTitle>{t('isbn_application_on_hold_dialog_title')}</DialogTitle>

                <DialogContent className={classes.dialogContent}>
                  <BaseTextField
                    multiline
                    label="Reden"
                    rows={5}
                    value={onHoldComment}
                    onChange={(event) => setOnHoldComment(event.target.value)}
                    autoFocus
                  />
                </DialogContent>

                <DialogActions>
                  <Button onClick={() => setModalOpen(false)} variant="text">
                    {t('form_cancel')}
                  </Button>
                  <Button onClick={() => updatePrefixApplicationState('on_hold')}>
                    {t('form_action_save')}
                  </Button>
                </DialogActions>
              </Dialog>
            </Grid>
          </Grid>
        )}
      </DelayedLoader>
    </ConstrainedPageContent>
  );
};

export default EditPrefixApplication;
