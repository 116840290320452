import { useFetch } from '@react-redux-fetch/hooks';
import React, { FC, useEffect } from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Button from '../../../../../components/Button';
import DelayedLoader from '../../../../../components/DelayedLoader';
import { DataConsumerDTO } from '../../../../../config/api/models/organisation';
import { Organisation } from '../../../../../config/api/types';
import { useAsyncValidation } from '../../../../../helpers/finalFormAsyncValidation';
import { getDataConsumerRequest, updateDataConsumerRequest } from '../../../api';
import DataConsumerFormFields from '../../components/DataConsumer/DataConsumerFormFields';
import { getDataConsumer } from '../../selectors';

type Props = {
  organisation?: Organisation;
  goToOrganisation: () => void;
};

const DataConsumerForm: FC<Props> = ({ organisation }) => {
  const dataConsumerLink = organisation?._links.dataConsumer.href;
  const { t } = useTranslation();
  const [, fetchDataConsumer] = useFetch(getDataConsumerRequest);
  const [updateDataConsumerFetch, updateDataConsumer] = useFetch(updateDataConsumerRequest);
  const dataConsumer = useSelector(getDataConsumer(dataConsumerLink));
  const { createSubmissionPromise } = useAsyncValidation(updateDataConsumerFetch, {
    ftpTitleMutationConsumerAndHasFileType: 'fileType',
  });

  useEffect(() => {
    if (dataConsumerLink) {
      fetchDataConsumer(dataConsumerLink);
    }
  }, [dataConsumerLink, fetchDataConsumer]);

  return (
    <DelayedLoader data={dataConsumer}>
      <Form
        onSubmit={(values: DataConsumerDTO) => {
          if (!dataConsumerLink || !values) {
            return;
          }
          updateDataConsumer(dataConsumerLink, values);
          return createSubmissionPromise();
        }}
        initialValues={dataConsumer}
      >
        {({ handleSubmit, values, submitting, pristine }) => (
          <form onSubmit={handleSubmit}>
            <DataConsumerFormFields
              showFileType={Boolean(values?.isFtpTitleMutationConsumer)}
              showApiKey={Boolean(values?.apiKey)}
              disableNewKeyButton={!dataConsumer?.isWebserviceTitleConsumer}
              refreshApiKeyUrl={dataConsumer?._links.refreshApiKey.href}
              disableFTPConsumer={
                !organisation?.hasMembership && !dataConsumer?.isFtpTitleMutationConsumer
              }
              disableFTPMediaFileConsumer={
                !organisation?.hasMembership && !dataConsumer?.isFtpMediaFileConsumer
              }
              disableFileTypeSelect={!organisation?.hasMembership}
            />
            <Button type="submit" disabled={submitting || pristine}>
              {t('form_action_save')}
            </Button>
          </form>
        )}
      </Form>
    </DelayedLoader>
  );
};

export default DataConsumerForm;
