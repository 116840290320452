import { MenuItem } from '@mui/material';
import * as React from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Field from '../../../../../../components/Form';
import InfoTooltip from '../../../../../../components/InfoTooltip';
import { DiscountCodes } from '../../../../../../config/api/types';
import useFetch from '../../../../../../helpers/useFetch';
import { getDiscountCodesRequest } from '../../api';
import CommercialField from '../../components/sections/CommercialField';

type Props = {
  url: string;
};

const DiscountCodesField = ({ url }: Props) => {
  const { t } = useTranslation();
  const [discountCodes, fetchDiscountCodes] = useFetch<DiscountCodes>(getDiscountCodesRequest(url));

  useEffect(() => {
    if (!discountCodes) {
      fetchDiscountCodes();
    }
  }, [discountCodes, fetchDiscountCodes]);

  return (
    <CommercialField
      component={Field}
      label={
        <>
          {`${t('title_discountCode')} (*)`}{' '}
          <InfoTooltip title={t('title_discountCode_tooltip')} inline />
        </>
      }
      select
      type="select"
      name="discountCode.code"
    >
      <MenuItem>&nbsp;</MenuItem>
      {discountCodes &&
        discountCodes.value &&
        discountCodes.value.map((discount) => (
          <MenuItem key={discount.code} value={discount.code}>
            {discount.label}
          </MenuItem>
        ))}
    </CommercialField>
  );
};

export default DiscountCodesField;
