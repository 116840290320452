import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import RefreshIcon from '@mui/icons-material/Refresh';
import { PromiseState } from '@react-redux-fetch/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../../components/Button';
import Table from '../../../../components/Table';
import { CellType, DataType } from '../../../../components/Table/types';
import { OrderFiles } from '../../../../config/api/models/shop';
import { Maybe } from '../../../../config/api/types';
import getFilename from '../../../../helpers/getFilename';
import getIdFromUrl from '../../../../helpers/getIdFromUrl';
import { getLink } from '../../../../helpers/hateoas';
import StatusCell from '../../../title/admin/titleFiles/components/StatusCell';

type Props = {
  orderFiles: Maybe<OrderFiles>;
  orderFilesRequest: PromiseState | undefined;
  dispatchOrderFilesGet: (url: string) => void;
};

const OrderFilesTable = ({ orderFiles, dispatchOrderFilesGet, orderFilesRequest }: Props) => {
  const { t } = useTranslation();

  if (!orderFiles) {
    return null;
  }

  const headers = [
    { label: t('table_headers_filename') },
    { label: t('table_headers_uploaded'), width: 200 },
    { label: t('table_headers_result'), width: 250 },
    { label: '' },
  ];

  const data: DataType = {
    resource: 'orderFiles',
    rows: orderFiles._embedded.items.map((orderFile) => ({
      id: getLink(orderFile, 'self') || orderFile.path,
      cells: [
        {
          type: CellType.Text,
          data: getFilename(orderFile.path),
        },
        {
          type: CellType.Text,
          data: orderFile.uploadedAt,
        },
        {
          type: CellType.Text,
          data: (
            <StatusCell
              success={orderFile.numSuccess}
              total={orderFile.numTotal}
              status={orderFile.status}
            />
          ),
        },
        {
          type: CellType.Action,
          data: Boolean(!orderFile.numSuccess || orderFile.numSuccess < orderFile.numTotal) ? (
            <Button
              variant="text"
              secondary
              link={`/orderbestanden/bestanden/${getIdFromUrl(getLink(orderFile, 'self') || '')}`}
            >
              {t('table_btn_result')} <ChevronRightIcon />
            </Button>
          ) : null,
        },
      ],
    })),
  };

  return (
    <>
      <Button
        onClick={() => dispatchOrderFilesGet(getLink(orderFiles, 'self') || '')}
        disabled={orderFilesRequest?.pending}
      >
        <RefreshIcon />
      </Button>
      <Table
        data={data}
        headers={headers}
        limit={orderFiles.limit}
        page={orderFiles.page}
        pages={orderFiles.pages}
        total={orderFiles.total}
        dispatchFn={dispatchOrderFilesGet}
        pagedResource={orderFiles}
      />
    </>
  );
};

export default OrderFilesTable;
