import React from 'react';
import { RouteComponentProps } from 'react-router';
import ConstrainedPageContent from '../../../../../components/ConstrainedPageContent';
import TitleForm from '../containers/TitleForm';

type Props = RouteComponentProps<{ id: string }, {}, { path?: string }>;

const TitlePage = ({ match, history, location }: Props) => {
  return (
    <ConstrainedPageContent flex center={false}>
      <TitleForm
        titleId={match.params.id}
        navigate={history.push}
        previousPath={location.state?.path}
      />
    </ConstrainedPageContent>
  );
};
export default TitlePage;
