import React, { FC, useEffect } from 'react';
import { useFetch } from '@react-redux-fetch/hooks';
import { useSelector } from 'react-redux';
import useApiRoute from '../../../../../../../config/api/useApiRoute';
import { getContributorRolesRequest } from '../../../api';
import { getContributorRoles } from '../../../selectors';
import BaseReactSelectField from '../../../../../../../components/Form/BaseReactSelectField';
import { useTranslation } from 'react-i18next';
import { ContributorRole } from '../../../../../../../config/api/types';

type Props = {
  name: string;
};
const ContributorRoleField: FC<Props> = ({ name }) => {
  const { t } = useTranslation();
  const apiRoute = useApiRoute('titleContributorRoles');
  const [, fetchContributorRoles] = useFetch(getContributorRolesRequest);
  const roles = useSelector(getContributorRoles);

  useEffect(() => {
    if (apiRoute && !roles) {
      fetchContributorRoles(apiRoute);
    }
  }, [roles, apiRoute, fetchContributorRoles]);

  if (!roles?.length) {
    return null;
  }

  return (
    <BaseReactSelectField
      options={roles || []}
      getOptionLabel={(role: ContributorRole) => role.label}
      getOptionValue={(role: ContributorRole) => role}
      name={name}
      label={t('form_contributor_role')}
    />
  );
};

export default ContributorRoleField;
