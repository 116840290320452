import Divider from '@mui/material/Divider/Divider';
import React from 'react';
import SubTitle from '../../../../components/SubTitle';

type Props = {
  title: string;
};

const FormTitle = ({ title }: Props) => {
  return (
    <>
      <SubTitle>{title}</SubTitle>
      <Divider style={{ marginBottom: 16 }} />
    </>
  );
};

export default FormTitle;
