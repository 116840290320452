import useApiRoute from '../../../config/api/useApiRoute';
import { useFetch } from '@react-redux-fetch/hooks';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getThemasRequest } from '../api';
import { selectThemas } from '../selectors';

type Args = {
  initiate?: boolean;
};
export const useThemas = ({ initiate }: Args = { initiate: true }) => {
  const themasApiRoute = useApiRoute('titleThemas');
  const [, fetchThemas] = useFetch(getThemasRequest);
  const themas = useSelector(selectThemas);
  const hasThemas = themas && themas.length > 0;

  useEffect(() => {
    if (themasApiRoute && !hasThemas && initiate) {
      fetchThemas(themasApiRoute);
    }
  }, [hasThemas, themasApiRoute, fetchThemas, initiate]);

  return {
    themas,
    hasAccess: Boolean(themasApiRoute),
  };
};
