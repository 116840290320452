import CloseIcon from '@mui/icons-material/Close';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { Box, Dialog, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { PageFlip } from 'page-flip';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import HTMLFlipBook from 'react-pageflip';
import { Document, DocumentProps, pdfjs } from 'react-pdf';
import Button from '../../../../../components/Button';
import { Media } from '../../../../../config/api/types';
import { FlipbookPage } from './FlipbookPage';

import useWindowSize from '../../../../../helpers/useWindowSize';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

type Props = { sampleContent?: Media };
type LoadCallback = Required<DocumentProps>['onLoadSuccess'];
type PDFDocumentProxy = Parameters<LoadCallback>[0];

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    textTransform: 'uppercase',
    paddingLeft: 0,
    '& svg': {
      marginRight: 8,
    },
  },
  closeBtn: {
    position: 'absolute',
    right: '-100px',
    top: '-50px',
    cursor: 'pointer',
    backgroundColor: '#fff',
    borderRadius: '50%',
    boxShadow: '0px 0px 7px rgba(0, 0, 0, 0.7)',
    '&:hover': {
      boxShadow: 'none',
    },
  },
  btnBg: {
    backgroundColor: theme.palette.grey[600],
  },
  flipBook: {
    display: 'none',
  },
  page: {
    position: 'relative',
  },
  pageShadow: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    boxShadow: 'inset 0px 0 30px 0px rgba(0, 0, 0, 0.4)',
  },
}));

const calculateFlipbookWidth = (ratio: number, width: number, height: number) => {
  if (width === 0 || height === 0 || ratio === 0) return 1200;
  const bookWidth = (height - 170) / (ratio / 2);
  if (bookWidth > width - 300) return width - 300; // Window width overflow
  if (bookWidth > 1200 && ratio > 1) return 1200; // Max width for portrait pages
  return bookWidth;
};

const SampleContent = ({ sampleContent }: Props) => {
  const { t } = useTranslation();
  const classes = { ...useStyles() };
  const { height, width } = useWindowSize();
  const pdfRef = useRef<PDFDocumentProxy | null>(null);

  const [pdfOpen, setPdfOpen] = useState(false);
  const [numPages, setNumPages] = useState<number>(0);
  const [currentPageIndex, setCurrentPageIndex] = useState<number>(0);
  const [ratio, setRatio] = useState<number>(1);
  const flipbookWidth = calculateFlipbookWidth(ratio, width ?? 0, height ?? 0);

  const flipBookRef = useRef<{ pageFlip(): PageFlip }>(null);

  const setPageDimensions = useCallback(async (pageNumber: number) => {
    if (pdfRef.current == null) return;
    const page = await pdfRef.current.getPage(pageNumber);
    const pageInfo = await page.getViewport({ scale: 1 });

    const pageWidth = pageInfo.width;
    const pageHeight = pageInfo.height;

    setRatio(pageHeight / pageWidth);
    setNumPages(pdfRef.current.numPages);
  }, []);

  useEffect(() => {
    if (currentPageIndex > 0) setPageDimensions(currentPageIndex);
  }, [currentPageIndex, setPageDimensions]);

  if (!sampleContent || !sampleContent.original) {
    return null;
  }

  const closePdf = () => setPdfOpen(false);
  const openPdf = () => setPdfOpen(true);

  const onNext = () => {
    if (flipBookRef.current == null) return;
    flipBookRef.current.pageFlip().flipNext();
  };

  const onPrev = () => {
    if (flipBookRef.current == null) return;
    flipBookRef.current.pageFlip().flipPrev();
  };

  return (
    <>
      <Button color="primary" variant="text" onClick={openPdf} className={classes.button}>
        <InsertDriveFileIcon /> {t('title_sample_content_btn')}
      </Button>
      <Dialog
        open={pdfOpen}
        onClose={closePdf}
        PaperProps={{
          style: {
            backgroundColor: 'transparent',
            boxShadow: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            maxWidth: 'none',
            maxHeight: 'none',
            overflow: 'visible',
            transform: 'translateY(30px)',
          },
          elevation: 5,
        }}
      >
        <Box width={flipbookWidth} position="relative">
          <Box className={classes.closeBtn} p={1} onClick={closePdf}>
            <CloseIcon sx={{ fontSize: 'default', display: 'block' }} />
          </Box>
          <Document
            file={sampleContent.original}
            onLoadSuccess={(pdfObject) => {
              const getPdf = async (pdf: PDFDocumentProxy) => {
                pdfRef.current = pdf;
                void setPageDimensions(1);
              };
              void getPdf(pdfObject);
            }}
          >
            {numPages > 0 && (
              <>
                {/* @ts-ignore */}
                <HTMLFlipBook
                  key={ratio}
                  width={flipbookWidth / 2}
                  height={(flipbookWidth / 2) * ratio}
                  maxShadowOpacity={0.5}
                  flippingTime={600}
                  ref={flipBookRef}
                  size="stretch"
                  className={classes.flipBook}
                  onFlip={(e: { data: number }) => setCurrentPageIndex(e.data)}
                  onInit={(e: { page?: number }) => setCurrentPageIndex(e.page ?? 0)}
                >
                  {[...Array(numPages)].map((_, pageNumber) => (
                    <FlipbookPage
                      pageNumber={pageNumber + 1}
                      width={flipbookWidth / 2}
                      key={`pdf-page-${pageNumber + 1}`}
                    />
                  ))}
                </HTMLFlipBook>
              </>
            )}
          </Document>
          <Box
            style={{
              gap: '30px',
              display: 'flex',
              marginTop: '30px',
              justifyContent: 'center',
              paddingLeft: '20px',
            }}
          >
            <Box className={classes.btnBg}>
              <Button type="button" onClick={onPrev} disabled={currentPageIndex <= 1}>
                {t('pdf_page_prev')}
              </Button>
            </Box>
            <Box className={classes.btnBg}>
              <Button type="button" onClick={onNext} disabled={currentPageIndex >= numPages - 2}>
                {t('pdf_page_next')}
              </Button>
            </Box>
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

export default SampleContent;
