import { FetchConfig } from '@react-redux-fetch/core';
import { Data } from '../../../../config/store/types';
import apiRoutes from '../../../../config/api/routes';
import { RetourDetail, RetourLine } from '../../../../config/store/retour';
import i18next from 'i18next';

export type RetourLineRequest = {
  distributor?: string;
  gtin13?: string;
  quantity?: number;
  reason?: string;
  reference?: string;
  backorder?: boolean;
  articleReturn?: boolean;
  deliveryNote?: string;
  invoiceNumber?: string;
  lineReference?: string;
  comments?: string;

  id?: string;
  state?: string;
};

export const createRetourLineRequest = (
  url: string,
  body: RetourLineRequest
): FetchConfig<Data> => {
  return {
    method: 'POST',
    url: apiRoutes.create(url),
    requestKey: 'retourLine',
    fetchOptions: {
      body,
      successMsg: i18next.t('return_add_line_success'),
    },
    repository: {
      unconfirmedRetour: (prev: RetourDetail, next: RetourLine): RetourDetail => {
        return {
          ...prev,
          lines: [...prev.lines, next],
        };
      },
    },
  };
};
