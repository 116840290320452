import { Alert } from '@mui/lab';
import { Box, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { FormRenderProps } from 'react-final-form';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import Button from '../../../components/Button';
import Field from '../../../components/Form/index';
import TextLink from '../../../components/TextLink';
import ROUTE_KEY from '../../../config/routes/routeKeys';
import usePath from '../../../config/routes/usePath';

const useStyles = makeStyles(
  (theme: Theme) => ({
    field: {
      marginTop: theme.spacing(1),
    },
    input: {
      padding: theme.spacing(2),
    },
    button: {
      padding: `${theme.spacing(1.5)} ${theme.spacing(2)}`,
    },
  }),
  { name: 'Login' }
);

type Props = {
  onSubmit: FormRenderProps['handleSubmit'];
  disabled?: boolean;
  error: string;
  errorCode: number;
  values: FormRenderProps['values'];
};

const Login = ({ onSubmit, disabled, error, errorCode, values }: Props) => {
  const {
    location: { state },
  } = useHistory<{ message?: string }>();
  const { t } = useTranslation();
  const forgotPWLink = usePath(ROUTE_KEY.FORGOT_PW);
  const classes = useStyles();

  const errorMessage =
    errorCode === 401 ? (
      <Trans
        i18nKey="security_invalid"
        components={[
          <Link
            to={{
              pathname: forgotPWLink,
              state: {
                email: values.username,
              },
            }}
          />,
        ]}
      />
    ) : (
      error
    );

  return (
    <>
      {state?.message && (
        <Box mb={2}>
          <Alert severity="success">{state.message}</Alert>
        </Box>
      )}
      <form onSubmit={onSubmit}>
        <Box mb={1}>
          <Field
            name="username"
            type="text"
            label={t('security_code')}
            margin="normal"
            customError={errorMessage}
            disabled={disabled}
            className={classes.field}
            InputProps={{ classes: { input: classes.input } }}
          />
        </Box>
        <Box mb={2}>
          <Field
            name="password"
            type="password"
            label={t('security_pw')}
            margin="normal"
            disabled={disabled}
            className={classes.field}
            InputProps={{ classes: { input: classes.input } }}
          />
        </Box>
        <Box mb={2}>
          <Button fullWidth type="submit" disabled={disabled} className={classes.button}>
            {t('security_login')}
          </Button>
        </Box>
        <Box mb={1}>
          {forgotPWLink && (
            <Typography color="primary">
              <TextLink
                to={{
                  pathname: forgotPWLink,
                  state: {
                    email: values.username,
                  },
                }}
              >
                {t('security_pw_forgotten')}
              </TextLink>
            </Typography>
          )}
        </Box>
      </form>
    </>
  );
};

export default Login;
