import { CircularProgress } from '@mui/material';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, useHistory } from 'react-router-dom';
import useQuery from '../../../helpers/useQuery';
import SmallCenterLayout from '../components/SmallCenterLayout';
import SetPasswordForm from '../containers/SetPasswordForm';
import useForceLogout from '../hooks/useForceLogout';

const ActivatePage = () => {
  const { t } = useTranslation();
  const query = useQuery();
  const history = useHistory();
  const token = query.get('token');
  const { isAuthenticated } = useForceLogout();

  const handleSuccess = useCallback(() => {
    history.push('/login', { message: t('security_set_password_success') });
  }, [history, t]);

  if (!token) {
    return <Redirect to="/login" />;
  }

  return (
    <SmallCenterLayout>
      {isAuthenticated ? (
        <CircularProgress />
      ) : (
        <SetPasswordForm onSuccess={handleSuccess} token={token} />
      )}
    </SmallCenterLayout>
  );
};

export default ActivatePage;
