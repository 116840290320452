import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  AnyArray,
  buildActionsFromMappings,
  FetchConfig,
  PromiseState,
  selectors,
} from 'react-redux-fetch';
// import isEqualWith from 'lodash/isEqualWith';
// import isEqual from 'lodash/isEqual';
// import isFunction from 'lodash/isFunction';

const getResourceName = (fetchConfig: FetchConfig<any>) => {
  if (typeof fetchConfig.resource === 'string') {
    return fetchConfig.resource;
  }
  return fetchConfig.resource.name;
};

const actionCache: Record<
  string,
  { action: (...args: any[]) => void; fetchConfig: FetchConfig<any> }
> = {};

// function isequalcustomizer(
//   objValue: unknown,
//   othValue: unknown,
//   key?: string | number | symbol
// ): true | undefined {
//   return (key === 'request' && isFunction(objValue) && isFunction(othValue)) || undefined;
// }

const getAction = <TReqParams extends AnyArray>(fetchConfig: FetchConfig<TReqParams>) => {
  const resourceName =
    typeof fetchConfig.resource === 'string' ? fetchConfig.resource : fetchConfig.resource.name;
  const cacheKey = resourceName + (fetchConfig.method || 'GET');

  if (
    !actionCache[cacheKey] ||
    fetchConfig !== actionCache[cacheKey].fetchConfig
    // !isEqualWith(fetchConfig, actionCache[cacheKey].fetchConfig, isEqualCustomizer)
  ) {
    const actions = buildActionsFromMappings([fetchConfig]);
    actionCache[cacheKey] = {
      fetchConfig,
      action: actions[Object.keys(actions)[0]],
    };
  }

  return actionCache[cacheKey].action;
};

export function useFetch<TResponse = any, TReqParams extends AnyArray = any>(
  fetchConfig: FetchConfig<TReqParams>
): [PromiseState<TResponse> | undefined, (...args: TReqParams) => void] {
  const action = getAction(fetchConfig);
  const dispatch = useDispatch();

  const promiseState = useSelector(
    selectors.getPromise(getResourceName(fetchConfig)).fromState /*,
    isEqual*/
  ) as PromiseState<TResponse>;

  const makeRequest = useCallback(
    (...args: TReqParams) => dispatch(action(...args)),
    [action, dispatch]
  );

  return [promiseState, makeRequest];
}

export default useFetch;
