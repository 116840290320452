import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useIsVisible } from 'react-is-visible';
import FileIcon from '../../../../../../components/FileIcon';
import { PollForChanges } from '../../../../../../components/PollForChanges/PollForChanges';
import Table from '../../../../../../components/Table';
import { CellType, DataType } from '../../../../../../components/Table/types';
import { TitleMediaFiles } from '../../../../../../config/api/types';
import { ExcludesFalse } from '../../../../../../helpers/ExcludesFalse';
import getFilename from '../../../../../../helpers/getFilename';
import getIdFromUrl from '../../../../../../helpers/getIdFromUrl';
import { updateQueryParameters } from '../../../../../../helpers/hateoas';
import isImage from '../../../../../../helpers/isImage';
import useFetch from '../../../../../../helpers/useFetch';
import StatusCell from '../../../mediaFiles/components/StatusCell';
import { getTitleMediaFilesRequest } from '../../api';

type Props = {
  pollForChanges?: boolean;
  url: string;
  onStopPolling: () => void;
  pollingInterval?: number;
};
const LIMIT = 5;

const MediaFileTable = ({ pollForChanges, url, onStopPolling, pollingInterval = 2000 }: Props) => {
  const finalUrl = updateQueryParameters(url, {
    limit: LIMIT,
    type: 'MEDIA_TYPE_FRONT,MEDIA_TYPE_BACK,MEDIA_TYPE_SAMPLE_CONTENT',
  });
  const { t } = useTranslation();
  const [mediaFilesFetch, getMediaFiles] = useFetch<TitleMediaFiles>(getTitleMediaFilesRequest());
  const nodeRef = React.useRef(null);
  const isVisible = useIsVisible(nodeRef);
  useEffect(() => {
    if (isVisible) {
      getMediaFiles(finalUrl);
    }
  }, [isVisible, getMediaFiles, finalUrl]);

  const pagedMediaFiles = mediaFilesFetch && mediaFilesFetch.value;

  const pagedMediaFileItems = pagedMediaFiles?._embedded.items || [];

  const showSource = pagedMediaFileItems[0]?.source;
  const showBy = pagedMediaFileItems[0]?.byOrganisation;

  const data: DataType = {
    resource: 'mediaFiles',
    rows: pagedMediaFileItems.map((titleMediaFile) => {
      const key = `${titleMediaFile.path}${titleMediaFile.uploadedAt}`;

      return {
        id: key,
        cells: [
          {
            type: CellType.Action,
            data: <FileIcon fileName={titleMediaFile.path} />,
          },
          {
            type: CellType.Text,
            data:
              titleMediaFile.type === 'MEDIA_TYPE_FRONT'
                ? t('title_heading_frontCover')
                : titleMediaFile.type === 'MEDIA_TYPE_BACK'
                ? t('title_heading_backCover')
                : titleMediaFile.type === 'MEDIA_TYPE_SAMPLE_CONTENT'
                ? t('title_heading_sample')
                : // FALLBACK
                isImage(titleMediaFile.path)
                ? getFilename(titleMediaFile.path).includes('a')
                  ? t('title_heading_backCover')
                  : t('title_heading_frontCover')
                : t('title_heading_sample'),
          },
          showSource
            ? {
                type: CellType.Text,
                data: titleMediaFile.source,
              }
            : null,
          {
            type: CellType.Text,
            data: titleMediaFile.uploadedAt,
          },
          showBy
            ? {
                type: CellType.Text,
                data:
                  titleMediaFile.byUser && titleMediaFile.byOrganisation
                    ? `${titleMediaFile.byUser.firstName} ${titleMediaFile.byUser.lastName} (${titleMediaFile.byOrganisation.name})`
                    : titleMediaFile.byOrganisation
                    ? titleMediaFile.byOrganisation.name
                    : '',
              }
            : null,
          {
            type: CellType.Text,
            data: <StatusCell status={titleMediaFile.status} />,
          },
          {
            type: CellType.Array,
            data:
              titleMediaFile._embedded &&
              titleMediaFile._embedded.importResult &&
              titleMediaFile._embedded.importResult.map((result) => result.message),
          },
        ].filter(Boolean as any as ExcludesFalse),
      };
    }),
  };

  const headers = [
    { label: '' },
    { label: '' },
    showSource ? { label: t('table_headers_through') } : null,
    { label: t('table_headers_uploaded'), width: '10rem' },
    showBy ? { label: t('table_headers_by'), width: '10rem' } : null,
    { label: t('table_headers_result'), width: '14rem' },
    { label: t('table_headers_alert') },
  ].filter(Boolean as any as ExcludesFalse);

  if (
    !mediaFilesFetch ||
    getIdFromUrl(finalUrl) !== getIdFromUrl(pagedMediaFiles?._links.self.href)
  ) {
    return <div ref={nodeRef}>&nbsp;</div>;
  }

  return (
    <div ref={nodeRef}>
      <PollForChanges
        onStopPolling={onStopPolling}
        query={() => getMediaFiles(finalUrl)}
        pagedResponse={pagedMediaFiles}
        pollForChanges={pollForChanges}
        pollingInterval={pollingInterval}
        mediaType="media"
      >
        {!pollForChanges &&
        (!pagedMediaFiles || pagedMediaFiles._embedded.items.length === 0) ? null : (
          <Table
            data={data}
            headers={headers}
            limit={pagedMediaFiles?.limit || LIMIT}
            page={pagedMediaFiles?.page || 1}
            pages={pagedMediaFiles?.pages || 1}
            total={pagedMediaFiles?.total || 0}
            dispatchFn={getMediaFiles}
            pagedResource={pagedMediaFiles}
            paginationSettings={{
              rowsPerPageOptions: [5],
            }}
          />
        )}
      </PollForChanges>
    </div>
  );
};

export default MediaFileTable;
