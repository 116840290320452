import { Theme } from '@mui/material';
import { styled } from '@mui/styles';
import React, { HTMLProps } from 'react';

type Props = Partial<HTMLProps<HTMLDivElement>> & {};

type StyledProps = {
  theme: Theme;
};

const Fieldset = ({ ...other }: Props) => <div {...other} />;

export default styled(Fieldset)(({ theme }: StyledProps) => ({
  padding: `${theme.spacing(2)} 0px ${theme.spacing(1)}`,
  margin: `0px ${theme.spacing(2)}`,
  background: 'transparent',
  '& + div': {
    borderTop: `3px solid ${theme.palette.common.white}`,
  },
}));
