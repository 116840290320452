import { formatFields } from '../../domain';
import React from 'react';

type Props = {
  [key: string]: any;
  name: keyof { [key in typeof formatFields[number]]: true };
};

const TitleFormatField = ({ component: Component, ...other }: Props) => <Component {...other} />;

export default TitleFormatField;
