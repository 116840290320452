import React, { FC } from 'react';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import WhenFieldChanges from '../../../../../../components/Form/WhenFieldChanges';
import InfoTooltip from '../../../../../../components/InfoTooltip';
import { Thema as ThemaModel, ThemaQualifiers } from '../../../../../../config/api/models/dataSets';
import { KeywordsField } from '../../containers/fields/KeywordsField';
import { NurFieldAdapter } from '../../containers/fields/NurField';
import { ThemaQualifiersFieldAdapter } from '../../containers/fields/ThemaQualifiersField';
import { ThemasFieldAdapter } from '../../containers/fields/ThemasField';
import Fieldset from '../Fieldset';
import FieldsetTitle from '../FieldsetTitle';
import SectionWrapper from '../SectionWrapper';
import ThemaField from './ThemaField';

type ThemaProps = {
  selectedThemas?: ThemaModel[];
  selectedQualifiers?: ThemaQualifiers;
  isBibliographicalVerified?: boolean;
  showKeywords?: boolean;
  themaAndNurRequired?: boolean;
};
const Thema: FC<ThemaProps> = ({
  selectedThemas,
  selectedQualifiers,
  isBibliographicalVerified,
  showKeywords = true,
  themaAndNurRequired = false,
}) => {
  const { t } = useTranslation();

  return (
    <SectionWrapper id="thema">
      <FieldsetTitle>{t('title_section_thema')}</FieldsetTitle>
      <Fieldset>
        <ThemaField
          name="nur"
          component={Field}
          /* @ts-expect-error -- react 18 upgrade */
          innerComponent={NurFieldAdapter}
          label={
            <>
              {t('title_nur_code_classification')} (*)
              <InfoTooltip title={t('title_nur_code_tooltip')} inline />
            </>
          }
          validate={(value: unknown) =>
            value || !themaAndNurRequired ? undefined : t('isbn_application_nur_required_error')
          }
        />
      </Fieldset>
      <Fieldset>
        <ThemaField
          name="themas"
          component={Field}
          /* @ts-expect-error -- react 18 upgrade */
          innerComponent={ThemasFieldAdapter}
          label={
            <>
              {t('title_thema')} (*)
              <InfoTooltip title={t('title_thema_tooltip')} inline />
            </>
          }
          validate={(value: any) => {
            return value?.length > 0 || !themaAndNurRequired
              ? undefined
              : t('isbn_application_thema_required_error');
          }}
        />
        {selectedQualifiers && selectedQualifiers.length > 0 && (
          <WhenFieldChanges
            field="themas"
            set="themaQualifiers"
            to={(themas: ThemaModel[], fieldValue) => {
              if (themas.length === 0) {
                return [];
              }
              return selectedQualifiers || [];
            }}
          />
        )}
        {selectedThemas && selectedThemas.length > 0 && (
          <ThemaField
            name="themaQualifiers"
            component={Field}
            /* @ts-expect-error -- react 18 upgrade */
            innerComponent={ThemaQualifiersFieldAdapter}
            label={
              <>
                {t('title_thema_qualifiers')}
                <InfoTooltip title={t('title_thema_qualifiers_tooltip')} inline />
              </>
            }
          />
        )}
      </Fieldset>

      {showKeywords && (
        <Fieldset>
          <ThemaField
            name="keywords"
            label={
              <>
                {t('title_keywords')}
                <InfoTooltip
                  title={
                    isBibliographicalVerified
                      ? t('title_isBibliographicalVerified_disabled_field')
                      : t('title_keywords_tooltip')
                  }
                  inline
                />
              </>
            }
            component={KeywordsField}
            disabled={isBibliographicalVerified}
          />
        </Fieldset>
      )}
    </SectionWrapper>
  );
};

export default Thema;
