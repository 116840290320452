import { RootState } from '../../../../config/store/types';
import { ContributorRoles, Maybe, Title } from '../../../../config/api/types';
import {
  AgeRanges,
  AviLevels,
  AgeRangeGroups,
  CollectionItem,
  TitleKeywords,
} from '../../../../config/api/models/dataSets';

export const getAgeRanges = (state: RootState): Maybe<AgeRanges> => state.data.ageRanges;

export const getTitleKeywords = (state: RootState): Maybe<TitleKeywords> => state.data.keywords;

export const getAgeRangeGroups = (state: RootState): Maybe<AgeRangeGroups> =>
  state.data.ageRangeGroups;

export const getAviLevels = (resource: 'aviLevelsNew' | 'aviLevelsOld') => (
  state: RootState
): Maybe<AviLevels> => state.data[resource];

export const getCollections = (state: RootState): Maybe<CollectionItem[]> => state.data.collections;

export const getContributorRoles = (state: RootState): Maybe<ContributorRoles> =>
  state.data.contributorRoles;

export const getTitleReplacementSearch = (state: RootState) => state.data.titleReplacementSearch;
export const getTitleReplacementSearchTitles = (state: RootState): Title[] =>
  state.data.titleReplacementSearch?._embedded.items || [];

export const getPublishers = (state: RootState) =>
  state.repository?.titlePublishers?.value?._embedded?.items;

export const getLanguages = (state: RootState) => state.repository?.titleLanguages?.value;

export const getFunds = (state: RootState) => state.repository?.titleFunds?.value?._embedded?.items;

export const selectTitle = (state: RootState) => state.data.title;
