import { CheckboxProps } from '@mui/material/Checkbox';
import { RadioProps as MuiRadioProps } from '@mui/material/Radio';
import { TextFieldProps } from '@mui/material/TextField';
import { DateFieldProps } from '@mui/x-date-pickers';
import React from 'react';
import { EditorProps } from 'react-draft-wysiwyg';
import { FieldProps, Field as FinalFormField } from 'react-final-form';
import BaseReactSelectField from './BaseReactSelectField';
import MuiCheckBox from './CheckBox';
import MuiDateField from './DateField';
import MuiRadio from './Radio';
import SearchField from './SearchField';
import SelectField from './SelectField';
import MuiTextField from './TextField';
import DraftWysiwyg from './Wysiwyg/Wysiwyg';

type CheckBoxProps = FieldProps<any, any> & CheckboxProps & {};
type RadioProps = FieldProps<any, any> & MuiRadioProps & {};
type TextProps = FieldProps<any, any> &
  TextFieldProps & {
    customError?: React.ReactNode;
    validateOnChange?: boolean;
  };
type WysiwygProps = FieldProps<any, any> & EditorProps;
type DateProps = FieldProps<any, any> & Omit<DateFieldProps<any>, 'onError' | 'format'>;

type Props = TextFieldProps &
  FieldProps<any, any> & {
    type: 'text' | 'select' | 'autocomplete' | 'date' | 'password' | 'number' | 'wysiwyg';
    customError?: React.ReactNode;
    validateOnChange?: boolean;
  };

const TextField = ({ ...props }: TextProps) => (
  <FinalFormField component={MuiTextField} parse={(value) => value} {...props} />
);
const DateField = ({ ...props }: DateProps) => (
  <FinalFormField component={MuiDateField} sx={{ width: '100%' }} {...props} />
);
const CheckBox = ({ ...props }: CheckBoxProps) => (
  <FinalFormField type="checkbox" component={MuiCheckBox} {...props} />
);
const Radio = ({ ...props }: RadioProps) => (
  <FinalFormField type="radio" component={MuiRadio} {...props} />
);
const Wysiwyg = ({ ...props }: WysiwygProps) => (
  <FinalFormField type="wysiwyg" component={DraftWysiwyg} {...props} />
);

/**
 * This is nothing more then a switch between the different types of fields
 * exports as Field
 */
const Field = (props: Props) => {
  switch (props.type) {
    case 'text':
    case 'number':
      return <TextField {...props} validateOnChange={props.validateOnChange} />;
    case 'select':
      return <TextField {...props} select />;
    case 'autocomplete':
      return <BaseReactSelectField {...props} select />;
    case 'date':
      return <DateField {...props} />;
    default:
      return <TextField {...props} />;
  }
};
export default Field;
export { CheckBox, DateField, Radio, SearchField, SelectField, TextField, Wysiwyg };
