import { Box, Grid, MenuItem, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useFetch } from '@react-redux-fetch/hooks';
import moment from 'moment';
import React, { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import DelayedLoader from '../../../../components/DelayedLoader';
import DropdownButton from '../../../../components/DropdownButton';
import Header from '../../../../components/Header';
import SubTitle from '../../../../components/SubTitle';
import useApiRoute from '../../../../config/api/useApiRoute';
import getIdFromUrl from '../../../../helpers/getIdFromUrl';
import { getLink } from '../../../../helpers/hateoas';
import ExportButton from '../../../core/exports';
import organisation from '../../../organisation';
import {
  getOrderLineStatusesRequest,
  getOrderRequest,
  patchOrderLinesStateRequest,
} from '../../api';
import { getOrder, getOrderLineStatuses } from '../../selectors';
import { Label, Row, Value } from './Atoms';
import OrderLibraryDetailTable from './OrderLibraryDetailTable';

type Props = {
  orderId: string;
  goBackPath?: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  distributor: {
    color: theme.palette.text.subtitle,
    fontSize: 18,
  },
}));

const OrderLibraryDetail = ({ orderId, goBackPath }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const statusesApiUrl = useApiRoute('orderLineStatus');
  const orderLinePatchUrl = '/orders/lines/state';

  const apiRoute = useApiRoute('order', orderId) || '';
  const [orderFetch, fetchOrder] = useFetch(getOrderRequest);

  const order = useSelector(getOrder);

  const statuses = useSelector(getOrderLineStatuses);

  const [, fetchStatuses] = useFetch(getOrderLineStatusesRequest);
  const [patchStatusesRequest, patchStatuses] = useFetch(patchOrderLinesStateRequest);

  useEffect(() => {
    if (apiRoute) {
      fetchOrder(apiRoute);
    }
  }, [fetchOrder, apiRoute]);

  useEffect(() => {
    if (statusesApiUrl && !statuses) {
      fetchStatuses(statusesApiUrl);
    }
  }, [fetchStatuses, statusesApiUrl, statuses]);

  useEffect(() => {
    if (patchStatusesRequest?.fulfilled && apiRoute) {
      fetchOrder(apiRoute);
    }
  }, [patchStatusesRequest, apiRoute, fetchOrder]);

  if (!apiRoute) {
    return <Redirect to="/" />;
  }

  const handleStatusBulkChange = (newState: string) => {
    if (order) {
      const orderLineIds: string[] = [];
      order.linesByDistributor.forEach((distributorLine) => {
        distributorLine.lines?.forEach((line) => {
          if (!line.stateLibrary || line.stateLibrary === 'submitted') {
            const link = getLink(line, 'changeOrderLineStatus');
            const id = getIdFromUrl(link || '');
            if (id) {
              orderLineIds.push(id);
            }
          }
        });
      });

      if (orderLineIds.length && orderLinePatchUrl) {
        patchStatuses(orderLinePatchUrl, {
          orderLines: orderLineIds.map((id) => ({ orderLineId: id, state: newState })),
        });
      }
    }
  };

  const isLoading =
    orderFetch?.pending ||
    !order ||
    order.linesByDistributor.some((distributorLine) => !distributorLine.lines);
  const bookstore = order?._embedded.bookstore;

  return (
    <DelayedLoader data={!isLoading}>
      {order ? (
        <>
          <Header
            title={t(`page_title_library_order_detail_customer`)}
            goBack
            goBackPath={goBackPath}
          />

          <Grid container alignItems="flex-end" spacing={1} direction="column">
            <Grid item component={Box} marginTop={-7.5}>
              <ExportButton url={apiRoute} size="small">
                {t('order_library_single_export')}
              </ExportButton>
            </Grid>
            <Grid item>
              <DropdownButton
                label={t('orderline_change_status_all')}
                size="small"
                closeOnChildClick
                disabled={patchStatusesRequest?.pending}
              >
                <MenuItem onClick={() => handleStatusBulkChange('delivered')}>
                  {t('orderline_submitted_to_delivered')}
                </MenuItem>
                <MenuItem onClick={() => handleStatusBulkChange('cancelled')}>
                  {t('orderline_submitted_to_cancelled')}
                </MenuItem>
              </DropdownButton>
            </Grid>
          </Grid>

          <Box marginTop={3}>
            <Grid container sx={{ justifyContent: 'space-between' }}>
              <Grid item xs={9}>
                {order.orderNumber && (
                  <Row>
                    <Label>{t('order_library_number')}:</Label>
                    <Value>{order.orderNumber}</Value>
                  </Row>
                )}
                <Row>
                  <Label>{t('table_headers_library_order_reference')}:</Label>
                  <Value>{order.orderReference}</Value>
                </Row>
                <Row>
                  <Label>{t('table_headers_department')}:</Label>
                  <Value>{order._embedded?.department?.title || ''}</Value>
                </Row>
                <Row>
                  <Label>{t('table_headers_ordered_at')}:</Label>
                  <Value>{moment(order.orderedAt).format(`DD/MM/YYYY [${t('at')}] HH:mm`)}</Value>
                </Row>
              </Grid>
            </Grid>
          </Box>

          <Box marginTop={3}>
            <SubTitle>
              <Trans
                i18nKey="order_ordered_at"
                components={[
                  <organisation.components.OrganisationDialog
                    organisationUrl={bookstore?._links.organisation.href || ''}
                    toggleButtonProps={{ className: classes.distributor }}
                  >
                    {{ organisation: bookstore?._embedded.organisation.name } as any}
                  </organisation.components.OrganisationDialog>,
                ]}
                values={{ organisation: bookstore?._embedded.organisation.name }}
              />
            </SubTitle>
          </Box>

          <OrderLibraryDetailTable lines={order.linesByDistributor} orderRef={apiRoute} />
        </>
      ) : null}
    </DelayedLoader>
  );
};

export default OrderLibraryDetail;
