import alertReducer from './reducer';
import { Actions } from './actions';
import Alert from './containers/Alert';

const alerts = {
  reducer: alertReducer,
  actions: Actions,
  containers: { Alert },
};

export default alerts;
