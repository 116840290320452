import SearchIcon from '@mui/icons-material/Search';
import { Box, Grid, List, ListItem, Theme, Typography, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PieChart } from 'react-minimal-pie-chart';
import Button from '../../../components/Button';
import InfoTooltip from '../../../components/InfoTooltip';
import TextLink from '../../../components/TextLink';
import { Prefix } from '../../../config/api/types';
import useApiRoute from '../../../config/api/useApiRoute';
import ROUTE_KEY from '../../../config/routes/routeKeys';
import usePath from '../../../config/routes/usePath';
import { updateQueryParameter } from '../../../helpers/hateoas';
import useFileExport from '../../../helpers/useFileExport';
import { canStartNewApplication } from '../domain';
import LegendListItem from './LegendListItem';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
  },
  title: {
    marginBottom: theme.spacing(1),
  },
  totalNumbers: {
    color: theme.palette.text.hint,
  },
  legendListItem: {
    minHeight: 32,
  },
  tinyButton: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  searchIcon: {
    position: 'relative',
    top: 4,
    left: 4,
  },
}));

type Props = {
  prefix: Prefix;
};

const PrefixListItem = ({ prefix }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();

  const isbnExportApiRoute = useApiRoute('isbnExport') || '';
  const isbnApiRoute = updateQueryParameter(isbnExportApiRoute, 'prefix', prefix.prefix);
  const [occupiedExportInProgress, exportOccupied] = useFileExport(
    updateQueryParameter(isbnApiRoute, 'type', 'occupied')
  );
  const [reservedExportInProgress, exportReserved] = useFileExport(
    updateQueryParameter(isbnApiRoute, 'type', 'reserved')
  );

  const totalNumbers =
    // prefix.freeIsbnCount +
    prefix.occupiedIsbnCount +
    prefix.usedIsbnCount +
    prefix.remainingIsbnCount +
    prefix.applicationIsbnCount +
    prefix.reservedIsbnCount;
  const newApplicationLink = usePath(ROUTE_KEY.ISBN_APPLICATION, { isbn: prefix.prefix });

  const catalogSearchLink = `${usePath(ROUTE_KEY.TITLES)}?q=${prefix.prefix}`;
  const newISBNRoute = usePath(ROUTE_KEY.MANAGE_TITLE_ADD);

  return (
    <ListItem divider disableGutters className={classes.root}>
      <Typography variant="h3" className={classes.title}>
        {prefix.prefix}
        <Typography variant="inherit" component="span" className={classes.totalNumbers}>
          {` (${totalNumbers} nummers)`}
        </Typography>
      </Typography>

      <Box display="flex" width="100%">
        <Box width={64} mr={4}>
          <PieChart
            startAngle={-90}
            data={[
              {
                title: t('isbn_prefix_used_numbers', { count: +prefix.usedIsbnCount }),
                value: prefix.usedIsbnCount,
                color: `${theme.palette.prefixLegend.used}`,
              },
              {
                title: t('isbn_prefix_reserved_numbers', { count: +prefix.reservedIsbnCount }),
                value: prefix.reservedIsbnCount,
                color: `${theme.palette.prefixLegend.reserved}`,
              },
              {
                title: t('isbn_prefix_occupied_numbers', { count: +prefix.occupiedIsbnCount }),
                value: prefix.occupiedIsbnCount,
                color: `${theme.palette.prefixLegend.occupied}`,
              },
              {
                title: t('isbn_prefix_application_numbers', {
                  count: +prefix.applicationIsbnCount,
                }),
                value: prefix.applicationIsbnCount,
                color: `${theme.palette.prefixLegend.application}`,
              },
              {
                title: t('isbn_prefix_free_numbers', { count: +prefix.remainingIsbnCount }),
                value: prefix.remainingIsbnCount,
                color: `${theme.palette.prefixLegend.remaining}`,
              },
            ]}
          />
        </Box>
        <Box flexGrow={1}>
          <List disablePadding dense>
            <LegendListItem
              color={theme.palette.prefixLegend.remaining}
              className={classes.legendListItem}
            >
              <Grid container alignItems="center">
                <Grid item xs={2}>
                  <Typography>
                    {t('isbn_prefix_free_numbers', { count: +prefix.remainingIsbnCount })}
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  {canStartNewApplication(prefix) && (
                    <Button size="small" className={classes.tinyButton} link={newApplicationLink}>
                      {t('isbn_new_application_btn')}
                    </Button>
                  )}
                </Grid>
              </Grid>
            </LegendListItem>

            {Boolean(prefix.reservedIsbnCount) && (
              <LegendListItem
                color={theme.palette.prefixLegend.reserved}
                className={classes.legendListItem}
              >
                <Grid container alignItems="center">
                  <Grid item xs={2}>
                    <Typography>
                      {t('isbn_prefix_reserved_numbers', { count: +prefix.reservedIsbnCount })}
                      <InfoTooltip title={t('isbn_prefix_reserved_numbers_tooltip')} inline />
                    </Typography>
                  </Grid>
                  <Grid item container xs={8} spacing={1}>
                    <Grid item>
                      <Button
                        size="small"
                        className={classes.tinyButton}
                        variant="outlined"
                        onClick={exportReserved}
                        disabled={reservedExportInProgress}
                      >
                        {t('isbn_prefix_reserved_numbers_export')}
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button size="small" className={classes.tinyButton} link={newISBNRoute}>
                        {t('isbn_register_new')}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </LegendListItem>
            )}

            {Boolean(prefix.occupiedIsbnCount) && (
              <LegendListItem
                color={theme.palette.prefixLegend.occupied}
                className={classes.legendListItem}
              >
                <Grid container alignItems="center">
                  <Grid item xs={2}>
                    <Typography>
                      {t('isbn_prefix_occupied_numbers', { count: +prefix.occupiedIsbnCount })}
                      <InfoTooltip title={t('isbn_prefix_occupied_numbers_tooltip')} inline />
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Button
                      size="small"
                      className={classes.tinyButton}
                      variant="outlined"
                      onClick={exportOccupied}
                      disabled={occupiedExportInProgress}
                    >
                      {t('isbn_prefix_occupied_numbers_export')}
                    </Button>
                  </Grid>
                </Grid>
              </LegendListItem>
            )}

            {Boolean(prefix.applicationIsbnCount) && (
              <LegendListItem
                color={theme.palette.prefixLegend.application}
                className={classes.legendListItem}
              >
                <Typography>
                  {t('isbn_prefix_application_numbers', {
                    count: +prefix.applicationIsbnCount,
                  })}
                  <InfoTooltip title={t('isbn_prefix_application_numbers_tooltip')} inline />
                </Typography>
              </LegendListItem>
            )}

            {Boolean(prefix.usedIsbnCount) && (
              <LegendListItem
                color={theme.palette.prefixLegend.used}
                className={classes.legendListItem}
              >
                <Typography color="primary">
                  <TextLink to={catalogSearchLink}>
                    {t('isbn_prefix_used_numbers', { count: +prefix.usedIsbnCount })}
                    <SearchIcon fontSize="inherit" className={classes.searchIcon} />
                  </TextLink>
                </Typography>
              </LegendListItem>
            )}
          </List>
        </Box>
      </Box>
    </ListItem>
  );
};

export default PrefixListItem;
