import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  fieldset: {
    margin: `0 0 ${theme.spacing(3)}`,
    padding: 0,
    border: 'none',
  },
}));

type Props = React.HTMLAttributes<HTMLFieldSetElement>;

const Fieldset = (props: Props) => {
  const classes = useStyles();

  return <fieldset className={classes.fieldset} {...props} />;
};

export default Fieldset;
