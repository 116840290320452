import { Grid } from '@mui/material';
import { useFetch } from '@react-redux-fetch/hooks';
import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useIsVisible } from 'react-is-visible';
import { useSelector } from 'react-redux';
import BaseReactSelectField from '../../../../../../components/Form/BaseReactSelectField';
import InfoTooltip from '../../../../../../components/InfoTooltip';
import { AgeRange } from '../../../../../../config/api/models/dataSets';
import { getAgeRangesRequest } from '../../api';
import TargetAudienceField from '../../components/sections/TargetAudienceField';
import { getAgeRanges } from '../../selectors';

type Props = {
  url: string;
};

const AgeRangeField: FC<Props> = ({ url }) => {
  const { t } = useTranslation();
  const [, fetchAgeRanges] = useFetch(getAgeRangesRequest);
  const ageRanges = useSelector(getAgeRanges);
  const nodeRef = React.useRef(null);
  const isVisible = useIsVisible(nodeRef);

  useEffect(() => {
    if (!ageRanges && isVisible) {
      fetchAgeRanges(url);
    }
  }, [isVisible, ageRanges, fetchAgeRanges, url]);

  if (!ageRanges) {
    return <div ref={nodeRef}>&nbsp;</div>;
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={6}>
        <TargetAudienceField
          component={BaseReactSelectField}
          name="ageRange.from"
          label={
            <>
              {t('title_ageRange_from')}
              <InfoTooltip title={t('title_ageRange_from_tooltip')} inline />
            </>
          }
          options={ageRanges}
          getOptionLabel={(ageRange: AgeRange) => ageRange.label}
          getOptionValue={(ageRange: AgeRange) => ageRange}
          isClearable
        />
      </Grid>
      <Grid item xs={6}>
        <TargetAudienceField
          component={BaseReactSelectField}
          name="ageRange.to"
          label={t('title_ageRange_to')}
          options={ageRanges.filter((age) => age.value !== 0)}
          getOptionLabel={(ageRange: AgeRange) => ageRange.label}
          getOptionValue={(ageRange: AgeRange) => ageRange}
          isClearable
        />
      </Grid>
    </Grid>
  );
};

export default React.memo(AgeRangeField);
