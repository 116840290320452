import { useCallback, useState } from 'react';

export const useStartStop = (initial = false) => {
  const [started, setStarted] = useState(initial);

  const stop = useCallback(() => {
    setStarted(false);
  }, []);

  const start = useCallback(() => {
    setStarted(true);
  }, []);

  return {
    started,
    start,
    stop,
  };
};
