import { Box, Tooltip, Typography, useTheme } from '@mui/material';
import { useFetch } from '@react-redux-fetch/hooks';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import DelayedLoader from '../../../../components/DelayedLoader';
import Table from '../../../../components/Table';
import { CellType, DataType } from '../../../../components/Table/types';
import TextLink from '../../../../components/TextLink';
import useApiRoute from '../../../../config/api/useApiRoute';
import ROUTE_KEY from '../../../../config/routes/routeKeys';
import usePath from '../../../../config/routes/usePath';
import formatEuro from '../../../../helpers/formatEuro';
import getIdFromUrl from '../../../../helpers/getIdFromUrl';
import { trimStringLength } from '../../../../helpers/trimStringLength';
import { getDetailLink, getPriceData } from '../../../title/domain';
import { getOrderReplacementsRequest } from '../../api';
import { getOrderReplacements } from '../../selectors';
import { OrderReplacementsForm } from './OrderReplacementsForm';

export const OrderReplacements = () => {
  const { t } = useTranslation();
  const orderDetailPath = usePath(ROUTE_KEY.ORDER_DETAIL);
  const titlePath = usePath(ROUTE_KEY.TITLE);
  const theme = useTheme();

  const orderReplacementsApiPath = useApiRoute('orderReplacements');
  const [orderReplacementsFetch, fetchOrderReplacements] = useFetch(getOrderReplacementsRequest);

  const orderReplacements = useSelector(getOrderReplacements);

  useEffect(() => {
    if (
      orderReplacementsApiPath &&
      !orderReplacementsFetch?.fulfilled &&
      !orderReplacementsFetch?.pending
    ) {
      fetchOrderReplacements(orderReplacements?._links.self.href || orderReplacementsApiPath);
    }
  }, [
    orderReplacementsApiPath,
    fetchOrderReplacements,
    orderReplacements?._links.self.href,
    orderReplacementsFetch,
  ]);

  const formatPrice = (price?: number, compareTo?: number) => {
    if (price === undefined) return undefined;

    return (
      <Typography
        component="span"
        sx={{
          color:
            compareTo === undefined || price === compareTo
              ? 'inherit'
              : price > compareTo
              ? theme.palette.error.main
              : theme.palette.success.main,
          fontWeight: 600,
        }}
      >
        {formatEuro(price)}
      </Typography>
    );
  };

  const headers = [
    { id: 'ordered_at', label: t('table_headers_ordered_at'), width: '6rem' },
    { id: 'order_ref', label: t('table_headers_order_reference') },
    { id: 'gtin13', label: t('table_headers_your_order') },
    { id: 'replaced_by', label: t('table_headers_replaced_by') },
    { id: 'title', label: t('table_headers_title') },
    { id: 'num_pieces', label: t('table_headers_num_pieces'), width: 80, align: 'center' },
  ];

  const data: DataType | null = orderReplacements
    ? {
        resource: 'orderReplacements',
        rows: orderReplacements._embedded.items.map((replacement) => {
          const orderId = getIdFromUrl(replacement._links.order.href);
          const orderDetailUrl = orderDetailPath.replace(':id', orderId);

          const originalTitle = replacement._embedded.originalTitle;
          const replacedTitle = replacement._embedded.replacedTitle;

          const originalTitleUrl = getDetailLink(titlePath, originalTitle);
          const replacedTitleUrl = getDetailLink(titlePath, replacedTitle);

          return {
            id: `${replacement.orderId}-${replacement.originalGtin13}-${replacement.replacedGtin13}-${replacement.replacedAt}`,
            // link: orderDetailUrl,
            // linkState: {
            //   from: window.location.pathname,
            // },
            cells: [
              {
                type: CellType.Text,
                data: moment(replacement.replacedAt).format('DD/MM/YYYY'),
              },
              {
                type: CellType.Text,
                data: replacement.orderReference ? (
                  <TextLink
                    underline
                    to={{
                      pathname: orderDetailUrl,
                      state: { from: window.location.pathname },
                    }}
                    title={replacement.orderReference}
                  >
                    {trimStringLength(replacement.orderReference, 45)}
                  </TextLink>
                ) : null,
              },

              {
                type: CellType.Text,
                data: (
                  <div>
                    <TextLink
                      underline
                      to={{
                        pathname: originalTitleUrl,
                        state: { from: window.location.pathname },
                      }}
                    >
                      {replacement.originalGtin13}
                    </TextLink>
                    <Typography noWrap>
                      {`${originalTitle.productForm.shortLabel} - `}
                      {originalTitle.price && (
                        <>
                          {formatPrice(
                            getPriceData(originalTitle)[0].price,
                            getPriceData(replacedTitle)[0].price
                          )}{' '}
                          -{' '}
                        </>
                      )}
                      {originalTitle.availability.label}
                    </Typography>
                  </div>
                ),
              },
              {
                type: CellType.Text,
                data: (
                  <div>
                    <TextLink
                      underline
                      to={{
                        pathname: replacedTitleUrl,
                        state: { from: window.location.pathname },
                      }}
                    >
                      {replacement.replacedGtin13}
                    </TextLink>
                    <Typography noWrap>
                      {`${replacedTitle.productForm.shortLabel} - `}
                      {replacedTitle.price && (
                        <>
                          {formatPrice(
                            getPriceData(replacedTitle)[0].price,
                            getPriceData(originalTitle)[0].price
                          )}{' '}
                          -{' '}
                        </>
                      )}
                      {replacedTitle.availability.label}
                    </Typography>
                  </div>
                ),
              },
              {
                type: CellType.Text,
                data: (
                  <Box sx={{ width: '230px' }}>
                    <Tooltip
                      placement="top-start"
                      title={replacement._embedded.replacedTitle.title}
                      enterDelay={500}
                    >
                      <Typography noWrap>{replacement._embedded.replacedTitle.title}</Typography>
                    </Tooltip>
                  </Box>
                ),
              },
              {
                type: CellType.Text,
                data: <Typography textAlign="center">{replacement.quantity}</Typography>,
              },
            ],
          };
        }),
      }
    : null;

  return (
    <DelayedLoader data={data}>
      {data != null && (
        <>
          <OrderReplacementsForm
            url={orderReplacements?._links.self.href || ''}
            fetchReplacements={fetchOrderReplacements}
          />
          <Table
            data={data}
            headers={headers}
            pagedResource={orderReplacements ?? undefined}
            loading={orderReplacementsFetch?.pending}
            dispatchFn={fetchOrderReplacements}
          />
        </>
      )}
    </DelayedLoader>
  );
};
