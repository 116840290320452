import useFetch from './useFetch';
import memoizeOne from 'memoize-one';
import apiRoutes from '../config/api/routes';

type Method = 'GET' | 'PUT' | 'POST' | 'DELETE' | 'PATCH' | 'OPTIONS';

const config = memoizeOne((url: string) => ({
  resource: `access_${url}`,
  method: 'OPTIONS',
  request: () => ({
    url: apiRoutes.get(url),
    clearValueOnRequest: true,
  }),
}));

const useAccessControlAllowMethods = (url?: string | false, method: Method = 'GET') => {
  const [canAccess, fetchCanAccess] = useFetch(config(url || ''));

  const allowedMethods =
    (canAccess &&
      canAccess.meta &&
      canAccess.meta.response &&
      canAccess.meta.response.headers &&
      canAccess.meta.response.headers['access-control-allow-methods']) ||
    [];

  if (!url) {
    return [false, () => {}, false];
  }

  return [allowedMethods.includes(method), fetchCanAccess, canAccess?.pending];
};

export default useAccessControlAllowMethods;
