import { Theme } from '@mui/material';
import Typography, { TypographyProps } from '@mui/material/Typography';
import { WithStyles, createStyles, withStyles } from '@mui/styles';
import clx from 'classnames';
import React, { FC } from 'react';

type Props = WithStyles<typeof subTitleStyles> & TypographyProps;

export const subTitleStyles = (theme: Theme) =>
  createStyles({
    title: {
      color: theme.palette.text.subtitle,
      fontSize: 18,
    },
  });

const SubTitle: FC<Props> = ({ children, classes, className, ...props }) => {
  return (
    <Typography variant="h2" gutterBottom className={clx(className, classes.title)} {...props}>
      {children}
    </Typography>
  );
};

export default withStyles(subTitleStyles)(SubTitle);
