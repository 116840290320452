import { Badge } from '@mui/material';
import { snakeCase } from 'lodash';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { NestedClientRoute } from '../../../config/routes/types';
import { getNotificationsCounters } from '../../notifications';

// const DELAY = parseInt(process.env.REACT_APP_NOTIFICATION_INTERVAL ?? '3000');

type Props = {
  route: NestedClientRoute;
};
export const OrdersNavLabel: FC<Props> = ({ route }) => {
  const { t } = useTranslation();

  const counters = useSelector(getNotificationsCounters);

  return counters?.openOrders ? (
    <Badge badgeContent={counters?.openOrders} color="primary" style={{ paddingRight: 8 }}>
      {t(`nav_${snakeCase(route.key)}`)}
    </Badge>
  ) : (
    <>{t(`nav_${snakeCase(route.key)}`)}</>
  );
};
