import ImageIcon from '@mui/icons-material/Image';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';
import isImage from '../helpers/isImage';

type Props = {
  fileName: string;
  fontSize?: SvgIconProps['fontSize'];
};

const FileIcon = ({ fileName, fontSize = 'small' }: Props) =>
  isImage(fileName) ? (
    <ImageIcon fontSize={fontSize} />
  ) : (
    <InsertDriveFileIcon fontSize={fontSize} />
  );

export default FileIcon;
