import React from 'react';
import TextLink from '../../../components/TextLink';
import { ElasticOrderLine } from '../../../config/api/models/shop';
import ROUTE_KEY from '../../../config/routes/routeKeys';
import usePath from '../../../config/routes/usePath';
import slugify from '../../../helpers/slugify';

type Props = {
  orderLine: ElasticOrderLine & { titleId: string };
};

const ElasticIsbnLink = ({ orderLine }: Props) => {
  const detailPath = usePath(ROUTE_KEY.TITLE);

  return (
    <TextLink
      underline
      to={{
        pathname: detailPath
          .replace(':slug', slugify(orderLine.titleTitle))
          .replace(':id', orderLine.titleId),
        state: { from: window.location.pathname },
      }}
      onClick={(e) => e.stopPropagation()}
    >
      {orderLine.gtin13}
    </TextLink>
  );
};

export default ElasticIsbnLink;
