import { useTranslation } from 'react-i18next';
import { UserRole } from '../../../config/api/types';
import { Category } from '../types';

type FAQ = {
  q: string;
  a: string;
  show: boolean;
  category: Category;
};

export const useFaq = (userRoles: UserRole[], isMember: boolean): FAQ[] => {
  const { t } = useTranslation();

  const isPublisher = userRoles.includes('ROLE_PUBLISHER');
  const isDistributor = userRoles.includes('ROLE_DISTRIBUTOR');
  const isLibrary = userRoles.includes('ROLE_LIBRARY');
  const isBookstore = userRoles.includes('ROLE_BOOKSTORE');
  const isDataProducer = userRoles.includes('ROLE_DATA_PRODUCER');
  const isAdmin = userRoles.includes('ROLE_ADMIN');

  return [
    {
      q: t('faq_q1'),
      a: t('faq_a1'),
      show: true,
      category: Category.General,
    },
    {
      q: t('faq_q2'),
      a: t('faq_a2'),
      show: true,
      category: Category.General,
    },
    {
      q: t('faq_q3'),
      a: t('faq_a3'),
      show: true,
      category: Category.General,
    },
    {
      q: t('faq_q4'),
      a: t('faq_a4'),
      show: true,
      category: Category.General,
    },
    {
      q: t('faq_q5'),
      a: t('faq_a5'),
      show: true,
      category: Category.General,
    },
    {
      q: t('faq_q6'),
      a: t('faq_a6'),
      show: true,
      category: Category.General,
    },
    {
      q: t('faq_q7'),
      a: t('faq_a7'),
      show: isPublisher || isAdmin,
      category: Category.Isbn,
    },
    {
      q: t('faq_q8'),
      a: t('faq_a8'),
      show: isPublisher || isAdmin,
      category: Category.Isbn,
    },
    {
      q: t('faq_q9'),
      a: t('faq_a9'),
      show: isPublisher || isDistributor || isAdmin,
      category: Category.Isbn,
    },
    {
      q: t('faq_q10'),
      a: t('faq_a10'),
      show: isPublisher || isDistributor || isAdmin,
      category: Category.Isbn,
    },
    {
      q: t('faq_q11'),
      a: t('faq_a11'),
      show: isPublisher || isDistributor || isAdmin,
      category: Category.Isbn,
    },
    {
      q: t('faq_q12'),
      a: t('faq_a12'),
      show: isPublisher || isDistributor || isAdmin,
      category: Category.Isbn,
    },
    {
      q: t('faq_q13'),
      a: t('faq_a13'),
      show: isPublisher || isDistributor || isAdmin,
      category: Category.Isbn,
    },
    {
      q: t('faq_q14'),
      a: t('faq_a14'),
      show: isPublisher || isDistributor || isAdmin,
      category: Category.Titles,
    },
    {
      q: t('faq_q15'),
      a: t('faq_a15'),
      show: isPublisher || isDistributor || isAdmin,
      category: Category.Titles,
    },
    {
      q: t('faq_q16'),
      a: t('faq_a16'),
      show: isPublisher || isDistributor || isAdmin,
      category: Category.Titles,
    },
    {
      q: t('faq_q17'),
      a: t('faq_a17'),
      show: isPublisher || isAdmin,
      category: Category.Titles,
    },
    {
      q: t('faq_q18'),
      a: t('faq_a18'),
      show: isPublisher || isDistributor || isAdmin,
      category: Category.Gbp,
    },
    {
      q: t('faq_q19'),
      a: t('faq_a19'),
      show: (isPublisher && isDistributor) || isAdmin,
      category: Category.Gbp,
    },
    {
      q: t('faq_q20'),
      a: t('faq_a20'),
      show: isPublisher || isAdmin,
      category: Category.Isbn,
    },
    {
      q: t('faq_q21'),
      a: t('faq_a21'),
      show: (isDistributor && isMember) || isAdmin,
      category: Category.Orders,
    },
    {
      q: t('faq_q22'),
      a: t('faq_a22'),
      show: isBookstore || isAdmin,
      category: Category.Orders,
    },
    {
      q: t('faq_q23'),
      a: t('faq_a23'),
      show: isBookstore || isAdmin,
      category: Category.Orders,
    },
    {
      q: t('faq_q24'),
      a: t('faq_a24'),
      show: isBookstore || isAdmin,
      category: Category.Orders,
    },
    {
      q: t('faq_q25'),
      a: t('faq_a25'),
      show: isBookstore || isLibrary || isAdmin,
      category: Category.General,
    },
    {
      q: t('faq_q26'),
      a: t('faq_a26'),
      show: ((isPublisher || isDistributor) && isMember) || isAdmin,
      category: Category.Orders,
    },
    {
      q: t('faq_q27'),
      a: t('faq_a27'),
      show: (isDistributor && isMember) || isAdmin,
      category: Category.Orders,
    },
    {
      q: t('faq_q28'),
      a: t('faq_a28'),
      show: (isDataProducer && isDistributor && isMember) || isAdmin,
      category: Category.Titles,
    },
    {
      q: t('faq_q29'),
      a: t('faq_a29'),
      show: isLibrary || isAdmin,
      category: Category.Orders,
    },
    {
      q: t('faq_q30'),
      a: t('faq_a30'),
      show: isBookstore || isAdmin,
      category: Category.Orders,
    },
    {
      q: t('faq_q31'),
      a: t('faq_a31'),
      show: isBookstore || isAdmin,
      category: Category.Orders,
    },
    {
      q: t('faq_q32'),
      a: t('faq_a32'),
      show: isBookstore || isAdmin,
      category: Category.Orders,
    },
    {
      q: t('faq_q33'),
      a: t('faq_a33'),
      show: isBookstore || isAdmin,
      category: Category.Orders,
    },
    {
      q: t('faq_q34'),
      a: t('faq_a34'),
      show: isBookstore || isAdmin,
      category: Category.Orders,
    },
    {
      q: t('faq_q35'),
      a: t('faq_a35'),
      show: isBookstore || isAdmin,
      category: Category.Orders,
    },
    {
      q: t('faq_q36'),
      a: t('faq_a36'),
      show: isBookstore || isAdmin,
      category: Category.Orders,
    },
    {
      q: t('faq_q37'),
      a: t('faq_a37'),
      show: isBookstore || isAdmin,
      category: Category.Orders,
    },
  ].filter(({ show }) => show);
};
