import React from 'react';
import { useTranslation } from 'react-i18next';
import OrdersHistoryProcessed from '../components/ordersHistoryProcessed/OrdersHistoryProcessed';
import ConstrainedPageContent from '../../../components/ConstrainedPageContent';
import OrdersTabHeader from '../components/OrdersTabHeader';
import { RouteComponentProps } from 'react-router';
import usePath from '../../../config/routes/usePath';
import ROUTE_KEY from '../../../config/routes/routeKeys';

type Props = RouteComponentProps;

export const OrdersProcessedHeader = (props: Props) => {
  const { t } = useTranslation();
  const orderLinesPath = usePath(ROUTE_KEY.ORDERS_HISTORY_PROCESSED);
  const ordersPath = usePath(ROUTE_KEY.ORDERS_HISTORY_PROCESSED_ORDERS);

  return (
    <OrdersTabHeader
      {...props}
      tabs={[
        { route: orderLinesPath, label: t('page_tab_orderlines') },
        { route: ordersPath, label: t('page_tab_orders') },
      ]}
      title={t('page_title_order_overview_processed')}
    />
  );
};

const OrdersProcessedPage = (props: Props) => {
  return (
    <ConstrainedPageContent center={false}>
      <OrdersProcessedHeader {...props} />
      <OrdersHistoryProcessed />
    </ConstrainedPageContent>
  );
};

export default OrdersProcessedPage;
