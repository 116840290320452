import { CircularProgress } from '@mui/material';
import moment from 'moment';
import React, { FC, ReactNode, useEffect, useMemo } from 'react';
import { Paged } from '../../config/api/types';
import { getLink } from '../../helpers/hateoas';
import useInterval from '../../helpers/useInterval';
import { Overlay, OverlayRoot } from '../Overlay';

type Props = {
  pagedResponse?: Paged<{
    status: string;
    uploadedAt: string;
    numSuccess?: number;
    numTotal?: number;
    processedTotal?: number;
  }>;
  query: (url: string) => void;
  pollForChanges?: boolean;
  onStopPolling: () => void;
  pollingInterval?: number;
  withoutLoader?: boolean;
  children?: ReactNode;
  mediaType: 'files' | 'media';
};

export const PollForChanges: FC<Props> = ({
  query,
  pagedResponse,
  children,
  pollForChanges,
  onStopPolling,
  pollingInterval = 2000,
  withoutLoader,
  mediaType,
}) => {
  const url = getLink(pagedResponse, 'self') || '';
  useInterval(() => query(url), pollForChanges ? pollingInterval : null);

  const fortyFiveMinutesAgo = moment().subtract(45, 'minutes');

  const numItems = pagedResponse && pagedResponse.total;
  const hasPendingMediaFiles = useMemo(
    () =>
      pagedResponse
        ? Boolean(
            pagedResponse._embedded.items.some(
              ({ processedTotal, numTotal, uploadedAt, numSuccess, status }) => {
                if (mediaType === 'media') {
                  //Titelbewerkpagina & mediabestanden pagina
                  if (
                    (['uploaded', 'processing'].includes(status) ||
                      (status === 'process' && numSuccess === numTotal)) &&
                    moment(uploadedAt).isAfter(fortyFiveMinutesAgo)
                  ) {
                    return true;
                  }
                }

                if (mediaType === 'files') {
                  //Titelbestanden pagina
                  if (processedTotal == null || numTotal == null) return true; //initial response
                  if (
                    processedTotal < numTotal &&
                    moment(uploadedAt).isAfter(fortyFiveMinutesAgo)
                  ) {
                    return true; //pending
                  }
                }

                return false; // processing finished
              }
            )
          )
        : false,
    [fortyFiveMinutesAgo, mediaType, pagedResponse]
  );

  // const hasPendingMediaFiles = pagedResponse
  //   ? Boolean(
  //       pagedResponse._embedded.items.find(
  //         ({ status, uploadedAt, numSuccess, numTotal }) =>
  //           (['uploaded', 'processing'].includes(status) ||
  //             (status === 'process' && numSuccess === numTotal)) &&
  //           moment(uploadedAt).isAfter(fortyFiveMinutesAgo)
  //       )
  //     )
  //   : false;

  useEffect(() => {
    if (!hasPendingMediaFiles) {
      onStopPolling();
    }
  }, [numItems, hasPendingMediaFiles, onStopPolling]);

  return (
    <OverlayRoot>
      {children}
      {!withoutLoader && pollForChanges && (
        <Overlay>
          <CircularProgress />
        </Overlay>
      )}
    </OverlayRoot>
  );
};
