import { Button, ButtonProps } from '@mui/material';
import { useFetch } from '@react-redux-fetch/hooks';
import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import useApiRoute from '../../../../config/api/useApiRoute';
import getIdFromUrl from '../../../../helpers/getIdFromUrl';
import { getUserOrganisationUrl } from '../../../security/selectors';
import { createRetourRequest } from './api';

type Props = Omit<ButtonProps, 'onClick'> & {
  onClick: () => void;
  retourId?: string;
};

export const CreateUnconfirmedRetourButton: FC<Props> = ({ children, retourId, onClick }) => {
  const url = useApiRoute('retours') || '';
  const organisationUrl = useSelector(getUserOrganisationUrl);
  const organisationId = getIdFromUrl(organisationUrl);
  const [createRetourFetch, createRetour] = useFetch(createRetourRequest);

  useEffect(() => {
    if (createRetourFetch?.fulfilled) {
      onClick();
    }
  }, [createRetourFetch?.fulfilled, onClick]);

  const handleClick = () => {
    createRetour(url, { organisationId });
  };

  return (
    <Button onClick={retourId ? onClick : handleClick} disabled={createRetourFetch?.pending}>
      {children}
    </Button>
  );
};
