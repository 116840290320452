import HistoryIcon from '@mui/icons-material/History';
import { useFetch } from '@react-redux-fetch/hooks';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Button from '../../../../../components/Button';
import { Title } from '../../../../../config/api/types';
import { getTitleOrderHistoryRequest } from '../../api';
import { getTitleOrderHistory } from '../../selectors';
import OrderHistoryDialog from './OrderHistoryDialog';

type Props = { title: Title };

const OrderHistoryButton: FC<Props> = ({ title }) => {
  const { t } = useTranslation();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [, fetchTitleOrderHistory] = useFetch(getTitleOrderHistoryRequest);
  const url = title._links.orderHistory?.href || '';
  const titleOrderHistory = useSelector(getTitleOrderHistory(url));

  useEffect(() => {
    if (url) {
      fetchTitleOrderHistory(url);
    }
  }, [fetchTitleOrderHistory, url]);

  if (!url || !titleOrderHistory || !titleOrderHistory.length) {
    return null;
  }

  return (
    <>
      <Button variant="text" onClick={() => setDialogOpen(true)}>
        <HistoryIcon fontSize="small" style={{ marginRight: 4 }} />
        {t('title_order_history')}
      </Button>
      {dialogOpen && (
        <OrderHistoryDialog
          onClose={() => setDialogOpen(false)}
          titleOrderHistory={titleOrderHistory}
        />
      )}
    </>
  );
};

export default OrderHistoryButton;
