import AccessTime from '@mui/icons-material/AccessTime';
import Check from '@mui/icons-material/Check';
import Close from '@mui/icons-material/Close';
import { Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import cx from 'classnames';
import React from 'react';
import { Availability } from '../../../../config/api/types';

const useStyles = makeStyles((theme: Theme) => ({
  titleStatus: {
    position: 'absolute',
    right: 0,
    top: '-1em',
    lineHeight: 2,
    marginRight: theme.spacing(2),
    padding: `0 ${theme.spacing(2)}`,
    textTransform: 'uppercase',
    fontWeight: theme.typography.fontWeightMedium,
    '& > svg': {
      verticalAlign: 'middle',
      marginRight: theme.spacing(1),
    },
  },
  level100: {
    backgroundColor: theme.palette.status.available,
    color: theme.palette.status.availableText,
  },
  level200: {
    backgroundColor: theme.palette.status.expected,
    color: theme.palette.status.expectedText,
  },
  level300: {
    backgroundColor: theme.palette.status.notAvailable,
    color: theme.palette.status.notAvailableText,
  },
}));

type Props = {
  availability: Availability;
  className?: string;
  showLabel?: boolean;
};

const TitleStatus = ({ availability, className, showLabel = true }: Props) => {
  const classes = useStyles();

  const classNames = cx(classes.titleStatus, className, {
    [classes.level100]: availability.level === 100,
    [classes.level200]: availability.level === 200,
    [classes.level300]: availability.level === 300,
  });

  return (
    <Typography className={classNames}>
      {availability.level === 100 && <Check fontSize="small" />}
      {availability.level === 200 && <AccessTime fontSize="small" />}
      {availability.level === 300 && <Close fontSize="small" />}
      {showLabel && availability.label}
    </Typography>
  );
};

export default TitleStatus;
