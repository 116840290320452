import React, { FC, useEffect } from 'react';
import { useFetch } from '@react-redux-fetch/hooks';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getAllBookstores } from '../../admin/selectors';
import BaseReactSelectField from '../../../../components/Form/BaseReactSelectField';
import { getBookstoresRequest } from '../api';
import { updateQueryParameters } from '../../../../helpers/hateoas';
import { TextField } from '../../../../components/Form';
import { Bookstore } from '../../../../config/api/models/organisation';

type Props = {
  urlParams?: Record<string, string | number>;
  url?: string;
  disabled?: boolean;
  bookstoreId?: string;
  change?: (name: string, value: any) => void;
  label: string;
  placeholder?: string;
  onClearValue?: () => void;
};

export const BookstoresSelectField: FC<Props> = ({
  urlParams,
  disabled,
  bookstoreId,
  change,
  label,
  url: propUrl,
  placeholder,
  onClearValue,
}) => {
  const baseUrl = '/order/bookstores';
  const url = propUrl || (urlParams ? updateQueryParameters(baseUrl, urlParams) : baseUrl);
  const { t } = useTranslation();
  const [, fetchBookstores] = useFetch(getBookstoresRequest);
  const bookstores = useSelector(getAllBookstores);

  useEffect(() => {
    fetchBookstores(url);
  }, [fetchBookstores, url]);

  useEffect(() => {
    if (!bookstores || !change || !bookstoreId) return;

    const bookstore = Object.values(bookstores).find((bookstore) => {
      return bookstore.bookstoreId === bookstoreId;
    });

    change('bookstore', bookstore || null);
  }, [change, bookstores, bookstoreId]);

  const bookstoreList = Object.values(bookstores || {});

  if (disabled) {
    return <TextField disabled label={t('form_bookstore')} name="bookstoreTxt" />;
  }

  return (
    <BaseReactSelectField
      label={label || t('form_bookstore')}
      name="bookstore"
      options={bookstoreList || []}
      getOptionValue={(option: Bookstore) => option.bookstoreId}
      getOptionLabel={(option: Bookstore) => option._embedded.organisation.name}
      disabled={disabled}
      blurInputOnSelect
      isClearable
      placeholder={placeholder || t('form_filter_bookstore_placeholder')}
      onClearValue={onClearValue}
    />
  );
};
