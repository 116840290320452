import { reducer as fetchReducer } from 'react-redux-fetch';
import { repositoryReducer, requestReducer } from '@react-redux-fetch/core';
import { AnyAction, combineReducers } from 'redux';
import { RootState } from '../../config/store/types';
import security from '../security';
import alert from '../core/alerts';

const combinedReducers = combineReducers<RootState>({
  repository: fetchReducer,
  data: repositoryReducer,
  requests: requestReducer,
  security: security.reducer,
  alert: alert.reducer,
});

const rootReducer = (state: RootState | undefined, action: AnyAction) => {
  if (action.type === security.actionTypes.LOGGED_OUT) {
    return combinedReducers(undefined, action);
  }
  return combinedReducers(state, action);
};

export default rootReducer;
