import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  STATUS_FAILED_CLIENT,
  STATUS_FAILED_SERVER,
  STATUS_PROCESSED,
  STATUS_PROCESSED_WARNING,
  STATUS_PROCESSING,
  STATUS_UPLOADED,
  StatusType,
} from '../../../../../config/api/types';
import StatusLabel, { StatusLabelStatus } from '../../../../../components/StatusLabel';

type Props = {
  status: StatusType;
};

const StatusCell = ({ status }: Props) => {
  const { t } = useTranslation();

  const getCategoryAndText = (): { category: StatusLabelStatus; text: string } => {
    switch (status) {
      case STATUS_PROCESSED:
        return { category: 'success', text: t('processed') };
      case STATUS_FAILED_CLIENT:
      case STATUS_FAILED_SERVER:
        return { category: 'fail', text: t('processing_error') };
      case STATUS_PROCESSED_WARNING:
        return { category: 'warning', text: t('processed_warning') };
      case STATUS_UPLOADED:
        return { category: 'default', text: t('pending') };
      case STATUS_PROCESSING:
        return { category: 'default', text: t('processing') };
      default:
        return { category: 'default', text: t('processing') };
    }
  };

  const { category, text } = getCategoryAndText();

  return <StatusLabel status={category}>{text}</StatusLabel>;
};

export default StatusCell;
