import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material';
import parseHtml from 'html-react-parser';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import ConstrainedPageContent from '../../../components/ConstrainedPageContent';
import PageTitle from '../../../components/PageTitle';
import security from '../../security';
import { useFaq } from '../hooks/useFaq';
import { Category } from '../types';

export const FAQPage = () => {
  const { t } = useTranslation();
  const user = useSelector(security.selectors.getUser);
  const userRoles = useSelector(security.selectors.getUserRole) || [];

  const isMember = user?._embedded?.organisation?.hasMembership ?? false;

  const faq = useFaq(userRoles, isMember);
  const faqByCategory = Object.keys(Category)
    .map((key) => ({
      [key]: faq.filter((item) => item.category === key),
    }))
    .filter((cat) => Object.values(cat)[0].length > 0);

  return (
    <ConstrainedPageContent center={false}>
      <PageTitle>{t('nav_help_faq')}</PageTitle>
      <Box mb={4} mt={4}>
        {faqByCategory.map((cat) => {
          return (
            <Box key={`faq-${Object.keys(cat)[0]}`} mb={3}>
              <Box mb={1}>
                <Typography variant="h3">
                  <strong>{t(`faq_cat_${Object.keys(cat)[0].toLowerCase()}`)}</strong>
                </Typography>
              </Box>
              {Object.values(cat)[0].map(({ q, a }, index) => (
                <Accordion key={`faq-${Object.keys(cat)[0]}-${index}`}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>
                      <b>{q}</b>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>{parseHtml(a)}</Typography>
                  </AccordionDetails>
                </Accordion>
              ))}
            </Box>
          );
        })}
      </Box>
    </ConstrainedPageContent>
  );
};
