import React from 'react';
import { RouteComponentProps } from 'react-router';
import OrganisationWrapper from '../containers/OrganisationWrapper';
import Publisher from '../containers/publisher/Publisher';

type Params = { id: string };
type Props = RouteComponentProps<Params> & {};

const PublisherTab = (props: Props) => {
  return (
    <OrganisationWrapper {...props}>
      {({ organisation }) => <Publisher organisation={organisation} />}
    </OrganisationWrapper>
  );
};

export default PublisherTab;
