import React from 'react';
import { Field } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';

type Props = {
  field: string;
  set: string;
  to: (value: any, fieldValue: any) => any;
};

const WhenFieldChanges = ({ field, set, to }: Props) => (
  <Field name={set} subscription={{}}>
    {(
      // No subscription. We only use Field to get to the change function
      { input: { onChange, value: fieldValue } }
    ) => (
      <OnChange name={field}>
        {(value) => {
          onChange(to(value, fieldValue));
        }}
      </OnChange>
    )}
  </Field>
);

export default WhenFieldChanges;
