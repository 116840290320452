export const canBackorder = (reasonCode: number) => {
  return [2, 3, 8].includes(reasonCode);
};

export const canReturn = (reasonCode: number) => {
  return [1, 2, 3, 4, 5, 6, 7].includes(reasonCode);
};

export const canReturnDefaultYes = (reasonCode: number) => {
  return [1, 5, 7].includes(reasonCode);
};
export const canReturnDefaultNo = (reasonCode: number) => {
  return [2, 3, 6].includes(reasonCode);
};

export const requiresInvoiceNr = (reasonCode: number) => {
  return [2, 4, 5, 8].includes(reasonCode);
};
