import { FormControlLabel, Grid, Radio, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useFetch } from '@react-redux-fetch/hooks';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Button from '../../../../components/Button';
import DelayedLoader from '../../../../components/DelayedLoader';
import { BaseTextField } from '../../../../components/Form/TextField';
import SubTitle from '../../../../components/SubTitle';
import { IsbnApplication, Organisation, PrefixApplication } from '../../../../config/api/types';
import useApiRoute from '../../../../config/api/useApiRoute';
import getIdFromUrl from '../../../../helpers/getIdFromUrl';
import { getLink, updateQueryParameter } from '../../../../helpers/hateoas';
import useDebounce from '../../../../helpers/useDebounce';
import { getOrganisationsSearchRequest } from '../../../organisation/api';
import { getOrganisations } from '../../../organisation/selectors';
import { getPrefixesRequest } from '../../api';
import { getPrefixes } from '../../selectors';
import OrganisationMenuItem from './OrganisationMenuItem';

type Props = {
  application: IsbnApplication | PrefixApplication;
  onCancel: () => void;
  onSelect: (id: string, removePrefix?: boolean) => void;
};

type SearchOptions = 'PREFIX' | 'EMAIL' | 'NAME' | string;
const DEBOUNCE_TIMEOUT = 400;

const useStyles = makeStyles((theme: Theme) => ({
  filters: {
    borderRight: `2px solid ${theme.palette.background.appbarHover}`,
  },
  orgList: {
    height: 400,
    overflowY: 'auto',
  },
  actionButtons: {
    marginTop: 5,
  },
}));

const MatchOrganisations = ({ onCancel, onSelect, application }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [selectedOrg, setSelectedOrg] = useState<Organisation | null>(null);
  const [search, setSearch] = useState<SearchOptions>(application.prefix ? 'PREFIX' : 'EMAIL');
  const [fetchOrganisationsRequest, fetchOrganisations] = useFetch(getOrganisationsSearchRequest);
  const [fetchPrefixRequest, fetchPrefixes] = useFetch(getPrefixesRequest);
  const [debounceSearch] = useDebounce(search, DEBOUNCE_TIMEOUT);
  const orgUrl = useApiRoute('organisations');
  const prefixUrl = useApiRoute('prefixes');
  const organisations = useSelector(getOrganisations);
  const prefixes = useSelector(getPrefixes);

  const loading =
    search === 'PREFIX'
      ? fetchPrefixRequest?.pending && !prefixes
      : !fetchOrganisationsRequest || fetchOrganisationsRequest?.pending;
  const handleTextfieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value;
    setSearch(value);
  };

  const handleSave = () => {
    if (selectedOrg) {
      const orgId = getIdFromUrl(getLink(selectedOrg, 'self') || '');
      if (!application.prefix) {
        return onSelect(orgId, false);
      }

      const prefix = prefixes?._embedded.items.find((prefix) =>
        prefix.prefix.includes(application.prefix!)
      );
      const removePrefix = Boolean(
        prefix && getLink(prefix._embedded.organisation, 'self') !== getLink(selectedOrg, 'self')
      );
      return onSelect(orgId, removePrefix);
    }
  };

  const matchedOrganisations: Organisation[] =
    (search === 'PREFIX'
      ? prefixes?._embedded.items.map((prefix) => prefix._embedded.organisation)
      : organisations?._embedded.items) || [];

  useEffect(() => {
    if (prefixUrl && application.prefix) {
      let url = updateQueryParameter(prefixUrl || '', 'q', application.prefix);
      fetchPrefixes(url);
    }
  }, [application.prefix, prefixUrl, fetchPrefixes]);

  useEffect(() => {
    if (orgUrl) {
      let url = updateQueryParameter(orgUrl, 'roles', 'ROLE_PUBLISHER:true');
      switch (debounceSearch) {
        case 'PREFIX':
          return;
        case 'EMAIL':
          url = updateQueryParameter(url, 'email', application.organisation.email);
          break;
        case 'NAME':
          url = updateQueryParameter(url, 'name', application.organisation.name);
          break;
        default:
          url = updateQueryParameter(url, 'q', debounceSearch);
          break;
      }
      fetchOrganisations(url);
    }
  }, [orgUrl, fetchOrganisations, application.organisation, debounceSearch]);

  return (
    <div>
      <Grid container spacing={1}>
        <Grid item xs={3} className={classes.filters} container direction="column">
          <Grid item>
            <SubTitle>{t('search_by')}</SubTitle>
          </Grid>
          {application.prefix && (
            <Grid item>
              <FormControlLabel
                control={<Radio size="small" color="primary" />}
                label={t('form_prefix')}
                checked={search === 'PREFIX'}
                onChange={() => setSearch('PREFIX')}
              />
            </Grid>
          )}
          <Grid item>
            <FormControlLabel
              control={<Radio size="small" color="primary" />}
              label={t('form_email')}
              checked={search === 'EMAIL'}
              onChange={() => setSearch('EMAIL')}
            />
          </Grid>
          <Grid item>
            <FormControlLabel
              control={<Radio size="small" color="primary" />}
              label={t('form_name')}
              checked={search === 'NAME'}
              onChange={() => setSearch('NAME')}
            />
          </Grid>
          <Grid item>
            <BaseTextField placeholder="" onChange={handleTextfieldChange} />
          </Grid>
        </Grid>

        <Grid item xs={9} className={classes.orgList}>
          <DelayedLoader data={!loading}>
            {matchedOrganisations.length
              ? matchedOrganisations.map((org) => (
                  <OrganisationMenuItem
                    key={org.name}
                    organisation={org}
                    onSelect={setSelectedOrg}
                    selected={org._links.self === selectedOrg?._links.self}
                  />
                ))
              : t('search_no_options')}
          </DelayedLoader>
        </Grid>
      </Grid>

      <Grid
        container
        sx={{ justifyContent: 'flex-end' }}
        spacing={1}
        className={classes.actionButtons}
      >
        <Grid item>
          <Button onClick={handleSave} disabled={!selectedOrg}>
            {t('form_save')}
          </Button>
        </Grid>
        <Grid item>
          <Button onClick={onCancel}>{t('form_cancel')}</Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default MatchOrganisations;
