import { Box, Grid, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import BackButton from '../../../../../components/BackButton';
import PageTitle from '../../../../../components/PageTitle';
import { TitleFull } from '../../../../../config/api/types';
import CopyToClipboardButton from '../../../../app/components/CopyToClipboardButton';
import { getFrontCover } from '../../../domain';

const useStyles = makeStyles(
  (theme: Theme) => ({
    copyToClipboardButton: {
      marginLeft: theme.spacing(0.5),
    },
  }),
  { name: 'TitleFormHeader' }
);

type Props = {
  title: TitleFull;
  goBackPath: string;
  goBackLabel: string;
};

export const TitleFormHeader: FC<Props> = ({ title, goBackLabel, goBackPath }) => {
  const frontCover = getFrontCover(title);
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <Box mb={2}>
        <BackButton
          link={{ pathname: goBackPath, state: { keepFilters: true } }}
          style={{ width: '100%' }}
        >
          {goBackLabel}
        </BackButton>
      </Box>
      <Grid container spacing={1}>
        {frontCover && (
          <Grid item style={{ width: 52 }}>
            <img
              src={frontCover.thumbnail || frontCover.thumbnailSmall}
              alt={`Cover ${title.title}`}
              style={{ width: '100%' }}
              // className={classes?.cover}
            />
          </Grid>
        )}
        <Grid item style={{ width: 'calc(100% - 52px)' }}>
          <Box display="flex" alignItems="center">
            <PageTitle gutterBottom={false}>{title.gtin13}</PageTitle>
            {title.gtin13 && (
              <CopyToClipboardButton
                textToCopy={title.gtin13}
                tooltipText={t('copy_isbn')}
                className={classes.copyToClipboardButton}
              />
            )}
          </Box>
          <Typography noWrap>{title.title}</Typography>
        </Grid>
      </Grid>
    </>
  );
};
