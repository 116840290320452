import {
  Bookstore,
  NormalizedBookstores,
  NormalizedPagedBookstores,
} from '../../../config/api/models/organisation';
import { Paged } from '../../../config/api/types';

export const normalizeBookstoresRequest = (
  data: Bookstore[]
): { bookstores: NormalizedBookstores } => {
  return {
    bookstores: data.reduce((prev, bookstore) => {
      return {
        ...prev,
        [bookstore._links.self.href]: bookstore,
      };
    }, {}),
  };
};

export const normalizePagedBookstoresRequest = (
  data: Paged<Bookstore>
): { bookstores: NormalizedBookstores; pagedBookstores: NormalizedPagedBookstores } => {
  return {
    ...normalizeBookstoresRequest(data._embedded.items),
    pagedBookstores: {
      ...data,
      _embedded: {
        items: data._embedded.items.map((bookstore) => bookstore._links.self.href),
      },
    },
  };
};
