import { useFetch } from '@react-redux-fetch/hooks';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { CheckBox } from '../../../../../../components/Form';
import InfoTooltip from '../../../../../../components/InfoTooltip';
import { getLink } from '../../../../../../helpers/hateoas';
import { getPublisher } from '../../../../../organisation/admin/selectors';
import { getUserOrganisation, getUserRole } from '../../../../../security/selectors';
import { getPublisherRequest } from '../../api';
import BibliographicField from '../../components/sections/BibliographicField';

const GprcCheckbox = () => {
  const { t } = useTranslation();
  const [, fetchPublisher] = useFetch(getPublisherRequest);
  const organisation = useSelector(getUserOrganisation);
  const publisherLink = getLink(organisation, 'publisher') || '';

  useEffect(() => {
    if (publisherLink) {
      fetchPublisher(publisherLink);
    }
  }, [publisherLink, fetchPublisher]);

  const publisher = useSelector(getPublisher);
  const userRoles = useSelector(getUserRole);
  const isAdmin = userRoles && userRoles.includes('ROLE_ADMIN');

  const hasGprcPermission = publisher?.canChangeGprc || isAdmin;

  return (
    <BibliographicField
      component={CheckBox}
      name="isGprc"
      disabled={!hasGprcPermission}
      label={
        <>
          {t('title_gprc')} <InfoTooltip title={t('title_gprc_tooltip')} inline />
        </>
      }
    />
  );
};

export default GprcCheckbox;
