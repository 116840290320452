import React, { useEffect } from 'react';
import IsbnApplicationTable from '../components/IsbnApplicationTable';
import { useSelector } from 'react-redux';
import { getIsbnApplications } from '../../selectors';
import { useFetch } from '@react-redux-fetch/hooks';
import { getIsbnApplicationsRequest } from '../../api';
import useApiRoute from '../../../../config/api/useApiRoute';
import DelayedLoader from '../../../../components/DelayedLoader';

const IsbnApplicationOverviewWrapper = () => {
  const [, fetchIsbnApplications] = useFetch(getIsbnApplicationsRequest);
  const isbnApplicationApiLink = useApiRoute('isbnApplication') || '';

  const isbnApplications = useSelector(getIsbnApplications);

  useEffect(() => {
    if (isbnApplicationApiLink) {
      fetchIsbnApplications(isbnApplicationApiLink);
    }
  }, [fetchIsbnApplications, isbnApplicationApiLink]);

  return (
    <DelayedLoader data={isbnApplications}>
      <IsbnApplicationTable
        isbnApplications={isbnApplications}
        dispatchFn={fetchIsbnApplications}
      />
    </DelayedLoader>
  );
};

export default IsbnApplicationOverviewWrapper;
