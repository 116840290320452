import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as BoekenbankLogo } from '../../../../config/assets/pictures/boekenbank_logo2.svg';

const useStyles = makeStyles((theme: Theme) => ({
  img: {
    width: '75%',
    height: 'auto',
    paddingBottom: 8,
  },
  imgWrapper: {
    textAlign: 'center',
  },
}));

const Logo = () => {
  const classes = useStyles();
  return (
    <Link to="/">
      <div className={classes.imgWrapper}>
        <BoekenbankLogo className={classes.img} />
      </div>
    </Link>
  );
};

export default Logo;
