import AddIcon from '@mui/icons-material/AddCircleOutline';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import React from 'react';

type Props = {
  isDragActive?: boolean;
  activeMessage?: string;
  message?: string;
  children?: React.ReactChild | React.ReactChild[];
};

const useStyles = makeStyles((theme: Theme) => ({
  dropZoneWrapper: {
    border: '2px #C8C8C8 dashed',
    padding: theme.spacing(0.5),
    boxSizing: 'border-box',
    cursor: 'pointer',
    width: 60,
    height: 60,
    borderRadius: '100%',
  },
  dropZoneContent: {
    position: 'relative',
    width: '100%',
    height: '100%',
    backgroundColor: '#f2fbee',
    borderRadius: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
      opacity: 0.8,
    },
  },
  cloudIcon: {
    // height: 50,
    // width: 50,
    color: theme.palette.buttons.highlight,
  },
  greenIcon: {
    color: theme.palette.buttons.highlight,
  },
}));

const SmallUploadPlaceholder = ({ isDragActive, activeMessage, message, children }: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.dropZoneWrapper}>
      <div className={classes.dropZoneContent}>
        {isDragActive ? (
          <AddIcon
            className={classNames([classes.cloudIcon, classes.greenIcon])}
            sx={{ fontSize: 'default' }}
          />
        ) : (
          <CloudUploadIcon className={classes.cloudIcon} sx={{ fontSize: 'default' }} />
        )}
      </div>
    </div>
  );
};

export default SmallUploadPlaceholder;
