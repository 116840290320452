import { Chip, Grid, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useFetch } from '@react-redux-fetch/hooks';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import DelayedLoader from '../../../components/DelayedLoader';
import { Radio } from '../../../components/Form';
import SubTitle from '../../../components/SubTitle';
import { Prefix, User } from '../../../config/api/types';
import { getPrefixesRequest } from '../api';
import { canStartNewApplication } from '../domain';
import { getPrefixes } from '../selectors';
import { IsbnFormValues } from '../types';

type Props = {
  user: User;
  values: IsbnFormValues;
};

const useStyles = makeStyles((theme: Theme) => ({
  selectedLabelRoot: {
    ...theme.typography.body2,
  },
}));

const sortByRemaining = (prefixA: Prefix, prefixB: Prefix) => {
  if (prefixA.remainingIsbnCount < prefixB.remainingIsbnCount) return -1;
  if (prefixA.remainingIsbnCount > prefixB.remainingIsbnCount) return 1;
  return 0;
};

const PrefixFormAuthenticated = ({ user, values }: Props) => {
  const { t } = useTranslation();
  const prefixes = useSelector(getPrefixes);
  const [, fetchPrefixes] = useFetch(getPrefixesRequest);
  const prefixApiRoute =
    user?._embedded?.organisation?._links.prefixesWithRemainingIsbns?.href.replace(
      /(1|true)$/,
      'false'
    ) || '';
  const classes = useStyles();

  useEffect(() => {
    if (!prefixes) {
      fetchPrefixes(prefixApiRoute);
    }
  }, [prefixes, prefixApiRoute, fetchPrefixes]);

  const getRadioLabel = (item: Prefix) => {
    return t('form_isbn_prefix_radio_label', {
      prefix: item.prefix,
      amount: item.remainingIsbnCount,
    });
  };

  return (
    <Grid container direction="column" spacing={1}>
      <Grid item xs>
        <SubTitle>{t('form_isbn_choose_prefix_title')}</SubTitle>
        <Typography>{t('form_isbn_choose_prefix')}</Typography>
      </Grid>

      <Grid item xs>
        <DelayedLoader data={prefixes}>
          {prefixes && prefixes._embedded.items.length ? (
            <>
              {prefixes._embedded.items.sort(sortByRemaining).map((item) =>
                canStartNewApplication(item) ? (
                  <div key={item.prefix}>
                    <Radio
                      name="prefix"
                      size="small"
                      label={
                        values.prefix === item.prefix ? (
                          <Chip
                            color="primary"
                            label={getRadioLabel(item)}
                            classes={{ root: classes.selectedLabelRoot }}
                          />
                        ) : (
                          getRadioLabel(item)
                        )
                      }
                      value={item.prefix}
                    />
                  </div>
                ) : null
              )}
            </>
          ) : (
            <div>{t('form_isbn_no_prefixes')}</div>
          )}
        </DelayedLoader>
      </Grid>
    </Grid>
  );
};

export default PrefixFormAuthenticated;
