import * as React from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import BaseReactSelectField, {
  BaseReactSelectFieldProps,
} from '../../../../../../components/Form/BaseReactSelectField';
import {
  ProductForm,
  ProductFormDetail,
  ProductFormDetails,
} from '../../../../../../config/api/types';
import useFetch from '../../../../../../helpers/useFetch';
import { getProductFormDetailsRequest } from '../../api';

type Props = Partial<BaseReactSelectFieldProps> & {
  url: string;
  required?: boolean;
  isBibliographicalVerified?: boolean;
  onClearValue?: () => void;
  selectedProductForm: ProductForm;
};

const ProductFormDetailsField = ({
  url,
  required,
  disabled,
  selectedProductForm,
  ...other
}: Props) => {
  const { t } = useTranslation();
  const [productFormDetailsFetch, fetchProductFormDetails] = useFetch<ProductFormDetails>(
    getProductFormDetailsRequest(url)
  );

  useEffect(() => {
    if (!productFormDetailsFetch) {
      fetchProductFormDetails();
    }
  }, [productFormDetailsFetch, fetchProductFormDetails]);

  if (!productFormDetailsFetch || !productFormDetailsFetch.value) {
    return null;
  }

  const optionsObject = productFormDetailsFetch.value[selectedProductForm.code] as
    | ProductFormDetail
    | undefined;
  const options =
    optionsObject != null
      ? Object.entries(optionsObject).map(([code, value]) => ({ code, label: value.nl }))
      : [];

  if (options.length === 0) return null;

  return (
    <BaseReactSelectField
      label={t('title_productFormDetails')}
      name="selectedProductFormDetails"
      options={options}
      noOptionsMessage={() => t('search_no_options')}
      isMulti
      getOptionValue={(option: ProductFormDetail) => option.code}
      {...other}
    />
  );
};

export default ProductFormDetailsField;
