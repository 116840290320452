import React, { useState } from 'react';
import { PromiseState, ReduxFetch } from 'react-redux-fetch';
import { Organisations } from '../../../../../config/api/types';
import { ApiConfig } from '../../../api';
import { OrganisationsTable } from '../../components/Organisations/index';
import { OrganisationsHeader } from '../../components/Organisations/index';
import DelayedLoaderForFetch from '../../../../../components/DelayedLoaderForFetch';
import { useLocation } from 'react-router-dom';

type ReduxFetchRenderProps = {
  organisationsFetch: PromiseState<Organisations>;
  dispatchOrganisationsGet: (url?: string) => void;
};

const OrganisationList = () => {
  const locationParams = useLocation<{ keepFilters: boolean }>();
  const [initialRender, setInitialRender] = useState<boolean>(true);

  return (
    <>
      <OrganisationsHeader />
      <ReduxFetch config={[ApiConfig.getOrganisations()]}>
        {({ organisationsFetch, dispatchOrganisationsGet }: ReduxFetchRenderProps) => {
          const url = locationParams.state?.keepFilters
            ? organisationsFetch.value?._links.self.href
            : undefined;

          if (initialRender) {
            dispatchOrganisationsGet(url);
            setInitialRender(false);
          }

          return (
            <DelayedLoaderForFetch fetchObj={organisationsFetch}>
              <OrganisationsTable
                organisationsFetch={organisationsFetch}
                dispatchOrganisationsGet={dispatchOrganisationsGet}
              />
            </DelayedLoaderForFetch>
          );
        }}
      </ReduxFetch>
    </>
  );
};

export default OrganisationList;
