import { Typography } from '@mui/material';
import React, { FC } from 'react';
import { FieldRenderProps } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useIsVisible } from 'react-is-visible';
import { Thema } from '../../../../../../config/api/models/dataSets';
import { useThemas } from '../../../../../thema';
import { getThemas } from '../../../../domain';
import SearchableTreeSelect from './SearchableTreeSelect';

export const ThemasFieldAdapter: FC<FieldRenderProps<any>> = ({
  input: { name, onChange, value },
  meta: { submitError, error, submitFailed },
  label,
  ...other
}) => {
  const { t } = useTranslation();
  const nodeRef = React.useRef(null);
  const isVisible = useIsVisible(nodeRef);
  const { themas, hasAccess } = useThemas({ initiate: isVisible });

  if (!hasAccess || !themas) {
    return <div ref={nodeRef}>&nbsp;</div>;
  }

  return (
    <SearchableTreeSelect
      value={getThemas({ themas: value })}
      treeData={themas}
      onSelect={(themas: Thema[]) => {
        const hasFavorite = themas.some((thema) => thema.isMainSubject);
        if (themas.length && !hasFavorite) {
          const [first, ...rest] = themas;
          onChange([{ ...first, isMainSubject: true }, ...rest]);
        } else {
          onChange(themas);
        }
      }}
      label={label}
      error={(submitFailed && error) || submitError}
      favorite={value.find((thema: Thema) => thema.isMainSubject)}
      onSelectFavorite={(item) => {
        let itemFound = false;
        const values = value.map((thema: Thema) => {
          if (thema.isMainSubject) {
            return {
              ...thema,
              isMainSubject: false,
            };
          }

          if (thema.code === item.code) {
            itemFound = true;
            return {
              ...thema,
              isMainSubject: true,
            };
          }

          return thema;
        });

        const newValues = itemFound ? values : [{ ...item, isMainSubject: true }, ...values];

        onChange(newValues);
      }}
      renderSelectedValue={(item) => {
        return (
          <Typography key={`${item.code} - ${item.label}`} component="span">
            {item.code} - {item.label} {item.isMainSubject ? '(' + t('title_main_thema') + ')' : ''}{' '}
            <br />
          </Typography>
        );
      }}
    />
  );
};
