import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import ConstrainedPageContent from '../../../components/ConstrainedPageContent';
import PageTitle from '../../../components/PageTitle';
import '../components/quickOrders/QuickOrderEditorStyles.css';
import QuickOrdersEditor from '../components/quickOrders/QuickOrdersEditor';
import { getBulkOrdersToProcess } from '../selectors';
import QuickOrdersValidated from './QuickOrdersValidated';

type ActivePages = 'quickorderpage' | 'validationpage';

const QuickOrdersPage = () => {
  const bulkOrdersToProcess = useSelector(getBulkOrdersToProcess);
  const { t } = useTranslation();

  const [activePage, setActivePage] = useState<ActivePages>('quickorderpage');

  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, [activePage]);

  return (
    <ConstrainedPageContent center={false} lg>
      <Box pb={3}>
        <PageTitle>{t('nav_quickorder')}</PageTitle>
        <Box display={activePage === 'quickorderpage' ? 'block' : 'none'}>
          {/* Keep component alive */}
          <QuickOrdersEditor
            isValidationPageRendered={activePage === 'validationpage'}
            onNext={() => setActivePage('validationpage')}
          />
        </Box>

        <Box display={activePage === 'validationpage' && bulkOrdersToProcess ? 'block' : 'none'}>
          <QuickOrdersValidated
            orders={bulkOrdersToProcess}
            handleBack={() => setActivePage('quickorderpage')}
          />
        </Box>
      </Box>
    </ConstrainedPageContent>
  );
};

export default QuickOrdersPage;
