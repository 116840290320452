import { useFetch } from '@react-redux-fetch/hooks';
import useApiRoute from '../../../config/api/useApiRoute';
import { getNotificationsRequest } from '../api';
import { Channel } from '../selectors';
import { updateQueryParameter } from '../../../helpers/hateoas';

export const useNotificationsFetch = (
  pollInterval: false | number = false,
  channel?: Channel,
  page: number = 1
) => {
  const apiRoute = useApiRoute('notifications') || '';
  const url = channel
    ? updateQueryParameter(apiRoute, 'channel', channel)
    : updateQueryParameter(apiRoute, 'page', page);

  return useFetch(
    () => (url ? getNotificationsRequest(url, channel) : null),
    pollInterval !== false
      ? {
          pollInterval: url ? pollInterval : 0,
          eager: true,
        }
      : undefined
  );
};
