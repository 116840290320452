import React from 'react';
import MediaFiles from '../containers/MediaFiles';
import Header from '../../../../../components/Header';
import { useTranslation } from 'react-i18next';

// type Props = RouteComponentProps;

const MediaFilesPage = (/*{  }: Props*/) => {
  const { t } = useTranslation();

  return (
    <>
      <Header title={t('page_title_media_files')} />
      <MediaFiles />
    </>
  );
};

export default MediaFilesPage;
