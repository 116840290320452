import { RootState } from '../../../config/store/types';
import { Distributor, Maybe } from '../../../config/api/types';
import {
  DataConsumer,
  DataConsumerRef,
  DistributorRef,
  BookstoreRef,
  Bookstore,
} from '../../../config/api/models/organisation';

export const getOrganisation = (state: RootState) =>
  state.repository.organisation && state.repository.organisation.value;

export const getAllDistributors = (state: RootState) => state.data.distributors;

export const getDistributor = (distributorRef?: DistributorRef) => (
  state: RootState
): Maybe<Distributor> => {
  return distributorRef ? (getAllDistributors(state) || {})[distributorRef] : null;
};

export const getPublisher = (state: RootState) => state.data.publisher;

export const getAllDataConsumers = (state: RootState) => state.data.dataConsumers;

export const getDataConsumer = (dataConsumerRef?: DataConsumerRef) => (
  state: RootState
): Maybe<DataConsumer> => {
  return dataConsumerRef ? (getAllDataConsumers(state) || {})[dataConsumerRef] : null;
};

export const getAllBookstores = (state: RootState) => state.data.bookstores;

export const getBookstore = (bookstoreRef?: BookstoreRef) => (
  state: RootState
): Maybe<Bookstore> => {
  return bookstoreRef ? (getAllBookstores(state) || {})[bookstoreRef] : null;
};

export const getBookstoreGroups = (state: RootState) => state.data.bookstoreGroups;

export const getCashierSystems = (state: RootState) => state.data.cashierSystems;

export const getOrderFileTypes = (state: RootState) => state.data.orderFileTypes;

export const getLibrary = (state: RootState) => state.data.library;

export const getDepartments = (state: RootState) => state.data.departments;

export const getDepartment = (state: RootState) => state.data.department;

export const getFavoriteBookstores = (state: RootState) => state.data.favoriteBookstores;
