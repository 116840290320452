import { uniqBy } from 'ramda';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Field from '../../../../../../components/Form';
import BaseReactSelectField from '../../../../../../components/Form/BaseReactSelectField';
import InfoTooltip from '../../../../../../components/InfoTooltip';
import { ProductForm, ProductForms } from '../../../../../../config/api/types';
import useFetch from '../../../../../../helpers/useFetch';
import { isEBook, isPhysicalBook } from '../../../../domain';
import { getProductFormsRequest } from '../../api';
import TitleFormatField from '../../components/sections/TitleFormatField';

type Props = {
  onSelect: (productForm: ProductForm) => void;
  productFormFromServer?: ProductForm;
  url: string;
  required?: boolean;
  isBibliographicalVerified?: boolean;
};

const uniqByLabel = uniqBy((productForm: ProductForm) => productForm.label);

export const findProductForm = (allProductForms: ProductForm[], productForm: ProductForm) => {
  const selectedProductForms = allProductForms.filter(
    (p) =>
      p.label === productForm.label &&
      (!p.drm || !productForm.drm || p.drm.code === productForm.drm.code) &&
      ((p.details == null && productForm.details == null) ||
        p.details?.some(({ code }) => productForm.details?.some((detail) => detail.code === code)))
  );
  if (selectedProductForms.length > 1) {
    //shouldn't happen but it happened either way with ISBN 9789090374802. A test prevents us from comparing codes. Only labels, drm codes and detail codes may be compared.
    // It's unclear why. More info in ticket https://documents.calibrate.be/issues/130481. With this patch, the test (productFormField.test.ts line 32) is now commented out. When issues occur in the future, remove this conditional statement and find another solution.
    return selectedProductForms.find((p) => p.code === productForm.code);
  }
  return selectedProductForms[0];
};

const ProductFormField = ({
  onSelect,
  productFormFromServer,
  url,
  required,
  isBibliographicalVerified,
}: Props) => {
  const { t } = useTranslation();
  const [selectedProductFormSet, setSelectedProductFormSet] = useState(false);
  const [productFormsFetch, fetchProductForms] = useFetch<ProductForms>(
    getProductFormsRequest(url)
  );

  useEffect(() => {
    if (!productFormsFetch) {
      fetchProductForms();
    }
  }, [productFormsFetch, fetchProductForms]);

  useEffect(() => {
    if (
      !selectedProductFormSet &&
      productFormsFetch &&
      productFormsFetch.value &&
      productFormFromServer
    ) {
      const pf = findProductForm(Object.values(productFormsFetch.value), productFormFromServer);
      if (pf) {
        onSelect(pf);
      }
      setSelectedProductFormSet(true);
    }
  }, [selectedProductFormSet, productFormFromServer, productFormsFetch, onSelect]);

  if (!productFormsFetch || !productFormsFetch.value) {
    return (
      <Field
        label={`${t('title_productForm')}${required ? ' (*)' : ''}`}
        type="text"
        name="productForm.label"
        disabled
      />
    );
  }

  const emptyGroupedOptions: Array<{ label: string; options: ProductForm[] }> = [
    { label: t('title_productForm_group_physical'), options: [] },
    { label: t('title_productForm_group_ebook'), options: [] },
    { label: t('title_productForm_group_other'), options: [] },
  ];

  const options = uniqByLabel(Object.values(productFormsFetch.value)).reduce((agg, item) => {
    if (isPhysicalBook(item)) {
      agg[0].options.push(item);
    } else if (isEBook(item)) {
      agg[1].options.push(item);
    } else {
      agg[2].options.push(item);
    }
    return agg;
  }, emptyGroupedOptions);

  return (
    <TitleFormatField
      component={BaseReactSelectField}
      label={
        <>
          {`${t('title_productForm')}${required ? ' (*)' : ''}`}
          {isBibliographicalVerified && (
            <InfoTooltip title={t('title_isBibliographicalVerified_disabled_field')} inline />
          )}
        </>
      }
      name="selectedProductForm"
      options={options}
      getOptionValue={(option: ProductForm) => option.label}
      isDisabled={isBibliographicalVerified}
      onChange={(productForm: ProductForm) => onSelect(productForm)}
    />
  );
};

export default ProductFormField;
