import { Add } from '@mui/icons-material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DispatchFunctions, PromiseState, ReduxFetch } from 'react-redux-fetch';
import Button from '../../../../../components/Button';
import { CbRelationDto, Organisation } from '../../../../../config/api/types';
import createFinalFormAsyncValidation from '../../../../../helpers/finalFormAsyncValidation';
import { ApiConfig } from '../../../api';
import FormTitle from '../../components/FormTitle';
import CbRelationForm from '../../components/Publisher/CbRelationForm';
import CbRelationList from '../../components/Publisher/CbRelationList';

type Props = {
  organisation: Organisation;
};

type ReduxFetchRenderProps = {
  cbRelationsFetch: PromiseState;
  dispatchCbRelationsGet: (url?: string) => void;
  cbRelationFetch: PromiseState;
  dispatchCbRelationPut: (url?: string) => void;
  dispatchCbRelationDelete: (url?: string) => void;
};

const CbRelations = ({ organisation }: Props) => {
  const { t } = useTranslation();
  const [showAddCbRelationForm, setShowAddCbRelationForm] = useState(false);

  const cbRelationApiPath = organisation._links ? organisation._links.cbRelations.href : '';
  const { createSubmissionPromise, handleFulfil, handleReject } =
    createFinalFormAsyncValidation('cbRelationFetch');

  return (
    <>
      <FormTitle title={t('organisation_edit_publisher_cb_relation_title')} />
      <ReduxFetch
        config={[
          ApiConfig.getCbRelations(cbRelationApiPath),
          ApiConfig.updateCbRelation(),
          ApiConfig.removeCbRelation(),
        ]}
        fetchOnMount={['cbRelations']}
        onFulfil={(key: string, state: PromiseState, dispatchFunctions: DispatchFunctions) => {
          if (key === 'cbRelationFetch') {
            dispatchFunctions.dispatchCbRelationsGet();
          }
        }}
      >
        {({
          dispatchCbRelationsGet,
          cbRelationsFetch,
          dispatchCbRelationPut,
          cbRelationFetch,
          dispatchCbRelationDelete,
        }: ReduxFetchRenderProps) =>
          cbRelationsFetch.value ? (
            <>
              <CbRelationList
                cbRelations={cbRelationsFetch.value}
                dispatchCbRelationsGet={dispatchCbRelationsGet}
                updateCbRelation={dispatchCbRelationPut}
                removeCbRelation={dispatchCbRelationDelete}
                requestPending={
                  (cbRelationFetch && cbRelationFetch.pending) ||
                  (cbRelationsFetch && cbRelationsFetch.pending)
                }
              />
              {showAddCbRelationForm ? (
                <ReduxFetch
                  config={[ApiConfig.createCbRelation(cbRelationApiPath)]}
                  onFulfil={handleFulfil}
                  onReject={handleReject}
                >
                  {({
                    dispatchCbRelationPost,
                  }: {
                    dispatchCbRelationPost: (cbRelationDto: CbRelationDto) => void;
                  }) => (
                    <CbRelationForm
                      onSubmit={(formValues) => {
                        const cbRelationDto: CbRelationDto = {
                          cbRelationId: formValues.cbRelationId,
                          isPrimary: formValues.isPrimary,
                        };

                        dispatchCbRelationPost(cbRelationDto);
                        return createSubmissionPromise();
                      }}
                      onClose={() => setShowAddCbRelationForm(false)}
                    />
                  )}
                </ReduxFetch>
              ) : (
                <Button variant="text" onClick={() => setShowAddCbRelationForm(true)}>
                  <Add /> {t('form_cb_relation_create')}
                </Button>
              )}
            </>
          ) : null
        }
      </ReduxFetch>
    </>
  );
};

export default CbRelations;
