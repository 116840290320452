import React from 'react';
import UpdateForm from '../containers/UpdateForm';
import { RouteComponentProps } from 'react-router';
import OrganisationWrapper from '../containers/OrganisationWrapper';

type Params = { id: string };
type Props = RouteComponentProps<Params> & {};

const Update = (props: Props) => (
  <OrganisationWrapper {...props}>
    {({ organisation }) => <UpdateForm organisation={organisation} {...props} />}
  </OrganisationWrapper>
);

export default Update;
