import { Box, Grid } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import * as React from 'react';
import { FormRenderProps } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import Button from '../../../../../components/Button';
import Field, { CheckBox } from '../../../../../components/Form';
import { DataProducerDto } from '../../../../../config/api/types';
import FormSubTitle from '../FormSubTitle';
import FormTitle from '../FormTitle';

type Props = {
  onSubmit: FormRenderProps['handleSubmit'];
  disabled: boolean;
};

const DataProducerFormFields = ({ onSubmit, disabled }: Props) => {
  const { t } = useTranslation();

  const supplyFormats: Array<{ value: DataProducerDto['supplyFormat']; label: string }> = [
    {
      value: 'manual',
      label: t('filter_option_supply_manual'),
    },
    { value: 'onix', label: t('filter_option_supply_onix') },
    { value: 'csv', label: t('filter_option_supply_csv') },
  ];

  return (
    <form onSubmit={onSubmit}>
      <FormTitle title={t('organisation_edit_data_producer_parameters_title')} />

      <Box sx={{ mb: 3 }}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <FormSubTitle title={t('organisation_edit_data_producer_titlefiles_subtitle')} />
          </Grid>
          <Grid item xs={12} md={3}>
            <Field label={t('form_supply_format')} select type="text" name="supplyFormat">
              {supplyFormats.map((format) => (
                <MenuItem key={format.value} value={format.value}>
                  {format.label}
                </MenuItem>
              ))}
            </Field>
            <CheckBox name="usesBlockUpdates" label={t('form_uses_block_updates')} />
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ mb: 3 }}>
        <Grid container>
          <Grid item xs={12}>
            <FormSubTitle
              title={t('organisation_edit_data_producer_externalinterfaces_subtitle')}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <CheckBox name="usesGs1" label={t('form_uses_gs1')} />
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ mb: 1 }}>
        <Grid container>
          <Grid item xs={12}>
            <Button type="submit" disabled={disabled}>
              {t('form_action_save')}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </form>
  );
};

export default DataProducerFormFields;
