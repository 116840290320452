import { Theme } from '@mui/material';
import createTheme from './createTheme';
import overrides from './overrides';
import palette from './palette';
import './types';

const theme: Theme = createTheme({
  palette,
  typography: {
    h1: {
      fontSize: '2rem',
    },
    h6: {
      fontSize: '1.2rem',
    },
    subtitle2: {
      fontSize: '1rem',
    },
  },
  components: {
    ...overrides,
  },
  shape: {
    borderRadius: 0,
  },
});

export default theme;
