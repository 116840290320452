import { Theme, Typography } from '@mui/material';
import { TypographyProps } from '@mui/material/Typography';
import { styled } from '@mui/styles';
import React from 'react';

const FieldsetTitle = ({ children, ...other }: TypographyProps) => (
  <Typography variant="h3" {...other}>
    {children}
  </Typography>
);

type Props = {
  theme: Theme;
};

export default styled(FieldsetTitle)(({ theme }: Props) => ({
  padding: `${theme.spacing(1)} 0px`,
  margin: `0px ${theme.spacing(1)}`,
  borderBottom: `3px solid ${theme.palette.common.white}`,
  textTransform: 'uppercase',
}));
