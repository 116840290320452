import { Grid, Theme, Typography } from '@mui/material';
import { WithStyles, createStyles, withStyles } from '@mui/styles';
import { useFetch } from '@react-redux-fetch/hooks';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Button from '../../../../components/Button';
import DelayedLoader from '../../../../components/DelayedLoader';
import { ProcessAllOrdersDTO } from '../../../../config/api/models/shop';
import useApiRoute from '../../../../config/api/useApiRoute';
import { updateQueryParameter } from '../../../../helpers/hateoas';
import ExportButton from '../../../core/exports';
import { useNotificationsFetch } from '../../../notifications';
import { getOrdersToProcessRequest, processAllOrdersRequest } from '../../api';
import { getOrdersToProcess } from '../../selectors';
import Order from './Order';

const styles = (theme: Theme) =>
  createStyles({
    buttonList: {
      padding: `0 ${theme.spacing(1)}`,
    },
  });

const OrdersToProcess = ({ classes }: WithStyles<typeof styles>) => {
  const baseRoute = useApiRoute('elasticOrdersToBeProcessed') || '';
  const route = updateQueryParameter(baseRoute, 'order_dir', 'asc');
  const { t } = useTranslation();
  const [, fetchOrders] = useFetch(getOrdersToProcessRequest);
  const [processAllRequest, processAll] = useFetch(processAllOrdersRequest);
  const [, fetchNotifications] = useNotificationsFetch();

  useEffect(() => {
    fetchOrders(route);
  }, [fetchOrders, route]);

  useEffect(() => {
    if (processAllRequest?.fulfilled) {
      fetchNotifications();
    }
  }, [processAllRequest?.fulfilled, fetchNotifications]);

  const ordersToProcess = useSelector(getOrdersToProcess);

  const orders = ordersToProcess ? ordersToProcess._embedded.items : [];
  const exportUrl = ordersToProcess?._links.export?.href || undefined;
  const processAllUrl = ordersToProcess?._links.processAll?.href || '';

  const handleRefetch = useCallback(() => {
    fetchOrders(route);
    fetchNotifications();
  }, [fetchOrders, fetchNotifications, route]);

  const handleProcessAll = () => {
    const ordersList = orders.map(
      (order) =>
        ({
          orderId: order.orderId,
          stateDistributor: 'processed',
        } as ProcessAllOrdersDTO)
    );
    processAll(processAllUrl, ordersList);
  };

  return (
    <DelayedLoader data={ordersToProcess}>
      <>
        <Grid
          container
          spacing={1}
          sx={{ alignItems: 'center', justifyContent: orders.length ? 'flex-end' : 'flex-start' }}
          className={classes.buttonList}
        >
          {orders.length ? (
            [
              <Grid item key="processAllButton">
                {processAllUrl && (
                  <Button
                    size="small"
                    onClick={handleProcessAll}
                    disabled={processAllRequest?.pending}
                  >
                    {t('order_mark_all_complete')}
                  </Button>
                )}
              </Grid>,
              <Grid item key="exportAllButton">
                {exportUrl && (
                  <>
                    <Typography display="inline">{t('order_export_all')}: </Typography>
                    <ExportButton
                      url={exportUrl}
                      size="small"
                      withInitiation
                      queryKey="orderExportAll"
                      style={{ marginRight: 2 }}
                    >
                      Excel
                    </ExportButton>
                    <ExportButton
                      url={exportUrl}
                      size="small"
                      withInitiation
                      queryKey="orderExportAllCSV"
                      fetchConfig={{
                        fetchOptions: {
                          headers: {
                            accept: 'text/csv',
                          },
                        },
                      }}
                    >
                      CSV
                    </ExportButton>
                  </>
                )}
              </Grid>,
            ]
          ) : (
            <Grid item>
              <Typography>{t('order_none_to_process')}</Typography>
            </Grid>
          )}
        </Grid>
        {orders.map((order) => (
          <Order
            key={order.orderId}
            order={order}
            refetch={handleRefetch}
            canMarkProcessed={Boolean(processAllUrl)}
          />
        ))}
      </>
    </DelayedLoader>
  );
};

export default withStyles(styles)(OrdersToProcess);
