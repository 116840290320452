import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { OrderConfirmedForBookstoreNotification as OrderConfirmedforBookstoreAppNotification } from '../../../config/api/models/notifications';
import ROUTE_KEY from '../../../config/routes/routeKeys';
import usePath from '../../../config/routes/usePath';
import { apiDate } from '../../../helpers/date';

type Props = {
  notification: OrderConfirmedforBookstoreAppNotification;
  onClick: (notification: OrderConfirmedforBookstoreAppNotification, redirectPath?: string) => void;
  onDelete: () => void;
};

const OrderConfirmedForBookstoreNotification = ({ notification, onClick, onDelete }: Props) => {
  const { t } = useTranslation();
  const ordersPath = usePath(ROUTE_KEY.ORDERS_FOR_BOOKSTORE);

  const handleClick = () => {
    onClick(notification, ordersPath);
  };

  return (
    <MenuItem>
      <Box display={'flex'} style={{ gap: 20, width: '100%' }} alignItems={'center'}>
        <Grid container direction="column" onClick={handleClick}>
          <Grid item container direction="row" justifyContent="space-between" spacing={2}>
            <Grid item>
              <Box sx={{ position: 'relative' }}>
                {!notification.readAt && (
                  <Typography
                    style={{
                      position: 'absolute',
                      left: -12,
                      top: '50%',
                      transform: 'translateY(-50%)',
                    }}
                  >
                    {'\u25CF'}
                  </Typography>
                )}
                <Typography style={{ fontWeight: notification.readAt == null ? 500 : 400 }}>
                  {t('notifications_bookstore_OrderConfirmedNotification_title')}
                </Typography>
              </Box>
            </Grid>
            <Grid item>
              <Typography variant="caption">
                {apiDate(notification.parameters.date).toDateTime()}
              </Typography>
            </Grid>
          </Grid>
          <Grid item container direction="row" justifyContent="space-between" spacing={2}>
            <Grid item>
              <Typography>{notification.parameters.libraryName}</Typography>
            </Grid>
            <Grid item>
              <Typography variant="caption">
                {t('notifications_OrderConfirmedNotification_qty', notification.parameters)}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <IconButton onClick={onDelete} size="small">
          <DeleteIcon />
        </IconButton>
      </Box>
    </MenuItem>
  );
};

export default OrderConfirmedForBookstoreNotification;
