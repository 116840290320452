import { Box } from '@mui/material';
import React from 'react';
import { FormRenderProps } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import Button from '../../../../../components/Button';
import Field, { CheckBox } from '../../../../../components/Form';
import WhenFieldChanges from '../../../../../components/Form/WhenFieldChanges';
import { Bookstore } from '../../../../../config/api/models/organisation';
import BookstoreGroupField from '../Fields/BookstoreGroupField';
import CashierSystemField from '../Fields/CashierSystemField';
import OrderFileTypeField from '../Fields/OrderFileTypeField';
import FormSubTitle from '../FormSubTitle';
import FormTitle from '../FormTitle';
import Fieldset from '../Organisation/Fieldset';

type Props = {
  links: Bookstore['_links'];
  onSubmit: FormRenderProps['handleSubmit'];
  disabled: boolean;
  showEmailField: boolean;
};

const BookstoreFormFields = ({ links, onSubmit, disabled, showEmailField }: Props) => {
  const { t } = useTranslation();
  return (
    <form onSubmit={onSubmit}>
      <Fieldset>
        <FormTitle title={t('organisation_edit_bookstore_parameters_title')} />
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          <Box sx={{ maxWidth: '400px' }}>
            <Box marginTop={1} marginBottom={1}>
              <CheckBox
                name="isMemberBookstoresFlanders"
                label={t('organisation_edit_isMemberBookstoresFlanders')}
              />
            </Box>
          </Box>
          <Box sx={{ maxWidth: '400px' }}>
            <Field name="cbRelationId" type="text" label={t('form_cb_relation_id')} />
          </Box>
          <Box sx={{ maxWidth: '400px' }}>
            <BookstoreGroupField url={links.groups.href} />
          </Box>
          <Box sx={{ maxWidth: '400px' }}>
            <Box marginTop={1} marginBottom={1}>
              <CheckBox name="wantsEmailNotifications" label={t('form_wants_email_notification')} />
            </Box>

            {showEmailField && (
              <Field type="text" name="email" label={t('form_notification_email')} />
            )}
          </Box>
          <Box sx={{ maxWidth: '400px' }}>
            <Box marginTop={1} marginBottom={1}>
              <CheckBox name="usesFlowNumber" label={t('form_uses_flow_number')} />
            </Box>
          </Box>
        </Box>
      </Fieldset>

      <Fieldset>
        <FormSubTitle title={t('organisation_edit_bookstore_cashier_system_subtitle')} />
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          <Box sx={{ maxWidth: '400px' }}>
            <CashierSystemField url={links.cashierSystems.href} />
          </Box>

          <Box sx={{ maxWidth: '400px' }}>
            <OrderFileTypeField url={links.orderFileTypes.href} />
          </Box>
        </Box>
      </Fieldset>

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        <Box sx={{ maxWidth: '400px' }}>
          <Button type="submit" disabled={disabled}>
            {t('form_action_save')}
          </Button>
        </Box>
      </Box>

      <WhenFieldChanges
        field="wantsEmailNotifications"
        set="email"
        to={(value: string) => {
          return null;
        }}
      />
    </form>
  );
};

export default BookstoreFormFields;
