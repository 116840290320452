import React, { useEffect } from 'react';
import { useFetch } from '@react-redux-fetch/hooks';
import useApiRoute from '../../../../config/api/useApiRoute';
import { useSelector } from 'react-redux';
import DelayedLoader from '../../../../components/DelayedLoader';
import { getPrefixApplications } from '../../selectors';
import { getPrefixApplicationsRequest } from '../../api';
import PrefixApplicationTable from '../components/PrefixApplicationTable';

const PrefixApplicationOverviewWrapper = () => {
  const [, fetchPrefixApplications] = useFetch(getPrefixApplicationsRequest);
  const prefixApplicationApiLink = useApiRoute('prefixApplication') || '';

  const prefixApplications = useSelector(getPrefixApplications);

  useEffect(() => {
    if (prefixApplicationApiLink) {
      fetchPrefixApplications(prefixApplicationApiLink);
    }
  }, [fetchPrefixApplications, prefixApplicationApiLink]);

  return (
    <DelayedLoader data={prefixApplications}>
      <PrefixApplicationTable
        prefixApplications={prefixApplications}
        dispatchFn={fetchPrefixApplications}
      />
    </DelayedLoader>
  );
};

export default PrefixApplicationOverviewWrapper;
