import { FetchConfig } from '@react-redux-fetch/core';
import i18next from 'i18next';
import memoizeOne from 'memoize-one';
import apiRoutes from '../../../../config/api/routes';
import { PublisherDTO } from '../../../../config/api/types';
import { Data } from '../../../../config/store/types';
import { mapFormValues } from './dataMapper';
import { FormValues } from './domain';

export const getTitleKeywordsRequest = (url: string): FetchConfig<Data> => ({
  url: apiRoutes.create(url),
  repository: {
    keywords: (prev, next) => next,
  },
});

export const getTitleReplacementSearchRequest = (url: string): FetchConfig<Data> => ({
  url: apiRoutes.create(url),
  repository: {
    titleReplacementSearch: (prev, next) => next,
  },
});

export const getAgeRangesRequest = (url: string): FetchConfig<Data> => {
  return {
    method: 'GET',
    url: apiRoutes.create(url),
    requestKey: 'ageRanges',
    repository: {
      ageRanges: (prev, next) => next,
    },
  };
};

export const getAgeRangeGroupsRequest = (url: string): FetchConfig<Data> => {
  return {
    method: 'GET',
    url: apiRoutes.create(url),
    repository: {
      ageRangeGroups: (prev, next) => next,
    },
  };
};

export const getAviLevelsRequest = (
  url: string,
  resource: 'aviLevelsNew' | 'aviLevelsOld'
): FetchConfig<Data> => {
  return {
    method: 'GET',
    url: apiRoutes.create(url),
    repository: {
      [resource]: (prev: any, next: any) => next,
    },
  };
};

export const getContributorRolesRequest = (url: string): FetchConfig<Data> => {
  return {
    method: 'GET',
    url: apiRoutes.create(url),
    repository: {
      contributorRoles: (prev: any, next: any) => next,
    },
  };
};

export const getPublisherRequest = (url: string): FetchConfig<Data> => ({
  method: 'GET',
  url: apiRoutes.create(url),
  repository: {
    publisher: (prev, next) => next,
  },
});

export const getCollectionsRequest = (url: string): FetchConfig<Data> => ({
  method: 'GET',
  url: apiRoutes.create(url),
  transform: (responseValue) => {
    return {
      collections: responseValue._embedded.items,
    };
  },
  repository: {
    collections: (prev, next) => next,
  },
});

export const updatePublisherRequest = (url: string, body: PublisherDTO): FetchConfig<Data> => ({
  method: 'PUT',
  url: apiRoutes.create(url),
  fetchOptions: {
    body,
    successMsg: i18next.t('form_organisation_update_success'),
  },
  repository: {
    publisher: (prev, next) => next,
  },
});

export const updateTitleRequest = (url: string) => ({
  resource: 'title',
  method: 'PUT',
  request: (data: FormValues) => ({
    url: apiRoutes.get(url),
    body: mapFormValues(data).toTitleDTO(),
    meta: {
      successMsg: i18next.t('form_title_update_success'),
    },
  }),
});

export const createTitleRequest = (url: string, gtin13?: string) => ({
  resource: 'title',
  method: 'POST',
  request: (data: FormValues) => ({
    url: apiRoutes.get(url),
    body: mapFormValues(data).toTitleDTO(gtin13),
    meta: {
      successMsg: i18next.t('form_title_save_success'),
    },
  }),
});

export const getProductFormsRequest = (url: string) => ({
  resource: 'titleProductForms',
  request: () => ({
    url: apiRoutes.get(url),
  }),
});

export const getProductFormDetailsRequest = (url: string) => ({
  resource: 'titleProductFormDetails',
  request: () => ({
    url: apiRoutes.get(url),
  }),
});

export const getEBookTypesRequest = (url: string) => ({
  resource: 'titleEbookTypes',
  request: () => ({
    url: apiRoutes.get(url),
  }),
});

export const getPublishersRequest = () => ({
  resource: 'titlePublishers',
  request: (url: string) => ({
    url: apiRoutes.get(url),
  }),
});

export const getLanguagesRequest = (url: string) => ({
  resource: 'titleLanguages',
  request: () => ({
    url: apiRoutes.get(url),
  }),
});

export const getEditionTypesRequest = (url: string) => ({
  resource: 'titleEditionTypes',
  request: () => ({
    url: apiRoutes.get(url),
  }),
});

export const getAvailabilitiesRequest = (url: string) => ({
  resource: 'titleAvailabilities',
  request: () => ({
    url: apiRoutes.get(url),
  }),
});

export const getFundsRequest = () => ({
  resource: 'titleFunds',
  request: (url: string) => ({
    url: apiRoutes.get(url),
  }),
});

export const getTaxRequest = (url: string) => ({
  resource: 'titleTax',
  request: () => ({
    url: apiRoutes.get(url),
  }),
});

export const getDiscountCodesRequest = (url: string) => ({
  resource: 'discountCodes',
  request: () => ({
    url: apiRoutes.get(url),
  }),
});

export const getTitleMediaFilesRequest = memoizeOne(() => ({
  resource: 'titleMediaFiles',
  request: (url: string) => ({
    url: apiRoutes.get(url),
  }),
}));

export const deleteMediaRequest = memoizeOne((removeUrl?: string) => ({
  resource: `titleMedia_${removeUrl}`,
  method: 'DELETE',
  request: (newRemoveUrl?: string) => ({
    url: apiRoutes.create(newRemoveUrl || removeUrl),
    meta: {
      removeUrl,
      successMsg: i18next.t('form_title_media_delete_success'),
    },
  }),
}));

export const getAwardsDataRequest = (url: string) => {
  return {
    resource: 'prizes',
    request: () => ({
      url: apiRoutes.get(url),
    }),
  };
};
