import { ActionsUnion, createAction } from '../../../helpers/createAction';
import { ERROR_SHOWN, ALERT_CLOSED, SUCCESS_SHOWN } from './actionTypes';

export const Actions = {
  openError: (message: string) => createAction(ERROR_SHOWN, { message }),
  openSuccess: (message: string) => createAction(SUCCESS_SHOWN, { message }),
  closeAlert: (key: number) => createAction(ALERT_CLOSED, { key }),
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type Actions = ActionsUnion<typeof Actions>;
