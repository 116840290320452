import PrefixOverview from './scenes/PrefixOverview';
import MasterPrefixOverview from './scenes/MasterPrefixOverview';
import EditPrefix from './scenes/EditPrefix';
import IsbnApplicationOverview from './scenes/IsbnApplicationOverview';
import EditIsbnApplication from './scenes/EditIsbnApplication';
import PrefixApplicationOverview from './scenes/PrefixApplicationOverview';
import EditPrefixApplication from './scenes/EditPrefixApplication';

const isbnAdmin = {
  scenes: {
    PrefixOverview: PrefixOverview,
    MasterPrefixOverview: MasterPrefixOverview,
    EditPrefix: EditPrefix,
    IsbnApplicationOverview: IsbnApplicationOverview,
    EditIsbnApplication: EditIsbnApplication,
    PrefixApplicationOverview: PrefixApplicationOverview,
    editPrefixApplication: EditPrefixApplication,
  },
};

export default isbnAdmin;
