import { FetchConfig } from '@react-redux-fetch/core';
import { Data } from '../../../config/store/types';
import apiRoutes from '../../../config/api/routes';

export const getRetourRequest = (url: string): FetchConfig<Data> => {
  return {
    method: 'GET',
    url: apiRoutes.create(url),
    requestKey: 'retours',
    repository: {
      retour: (prev, next) => next,
    },
  };
};
