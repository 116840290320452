import { Box, Divider, Theme, Tooltip, Typography, useTheme } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { makeStyles } from '@mui/styles';
import camelCase from 'lodash/camelCase';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Button from '../../../../components/Button';
import SubTitle from '../../../../components/SubTitle';
import { ElasticOrder as ElasticOrderModel } from '../../../../config/api/models/shop';
import useFetch from '../../../../helpers/useFetch';
import ExportButton from '../../../core/exports';
import organisation from '../../../organisation';
import { updateOrderRequest } from '../../api';
import OrderLine from './OrderLine';
import OrderLineTotal from './OrderLineTotal';

type Props = {
  order: ElasticOrderModel;
  refetch: () => void;
  canMarkProcessed?: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  order: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
  orderTitle: {
    paddingTop: 0,
    paddingBottom: 0,
    margin: 0,
    width: '100%',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    background: theme.palette.background.appbar,
  },
  organisation: {
    ...theme.typography.h2,
    fontSize: 18,
    fontWeight: 'bolder',
  },
  orderTotal: {
    marginTop: theme.spacing(2),
  },
  noShrink: {
    display: 'flex',
    flexShrink: 0,
  },
  grow: {
    flexGrow: 1,
  },
}));

const Order = ({ order, refetch, canMarkProcessed }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [isUpdating, setIsUpdating] = useState(false);
  const [orderFetch, updateOrder] = useFetch(updateOrderRequest(order._links.self.href));

  const theme = useTheme();

  const fetchIsRejected = orderFetch && orderFetch.rejected;
  const fetchIsSuccess = orderFetch && orderFetch.fulfilled;

  useEffect(() => {
    if (fetchIsSuccess && isUpdating) {
      refetch();
    }
    if (fetchIsRejected && isUpdating) {
      setIsUpdating(false);
    }
  }, [fetchIsRejected, isUpdating, fetchIsSuccess, refetch]);

  if (isUpdating) {
    return null;
  }

  return (
    <div className={classes.order}>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        wrap="nowrap"
        className={classes.orderTitle}
        spacing={2}
      >
        <Grid className={classes.noShrink}>
          <Box display="flex">
            <SubTitle gutterBottom={false} style={{ fontSize: 16 }}>
              <Trans
                i18nKey="orders_ordered_by"
                components={[
                  <strong />,
                  <strong />,
                  <organisation.components.OrganisationDialog
                    organisationUrl={order._links.organisation.href}
                    toggleButtonProps={{ className: classes.organisation }}
                  >
                    {{ organisation: order._embedded.organisation.name } as any}
                  </organisation.components.OrganisationDialog>,
                ]}
                values={{
                  orderNumber: order.orderNumber,
                  date: moment(order.orderedAt).format('DD/MM/YYYY'),
                  organisation: order._embedded.organisation.name,
                }}
              />
            </SubTitle>
          </Box>
        </Grid>
        <Grid className={classes.grow} style={{ paddingLeft: 0, marginLeft: -2 }}>
          <Tooltip placement="top-start" title={order.orderReference || ''} enterDelay={500}>
            <Typography
              style={{ fontSize: 16, color: theme.palette.text.subtitle }}
              noWrap
              variant="h2"
            >
              <Trans
                i18nKey="with_ref"
                values={{ reference: order.orderReference }}
                components={[<strong />]}
              />
            </Typography>
          </Tooltip>
        </Grid>
        <Grid className={classes.noShrink}>
          <Grid container spacing={1} wrap="nowrap">
            {canMarkProcessed && (
              <Grid>
                <Button
                  size="small"
                  onClick={() => {
                    setIsUpdating(true);
                    updateOrder({ ...order, stateDistributor: 'processed' });
                  }}
                  disabled={isUpdating}
                >
                  {t('order_mark_complete')}
                </Button>
              </Grid>
            )}
            <Grid>
              <ExportButton
                url={order._links.self.href}
                size="small"
                queryKey={camelCase(order._embedded.organisation.name)}
              >
                {t('order_single_export_excel')}
              </ExportButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Divider />
      {order.linesByDistributor[0].lines?.map((orderLine) => (
        <React.Fragment key={orderLine.titleId}>
          <OrderLine orderLine={orderLine} />
          <Divider />
        </React.Fragment>
      ))}
      <OrderLineTotal amount={order.linesByDistributor[0]?.resaleValue} />
    </div>
  );
};

export default Order;
