import React from 'react';
import { useTranslation } from 'react-i18next';
import PageTitle from '../../../../components/PageTitle';
import IsbnApplicationOverviewWrapper from '../containers/IsbnApplicationOverviewWrapper';

const IsbnApplicationOverview = () => {
  const { t } = useTranslation();

  return (
    <>
      <PageTitle>{t('isbn_application_overview')}</PageTitle>
      <IsbnApplicationOverviewWrapper />
    </>
  );
};

export default IsbnApplicationOverview;
