import { Box } from '@mui/material';
import { useFetch } from '@react-redux-fetch/hooks';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import DelayedLoader from '../../../../components/DelayedLoader';
import { SearchField } from '../../../../components/Form';
import TableFilterWrapper from '../../../../components/Table/TableFilterWrapper';
import useApiRoute from '../../../../config/api/useApiRoute';
import { getLink, updateQueryParameter } from '../../../../helpers/hateoas';
import { getPublishersRequest } from '../../api';
import { getPublishersList } from '../../selectors';
import { PublishersTable } from '../components/PublishersTable';

export const PublishersTableWrapper = () => {
  const { t } = useTranslation();
  const apiRoute = useApiRoute('publishers');
  const [, fetchPublishers] = useFetch(getPublishersRequest);

  const pagedPublishers = useSelector(getPublishersList);
  const url = getLink(pagedPublishers, 'self') || '';

  useEffect(() => {
    if (apiRoute) {
      fetchPublishers(apiRoute);
    }
  }, [apiRoute, fetchPublishers]);

  const handleSearchTermChange = (term: string) => {
    fetchPublishers(updateQueryParameter(url, 'q', term.trim()));
  };

  return (
    <DelayedLoader data={pagedPublishers}>
      <TableFilterWrapper>
        <Box flex={1}>
          <SearchField
            placeholder={t('form_placeholder_prefix_search')}
            searchCallback={handleSearchTermChange}
            isClearable
          />
        </Box>
      </TableFilterWrapper>
      <PublishersTable publishers={pagedPublishers} fetchPublishers={fetchPublishers} />
    </DelayedLoader>
  );
};
