import { Grid, MenuItem, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Field from '../../../components/Form';
import FormFieldWrapper from '../../../components/Form/FormFieldWrapper';
import SubTitle from '../../../components/SubTitle';
import { User } from '../../../config/api/types';
import countries from '../../../config/data/countries';

type Props = {
  user?: User;
  formType: 'isbn' | 'prefix';
};

type countryType = {
  name: string;
  short_name: string;
  calling_code: string;
};

const OrganisationForm = ({ user, formType }: Props) => {
  const { t } = useTranslation();

  return (
    <Grid container spacing={3} direction="column">
      {user ? (
        <Grid item>
          <SubTitle gutterBottom>{t('form_check_organisation_info_title')}</SubTitle>
          <Typography>{t(`form_${formType}_check_organisation_info`)}</Typography>
        </Grid>
      ) : (
        <Grid item>
          <SubTitle gutterBottom>{t('form_isbn_organisation_info_title')}</SubTitle>
          <Typography>{t('form_isbn_organisation_info')}</Typography>
        </Grid>
      )}

      <Grid item>
        <FormFieldWrapper>
          <Field
            label={`${t('form_organisation_name')} (*)`}
            type="text"
            name="organisation.name"
          />
        </FormFieldWrapper>
        <FormFieldWrapper>
          <Field
            label={t('form_organisation_company_registration_number')}
            type="text"
            name="organisation.companyRegistrationNumber"
            helperText={t('form_company_registration_number_helpertext')}
          />
        </FormFieldWrapper>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Field
              label={`${t('form_organisation_street')} (*)`}
              type="text"
              name="organisation.street"
            />
          </Grid>
          <Grid item xs={3}>
            <Field
              label={`${t('form_organisation_number')} (*)`}
              type="text"
              name="organisation.number"
            />
          </Grid>
          <Grid item xs>
            <Field
              label={t('form_organisation_addition')}
              type="text"
              name="organisation.addition"
            />
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <Field
              label={`${t('form_organisation_postal_code')} (*)`}
              type="text"
              name="organisation.postalCode"
            />
          </Grid>
          <Grid item xs>
            <Field
              label={`${t('form_organisation_city')} (*)`}
              type="text"
              name="organisation.city"
            />
          </Grid>
        </Grid>
        <FormFieldWrapper>
          <Field
            label={`${t('form_organisation_country_iso')} (*)`}
            select
            type="text"
            name="organisation.countryIso"
          >
            {countries.map((country: countryType) => (
              <MenuItem key={country.short_name} value={country.short_name}>
                {country.name}
              </MenuItem>
            ))}
          </Field>
        </FormFieldWrapper>
        <FormFieldWrapper>
          <Field
            label={`${t('form_organisation_phone_number')} (*)`}
            type="text"
            helperText={t('form_phone_number_helpertext')}
            name="organisation.phoneNumber"
          />
        </FormFieldWrapper>
        <FormFieldWrapper>
          <Field
            label={`${t('form_organisation_email')}`}
            type="text"
            helperText={t('form_email_helpertext_isbn')}
            name="organisation.email"
          />
        </FormFieldWrapper>
        <FormFieldWrapper>
          <Field
            label={`${t('form_url')}`}
            type="text"
            helperText={t('form_url_helpertext')}
            name="organisation.url"
          />
        </FormFieldWrapper>
      </Grid>
    </Grid>
  );
};

export default OrganisationForm;
