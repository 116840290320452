import { Box, Typography } from '@mui/material';
import { useFetch } from '@react-redux-fetch/hooks';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Button from '../../../components/Button';
import { BulkOrderTitle, BulkOrdersToProcess } from '../../../config/api/models/shop';
import useApiRoute from '../../../config/api/useApiRoute';
import ROUTE_KEY from '../../../config/routes/routeKeys';
import usePath from '../../../config/routes/usePath';
import CopyToClipboardButton from '../../app/components/CopyToClipboardButton';
import { getShoppingCartRequest } from '../../title/cart/api';
import { postBulkQuickOrderRequest } from '../api';
import QuickOrdersToProcess from '../components/quickOrders/QuickOrdersToProcess';

type Props = {
  orders?: BulkOrdersToProcess | null;
  handleBack: () => void;
};

const QuickOrdersValidated = ({ orders, handleBack }: Props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const returnPath = usePath(ROUTE_KEY.SHOPPING_CART);
  const url = useApiRoute('shoppingCart');
  const [shoppingCartFetchRequest, fetchShoppingCart] = useFetch(getShoppingCartRequest);

  const [postBulkOrderRequest, postBulkOrder] = useFetch(postBulkQuickOrderRequest);

  const handleBulkAddToCart = useCallback(() => {
    if (!orders?.valid.length) return;

    postBulkOrder('/shopping_carts/bulk', {
      titles: orders.valid,
    });
  }, [postBulkOrder, orders?.valid]);

  const convertToString = (invalidOrders: BulkOrderTitle[]) => {
    return invalidOrders.map((entry) => Object.values(entry).join('\t')).join('\r\n');
  };

  useEffect(() => {
    if (postBulkOrderRequest?.fulfilled) {
      if (url) {
        fetchShoppingCart(url);
      }
    }
  }, [postBulkOrderRequest, url, fetchShoppingCart]);

  useEffect(() => {
    //After the bulk orders are succesfully sent to the backend, we fetch the shoppingCart to update the redux store before the user is being redirected to the shopping cart page.
    //The reason is that otherwise 'shouldUpdate' inside CartLine.tsx will be set to true because the redux store is behind.
    //As a result, the quantities of the items will be reset to their previous values. We don't want that.
    //A possibly better solution would be trying to update the store right after the post request inside postBulkQuickOrderRequest in file /orders/api.ts but this may require additional BE changes.

    if (shoppingCartFetchRequest?.fulfilled) {
      handleBack(); //This will reset the orders
      history.push(returnPath, { keepFilters: true });
    }
  }, [shoppingCartFetchRequest, history, returnPath, handleBack]);

  if (orders == null) return null;

  return (
    <>
      {orders.invalid.length > 0 && (
        <>
          <Box mb={2}>
            <QuickOrdersToProcess orders={orders.invalid} type="invalid" />
          </Box>
          <Box>
            <CopyToClipboardButton
              textToCopy={convertToString(orders.invalid)}
              tooltipText={t('quick_order_copy_error_lines')}
              withMargin={false}
            >
              {t('quick_order_copy_error_lines')}
            </CopyToClipboardButton>
          </Box>
        </>
      )}
      {orders.valid.length > 0 && (
        <Box mt={4}>
          <QuickOrdersToProcess orders={orders.valid} type="valid" />
          <div style={{ marginTop: '1rem', float: 'right' }}>
            <Button onClick={handleBulkAddToCart}>{t('quick_order_add_to_cart')}</Button>
          </div>
        </Box>
      )}
      {orders.invalid.length === 0 && orders.valid.length === 0 && (
        <Typography>{t('quick_order_no_results')}</Typography>
      )}

      <Box mt={2}>
        <Button onClick={handleBack} variant={'outlined'}>
          {t('form_previous')}
        </Button>
      </Box>
    </>
  );
};

export default QuickOrdersValidated;
