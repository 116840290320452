import {
  NormalizedOrgLogins,
  NormalizedOrgLoginsPage,
  OrgLogins,
} from '../../config/api/models/organisation';
import normalizePagedResponse from '../../helpers/normalizePagedResponse';
import { Organisation, OrganisationCreateRequest, UserRole } from '../../config/api/types';

type NormalizedPagedOrgLogin = {
  orgLogins: NormalizedOrgLogins;
  orgLoginsPage: NormalizedOrgLoginsPage;
};

export const normalizePagedOrgLoginRequest = (data: OrgLogins): NormalizedPagedOrgLogin => {
  const { normalizedData, normalizedPage } = normalizePagedResponse(
    data,
    (orgLogin) => orgLogin.email
  );

  return {
    orgLogins: normalizedData,
    orgLoginsPage: normalizedPage,
  };
};

/*
 * Transform ["ROLE_LIBRARY"] to {ROLE_LIBRARY: true}
 */
export const organisationFormToOrganisationModel = <T extends { roles: string[] }>(
  values: T,
  organisation?: Organisation
): OrganisationCreateRequest => {
  return ({
    ...values,
    roles: values.roles.reduce((prev, role) => {
      return { ...prev, [role]: organisation?.roles?.[role as UserRole] ?? true };
    }, {}),
  } as unknown) as OrganisationCreateRequest;
};
