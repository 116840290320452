import { Theme, Tooltip, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ElasticShoppingCartLine } from '../../../../config/api/models/shop';
import formatEuro from '../../../../helpers/formatEuro';
import getIdFromUrl from '../../../../helpers/getIdFromUrl';
import CopyToClipboardButton from '../../../app/components/CopyToClipboardButton';
import publisher from '../../../organisation/publisher';
import ElasticIsbnLink from '../../../title/components/ElasticIsbnLink';
import { elasticGetProductForm } from '../../../title/domain';

type Props = {
  orderLine: ElasticShoppingCartLine;
};

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      padding: theme.spacing(1),
    },
    copyToClipboardButton: {
      marginLeft: theme.spacing(0.25),
    },
  }),
  { name: 'OrderLine' }
);

const OrderLine = ({ orderLine }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <Grid
      container
      spacing={2}
      sx={{ alignItems: 'center', justifyContent: 'space-between' }}
      className={classes.root}
    >
      <Grid xs={2} sm={4}>
        <Typography noWrap>{orderLine.titleTitle}</Typography>
      </Grid>

      <Grid style={{ flexGrow: 0, maxWidth: '19%', flexBasis: '19%' }}>
        <Typography>
          <ElasticIsbnLink
            orderLine={{ ...orderLine, titleId: getIdFromUrl(orderLine._links.title.href) }}
          />
          <CopyToClipboardButton
            textToCopy={orderLine.gtin13}
            tooltipText={t('copy_isbn')}
            className={classes.copyToClipboardButton}
          />
          {' | '}
          {elasticGetProductForm(orderLine)}
        </Typography>
      </Grid>

      <Grid xs={2}>
        <publisher.components.ElasticPublisher
          publisherName={orderLine.publisherName}
          imprint={orderLine.imprint}
          publisherOrganisationUrl={orderLine._links.publisher?.href}
          showBothIfPossible={false}
        />
      </Grid>

      <Grid xs={1}>
        <Tooltip placement="top-start" title={orderLine.reference || ''} enterDelay={500}>
          <Typography noWrap>{orderLine.reference}</Typography>
        </Tooltip>
      </Grid>

      <Grid
        style={{ width: 168 }}
        container
        spacing={1}
        sx={{ alignItems: 'center', justifyContent: 'flex-end' }}
      >
        <Grid style={{ textAlign: 'right' }}>{t('order_qty', { count: orderLine.quantity })}</Grid>
        <Grid style={{ width: 80, textAlign: 'right' }}>{formatEuro(orderLine.unitPrice)}</Grid>
      </Grid>
    </Grid>
  );
};

export default OrderLine;
