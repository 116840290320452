import { FetchConfig } from '@react-redux-fetch/core';
import apiRoutes from '../../config/api/routes';
import { Data } from '../../config/store/types';
import { Channel } from './selectors';

export const getNotificationsRequest = (url: string, channel?: Channel): FetchConfig<Data> => ({
  url: apiRoutes.create(url),
  requestKey: channel ? `notificationsFor${channel}` : 'notifications',
  fetchOptions: {
    skipAlertOnError: true,
  },
  repository: channel
    ? {
        notificationsForChannel: (prev, next) => ({
          [channel]: next,
        }),
      }
    : {
        notifications: (prev, next) => next,
      },
});

export const deleteNotificationRequest = (url: string, id: string): FetchConfig<Data> => ({
  url: apiRoutes.create(url),
  method: 'DELETE',
  repository: {
    notifications: (prev: Data['notifications']) => {
      if (!prev?._embedded) return prev;

      const items = prev._embedded.items.filter((notification) => notification.id !== id);

      return {
        ...prev,
        total: items.length !== prev._embedded.items.length ? prev.total - 1 : prev.total,
        _embedded: {
          ...prev._embedded,
          items: prev._embedded.items.filter((notification) => notification.id !== id),
        },
      };
    },
  },
});

export const patchNotificationRequest = (url: string, id: string): FetchConfig<Data> => ({
  url: apiRoutes.create(url),
  requestKey: 'patchNotificationRequest',
  method: 'PATCH',
  repository: {
    notifications: (prev: Data['notifications']) => {
      if (!prev?._embedded) return prev;

      return {
        ...prev,
        _embedded: {
          ...prev._embedded,
          items: prev._embedded.items.map((notification) => ({
            ...notification,
            ...(notification.id === id && { readAt: 'read' }),
          })),
        },
      };
    },
  },
});

export const getNotificationsCountersRequest = (url: string): FetchConfig<Data> => ({
  url: apiRoutes.create(url),
  requestKey: 'notificationsCounters',
  method: 'GET',
  repository: {
    notificationsCounters: (prev, next) => {
      return next;
    },
  },
});
