import React, { useContext } from 'react';
import { EditForm } from '../components/Organisation/index';
import { Form } from 'react-final-form';
import { PromiseState, ReduxFetch } from 'react-redux-fetch';
import { Organisation, OrganisationCreateRequest } from '../../../../config/api/types';
import createFinalFormAsyncValidation from '../../../../helpers/finalFormAsyncValidation';
import Button from '../../../../components/Button';
import { ApiConfig } from '../../api';
import { useTranslation } from 'react-i18next';
import apiRoutes from '../../../../config/api/routes';
import getIdFromUrl from '../../../../helpers/getIdFromUrl';
import { getLink } from '../../../../helpers/hateoas';
import { RouteComponentProps } from 'react-router';
import { ClientRouteContext } from '../../../../config/routes/ClientRouteContext';
import ROUTE_KEY from '../../../../config/routes/routeKeys';

type Props = RouteComponentProps & {};

type ReduxFetchRenderProps = {
  organisationFetch: PromiseState<Organisation>;
  dispatchOrganisationPost: (values: OrganisationCreateRequest) => void;
};

const defaultFieldValues: OrganisationCreateRequest = {
  name: '',
  companyRegistrationNumber: '',
  street: '',
  number: '',
  addition: '',
  postalCode: '',
  city: '',
  countryIso: '',
  email: '',
  phoneNumber: '',
  url: '',
  roles: {},
};

const Create = ({ history }: Props) => {
  const { t } = useTranslation();
  const { flatRoutes } = useContext(ClientRouteContext);
  const fetchConfig = [ApiConfig.createOrganisation(apiRoutes.organisation())];
  const { createSubmissionPromise, handleFulfil, handleReject } = createFinalFormAsyncValidation(
    'organisationFetch'
  );

  const onFulfil = (resourceName: string, promiseState: PromiseState<Organisation>) => {
    handleFulfil(resourceName);

    const id = getIdFromUrl(getLink(promiseState.value, 'self') || '');
    const clientRoute = flatRoutes.find((route) => route.key === ROUTE_KEY.ADMIN_ORG_EDIT);

    if (!clientRoute) {
      throw new Error(`Path ${ROUTE_KEY.ADMIN_ORG_EDIT} not found`);
    }

    history.push(clientRoute.path.replace(':id', id));
  };

  return (
    <ReduxFetch config={fetchConfig} onFulfil={onFulfil} onReject={handleReject}>
      {({ dispatchOrganisationPost }: ReduxFetchRenderProps) => {
        return (
          <Form
            initialValues={defaultFieldValues}
            onSubmit={(values) => {
              dispatchOrganisationPost(values as OrganisationCreateRequest);
              return createSubmissionPromise();
            }}
          >
            {({ handleSubmit, submitting, values }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <EditForm />
                  {/*<DropZone />*/}
                  {/*<RolesForm selectedRoles={values.roles} />*/}
                  <Button type="submit" disabled={submitting}>
                    {t('form_action_save')}
                  </Button>
                </form>
              );
            }}
          </Form>
        );
      }}
    </ReduxFetch>
  );
};
export default Create;
