import { FormHelperText, Grid, MenuItem } from '@mui/material';
import React from 'react';
import { AnyObject, FormRenderProps } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import Button from '../../../../../components/Button';
import Field, { CheckBox } from '../../../../../components/Form';
import FormFieldWrapper from '../../../../../components/Form/FormFieldWrapper';
import { statisticCodes } from '../../../domain';
import DistributorSelect from '../../containers/DistributorSelect';
import FundCodeField from '../../containers/FundCodeField';

type Props = {
  onSubmit: FormRenderProps['handleSubmit'];
  values: AnyObject;
  submitting: boolean;
  invalid: boolean;
  isUpdate: boolean;
  isInitiallyDefault: boolean;
};

const FundsFormFields = ({
  onSubmit,
  values,
  submitting,
  invalid,
  isUpdate,
  isInitiallyDefault,
}: Props) => {
  const { t } = useTranslation();

  return (
    <form onSubmit={onSubmit}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <FormFieldWrapper>
            <DistributorSelect isUpdate={isUpdate} />
          </FormFieldWrapper>
          <FormFieldWrapper>
            <FundCodeField isUpdate={isUpdate} distributorUrl={values.distributorUrl} />
          </FormFieldWrapper>
          <FormFieldWrapper>
            <Field label={t('form_description')} type="text" name="description" />
          </FormFieldWrapper>
          <FormFieldWrapper>
            <Field label={t('form_statistics_code')} select type="select" name="statisticsCode">
              <MenuItem>&nbsp;</MenuItem>
              {statisticCodes.map((code) => (
                <MenuItem key={code} value={code}>
                  {t(`filter_option_${code.toLowerCase()}`)}
                </MenuItem>
              ))}
            </Field>
          </FormFieldWrapper>
          <FormFieldWrapper>
            <Field label={t('form_status')} select type="select" name="status">
              <MenuItem value="status_active">{t('filter_option_active')}</MenuItem>
              <MenuItem value="status_not_active">{t('filter_option_inactive')}</MenuItem>
            </Field>
          </FormFieldWrapper>
          <FormFieldWrapper>
            <CheckBox name="isAvailable" label={t('form_is_available')} />
          </FormFieldWrapper>
          <FormFieldWrapper>
            <CheckBox
              name="isDefault"
              label={t('form_is_default')}
              disabled={
                (values.status === 'status_not_active' && values.isDefault === false) ||
                isInitiallyDefault
              }
            />
            {isInitiallyDefault && (
              <FormHelperText style={{ margin: '0 0 8px 12px' }}>
                {t('form_is_default_helpertext')}
              </FormHelperText>
            )}
          </FormFieldWrapper>
          <FormFieldWrapper>
            <Button type="submit" disabled={submitting || !values.fullCode}>
              {t('form_action_save')}
            </Button>
          </FormFieldWrapper>
        </Grid>
      </Grid>
    </form>
  );
};

export default FundsFormFields;
