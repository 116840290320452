import { useCallback, useContext } from 'react';
import { ApiRouteContext } from './ApiRouteContext';
import { RouteResourceNames } from './types';

const useCreateApiRoute = () => {
  const { routes } = useContext(ApiRouteContext);

  return useCallback(
    (routeKey: RouteResourceNames, id?: string): string | false => {
      if (!routes || !routes._links[routeKey]) {
        return false;
      }

      const route = routes._links[routeKey];

      if (route && id && route.type === 'collection') {
        return `${route.href}/${id}`;
      }

      if (route && id && route.type === 'detail') {
        return decodeURI(route.href).replace(/\{.*\}/, id);
      }

      return route ? route.href : false;
    },
    [routes]
  );
};

export default useCreateApiRoute;
