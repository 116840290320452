import React, { useEffect } from 'react';
import MasterPrefixForm from '../components/MasterPrefixForm';
import { useFetch } from '@react-redux-fetch/hooks';
import { createMasterPrefixRequest } from '../../api';
import { MasterPrefixDTO } from '../../../../config/api/types';
import { useAsyncValidation } from '../../../../helpers/finalFormAsyncValidation';
import useApiRoute from '../../../../config/api/useApiRoute';

type Props = {
  onClose: () => void;
};

const AddMasterPrefixWrapper = ({ onClose }: Props) => {
  const [addMasterPrefixRequest, addMasterPrefix] = useFetch(createMasterPrefixRequest);
  const masterPrefixApiLink = useApiRoute('masterPrefixes') || '';
  const { createSubmissionPromise } = useAsyncValidation(addMasterPrefixRequest);

  const handleSubmit = (values: MasterPrefixDTO) => {
    addMasterPrefix(masterPrefixApiLink, values);
    return createSubmissionPromise().then((result) => result);
  };

  useEffect(() => {
    if (addMasterPrefixRequest?.fulfilled) {
      onClose();
    }
  }, [addMasterPrefixRequest, onClose]);

  return (
    <MasterPrefixForm
      onSubmit={handleSubmit}
      onCancel={onClose}
      disabled={addMasterPrefixRequest?.pending}
    />
  );
};

export default AddMasterPrefixWrapper;
