import { Box, Divider, Grid, Tooltip, Typography } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import InfoTooltip from '../../../../components/InfoTooltip';
import { ShoppingCartLine } from '../../../../config/api/models/shop';
import formatEuro from '../../../../helpers/formatEuro';
import { IsbnLink } from '../../../title';

type Props = {
  lines?: ShoppingCartLine[];
};

const OrderDetailTable: FC<Props> = ({ lines }) => {
  const { t } = useTranslation();

  return (
    <>
      <Grid container spacing={1}>
        <Grid item style={{ width: 120 }}>
          <Typography variant="subtitle2">{t('table_headers_isbn')}</Typography>
        </Grid>
        <Grid item style={{ flex: 1 }}>
          <Typography variant="subtitle2">{t('table_headers_title')}</Typography>
        </Grid>
        <Grid item style={{ width: 160 }}>
          <Typography variant="subtitle2">{t('table_headers_product_form')}</Typography>
        </Grid>
        <Grid item style={{ width: 240 }}>
          <Typography variant="subtitle2">{t('table_headers_line_reference')}</Typography>
        </Grid>
        <Grid item style={{ width: 80 }}>
          <Typography variant="subtitle2">
            {t('table_headers_flow_number')}
            <InfoTooltip title={t('table_headers_flow_number_tooltip')} inline />
          </Typography>
        </Grid>
        {/*<Grid item >*/}
        {/*  <Typography variant="subtitle2">{t('table_headers_kind')}</Typography>*/}
        {/*</Grid>*/}
        <Grid item style={{ textAlign: 'center', width: 60 }}>
          <Typography variant="subtitle2">{t('table_headers_type')}</Typography>
        </Grid>
        <Grid item style={{ textAlign: 'center', width: 80 }}>
          <Typography variant="subtitle2">{t('table_headers_num_pieces')}</Typography>
        </Grid>
        <Grid item style={{ textAlign: 'right', width: 80 }}>
          <Typography variant="subtitle2">{t('table_headers_price')}</Typography>
        </Grid>
        <Grid item style={{ textAlign: 'right', width: 80 }}>
          <Typography variant="subtitle2">{t('table_headers_price_total')}</Typography>
        </Grid>
      </Grid>
      <Box marginTop={1} marginBottom={1}>
        <Divider />
      </Box>
      {lines?.map((line) => (
        <React.Fragment key={line._links.shoppingCartLine.href}>
          <Grid container spacing={1}>
            <Grid item style={{ width: 120 }}>
              <Typography>
                <IsbnLink orderLine={line} />
              </Typography>
            </Grid>
            <Grid item style={{ flex: 1, overflow: 'hidden' }}>
              <Tooltip placement="top-start" title={line._embedded.title.title} enterDelay={500}>
                <Typography noWrap>{line._embedded.title.title}</Typography>
              </Tooltip>
            </Grid>
            <Grid item style={{ width: 160 }}>
              <Typography>
                {line._embedded.title.productForm.shortLabel ||
                  line._embedded.title.productForm.label}
              </Typography>
            </Grid>
            <Grid item style={{ width: 240 }}>
              <Typography>{line.reference}</Typography>
            </Grid>
            <Grid item style={{ textAlign: 'center', width: 80 }}>
              <Typography>{line.flowNumber}</Typography>
            </Grid>
            {/*<Grid item >*/}
            {/*  <Typography>&nbsp;</Typography>*/}
            {/*</Grid>*/}
            <Grid item style={{ textAlign: 'center', width: 60 }}>
              <Typography>{t(`orderline_type_${line.type}`)}</Typography>
            </Grid>
            <Grid item style={{ textAlign: 'center', width: 80 }}>
              <Typography>{line.quantity}</Typography>
            </Grid>
            <Grid item style={{ textAlign: 'right', width: 80 }}>
              <Typography>{formatEuro(line.unitPrice)}</Typography>
            </Grid>
            <Grid item style={{ textAlign: 'right', width: 80 }}>
              <Typography>{formatEuro(line.total)}</Typography>
            </Grid>
          </Grid>
          <Box marginTop={1} marginBottom={1}>
            <Divider />
          </Box>
        </React.Fragment>
      ))}
    </>
  );
};

export default OrderDetailTable;
