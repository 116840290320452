import { Grid, GridProps } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DateField } from './index';

type Props = {
  onSubmit: () => void;
  md?: GridProps['md'];
};

const FromToField: FC<Props> = ({ onSubmit, md = 3 }) => {
  const { t } = useTranslation();

  return (
    <>
      <Grid item xs={6} md={md}>
        <DateField
          name="from_date"
          placeholder={t('search_placeholder_date')}
          label={t('from_date')}
          onAccept={onSubmit}
          onBlur={onSubmit}
          isClearable
          onClear={onSubmit}
        />
      </Grid>
      <Grid item xs={6} md={md}>
        <DateField
          name="to_date"
          placeholder={t('search_placeholder_date')}
          label={t('to_date')}
          onAccept={onSubmit}
          onBlur={onSubmit}
          isClearable
          onClear={onSubmit}
        />
      </Grid>
    </>
  );
};

export default FromToField;
