import { NormalizedOrders, NormalizedOrdersPage, Orders } from '../../config/api/models/shop';
import normalizePagedResponse from '../../helpers/normalizePagedResponse';

type NormalizedPagedOrder = {
  orders: NormalizedOrders;
  ordersPage: NormalizedOrdersPage;
  ordersToProcess: NormalizedOrdersPage;
};

export const normalizePagedOrderRequest = (data: Orders): NormalizedPagedOrder => {
  const { normalizedData, normalizedPage } = normalizePagedResponse(data);

  return {
    orders: normalizedData,
    ordersPage: normalizedPage,
    ordersToProcess: normalizedPage,
  };
};
