import { Button } from '@mui/material';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Maybe } from '../../../../config/api/types';
import { RetourDetail } from '../../../../config/store/retour';
import { ConfirmRetourDialog } from './ConfirmRetourDialog';
import { ConfirmSuccessDialog } from './ConfirmSuccessDialog';

enum DialogType {
  None,
  Confirm,
  Success,
}

type Props = {
  unconfirmedRetour: Maybe<RetourDetail>;
};
export const ConfirmRetourButton: FC<Props> = ({ unconfirmedRetour }) => {
  const { t } = useTranslation();
  const [isOpen, setOpen] = useState<DialogType>(DialogType.None);

  const onOpen = (dialogType: DialogType) => () => {
    setOpen(dialogType);
  };

  return (
    <>
      {unconfirmedRetour && unconfirmedRetour.lines.length > 0 && (
        <Button onClick={onOpen(DialogType.Confirm)}>{t('return_confirm')}</Button>
      )}
      {isOpen === DialogType.Confirm && (
        <ConfirmRetourDialog
          onClose={onOpen(DialogType.None)}
          unconfirmedRetour={unconfirmedRetour}
          onSuccess={onOpen(DialogType.Success)}
        />
      )}
      {isOpen === DialogType.Success && <ConfirmSuccessDialog onClose={onOpen(DialogType.None)} />}
    </>
  );
};
