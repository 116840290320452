import { Box } from '@mui/material';
import { useFetch } from '@react-redux-fetch/hooks';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import DelayedLoader from '../../../../components/DelayedLoader';
import { SearchField } from '../../../../components/Form';
import TableFilterWrapper from '../../../../components/Table/TableFilterWrapper';
import useApiRoute from '../../../../config/api/useApiRoute';
import { getLink, updateQueryParameters } from '../../../../helpers/hateoas';
import useQueryParams from '../../../../helpers/useQueryParams';
import { getPagedBookstoresRequest } from '../api';
import { getPagedBookstores } from '../selectors';
import { BookstoresTable } from './BookstoresTable';

export const BookstoresTableWrapper = () => {
  const { t } = useTranslation();
  const apiRoute = updateQueryParameters(useApiRoute('bookstores') || '', { is_member: 'true' });
  const [, fetchBookstores] = useFetch(getPagedBookstoresRequest);

  useEffect(() => {
    if (apiRoute) {
      fetchBookstores(apiRoute);
    }
  }, [apiRoute, fetchBookstores]);

  const pagedBookstores = useSelector(getPagedBookstores);
  const url = getLink(pagedBookstores, 'self') || '';
  const { queryObj } = useQueryParams<{ q: string }>(url);

  const handleSearchTermChange = (term: string) => {
    fetchBookstores(
      updateQueryParameters(url, { name_or_membership_registration_number: term.trim(), page: 1 })
    );
  };

  return (
    <DelayedLoader data={pagedBookstores}>
      <TableFilterWrapper>
        <Box flex={1}>
          <SearchField
            placeholder={t('form_placeholder_bookstores_search')}
            searchCallback={handleSearchTermChange}
            initialValue={queryObj.q}
            isClearable
          />
        </Box>
      </TableFilterWrapper>
      <BookstoresTable bookstores={pagedBookstores} fetchBookstores={fetchBookstores} />
    </DelayedLoader>
  );
};
