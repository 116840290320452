import { Grid, Typography } from '@mui/material';
import { useFetch } from '@react-redux-fetch/hooks';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Button from '../../../../components/Button';
import DelayedLoader from '../../../../components/DelayedLoader';
import useApiRoute from '../../../../config/api/useApiRoute';
import { getLink } from '../../../../helpers/hateoas';
import ExportButton from '../../../core/exports';
import {
  getOrderLineStatusesRequest,
  getOrdersToProcessRequest,
  processOrderRequest,
} from '../../api';
import { getOrdersToProcess } from '../../selectors';
import LibraryOrder from './LibraryOrder';

const OrdersToProcessForBookstore = () => {
  const { t } = useTranslation();
  const ordersApiRoute = useApiRoute('elasticOrdersToBeProcessedForBookstore') || '';
  const statusesApiUrl = useApiRoute('orderLineStatus');

  const [, fetchOrders] = useFetch(getOrdersToProcessRequest);
  const [, processOrder] = useFetch(processOrderRequest);
  const [, fetchStatuses] = useFetch(getOrderLineStatusesRequest);

  const ordersToProcess = useSelector(getOrdersToProcess);

  const orders = ordersToProcess ? ordersToProcess._embedded.items : [];

  useEffect(() => {
    if (ordersApiRoute) {
      fetchOrders(ordersApiRoute);
    }
  }, [fetchOrders, ordersApiRoute]);

  useEffect(() => {
    if (statusesApiUrl) {
      fetchStatuses(statusesApiUrl);
    }
  }, [fetchStatuses, statusesApiUrl]);

  const handleRefetch = useCallback(() => {
    fetchOrders(ordersApiRoute);
  }, [fetchOrders, ordersApiRoute]);

  const handleProcessAll = () => {
    orders.forEach((order) => {
      const orderApiUrl = getLink(order, 'markOrderLinesProcessed') || '';
      if (orderApiUrl) {
        processOrder(orderApiUrl);
      }
    });
  };

  return (
    <DelayedLoader data={ordersToProcess}>
      <>
        <Grid
          container
          spacing={1}
          justifyContent={orders.length ? 'flex-end' : 'flex-start'}
          alignItems="center"
        >
          {orders.length ? (
            [
              <Grid item key="processAllButton">
                {
                  <Button size="small" onClick={handleProcessAll}>
                    {t('order_mark_all_complete')}
                  </Button>
                }
              </Grid>,
              <Grid item key="exportAllButton">
                {ordersApiRoute && (
                  <ExportButton url={ordersApiRoute} size="small">
                    {t('order_library_export_all')}
                  </ExportButton>
                )}
              </Grid>,
            ]
          ) : (
            <Grid item>
              <Typography>{t('order_none_to_process_bookstore')}</Typography>
            </Grid>
          )}
        </Grid>

        {orders.map((order) => (
          <LibraryOrder
            key={order.orderId}
            order={order}
            refetch={handleRefetch}
            canMarkProcessed={Boolean(getLink(order, 'markOrderLinesProcessed'))}
          />
        ))}
      </>
    </DelayedLoader>
  );
};

export default OrdersToProcessForBookstore;
