import { Box, Grid, MenuItem, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { FormRenderProps } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import Button from '../../../../../components/Button';
import TextField, { CheckBox } from '../../../../../components/Form';
import WhenFieldChanges from '../../../../../components/Form/WhenFieldChanges';
import FormSubTitle from '../FormSubTitle';
import FormTitle from '../FormTitle';

type Props = {
  onSubmit: FormRenderProps['handleSubmit'];
  disabled: boolean;
  showFormatField: boolean;
  showEmailField: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  distributorCode: {
    marginBottom: theme.spacing(2),
  },
  fundIcon: {
    opacity: 0.3,
    cursor: 'pointer',
    '&:hover': {
      opacity: 1,
    },
  },
  defaultFundIcon: {},
  cell: {
    paddingLeft: 0,
  },
}));

const DistributorFormFields = ({ onSubmit, disabled, showFormatField, showEmailField }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <form onSubmit={onSubmit}>
      <Grid container spacing={3}>
        <Grid item xs={4} md={2}>
          <div className={classes.distributorCode}>
            <TextField
              type="text"
              name="code"
              label={t('form_distributor_code')}
              variant="outlined"
              disabled
            />
          </div>
        </Grid>
      </Grid>

      <FormTitle title={t('organisation_edit_distributor_parameters_title')} />

      <FormSubTitle title={t('organisation_edit_distributor_orders_subtitle')} />

      <Box marginBottom={1}>
        <Grid container>
          <Grid item xs={4} md={2}>
            <TextField type="select" name="processingType" label={t('form_processingType')}>
              <MenuItem value="automatic">{t('form_processingType_automatic')}</MenuItem>
              <MenuItem value="manual">{t('form_processingType_manual')}</MenuItem>
            </TextField>

            {showFormatField && (
              <TextField type="select" name="orderFileFormat" label={t('form_orderFileFormat')}>
                <MenuItem> </MenuItem>
                <MenuItem value="csv">{t('form_orderFileFormat_csv')}</MenuItem>
                <MenuItem value="opdnaw">{t('form_orderFileFormat_opdnaw')}</MenuItem>
              </TextField>
            )}

            <Box marginTop={1} marginBottom={1}>
              <CheckBox name="wantsEmailNotifications" label={t('form_wants_email_notification')} />
            </Box>

            {showEmailField && (
              <TextField type="text" name="email" label={t('form_notification_email')} />
            )}
          </Grid>
        </Grid>

        <WhenFieldChanges
          field="processingType"
          set="orderFileFormat"
          to={(value: string) => {
            return null;
          }}
        />
        <WhenFieldChanges
          field="wantsEmailNotifications"
          set="email"
          to={(value: string) => {
            return null;
          }}
        />
      </Box>

      <Box marginBottom={1}>
        <FormSubTitle title={t('organisation_edit_distributor_return_subtitle')} />
        <Grid container spacing={1} direction="column">
          <Grid item xs={12} md={6}>
            <CheckBox name="canProcessReturns" label={t('form_canProcessReturns')} />
          </Grid>
        </Grid>
      </Box>

      <FormSubTitle title={t('organisation_edit_distributor_other_subtitle')} />
      <Grid container spacing={1} direction="column">
        <Grid item xs={12} md={6}>
          <CheckBox name="isCbMember" label={t('form_is_cb_member')} />
        </Grid>
        <Grid item xs={12} md={6}>
          <CheckBox
            name="usesRegulatedPriceAlgorithm"
            label={t('organisation_edit_regulated_price_algorithm')}
          />
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Button type="submit" disabled={disabled}>
            {t('form_action_save')}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default DistributorFormFields;
