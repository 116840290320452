import { Grid, InputLabel, Typography } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Button from '../../../../components/Button';
import { CheckBox } from '../../../../components/Form';
import { BillingType, Organisation, Prefix } from '../../../../config/api/types';
import useApiRoute from '../../../../config/api/useApiRoute';
import getIdFromUrl from '../../../../helpers/getIdFromUrl';
import { getLink, updateQueryParameter } from '../../../../helpers/hateoas';
import useFileExport from '../../../../helpers/useFileExport';
import { getBillingTypes } from '../../selectors';
import BillingTypeSelect from './BillingTypeSelect';
import ReservePrefixNumbersButton from './ReservePrefixNumbersButton';
import SelectNewOrganisation from './SelectNewOrganisation';

type Props = {
  prefix: Prefix;
  onSubmit: (values: Partial<Prefix>) => Promise<any>;
  returnPath: string;
};

export type PrefixFormValues = {
  billingType: BillingType['code'];
  billed: boolean;
  willNotBeBilled: boolean;
  active: boolean;
};

const PrefixForm = ({ prefix, onSubmit, returnPath }: Props) => {
  const { t } = useTranslation();
  const billingTypes = useSelector(getBillingTypes);
  const history = useHistory();
  const [showPublisherError, setShowPublisherError] = useState<boolean>(false);
  const [switchingOrg, setSwitchingOrg] = useState<boolean>(false);
  const canBeBilled = (billingType: BillingType['code']): boolean => billingType === 'FLEMISH_NEW';
  const canReserveNumbers = Boolean(
    prefix?.freeIsbnCount && prefix.freeIsbnCount > 0 && prefix.billingType.code !== 'N/A'
  );

  const isbnExportApiRoute = useApiRoute('isbnExport') || '';
  const isbnApiRoute = updateQueryParameter(isbnExportApiRoute, 'prefix', prefix.prefix);
  const [reservedExportInProgress, exportReserved] = useFileExport(
    updateQueryParameter(isbnApiRoute, 'type', 'reserved'),
    undefined,
    undefined,
    undefined,
    undefined,
    'exportReserved'
  );

  const handleRedirect = useCallback(() => {
    history.push(returnPath, { keepFilters: true });
  }, [returnPath, history]);

  const handleSubmit = (values: PrefixFormValues) => {
    const selectedType =
      (billingTypes && billingTypes.find((type) => type.code === values.billingType)) ||
      prefix.billingType;
    const editedPrefix: Partial<Prefix> = {
      prefix: prefix.prefix,
      organisationId: prefix.organisationId,
      ...values,
      billingType: selectedType,
    };
    if (!canBeBilled(selectedType.code)) {
      delete editedPrefix.billed;
    }
    return onSubmit(editedPrefix);
  };

  const savePublisher = async (organisation: Organisation) => {
    const editedPrefix = {
      ...prefix,
      organisationId: getIdFromUrl(getLink(organisation, 'self') || ''),
    };
    const result = await onSubmit(editedPrefix);
    if (result) {
      setShowPublisherError(true);
    }
  };

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={{
        billingType: prefix.billingType.code,
        billed: prefix.billed,
        willNotBeBilled: prefix.willNotBeBilled,
        active: prefix.active,
      }}
      keepDirtyOnReinitialize
      render={({ handleSubmit, values }) => (
        <form onSubmit={handleSubmit}>
          {switchingOrg ? (
            <SelectNewOrganisation
              onSubmit={savePublisher}
              onCancel={() => setSwitchingOrg(false)}
              publishersOnly
            />
          ) : (
            <Grid container spacing={3}>
              <Grid item xs={12} container direction="column" spacing={1}>
                <Grid container item alignItems="center">
                  <Grid item xs={5}>
                    <InputLabel>{t('prefix_name')}</InputLabel>
                  </Grid>
                  <Grid item xs={7} container alignItems="center" justifyContent="space-between">
                    <Grid item>
                      <Typography>{prefix.prefix}</Typography>
                    </Grid>
                    <Grid item>
                      {canReserveNumbers && (
                        <ReservePrefixNumbersButton prefix={prefix} onSuccess={handleRedirect} />
                      )}
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container item alignItems="center">
                  <Grid item xs={5}>
                    <InputLabel>{t('prefix_reserved_numbers')}</InputLabel>
                  </Grid>
                  <Grid item xs={7} container alignItems="center" justifyContent="space-between">
                    <Grid item>
                      <Typography>{prefix.reservedIsbnCount}</Typography>
                    </Grid>
                    {prefix.reservedIsbnCount > 0 && (
                      <Grid item>
                        <Button
                          size="small"
                          onClick={exportReserved}
                          disabled={reservedExportInProgress}
                        >
                          {t('isbn_prefix_reserved_numbers_export')}
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                </Grid>

                <Grid container item alignItems="center">
                  <Grid item xs={5}>
                    <InputLabel>{t('prefix_organisation')}</InputLabel>
                  </Grid>
                  <Grid item xs={7} container alignItems="center" justifyContent="space-between">
                    <Grid item>
                      <Typography>{prefix._embedded.organisation.name}</Typography>
                      {showPublisherError && (
                        <Typography color="error">{t('prefix_used_error')}</Typography>
                      )}
                    </Grid>
                    <Grid item>
                      <Button
                        size="small"
                        onClick={() => setSwitchingOrg(true)}
                        disabled={prefix.applicationIsbnCount > 0}
                      >
                        {t('prefix_change_organisation_button')}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container item alignItems="center">
                  <Grid item xs={5}>
                    <InputLabel>{t('prefix_billing_type')}</InputLabel>
                  </Grid>
                  <Grid item xs={7}>
                    <BillingTypeSelect loadingValue={prefix.billingType} />
                  </Grid>
                </Grid>

                {canBeBilled(values.billingType) && (
                  <Grid container item alignItems="center">
                    <Grid item xs={5}>
                      <InputLabel>{t('prefix_billed')}</InputLabel>
                    </Grid>
                    <Grid item xs={7}>
                      <CheckBox name="billed" size="small" />
                    </Grid>
                  </Grid>
                )}

                <Grid container item alignItems="center">
                  <Grid item xs={5}>
                    <InputLabel>{t('prefix_will_not_be_billed')}</InputLabel>
                  </Grid>
                  <Grid item xs={7}>
                    <CheckBox name="willNotBeBilled" size="small" />
                  </Grid>
                </Grid>

                <Grid container item alignItems="center">
                  <Grid item xs={5}>
                    <InputLabel>{t('prefix_active')}</InputLabel>
                  </Grid>
                  <Grid item xs={7}>
                    <CheckBox name="active" size="small" />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item container justifyContent="space-between">
                <Grid item>
                  <Button type="submit">{t('form_action_save')}</Button>
                </Grid>
              </Grid>
            </Grid>
          )}
        </form>
      )}
    />
  );
};

export default PrefixForm;
