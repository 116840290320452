import { Divider, FormControlLabel, Grid, Radio, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../../components/Button';
import DelayedLoader from '../../../../components/DelayedLoader';
import SubTitle from '../../../../components/SubTitle';
import { IsbnApplication, Maybe, Prefix, Prefixes } from '../../../../config/api/types';

type Props = {
  isbnApplication: IsbnApplication;
  prefixes: Maybe<Prefixes>;
  loadingPrefixes?: boolean;
  generatePrefix: () => void;
  prefix?: Prefix | null;
  onPrefixSelect: (prefix?: Prefix) => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  verticalPadding: {
    padding: '8px 0px',
  },
  selectedLabel: {
    color: theme.palette.primary.main,
  },
}));

const IsbnPrefixReview = ({
  isbnApplication,
  prefixes,
  loadingPrefixes,
  generatePrefix,
  prefix,
  onPrefixSelect,
}: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const matchedPrefix = prefixes?._embedded.items.find((p) => p.prefix === isbnApplication.prefix);

  useEffect(() => {
    if (matchedPrefix && !prefix) {
      onPrefixSelect(matchedPrefix);
    }
  }, [matchedPrefix, prefix, onPrefixSelect]);

  const handleSelectPrefix = (selectedPrefix: Prefix) => {
    if (prefix && selectedPrefix.prefix === prefix.prefix) {
      onPrefixSelect(undefined);
    } else {
      onPrefixSelect(selectedPrefix);
    }
  };

  return (
    <Grid container direction="column">
      <Grid item xs className={classes.verticalPadding}>
        <SubTitle>{t('isbn_application_chosen_prefix_title')}</SubTitle>
        <Divider />
      </Grid>

      {isbnApplication.prefix && (
        <Grid item xs className={classes.verticalPadding}>
          {isbnApplication.prefix}
        </Grid>
      )}

      {isbnApplication.requestIsbnNumbers && (
        <Grid item xs className={classes.verticalPadding}>
          {isbnApplication.requestIsbnNumbers === 'use_existing_prefix'
            ? t('isbn_use_existing_prefix')
            : t('isbn_requested_numbers', {
                count: +isbnApplication.requestIsbnNumbers,
              })}
        </Grid>
      )}

      <Grid item xs className={classes.verticalPadding}>
        <SubTitle>{t('isbn_available_prefixes_title')}</SubTitle>
        <Divider />
      </Grid>

      <Grid
        item
        container
        className={classes.verticalPadding}
        sx={{ justifyContent: 'space-between', alignItems: 'flex-start' }}
      >
        <Grid item>
          {isbnApplication.publisherOrganisationId ? (
            <Grid item xs container direction="column">
              <DelayedLoader data={!loadingPrefixes}>
                {prefixes
                  ? prefixes._embedded.items
                      .filter(
                        (p) =>
                          p.remainingIsbnCount ||
                          (p.applicationIsbnCount && p.prefix === isbnApplication.prefix)
                      )
                      .map((p) => (
                        <Grid item key={p.prefix}>
                          <FormControlLabel
                            control={
                              <Radio
                                size="small"
                                value={p.prefix}
                                color="primary"
                                checked={p.prefix === prefix?.prefix}
                              />
                            }
                            label={t('form_isbn_prefix_radio_label', {
                              prefix: p.prefix,
                              amount: p.remainingIsbnCount,
                            })}
                            className={p.prefix === prefix?.prefix ? classes.selectedLabel : ''}
                            onMouseUp={() => handleSelectPrefix(p)}
                          />
                        </Grid>
                      ))
                  : t('isbn_no_available_prefixes')}
              </DelayedLoader>
            </Grid>
          ) : (
            <Typography>{t('isbn_prefix_no_organisation')}</Typography>
          )}
        </Grid>

        <Grid item>
          <Button disabled={!isbnApplication.publisherOrganisationId} onClick={generatePrefix}>
            {t('isbn_generate_prefix')}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default IsbnPrefixReview;
