import { Box, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import React, { useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../../components/Button';
import { TitleFull } from '../../../../config/api/types';
import Step1 from './Step1';
import Step2 from './Step2';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onSelectTitle: (title: TitleFull) => void;
};

type StepState = 'searchTitle' | 'editTitle';

const stepsReducer = (state: StepState, action: { type: 'next' | 'prev' }): StepState => {
  if (state === 'searchTitle') {
    switch (action.type) {
      case 'next':
        return 'editTitle';
      default:
        return 'searchTitle';
    }
  } else {
    switch (action.type) {
      case 'prev':
        return 'searchTitle';
      default:
        return 'editTitle';
    }
  }
};

export const SearchTitleDialog = ({ isOpen, onClose, onSelectTitle }: Props) => {
  const { t } = useTranslation();
  const [stepState, dispatch] = useReducer(stepsReducer, 'searchTitle');

  const handleClickCancel = () => {
    dispatch({ type: 'prev' });
    onClose();
  };

  return (
    <Dialog open={isOpen}>
      <DialogTitle>{t('fetch_from_existing_gtin13')}</DialogTitle>

      <DialogContent>
        <Box mt={2}>
          {stepState === 'searchTitle' && <Step1 onNext={() => dispatch({ type: 'next' })} />}
          {stepState === 'editTitle' && <Step2 onSubmit={onSelectTitle} />}
        </Box>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClickCancel} variant="text">
          {t('form_cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
