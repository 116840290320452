import { Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../../components/Button';
import Multiline from '../../../../components/Multiline';
import PageTitle from '../../../../components/PageTitle';
import useApiRoute from '../../../../config/api/useApiRoute';
import { updateQueryParameters } from '../../../../helpers/hateoas';
import useFileExport from '../../../../helpers/useFileExport';
import PrefixTableWrapper from '../containers/PrefixTableWrapper';

const PrefixOverview = () => {
  const { t } = useTranslation();
  const [dialogOpen, setDialogOpen] = useState<undefined | 'prefix' | 'isbn'>(undefined);

  const prefixBaseUrl = useApiRoute('prefixes') || '';
  const prefixUrl = updateQueryParameters(prefixBaseUrl, {
    billed: 'false',
    will_not_be_billed: 'false',
    billing_type: 'FLEMISH_NEW',
    backup: 'true',
  });

  const isbnBaseUrl = useApiRoute('isbnExport') || '';
  const isbnUrl = updateQueryParameters(isbnBaseUrl, {
    type: 'unbilled',
    backup: 'true',
  });

  const [prefixExportInProgress, initiatePrefixExport] = useFileExport(prefixUrl);
  const [isbnExportInProgress, initiateIsbnExport] = useFileExport(isbnUrl);

  const exportInProgress = prefixExportInProgress || isbnExportInProgress;

  const handleExport = () => {
    switch (dialogOpen) {
      case 'prefix':
        initiatePrefixExport();
        break;
      case 'isbn':
        initiateIsbnExport();
        break;
    }
    setDialogOpen(undefined);
  };

  return (
    <>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <PageTitle>{t('prefix_overview')}</PageTitle>
        </Grid>

        <Grid item>
          <Grid container spacing={1}>
            <Grid item>
              <Button
                size="small"
                disabled={exportInProgress}
                onClick={() => setDialogOpen('isbn')}
              >
                {t('isbn_billing_export')}
              </Button>
            </Grid>
            <Grid item>
              <Button
                size="small"
                disabled={exportInProgress}
                onClick={() => setDialogOpen('prefix')}
              >
                {t('prefix_billing_export')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <PrefixTableWrapper />

      {dialogOpen && (
        <Dialog open={Boolean(dialogOpen)} onClose={() => setDialogOpen(undefined)}>
          <DialogTitle>{t(`${dialogOpen}_billing_export`)}</DialogTitle>
          <DialogContent>
            <Multiline>{t(`${dialogOpen}_billing_export_dialog`)}</Multiline>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleExport}>{t('form_confirm_agree')}</Button>
            <Button onClick={() => setDialogOpen(undefined)}>{t('form_cancel')}</Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default PrefixOverview;
