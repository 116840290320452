import { Theme } from '@mui/material';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import React, { ReactNode } from 'react';
import { ReactComponent as BoekenbankLogo } from '../../../config/assets/pictures/boekenbank_logo2.svg';

type Props = {
  children?: ReactNode;
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    height: '100vh',
    marginTop: `calc(-${theme.toolbar.height}px - 48px)`,
  },
  imgWrapper: {
    textAlign: 'center',
    '& > *': {
      width: 200,
    },
  },
}));

const SmallCenterLayout = ({ children }: Props) => {
  const classes = useStyles();

  return (
    <Grid
      container
      sx={{ justifyContent: 'center', alignItems: 'center' }}
      className={classes.container}
    >
      <Grid xs={10} sm={6} md={4} lg={3} item>
        <div className={classes.imgWrapper}>
          <BoekenbankLogo />
        </div>
        {children}
      </Grid>
    </Grid>
  );
};

export default SmallCenterLayout;
