import { Paged } from '../config/api/types';

// @ts-ignore
const defaultGetKey = <T>(item: T) => item?._links?.self?.href;

type ReturnValue<T, K extends string> = {
  normalizedData: Record<K, T> | {};
  normalizedPage: Paged<K>;
};

const normalizePagedResponse = <T, K extends string>(
  data: Paged<T>,
  getKey: (item: T) => K = defaultGetKey
): ReturnValue<T, K> => {
  const normalizedData = data._embedded.items.reduce((agg, item) => {
    return {
      ...agg,
      [getKey(item)]: item,
    };
  }, {});

  const dataKeys = data._embedded.items.map((item) => getKey(item));

  return {
    normalizedData,
    normalizedPage: {
      ...data,
      _embedded: {
        items: dataKeys,
      },
    },
  };
};

export default normalizePagedResponse;
