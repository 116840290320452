import { StyledEngineProvider, Theme, ThemeProvider } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { StylesProvider } from '@mui/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import * as Sentry from '@sentry/react';
import 'moment/locale/nl';
import React from 'react';
import 'react-app-polyfill/ie11';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import ErrorBoundary from './components/ErrorBoundary';
import './config/i18n';
import './config/sentry/initSentry';
import initReduxFetch from './config/store/initReduxFetch';
import configureStore from './config/store/storeConfig';
import theme from './config/theme';
import app from './packages/app';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const [store, persistor] = configureStore(app.rootReducer, [...app.middleware]);

initReduxFetch();

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  // Material-ui and react-final-form are not ready for strict mode
  // <React.StrictMode>
  <Sentry.ErrorBoundary
    fallback={<>'Oeps, er is iets fout gelopen. Probeer deze pagina opnieuw te laden.'</>}
  >
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <StylesProvider injectFirst>
          <LocalizationProvider
            dateAdapter={AdapterMoment}
            adapterLocale="nl"
            localeText={{
              fieldDayPlaceholder: () => '__',
              fieldMonthPlaceholder: () => '__',
              fieldYearPlaceholder: () => '____',
            }}
          >
            <CssBaseline />
            <ErrorBoundary>
              <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                  <app.containers.App />
                </PersistGate>
              </Provider>
            </ErrorBoundary>
          </LocalizationProvider>
        </StylesProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  </Sentry.ErrorBoundary>
  // </React.StrictMode>,
);
