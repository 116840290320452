import { Table, TableBody, TableCell, TableRow, Theme } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import { makeStyles } from '@mui/styles';
import { useFetch } from '@react-redux-fetch/hooks';
import { FC, default as React, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import DelayedLoader from '../../../components/DelayedLoader';
import { getCountryNameByShortName } from '../../../config/data/countries';
import { getOrganisationRequest } from '../api';
import { formatAddress } from '../domain';
import { getOrganisationByRef } from '../selectors';

type Props = {
  organisationUrl: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  table: {
    '& td': {
      borderBottom: 'none',
    },
    '& tr': {
      height: theme.spacing(2),
    },
    '& tr > td:first-child': {
      fontWeight: theme.typography.fontWeightMedium,
      width: 120,
      paddingRight: 10,
    },
  },
}));

const OrganisationDialogContent: FC<Props> = ({ organisationUrl }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [, fetchOrganisation] = useFetch(getOrganisationRequest);
  const organisation = useSelector(getOrganisationByRef(organisationUrl));

  useEffect(() => {
    fetchOrganisation(organisationUrl);
  }, [organisationUrl, fetchOrganisation]);

  return (
    <>
      {organisation ? (
        <Table className={classes.table}>
          <TableBody>
            <TableRow>
              <TableCell>{t('form_name')}</TableCell>
              <TableCell>{organisation.name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{t('form_address')}</TableCell>
              <TableCell>{formatAddress(organisation)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{t('form_country_iso')}</TableCell>
              <TableCell>{getCountryNameByShortName(organisation.countryIso)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{t('form_phone_number')}</TableCell>
              <TableCell>{organisation.phoneNumber}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{t('form_email')}</TableCell>
              <TableCell>{organisation.email}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{t('form_url')}</TableCell>
              <TableCell>
                <a href={organisation.url} target="_blank" rel="noopener noreferrer">
                  {organisation.url}
                </a>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{t('form_vat_number')}</TableCell>
              <TableCell>{organisation.companyRegistrationNumber}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      ) : (
        <DialogContent>
          <DelayedLoader data={organisation} />
        </DialogContent>
      )}
    </>
  );
};

export default OrganisationDialogContent;
