import React, { FC, useCallback } from 'react';
import ExpandableList from '../../../../../../../components/ExpandableList';
import { Contributor } from '../../../../../../../config/api/types';
import { FormValues } from '../../../domain';
import DraggableContributorField from './DraggableContributorField';

type Props = {
  formContributors: Contributor[];
  onChange: (name: keyof FormValues, value?: any) => void;
  isBibliographicalVerified: boolean;
};
const ContributorsField: FC<Props> = ({
  formContributors,
  onChange,
  isBibliographicalVerified,
}) => {
  const moveContributor = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const dragContributor = formContributors[dragIndex];
      let copyContributors = [...formContributors];
      copyContributors.splice(dragIndex, 1);
      copyContributors.splice(hoverIndex, 0, dragContributor);

      const updatedContributors = copyContributors.map((c, index) => {
        return {
          ...c,
          sequenceNumber: index + 1,
        };
      });

      onChange('contributors', updatedContributors);
    },
    [formContributors, onChange]
  );

  const removeContributor = useCallback(
    (index: number) => {
      let copyContributors = [...formContributors];
      copyContributors.splice(index, 1);
      onChange('contributors', copyContributors);
    },
    [formContributors, onChange]
  );

  const toggleContributor = useCallback(
    (index: number, isCorporate: boolean) => {
      const copyContributors = formContributors.map((c, cIndex) => {
        if (index !== cIndex) return c;

        return {
          ...c,
          ...(isCorporate
            ? { firstName: undefined, lastName: undefined, corporateName: '' }
            : { corporateName: undefined, firstName: '', lastName: '' }),
        };
      });
      onChange('contributors', copyContributors);
    },
    [formContributors, onChange]
  );

  const hasNewItems = formContributors.filter((c) => !Boolean(c.titleId))?.length;

  return (
    <ExpandableList
      initialLength={hasNewItems > 0 ? formContributors.length : 10}
      increaseBy={formContributors.length}
      renderListItem={(contributor, index) => {
        return (
          <DraggableContributorField
            contributor={contributor}
            key={contributor.titleContributorId}
            index={index}
            onMove={moveContributor}
            onRemove={removeContributor}
            onToggleContributor={toggleContributor}
            isBibliographicalVerified={isBibliographicalVerified}
          />
        );
      }}
      items={formContributors}
    />
  );
};

export default ContributorsField;
