import Error from '@mui/icons-material/Error';
import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';

type Props = {
  score: number;
  hasErrors?: boolean;
};

const size = 20;

const useStyles = makeStyles((theme: Theme) => ({
  scoreRoot: {
    width: size,
    height: size,
    display: 'inline-block',
    borderRadius: '50%',
    position: 'relative',
    overflow: 'hidden',
    background: 'white',
    border: `1px solid ${theme.palette.messages.success}`,
  },
  scoreFill: (props: Props) => ({
    position: 'absolute',
    bottom: 0,
    width: size,
    backgroundColor: theme.palette.messages.success,
    height: `${props.score}%`,
  }),
}));

const Score = ({ score, hasErrors }: Props) => {
  const classes = useStyles({ score, hasErrors });

  if (hasErrors) {
    return <Error color="error" />;
  }

  return (
    <div className={classes.scoreRoot}>
      <div className={classes.scoreFill} />
    </div>
  );
};

export default Score;
