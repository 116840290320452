import React from 'react';
import { RouteComponentProps } from 'react-router';
import ConstrainedPageContent from '../../../components/ConstrainedPageContent';
import OrdersHistoryLines from '../components/ordersHistoryLines/OrdersHistoryLines';
import { OrdersHistoryHeader } from './OrdersConfirmedPage';

type Props = RouteComponentProps;

const OrdersLinesConfirmedPage = (props: Props) => {
  return (
    <ConstrainedPageContent center={false}>
      <OrdersHistoryHeader {...props} />
      <OrdersHistoryLines />
    </ConstrainedPageContent>
  );
};

export default OrdersLinesConfirmedPage;
