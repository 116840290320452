import { FetchConfig } from '@react-redux-fetch/core';
import { Data } from '../../../../config/store/types';
import apiRoutes from '../../../../config/api/routes';

type ApiRetourReason = { label: string; shortLabel: string };

export const getRetourReasonsRequest = (url: string): FetchConfig<Data> => {
  return {
    method: 'GET',
    url: apiRoutes.create(url),
    repository: {
      retourReasons: (
        prev,
        next: Record<string, ApiRetourReason>
      ): { value: number; label: string; shortLabel: string }[] => {
        return Object.keys(next).map((reasonId) => ({
          value: parseInt(reasonId),
          label: next[reasonId].label,
          shortLabel: next[reasonId].shortLabel,
        }));
      },
    },
  };
};
