import { Box, Table, TableBody, Tooltip, Typography } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import SubTitle from '../../../../components/SubTitle';
import TableHead, { HeaderRowType } from '../../../../components/Table/TableHead';
import TableRow from '../../../../components/Table/TableRow';
import { CellType, DataType } from '../../../../components/Table/types';
import { RetourLine } from '../../../../config/store/retour';
import getIdFromUrl from '../../../../helpers/getIdFromUrl';
import { IsbnLink } from '../../../title';
import { canBackorder, canReturn, canReturnDefaultYes } from '../../domain';
import { DeliveryNoteIcon } from '../../retourDetail/DeliveryNoteIcon';
import { DeleteRetourLineButton } from './DeleteRetourLineButton';

type Props = {
  retourLines: RetourLine[];
};

export const UnconfirmedRetourLinesForDistributor: FC<Props> = ({ retourLines }) => {
  const { t } = useTranslation();

  const headers: HeaderRowType = [
    { id: 'gtin13', label: t('title_gtin13'), width: 114 },
    { id: 'title', label: t('table_headers_title'), width: 140 },
    { id: 'qty', label: t('table_headers_num_pieces'), width: 80, align: 'center' },
    { id: 'reason', label: t('form_retourReason') },
    { id: 'backorder', label: t('table_headers_additional_delivery'), width: 110, align: 'center' },
    { id: 'articleReturn', label: t('table_headers_article_return'), width: 110, align: 'center' },
    { id: 'line_ref', label: t('return_reference') },
    { id: 'comments', label: t('return_comments'), width: 80 },
    { id: 'delete', label: '' },
  ];

  const data: DataType = {
    resource: 'retourLines',
    rows: retourLines.map((retourLine) => ({
      id: retourLine.id,
      cells: [
        {
          type: CellType.Text,
          data: (
            <div style={{ width: 114 }}>
              <IsbnLink
                orderLine={{
                  titleId: getIdFromUrl(retourLine._embedded.title._links.self.href),
                  _embedded: {
                    title: retourLine._embedded.title,
                  },
                }}
              />
            </div>
          ),
        },
        {
          type: CellType.Text,
          data: (
            <Tooltip
              placement="top-start"
              title={retourLine._embedded.title.title}
              enterDelay={500}
            >
              <Typography style={{ width: 140 }} noWrap>
                {retourLine._embedded.title.title}
              </Typography>
            </Tooltip>
          ),
        },
        {
          type: CellType.Text,
          data: <Typography align="center">{retourLine.quantity}</Typography>,
        },
        {
          type: CellType.Text,
          data: <>{retourLine._embedded.retourReasons[retourLine.reason]?.shortLabel}</>,
        },
        {
          type: CellType.Text,
          data: (
            <Typography align="center">
              {canBackorder(parseInt(retourLine.reason))
                ? t(retourLine.backorder ? 'yes' : 'no')
                : t('na')}
            </Typography>
          ),
        },
        {
          type: CellType.Text,
          data: (
            <Typography align="center">
              {canReturn(parseInt(retourLine.reason))
                ? t(
                    retourLine.articleReturn || canReturnDefaultYes(parseInt(retourLine.reason))
                      ? 'yes'
                      : 'no'
                  )
                : t('na')}
            </Typography>
          ),
        },
        {
          type: CellType.Text,
          data: (
            <Tooltip placement="top-start" title={retourLine.lineReference || ''} enterDelay={500}>
              <Typography noWrap>{retourLine.lineReference}</Typography>
            </Tooltip>
          ),
        },
        {
          type: CellType.Text,
          data: retourLine.comments ? (
            <div style={{ width: 80, textAlign: 'center' }}>
              <DeliveryNoteIcon note={retourLine.comments} />
            </div>
          ) : (
            ''
          ),
        },
        {
          type: CellType.Action,
          data: <DeleteRetourLineButton retourLine={retourLine} />,
        },
      ],
    })),
  };

  return (
    <>
      <Box mb={2} mt={4}>
        <SubTitle>
          {t(retourLines[0]._embedded.distributor.isCbMember ? 'return_at_cb' : 'return_at')}{' '}
          <strong>{retourLines[0]._embedded.organisation.name}</strong>
        </SubTitle>
      </Box>
      <Table>
        <TableHead headers={headers} />
        <TableBody>
          {data.rows.map((row: any, index: number) => (
            <TableRow
              key={`row${row.id + index}`}
              row={row.cells}
              link={row.link}
              variant={row.variant}
            />
          ))}
        </TableBody>
      </Table>
    </>
  );
};
