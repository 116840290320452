import { gtin13Fields } from '../../domain';
import React from 'react';

type Props = {
  [key: string]: any;
  name: keyof { [key in typeof gtin13Fields[number]]: true };
};

const TitleISBNField = ({ component: Component, ...other }: Props) => <Component {...other} />;

export default TitleISBNField;
