import { ShoppingCart, ShoppingCartLine } from '../../../config/api/models/shop';
import { UpdateShoppingCartLineRequest } from '../../../config/api/types';
import { replaceInArray } from '../../../helpers/replaceInArray';

type LineUpdater = { found: boolean; lines: ShoppingCartLine[] };

export const updateCartQuantity = (data: UpdateShoppingCartLineRequest, url: string) => {
  const lineUpdater = createLineUpdater(data, url);

  return (prev: ShoppingCart): ShoppingCart => {
    const linesByUser = prev.linesByUser;

    const nextLinesByDistributor = prev.linesByDistributor?.map((linesByDist) => {
      if (!linesByDist.lines) return linesByDist;
      const { found, lines } = lineUpdater(linesByDist.lines);
      if (!found) return linesByDist;

      const resaleValue = lines.reduce((total, line) => total + line.total, 0);

      return {
        ...linesByDist,
        lines,
        resaleValue,
      };
    });
    const nextLinesByUser =
      linesByUser &&
      Object.keys(linesByUser).reduce((allLinesByUser, key) => {
        const userLines = linesByUser[key];
        if (!userLines.lines) return allLinesByUser;
        const { found, lines } = lineUpdater(userLines.lines);
        if (!found) return allLinesByUser;

        return {
          ...allLinesByUser,
          [key]: {
            ...userLines,
            lines,
          },
        };
      }, prev.linesByUser);

    const updatedCart = {
      ...prev,
      linesByDistributor: nextLinesByDistributor,
      linesByUser: nextLinesByUser,
    };

    return recalculateTotals(updatedCart);
  };
};

export const removeLineFromCart = (cartLineUrl: string) => (prev: ShoppingCart): ShoppingCart => {
  const updatedCart = {
    ...prev,
    linesByDistributor: prev.linesByDistributor
      ?.map((line) => ({
        ...line,
        lines: line.lines?.filter(
          (cartLine) => cartLine._links.shoppingCartLine?.href !== cartLineUrl
        ),
      }))
      .filter((line) => line.lines?.length),

    linesByUser:
      prev.linesByUser &&
      Object.keys(prev.linesByUser).reduce((lines, key) => {
        const line = prev.linesByUser?.[key];

        const newCartLines =
          line?.lines?.filter(
            (cartLine) => cartLine._links.shoppingCartLine?.href !== cartLineUrl
          ) || [];

        if (!newCartLines.length) {
          return lines;
        }

        return {
          ...lines,
          [key]: {
            ...line,
            lines: newCartLines,
          },
        };
      }, {}),
  };

  return recalculateTotals(updatedCart);
};

function recalculateTotals(cart: ShoppingCart): ShoppingCart {
  const updatedLinesByDistributor = cart.linesByDistributor?.map((linesByDistributor) => {
    return {
      ...linesByDistributor,
      resaleValue: (linesByDistributor.lines || []).reduce((cartLineTotal, line) => {
        return cartLineTotal + line.total;
      }, 0),
    };
  });

  const recalculatedLinesByDistributorTotal =
    updatedLinesByDistributor?.reduce((total, lines) => {
      return total + lines.resaleValue;
    }, 0) || 0;

  const updatedLinesByUser =
    cart.linesByUser &&
    Object.keys(cart.linesByUser).reduce((allLinesByUser, key) => {
      const distLine = cart.linesByUser?.[key];
      if (!distLine?.lines) return allLinesByUser;

      return {
        ...allLinesByUser,
        [key]: {
          ...distLine,
          resaleValue: (distLine.lines || []).reduce((cartLineTotal, line) => {
            return cartLineTotal + line.total;
          }, 0),
        },
      };
    }, cart.linesByUser);

  const recalculatedLinesByUserTotal = updatedLinesByUser
    ? Object.values(updatedLinesByUser).reduce((total, line) => {
        return total + line.resaleValue;
      }, 0)
    : 0;

  const resaleValue = recalculatedLinesByDistributorTotal + recalculatedLinesByUserTotal;

  return {
    ...cart,
    linesByDistributor: updatedLinesByDistributor,
    linesByUser: updatedLinesByUser,
    resaleValue,
  };
}

function createLineUpdater(data: UpdateShoppingCartLineRequest, url: string) {
  return (lines: ShoppingCartLine[]): LineUpdater => {
    const index = lines.findIndex((line) => line._links.changeOrderLineStatus.href === url);
    if (typeof index === 'undefined') return { found: false, lines };

    const updatedLine = {
      ...lines[index],
      quantity: data.quantity,
      reference: data.reference,
      total: data.quantity * lines[index]?.unitPrice,
    };

    return { found: true, lines: replaceInArray(lines, index, updatedLine) };
  };
}
