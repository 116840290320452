import { Box, Grid } from '@mui/material';
import { FormApi } from 'final-form';
import omit from 'lodash/omit';
import { isMoment } from 'moment';
import React, { FC, ReactNode, useState } from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import Button from '../../../../components/Button';
import { TextField } from '../../../../components/Form';
import AutoSave from '../../../../components/Form/AutoSave';
import FromToField from '../../../../components/Form/FromToField';
import { API_DATE_FORMAT } from '../../../../helpers/date';
import { retrieveAllQueryParameters, setQueryParameters } from '../../../../helpers/hateoas';
import ExportButton from '../../../core/exports';

type Props = {
  fetchOrders: (url: string) => void;
  url: string;
  numResults?: number;
  role?: 'library';
  withFreeText?: boolean;
  withExportButton?: boolean;
  offsetExportButton?: boolean;
  exportButtonLabel?: string;
  children: ({ form, values }: { form: FormApi; values: Record<string, string> }) => JSX.Element;
  extraFormFields?: ({
    form,
    values,
  }: {
    form: FormApi<Record<string, string | boolean>>;
    values: Record<string, string>;
  }) => ReactNode;
};

const OrdersHistoryForm: FC<Props> = ({
  fetchOrders,
  url,
  children,
  numResults,
  role,
  withFreeText = true,
  withExportButton = true,
  offsetExportButton = true,
  exportButtonLabel,
  extraFormFields,
}) => {
  const { t } = useTranslation();
  const initialValues = retrieveAllQueryParameters(url);
  const [currentUrl, setCurrentUrl] = useState<string>(url);

  return (
    <>
      {withExportButton && (
        <Grid container justifyContent="flex-end">
          <Grid item style={{ marginTop: offsetExportButton ? -60 : 0 }}>
            <ExportButton url={currentUrl} size="small" disabled={!numResults}>
              {exportButtonLabel ||
                t(role === 'library' ? 'order_library_multiple_export' : 'order_multiple_export')}
            </ExportButton>
          </Grid>
        </Grid>
      )}
      <Box marginTop={2} marginBottom={2}>
        <Form
          onSubmit={(values) => {
            const dateFrom = isMoment(values.from_date)
              ? values.from_date.format(API_DATE_FORMAT)
              : values.from_date;
            const dateTo = isMoment(values.to_date)
              ? values.to_date.format(API_DATE_FORMAT)
              : values.to_date;

            const urlWithParams = setQueryParameters(currentUrl, {
              ...omit(values, ['distributor']),
              from_date: dateFrom || '',
              to_date: dateTo || '',
              page: 1,
              reference: values.reference.replaceAll(' ', '%20'),
              // @ts-ignore
              distributor_id: values.distributor?.distributorId,
              // @ts-ignore
              bookstore_id: values.bookstore?.bookstoreId,
              library_id: values.libraryId,
              to_process: values.to_process,
            });

            setCurrentUrl(urlWithParams.replace(/\s+/g, ''));
            if (url !== urlWithParams.replace(/\s+/g, '')) {
              // Only fetch when url changed
              fetchOrders(urlWithParams.replace(/\s+/g, ''));
            }
          }}
          initialValues={initialValues}
          keepDirtyOnReinitialize={true}
        >
          {({ handleSubmit, form, values }) => (
            <form onSubmit={handleSubmit}>
              <AutoSave save={form.submit} />
              <Grid container spacing={1}>
                <Grid xs={6} item>
                  {withFreeText && (
                    <TextField
                      name="q"
                      label={t('form_gtin13_search')}
                      placeholder={t('form_placeholder_gtin13_search')}
                      autoComplete="off"
                      isClearable
                      onClear={form.submit}
                    />
                  )}
                </Grid>
                <Grid xs={6} item>
                  {typeof children === 'function' ? children({ form, values }) : children}
                </Grid>
                <Grid xs={12} md={6} item>
                  <TextField
                    name="reference"
                    label={t(
                      role === 'library'
                        ? 'form_placeholder_library_reference_search'
                        : 'form_placeholder_reference_search'
                    )}
                    placeholder={t(
                      role === 'library'
                        ? 'form_placeholder_library_reference_search'
                        : 'form_placeholder_reference_search'
                    )}
                    autoComplete="off"
                    isClearable
                    onClear={form.submit}
                  />
                </Grid>
                <FromToField onSubmit={form.submit} />
              </Grid>
              {extraFormFields?.({ form, values })}
              <Button type="submit" style={{ display: 'none' }}>
                {t('form_action_save')}
              </Button>
            </form>
          )}
        </Form>
      </Box>
    </>
  );
};

export default OrdersHistoryForm;
