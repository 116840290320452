import PersonIcon from '@mui/icons-material/PersonOutline';
import { Grid, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import InfoTooltip from '../../../../components/InfoTooltip';
import Table from '../../../../components/Table';
import { CellType, DataType } from '../../../../components/Table/types';
import { IsbnApplications, Maybe } from '../../../../config/api/types';
import ROUTE_KEY from '../../../../config/routes/routeKeys';
import usePath from '../../../../config/routes/usePath';
import getIdFromUrl from '../../../../helpers/getIdFromUrl';
import { getLink } from '../../../../helpers/hateoas';

type Props = {
  isbnApplications: Maybe<IsbnApplications>;
  dispatchFn: (url: string) => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  doubleLineCell: {
    margin: '3px 0px',
  },
  iconColumn: {
    textAlign: 'end',
  },
}));

const IsbnApplicationTable = ({ isbnApplications, dispatchFn }: Props) => {
  const path = usePath(ROUTE_KEY.ADMIN_ISBN_APPLICATION);
  const { t } = useTranslation();
  const classes = useStyles();
  const headers = [
    { id: 'date/distributor', label: t('table_headers_date_publisher') },
    { id: 'anonymous', label: '' },
    { id: 'contactPerson', label: t('table_headers_contact_person') },
    { id: 'prefix', label: t('table_headers_prefix') },
    { id: 'onHoldReason', label: '' },
    { id: 'publication', label: t('table_headers_publication') },
  ];

  if (!isbnApplications) {
    return null;
  } else if (isbnApplications._embedded.items.length < 1) {
    return <>{t('isbn_application_empty_list')}</>;
  }

  const doubleLineCell = (firstLine: string, secondLine: string) => (
    <Grid container direction="column" className={classes.doubleLineCell}>
      <Grid item>{firstLine}</Grid>
      <Grid item>{secondLine}</Grid>
    </Grid>
  );

  const data: DataType = {
    resource: 'isbnApplications',
    rows: isbnApplications._embedded.items.map((application) => ({
      id: getLink(application, 'self') || '',
      link: path.replace(':id', getIdFromUrl(getLink(application, 'self') || '')),
      variant: application.state === 'on_hold' ? 'red' : undefined,
      cells: [
        {
          type: CellType.Text,
          data: doubleLineCell(
            moment(application.createdAt).format('DD-MM-YYYY HH:mm'),
            application.organisation.name
          ),
        },
        {
          type: CellType.Text,
          data: application.anonymous ? (
            <div className={classes.iconColumn}>
              <PersonIcon />
            </div>
          ) : undefined,
        },
        {
          type: CellType.Text,
          data: doubleLineCell(
            application.login.firstName
              ? `${application.login.firstName} ${application.login.lastName}`
              : application.login.lastName,
            `${application.login.email}`
          ),
        },
        {
          type: CellType.Text,
          data:
            application.prefix ||
            (application.requestIsbnNumbers === 'use_existing_prefix'
              ? t('use_existing_prefix')
              : t('requested_numbers', {
                  count: +application.requestIsbnNumbers,
                })),
        },
        {
          type: CellType.Text,
          data:
            application.state === 'on_hold' && application.onHoldComment ? (
              <div style={{ position: 'relative' }}>
                <InfoTooltip title={application.onHoldComment} />
              </div>
            ) : undefined,
        },
        {
          type: CellType.Text,
          data: doubleLineCell(
            application.title.productForm.shortLabel || application.title.productForm.label,
            application.title.title
          ),
        },
      ],
    })),
  };

  return (
    <>
      <Table
        headers={headers}
        data={data}
        page={isbnApplications.page}
        pages={isbnApplications.pages}
        total={isbnApplications.total}
        limit={isbnApplications.limit}
        pagedResource={isbnApplications}
        dispatchFn={dispatchFn}
        hidePagination={isbnApplications.limit >= isbnApplications.total}
      />
    </>
  );
};

export default IsbnApplicationTable;
