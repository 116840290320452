import React, { FC } from 'react';
import { FieldRenderProps } from 'react-final-form';
import SearchableTreeSelect from './SearchableTreeSelect';
import { useThemaQualifiers } from '../../../../../thema';
import { getThemaQualifiers } from '../../../../domain';

export const ThemaQualifiersFieldAdapter: FC<FieldRenderProps<any>> = ({
  input: { name, onChange, value },
  label,
}) => {
  const { themaQualifiers, hasAccess } = useThemaQualifiers();

  if (!hasAccess) return null;

  return (
    <SearchableTreeSelect
      value={getThemaQualifiers({ themaQualifiers: value })}
      treeData={themaQualifiers}
      onSelect={onChange}
      label={label}
    />
  );
};
