import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Header from '../../../components/Header';
import { useParams } from 'react-router-dom';
import OrderFileResultTable from '../components/orderFiles/OrderFileResultTable';
import useApiRoute from '../../../config/api/useApiRoute';
import { useFetch } from '@react-redux-fetch/hooks';
import { getOrderFileResultRequest, getOrderFileRequest } from '../api';
import { useSelector } from 'react-redux';
import { getOrderFileResult, getOrderFile } from '../selectors';
import DelayedLoader from '../../../components/DelayedLoader';
import { getLink } from '../../../helpers/hateoas';
import Info from '../../title/admin/processResult/components/Info';
import getIdFromUrl from '../../../helpers/getIdFromUrl';

const OrderFileDetailPage = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const orderFileUrl = useApiRoute('orderFiles', id);
  const [, fetchOrderFileResult] = useFetch(getOrderFileResultRequest);
  const [, fetchOrderFile] = useFetch(getOrderFileRequest);

  const orderFile = useSelector(getOrderFile);
  const orderFileResultUrl = getLink(orderFile, 'importResults');
  const orderFileResult = useSelector(getOrderFileResult);

  useEffect(() => {
    if (orderFileUrl) {
      fetchOrderFile(orderFileUrl);
    }
  }, [orderFileUrl, fetchOrderFile]);

  useEffect(() => {
    if (
      getIdFromUrl(orderFileResultUrl) === getIdFromUrl(orderFileUrl || undefined) &&
      orderFileResultUrl
    ) {
      fetchOrderFileResult(orderFileResultUrl);
    }
  }, [orderFileUrl, fetchOrderFileResult, orderFileResultUrl]);

  return (
    <>
      <Header title={t('page_title_titlefile_process_result')} goBack />
      <DelayedLoader data={orderFileUrl === orderFile?._links.self.href && orderFile}>
        <Info fileInfo={orderFile!} />
        <DelayedLoader
          data={
            getIdFromUrl(orderFileResultUrl) ===
              getIdFromUrl(orderFileResult?.pager._links.self.href) && orderFileResult
          }
        >
          <OrderFileResultTable
            orderFileResult={orderFileResult!}
            fetchOrderFileResult={fetchOrderFileResult}
          />
        </DelayedLoader>
      </DelayedLoader>
    </>
  );
};

export default OrderFileDetailPage;
