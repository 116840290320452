import { RootState } from '../../config/store/types';
import { TreeData } from '../../components/Form/TreeSelect/types';
import { Nur } from '../../config/api/models/dataSets';
import { createSelector } from 'reselect';
import { convertThemaQualifierToTree, convertThemaToTree } from '../title/admin/title/dataMapper';

export const selectAllNurs = (state: RootState) => state.data.nurs;
export const selectAllThemas = (state: RootState) => state.data.themas;
export const selectAllThemaQualifiers = (state: RootState) => state.data.themaQualifiers;

type SelectableNurs = undefined | Array<TreeData & Nur>;

export const selectThemas = createSelector(selectAllThemas, (themas) => {
  if (!themas) return;

  const parents = themas.filter((thema) => thema.code.length === 1);

  return parents.map((parentThema) => convertThemaToTree(parentThema, themas));
});

export const selectThemaQualifiers = createSelector(selectAllThemaQualifiers, (themaQualifiers) => {
  if (!themaQualifiers) return;

  const parents = themaQualifiers.filter((themaQualifier) => themaQualifier.code.length === 1);

  return parents.map((parentThemaQualifier) =>
    convertThemaQualifierToTree(parentThemaQualifier, themaQualifiers)
  );
});

export const selectNurs = createSelector(
  selectAllNurs,
  (nurs): SelectableNurs => {
    if (!nurs) return;

    const parents = nurs.filter((nur) => nur.code.match(/[0-9]00/));

    return parents.map((parentNur) => ({
      ...parentNur,
      parents: [],
      children: nurs
        .filter((nur) => nur.code !== parentNur.code && nur.code[0] === parentNur.code[0])
        .map((nur) => ({
          ...nur,
          parents: [parentNur.code],
          children: null,
        })),
    }));
  }
);
