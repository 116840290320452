import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Grid, Typography } from '@mui/material';
import { styled } from '@mui/styles';
import React, { FC, useState } from 'react';
import { useFormState } from 'react-final-form';
import { Award } from '../../../../../../config/api/types';
import { FormValues } from '../../domain';
import AwardField from './AwardField';

const Container = styled(Grid)({
  cursor: 'pointer',
  '& svg': { opacity: 0, transition: 'opacity 0.2 ease' },
  '&:hover p': {
    textDecoration: 'underline',
  },
  '&:hover svg': {
    opacity: 0.7,
  },
});

type Props = {
  award: Award;
  name: string;
  onRemove: () => void;
  index: number;
};

const EditAwardField: FC<Props> = ({ award, name, onRemove, index }) => {
  const state = useFormState<FormValues>();

  const [isEditing, setIsEditing] = useState(!Boolean(award.literaturePrize.id));

  const error = state.errors?.awards?.[index];

  const closeAndRemove = () => {
    onRemove();
  };

  return (
    <>
      {!isEditing && (
        <Container container alignItems="center" spacing={1}>
          <Grid item onClick={() => setIsEditing(true)}>
            <Typography>
              {award.result.result} - {award.literaturePrize.name}
              {Boolean(award.year) && ` - ${award.year}`}
            </Typography>
          </Grid>

          <Grid item onClick={() => setIsEditing(true)}>
            <EditIcon fontSize="small" />
          </Grid>
          <Grid item>
            <DeleteIcon fontSize="small" onClick={onRemove} />
          </Grid>
        </Container>
      )}
      <div
        style={{
          display: isEditing ? 'block' : 'none',
        }}
      >
        {/*
         *** Keep this field alive or it won't be seen as dirty and will get resetted when flapText changes.
         *** This because of the prop keepDirtyOnReinitialize on the Form component inside TitleForm.
         */}
        <AwardField
          name={name}
          onRemove={closeAndRemove}
          RemoveIcon={DeleteIcon}
          onClose={!error ? () => setIsEditing(false) : undefined}
        />
      </div>
    </>
  );
};

export default EditAwardField;
