import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getAgeRangeGroups } from '../../selectors';
import { useFetch } from '@react-redux-fetch/hooks';
import { getAgeRangeGroupsRequest } from '../../api';
import { AgeRangeGroup } from '../../../../../../config/api/models/dataSets';
import BaseReactSelectField, {
  BaseReactSelectFieldProps,
} from '../../../../../../components/Form/BaseReactSelectField';

type Props = Partial<BaseReactSelectFieldProps> & {
  url: string;
};

const AgeRangeGroupField = ({ url, ...other }: Props) => {
  const [, fetchAgeRangeGroups] = useFetch(getAgeRangeGroupsRequest);
  const ageRangeGroups = useSelector(getAgeRangeGroups);

  useEffect(() => {
    if (!ageRangeGroups) {
      fetchAgeRangeGroups(url);
    }
  }, [url, ageRangeGroups, fetchAgeRangeGroups]);

  if (!ageRangeGroups) {
    return null;
  }

  return (
    <BaseReactSelectField
      name="ageRangeGroup"
      options={ageRangeGroups}
      getOptionLabel={(arg: AgeRangeGroup) => arg.label}
      getOptionValue={(arg: AgeRangeGroup) => arg}
      isClearable
      {...other}
    />
  );
};

export default AgeRangeGroupField;
