import { textsFields } from '../../domain';
import React from 'react';

type Props = {
  [key: string]: any;
  name: keyof { [key in typeof textsFields[number]]: true };
};

const TextsField = ({ component: Component, ...other }: Props) => <Component {...other} />;

export default TextsField;
