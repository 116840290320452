import { Grid } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import PageTitle from '../../../../components/PageTitle';
import { PublishersTableWrapper } from '../containers/PublishersTableWrapper';

const PublishersListPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <PageTitle>{t('page_title_publishers')}</PageTitle>
        </Grid>
      </Grid>

      <PublishersTableWrapper />
    </>
  );
};

export default PublishersListPage;
