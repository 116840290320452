import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { OrderReplacedNotification as OrderReplacedAppNotification } from '../../../config/api/models/notifications';
import ROUTE_KEY from '../../../config/routes/routeKeys';
import usePath from '../../../config/routes/usePath';
import { apiDate } from '../../../helpers/date';

type Props = {
  notification: OrderReplacedAppNotification;
  onClick: (notification: OrderReplacedAppNotification, redirectPath?: string) => void;
  onDelete: () => void;
};

const OrderReplacedNotification = ({ notification, onClick, onDelete }: Props) => {
  const { t } = useTranslation();
  const orderReplacementsPath = usePath(ROUTE_KEY.ORDER_REPLACEMENTS);

  const handleClick = () => {
    onClick(notification, orderReplacementsPath);
  };

  return (
    <MenuItem>
      <Box display={'flex'} style={{ gap: 20, width: '100%' }} alignItems={'center'}>
        <Grid container direction="column" onClick={handleClick}>
          <Grid item container direction="row" justifyContent="space-between" spacing={2}>
            <Grid item>
              <Box sx={{ position: 'relative' }}>
                {!notification.readAt && (
                  <Typography
                    style={{
                      position: 'absolute',
                      left: -12,
                      top: '50%',
                      transform: 'translateY(-50%)',
                    }}
                  >
                    {'\u25CF'}
                  </Typography>
                )}
                <Typography style={{ fontWeight: notification.readAt == null ? 500 : 400 }}>
                  {notification.message.replace(
                    '%quantity%',
                    notification.parameters.quantity.toString()
                  )}
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid item container direction="row" justifyContent="space-between" spacing={2}>
            <Grid item>
              <Typography variant="caption">
                {t('notifications_order_replaced_at', {
                  timestamp: apiDate(notification.parameters.replacedAt).toDateTime(),
                })}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <IconButton onClick={onDelete} size="small">
          <DeleteIcon />
        </IconButton>
      </Box>
    </MenuItem>
  );
};

export default OrderReplacedNotification;
