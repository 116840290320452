import { Grid } from '@mui/material';
import { useFetch } from '@react-redux-fetch/hooks';
import React, { useEffect } from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Button from '../../../../../components/Button';
import { CheckBox } from '../../../../../components/Form';
import { Organisation, PublisherDTO } from '../../../../../config/api/types';
import { getLink } from '../../../../../helpers/hateoas';
import { getPublisherRequest, updatePublisherRequest } from '../../../../title/admin/title/api';
import FormTitle from '../../components/FormTitle';
import { getPublisher } from '../../selectors';

type Props = {
  organisation: Organisation;
};

const PublisherParams = ({ organisation }: Props) => {
  const { t } = useTranslation();
  const publisherLink = getLink(organisation, 'publisher') || '';
  const [, fetchPublisher] = useFetch(getPublisherRequest);
  const [editPublisherRequest, updatePublisher] = useFetch(updatePublisherRequest);
  const publisher = useSelector(getPublisher);

  useEffect(() => {
    if (publisherLink) {
      fetchPublisher(publisherLink);
    }
  }, [publisherLink, fetchPublisher]);

  const handlePublisherUpdate = (values: PublisherDTO) => {
    updatePublisher(publisherLink, values);
  };

  if (!publisher) {
    return null;
  }

  return (
    <Form
      onSubmit={handlePublisherUpdate}
      initialValues={publisher}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <FormTitle title={t('organisation_edit_publisher_params_title')} />
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <CheckBox
                name="usesRegulatedPriceAlgorithm"
                label={t('organisation_edit_regulated_price_algorithm')}
              />
            </Grid>
            <Grid item>
              <CheckBox
                name="isPurchaseOrderRequired"
                label={t('organisation_edit_publisher_purchase_order_required')}
              />
            </Grid>
            <Grid item>
              <CheckBox
                name="isMemberGauGewu"
                label={t('organisation_edit_publisher_is_member_gau_gewu')}
              />
            </Grid>
            <Grid item>
              <CheckBox
                name="canChangeGprc"
                label={t('organisation_edit_publisher_can_change_gprc')}
              />
            </Grid>
            <Grid item>
              <CheckBox name="lannooGroup" label={t('organisation_edit_publisher_lannoogroup')} />
            </Grid>
            <Grid item>
              <CheckBox name="isDefaulter" label={t('organisation_edit_publisher_is_defaulter')} />
            </Grid>
            <Grid item>
              <CheckBox
                name="sendDataToCb"
                label={t('organisation_edit_publisher_send_data_to_cb')}
              />
            </Grid>
            <Grid item>
              <Button type="submit" disabled={editPublisherRequest?.pending}>
                {t('form_action_save')}
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    />
  );
};

export default PublisherParams;
