import PersonAdd from '@mui/icons-material/PersonAdd';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../../../components/Button';
import { Organisation } from '../../../../../config/api/types';
import { AddLoginForm } from './AddLoginForm';

type Props = {
  url: string;
  onAdd: () => void;
  organisation?: Organisation;
};

export const AddLogin: FC<Props> = ({ url, onAdd, organisation }) => {
  const { t } = useTranslation();
  const [showAddPersonForm, setShowAddPersonForm] = useState(false);

  if (showAddPersonForm && organisation) {
    return (
      <AddLoginForm
        onAdd={onAdd}
        url={url}
        onClose={() => setShowAddPersonForm(false)}
        organisation={organisation}
      />
    );
  }

  return (
    <Button variant="text" onClick={() => setShowAddPersonForm(true)}>
      <PersonAdd style={{ marginRight: 4 }} /> {t('form_login_create')}
    </Button>
  );
};
