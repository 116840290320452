import React from 'react';
import { useTranslation } from 'react-i18next';
import { SelectField as Select } from '../../../../../components/Form/index';
import { SelectFieldProps } from '../../../../../components/Form/SelectField';

type Props = {
  onChange: SelectFieldProps['onChange'];
  defaultValue?: string;
};

const IsAvailableFilter = ({ onChange, defaultValue }: Props) => {
  const { t } = useTranslation();

  const items = {
    1: t('filter_option_available'),
    0: t('filter_option_not_available'),
  };

  return (
    <Select
      items={items}
      onChange={onChange}
      label={t('filter_is_available')}
      value={defaultValue}
    />
  );
};

export default IsAvailableFilter;
