import React from 'react';
import { useTranslation } from 'react-i18next';
import Table from '../../../../components/Table';
import { CellType, DataType } from '../../../../components/Table/types';
import { Prefixes, Maybe } from '../../../../config/api/types';
import { getLink } from '../../../../helpers/hateoas';
import getIdFromUrl from '../../../../helpers/getIdFromUrl';
import DelayedLoader from '../../../../components/DelayedLoader';
import usePath from '../../../../config/routes/usePath';
import ROUTE_KEY from '../../../../config/routes/routeKeys';

type Props = {
  prefixes: Maybe<Prefixes>;
  dispatchFn: (url: string) => void;
  loading: boolean;
};

const PrefixTable = ({ prefixes, dispatchFn, loading }: Props) => {
  const { t } = useTranslation();
  const path = usePath(ROUTE_KEY.ADMIN_PREFIX_EDIT);
  const headers = [
    { id: 'prefix', label: t('table_headers_prefix') },
    { id: 'freeNumbers', label: t('table_headers_isbn_free'), align: 'center' },
    { id: 'reservedNumbers', label: t('table_headers_isbn_reserved'), align: 'center' },
    { id: 'occupiedNumbers', label: t('table_headers_isbn_occupied'), align: 'center' },
    { id: 'usedNumbers', label: t('table_headers_isbn_used'), align: 'center' },
    { id: 'requestedNumbers', label: t('table_headers_isbn_applied'), align: 'center' },
    { id: 'organisation', label: t('table_headers_publisher') },
    { id: 'billingType', label: t('table_headers_billing_type') },
    { id: 'active', label: t('table_headers_active') },
  ];

  if (!prefixes && loading) {
    return <DelayedLoader data={prefixes} />;
  }

  if (!prefixes) {
    return null;
  }

  const data: DataType = {
    resource: 'prefixes',
    rows:
      prefixes._embedded.items.map((prefix) => ({
        id: getLink(prefix, 'self') || '',
        link: path.replace(':id', getIdFromUrl(getLink(prefix, 'self') || '')),
        cells: [
          {
            type: CellType.Text,
            data: prefix.prefix,
          },
          {
            type: CellType.Text,
            data: prefix.remainingIsbnCount,
            align: 'center',
          },
          {
            type: CellType.Text,
            data: prefix.reservedIsbnCount,
            align: 'center',
          },
          {
            type: CellType.Text,
            data: prefix.occupiedIsbnCount,
            align: 'center',
          },
          {
            type: CellType.Text,
            data: prefix.usedIsbnCount,
            align: 'center',
          },
          {
            type: CellType.Text,
            data: prefix.applicationIsbnCount,
            align: 'center',
          },
          {
            type: CellType.Text,
            data:
              prefix?._embedded.organisation.name.length > 43
                ? prefix?._embedded.organisation.name.slice(0, 40) + '...'
                : prefix?._embedded.organisation.name ?? '',
          },
          {
            type: CellType.Text,
            data: prefix.billingType.label,
          },
          {
            type: CellType.Boolean,
            data: prefix.active,
          },
        ],
      })) || [],
  };

  return (
    <Table
      headers={headers}
      data={data}
      page={prefixes.page}
      pages={prefixes.pages}
      total={prefixes.total}
      limit={prefixes.limit}
      pagedResource={prefixes}
      dispatchFn={dispatchFn}
    />
  );
};

export default PrefixTable;
