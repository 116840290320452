import React, { FC } from 'react';
import { useThemaQualifiers } from '../../../../thema';
import { QueryObjectValue } from '../../../../../helpers/elasticQueryDSL';
import { ThemaBaseFacet } from './ThemaBaseFacet';

type Props = {
  value?: QueryObjectValue;
  onFilter: (themas: string[]) => void;
};

export const ThemaQualifierFacet: FC<Props> = ({ value, onFilter }) => {
  const { themaQualifiers } = useThemaQualifiers();

  if (!themaQualifiers) return null;

  return (
    <ThemaBaseFacet
      onFilter={onFilter}
      treeData={themaQualifiers}
      value={value}
      labelKey="facet_thema_qualifier_select"
      titleKey="facet_thema_qualifier"
    />
  );
};
