import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { PromiseState } from 'react-redux-fetch';
import { useLocation } from 'react-router-dom';
import SearchField from '../../../../../components/Form/SearchField';
import Table from '../../../../../components/Table/Table';
import TableFilterWrapper from '../../../../../components/Table/TableFilterWrapper';
import { CellType, DataType } from '../../../../../components/Table/types';
import { Funds, StatisticsCode } from '../../../../../config/api/types';
import ROUTE_KEY from '../../../../../config/routes/routeKeys';
import usePath from '../../../../../config/routes/usePath';
import getIdFromUrl from '../../../../../helpers/getIdFromUrl';
import {
  getLink,
  removeQueryParameter,
  updateQueryParameter,
  updateQueryParameterList,
} from '../../../../../helpers/hateoas';
import useQueryParams from '../../../../../helpers/useQueryParams';
import { STATISTICS } from '../../../domain';
import DistributorFilter from '../../containers/DistributorFilter';
import CreateFundButton from './CreateFundButton';
import IsAvailableFilter from './IsAvailableFilter';
import StatisticsCodeFilter from './StatisticsCodeFilter';
import StatusFilter from './StatusFilter';

type Props = {
  fundsFetch: PromiseState<Funds>;
  dispatchFundsGet: (url?: string) => void;
};

type FundsQueryParams = {
  status?: 'status_active' | 'status_not_active';
  statistics_code: StatisticsCode;
  is_available?: '0' | '1';
  code?: string;
  description?: string;
  'organisation_ids[0]': string;
};

const FundsTable = ({ fundsFetch, dispatchFundsGet }: Props) => {
  const { t } = useTranslation();
  const path = usePath(ROUTE_KEY.FUNDS_UPDATE);
  const pagedFunds = fundsFetch.value;
  const url = decodeURI(getLink(pagedFunds, 'self') || '');
  const { queryObj } = useQueryParams<FundsQueryParams>(url);
  const locationParams = useLocation<{ keepFilters?: boolean }>();
  const keepFilters = locationParams.state?.keepFilters;

  const distributorSearchCallback = useCallback(
    (ids: string[]) => {
      const newUrl = updateQueryParameterList(url, 'organisation_ids', ids);
      if (newUrl !== url) {
        dispatchFundsGet(newUrl);
      }
    },
    [dispatchFundsGet, url]
  );

  if (!pagedFunds) {
    return null;
  }

  const headers = [
    { id: 'code', label: t('table_headers_code') },
    { id: 'distributor', label: t('table_headers_distributor') },
    { id: 'description', label: t('table_headers_description') },
    { id: 'statisticsCode', label: t('table_headers_statistics_code') },
    { id: 'default', label: t('table_headers_default') },
    { id: 'status', label: t('table_headers_active') },
  ];

  const data: DataType = {
    resource: 'funds',
    rows: pagedFunds._embedded.items.map((fund) => ({
      id: getLink(fund, 'self') || '',
      link: path.replace(':id', getIdFromUrl(getLink(fund, 'self') || '')),
      cells: [
        {
          type: CellType.Text,
          data: fund.code,
        },
        {
          type: CellType.Text,
          data: fund.organisation ? fund.organisation.name : '',
        },
        {
          type: CellType.Text,
          data:
            fund.description.length < 33 ? fund.description : `${fund.description.slice(0, 30)}...`,
        },
        {
          type: CellType.Text,
          data: fund.statisticsCode,
        },
        {
          type: CellType.Boolean,
          data: fund.isDefault,
          trueOnly: true,
        },
        {
          type: CellType.Boolean,
          data: fund.status === 'status_active',
        },
      ],
    })),
  };

  const getStatisticsValue = () => {
    return keepFilters
      ? queryObj.statistics_code === ''
        ? STATISTICS.EMPTY
        : queryObj.statistics_code
      : '';
  };

  return (
    <>
      <TableFilterWrapper>
        <TableFilterWrapper>
          <StatisticsCodeFilter
            onChange={(event) => {
              const newUrl =
                event.target.value === STATISTICS.EMPTY
                  ? updateQueryParameter(url, 'statistics_code', '')
                  : Boolean(event.target.value)
                  ? updateQueryParameter(url, 'statistics_code', event.target.value as string)
                  : removeQueryParameter(url, 'statistics_code');

              dispatchFundsGet(newUrl);
            }}
            defaultValue={getStatisticsValue()}
          />
          <StatusFilter
            onChange={(event) => {
              dispatchFundsGet(
                updateQueryParameter(url, 'status', (event.target.value as string) || '')
              );
            }}
            defaultValue={keepFilters ? queryObj.status : ''}
          />
          <IsAvailableFilter
            onChange={(event) => {
              const isAvailable = event.target.value as string;
              const newUrl = isAvailable
                ? updateQueryParameter(url, 'is_available', isAvailable)
                : removeQueryParameter(url, 'is_available');
              dispatchFundsGet(newUrl);
            }}
            defaultValue={keepFilters ? queryObj.is_available : ''}
          />
        </TableFilterWrapper>
        <div>
          <CreateFundButton />
        </div>
      </TableFilterWrapper>
      <TableFilterWrapper style={{ justifyContent: 'flex-start' }}>
        <DistributorFilter
          placeholder={t('form_placeholder_distributor_search')}
          searchCallback={distributorSearchCallback}
          keepFilters={Boolean(keepFilters && queryObj['organisation_ids[0]'])}
        />
        <SearchField
          placeholder={t('form_placeholder_fund_code_search')}
          searchCallback={(term: string) => {
            dispatchFundsGet(updateQueryParameter(url, 'code', term.toUpperCase()));
          }}
          initialValue={keepFilters ? queryObj.code : ''}
          isClearable
        />
        <SearchField
          placeholder={t('form_placeholder_description_search')}
          searchCallback={(term: string) => {
            dispatchFundsGet(updateQueryParameter(url, 'description', term));
          }}
          initialValue={keepFilters ? queryObj.description : ''}
          isClearable
        />
      </TableFilterWrapper>

      <Table
        data={data}
        headers={headers}
        dispatchFn={dispatchFundsGet}
        pagedResource={pagedFunds}
      />
    </>
  );
};

export default FundsTable;
