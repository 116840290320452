import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useFetch } from '@react-redux-fetch/hooks';
import { getCashierSystemsRequest } from '../../../api';
import { useSelector } from 'react-redux';
import { getCashierSystems } from '../../selectors';
import BaseReactSelectField from '../../../../../components/Form/BaseReactSelectField';
import { CashierSystem } from '../../../../../config/api/models/dataSets';

type Props = {
  url: string;
};

const CashierSystemField = ({ url }: Props) => {
  const { t } = useTranslation();
  const [, fetchCashierSystems] = useFetch(getCashierSystemsRequest);
  const cashierSystems = useSelector(getCashierSystems);

  useEffect(() => {
    if (!cashierSystems) {
      fetchCashierSystems(url);
    }
  }, [cashierSystems, fetchCashierSystems, url]);

  if (!cashierSystems) {
    return null;
  }

  return (
    <BaseReactSelectField
      label={t('form_cashier_system')}
      name="cashierSystem"
      options={cashierSystems}
      getOptionLabel={(option: CashierSystem) => option.name}
      getOptionValue={(option: CashierSystem) => option.code}
      isClearable
    />
  );
};

export default CashierSystemField;
