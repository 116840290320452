import { useFetch } from '@react-redux-fetch/hooks';
import { debounce } from 'lodash';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import RemoteAutocomplete, {
  RemoteAutocompleteProps,
} from '../../../../../../components/Form/RemoteAutocomplete';
import { Title } from '../../../../../../config/api/types';
import useApiRoute from '../../../../../../config/api/useApiRoute';
import { updateQueryParameters } from '../../../../../../helpers/hateoas';
import { Option } from '../../../../catalog/components/Search/Option';
import { getTitleReplacementSearchRequest } from '../../api';
import { SelectedTitle } from '../../domain';
import { getTitleReplacementSearchTitles } from '../../selectors';

type Props = Partial<RemoteAutocompleteProps> & {
  label: string;
  name: string;
  selectedValues?: SelectedTitle[];
  showLanguageInOption?: boolean;
  optionsFilter?: (title: Title) => Title[];
};

const SearchTitlesField = ({
  selectedValues,
  name,
  showLanguageInOption,
  optionsFilter,
  ...other
}: Props) => {
  const { t } = useTranslation();
  const url = useApiRoute('titles');
  const [titlesFetch, fetchTitles] = useFetch(getTitleReplacementSearchRequest);
  const titles = useSelector(getTitleReplacementSearchTitles);

  const createUrl = useCallback(
    (term: string) => {
      return url ? updateQueryParameters(url, { q: term }) : '';
    },
    [url]
  );

  const transformTitleToOption = (
    title: Title
  ): SelectedTitle & { showLanguageInTitle?: boolean } => {
    return {
      value: title.gtin13,
      label: `${title.gtin13} - ${title.title}${
        !title.languages?.length
          ? ''
          : title.languages.length === 1
          ? ` - ${title.languages[0].label}`
          : ` - ${t('title_more_languages')}`
      }`,
      title,
      showLanguageInTitle: showLanguageInOption,
    };
  };

  const filteredTitles = optionsFilter != null ? titles.filter(optionsFilter) : titles;

  return (
    <RemoteAutocomplete
      {...other}
      name={name}
      placeholder={t('search_placeholder')}
      createUrlFromInput={createUrl}
      promiseState={titlesFetch}
      makeRequest={debounce(fetchTitles, 200)}
      options={filteredTitles.map(transformTitleToOption)}
      filterOption={() => true}
      components={{ Option: Option }}
      isClearable
    />
  );
};

export default SearchTitlesField;
