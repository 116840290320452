import { Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ConstrainedPageContent from '../../../components/ConstrainedPageContent';
import Header from '../../../components/Header';
import Multiline from '../../../components/Multiline';
import { RetourLines } from '../retourRequest/RetourLines';

export const RetourRequestPage = () => {
  const { t } = useTranslation();

  return (
    <ConstrainedPageContent center={false}>
      <Header title={t('page_title_return_request')} />
      <Typography>
        <Multiline>{t('page_subtitle_return_request')}</Multiline>
      </Typography>

      <RetourLines />
    </ConstrainedPageContent>
  );
};
