import { Box, Grid, Typography } from '@mui/material';
import { useFetch } from '@react-redux-fetch/hooks';
import React, { FC, useEffect, useState } from 'react';
import { Form } from 'react-final-form';
import Button from '../../../../../../components/Button';
import { CheckBox, TextField } from '../../../../../../components/Form';
import useApiRoute from '../../../../../../config/api/useApiRoute';
import { getLink, updateQueryParameters } from '../../../../../../helpers/hateoas';
import { getTitleRequest } from '../../../../detail/api';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import getIdFromUrl from '../../../../../../helpers/getIdFromUrl';
import { selectTitle } from '../../selectors';

type Props = {
  onNext: () => void;
  onSubmit: (gtin13: string, existingGtin13?: string) => void;
};

const SearchTitle: FC<Props> = ({ onNext, onSubmit }) => {
  const { t } = useTranslation();
  const url = useApiRoute('titles');
  const [gtin13, setGtin13] = useState('');
  const [existingGtin13, setExistingGtin13] = useState('');
  const [titleFetch, getTitle] = useFetch(getTitleRequest);
  const [existingtitleFetch, getExistingTitle] = useFetch(getTitleRequest);
  const foundTitle = useSelector(selectTitle);
  const isFulfilled = titleFetch?.fulfilled;

  useEffect(() => {
    if (isFulfilled && foundTitle && gtin13 === foundTitle.gtin13) {
      // New ISBN already exists
      onNext();
    }
    if (isFulfilled && !foundTitle && gtin13 && gtin13 !== existingGtin13) {
      if (existingGtin13 === '') {
        // New ISBN does not exist and we don't want to copy the data from an existing one
        onSubmit(gtin13);
      } else {
        // New ISBN does not exist and we do want to copy the data from an existing one. -> Start checking the existing isbn to make sure if it does exist
        const updatedUrl = updateQueryParameters(url || '', {
          q: existingGtin13,
        });
        getExistingTitle(updatedUrl, existingGtin13);
      }
    }
  }, [onNext, isFulfilled, foundTitle, onSubmit, gtin13, existingGtin13, url, getExistingTitle]);

  useEffect(() => {
    if (existingtitleFetch?.fulfilled) {
      if (foundTitle && gtin13 !== foundTitle.gtin13 && existingGtin13 === foundTitle.gtin13)
        // Existing ISBN does exist!
        onSubmit(gtin13, getIdFromUrl(getLink(foundTitle, 'self') || ''));
    }
  }, [existingGtin13, existingtitleFetch?.fulfilled, foundTitle, gtin13, onSubmit]);

  return (
    <Grid container>
      <Form
        onSubmit={(values) => {
          const gtin13 = values.gtin13;
          const existingGtin13 = values.existingGtin13;

          if (!gtin13 || gtin13.length !== 13) {
            return { gtin13: t('form_validation_invalid_gtin13') };
          }

          if (existingGtin13 && existingGtin13.length !== 13) {
            return { existingGtin13: t('form_validation_invalid_gtin13') };
          }

          const updatedUrl = updateQueryParameters(url || '', {
            q: values.gtin13,
          });
          setGtin13(values.gtin13);
          getTitle(updatedUrl, values.gtin13);
          setExistingGtin13(values.copyFromExistingGtin13 ? values.existingGtin13 : '');
        }}
        initialValues={{ gtin13: '', existingGtin13: '', copyFromExistingGtin13: false }}
      >
        {({ handleSubmit, values }) => {
          return (
            <Box
              display="flex"
              flexDirection="column"
              style={{ gap: 10 }}
              component="form"
              onSubmit={handleSubmit}
            >
              <Grid container spacing={2}>
                <Grid item style={{ width: 240 }}>
                  <TextField
                    name="gtin13"
                    label={`${t('new_gtin13')}*`}
                    fullWidth
                    type="number"
                    isClearable
                    autoFocus
                  />
                </Grid>
                {!values.copyFromExistingGtin13 ? (
                  <Grid item>
                    <Box mt={3}>
                      <Button type="submit" disabled={titleFetch?.pending}>
                        {t('form_next')}
                      </Button>
                    </Box>
                  </Grid>
                ) : null}
              </Grid>
              <CheckBox name="copyFromExistingGtin13" label={t('checkbox_existing_gtin13')} />

              {values.copyFromExistingGtin13 ? (
                <Box
                  display={'flex'}
                  flexDirection={'column'}
                  style={{ gap: 12, marginTop: 4 }}
                  alignItems={'flex-start'}
                >
                  <div>
                    <div style={{ width: 224 }}>
                      <TextField
                        name="existingGtin13"
                        label={t('existing_gtin13')}
                        fullWidth
                        type="number"
                        isClearable
                        autoFocus
                      />
                    </div>
                    {existingtitleFetch?.fulfilled && existingGtin13 !== foundTitle?.gtin13 ? (
                      <Typography color="error" style={{ fontSize: '0.75rem', marginTop: -5 }}>
                        {t('gtin13_not_found')}
                      </Typography>
                    ) : null}
                  </div>
                  <Button
                    type="submit"
                    disabled={titleFetch?.pending || existingtitleFetch?.pending}
                  >
                    {t('form_next')}
                  </Button>
                </Box>
              ) : null}
            </Box>
          );
        }}
      </Form>
    </Grid>
  );
};

export default SearchTitle;
