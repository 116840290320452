import { Theme } from '@mui/material';
import MUIAvatar from '@mui/material/Avatar/Avatar';
import { WithStyles, createStyles, withStyles } from '@mui/styles';
import React, { ReactNode, memo } from 'react';

const styles = (theme: Theme) =>
  createStyles({
    avatar: {
      marginRight: '16px',
      backgroundColor: theme.palette.primary.main,
    },
  });

type Props = WithStyles<typeof styles> & { children: ReactNode };

const Avatar = ({ classes, children }: Props) => (
  <MUIAvatar className={classes.avatar}>
    {typeof children === 'string'
      ? children
          .split(' ')
          .map((part) => part[0])
          .join('')
          .substring(0, 3)
          .toUpperCase()
      : ''}
  </MUIAvatar>
);

export default memo(withStyles(styles)(Avatar));
