import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Tab,
  Tabs,
  Theme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useFetch } from '@react-redux-fetch/hooks';
import { cloneDeep } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import Button from '../../../../components/Button';
import ConstrainedPageContent from '../../../../components/ConstrainedPageContent';
import DelayedLoader from '../../../../components/DelayedLoader';
import { BaseTextField } from '../../../../components/Form/TextField';
import Header from '../../../../components/Header';
import { IsbnApplication, IsbnApplicationState, Prefix } from '../../../../config/api/types';
import useApiRoute from '../../../../config/api/useApiRoute';
import ROUTE_KEY from '../../../../config/routes/routeKeys';
import usePath from '../../../../config/routes/usePath';
import { useAsyncValidation } from '../../../../helpers/finalFormAsyncValidation';
import { getLink, updateQueryParameters } from '../../../../helpers/hateoas';
import AlertStatus from '../../../core/alerts/components/AlertStatus';
import { getPublisher } from '../../../organisation/admin/selectors';
import { getPublisherRequest } from '../../../title/admin/title/api';
import {
  getIsbnApplicationRequest,
  getPrefixesRequest,
  updateIsbnApplicationRequest,
} from '../../api';
import { getIsbnApplication, getPrefixes } from '../../selectors';
import EditIsbnApplicantWrapper from '../containers/EditIsbnApplicantWrapper';
import EditIsbnPrefixWrapper from '../containers/EditIsbnPrefixWrapper';
import EditIsbnPurchaseOrderWrapper from '../containers/EditIsbnPurchaseOrderWrapper';
import EditIsbnTitleWrapper from '../containers/EditIsbnTitleWrapper';

type TabOption = 'applicant' | 'prefix' | 'purchaseOrder' | 'title';

const useStyles = makeStyles((theme: Theme) => ({
  indicator: {
    display: 'none',
  },
  tabsContainer: {
    paddingLeft: 1,
  },
  tab: {
    borderRight: `1px solid ${theme.palette.background.appbarHover}`,
    backgroundColor: theme.palette.background.appbar,
    color: theme.palette.text.primary,
  },
  selectedTab: {
    marginLeft: '-1px',
    backgroundColor: '#fff',
    borderTop: `2px solid ${theme.palette.background.appbarHover}`,
    borderLeft: `2px solid ${theme.palette.background.appbarHover}`,
    borderRight: `2px solid ${theme.palette.background.appbarHover}`,
    zIndex: 10,
  },
  divider: {
    marginTop: '-2px',
    height: 2,
    backgroundColor: theme.palette.background.appbarHover,
    zIndex: 9,
    position: 'relative',
    marginBottom: theme.spacing(4),
  },
  dialogContent: {
    width: 400,
  },
}));

const EditIsbnApplication = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const classes = useStyles();

  const isbnOverviewLink = usePath(ROUTE_KEY.ADMIN_ISBN_APPLICATIONS);
  const applicationUrl = useApiRoute('isbnApplication', id);
  const prefixUrl = useApiRoute('prefixes');

  const [updateApplicationRequest, updateIsbnApplication] = useFetch(updateIsbnApplicationRequest);
  const [, fetchIsbnApplication] = useFetch(getIsbnApplicationRequest);
  const [, fetchPrefixes] = useFetch(getPrefixesRequest);
  const [, fetchPublisher] = useFetch(getPublisherRequest);
  const isbnApplication = useSelector(getIsbnApplication);
  const prefixes = useSelector(getPrefixes);
  const publisher = useSelector(getPublisher);

  const [selectedTab, setSelectedTab] = useState<TabOption>('applicant');
  const [selectedPrefix, setSelectedPrefix] = useState<Prefix | undefined>(undefined);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [onHoldComment, setOnHoldComment] = useState<string>('');
  const [applicantOverrides, setApplicantOverrides] = useState<
    Partial<IsbnApplication['organisation']>
  >({});
  const [errors, setErrors] = useState<any>(null);
  const { createSubmissionPromise } = useAsyncValidation(updateApplicationRequest);
  const organisationId = isbnApplication?.publisherOrganisationId;
  const publisherUrl = isbnApplication?._embedded?.publisherOrganisation
    ? getLink(isbnApplication._embedded.publisherOrganisation, 'publisher')
    : '';
  const matchedPrefix = selectedPrefix
    ? selectedPrefix
    : prefixes?._embedded.items.find((p) => p.prefix === isbnApplication?.prefix);

  const showPurchaseOrderTab = Boolean(
    matchedPrefix?.billingType.code === 'FLEMISH_OLD' && publisher?.isPurchaseOrderRequired
  );
  const disableApprove =
    !isbnApplication ||
    !(isbnApplication.prefix || selectedPrefix) ||
    !isbnApplication.publisherOrganisationId ||
    (showPurchaseOrderTab && !isbnApplication.purchaseOrderNumber);

  const isCorrectApplication = (application?: IsbnApplication): Boolean => {
    return Boolean(application && (getLink(application, 'self') || '').includes(id));
  };

  useEffect(() => {
    if (applicationUrl) {
      fetchIsbnApplication(applicationUrl);
    }
  }, [fetchIsbnApplication, applicationUrl]);

  useEffect(() => {
    setSelectedPrefix(undefined);
  }, [organisationId]);

  useEffect(() => {
    if (publisherUrl) {
      fetchPublisher(publisherUrl);
    }
  }, [fetchPublisher, publisherUrl]);

  useEffect(() => {
    if (prefixUrl && organisationId) {
      fetchPrefixes(
        updateQueryParameters(prefixUrl, {
          organisation_id: organisationId,
        })
      );
    }
  }, [prefixUrl, fetchPrefixes, organisationId]);

  const handleUpdate = useCallback(
    (data: IsbnApplication) => {
      if (applicationUrl) {
        updateIsbnApplication(applicationUrl, data);
      }
    },
    [applicationUrl, updateIsbnApplication]
  );

  const overrideApplicantField = (field: keyof IsbnApplication['organisation'], value: string) => {
    const newOverrides = { ...applicantOverrides };
    newOverrides[field] = value;
    setApplicantOverrides(newOverrides);
  };

  const augmentedApplication = (application: IsbnApplication): IsbnApplication => {
    if (application._embedded?.publisherOrganisation) {
      return {
        ...application,
        _embedded: {
          publisherOrganisation: {
            ...application._embedded.publisherOrganisation,
            ...applicantOverrides,
          },
        },
      };
    }
    return application;
  };

  const replaceOrganisation = (values: IsbnApplication): IsbnApplication => {
    const result = {
      ...values,
      organisation: {
        ...cloneDeep(isbnApplication?._embedded.publisherOrganisation),
        ...applicantOverrides,
      },
    };

    // const orgKeys = Object.keys(application.organisation) as Array<
    //   keyof IsbnApplication['organisation']
    // >;
    // orgKeys.forEach((key) => {
    //   if (!result.organisation[key]) {
    //     result.organisation[key] = application.organisation[key];
    //   }
    // });
    return result as IsbnApplication;
  };

  const updateIsbnApplicationState = (state: IsbnApplicationState) => {
    setErrors(null);
    if (isbnApplication) {
      setModalOpen(false);
      const isbnApplicationUrl = getLink(isbnApplication, 'self') || '';
      let newApplication = {
        ...isbnApplication,
        state,
        prefix: selectedPrefix?.prefix || isbnApplication.prefix,
      };

      if (state === 'on_hold') {
        newApplication.onHoldComment = onHoldComment;
      } else if (state === 'approved') {
        newApplication = replaceOrganisation(newApplication);

        // @ts-ignore
        delete newApplication.requestIsbnNumbers;
      }
      updateIsbnApplication(isbnApplicationUrl, newApplication);
    }
    createSubmissionPromise().then((result) => {
      if (result) {
        setErrors(result);
      } else {
        history.push(isbnOverviewLink);
      }
    });
  };

  return (
    <ConstrainedPageContent center={false}>
      <Grid container alignItems="center">
        <Grid item xs={4}>
          <Header title={t('isbn_application_edit')} goBack />
        </Grid>

        <Grid
          item
          container
          spacing={1}
          sx={{ justifyContent: 'flex-end', alignItems: 'center' }}
          xs={selectedTab === 'title' ? 8 : 6}
        >
          {isbnApplication && (
            <>
              <Grid item>
                <Button
                  onClick={() => updateIsbnApplicationState('approved')}
                  disabled={disableApprove}
                >
                  {t('isbn_application_accept')}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  disabled={isbnApplication.state === 'on_hold'}
                  onClick={() => setModalOpen(true)}
                >
                  {t('isbn_application_pause')}
                </Button>
              </Grid>
              <Grid item>
                <Button onClick={() => updateIsbnApplicationState('rejected')}>
                  {t('isbn_application_reject')}
                </Button>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>

      <DelayedLoader data={isbnApplication && isCorrectApplication(isbnApplication)}>
        {isbnApplication && (
          <Grid container direction="column" spacing={2}>
            <Grid item xs={selectedTab === 'title' ? 12 : 10}>
              <Tabs
                value={selectedTab}
                onChange={(event, value: TabOption) => setSelectedTab(value)}
                classes={{ flexContainer: classes.tabsContainer, indicator: classes.indicator }}
              >
                <Tab
                  value={'applicant'}
                  label={t('table_headers_applicant')}
                  classes={{
                    root: classes.tab,
                    selected: classes.selectedTab,
                  }}
                />
                <Tab
                  value={'title'}
                  label={t('table_headers_title')}
                  classes={{
                    root: classes.tab,
                    selected: classes.selectedTab,
                  }}
                />
                <Tab
                  value={'prefix'}
                  label={t('table_headers_prefix')}
                  classes={{
                    root: classes.tab,
                    selected: classes.selectedTab,
                  }}
                />
                {showPurchaseOrderTab && (
                  <Tab
                    value={'purchaseOrder'}
                    label={t('table_headers_purchase_order')}
                    classes={{
                      root: classes.tab,
                      selected: classes.selectedTab,
                    }}
                  />
                )}
              </Tabs>
              <Divider className={classes.divider} />
              {errors?.prefix && (
                <AlertStatus label={t('isbn_application_invalid_prefix_error')}></AlertStatus>
              )}
              {publisher?.isDefaulter && (
                <AlertStatus label={t('isbn_application_is_defaulter_alert')}></AlertStatus>
              )}
              {selectedTab === 'applicant' && (
                <EditIsbnApplicantWrapper
                  isbnApplication={augmentedApplication(isbnApplication)}
                  onUpdate={handleUpdate}
                  overrideApplicantField={overrideApplicantField}
                  hasApplicantOverrides={Boolean(Object.keys(applicantOverrides).length)}
                />
              )}
              {selectedTab === 'prefix' && (
                <EditIsbnPrefixWrapper
                  isbnApplication={isbnApplication}
                  prefix={selectedPrefix}
                  onPrefixSelect={setSelectedPrefix}
                />
              )}
              {selectedTab === 'purchaseOrder' && (
                <EditIsbnPurchaseOrderWrapper
                  isbnApplication={isbnApplication}
                  onUpdate={handleUpdate}
                />
              )}
              {selectedTab === 'title' && (
                <EditIsbnTitleWrapper
                  isbnApplication={isbnApplication}
                  onUpdate={handleUpdate}
                  updateRequest={updateApplicationRequest}
                />
              )}
              <Dialog open={modalOpen}>
                <DialogTitle>{t('isbn_application_on_hold_dialog_title')}</DialogTitle>

                <DialogContent className={classes.dialogContent}>
                  <BaseTextField
                    multiline
                    label="Reden"
                    rows={5}
                    value={onHoldComment}
                    onChange={(event) => setOnHoldComment(event.target.value)}
                    autoFocus
                  />
                </DialogContent>

                <DialogActions>
                  <Button onClick={() => setModalOpen(false)} variant="text">
                    {t('form_cancel')}
                  </Button>
                  <Button onClick={() => updateIsbnApplicationState('on_hold')}>
                    {t('form_action_save')}
                  </Button>
                </DialogActions>
              </Dialog>
            </Grid>
          </Grid>
        )}
      </DelayedLoader>
    </ConstrainedPageContent>
  );
};

export default EditIsbnApplication;
