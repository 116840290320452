import { RootState } from '../../../config/store/types';
import { Maybe, Paged } from '../../../config/api/types';
import { Bookstore } from '../../../config/api/models/organisation';

export const getBookstores = (state: RootState): Maybe<Record<string, Bookstore>> =>
  state.data.bookstores;

export const createGetBookstore = (state: RootState) => {
  const bookstores = getBookstores(state);
  return (url?: string) => {
    if (!url || !bookstores) return null;
    return bookstores[url] || null;
  };
};

export const getPagedBookstores = (state: RootState): Maybe<Paged<Bookstore>> => {
  const allBookstores = getBookstores(state);
  return state.data.pagedBookstores && allBookstores
    ? {
        ...state.data.pagedBookstores,
        _embedded: {
          items: state.data.pagedBookstores._embedded.items.map(
            (bookstoreRef) => allBookstores[bookstoreRef]
          ),
        },
      }
    : null;
};
