import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Header from '../../../../components/Header';
import security from '../../../security';
import FundsList from '../containers/FundsList';

const FundsListPage = () => {
  const { t } = useTranslation();

  const userRoles = useSelector(security.selectors.getUserRole) || [];
  const isDataConsumer = userRoles.includes('ROLE_DATA_CONSUMER');

  return (
    <>
      <Header title={isDataConsumer ? t('page_title_funds_view') : t('page_title_funds')} />
      <FundsList />
    </>
  );
};

export default FundsListPage;
