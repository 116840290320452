import React from 'react';
import Catalog from '../containers/Catalog';
import ConstrainedPageContent from '../../../../components/ConstrainedPageContent';

const CatalogPage = () => (
  <ConstrainedPageContent center={false} lg>
    <Catalog />
  </ConstrainedPageContent>
);

export default CatalogPage;
