import { Theme, Tooltip, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { ReactElement } from 'react';
import formatEuro from '../helpers/formatEuro';

type Props = {
  price: number;
  icon?: ReactElement<any>;
  label?: string;
  strike?: boolean;
  strikeIcon?: boolean;
  iconPlacement?: 'before' | 'after';
  tooltip?: string;
};

const useStyles = makeStyles<Theme, Props>((theme: Theme) => ({
  price: {
    fontSize: theme.typography.caption.fontSize,
    fontWeight: theme.typography.fontWeightMedium,
    position: 'relative',
  },
  strike: (props: Props) => ({
    background: 'rgba(255,0,0,0.5)',
    width: props.strikeIcon ? (props.iconPlacement === 'before' ? 70 : 55) : 35,
    height: 2,
    position: 'absolute',
    top: '0.45rem',
    left: props.strikeIcon ? (props.iconPlacement === 'before' ? -28 : 0) : 0,
  }),
  icon: {
    width: theme.spacing(3.5),
    display: 'inline-block',
    textAlign: 'left',
  },
  iconRight: {
    width: theme.spacing(2),
    display: 'inline-block',
    textAlign: 'right',
    verticalAlign: 'text-bottom',
  },
}));

const PriceSecondary = (props: Props) => {
  const { price, icon, label, strike, iconPlacement = 'before' } = props;
  const classes = useStyles(props);

  const content = (
    <span>
      {icon && iconPlacement === 'before' && <span className={classes.icon}>{icon}</span>}
      <Typography className={classes.price} display="inline">
        {formatEuro(price)}
        {strike && <span className={classes.strike} />}
      </Typography>
      {icon && iconPlacement === 'after' && <span className={classes.icon}>{icon}</span>}
      &nbsp;
      <Typography variant="caption">{label}</Typography>
    </span>
  );

  return props.tooltip ? (
    <Tooltip title={props.tooltip} arrow>
      {content}
    </Tooltip>
  ) : (
    content
  );
};

export default PriceSecondary;
