import { Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TaxRate as TaxRateModel } from '../config/api/types';

type Classes = {
  tax?: string;
};

type Props = {
  tax: TaxRateModel;
  classes?: Classes;
};

const TaxRate = ({ tax, classes }: Props) => {
  const { t } = useTranslation();
  const pctAtHighRate = tax.percentageAtHighRate || 0;

  const text =
    tax.code === 'C'
      ? t('title_tax_rate_compound', { low: 100 - pctAtHighRate, high: pctAtHighRate })
      : t('title_tax_rate', { tax: tax.label });

  return <Typography className={classes && classes.tax}>{text}</Typography>;
};

export default TaxRate;
