import apiRoutes from '../../../config/api/routes';
import { ApiConfigUnion } from '../../../helpers/apiConfig';
import memoizeOne from 'memoize-one';
import { FetchConfig } from '@react-redux-fetch/core';
import { Data } from '../../../config/store/types';
import { Order } from '../../../config/api/models/shop';
import { TitleFull, Titles } from '../../../config/api/types';

export const getTitleOrderHistoryRequest = (url: string): FetchConfig<Data> => {
  return {
    method: 'GET',
    url: apiRoutes.create(url),
    repository: {
      titleOrders: (prev, next: Order) => ({ ...prev, [url]: next }),
    },
  };
};

export const getTitleRequest = (url: string, gtin13?: string): FetchConfig<Data> => {
  return {
    method: 'GET',
    requestKey: gtin13,
    url: apiRoutes.create(url),
    repository: {
      title: (prev, next: TitleFull | Titles): Data['title'] => {
        return (next._embedded as Titles['_embedded']).items
          ? (next as Titles)._embedded.items.find((i) => i.gtin13 === gtin13)
          : (next as TitleFull);
      },
    },
  };
};

export const ApiConfig = {
  getTitle: memoizeOne((url: string) => ({
    resource: 'title',
    request: () => ({
      url: apiRoutes.get(url),
      // comparison: url, // No comparison because a refresh is needed after a title file is uploaded and processed
      clearValueOnRequest: true,
    }),
  })),
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ApiConfig = ApiConfigUnion<typeof ApiConfig>;
