import { prop, sortBy } from 'ramda';

export const STATISTICS: {
  FLEMISH: 'Vlaams';
  DUTCH: 'Nederlands';
  IMPORT: 'Import';
  WALLOON: 'Waals';
  MIXED: 'Gemengd';
  EMPTY: 'Leeg';
} = {
  FLEMISH: 'Vlaams',
  DUTCH: 'Nederlands',
  IMPORT: 'Import',
  WALLOON: 'Waals',
  MIXED: 'Gemengd',
  EMPTY: 'Leeg',
};

export const statisticCodes = Object.values(STATISTICS);

export const sortByName = sortBy(prop('name'));
