import { Box, Grid } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Field from '../../../../../../components/Form';
import InfoTooltip from '../../../../../../components/InfoTooltip';
import { Award } from '../../../../../../config/api/types';
import AddAwardsField from '../../containers/fields/AddAwardsField';
import AwardsField from '../../containers/fields/AwardsField';
import { FormValues } from '../../domain';
import Fieldset from '../Fieldset';
import FieldsetSubTitle from '../FieldsetSubTitle';
import FieldsetTitle from '../FieldsetTitle';
import SectionWrapper from '../SectionWrapper';
import PromotionField from './PromotionField';

type Props = {
  formAwards?: Award[];
  onChangeFormValue: (name: keyof FormValues, value?: any) => void;
};

const Promotion = ({ onChangeFormValue, formAwards }: Props) => {
  const { t } = useTranslation();

  return (
    <SectionWrapper id="promotion">
      <FieldsetTitle>{t('title_section_promotion')}</FieldsetTitle>
      <Fieldset>
        <Grid container spacing={1}>
          <Grid xs={6} item>
            <PromotionField
              component={Field}
              label={
                <>
                  {t('title_feature')} <InfoTooltip title={t('title_feature_tooltip')} inline />
                </>
              }
              type="text"
              name="feature"
              helperText={t('title_feature_helperText')}
            />
          </Grid>
        </Grid>
      </Fieldset>
      {formAwards != null && (
        <Fieldset>
          <FieldsetSubTitle>{t('title_awards')}</FieldsetSubTitle>
          <AwardsField formAwards={formAwards} onChange={onChangeFormValue} />
          <Box mt={1}>
            <AddAwardsField
              name={`awards[${formAwards.length}]`}
              onChange={onChangeFormValue}
              formNewAwards={formAwards}
            />
          </Box>
        </Fieldset>
      )}
    </SectionWrapper>
  );
};

export default Promotion;
