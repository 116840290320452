import React, { FC, useEffect, useState } from 'react';
import { FormSpy, FormSpyProps, FormSpyRenderProps } from 'react-final-form';

type CustomProps = {
  save: () => void;
};

const AutoSave: FC<FormSpyRenderProps & CustomProps> = ({ active, save }) => {
  const [wasActive, setWasActive] = useState(active);

  useEffect(() => {
    if (!active && wasActive) {
      save();
    }
  }, [active, wasActive, save]);

  useEffect(() => {
    setWasActive(active);
  }, [active]);

  return null;
};

const AutoSaveWrapper = (props: FormSpyProps & CustomProps) => (
  // @ts-ignore
  <FormSpy {...props} component={AutoSave} subscription={{ active: true }} />
);

export default AutoSaveWrapper;
