import React from 'react';
import CreateForm from '../containers/Create';
import { RouteComponentProps } from 'react-router';
import Header from '../../../../components/Header';

type Props = RouteComponentProps & {};

const Create = (props: Props) => (
  <>
    <Header title="Organisaties" goBack />
    <CreateForm {...props} />
  </>
);

export default Create;
