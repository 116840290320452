import { FetchConfig } from '@react-redux-fetch/core';
import { Data } from '../../../config/store/types';
import apiRoutes from '../../../config/api/routes';
import { normalizeBookstoresRequest, normalizePagedBookstoresRequest } from './dtoMapper';

export const getBookstoresRequest = (url: string, clearPrevValue = true): FetchConfig<Data> => {
  return {
    method: 'GET',
    url: apiRoutes.create(url),
    transform: normalizeBookstoresRequest,
    repository: {
      bookstores: (prev, next) => {
        return clearPrevValue
          ? next
          : {
              ...prev,
              ...next,
            };
      },
    },
  };
};

export const getPagedBookstoresRequest = (url: string): FetchConfig<Data> => {
  return {
    method: 'GET',
    url: apiRoutes.create(url),
    transform: normalizePagedBookstoresRequest,
    repository: {
      bookstores: (prev, next) => {
        return {
          ...prev,
          ...next,
        };
      },
      pagedBookstores: (prev, next) => {
        return next;
      },
    },
  };
};
