import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import { Theme, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) => ({
  priceTag: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '2rem',
    transform: 'translate(-78%,-20%)',
  },
  percentSign: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%,-50%)',
    fontWeight: 700,
    fontSize: '0.9rem',
    color: '#fff',
  },
  icon: {
    fill: theme.palette.warning.main,
    display: 'block',
    width: '100%',
    height: '100%',
    transform: 'rotate(90deg)',
    transformOrigin: 'center',
  },
}));

const ActionPrice = () => {
  const classNames = useStyles();
  const { t } = useTranslation();

  return (
    <Tooltip title={t('title_priceActionTag_tooltip')} arrow>
      <div className={classNames.priceTag}>
        <LocalOfferIcon className={classNames.icon} />
        <span className={classNames.percentSign}>%</span>
      </div>
    </Tooltip>
  );
};

export default ActionPrice;
