import React from 'react';
import { useTranslation } from 'react-i18next';
import { SelectField as Select } from '../../../../../components/Form/index';
import { SelectFieldProps } from '../../../../../components/Form/SelectField';

type Props = {
  onChange: SelectFieldProps['onChange'];
  defaultValue?: string;
};

const StatusFilter = ({ onChange, defaultValue }: Props) => {
  const { t } = useTranslation();

  const items = {
    status_active: t('filter_option_active'),
    status_not_active: t('filter_option_inactive'),
  };

  return (
    <Select items={items} onChange={onChange} label={t('filter_status')} value={defaultValue} />
  );
};

export default StatusFilter;
