import * as Sentry from '@sentry/react';

type BaseUser = { email?: string; username?: string; roles?: string[] };

let email = '';

export const setSentryUser = <TUser extends BaseUser>(user?: TUser) => {
  if (!user) return Sentry.setUser(null);
  if (email === user.email) return;

  Sentry.setUser({ email: user.email, username: user.username });
  Sentry.setTag('roles', (user.roles || []).join(','));
  email = user.email || '';
};
