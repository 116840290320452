import FundsListPage from './scenes/FundsListPage';
import FundDetailPage from './scenes/FundDetailPage';
import { ApiConfig } from './api';

const fundAdmin = {
  scenes: {
    FundsListPage,
    NewFundPage: FundDetailPage,
    UpdateFundPage: FundDetailPage,
  },
  apiConfig: ApiConfig,
};

export default fundAdmin;
