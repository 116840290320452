import React, { useEffect, useState } from 'react';
import { IsbnApplication, MasterPrefix, Prefix } from '../../../../config/api/types';
import IsbnPrefixReview from '../components/IsbnPrefixReview';
import { useFetch } from '@react-redux-fetch/hooks';
import { getPrefixesRequest, createPrefixRequest } from '../../api';
import { getPrefixes, getPrefix } from '../../selectors';
import useApiRoute from '../../../../config/api/useApiRoute';
import { useSelector } from 'react-redux';
import getIdFromUrl from '../../../../helpers/getIdFromUrl';
import { getLink, updateQueryParameters } from '../../../../helpers/hateoas';
import GeneratePrefix from '../components/GeneratePrefix';

type Props = {
  isbnApplication: IsbnApplication;
  onPrefixSelect: (prefix?: Prefix) => void;
  prefix?: Prefix;
};

const EditIsbnPrefixWrapper = ({ isbnApplication, prefix, onPrefixSelect }: Props) => {
  const prefixUrl = useApiRoute('prefixes');
  const [prefixRequest, fetchPrefixes] = useFetch(getPrefixesRequest);
  const [createPrefixResponse, createPrefix] = useFetch(createPrefixRequest);
  const [generatePrefix, setGeneratePrefix] = useState<boolean>(false);
  const prefixes = useSelector(getPrefixes);
  const reduxPrefix = useSelector(getPrefix);
  const organisationId = isbnApplication._embedded?.publisherOrganisation
    ? getIdFromUrl(getLink(isbnApplication._embedded.publisherOrganisation, 'self') || '')
    : '';

  useEffect(() => {
    if (prefixUrl && organisationId) {
      fetchPrefixes(
        updateQueryParameters(prefixUrl, {
          organisation_id: organisationId,
          billing_type: 'FLEMISH_OLD,FLEMISH_NEW',
          with_remaining_isbns_only: 'true',
          active: 'true',
        })
      );
    }
  }, [prefixUrl, fetchPrefixes, organisationId]);

  useEffect(() => {
    if (
      createPrefixResponse?.fulfilled &&
      reduxPrefix &&
      isbnApplication.prefix !== reduxPrefix.prefix
    ) {
      onPrefixSelect(reduxPrefix);
    }
  }, [createPrefixResponse, reduxPrefix, isbnApplication, onPrefixSelect]);

  const handleGeneratePrefix = (masterPrefix: MasterPrefix, size: string) => {
    const masterPrefixUrl = getLink(masterPrefix, 'prefixForMasterPrefix') || '';
    const url = updateQueryParameters(masterPrefixUrl, { size, organisationId });
    createPrefix(url);
    setGeneratePrefix(false);
  };

  return generatePrefix ? (
    <GeneratePrefix
      onCancel={() => setGeneratePrefix(false)}
      onSave={handleGeneratePrefix}
      requestedLength={
        isbnApplication.requestIsbnNumbers &&
        isbnApplication.requestIsbnNumbers !== 'use_existing_prefix'
          ? isbnApplication.requestIsbnNumbers
          : ''
      }
    />
  ) : (
    <IsbnPrefixReview
      isbnApplication={isbnApplication}
      prefixes={prefixes}
      loadingPrefixes={!prefixRequest || prefixRequest.pending}
      generatePrefix={() => setGeneratePrefix(true)}
      prefix={prefix}
      onPrefixSelect={onPrefixSelect}
    />
  );
};

export default EditIsbnPrefixWrapper;
