import { Box } from '@mui/material';
import moment from 'moment';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Table from '../../../../components/Table';
import { CellType, DataType } from '../../../../components/Table/types';
import { Paged } from '../../../../config/api/types';
import ROUTE_KEY from '../../../../config/routes/routeKeys';
import usePath from '../../../../config/routes/usePath';
import { Retour } from '../../../../config/store/retour';
import { RetourHistoryFilter } from './RetourHistoryFilter';

type Props = {
  pagedRetours: Paged<Retour>;
  isLoading?: boolean;
  fetchRetours: (url: string) => void;
};
export const RetoursTable: FC<Props> = ({ pagedRetours, isLoading, fetchRetours }) => {
  const { t } = useTranslation();
  const url = pagedRetours._links.self.href;
  const retourPath = usePath(ROUTE_KEY.RETURN_DETAIL);

  const headers = [
    { id: 'retour_ref', label: t('table_headers_retour_reference'), width: '15%' },
    { id: 'confirmed_at', label: t('table_headers_confirmed_at'), width: '10rem' },
    { id: 'distributors', label: t('table_headers_distributors') },
    { id: 'num_titles', label: t('table_headers_num_titles'), width: '4rem', align: 'center' },
    { id: 'num_pieces', label: t('table_headers_num_pieces'), width: '4rem', align: 'center' },
  ];

  const data: DataType = {
    resource: 'retours',
    rows: pagedRetours._embedded.items.map((retour) => {
      const id = retour.retourId;
      const path = retourPath.replace(':id', id);

      const distributors = Array.from(
        new Set(
          retour.linesByDistributor.map((line) => {
            return `${line._embedded.organisation.name} ${
              line._embedded.distributor?.isCbMember ? t('cb') : ''
            }`;
          })
        )
      );

      return {
        id,
        link: path,
        linkState: { from: window.location.pathname },
        cells: [
          {
            type: CellType.Text,
            data: retour.reference,
          },
          {
            type: CellType.Text,
            data: moment(retour.confirmedAt).format('DD/MM/YYYY - HH:mm'),
          },
          {
            type: CellType.Text,
            data: (
              <>
                {distributors.map((name, index) => (
                  <div key={index}>
                    {name}
                    {index + 1 < distributors.length ? ', ' : ''}
                  </div>
                ))}
              </>
            ),
          },
          {
            type: CellType.Text,
            data: <Box textAlign="center">{retour.distinctTitleCount}</Box>,
          },
          {
            type: CellType.Text,
            data: <Box textAlign="center">{retour.titleCount}</Box>,
          },
        ],
      };
    }),
  };

  return (
    <>
      {/*<Grid container justify="flex-end">*/}
      {/*  <Grid item>*/}
      {/*    <ExportButton url={url} size="small" disabled={!pagedRetours?.total}>*/}
      {/*      {t('retour_export')}*/}
      {/*    </ExportButton>*/}
      {/*  </Grid>*/}
      {/*</Grid>*/}
      <RetourHistoryFilter url={url} fetchRetours={fetchRetours} withFreeText={false} />
      <Table
        data={data}
        headers={headers}
        pagedResource={pagedRetours}
        loading={isLoading}
        dispatchFn={fetchRetours}
      />
    </>
  );
};
