import { Theme } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import { WithStyles, createStyles, withStyles } from '@mui/styles';
import React from 'react';
import Content from './Content';

type Props = WithStyles<typeof styles> & {
  open: boolean;
  onClose: () => void;
  variant: 'success' | 'warning' | 'error' | 'info';
  message: string;
  anchorOrigin?: {
    vertical: 'bottom' | 'top';
    horizontal: 'right' | 'left' | 'center';
  };
};

const styles = (theme: Theme) =>
  createStyles({
    margin: {
      margin: theme.spacing(1),
    },
  });

const AlertMessage = ({
  open,
  onClose,
  variant,
  message,
  classes,
  anchorOrigin = {
    vertical: 'bottom',
    horizontal: 'right',
  },
}: Props) => (
  <Snackbar
    anchorOrigin={anchorOrigin}
    open={open}
    autoHideDuration={10000}
    onClose={onClose}
    style={{ zIndex: 9999 }}
  >
    <span>
      <Content variant={variant} className={classes.margin} message={message} onClose={onClose} />
    </span>
  </Snackbar>
);

export default withStyles(styles)(AlertMessage);
