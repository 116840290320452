import { Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

const NothingFiltered = () => {
  const { t } = useTranslation();
  return (
    <Typography variant="h4" style={{ padding: '2rem', opacity: 0.7, lineHeight: 1.5 }}>
      {t('catalog_nothing_filtered')}
    </Typography>
  );
};

export default NothingFiltered;
