import { Grid } from '@mui/material';
import { useFetch } from '@react-redux-fetch/hooks';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Redirect, useParams } from 'react-router-dom';
import DelayedLoader from '../../../../components/DelayedLoader';
import Header from '../../../../components/Header';
import { Prefix } from '../../../../config/api/types';
import useApiRoute from '../../../../config/api/useApiRoute';
import { ClientRouteContext } from '../../../../config/routes/ClientRouteContext';
import ROUTE_KEY from '../../../../config/routes/routeKeys';
import usePath from '../../../../config/routes/usePath';
import { useAsyncValidation } from '../../../../helpers/finalFormAsyncValidation';
import { getPrefixRequest, updatePrefixRequest } from '../../api';
import { getPrefix } from '../../selectors';
import PrefixForm from '../components/PrefixForm';

const propertyPathMapper = {
  billedCanOnlyBeTrueForBillingTypeNewFlemish: 'billed',
};

const EditPrefix = () => {
  const { t } = useTranslation();

  const { flatRoutes: clientRoutes } = useContext(ClientRouteContext);
  const returnRoute = clientRoutes.find((r) => r.key === ROUTE_KEY.ADMIN_PREFIX_EDIT);

  const { id } = useParams<{ id: string }>();
  const prefixUrl = useApiRoute('prefixes', id) || '';
  const returnPath = usePath(ROUTE_KEY.ADMIN_PREFIX);
  const prefix = useSelector(getPrefix);

  const [prefixRequest, fetchPrefix] = useFetch(getPrefixRequest);
  const [prefixUpdateRequest, updatePrefix] = useFetch(updatePrefixRequest);
  const { createSubmissionPromise } = useAsyncValidation(prefixUpdateRequest, propertyPathMapper);

  useEffect(() => {
    fetchPrefix(prefixUrl);
  }, [prefixUrl, fetchPrefix]);

  const handleSubmit = (values: Partial<Prefix>) => {
    updatePrefix(prefixUrl, values);
    return createSubmissionPromise().then((result) => result);
  };

  if (prefixUpdateRequest && prefixUpdateRequest.fulfilled) {
    return <Redirect to={{ pathname: returnPath, state: { keepFilters: true } }} />;
  }

  return (
    <Grid container direction="column">
      <Grid item xs={6}>
        <Header title={t('isbn_administration')} goBack={returnRoute && returnRoute.path} />
      </Grid>
      <Grid item xs={12} container justifyContent="space-between">
        <Grid item xs={6}>
          <DelayedLoader data={!prefixRequest?.pending && prefix}>
            {prefix && (
              <PrefixForm prefix={prefix} onSubmit={handleSubmit} returnPath={returnPath} />
            )}
          </DelayedLoader>
        </Grid>
        <Grid item></Grid>
      </Grid>
    </Grid>
  );
};

export default EditPrefix;
