import memoizeOne from 'memoize-one';
import { RouteResourceNames } from '../api/types';
import { clientRoutes } from './allClientRoutes';
import { flattenRoutes, removeNestedItems } from './helpers';
import { BuildRoutes, NestedClientRoute } from './types';

const buildRoutes: BuildRoutes = (apiRoutes) => {
  const routes = apiRoutes ? apiRoutes._links : [];
  const routeResourceNames = Object.keys(routes) as RouteResourceNames[];

  const filteredNestedRoutes: NestedClientRoute[] = removeNestedItems(
    clientRoutes,
    routeResourceNames
  );

  const filteredFlatRoutes = flattenRoutes(filteredNestedRoutes);

  return {
    nestedRoutes: filteredNestedRoutes,
    flatRoutes: filteredFlatRoutes,
  };
};

export default memoizeOne(buildRoutes);
