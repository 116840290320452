import { Avatar, ListItem, ListItemAvatar, ListItemText, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { ReactNode } from 'react';

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(1),
    },
    listItemAvatar: {
      width: 64 + 24,
    },
    avatar: {
      background: theme.palette.primary.highlight,
      width: 56,
      height: 56,
      '& svg': {
        width: 28,
        height: 28,
      },
    },
  }),
  { name: 'HomePageUspListItem' }
);

type Props = {
  icon: ReactNode;
  text: ReactNode;
};

const HomePageUspListItem = ({ icon, text }: Props) => {
  const classes = useStyles();

  return (
    <ListItem disableGutters className={classes.root}>
      <ListItemAvatar className={classes.listItemAvatar}>
        <Avatar classes={{ root: classes.avatar }}>{icon}</Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={
          <Typography component="p" variant="subtitle1">
            {text}
          </Typography>
        }
        disableTypography
      />
    </ListItem>
  );
};

export default HomePageUspListItem;
