import React from 'react';
import { PromiseState, ReduxFetch } from 'react-redux-fetch';
import { Redirect, RouteComponentProps } from 'react-router';
import DelayedLoaderForFetch from '../../../../../components/DelayedLoaderForFetch';
import { ProcessResult, TitleFile } from '../../../../../config/api/types';
import useApiRoute from '../../../../../config/api/useApiRoute';
import { getLink, updateQueryParameters } from '../../../../../helpers/hateoas';
import { ApiConfig } from '../api';
import Info from '../components/Info';
import ProcessResultTable from '../components/ProcessResultTable';

type Params = { id: string };
type Props = RouteComponentProps<Params> & {};
type DispatchFunctions = {
  dispatchTitleFileGet: () => void;
  dispatchProcessResultGet: (url: string) => void;
};
type ReduxFetchRenderProps = DispatchFunctions & {
  titleFileFetch: PromiseState<TitleFile>;
  processResultFetch: PromiseState<ProcessResult>;
};

const Detail = ({ match }: Props) => {
  const titleId = match.params.id;
  const titleFileUrl = useApiRoute('titleFiles', titleId);

  if (!titleFileUrl) {
    return <Redirect to="/" />;
  }
  const titleFileFetchConfig = [ApiConfig.getTitleFile(titleFileUrl), ApiConfig.getProcessResult()];

  return (
    <ReduxFetch
      config={titleFileFetchConfig}
      fetchOnMount={['titleFile']}
      onFulfil={(
        key: string,
        state: PromiseState<TitleFile>,
        dispatchFunctions: DispatchFunctions
      ) => {
        const { dispatchProcessResultGet } = dispatchFunctions;
        const titleFile = state.value;
        if (key === 'titleFileFetch') {
          dispatchProcessResultGet(
            updateQueryParameters(getLink(titleFile, 'importResults') || '', {
              limit: '100',
              message_type: 'error',
            })
          );
        }
      }}
    >
      {({
        titleFileFetch,
        processResultFetch,
        dispatchProcessResultGet,
      }: ReduxFetchRenderProps) => {
        return (
          <DelayedLoaderForFetch fetchObj={titleFileFetch}>
            <>
              <Info fileInfo={titleFileFetch.value} positionTweak />
              <DelayedLoaderForFetch fetchObj={processResultFetch}>
                {processResultFetch.value ? (
                  <ProcessResultTable
                    processResult={processResultFetch.value}
                    dispatchProcessResultGet={dispatchProcessResultGet}
                    pending={processResultFetch.pending}
                  />
                ) : null}
              </DelayedLoaderForFetch>
            </>
          </DelayedLoaderForFetch>
        );
      }}
    </ReduxFetch>
  );
};

export default Detail;
