import React, { FC } from 'react';
import { RouteComponentProps } from 'react-router';
import ConstrainedPageContent from '../../../components/ConstrainedPageContent';
import OrdersHistoryProcessedLinesForBookstore from '../components/ordersHistoryLines/OrdersHistoryProcessedLinesForBookstore';
import { OrdersProcessedHeader } from './OrdersProcessedForBookstorePage';

type Props = RouteComponentProps;

//Page incoming orders (inkomende bestellingen)
const OrdersLinesProcessedForBookstorePage: FC<Props> = (props) => {
  return (
    <ConstrainedPageContent center={false}>
      <OrdersProcessedHeader {...props} />
      <OrdersHistoryProcessedLinesForBookstore />
    </ConstrainedPageContent>
  );
};

export default OrdersLinesProcessedForBookstorePage;
