import { useDispatch, useSelector } from 'react-redux';
import { isAuthenticated } from '../selectors';
import { useEffect } from 'react';
import { Actions } from '../actions';

const useForceLogout = () => {
  const auth = useSelector(isAuthenticated);
  const dispatch = useDispatch();

  useEffect(() => {
    if (auth) {
      dispatch(Actions.logout());
    }
  }, [auth, dispatch]);

  return {
    isAuthenticated: auth,
  };
};

export default useForceLogout;
