import React from 'react';
import { useTranslation } from 'react-i18next';
import BaseReactSelectField, {
  BaseReactSelectFieldProps,
} from '../../../../../components/Form/BaseReactSelectField';
import { Bookstore } from '../../../../../config/api/models/organisation';
import { FavoriteBookstores, Maybe } from '../../../../../config/api/types';

type Props = Partial<BaseReactSelectFieldProps> & {
  favorites: Maybe<FavoriteBookstores>;
};

const FavoriteBookstoreField = ({ favorites, ...other }: Props) => {
  const { t } = useTranslation();

  const getValue = (value: string | Bookstore) => {
    if (!favorites || typeof value !== 'string') {
      return value;
    }
    return favorites.find((favorite) => favorite.bookstoreId === value);
  };

  return (
    <BaseReactSelectField
      label={t('form_favorite_bookstore')}
      placeholder={t('form_favorite_bookstore_placeholder')}
      name="bookstoreId"
      options={favorites}
      getOptionValue={(option: Bookstore) => option.bookstoreId}
      getOptionLabel={(option: Bookstore) => option._embedded.organisation.name}
      disabled={!favorites}
      parse={(value: Bookstore | null) => value?.bookstoreId}
      format={getValue}
      blurInputOnSelect
      {...other}
    />
  );
};

export default FavoriteBookstoreField;
