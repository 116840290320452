import { ActionsUnion, createAction } from '../../helpers/createAction';
import { LOGGED_IN, LOGGED_OUT, IMPERSONATE } from './actionTypes';
import { Token } from './model';
import { OrgLogin } from '../../config/api/models/organisation';
import { Organisation } from '../../config/api/types';

export const Actions = {
  login: (token: Token) => createAction(LOGGED_IN, { token }),
  logout: () => createAction(LOGGED_OUT),
  impersonate: (user?: OrgLogin, organisation?: Organisation) =>
    createAction(IMPERSONATE, { user, organisation }),
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type Actions = ActionsUnion<typeof Actions>;
