import React from 'react';
import { Form } from 'react-final-form';
import FundsFormFields from '../components/fundsForm/FundsFormFields';
import { FundDto } from '../../../../config/api/types';
import { RouteComponentProps } from 'react-router';
import { CreateNewFundFetch, FormValues, UpdateFundFetch } from './FundFetchVariants';
import { STATISTICS } from '../../domain';

type Props = {
  prevLocation: string;
  history: RouteComponentProps['history'];
  id?: string;
};

const FundForm = ({ prevLocation, history, id }: Props) => {
  const isUpdate = Boolean(id);
  const FetchComponent = isUpdate ? UpdateFundFetch : CreateNewFundFetch;

  return (
    <FetchComponent prevLocation={prevLocation} history={history} id={id}>
      {({ dispatch, createSubmissionPromise, initialValues }) => (
        <Form
          initialValues={initialValues}
          onSubmit={(values) => {
            const formValues = values as FormValues;

            const fund: FundDto = {
              code: formValues.fullCode,
              description: formValues.description || '',
              isDefault: formValues.isDefault,
              status: formValues.status,
              isAvailable: formValues.isAvailable,
              statisticsCode:
                formValues.statisticsCode === STATISTICS.EMPTY
                  ? undefined
                  : formValues.statisticsCode,
            };
            dispatch(formValues.fundUrl, fund);
            return createSubmissionPromise();
          }}
        >
          {({ handleSubmit, values, submitting, invalid }) => (
            <FundsFormFields
              onSubmit={handleSubmit}
              values={values}
              submitting={submitting}
              invalid={invalid}
              isUpdate={isUpdate}
              isInitiallyDefault={initialValues.isDefault}
            />
          )}
        </Form>
      )}
    </FetchComponent>
  );
};

export default FundForm;
