import React from 'react';
import { useTranslation } from 'react-i18next';
import { PromiseState, ReduxFetch } from 'react-redux-fetch';
import { Redirect } from 'react-router';
import DelayedLoaderForFetch from '../../../../../components/DelayedLoaderForFetch';
import { PollForChanges } from '../../../../../components/PollForChanges/PollForChanges';
import apiRoutes from '../../../../../config/api/routes';
import useApiRoute from '../../../../../config/api/useApiRoute';
import { useStartStop } from '../../../../../helpers/useStartStop';
import DropZone from '../../../../core/dropZone/index';
import { ApiConfig } from '../api';
import MediaFileTable from '../components/MediaFileTable';

type ReduxFetchRenderProps = {
  mediaFilesFetch: PromiseState;
  dispatchMediaFilesGet: (url?: string) => void;
};

const MediaFiles = () => {
  const { t } = useTranslation();
  const { start, stop, started } = useStartStop();
  const mediaFilesApiPath = useApiRoute('titleMediaFiles');

  if (!mediaFilesApiPath) {
    return <Redirect to="/" />;
  }

  const apiPath = `${mediaFilesApiPath}?limit=100`;

  return (
    <ReduxFetch config={[ApiConfig.files(apiPath)]} fetchOnMount>
      {({ mediaFilesFetch, dispatchMediaFilesGet }: ReduxFetchRenderProps) => {
        return (
          <>
            <DropZone
              message={t('media_files_upload')}
              activeMessage=""
              apiRoute={apiRoutes.create(apiPath)}
              accept=".pdf,.jpg,.jpeg,.png,.gif,.txt,.webp"
              onUploadDone={(...args: any) => {
                dispatchMediaFilesGet(...args);
                start();
              }}
            />
            <DelayedLoaderForFetch fetchObj={mediaFilesFetch}>
              <PollForChanges
                query={dispatchMediaFilesGet}
                onStopPolling={stop}
                pollForChanges={started}
                pagedResponse={mediaFilesFetch?.value}
                mediaType="media"
                withoutLoader
              >
                <MediaFileTable
                  mediaFilesFetch={mediaFilesFetch}
                  dispatchMediaFilesGet={dispatchMediaFilesGet}
                />
              </PollForChanges>
            </DelayedLoaderForFetch>
          </>
        );
      }}
    </ReduxFetch>
  );
};

export default MediaFiles;
