import { FormControlLabel, Radio as MuiRadio } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { FieldRenderProps } from 'react-final-form';

type Props = FieldRenderProps<any, any> & { label?: string };

const useStyles = makeStyles(() => ({
  label: {
    width: '100%',
  },
  root: {
    padding: 5,
  },
}));

const Radio = ({
  input: { name, onChange, value, checked, ...restInput },
  meta,
  ...rest
}: Props) => {
  const classes = useStyles();
  return (
    <>
      <FormControlLabel
        control={
          <MuiRadio
            {...rest}
            checked={checked}
            onChange={onChange}
            value={value}
            color="primary"
            classes={{ root: classes.root }}
          />
        }
        label=""
        className={classes.label}
        name={name}
        {...rest}
      />
    </>
  );
};

export default Radio;
