import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Field from '../../../../../../components/Form';
import SelectMenuItem from '../../../../../../components/Form/SelectMenuItem';
import { EBookTypes } from '../../../../../../config/api/types';
import useFetch from '../../../../../../helpers/useFetch';
import { getEBookTypesRequest } from '../../api';
import TitleFormatField from '../../components/sections/TitleFormatField';

type Props = {
  url: string;
  version: 'epub' | 'kindle';
};

const EBookVersionField = ({ url, version }: Props) => {
  const { t } = useTranslation();
  const [eBookTypesFetch, fetchEBookTypes] = useFetch<EBookTypes>(getEBookTypesRequest(url));

  useEffect(() => {
    if (!eBookTypesFetch) {
      fetchEBookTypes();
    }
  }, [eBookTypesFetch, fetchEBookTypes]);

  if (!eBookTypesFetch || !eBookTypesFetch.value) {
    return (
      <Field
        label={t(version === 'epub' ? 'title_productFormEPubType' : 'title_productFormKindleType')}
        type="text"
        name="selectedEBookType.label"
        disabled
      />
    );
  }

  return (
    <TitleFormatField
      component={Field}
      label={t(version === 'epub' ? 'title_productFormEPubType' : 'title_productFormKindleType')}
      type="text"
      name="selectedEBookType.code"
      select
    >
      <SelectMenuItem />
      {eBookTypesFetch.value
        .filter(
          ({ code }) =>
            (version === 'epub' && code.includes('101')) ||
            (version === 'kindle' && code.includes('116'))
        )
        .map((eBookType) => {
          return (
            <SelectMenuItem key={eBookType.code} value={eBookType.code}>
              {eBookType.label}
            </SelectMenuItem>
          );
        })}
    </TitleFormatField>
  );
};

export default EBookVersionField;
