import * as selectors from './selectors';
import Organisations from './scenes/Organisations';
import UsersTab from './scenes/UsersTab';
import Create from './scenes/Create';
import Update from './scenes/Update';
import Membership from './scenes/Membership';
import Distributor from './scenes/Distributor';
import DataProducer from './scenes/DataProducer';
import DataConsumer from './scenes/DataConsumer';
import PublisherTab from './scenes/PublisherTab';
import BookstoreTab from './scenes/BookstoreTab';
import LibraryTab from './scenes/LibraryTab';

const organisationAdmin = {
  scenes: {
    Create,
    Update,
    Users: UsersTab,
    Organisations,
    Membership,
    Distributor,
    DataProducer,
    DataConsumer,
    Publisher: PublisherTab,
    Bookstore: BookstoreTab,
    Library: LibraryTab,
  },
  selectors,
};

export default organisationAdmin;
