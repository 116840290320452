import React, { FC, useCallback } from 'react';
import { Form } from 'react-final-form';
import FacetWrapper from '../../components/Facet/FacetWrapper';
import { useTranslation } from 'react-i18next';
import PublisherField from '../../../admin/title/containers/fields/PublisherField';
import useApiRoute from '../../../../../config/api/useApiRoute';
import AutoSave from '../../../../../components/Form/AutoSave';
import { QueryObjectValue } from '../../../../../helpers/elasticQueryDSL';
import SetInitialValueWhenLoaded from '../../../../../components/Form/SetInitialValueWhenLoaded';
import { getPublishers } from '../../../admin/title/selectors';
import { Publisher } from '../../../../../config/api/types';
import { updateQueryParameters } from '../../../../../helpers/hateoas';

type Props = {
  onFilter: (value: string) => void;
  value?: QueryObjectValue;
};

const PublisherFacet: FC<Props> = ({ onFilter, value }) => {
  const { t } = useTranslation();
  const url = useApiRoute('publisherFacet');

  const findFromValue = useCallback(
    (publisher: Publisher) => publisher.organisation.organisationId === value,
    [value]
  );

  if (!url) {
    return null;
  }

  return (
    <Form
      onSubmit={(formValues) => {
        if (formValues.publisher?.organisation.organisationId !== value) {
          onFilter(formValues.publisher?.organisation.organisationId || null);
        }
      }}
      mutators={{
        reset: (args, state, { changeValue }) => {
          changeValue(state, 'publisher', () => '');
        },
      }}
    >
      {({
        handleSubmit,
        form: {
          submit,
          mutators: { reset },
        },
        values,
      }) => (
        <FacetWrapper
          title={t('facet_publisher')}
          collapsible={false}
          active={Boolean(values['publisher'])}
        >
          <form onSubmit={handleSubmit}>
            <AutoSave save={submit} />
            <PublisherField
              url={updateQueryParameters(url, { is_active: 'true' })}
              label=" "
              blurInputOnSelect
              isClearable
              onClearValue={submit}
              placeholder={t('facet_publisher_placeholder')}
              activeFilterValue={value}
              reset={reset}
            />
            {value && (
              <SetInitialValueWhenLoaded
                name="publisher"
                dataSelector={getPublishers}
                findValue={findFromValue}
              />
            )}
          </form>
        </FacetWrapper>
      )}
    </Form>
  );
};

export default PublisherFacet;
