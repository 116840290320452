import { Grid, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Radio } from '../../../components/Form';
import { PrefixApplicationDto, User } from '../../../config/api/types';
import { IsbnPrices } from '../domain';

type Props = {
  user?: User;
  values: Partial<PrefixApplicationDto>;
};

const PrefixForm = ({ user, values }: Props) => {
  const { t } = useTranslation();

  return (
    <Grid item container direction="column" spacing={3}>
      <Grid item container direction="column" spacing={1}>
        <Grid item>
          <Typography>{t('form_new_prefix_subText')}</Typography>
        </Grid>

        <Grid item>
          <Radio
            label={t('form_prefix_radio_1', { amount: IsbnPrices[1] })}
            name="requestIsbnNumbers"
            value="1"
            size="small"
          />
          <Radio
            label={t('form_prefix_radio_10', { amount: IsbnPrices[10] })}
            name="requestIsbnNumbers"
            value="10"
            size="small"
          />
          <Radio
            label={t('form_prefix_radio_100', { amount: IsbnPrices[100] })}
            name="requestIsbnNumbers"
            value="100"
            size="small"
          />
        </Grid>

        <Grid item>
          <Typography>{t('form_isbn_prefix_new_helpText')}</Typography>
          <Typography>
            {t('for_more_info')}{' '}
            <a
              href="http://www.meta4books.be/isbn-tarieven"
              target="_blank"
              rel="noopener noreferrer"
            >
              http://www.meta4books.be/isbn-tarieven
            </a>
            .
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PrefixForm;
