import { Box, Typography } from '@mui/material';
import React from 'react';
import InfoTooltip from '../../../../components/InfoTooltip';
import { TitleLiteraturePrize } from '../../../../config/api/types';
import usePrizeIconMap from '../../../../helpers/usePrizeIconMap';

type Props = {
  awards: TitleLiteraturePrize[];
};

const Prizes = ({ awards }: Props) => {
  const iconMap = usePrizeIconMap();

  return (
    <>
      {awards.map(({ literaturePrize, result, year, code }) => (
        <Box
          display="flex"
          alignItems="center"
          mt={0.5}
          key={`${literaturePrize.id}-${code}-${year}`}
        >
          {iconMap[code]}
          <Typography>
            {result} - {literaturePrize.name}
            <InfoTooltip title={literaturePrize.description} maxWidth={500} />
            {Boolean(year) && ` - ${year}`}
          </Typography>
        </Box>
      ))}
    </>
  );
};

export default Prizes;
