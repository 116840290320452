import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useFetch } from '@react-redux-fetch/hooks';
import Button from '../../../../../components/Button';
import { generateNewApiKeyRequest } from '../../../api';

type GenerateNewKeyProps = {
  disabled: boolean;
  url?: string;
};

export const GenerateNewKeyButton: FC<GenerateNewKeyProps> = ({ disabled, url }) => {
  const { t } = useTranslation();
  const [promiseState, generateNewApiKey] = useFetch(generateNewApiKeyRequest);

  const handleClick = () => {
    if (url) {
      generateNewApiKey(url);
    }
  };

  return (
    <Button disabled={disabled || promiseState?.pending} onClick={handleClick}>
      {t('organisation_edit_data_consumer_generate_key')}
    </Button>
  );
};
