import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Theme } from '@mui/material';
import Typography from '@mui/material/Typography';
import { WithStyles, createStyles, withStyles } from '@mui/styles';
import cxs from 'classnames';
import length from 'ramda/es/length';
import values from 'ramda/es/values';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { isJSON } from '../../../../helpers/isJson';

const styles = (theme: Theme) =>
  createStyles({
    error: {
      transition: '1s',
      backgroundColor: theme.palette.error.dark,
      marginBottom: theme.spacing(1),
    },
    root: {
      maxWidth: '100%',
    },
    heading: {
      color: theme.palette.primary.contrastText,
    },
    cta: {
      fontWeight: 600,
      marginLeft: theme.spacing(1),
      cursor: 'pointer',
    },
    details: {
      display: 'block',
    },
    detailsInnerWrapper: {
      marginTop: theme.spacing(2),
    },
    errorToggle: {
      display: 'flex',
      alignItems: 'center',
    },
    filename: {
      fontWeight: 600,
    },
    expandIcon: {
      color: theme.palette.primary.contrastText,
    },
    text: {
      color: theme.palette.primary.contrastText,
    },
    errormessage: {},
  });

type Props = WithStyles<typeof styles> & {
  errors: { [s: string]: { response: string; code: number } };
};

const FileError = ({ errors, classes }: Props) => {
  const { t } = useTranslation();

  return (
    <Accordion className={classes.error}>
      <AccordionSummary expandIcon={<ExpandMoreIcon className={classes.expandIcon} />}>
        <Typography className={classes.heading}>
          {t('form_files_error_with_count', { count: length(values(errors)) })}
        </Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.details}>
        {Object.keys(errors).map((fileName) => {
          const messages = isJSON(errors[fileName].response)
            ? JSON.parse(errors[fileName].response)
            : '';

          return (
            <div key={fileName} className={classes.detailsInnerWrapper}>
              <Typography className={cxs(classes.filename, classes.text)}>
                {decodeURI(fileName)}:
              </Typography>
              {(Array.isArray(messages) ? messages : [messages]).map(
                (message: any, index: number) => (
                  <Typography key={index} className={cxs(classes.text, classes.errormessage)}>
                    {t(message.message) || JSON.stringify(message)}
                  </Typography>
                )
              )}
            </div>
          );
        })}
      </AccordionDetails>
    </Accordion>
  );
};

export default withStyles(styles)(FileError);
