import React from 'react';
import Button, { ButtonProps } from '../../../../components/Button';
import useFileExport from '../../../../helpers/useFileExport';
import { FetchConfig } from '@react-redux-fetch/core';

type Props = ButtonProps & {
  url: string;
  withInitiation?: boolean;
  fetchConfig?: Partial<FetchConfig>;
  interval?: number;
  onSuccess?: () => void;
  queryKey?: string;
};

const ExportButton = ({
  children,
  url,
  withInitiation,
  onClick,
  disabled,
  fetchConfig,
  interval,
  onSuccess,
  queryKey,
  ...props
}: Props) => {
  const [exportInProgress, initiateExport] = useFileExport(
    url,
    withInitiation,
    interval,
    onSuccess,
    fetchConfig,
    queryKey
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    initiateExport();
    if (onClick) {
      onClick(event);
    }
  };

  return (
    <Button {...props} onClick={handleClick} disabled={disabled || exportInProgress}>
      {children}
    </Button>
  );
};

export default ExportButton;
