import MuiClearIcon from '@mui/icons-material/Clear';
import { ListItem, Theme } from '@mui/material';
import { styled } from '@mui/styles';
import React, { ComponentType, PropsWithChildren } from 'react';

type TreeNodeProps = typeof ListItem.arguments & {
  isSelected?: boolean;
  theme?: Theme;
};
export const TreeNode = styled<ComponentType<TreeNodeProps>>(({ isSelected, ...other }) => (
  <ListItem dense {...other} />
))(({ isSelected, theme }: TreeNodeProps) => {
  const shared = {
    '& .MuiListItemText-dense, & .MuiTypography-root': {
      lineHeight: 1,
    },
  };
  return isSelected
    ? {
        ...shared,
        backgroundColor: theme?.palette.background.appbar,
        color: theme?.palette.primary.main,
      }
    : shared;
});

export const TreeIndent = styled('span')({
  alignSelf: 'stretch',
  whiteSpace: 'nowrap',
  userSelect: 'none',
});

type TreeIndentUnitProps = {
  isOpen?: boolean;
  theme?: Theme;
};
export const TreeIndentUnit = styled<ComponentType<PropsWithChildren<TreeIndentUnitProps>>>(
  ({ isOpen, ...other }) => <span {...other} />
)(({ isOpen }: TreeIndentUnitProps) => ({
  position: 'relative',
  height: '100%',
  display: 'inline-block',
  zIndex: 99,

  '&:not(:first-child)': {
    width: 24,
  },

  '&::before': isOpen
    ? {
        position: 'absolute',
        top: 'calc(100% - 4px)',
        right: -28,
        bottom: -32,
        borderRight: '1px solid #d9d9d9',
        content: '""',
      }
    : {},
}));

export const ArrowPlaceholder = styled('div')({
  width: 56,
  height: '100%',
});

export const ClearIcon = styled(MuiClearIcon)(({ theme }: { theme: Theme }) => ({
  color: theme.palette.grey['700'],
  padding: 5,
  fontSize: 26,
  position: 'absolute',
  right: 60,
  '&:hover': {
    color: theme.palette.common.black,
    cursor: 'pointer',
  },
}));
