import { RootState } from '../../../../config/store/types';
import { getUser } from '../../../security/selectors';

export const getUnconfirmedRetourUrl = (state: RootState): string | undefined => {
  const user = getUser(state);

  return user?._embedded?.bookstore?._links?.unConfirmedRetour?.href;
};

export const getUnconfirmedRetour = (state: RootState) => state.data.unconfirmedRetour;
