import { Box, Grid } from '@mui/material';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Button from '../../../../components/Button';
import { TitleFull } from '../../../../config/api/types';
import useFetch from '../../../../helpers/useFetch';
import { selectTitle } from '../../../title/admin/title/selectors';
import { OptionContent } from '../../../title/catalog/components/Search/Option';
import { ApiConfig as DetailApiConfig } from '../../../title/detail/api';

type Props = {
  onSubmit: (title: TitleFull) => void;
};

const EditTitle = ({ onSubmit }: Props) => {
  const foundTitle = useSelector(selectTitle);
  const { t } = useTranslation();
  const titleDetailUrl = foundTitle?._links.self.href;
  const getTitleConfig = DetailApiConfig.getTitle(titleDetailUrl || '');
  const [titleFetch, fetchTitle] = useFetch<TitleFull>(getTitleConfig);

  useEffect(() => {
    if (titleDetailUrl) {
      fetchTitle();
    }
  }, [fetchTitle, titleDetailUrl]);

  const handleSubmit = () => {
    if (titleFetch?.fulfilled && titleFetch.value) {
      onSubmit(titleFetch.value);
    }
  };

  if (!foundTitle) {
    return null;
  }

  return (
    <Grid container spacing={2} direction="column">
      <Grid item>
        <Box display="flex" alignItems="center">
          <OptionContent title={foundTitle} />
        </Box>
      </Grid>
      <Grid item>
        <Button onClick={handleSubmit} disabled={!titleFetch?.fulfilled}>
          {t('cta_apply_data_gtin13')}
        </Button>
      </Grid>
    </Grid>
  );
};
export default EditTitle;
