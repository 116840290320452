import * as React from 'react';
import { Route, Redirect, RouteProps, RouteComponentProps } from 'react-router-dom';

type PublicRouteProps = RouteProps & {
  isAuthenticated: boolean;
};

const PublicRoute = ({ component, isAuthenticated, ...other }: PublicRouteProps) => {
  if (!component) {
    throw new Error('Prop "component missing" in PublicRoute');
  }
  return (
    <Route
      {...other}
      render={(props: RouteComponentProps) =>
        // @ts-ignore
        isAuthenticated ? <Redirect to="/" /> : React.createElement(component, props)
      }
    />
  );
};

export default PublicRoute;
