import React, { useEffect } from 'react';
import BaseReactSelectField from '../../../../../components/Form/BaseReactSelectField';
import { useTranslation } from 'react-i18next';
import { useFetch } from '@react-redux-fetch/hooks';
import { getOrderFileTypesRequest } from '../../../api';
import { getOrderFileTypes } from '../../selectors';
import { useSelector } from 'react-redux';
import { OrderFileType } from '../../../../../config/api/models/dataSets';

type Props = {
  url: string;
};

const OrderFileTypeField = ({ url }: Props) => {
  const { t } = useTranslation();
  const [, fetchOrderFileTypes] = useFetch(getOrderFileTypesRequest);
  const orderFileTypes = useSelector(getOrderFileTypes);

  useEffect(() => {
    if (!orderFileTypes) {
      fetchOrderFileTypes(url);
    }
  }, [orderFileTypes, fetchOrderFileTypes, url]);

  if (!orderFileTypes) {
    return null;
  }

  return (
    <BaseReactSelectField
      label={t('form_order_file_type')}
      name="orderFileType"
      options={orderFileTypes}
      getOptionLabel={(option: OrderFileType) => option.name}
      getOptionValue={(option: OrderFileType) => option.code}
      isClearable
    />
  );
};

export default OrderFileTypeField;
