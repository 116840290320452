import { Divider, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/Button';
import ROUTE_KEY from '../../../config/routes/routeKeys';
import usePath from '../../../config/routes/usePath';
import OrganisationForm from '../components/OrganisationForm';
import UserForm from '../components/UserForm';
import { PrefixSteppedFormProps } from '../types';

const useStyles = makeStyles(() => ({
  divider: {
    marginBottom: 5,
  },
}));

const PrefixOrganisationStep = ({
  user,
  changeStep,
  submitting,
  submitErrors,
  values,
  dirtyFieldsSinceLastSubmit,
  ...props
}: PrefixSteppedFormProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const cancelLink = usePath(user ? ROUTE_KEY.ISBN : ROUTE_KEY.LOGIN);
  const emailUniqueError =
    submitErrors?.login?.email === t('not_unique') && !dirtyFieldsSinceLastSubmit['login.email'];

  return (
    <Grid container spacing={3} direction="column">
      <Grid item xs={12} sx={{ width: { md: '58.33%' } }}>
        <UserForm user={user} EmailUniqueError={emailUniqueError} formType="prefix" />
      </Grid>

      <Grid item xs={12} sx={{ width: { md: '58.33%' } }}>
        <Divider className={classes.divider} />
        <OrganisationForm user={user} formType="prefix" />
      </Grid>

      <Grid
        container
        xs={12}
        sx={{ width: { md: '58.33%' }, justifyContent: 'space-between' }}
        item
      >
        <Grid item>
          <Button link={cancelLink} variant="outlined" disabled={submitting}>
            {t('form_confirm_disagree')}
          </Button>
        </Grid>
        <Grid item>
          <Button type="submit" disabled={submitting}>
            {t('form_next')}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PrefixOrganisationStep;
