import CloseIcon from '@mui/icons-material/Close';
import CircleIcon from '@mui/icons-material/FiberManualRecord';
import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import cxs from 'classnames';
import React, { Ref } from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  statusLabel: {
    display: 'flex',
  },
  icon: {
    marginRight: 5,
    width: 20,
    height: 20,
  },
  red: { color: theme.palette.messages.error },
  orange: { color: theme.palette.messages.warning },
  green: { color: theme.palette.buttons.highlight },
  grey: { color: theme.palette.buttons.lightDisabled },
  transparent: {
    color: 'transparent',
    '& circle': { stroke: theme.palette.messages.error },
  },
}));

export type StatusLabelStatus = 'fail' | 'error' | 'warning' | 'success' | 'default' | 'empty';

export type StatusLabelProps = {
  status: StatusLabelStatus;
  children?: React.ReactChild;
};

const StatusLabel = React.forwardRef(
  ({ status, children }: StatusLabelProps, ref: Ref<HTMLSpanElement>) => {
    const classes = useStyles();

    const statusComponent = (() => {
      switch (status) {
        case 'fail':
          return <CloseIcon className={cxs(classes.icon, classes.red)} />;

        case 'error':
          return <CircleIcon className={cxs(classes.icon, classes.red)} />;

        case 'warning':
          return <CircleIcon className={cxs(classes.icon, classes.orange)} />;

        case 'success':
          return <CircleIcon className={cxs(classes.icon, classes.green)} />;

        case 'empty':
          return <CircleIcon className={cxs(classes.icon, classes.transparent)} />;

        default:
          return <CircleIcon className={cxs(classes.icon, classes.grey)} />;
      }
    })();

    return (
      <span className={classes.statusLabel} ref={ref}>
        {statusComponent} <span>{children}</span>
      </span>
    );
  }
);

export default StatusLabel;
