type Obj = {
  [key: string]: string | Obj;
};

export const flattenObjectKeys = (obj: Obj): string[] => {
  // @ts-ignore
  return Object.keys(obj).reduce((agg, key): string[] => {
    const value = obj[key];
    if (typeof value === 'string') {
      return [...agg, key];
    }
    return [...agg, ...flattenObjectKeys(value).map((subKey) => `${key}.${subKey}`)];
  }, []);
};

export default flattenObjectKeys;
