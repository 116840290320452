import ShoppingCartPage from './scenes/ShoppingCartPage';
import AddToCart from './components/AddToCart';
import CartSummary from './components/CartSummary';

const cart = {
  components: {
    AddToCart,
    CartSummary,
  },
  scenes: {
    ShoppingBasket: ShoppingCartPage,
  },
};

export default cart;
