import { Box } from '@mui/material';
import React, { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { PromiseState } from 'react-redux-fetch';
import { useLocation } from 'react-router-dom';
import { SearchField } from '../../../../../components/Form';
import TableFilterWrapper from '../../../../../components/Table/TableFilterWrapper';
import Table from '../../../../../components/Table/index';
import { CellType, DataType } from '../../../../../components/Table/types';
import { Organisations, UserRole } from '../../../../../config/api/types';
import getIdFromUrl from '../../../../../helpers/getIdFromUrl';
import {
  getLink,
  retrieveQueryParameter,
  updateQueryParameter,
  updateQueryParameters,
} from '../../../../../helpers/hateoas';
import urlencode from '../../../../../helpers/urlencode';
import useQueryParams from '../../../../../helpers/useQueryParams';
import GbpFilter from '../../containers/organisations/filters/GbpFilter';
import IsCbMember from '../../containers/organisations/filters/IsCbMember';
import IsMember from '../../containers/organisations/filters/IsMember';
import RoleEnabledFilter from '../../containers/organisations/filters/RoleEnabledFilter';
import RolesFilter from '../../containers/organisations/filters/RolesFilter';

type ReduxFetchRenderProps = {
  organisationsFetch: PromiseState<Organisations>;
  dispatchOrganisationsGet: (url?: string) => void;
};

type OrganisationQueryParams = {
  order: string;
  order_dir: OrderDir;
  is_member: '' | 'true' | 'false';
  is_cb_member: string;
  role: string;
  roles: string;
  q: string;
  name: string;
  page: string;
  limit: string;
  gbp: '' | 'true' | 'false';
};

type Props = ReduxFetchRenderProps & {};
type OrderDir = 'asc' | 'desc' | undefined;

const OrganisationsTable = ({ organisationsFetch, dispatchOrganisationsGet }: Props) => {
  const { t } = useTranslation();
  const pagedOrganisations = organisationsFetch.value;
  const url = getLink(pagedOrganisations, 'self') || '';

  const { queryObj } = useQueryParams<OrganisationQueryParams>(url);
  const locationParams = useLocation<{ keepFilters?: boolean }>();
  const keepFilters = locationParams.state?.keepFilters;

  const headers = [
    { id: 'name', label: t('table_headers_name'), sortable: true },
    { id: 'companyRegistrationNumber', label: t('table_headers_company_registration_number') },
    {
      id: 'hasMembership',
      label: t('table_headers_company_member'),
      sortable: true,
    },
    { id: 'roles', label: t('table_headers_roles') },
    { id: 'countryIso', label: t('table_headers_country') },
  ];

  if (!pagedOrganisations) {
    return null;
  }
  const data: DataType = {
    resource: 'organisations',
    rows: pagedOrganisations._embedded.items.map((organisation) => ({
      id: getLink(organisation, 'self') || '',
      link: `/administratie/organisatie/${getIdFromUrl(getLink(organisation, 'self') || '')}`,
      cells: [
        {
          type: CellType.Text,
          data: organisation.name,
        },
        {
          type: CellType.Text,
          data: organisation.membershipRegistrationNumber,
        },
        {
          type: CellType.Boolean,
          data: organisation.hasMembership,
        },
        {
          type: CellType.Array,
          data: organisation.roles
            ? Object.keys(organisation.roles).map((role) => {
                return (
                  <span
                    style={
                      organisation.roles?.[role as UserRole] === false
                        ? { textDecoration: 'line-through' }
                        : {}
                    }
                  >
                    {t(role)}
                  </span>
                );
              })
            : [],
        },
        {
          type: CellType.Text,
          data: organisation.countryIso,
        },
      ],
    })),
  };

  const handleOnRolesChange = (event: ChangeEvent<{ name?: string; value: unknown }>) =>
    dispatchOrganisationsGet(
      updateQueryParameters(url, { role: (event.target.value as string) || '', roles: '' })
    );

  const handleRoleEnabledChange = (event: ChangeEvent<{ name?: string; value: unknown }>) => {
    const value = event.target.value as string;

    dispatchOrganisationsGet(
      updateQueryParameter(url, 'roles', value ? `${[queryObj.role]}:${value}` : '')
    );
  };

  const handleIsMemberChange = (event: ChangeEvent<{ name?: string; value: unknown }>) =>
    dispatchOrganisationsGet(
      updateQueryParameter(url, 'is_member', (event.target.value as string) || '')
    );

  const handleIsCbMemberChange = (event: ChangeEvent<{ name?: string; value: unknown }>) =>
    dispatchOrganisationsGet(
      updateQueryParameter(url, 'is_cb_member', (event.target.value as string) || '')
    );

  const handleGbpChange = (event: ChangeEvent<{ name?: string; value: unknown }>) =>
    dispatchOrganisationsGet(
      updateQueryParameter(
        url,
        'uses_regulated_price_algorithm',
        (event.target.value as string) || ''
      )
    );

  return (
    <>
      <TableFilterWrapper>
        <Box flex={1}>
          <SearchField
            placeholder={t('form_placeholder_organisation_search')}
            searchCallback={(term: string) => {
              dispatchOrganisationsGet(updateQueryParameter(url, 'q', urlencode(term)));
            }}
            initialValue={keepFilters ? queryObj.q : undefined}
            isClearable
            autoFocus
          />
        </Box>

        <RolesFilter
          onChange={handleOnRolesChange}
          initialValue={keepFilters ? queryObj.role : undefined}
        />

        {queryObj.role && (
          <RoleEnabledFilter
            onChange={handleRoleEnabledChange}
            value={queryObj.roles.replace(/[a-zA-Z_]*:/, '')}
          />
        )}

        <IsMember
          onChange={handleIsMemberChange}
          initialValue={keepFilters ? queryObj.is_member : undefined}
        />
        <IsCbMember
          onChange={handleIsCbMemberChange}
          initialValue={keepFilters ? queryObj.is_cb_member : undefined}
        />
        <GbpFilter
          onChange={handleGbpChange}
          initialValue={keepFilters ? queryObj.gbp : undefined}
        />
      </TableFilterWrapper>
      <Table
        data={data}
        headers={headers}
        limit={pagedOrganisations.limit}
        page={pagedOrganisations.page}
        pages={pagedOrganisations.pages}
        total={pagedOrganisations.total}
        orderBy={retrieveQueryParameter(url, 'order') || 'name'}
        orderDir={(retrieveQueryParameter(url, 'orderDir') || 'asc') as OrderDir}
        onSortChange={(orderBy, orderDir) => {
          if (!orderBy || !orderDir) {
            return;
          }
          let newUrl = updateQueryParameter(url, 'order', orderBy);
          newUrl = updateQueryParameter(newUrl, 'orderDir', orderDir);
          dispatchOrganisationsGet(newUrl);
        }}
        dispatchFn={dispatchOrganisationsGet}
        pagedResource={pagedOrganisations}
      />
    </>
  );
};

export default OrganisationsTable;
