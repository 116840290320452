import { Theme } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import { DateFieldProps, DatePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import * as React from 'react';
import { FieldRenderProps } from 'react-final-form';
import { UI_DATE_FORMAT } from '../../helpers/date';
import { TextFieldProps } from './TextField';

type Props = FieldRenderProps<any, any> &
  DateFieldProps<any> &
  TextFieldProps & {
    customError?: string;
    onBlur?: () => void;
    isClearable?: boolean;
    onClear?: () => void;
  };

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(2),
  },
  label: {
    // color: theme.palette.text.primary,
    // fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
  },
  icon: {
    padding: 4,
  },
  helperText: {
    // color: theme.palette.text.helpertext,
  },
  input: {
    padding: '10px 10px',
    background: 'white',
    marginRight: -13,
  },
  clearIconWrapper: {
    background: 'white',
    marginRight: '-1rem',
    height: 36,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  clearIcon: {
    color: theme.palette.grey['700'],
    padding: 5,
    fontSize: 26,
    '&:hover': {
      color: theme.palette.common.black,
      cursor: 'pointer',
    },
  },
  inputAdornment: {
    marginRight: 0,
    marginLeft: 4,
  },
}));

const toErrorString = (value: any): string => {
  if (!value || typeof value === 'string') {
    return value;
  }
  return 'error';
};

const DateField = ({
  input: { name, onChange, onBlur: inputBlur, value, ...restInput },
  meta,
  helperText,
  customError,
  label,
  disabled,
  onBlur,
  isClearable,
  onClear,
  onAccept,
  ...rest
}: Props) => {
  const classes = useStyles();

  return (
    <>
      <InputLabel className={classes.label}>{label}</InputLabel>
      <DatePicker
        {...rest}
        disabled={disabled}
        name={name}
        format={UI_DATE_FORMAT}
        onAccept={onAccept}
        slots={{
          textField: TextField,
        }}
        onChange={onChange}
        onError={() => {}}
        slotProps={{
          field: {
            clearable: isClearable,
            onClear: () => {
              onChange?.(null);
              onClear?.();
            },
            format: UI_DATE_FORMAT,
            shouldRespectLeadingZeros: false,
          },
          textField: {
            ...rest,
            helperText: toErrorString(customError || meta.error || meta.submitError || helperText),
            error:
              (Boolean(meta.error) && meta.touched) ||
              Boolean(meta.submitError) ||
              Boolean(customError),
            variant: 'outlined',
            onBlur: (event: any) => {
              if (onBlur) {
                onBlur();
              }
              inputBlur(event as any);
            },
            InputLabelProps: {
              shrink: true,
            },
            margin: 'normal',
            FormHelperTextProps: {
              classes: {
                root: classes.helperText,
              },
            },
            inputProps: {
              className: classes.input,
            },
          },
          inputAdornment: { position: 'start', className: classes.inputAdornment },
          clearButton: {
            sx: {
              backgroundColor: 'white',
              borderRadius: 0,
              border: 0,
            },
          },
        }}
        className={classes.root}
        // mask={input => (input ? [/\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/] : [])}
        value={value ? moment(value) : null}
      />
    </>
  );
};

export default DateField;
