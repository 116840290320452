import { Theme } from '@mui/material';
import MUITablePagination, { TablePaginationProps } from '@mui/material/TablePagination';
import { WithStyles, createStyles, withStyles } from '@mui/styles';
import React from 'react';

type Props = WithStyles<typeof styles> & TablePaginationProps & {};

const styles = (theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.text.primary,
      border: 'none',
    },
    select: {
      fontSize: 12,
    },
    selectIcon: {
      paddingLeft: theme.spacing(0.5),
    },
  });

const TablePagination = ({
  classes,
  rowsPerPage,
  page,
  count,
  onPageChange,
  onRowsPerPageChange,
  rowsPerPageOptions,
}: Props) => {
  return (
    <MUITablePagination
      className={classes.root}
      rowsPerPageOptions={rowsPerPageOptions || [50, 100, 150, 200]}
      count={count}
      // component="div"
      rowsPerPage={rowsPerPage}
      labelRowsPerPage="Rijen per pagina"
      page={page}
      backIconButtonProps={{
        'aria-label': 'Previous Page',
      }}
      nextIconButtonProps={{
        'aria-label': 'Next Page',
      }}
      // classes={{
      //   select: classes.select,
      //   selectIcon: classes.selectIcon,
      // }}
      onPageChange={onPageChange}
      onRowsPerPageChange={onRowsPerPageChange}
    />
  );
};

export default withStyles(styles)(TablePagination);
