import rootReducer from './rootReducer';
import App from './containers/App';
import setBearerAndFetchUser from './middleware/setBearerAndFetchUser';
import logoutOn401 from './middleware/logoutOn401';
import alertOnServerResponse from './middleware/alertOnServerResponse';
import impersonateMiddleware from './middleware/impersonateMiddleware';
import jwtMiddleware from './middleware/jwtMiddleware';

const AppModule = {
  rootReducer,
  containers: {
    App,
  },
  middleware: [
    setBearerAndFetchUser,
    logoutOn401,
    alertOnServerResponse,
    jwtMiddleware,
    impersonateMiddleware,
  ],
};

export default AppModule;
