import { FetchConfig } from '@react-redux-fetch/core';
import { Data } from '../../../../config/store/types';
import apiRoutes from '../../../../config/api/routes';

type SubmitRetourRequest = {
  confirmed: true;
  reference: string;
};

export const getSubmitRetourRequest = (
  url: string,
  body: SubmitRetourRequest
): FetchConfig<Data> => {
  return {
    method: 'PATCH',
    url: apiRoutes.create(url),
    requestKey: 'patchUnconfirmedRetour',
    fetchOptions: {
      body,
    },
    repository: {
      unconfirmedRetour: (prev, next) => next,
    },
  };
};
