import React from 'react';
import OrganisationWrapper from '../containers/OrganisationWrapper';
import { RouteComponentProps } from 'react-router-dom';
import Library from '../containers/library/Library';

type Params = { id: string };
type Props = RouteComponentProps<Params> & {};

const LibraryTab = (props: Props) => {
  return (
    <OrganisationWrapper {...props}>
      {({ organisation }) => <Library organisation={organisation} />}
    </OrganisationWrapper>
  );
};

export default LibraryTab;
