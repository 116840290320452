import { Grid } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import PageTitle from '../../../../components/PageTitle';
import { BookstoresTableWrapper } from '../components/BookstoresTableWrapper';

const BookstoresListPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <PageTitle>{t('page_title_bookstores')}</PageTitle>
        </Grid>
      </Grid>

      <BookstoresTableWrapper />
    </>
  );
};

export default BookstoresListPage;
