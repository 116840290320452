import ElasticPublisher from './components/ElasticPublisher';
import Publisher from './components/Publisher';
import PublishersListPage from './scenes/PublishersListPage';

const publisher = {
  components: {
    Publisher,
    ElasticPublisher,
  },
  scenes: {
    PublishersListPage,
  },
};

export default publisher;
