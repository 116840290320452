import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { InputAdornment, ListItemText, Popover } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { FC, useState } from 'react';
import { FieldRenderProps } from 'react-final-form';
import { useIsVisible } from 'react-is-visible';
import { BaseTextField } from '../../../../../../components/Form/TextField';
import TreeSelect from '../../../../../../components/Form/TreeSelect/TreeSelect';
import { Nur } from '../../../../../../config/api/models/dataSets';
import { useNurs } from '../../../../../thema';
import SearchableTreeSelect from './SearchableTreeSelect';

type NurFieldProps = {
  value: Nur;
  onChange: (nur: Nur) => void;
  label: React.ReactNode;
};

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiInputBase-adornedEnd': {
      backgroundColor: 'white',
    },
  },
}));

const filterTreeNode = (inputValue: string, nur: Nur) =>
  nur.label.toLowerCase().includes(inputValue.toLowerCase()) || nur.code.includes(inputValue);
const getLabel = (nur: Nur) => <ListItemText primary={`${nur.code} - ${nur.label}`} />;

const NurField: FC<NurFieldProps> = ({ value, onChange, label }) => {
  const { nurs, hasAccess } = useNurs();

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [searchValue, handleSearch] = useState('');

  if (!hasAccess) {
    return null;
  }

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleSelect = (item: Nur) => {
    handleClose();
    onChange(item);
  };

  return (
    <div>
      <BaseTextField
        value={value ? `${value?.code} - ${value?.label}` : ''}
        onClick={handleClick}
        label={label}
        inputProps={{ readOnly: true }}
        classes={{ root: classes.root }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <ArrowDropDownIcon />
            </InputAdornment>
          ),
        }}
      />
      <Popover open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={handleClose}>
        {nurs && (
          <TreeSelect
            treeData={nurs}
            searchValue={searchValue}
            onSearch={handleSearch}
            treeDefaultExpandAll={Boolean(searchValue)}
            onSelect={handleSelect}
            getLabel={getLabel}
            filterTreeNode={filterTreeNode}
            width={anchorEl?.clientWidth}
            value={value}
          />
        )}
      </Popover>
    </div>
  );
};

export const NurFieldAdapter = ({
  input: { name, onChange, value },
  meta: { submitError, submitFailed, error },
  label,
}: FieldRenderProps<any>) => {
  const nodeRef = React.useRef(null);
  const isVisible = useIsVisible(nodeRef);

  const { nurs, hasAccess } = useNurs({ initiate: isVisible });

  if (!nurs || !hasAccess) {
    return <div ref={nodeRef}>&nbsp;</div>;
  }

  return (
    <SearchableTreeSelect
      closeOnSelect
      value={value}
      treeData={nurs}
      onSelect={onChange}
      label={label}
      error={(submitFailed && error) || submitError}
    />
  );
};

export default NurField;
