import { IsbnFormValues } from './types';
import { IsbnDTO, TitleFull } from '../../config/api/types';
import { mapFormValues } from '../title/admin/title/dataMapper';

export const IsbnFormToDto = (values: IsbnFormValues): IsbnDTO => {
  const { login, organisation, prefix, requestIsbnNumbers, title, ...rest } = values;
  const dto = {
    login,
    organisation,
    prefix,
    requestIsbnNumbers,
    title: mapFormValues({ title, ...rest }).toTitleDTO() as TitleFull,
  };
  return dto;
};
