import ErrorIcon from '@mui/icons-material/Error';
import { Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  alertStatus: {
    marginBottom: '0.5rem',
    lineHeight: 2,
    padding: `0 ${theme.spacing(2)}`,
    textTransform: 'uppercase',
    fontWeight: theme.typography.fontWeightMedium,
    '& > svg': {
      verticalAlign: 'middle',
      marginRight: theme.spacing(1),
    },
    backgroundColor: theme.palette.status.notAvailable,
    color: theme.palette.status.notAvailableText,
  },
}));

type Props = {
  label: string;
};

const AlertStatus = ({ label }: Props) => {
  const classes = useStyles();

  return (
    <Typography className={classes.alertStatus}>
      <ErrorIcon fontSize="small" />
      {label}
    </Typography>
  );
};

export default AlertStatus;
