const themeVariables = {
  icons: {
    width: 35,
    height: 35,
  },
  drawer: {
    width: 292,
  },
  toolbar: {
    height: 60,
  },
};

export default themeVariables;
