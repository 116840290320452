import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFetch } from '@react-redux-fetch/hooks';
import { useSelector } from 'react-redux';
import FormTitle from '../../components/FormTitle';
import OrgLoginsTable from '../../components/Users/OrgLoginsTable';
import { getLoginsRequest, postUserTransfer, validateUserTransfer } from '../../../api';
import { Organisation } from '../../../../../config/api/types';
import { getPagedOrgLogins } from '../../../selectors';
import { AddLogin } from './AddLogin';
import { OrgLogin } from '../../../../../config/api/models/organisation';
import SelectNewOrganisation from '../../../../isbn/admin/components/SelectNewOrganisation';
import Table from '../../../../../components/Table';
import useUserTableData from '../../hooks/useUserTableData';
import AlertMessage from '../../../../core/alerts/components/AlertMessage';

type Props = {
  organisation?: Organisation;
};

const Users: FC<Props> = ({ organisation }) => {
  const { t } = useTranslation();
  const [loginsFetch, fetchLogins] = useFetch(getLoginsRequest);
  const [validateUserTransferGetRequest, validateUserTransferGet] = useFetch(validateUserTransfer);
  const [userTransferPostRequest, userTransferPost] = useFetch(postUserTransfer);
  const url = organisation?._links.logins?.href;
  const orgLogins = useSelector(getPagedOrgLogins);
  const [switchingUser, setSwitchingUser] = useState<OrgLogin | null>(null);
  const [switchingUserToBeValidated, setSwitchingUserToBeValidated] = useState<OrgLogin | null>(
    null
  );
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);

  const { headers, body } = useUserTableData(
    setSwitchingUser,
    true,
    organisation,
    switchingUser != null ? [switchingUser] : undefined,
    null,
    undefined,

    undefined
  );

  useEffect(() => {
    if (userTransferPostRequest?.fulfilled) {
      setSuccessMessage(t('organisation_switch_success'));
      setSwitchingUser(null);
      fetchLogins(url!);
    } else if (userTransferPostRequest?.rejected) {
      setSwitchingUser(null);
      setErrorMessage(userTransferPostRequest?.reason?.cause?.message);
    }
  }, [userTransferPostRequest, t, url, fetchLogins]);

  useEffect(() => {
    if (validateUserTransferGetRequest?.fulfilled) {
      if (switchingUserToBeValidated == null) return;
      setSwitchingUser(switchingUserToBeValidated);
      setSwitchingUserToBeValidated(null);
    } else if (validateUserTransferGetRequest?.rejected) {
      setErrorMessage(validateUserTransferGetRequest?.reason?.cause?.message);
    }
  }, [validateUserTransferGetRequest, switchingUserToBeValidated]);

  useEffect(() => {
    if (url) {
      fetchLogins(url);
    }
  }, [fetchLogins, url]);

  const validateAndsetSwitchingUser = (user: OrgLogin | null) => {
    if (user == null) {
      setSwitchingUser(null);
    } else {
      const moveUserValidationUrl = `/move-login/${user.loginId}`;
      validateUserTransferGet(moveUserValidationUrl);
      setSwitchingUserToBeValidated(user);
    }
  };

  const handleUserTransfer = (organisation: Organisation) => {
    if (switchingUser == null) return;
    const transferUrl = `/move-login/${switchingUser.loginId}/${organisation.organisationId}`;
    userTransferPost(transferUrl);
  };

  return (
    <>
      {switchingUser ? (
        <>
          <FormTitle title={t('organisation_switch_title')} />
          <Table data={body} headers={headers} hidePagination />
          <SelectNewOrganisation
            onSubmit={handleUserTransfer}
            onCancel={() => setSwitchingUser(null)}
            publishersOnly={false}
          />
        </>
      ) : (
        <>
          <FormTitle title={t('organisation_edit_users_title')} />
          {organisation && (
            <OrgLoginsTable
              orgLogins={orgLogins}
              loading={!loginsFetch || loginsFetch.pending}
              organisation={organisation}
              setSwitchingUser={validateAndsetSwitchingUser}
            />
          )}
          {url && <AddLogin url={url} onAdd={() => fetchLogins(url)} organisation={organisation} />}
        </>
      )}
      {errorMessage && (
        <AlertMessage
          variant="error"
          open={!!errorMessage}
          onClose={() => {
            setErrorMessage(null);
            setSuccessMessage(null);
          }}
          message={errorMessage ?? t('processing_error')}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        />
      )}
      {successMessage && (
        <AlertMessage
          variant="success"
          open={!!successMessage}
          onClose={() => {
            setErrorMessage(null);
            setSuccessMessage(null);
          }}
          message={successMessage ?? t('processing_error')}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        />
      )}
    </>
  );
};

export default Users;
