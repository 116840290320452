import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';
import ConstrainedPageContent from '../../../components/ConstrainedPageContent';
import ROUTE_KEY from '../../../config/routes/routeKeys';
import usePath from '../../../config/routes/usePath';
import OrdersHistory from '../components/ordersHistory/OrdersHistory';
import { OrdersTableForBookstore } from '../components/ordersHistoryProcessed/OrdersTableForBookstore';
import OrdersTabHeader from '../components/OrdersTabHeader';

type Props = RouteComponentProps;

export const OrdersProcessedHeader = (props: Props) => {
  const { t } = useTranslation();
  const orderLinesPath = usePath(ROUTE_KEY.ORDERS_HISTORY_BOOKSTORE);
  const ordersPath = usePath(ROUTE_KEY.ORDERS_HISTORY_BOOKSTORE_ORDERS);

  return (
    <OrdersTabHeader
      {...props}
      tabs={[
        { route: orderLinesPath, label: t('page_tab_orderlines_bookstore') },
        { route: ordersPath, label: t('page_tab_orders_bookstore') },
      ]}
      title={t('page_title_order_overview_processed_bookstore')}
    />
  );
};

const OrdersProcessedForBookstorePage: FC<Props> = (props) => {
  return (
    <ConstrainedPageContent center={false}>
      <OrdersProcessedHeader {...props} />
      <OrdersHistory
        apiRouteName="elasticOrderHistory"
        apiRouteParams={{ processed: 1 }}
        component={OrdersTableForBookstore}
      />
    </ConstrainedPageContent>
  );
};

export default OrdersProcessedForBookstorePage;
