import { Box, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Header from '../../../../components/Header';
import Multiline from '../../../../components/Multiline';
import ExportTitles from '../components/ExportTitles';

const ExportTitlesPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <Header title={t('page_title_titles_export')} />
      <Typography>
        <Multiline>{t('page_subtitle_titles_export')}</Multiline>
      </Typography>

      <Box mt={4}>
        <ExportTitles />
      </Box>
    </>
  );
};

export default ExportTitlesPage;
