import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { SelectFieldProps } from '../../../../../../components/Form/SelectField';
import { SelectField as Select } from '../../../../../../components/Form/index';

type Props = {
  onChange: SelectFieldProps['onChange'];
  initialValue?: SelectFieldProps['value'];
};

const useStyles = makeStyles((theme: Theme) => ({
  select: {
    // marginLeft: theme.spacing(2)
  },
}));

const IsMember = ({ onChange, initialValue }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const items = {
    true: t('filter_option_is_member'),
    false: t('filter_option_is_no_member'),
  };
  return (
    <div className={classes.select}>
      <Select
        items={items}
        onChange={onChange}
        label={t('filter_membership')}
        value={initialValue}
      />
    </div>
  );
};

export default IsMember;
