import { CircularProgress } from '@mui/material';
import * as React from 'react';
import { useEffect } from 'react';
import { Form } from 'react-final-form';
import DelayedLoaderForFetch from '../../../../../components/DelayedLoaderForFetch';
import { DataProducer, DataProducerDto, Organisation } from '../../../../../config/api/types';
import useFetch from '../../../../../helpers/useFetch';
import { ApiConfig } from '../../../api';
import DataProducerFormFields from '../../components/DataProducer/DataProducerFormFields';

type Props = {
  organisation?: Organisation;
  goToOrganisation: () => void;
};

const DataProducerForm = ({ organisation }: Props) => {
  const dataProducerLink =
    organisation && organisation._links && organisation._links.dataProducer.href;
  const [dataProducerFetch, fetchDataProducer] = useFetch<DataProducer>(
    ApiConfig.getDataProducer()
  );
  const [, updateDataProducer] = useFetch(ApiConfig.updateDataProducer());

  useEffect(() => {
    if (dataProducerLink) {
      fetchDataProducer(dataProducerLink);
    }
  }, [dataProducerLink, fetchDataProducer]);

  if (!organisation || !dataProducerFetch) {
    return <CircularProgress />;
  }

  return (
    <DelayedLoaderForFetch fetchObj={dataProducerFetch}>
      {dataProducerFetch.value ? (
        <Form
          initialValues={{
            supplyFormat: dataProducerFetch.value.supplyFormat,
            usesBlockUpdates: dataProducerFetch.value.usesBlockUpdates,
            usesGs1: dataProducerFetch.value.usesGs1,
          }}
          onSubmit={(values: DataProducerDto) => {
            updateDataProducer(values, dataProducerLink);
          }}
        >
          {({ handleSubmit, dirty }) => {
            return (
              <DataProducerFormFields
                onSubmit={handleSubmit}
                disabled={dataProducerFetch.pending || !dirty}
              />
            );
          }}
        </Form>
      ) : null}
    </DelayedLoaderForFetch>
  );
};

export default DataProducerForm;
