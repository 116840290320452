import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { IconButton } from '@mui/material';
import { useFetch } from '@react-redux-fetch/hooks';
import React, { FC } from 'react';
import useApiRoute from '../../../../config/api/useApiRoute';
import { RetourLine } from '../../../../config/store/retour';
import { getDeleteRetourLineRequest } from './api';

type Props = {
  retourLine: RetourLine;
};
export const DeleteRetourLineButton: FC<Props> = ({ retourLine }) => {
  const baseUrl = useApiRoute('retours');
  const url = `${baseUrl}/lines/${retourLine.id}`;
  const [deleteFetch, deleteRetourLine] = useFetch(getDeleteRetourLineRequest);

  const onClick = () => {
    deleteRetourLine(url, retourLine.id);
  };

  return (
    <IconButton onClick={onClick} size="small" disabled={deleteFetch?.pending}>
      <DeleteForeverIcon />
    </IconButton>
  );
};
