import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import OrganisationWrapper from '../containers/OrganisationWrapper';
import MembershipForm from '../containers/member/MembershipForm';

type Params = { id: string };
type Props = RouteComponentProps<Params> & {};

const Membership = (props: Props) => (
  <OrganisationWrapper {...props}>
    {({ organisation }) => <MembershipForm organisation={organisation} />}
  </OrganisationWrapper>
);

export default Membership;
