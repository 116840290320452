import path from 'ramda/src/path';
import { ImmutableObject } from 'seamless-immutable';
import { Resource } from '../config/api/types';
// import match from 'ramda/src/match';

export function elasticGetLink(
  model?: { _links: Record<string, { href: string }> },
  rel?: string
): string | undefined {
  return path(['_links', rel || '', 'href'], model || {});
}

export function getLink(
  model?: Resource | null | ImmutableObject<Resource<any>>,
  rel?: string
): string | undefined {
  return path(['_links', rel || '', 'href'], model || {});
}

export function getEmbedded<TEmbed = any>(model: any = {}, rel: string): TEmbed | undefined {
  return path(['_embedded', rel], model);
}

export function updateQueryParameterList(url: string, param: string, values: string[]): string {
  // eslint-disable-next-line
  const regexParam = new RegExp(`(${param}\\[?[0-9]*\\]?=[0-9a-zA-Z\-]*&?)+`);
  const newValue =
    values.length > 0 ? values.map((v, idx) => `${param}[${idx}]=${v}&`).join('') : `${param}=&`;

  return url.replace(regexParam, newValue).replace(/&$/, '');
}

export function updateQueryParameter(
  url: string,
  param: string,
  value: string | number = ''
): string {
  const regexParam = new RegExp(`${param}=[^&]*`);

  if (url.match(regexParam)) {
    return url.replace(regexParam, `${param}=${value}`);
  } else {
    const joinChar = url.includes('?') ? '&' : '?';
    return url.concat(`${joinChar}${param}=${value}`);
  }
}

export function removeQueryParameter(url: string, param: string): string {
  const regexParam = new RegExp(`${param}=[^&]*`);
  if (url.match(regexParam)) {
    return url.replace(regexParam, '').replace('?&', '?');
  }
  return url;
}

export function updateQueryParameters(
  url: string,
  params: Record<string, string | number | undefined>
) {
  return Object.keys(params).reduce((newUrl, param) => {
    return updateQueryParameter(newUrl, param, params[param]);
  }, url);
}

export function setQueryParameters(url: string, params: Record<string, string | number>): string {
  const base = url.replace(/\?.*/, '');
  return updateQueryParameters(base, params);
}

export function retrieveQueryParameter(url: string, param: string): string | undefined {
  const regexParam = new RegExp(`${param}=([^&]*)`);
  const match = url.match(regexParam);
  return match ? decodeURIComponent(match[1]) : undefined;
}

export function retrieveAllQueryParameters(url: string): Record<string, string> {
  const urlParams = new URLSearchParams(url.replace(/^.*\?/, ''));
  return Array.from(urlParams.entries()).reduce((all, [key, value]) => {
    return {
      ...all,
      [key]: value,
    };
  }, {});
}
