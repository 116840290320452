import { ThemeProvider, Theme, StyledEngineProvider } from '@mui/material';
import cloneDeep from 'lodash/cloneDeep';
import React, { FC } from 'react';
import theme from '../config/theme';
import Button, { ButtonProps } from './Button';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const redTheme = cloneDeep(theme);
redTheme.palette.primary = cloneDeep(theme.palette.error);

export const ErrorButton: FC<ButtonProps> = (props) => {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={redTheme}>
        <Button {...props} />
      </ThemeProvider>
    </StyledEngineProvider>
  );
};
