import React, { useEffect } from 'react';
import { Organisation } from '../../../../../config/api/types';
import DelayedLoader from '../../../../../components/DelayedLoader';
import { useFetch } from '@react-redux-fetch/hooks';
import { getBookstoreRequest, updateBookstoreRequest } from '../../../api';
import { useSelector } from 'react-redux';
import { getBookstore } from '../../selectors';
import { Form } from 'react-final-form';
import { BookstoreDto } from '../../../../../config/api/models/organisation';
import { useAsyncValidation } from '../../../../../helpers/finalFormAsyncValidation';
import BookstoreFormFields from '../../components/Bookstore/BookstoreFormFields';

type Props = {
  organisation?: Organisation;
};

const BookstoreForm = ({ organisation }: Props) => {
  const bookstoreLink = organisation?._links?.bookstore.href;
  const [, fetchBookstore] = useFetch(getBookstoreRequest);
  const [updateBookstoreFetch, updateBookstore] = useFetch(updateBookstoreRequest);
  const { createSubmissionPromise } = useAsyncValidation(updateBookstoreFetch);
  const bookstore = useSelector(getBookstore(bookstoreLink));

  useEffect(() => {
    if (bookstoreLink) {
      fetchBookstore(bookstoreLink);
    }
  }, [bookstoreLink, fetchBookstore]);

  return (
    <>
      <DelayedLoader data={bookstore}>
        {bookstore && (
          <Form
            initialValues={{
              cbRelationId: bookstore.cbRelationId,
              group: bookstore.group,
              cashierSystem: bookstore.cashierSystem,
              orderFileType: bookstore.orderFileType,
              wantsEmailNotifications: bookstore.wantsEmailNotifications,
              email: bookstore.email,
              isMemberBookstoresFlanders: bookstore.isMemberBookstoresFlanders,
              usesFlowNumber: bookstore.usesFlowNumber,
            }}
            onSubmit={(values: BookstoreDto) => {
              if (bookstoreLink) {
                updateBookstore(bookstoreLink, values);
              }
              return createSubmissionPromise();
            }}
          >
            {({ handleSubmit, dirty, values: { wantsEmailNotifications } }) => (
              <BookstoreFormFields
                links={bookstore._links}
                onSubmit={handleSubmit}
                disabled={updateBookstoreFetch?.pending || !dirty}
                showEmailField={wantsEmailNotifications === true}
              />
            )}
          </Form>
        )}
      </DelayedLoader>
    </>
  );
};

export default BookstoreForm;
