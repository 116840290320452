import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Maybe, Titles } from '../../../../config/api/types';
import { getLink } from '../../../../helpers/hateoas';
import TitleExportButton from './Export/TitleExportButton';
import SortBy, { SortByProps } from './SortBy';
import Title from './Title/Title';

type Props = {
  titles: Maybe<Titles>;
  changeSort: (order: string, orderDir: string) => void;
  order?: string;
  orderDir?: string;
  loading?: boolean;
  hasActiveFilters: boolean;
  changeParams: (values: Record<string, string | number>) => void;
};

const TitleList = ({
  titles,
  changeSort,
  order,
  orderDir,
  loading,
  hasActiveFilters,
  changeParams,
}: Props) => {
  const { t } = useTranslation();

  const handleSortChange: SortByProps['onChange'] = (option) => {
    changeSort(option.order, option.orderDir);
  };

  if (!titles) {
    return null;
  }

  return (
    <>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Grid container spacing={2}>
            <Grid item>
              <Typography variant="subtitle2" style={{ marginBottom: 24 }}>
                {t('catalog_num_results', { resultCount: titles.total })}
              </Typography>
            </Grid>
            <Grid item style={{ marginTop: -2 }}>
              {titles?._links.self && (
                <Box textAlign="right" mb={2}>
                  <TitleExportButton
                    url={titles._links.self.href}
                    total={titles.total}
                    disabled={loading || !hasActiveFilters}
                  />
                </Box>
              )}
            </Grid>
            <Grid item>
              {loading && (
                <CircularProgress
                  color="secondary"
                  style={{ width: 20, height: 20, marginLeft: 16, verticalAlign: 'middle' }}
                />
              )}
            </Grid>
          </Grid>
        </Grid>

        <Grid item>
          <SortBy onChange={handleSortChange} order={order} orderDir={orderDir} />
        </Grid>
      </Grid>
      {titles.total === 0 && <Typography>{t('catalog_no_results')}</Typography>}
      {titles._embedded.items.map((title) => (
        <Title key={getLink(title, 'self') || ''} title={title} changeParams={changeParams} />
      ))}
    </>
  );
};

export default TitleList;
