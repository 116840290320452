import AddIcon from '@mui/icons-material/AddCircleOutline';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { Alert } from '@mui/lab';
import { Box, Theme } from '@mui/material';
import Typography from '@mui/material/Typography';
import { WithStyles, createStyles, withStyles } from '@mui/styles';
import classNames from 'classnames';
import React from 'react';
import ReactDropZone, { DropzoneProps } from 'react-dropzone';

type Props = WithStyles<typeof styles> & {
  onDropAccepted: DropzoneProps['onDrop'];
  onDropRejected: DropzoneProps['onDropRejected'];
  accept?: DropzoneProps['accept'];
  activeMessage: string;
  message: string;
  validationErrors?: { count: number; message: string };
};

const styles = (theme: Theme) =>
  createStyles({
    dropZoneWrapper: {
      border: '2px #C8C8C8 dashed',
      padding: theme.spacing(1),
      boxSizing: 'border-box',
      cursor: 'pointer',
    },
    dropZoneContent: {
      position: 'relative',
      width: '100%',
      minHeight: 80,
      backgroundColor: '#f2fbee',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: theme.palette.buttons.highlight,
    },
    cloudIcon: {
      height: 50,
      width: 50,
      marginRight: theme.spacing(1),
    },
    greenIcon: {},
    drop: {},
    active: {},
    reject: {
      background: theme.palette.error.light,
      color: theme.palette.error.main,
    },
    inputField: {
      display: 'block',
    },
    text: {},
    tooltip: {
      backgroundColor: theme.palette.messages.error,
      fontSize: 16,
    },
  });

const StyledDropZone = ({
  onDropAccepted,
  onDropRejected,
  classes,
  activeMessage,
  message,
  validationErrors,
  accept,
}: Props) => {
  return (
    <>
      <ReactDropZone onDrop={onDropAccepted} onDropRejected={onDropRejected} accept={accept}>
        {({ getRootProps, getInputProps, isDragActive, isDragReject }) => {
          return (
            <div className={classes.dropZoneWrapper} {...getRootProps()}>
              <div
                className={classNames(classes.dropZoneContent, {
                  [classes.active]: isDragActive,
                  [classes.reject]: isDragReject,
                })}
              >
                <input {...getInputProps()} />
                {isDragActive && !isDragReject ? (
                  <AddIcon className={classNames([classes.cloudIcon, classes.greenIcon])} />
                ) : (
                  <AttachFileIcon className={classes.cloudIcon} />
                )}
                {isDragActive && !isDragReject ? (
                  <Typography className={classes.text}>{activeMessage}</Typography>
                ) : (
                  <Typography className={classes.text}>{message}</Typography>
                )}
              </div>
            </div>
          );
        }}
      </ReactDropZone>
      {validationErrors?.message && (
        <Box mt={2}>
          <Alert severity="error">{validationErrors.message}</Alert>
        </Box>
      )}
    </>
  );
};

export default withStyles(styles)(StyledDropZone);
