import { Dialog, Theme, Typography } from '@mui/material';
import DialogActions from '@mui/material/DialogActions/DialogActions';
import DialogContent from '@mui/material/DialogContent/DialogContent';
import DialogTitle from '@mui/material/DialogTitle/DialogTitle';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from './Button';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
}));

type Props = {
  onCancel: () => void;
  onConfirm: () => void;
  title: string;
  content: string;
};

const ConfirmationDialog = ({ title, content, onCancel, onConfirm }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Dialog open={true} className={classes.root}>
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <Typography>{content}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} variant="text">
          {t('form_confirm_disagree')}
        </Button>
        <Button onClick={onConfirm} color="primary" autoFocus type="submit">
          {t('form_confirm_agree')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
