import { Box, Theme, Tooltip, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import formatEuro from '../helpers/formatEuro';

type Classes = {
  price?: string;
};

type Props = {
  price?: number;
  freeOfCharge?: boolean;
  toBeAnnounced?: boolean;
  classes?: Classes;
  icon?: ReactElement<any>;
  label?: string;
  iconPlacement?: 'before' | 'after';
  suffix?: ReactElement<any>;
  tooltip?: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  label: {
    marginLeft: 4,
  },
  icon: {
    width: theme.spacing(3.5),
    display: 'inline-block',
    textAlign: 'left',
  },
  iconRight: {
    width: theme.spacing(2),
    display: 'inline-block',
    textAlign: 'right',
    verticalAlign: 'text-bottom',
  },
}));

const Price = ({
  price,
  freeOfCharge,
  toBeAnnounced,
  classes,
  icon,
  label,
  iconPlacement = 'before',
  suffix,
  tooltip,
}: Props) => {
  const { t } = useTranslation();
  const classNames = useStyles();

  if (freeOfCharge) {
    return <Typography>{t('title_free_of_charge')}</Typography>;
  }

  if (toBeAnnounced) {
    return <Typography>{t('title_to_be_announced')}</Typography>;
  }

  const iconContentRight = <span className={classNames.iconRight}>{icon}</span>;
  const iconContentLeft = <span className={classNames.icon}>{icon}</span>;

  if (price) {
    return (
      <Box display="flex" alignItems="center">
        {icon &&
          iconPlacement === 'before' &&
          (tooltip ? (
            <Tooltip title={tooltip} arrow>
              {iconContentLeft}
            </Tooltip>
          ) : (
            <>{iconContentLeft}</>
          ))}
        <Typography className={classes && classes.price} display="inline">
          {formatEuro(price)}
        </Typography>
        {icon &&
          iconPlacement === 'after' &&
          (tooltip ? (
            <Tooltip title={tooltip} arrow>
              {iconContentRight}
            </Tooltip>
          ) : (
            <>{iconContentRight}</>
          ))}
        {label && (
          <Typography className={classNames.label} variant="body1" display="inline">
            {label}
          </Typography>
        )}

        {suffix || null}
      </Box>
    );
  }

  return null;
};

export default Price;
