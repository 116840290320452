import CloseIcon from '@mui/icons-material/Close';
import { Dialog, IconButton, Theme, Typography } from '@mui/material';
import DialogContent from '@mui/material/DialogContent/DialogContent';
import DialogTitle from '@mui/material/DialogTitle/DialogTitle';
import { TypographyProps } from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import { useState } from 'react';
import OrganisationDialogContent from './OrganisationDialogContent';

type Props = {
  children: TypographyProps['children'];
  organisationUrl: string;
  toggleButtonProps?: TypographyProps;
};

const useStyles = makeStyles((theme: Theme) => ({
  dialogTitle: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing(1, 2),
    minWidth: 400,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
    padding: theme.spacing(0.5),
  },
}));

const OrganisationDialog = ({ organisationUrl, children, toggleButtonProps }: Props) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const classes = useStyles();

  const onClose = () => setDialogOpen(false);
  const onOpen = () => setDialogOpen(true);

  return (
    <>
      <Typography
        component="a"
        style={{ textDecoration: 'underline', cursor: 'pointer' }}
        display="inline"
        onClick={onOpen}
        {...(toggleButtonProps as any)}
      >
        {children}
      </Typography>
      {dialogOpen && (
        <Dialog open={dialogOpen} onClose={onClose}>
          <DialogTitle id="alert-dialog-title" className={classes.dialogTitle}>
            {children}
            <IconButton
              aria-label="Close"
              className={classes.closeButton}
              onClick={onClose}
              size="large"
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <OrganisationDialogContent organisationUrl={organisationUrl} />
          <DialogContent />
        </Dialog>
      )}
    </>
  );
};

export default OrganisationDialog;
