import React from 'react';
import { SelectField as Select } from '../../../../../components/Form';
import { SelectFieldProps } from '../../../../../components/Form/SelectField';
import { useTranslation } from 'react-i18next';

type Props = {
  onChange: SelectFieldProps['onChange'];
  initialValue?: SelectFieldProps['value'];
};

const WillNotBeBilledFilter = ({ onChange, initialValue }: Props) => {
  const { t } = useTranslation();

  const items = {
    true: t('filter_option_yes'),
    false: t('filter_option_no'),
  };

  return (
    <Select
      items={items}
      onChange={onChange}
      label={t('filter_will_not_be_billed')}
      value={initialValue}
    />
  );
};

export default WillNotBeBilledFilter;
