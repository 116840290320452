import { Paper, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import { MenuProps } from 'react-select';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing(0.2),
    left: 0,
    right: 0,
  },
  wideDropdown: {
    width: 'fit-content',
    minWidth: '100%',
    position: 'absolute',
    zIndex: 1,
  },
}));

export function Menu(props: MenuProps<any, any>) {
  const classes = useStyles();

  return (
    //@ts-ignore
    <Paper
      square
      //@ts-ignore
      className={props.selectProps.wideDropdown ? classes.wideDropdown : classes.paper}
      {...props.innerProps}
    >
      {props.children}
    </Paper>
  );
}
