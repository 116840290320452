import React from 'react';
import { RouteComponentProps } from 'react-router';
import ConstrainedPageContent from '../../../components/ConstrainedPageContent';
import OrdersHistoryLinesForLibrary from '../components/ordersHistoryLines/OrdersHistoryLinesForLibrary';
import { OrdersHistoryHeader } from './OrdersConfirmedForLibraryPage';

type Props = RouteComponentProps;

const OrdersLinesConfirmedForLibraryPage = (props: Props) => {
  return (
    <ConstrainedPageContent center={false}>
      <OrdersHistoryHeader {...props} />
      <OrdersHistoryLinesForLibrary />
    </ConstrainedPageContent>
  );
};

export default OrdersLinesConfirmedForLibraryPage;
