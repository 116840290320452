import CommentIcon from '@mui/icons-material/Comment';
import { IconButton, Popover, Typography } from '@mui/material';
import React, { FC } from 'react';

type Props = {
  note: string;
};
export const DeliveryNoteIcon: FC<Props> = ({ note }) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton onClick={handleClick} size="small">
        <CommentIcon fontSize="small" />
      </IconButton>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Typography style={{ padding: '1rem' }}>{note}</Typography>
      </Popover>
    </>
  );
};
