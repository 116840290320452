import * as React from 'react';
import { PromiseState, ReduxFetch } from 'react-redux-fetch';
import { ApiConfig } from '../../api';
import { Roles, UserRole } from '../../../../config/api/types';
import { CheckBox } from '../../../../components/Form';
import FormTitle from '../components/FormTitle';
import { useTranslation } from 'react-i18next';
import Fieldset from '../components/Organisation/Fieldset';
import { getForbiddenCombinations } from '../../domain';

type Props = {
  selectedRoles?: UserRole[];
  organisationRoles?: UserRole[];
};
type ReduxFetchRenderProps = {
  rolesFetch: PromiseState<Roles>;
};

const RolesForm = ({ selectedRoles, organisationRoles }: Props) => {
  const { t } = useTranslation();
  return (
    <ReduxFetch config={[ApiConfig.getRoles()]} fetchOnMount>
      {({ rolesFetch }: ReduxFetchRenderProps) => {
        if (!rolesFetch.value) {
          return null;
        }

        const allRoles = Object.keys(rolesFetch.value.hierarchy) as UserRole[];
        const forbiddenCombinations = getForbiddenCombinations(selectedRoles);

        return (
          <Fieldset>
            <FormTitle title="Rollen organisatie" />
            {allRoles.map((role: UserRole) => {
              return (
                <CheckBox
                  name="roles"
                  value={role}
                  label={t(role)}
                  key={role}
                  disabled={
                    forbiddenCombinations.includes(role) || organisationRoles?.includes(role)
                  }
                />
              );
            })}
          </Fieldset>
        );
      }}
    </ReduxFetch>
  );
};

export default RolesForm;
