import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import ConstrainedPageContent from '../../../components/ConstrainedPageContent';
import { RetourHistory } from '../retourHistory';
import OrdersTabHeader from '../../orders/components/OrdersTabHeader';
import { RouteComponentProps } from 'react-router';
import usePath from '../../../config/routes/usePath';
import ROUTE_KEY from '../../../config/routes/routeKeys';

export const RetourHistoryHeader = (props: Props) => {
  const { t } = useTranslation();
  const retourLinesPath = usePath(ROUTE_KEY.RETURN_HISTORY);
  const retourPath = usePath(ROUTE_KEY.RETURN_HISTORY_RETURNS);

  return (
    <OrdersTabHeader
      {...props}
      tabs={[
        { route: retourLinesPath, label: t('page_tab_retourlines') },
        { route: retourPath, label: t('page_tab_retours') },
      ]}
      title={t('page_title_return_history')}
    />
  );
};

type Props = RouteComponentProps;
export const RetourHistoryPage: FC<Props> = (props) => {
  return (
    <ConstrainedPageContent center={false}>
      <RetourHistoryHeader {...props} />
      <RetourHistory />
    </ConstrainedPageContent>
  );
};
