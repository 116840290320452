import { FilterTreeNode, TreeData } from './types';

const isSearchValueInTreeNode = (treeNode: string, searchValue: string) => {
  const normalizedTreeNode = treeNode.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  const normalizedSearchValue = searchValue.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

  return normalizedTreeNode.toLowerCase().includes(normalizedSearchValue.toLowerCase());
};
export const defaultTreeNodeFilter: FilterTreeNode = (inputValue, treeNode) => {
  return (
    isSearchValueInTreeNode(treeNode.label, inputValue) ||
    treeNode.code.toLowerCase().includes(inputValue.toLowerCase())
  );
};

export const flattenTree = <TData extends TreeData>(agg: TData[], treeNode: TData): TData[] => {
  return [
    ...agg,
    treeNode,
    ...(treeNode.children || []).reduce(
      (agg2: TData[], childNode) => flattenTree(agg2, childNode as TData),
      []
    ),
  ];
};

export const filterTree = <TData extends TreeData>(
  treeData: TData[],
  inputValue: string,
  filterTreeNode: FilterTreeNode
): TData[] => {
  return treeData && inputValue
    ? treeData
        .map((parentTreeNode) => {
          return {
            ...parentTreeNode,
            children: parentTreeNode.children
              ? filterTree(parentTreeNode.children, inputValue, filterTreeNode)
              : null,
          };
        })
        .filter(
          (treeNode) => Boolean(treeNode.children?.length) || filterTreeNode(inputValue, treeNode)
        )
    : treeData;
};
