import React from 'react';
import { useTranslation } from 'react-i18next';
import { TitleFull } from '../../../../config/api/types';
import ListHeading from './ListHeading';
import TitleMatches from './TitleMatches';

type Props = {
  title: TitleFull;
};

const TitleMatchesWrapper = ({ title }: Props) => {
  const { t } = useTranslation();
  const numMatching =
    (title._embedded.matchingLanguages?.length ?? 0) + (title._embedded.matchingNstc?.length ?? 0);

  if (numMatching <= 0) {
    return null;
  }

  return (
    <>
      <ListHeading>{t(`title_heading_nstc`, { count: numMatching })}</ListHeading>
      <TitleMatches titles={title._embedded.matchingNstc} type="nstc" />
      <TitleMatches titles={title._embedded.matchingLanguages} type="language" />
    </>
  );
};

export default TitleMatchesWrapper;
