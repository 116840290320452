import apiRoutes from '../../../../config/api/routes';
import { ApiConfigUnion } from '../../../../helpers/apiConfig';

export const ApiConfig = {
  files: (url: string) => ({
    resource: 'mediaFiles',
    request: (newUrl?: string) => ({
      url: apiRoutes.create(newUrl || url),
    }),
  }),
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ApiConfig = ApiConfigUnion<typeof ApiConfig>;
