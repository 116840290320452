import CloseIcon from '@mui/icons-material/Close';
import { IconButton, IconButtonProps, styled } from '@mui/material';
import React, { FC } from 'react';

const StyledIconButton = styled(IconButton)({
  position: 'absolute',
  right: 8,
  top: 8,
});

export const ClosePopoverButton: FC<IconButtonProps> = (props) => {
  return (
    <StyledIconButton size="small" {...props}>
      <CloseIcon style={{ fontSize: '1.75rem' }} />
    </StyledIconButton>
  );
};
