import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import { HTMLProps } from 'react';
import { SectionTitle } from '../domain';
// import { useIsVisible } from 'react-is-visible';

type Props = HTMLProps<HTMLDivElement> & {
  id: SectionTitle;
};

const useStyles = makeStyles((theme: Theme) => ({
  sectionWrapper: {
    background: theme.palette.background.appbar,
  },
}));

const SectionWrapper = ({ id, ...other }: Props) => {
  const classes = useStyles();
  // const nodeRef = React.useRef(null);
  // const isVisible = useIsVisible(nodeRef);
  //
  // return <div ref={nodeRef}>{isVisible ? <div id={id} {...other} /> : <div id={id} />}</div>;
  return <div id={id} {...other} className={classes.sectionWrapper} />;
};

export default SectionWrapper;
