import React from 'react';
import { PrefixApplication } from '../../../../config/api/types';
import PrefixApplicantReview from '../components/PrefixApplicantReview';

type Props = {
  prefixApplication: PrefixApplication;
  onUpdate: (body: PrefixApplication) => void;
  overrideApplicantField: (field: keyof PrefixApplication['organisation'], value: string) => void;
};

const EditPrefixApplicantWrapper = ({ prefixApplication, overrideApplicantField }: Props) => {
  return (
    <PrefixApplicantReview
      prefixApplication={prefixApplication}
      overrideApplicantField={overrideApplicantField}
    />
  );
};

export default EditPrefixApplicantWrapper;
