import { Theme } from '@mui/material';
import { WithStyles, createStyles, withStyles } from '@mui/styles';
import React, { HTMLProps, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router';
import { ClientRouteContext } from '../config/routes/ClientRouteContext';
import { getParentRoute } from '../config/routes/helpers';
import BackButton from './BackButton';
import Title from './PageTitle';

type Props = WithStyles<typeof styles> &
  RouteComponentProps & {
    title?: string;
    goBack?: boolean | string;
    goBackPath?: string;
    goBackLabel?: string;
    style?: HTMLProps<HTMLDivElement>['style'];
  };

const styles = (theme: Theme) =>
  createStyles({
    backButton: {
      marginTop: 8,
    },
  });

// title & breadcrumbs
const Header = ({ title, classes, goBack, match, goBackPath, goBackLabel, style }: Props) => {
  const { t } = useTranslation();
  const { nestedRoutes: clientRoutes } = useContext(ClientRouteContext);
  const parentRoute = goBackPath
    ? { path: goBackPath }
    : getParentRoute(clientRoutes, typeof goBack === 'string' ? goBack : match.path);

  return (
    <div style={style}>
      <Title>
        {title}
        {(goBack || goBackPath) && parentRoute && (
          <div className={classes.backButton}>
            <BackButton
              link={{
                pathname: parentRoute.path,
                state: { keepFilters: true },
              }}
            >
              {goBackLabel || t('back_to_overview')}
            </BackButton>
          </div>
        )}
      </Title>
    </div>
  );
};
export default withRouter(withStyles(styles)(Header));
