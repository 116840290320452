import { isErrorAction, isSuccessAction } from '@react-redux-fetch/core';
import { PromiseState } from 'react-redux-fetch';
import alerts from '../../core/alerts';

type FetchAction = PromiseState<any> & {
  type: string;
};

const serverError = (action: FetchAction) => {
  return (
    action.request &&
    action.request.meta &&
    action.request.meta.response &&
    action.request.meta.response.status >= 500
  );
};

const alertOnServerResponse = (store: any) => (next: any) => (action: any) => {
  next(action);

  if (isErrorAction(action)) {
    const response = action.payload.response;
    const reason = action.payload.reason;

    if (action.payload.fetchConfig.fetchOptions?.skipAlertOnError || response?.status < 500) {
      return;
    }

    const fallback = { message: 'Server error' };
    const cause = reason ? reason.cause || fallback : fallback;

    store.dispatch(alerts.actions.openError(cause.exceptionMessage || cause.message));
  }

  if (isSuccessAction(action)) {
    const successMsg = action.payload.fetchConfig.fetchOptions?.successMsg;

    if (successMsg) {
      const msgFn = typeof successMsg === 'function' ? successMsg : () => successMsg;

      store.dispatch(alerts.actions.openSuccess(msgFn(action.payload)));
    }
  }

  switch (action.type) {
    case 'react-redux-fetch/POST_REJECT':
    case 'react-redux-fetch/GET_REJECT':
    case 'react-redux-fetch/PUT_REJECT':
    case 'react-redux-fetch/DELETE_REJECT':
      const fetchErrorAction = action as FetchAction;

      if (serverError(fetchErrorAction) && !action.request.meta?.skipAlertOnError) {
        const fallback = { message: 'Server error' };
        const cause = fetchErrorAction.reason
          ? fetchErrorAction.reason.cause || fallback
          : fallback;

        store.dispatch(alerts.actions.openError(cause.exceptionMessage || cause.message));
      }
      break;

    case 'react-redux-fetch/POST_FULFIL':
    case 'react-redux-fetch/GET_FULFIL':
    case 'react-redux-fetch/PUT_FULFIL':
    case 'react-redux-fetch/DELETE_FULFIL':
      const fetchSuccessAction = action as FetchAction;
      const successMsg = fetchSuccessAction.request && fetchSuccessAction.request.meta.successMsg;

      if (successMsg) {
        const msgFn = typeof successMsg === 'function' ? successMsg : () => successMsg;

        store.dispatch(alerts.actions.openSuccess(msgFn(fetchSuccessAction.value)));
      }

      break;
  }
};

export default alertOnServerResponse;
