import { Box, Grid } from '@mui/material';
import { isMoment } from 'moment';
import React, { FC, useState } from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import Button from '../../../../components/Button';
import { TextField } from '../../../../components/Form';
import AutoSave from '../../../../components/Form/AutoSave';
import FromToField from '../../../../components/Form/FromToField';
import { API_DATE_FORMAT } from '../../../../helpers/date';
import { retrieveAllQueryParameters, setQueryParameters } from '../../../../helpers/hateoas';

type Props = {
  fetchReplacements: (url: string) => void;
  url: string;
};

export const OrderReplacementsForm: FC<Props> = ({ fetchReplacements, url }) => {
  const { t } = useTranslation();
  const initialValues = retrieveAllQueryParameters(url);
  const [currentUrl, setCurrentUrl] = useState<string>(url);

  return (
    <Box marginTop={2} marginBottom={2}>
      <Form
        onSubmit={(values) => {
          const dateFrom = isMoment(values.from_date)
            ? values.from_date.format(API_DATE_FORMAT)
            : values.from_date;
          const dateTo = isMoment(values.to_date)
            ? values.to_date.format(API_DATE_FORMAT)
            : values.to_date;

          const urlWithParams = setQueryParameters(currentUrl, {
            ...values,
            from_date: dateFrom || '',
            to_date: dateTo || '',
            page: 1,
            reference: values.reference.replaceAll(' ', '%20'),
          });

          setCurrentUrl(urlWithParams.replace(/\s+/g, ''));
          if (url !== urlWithParams.replace(/\s+/g, '')) {
            // Only fetch when url changed
            fetchReplacements(urlWithParams.replace(/\s+/g, ''));
          }
        }}
        initialValues={initialValues}
        keepDirtyOnReinitialize={true}
      >
        {({ handleSubmit, form, values }) => (
          <form onSubmit={handleSubmit}>
            <AutoSave save={form.submit} />
            <Grid container spacing={1}>
              <Grid xs={6} item>
                <TextField
                  name="q"
                  label={t('form_gtin13_search')}
                  placeholder={t('form_placeholder_gtin13_search')}
                  autoComplete="off"
                  isClearable
                  onClear={form.submit}
                />
              </Grid>

              <Grid xs={12} md={6} item>
                <TextField
                  name="reference"
                  label={t('form_placeholder_reference_search')}
                  placeholder={t('form_placeholder_reference_search')}
                  autoComplete="off"
                  isClearable
                  onClear={form.submit}
                />
              </Grid>
              <FromToField onSubmit={form.submit} />
            </Grid>
            <Button type="submit" style={{ display: 'none' }}>
              {t('form_action_save')}
            </Button>
          </form>
        )}
      </Form>
    </Box>
  );
};
