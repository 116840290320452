import {
  ElasticShoppingCartLine,
  ShoppingCart,
  ShoppingCartLine,
} from '../../../config/api/models/shop';
import { Title, UserRole } from '../../../config/api/types';

export const getNumberOfProducts = (shoppingCart?: ShoppingCart | null): number => {
  const orderLines =
    shoppingCart?.linesByDistributor || Object.values(shoppingCart?.linesByUser || {});
  return orderLines.reduce((sum, orderLine) => {
    return sum + (orderLine?.lines?.length || 0);
  }, 0);
};

export const shoppingCartContainsTitle = (
  shoppingCart: ShoppingCart | undefined | null,
  isbn?: string
): {
  foundOrderLine: ShoppingCartLine | null;
  foundOrderLineWithoutRef: ShoppingCartLine | null;
} => {
  if (!shoppingCart) {
    return { foundOrderLine: null, foundOrderLineWithoutRef: null };
  }

  const orderLines =
    shoppingCart?.linesByDistributor || Object.values(shoppingCart?.linesByUser || {});

  let foundOrderLine: ShoppingCartLine | null = null;
  let foundOrderLineWithoutRef: ShoppingCartLine | null = null;

  orderLines.find((orderLine) => {
    const found = orderLine.lines?.find((line) => {
      return line._embedded.title.gtin13 === isbn && !line.reference;
    });
    const foundWithoutRef = orderLine.lines?.find((line) => {
      return line._embedded.title.gtin13 === isbn;
    });
    if (found) {
      foundOrderLine = found;
    }
    if (foundWithoutRef) {
      foundOrderLineWithoutRef = foundWithoutRef;
    }
    return found;
  });

  return { foundOrderLine, foundOrderLineWithoutRef };
};

export const getCantBeOrderedMessage = (
  title: Title,
  userRoles: UserRole[]
): string | undefined => {
  const canOrderAsBookstore = title.isOrderableByBookstore && userRoles.includes('ROLE_BOOKSTORE');
  const canOrderAsLibrary = title.isOrderableByLibrary && userRoles.includes('ROLE_LIBRARY');
  if (canOrderAsBookstore || canOrderAsLibrary) {
    return;
  }

  if (title.fund?.isAvailable) {
    return 'cannot_order_unknown_distributor';
  }

  if (!title.fund?.organisation?.hasMembership && userRoles.includes('ROLE_BOOKSTORE')) {
    return 'cannot_order_distributor_not_member';
  }

  if (title.productForm.digital) {
    return 'cannot_order_digital_titles';
  }

  if (title.hasOrderRestriction) {
    return 'cannot_order_restriction';
  }

  if (title.fund?.status === 'status_not_active') {
    return 'cannot_order_fund_inactive';
  }

  return 'cannot_order_general';
};

export const elasticGetCantBeOrderedMessage = (
  orderLine: ElasticShoppingCartLine,
  userRoles: UserRole[]
): string | undefined => {
  const canOrderAsBookstore =
    orderLine.isOrderableByBookstore && userRoles.includes('ROLE_BOOKSTORE');
  const canOrderAsLibrary = orderLine.isOrderableByLibrary && userRoles.includes('ROLE_LIBRARY');
  if (canOrderAsBookstore || canOrderAsLibrary) {
    return;
  }

  if (orderLine.fundIsAvailable) {
    return 'cannot_order_unknown_distributor';
  }

  if (!orderLine.fundHasMembership && userRoles.includes('ROLE_BOOKSTORE')) {
    return 'cannot_order_distributor_not_member';
  }

  if (orderLine.productFormDigital) {
    return 'cannot_order_digital_titles';
  }

  if (orderLine.hasOrderRestriction) {
    return 'cannot_order_restriction';
  }

  if (orderLine.fundStatus === 'status_not_active') {
    return 'cannot_order_fund_inactive';
  }

  return 'cannot_order_general';
};
