import organisation from '../../../organisation';
import { useSelector } from 'react-redux';
import security from '../../../security';
import { useEffect } from 'react';
import { useFetch } from '@react-redux-fetch/hooks';
import { getRetourRequest } from './api';
import { getUnconfirmedRetour, getUnconfirmedRetourUrl } from './selectors';

export const useFetchUnconfirmedRetour = () => {
  // The next 3 lines are for impersonated bookstores.
  const org = useSelector(security.selectors.getUserOrganisation);
  const { bookstore } = organisation.hooks.useFetchBookstore(org?._links?.bookstore?.href);
  const [, fetchRetour] = useFetch(getRetourRequest);

  const unconfirmedRetourUrl =
    // Logged in user || Impersonated user
    useSelector(getUnconfirmedRetourUrl) || bookstore?._links?.unConfirmedRetour?.href;

  const unconfirmedRetour = useSelector(getUnconfirmedRetour);
  const hasUnconfirmedRetour = Boolean(unconfirmedRetour);

  useEffect(() => {
    if (!hasUnconfirmedRetour && unconfirmedRetourUrl) {
      fetchRetour(unconfirmedRetourUrl);
    }
  }, [fetchRetour, hasUnconfirmedRetour, unconfirmedRetourUrl]);

  return { unconfirmedRetour };
};
