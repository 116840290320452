import { useFetch } from '@react-redux-fetch/hooks';
import { getBookstoreRequest } from '../../api';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { createGetBookstore } from '../selectors';

export const useFetchBookstore = (bookstoreUrl?: string) => {
  const [, fetchBookstore] = useFetch(getBookstoreRequest);
  const getBookstore = useSelector(createGetBookstore);
  const bookstore = getBookstore(bookstoreUrl);

  useEffect(() => {
    if (bookstoreUrl) {
      fetchBookstore(bookstoreUrl);
    }
  }, [fetchBookstore, bookstoreUrl]);

  return { bookstore };
};
