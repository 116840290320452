import { useFetch } from '@react-redux-fetch/hooks';
import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { TextField } from '../../../../components/Form';
import BaseReactSelectField from '../../../../components/Form/BaseReactSelectField';
import { Distributor } from '../../../../config/api/types';
import useApiRoute from '../../../../config/api/useApiRoute';
import { updateQueryParameters } from '../../../../helpers/hateoas';
import { getAllDistributors } from '../../admin/selectors';
import { getDistributorsRequest } from '../api';

type Props = {
  urlParams?: Record<string, string | number>;
  url?: string;
  disabled?: boolean;
  distributorId?: string;
  change?: (name: any, value: any) => void;
  label: string;
  placeholder?: string;
  onClearValue?: () => void;
};

export const DistributorsSelectField: FC<Props> = ({
  urlParams,
  disabled,
  distributorId,
  change,
  label,
  url: propUrl,
  placeholder,
  onClearValue,
}) => {
  const baseUrl = useApiRoute('distributors') || '';
  const url = propUrl || (urlParams ? updateQueryParameters(baseUrl, urlParams) : baseUrl);
  const { t } = useTranslation();
  const [, fetchDistributors] = useFetch(getDistributorsRequest);
  const distributors = useSelector(getAllDistributors);
  const cbText = t('cb');

  useEffect(() => {
    fetchDistributors(url);
  }, [fetchDistributors, url]);

  useEffect(() => {
    if (!distributors || !change || !distributorId) return;

    const distributor = Object.values(distributors).find((dist) => {
      return dist.distributorId === distributorId;
    });

    change('distributor', distributor || null);
  }, [change, distributors, distributorId]);

  const distList = Object.values(distributors || {});
  //
  if (disabled) {
    return <TextField disabled label={t('form_distributor')} name="distributorTxt" />;
  }

  return (
    <BaseReactSelectField
      label={label || t('form_distributor')}
      name="distributor"
      options={distList || []}
      getOptionValue={(option: Distributor) => option.distributorId}
      getOptionLabel={(option: Distributor) =>
        option._embedded.organisation.name + (option.isCbMember ? ` ${cbText}` : '')
      }
      disabled={disabled}
      blurInputOnSelect
      isClearable
      placeholder={placeholder || t('form_filter_distributor_placeholder')}
      onClearValue={onClearValue}
    />
  );
};
