import apiRoutes from '../../config/api/routes';
import { LoginDTO } from '../../config/api/types';
import { FetchConfig } from '@react-redux-fetch/core';
import { Data } from '../../config/store/types';
import { SetPasswordDTO } from '../../config/api/models/security';

export const setPasswordRequest = (
  setPasswordDTO: SetPasswordDTO,
  token: string
): FetchConfig<Data> => {
  return {
    method: 'PUT',
    url: apiRoutes.setPassword(token),
    fetchOptions: {
      body: setPasswordDTO,
    },
    repository: {},
  };
};

export const loginRequest = (loginDto: LoginDTO): FetchConfig<Data> => {
  return {
    method: 'POST',
    url: apiRoutes.login(),
    fetchOptions: {
      body: loginDto,
    },
    repository: {
      token: (prev, next) => next.token,
    },
  };
};

export const requestNewPasswordRequest = (url: string) => (email: string): FetchConfig<Data> => {
  return {
    method: 'POST',
    url: apiRoutes.create(url),
    fetchOptions: {
      body: { email },
    },
    repository: {},
  };
};

export const ApiConfig = {
  login: () => ({
    resource: 'auth',
    method: 'post',
    request: (loginDto: LoginDTO) => ({
      url: apiRoutes.login(),
      body: loginDto,
    }),
  }),
};
