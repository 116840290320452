const mapDateRange = (rangeString?: string): (string | undefined)[] => {
  let output =
    rangeString == null
      ? [undefined, undefined]
      : rangeString
          .replace('[', '')
          .replace(']', '')
          .split(' TO ')
          .map((item) => (item === '*' ? undefined : item));
  if (output.length !== 2) {
    output = [undefined, undefined]; //should never happen, but just to prevent crashes the check is implemented
  }
  return output;
};

export default mapDateRange;
