import { Box, InputLabel } from '@mui/material';
import React from 'react';
import { useFormState } from 'react-final-form';

export const ContributorsError = () => {
  const state = useFormState();
  const contributorsError = state.submitErrors?.contributors;

  if (!contributorsError) return null;

  return (
    <Box mt={2}>
      <InputLabel error>{contributorsError}</InputLabel>
    </Box>
  );
};
