import { makeStyles } from '@mui/styles';
import React, { ComponentPropsWithoutRef, forwardRef } from 'react';
import { Page } from 'react-pdf';

interface Props extends ComponentPropsWithoutRef<'div'> {
  pageNumber: number;
  width: number;
}

const useStyles = makeStyles((theme) => ({
  flipBook: {
    display: 'none',
  },
  page: {
    position: 'relative',
  },
  pageShadow: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    boxShadow: 'inset 0px 0 30px 0px rgba(0, 0, 0, 0.4)',
  },
}));

export const FlipbookPage = forwardRef<HTMLDivElement, Props>(({ pageNumber, width }, ref) => {
  const classes = useStyles();
  return (
    <div ref={ref} className={classes.page}>
      <Page pageNumber={pageNumber} width={width} className={classes.page} />
      <div className={classes.pageShadow}></div>
    </div>
  );
});
