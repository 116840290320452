import { useFetch } from '@react-redux-fetch/hooks';
import useApiRoute from '../../../config/api/useApiRoute';
import { getNotificationsCountersRequest } from '../api';
import { OrderFor } from '../selectors';

export const useNotificationsCountersFetch = (role?: OrderFor) => {
  const apiRoute = useApiRoute('notificationCounters') || '';

  return useFetch(() =>
    role
      ? getNotificationsCountersRequest(`${apiRoute}?order_for=${role}`)
      : getNotificationsCountersRequest(apiRoute)
  );
};
