import * as React from 'react';
import { Route, Redirect, RouteProps, RouteComponentProps } from 'react-router-dom';
import { ReactNode } from 'react';
import { ReactNodeArray } from 'prop-types';

type PrivateRouteProps = RouteProps & {
  isAuthenticated?: boolean;
  children?: ReactNodeArray | ReactNode;
};

const PrivateRoute = ({ component, isAuthenticated, children, ...other }: PrivateRouteProps) => {
  // console.log(other);
  if (!component) {
    throw new Error('Prop "component missing" in PrivateRoute');
  }
  return (
    <Route
      {...other}
      render={(props: RouteComponentProps) =>
        // @ts-ignore
        isAuthenticated ? React.createElement(component, props, children) : <Redirect to="/login" />
      }
    />
  );
};

export default PrivateRoute;
