import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  list: {
    listStyleType: 'none',
    margin: 0,
    marginBottom: theme.spacing(2),
    padding: 0,
    '& > *:nth-child(2n)': {
      backgroundColor: theme.palette.background.listEven,
    },
    '& > *:nth-child(2n+1)': {
      backgroundColor: theme.palette.background.listOdd,
    },
  },
}));

type Props = React.HTMLAttributes<HTMLElement>;

const List = ({ children }: Props) => {
  const classes = useStyles();

  return <ul className={classes.list}>{children}</ul>;
};

export default List;
