import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Theme, Tooltip, TooltipProps } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import React from 'react';

export interface StyleProps {
  maxWidth?: number;
}

const useStyles = makeStyles<Theme, StyleProps>(() => ({
  tooltip: {
    fontSize: 12,
    maxWidth: ({ maxWidth }) => maxWidth,
  },
  icon: {
    color: 'inherit',
    lineHeight: 'initial',
    fontSize: 'inherit',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  inlineIcon: {
    top: 1,
    position: 'absolute',
  },
  tooltipContainer: {
    position: 'relative',
  },
  inline: {
    paddingLeft: 3,
  },
}));

type Props = Omit<TooltipProps, 'children'> & {
  inline?: boolean;
  maxWidth?: number;
};

const InfoTooltip = ({ inline, maxWidth, ...props }: Props) => {
  const classes = useStyles({ maxWidth });
  return (
    <span className={clsx(classes.tooltipContainer, inline ? classes.inline : null)}>
      <Tooltip arrow {...props} classes={{ tooltip: classes.tooltip }}>
        <HelpOutlineIcon className={clsx(classes.icon, inline ? classes.inlineIcon : null)} />
      </Tooltip>
    </span>
  );
};

export default InfoTooltip;
