import { Theme, Typography } from '@mui/material';
import { WithStyles, createStyles, withStyles } from '@mui/styles';
import React, { FC, ReactNode } from 'react';

type Props = WithStyles<typeof styles> & {
  gutterBottom?: boolean;
  children?: ReactNode;
};

const styles = (theme: Theme) =>
  createStyles({
    title: {
      color: theme.palette.text.title,
      fontSize: 30,
      // marginBottom: theme.spacing.unit * 1.5,
    },
  });

const PageTitle: FC<Props> = ({ children, classes, gutterBottom = true }) => (
  <Typography variant="h1" gutterBottom={gutterBottom} className={classes.title}>
    {children}
  </Typography>
);

export default withStyles(styles)(PageTitle);
