import { Grid, MenuItem, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import React from 'react';
import { Organisation } from '../../../../config/api/types';
import { getCountryNameByShortName } from '../../../../config/data/countries';
import { formatAddress } from '../../../organisation/domain';

const useStyles = makeStyles((theme: Theme) => ({
  menuItem: {
    borderBottom: `1px solid ${theme.palette.background.appbarHover}`,
  },
  selected: {
    backgroundColor: theme.palette.background.appbarHover,
  },
}));

type Props = {
  organisation: Organisation;
  onSelect: (organisation: Organisation) => void;
  selected: boolean;
};

const OrganisationMenuItem = ({ organisation, onSelect, selected }: Props) => {
  const classes = useStyles();
  return (
    <MenuItem
      onClick={() => onSelect(organisation)}
      className={clsx(classes.menuItem, selected ? classes.selected : '')}
    >
      <Grid container direction="column">
        <Grid item xs>
          {organisation.name}
        </Grid>
        <Grid item xs>
          {organisation.email}
        </Grid>
        <Grid item xs>
          {`${formatAddress(organisation)}, ${getCountryNameByShortName(organisation.countryIso)}`}
        </Grid>
      </Grid>
    </MenuItem>
  );
};

export default OrganisationMenuItem;
