import ClearIcon from '@mui/icons-material/Clear';
import { TextField } from '@mui/material';
import React, { HTMLAttributes, forwardRef } from 'react';
import { ControlProps } from 'react-select';
import { SelectOption } from './types';

type InputComponentProps = HTMLAttributes<HTMLDivElement>;

const inputComponent = forwardRef<HTMLDivElement, InputComponentProps>((props, ref) => {
  return <div ref={ref} {...props} />;
});

export function Control(props: ControlProps<SelectOption, any>) {
  const {
    children,
    innerProps,
    innerRef,
    selectProps: {
      classes,
      //@ts-ignore
      TextFieldProps,
      isClearable,
      //@ts-ignore
      onClearValue,
      inputValue,
      isLoading,
      value,
    },
  } = props;

  const clearButton = !Boolean(value) && Boolean(isClearable) && Boolean(inputValue) && !isLoading && (
    <ClearIcon
      onMouseDown={onClearValue}
      className={classes.clearIcon}
      style={{
        position: 'absolute',
        right: 60,
        top: '50%',
        transform: 'translateY(-50%)',
        opacity: 0.6,
        cursor: 'pointer',
      }}
    />
  );

  return (
    <>
      <TextField
        fullWidth
        variant="outlined"
        InputProps={{
          inputComponent,
          inputProps: {
            className: classes.input,
            ref: innerRef,
            children,
            ...innerProps,
          },
        }}
        {...TextFieldProps}
      />
      {clearButton}
    </>
  );
}
