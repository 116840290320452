import { Distributor, Title } from '../../../../config/api/types';
import { RetourLineRequest } from './api';
import { canBackorder, canReturn, canReturnDefaultNo, canReturnDefaultYes } from '../../domain';

export type RetourLineFormValues = {
  distributor?: Distributor;
  gtin13?: { title: Title; value: string; label: string } | null;
  quantity: number;
  reason?: { value: number; label: string };
  reference?: string;
  // state?: string;
  backorder?: boolean;
  articleReturn?: boolean;
  deliveryNote?: string;
  invoiceNumber?: string;
  lineReference?: string;
  comments?: string;

  rememberInvoice?: boolean;
  rememberReference?: boolean;
};

export const retourLineFormValueToRetourLineRequest = (
  formValues: RetourLineFormValues
): RetourLineRequest => {
  return {
    distributor: formValues.distributor?.id,
    gtin13: formValues.gtin13?.title.gtin13,
    quantity: formValues.quantity,
    reason: formValues.reason?.value.toString(),
    reference: formValues.reference || 'Reference',
    backorder:
      formValues.reason?.value && canBackorder(formValues.reason?.value)
        ? formValues.backorder
        : false,
    articleReturn:
      formValues.reason?.value &&
      canReturn(formValues.reason?.value) &&
      !canReturnDefaultYes(formValues.reason?.value) &&
      !canReturnDefaultNo(formValues.reason?.value)
        ? formValues.articleReturn
        : false,
    deliveryNote: formValues.deliveryNote,
    invoiceNumber: formValues.invoiceNumber,
    lineReference: formValues.lineReference,
    comments: formValues.comments,
  };
};
