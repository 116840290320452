import React, { FC } from 'react';
import PublisherField from '../../../admin/title/containers/fields/PublisherField';
import { useTranslation } from 'react-i18next';
import useApiRoute from '../../../../../config/api/useApiRoute';
import FacetTitle from '../../../catalog/components/Facet/FacetTitle';
import { RemoteAutocompleteProps } from '../../../../../components/Form/RemoteAutocomplete';
import { updateQueryParameters } from '../../../../../helpers/hateoas';

const PublisherFilter: FC<Partial<RemoteAutocompleteProps>> = (props) => {
  const { t } = useTranslation();
  const url = useApiRoute('publishers');

  if (!url) return null;

  return (
    <>
      <FacetTitle>{t('facet_publisher')}</FacetTitle>
      <PublisherField
        url={updateQueryParameters(url, { is_active: 'true' })}
        label=" "
        blurInputOnSelect
        isClearable
        {...props}
      />
    </>
  );
};

export default PublisherFilter;
