import * as React from 'react';

type Props = {
  [key: string]: any;
  name: string;
};

const AwardSelectField = ({ component: Component, ...other }: Props) => <Component {...other} />;

export default AwardSelectField;
