import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { IconButton } from '@mui/material';
import { useFetch } from '@react-redux-fetch/hooks';
import React, { FC } from 'react';
import { ShoppingCartLine } from '../../../../../config/api/models/shop';
import { getRemoveCartLineRequest } from '../../api';

type Props = {
  cartLine: ShoppingCartLine;
};
export const DeleteCartLineButton: FC<Props> = ({ cartLine }) => {
  const cartLineUrl = cartLine._links.shoppingCartLine.href;
  const [removeCartLineFetch, removeCartLine] = useFetch(getRemoveCartLineRequest);

  const removeItem = () => {
    removeCartLine(cartLineUrl);
  };

  return (
    <IconButton onClick={removeItem} disabled={removeCartLineFetch?.pending} size="large">
      <DeleteForeverIcon />
    </IconButton>
  );
};
