import OrganisationDialog from './containers/OrganisationDialog';
import { useLibraryDepartmentsAndBookstores } from './library/hooks/useLibraryDepartmentsAndBookstores';
import LibrarySelectField from './library/components/LibrarySelectField';
import { useFetchBookstore } from './bookstore/hooks/useFetchBookstore';
import { DistributorsSelectField } from './distributor/components/DistributorsSelectField';
import { BookstoresSelectField } from './bookstore/components/BookstoreSelectField';

const organisation = {
  components: {
    OrganisationDialog,
    LibrarySelectField,
    DistributorsSelectField,
    BookstoresSelectField,
  },
  hooks: {
    useLibraryDepartmentsAndBookstores,
    useFetchBookstore,
  },
};
export default organisation;
