import { useContext } from 'react';
import { ClientRouteContext } from './ClientRouteContext';
import { reduce } from 'ramda';

type KeyValueObj = { key: string; value: any };

const objToArray = (obj: Record<string, any>): KeyValueObj[] =>
  Object.keys(obj).map((key) => ({ key, value: obj[key] }));

const usePath = (routeKey: string, params?: Record<string, string>) => {
  const { flatRoutes } = useContext(ClientRouteContext);

  const route = flatRoutes.find((r) => r.key === routeKey);

  if (!route) {
    // throw new Error(`Route with key ${routeKey} not found`);
    return '';
  }

  if (!params) {
    return route.path;
  }

  const reduceParams = reduce((agg, val: KeyValueObj) => {
    return agg.replace(`:${val.key}`, val.value);
  }, route.path);

  return reduceParams(objToArray(params));
};

export default usePath;
