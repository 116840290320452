const createXmlHttpRequest = (
  scheme: string,
  uri: string,
  formData: FormData,
  jwtToken: string,
  progressCallback?: (p: number) => void,
  // finishedCallback?: () => void,
  errorCallback?: (errorCode: number, errorMessage: string) => void
) => {
  const xhr = new XMLHttpRequest();
  xhr.open(scheme, uri, true);
  xhr.setRequestHeader('authorization', 'bearer ' + jwtToken);
  if (progressCallback && xhr.upload) {
    xhr.upload.onprogress = (e) => {
      if (e.lengthComputable) {
        progressCallback((e.loaded / e.total) * 90);
      }
    };
    xhr.upload.onloadstart = (e) => progressCallback(0);
    xhr.upload.onabort = (e) => {
      console.log('abort', xhr.status);
    };
    xhr.upload.onerror = (e) => errorCallback && errorCallback(xhr.status, xhr.response);
    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        if (xhr.status >= 400 && errorCallback) {
          errorCallback(xhr.status, xhr.response);
        } else if (xhr.status >= 200 && xhr.status < 300) {
          progressCallback(100);
        } else {
          if (errorCallback) {
            errorCallback(xhr.status, 'error undefined');
          }
        }
      }
    };

    xhr.onerror = (e) => errorCallback && errorCallback(xhr.status, xhr.response);
  }
  // xhr.onprogress = e => {
  //   if (e.lengthComputable) {
  //     console.log('-----', (e.loaded / e.total) * 100);
  //   }
  // };

  xhr.send(formData);
};

export default createXmlHttpRequest;
