import { container } from 'react-redux-fetch';
import optionsReducer from './optionsReducer';
import patchReducer from './patchReducer';
// @ts-ignore
import fetchRequest from 'react-redux-fetch/lib/middleware/fetchRequest';

const initReduxFetch = () => {
  container.registerRequestMethod('options', {
    method: 'options',
    middleware: fetchRequest,
    reducer: optionsReducer,
  });

  container.registerRequestMethod('patch', {
    method: 'PATCH',
    middleware: fetchRequest,
    reducer: patchReducer,
  });
};

export default initReduxFetch;
