import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import BaseReactSelectField, {
  BaseReactSelectFieldProps,
} from '../../../components/Form/BaseReactSelectField';
import palette from '../../../config/theme/palette';
import { getOrderLineStatuses } from '../selectors';

type Props = Partial<BaseReactSelectFieldProps> & {
  colored?: boolean;
};

type Option = {
  value: string;
  label: string;
};

const OrderStatusSelect = ({ colored, ...other }: Props) => {
  const { t } = useTranslation();
  const statuses = useSelector(getOrderLineStatuses);

  const getBackgroundColor = (value: string) => {
    if (!colored) {
      return {};
    }
    switch (value) {
      case 'cancelled':
        return { backgroundColor: palette.status.expected };
      case 'delivered':
        return { backgroundColor: palette.status.available };
      default:
        return {};
    }
  };

  const options: Option[] = statuses
    ? statuses.map((status) => ({
        label: t(`orderline_status_${status}`),
        value: status,
      }))
    : [];

  return (
    <BaseReactSelectField
      name="state"
      options={options}
      getOptionValue={(option: Option) => option.value}
      getOptionLabel={(option: Option) => option.label}
      parse={(option: Option) => option.value}
      format={(value: string) => options.find((option) => option.value === value)}
      disabled={!statuses}
      styles={{
        valueContainer: (provided: any, state: any) => ({
          ...provided,
          ...getBackgroundColor(state.selectProps?.value?.value),
        }),
      }}
      blurInputOnSelect
      {...other}
    />
  );
};

export default OrderStatusSelect;
