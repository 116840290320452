import { CloudDownload } from '@mui/icons-material';
import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { saveAs } from 'file-saver';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, DispatchProp } from 'react-redux';
import Button from '../../../../../components/Button';
import { OrderFile } from '../../../../../config/api/models/shop';
import apiRoutes from '../../../../../config/api/routes';
import { TitleFile } from '../../../../../config/api/types';
import { RootState } from '../../../../../config/store/types';
import getFilename from '../../../../../helpers/getFilename';
import { getLink } from '../../../../../helpers/hateoas';
import alerts from '../../../../core/alerts/index';
import security from '../../../../security/index';

const useStyles = makeStyles((theme: Theme) => ({
  leftIcon: {
    marginRight: theme.spacing(1),
    fontSize: 20,
  },
  downloadBtn: {
    marginBottom: theme.spacing(2),
  },
}));

type Props = DispatchProp & {
  titleFile: TitleFile | OrderFile;
  jwt: string;
};

enum FETCH_STATE {
  IDLE,
  PENDING,
}

const DownloadProcessResult = ({ titleFile, jwt, dispatch }: Props) => {
  const { t } = useTranslation();
  const [fetchState, setFetchState] = useState(FETCH_STATE.IDLE);
  const classes = useStyles();

  const downloadLink = getLink(titleFile, 'importReport');

  if (!downloadLink) {
    return null;
  }

  const handleClick = async () => {
    setFetchState(FETCH_STATE.PENDING);
    const res = await fetch(apiRoutes.get(downloadLink), {
      headers: {
        Authorization: `bearer ${jwt}`,
      },
    });

    if (res.status >= 400) {
      dispatch(alerts.actions.openError(res.statusText));
    } else {
      const blob = await res.blob();
      saveAs(blob, `${getFilename(titleFile.path)}.txt`);
    }

    setFetchState(FETCH_STATE.IDLE);
  };

  return (
    <Button
      className={classes.downloadBtn}
      onClick={handleClick}
      disabled={fetchState === FETCH_STATE.PENDING}
    >
      <CloudDownload className={classes.leftIcon} />
      {t('titles_download_process_result')}
    </Button>
  );
};

const mapState = (state: RootState) => ({
  jwt: security.selectors.getJwtToken(state),
});

export default connect(mapState)(DownloadProcessResult);
