import { createStyles, Theme } from '@mui/material';

export const tabHeaderStyles = (theme: Theme) =>
  createStyles({
    wrapper: {
      width: '100%',
      backgroundColor: 'red',
      marginTop: theme.spacing(4),
    },
    divider: {
      marginTop: '-2px',
      height: 2,
      backgroundColor: theme.palette.background.appbarHover,
      zIndex: 9,
      position: 'relative',
      marginBottom: theme.spacing(4),
    },
    indicator: {
      display: 'none',
    },
    tabsContainer: {
      paddingLeft: 1,
    },
    tab: {
      borderRight: `1px solid ${theme.palette.background.appbarHover}`,
      backgroundColor: theme.palette.background.appbar,
      color: theme.palette.text.primary,
      '& .MuiTab-wrapper': {
        flexDirection: 'row',
      },
    },
    selectedTab: {
      marginLeft: '-1px',
      backgroundColor: '#fff',
      borderTop: `2px solid ${theme.palette.background.appbarHover}`,
      borderLeft: `2px solid ${theme.palette.background.appbarHover}`,
      borderRight: `2px solid ${theme.palette.background.appbarHover}`,
      zIndex: 10,
      color: `${theme.palette.text.primary} !important`,
    },
  });
