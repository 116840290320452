import { Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ListHeading from './ListHeading';

type Props = {
  heading: string;
  html?: string;
};

const SimpleContent = ({ heading, html }: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <ListHeading>{t(`title_heading_${heading}`)}</ListHeading>
      {html && (
        <Typography style={{ whiteSpace: 'normal' }} dangerouslySetInnerHTML={{ __html: html }} />
      )}
    </>
  );
};

export default SimpleContent;
