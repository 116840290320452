import { Distributor, FormattedDate, Organisation, Paged, Resource } from '../types';
import { BookstoreGroup, CashierSystem, OrderFileType } from './dataSets';

export type OrganisationRef = string;
export type DataConsumerRef = string;
export type DistributorRef = string;
export type BookstoreRef = string;
export type OrgLoginId = string;

export type CreateOrgLoginDTO = {
  email: string;
  firstName: string;
  lastName: string;
};

export type UpdateOrgLoginDTO = CreateOrgLoginDTO & {
  isActive: boolean;
};

export type OrgLogin = Resource<{
  email: OrgLoginId;
  firstName: string;
  lastName: string;
  username: string;
  departmentId?: string;
  isActive: boolean;
  createdAt: FormattedDate;
  lastLoginAt: FormattedDate;
  loginId: OrgLoginId;
}>;

export type OrgLogins = Paged<OrgLogin>;

export type Distributors = Paged<Distributor>;

export type NormalizedOrgLogins = Record<OrgLoginId, OrgLogin>;

export type NormalizedOrgLoginsPage = Paged<OrgLoginId>;

export type NormalizedOrganisations = Record<OrganisationRef, Organisation>;

export type NormalizedDistributors = Record<DistributorRef, Distributor>;

export type NormalizedBookstores = Record<BookstoreRef, Bookstore>;

export type NormalizedPagedBookstores = Paged<BookstoreRef>;

export enum DataConsumerFileType {
  CSV = 'csv',
  ONIX = 'onix',
}

export type DataConsumerDTO = {
  isFtpTitleMutationConsumer: boolean;
  isFtpMediaFileConsumer: boolean;
  isWebserviceTitleConsumer: boolean;
  fileType: DataConsumerFileType;
  apiKey: string;
};
export type DataConsumer = Resource<
  DataConsumerDTO,
  {
    self: { href: string };
    refreshApiKey: { href: string };
  },
  {
    organisation: Organisation;
  }
>;

export type BookstoreDto = {
  cbRelationId: string;
  group: BookstoreGroup;
  cashierSystem: CashierSystem;
  orderFileType: OrderFileType;
  wantsEmailNotifications: boolean;
  isMemberBookstoresFlanders: boolean;
  usesFlowNumber: boolean;
  email: string;
};

export type Bookstore = Resource<
  BookstoreDto & {
    bookstoreId: string;
  },
  {
    self: { href: string };
    refreshApiKey: { href: string };
    groups: { href: string };
    cashierSystems: { href: string };
    orderFileTypes: { href: string };
    organisation: { href: string };
    library?: { href: string };
    funds?: { href: string };
    unConfirmedRetour?: { href: string };
  },
  {
    organisation: Organisation;
  }
>;

export type NormalizedDataConsumers = Record<DataConsumerRef, DataConsumer>;
