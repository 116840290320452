import { RootState } from '../../../config/store/types';

export const getTitleSearch = (state: RootState) => state.data.titleSearch;

export const getProductFormGroups = (state: RootState) => state.data.productFormGroups;

export const getTitleAggregations = (state: RootState) => state.data.titleAggregations;

export const getTitleAggregationsFor = (name: string) => (state: RootState) => {
  const allAggregations = getTitleAggregations(state);
  return allAggregations?.find((agg) => agg.name === name);
};
