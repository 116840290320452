import { Box, Divider, Grid, Tooltip, Typography } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ShoppingCartLine } from '../../../../config/api/models/shop';
import formatEuro from '../../../../helpers/formatEuro';
import { IsbnLink } from '../../../title';

type Props = {
  lines?: ShoppingCartLine[];
};

const OrderBookstoreDetailTable: FC<Props> = ({ lines }) => {
  const { t } = useTranslation();

  return (
    <>
      {lines?.map((line) => (
        <React.Fragment key={line._links.shoppingCartLine.href}>
          <Grid container spacing={1}>
            <Grid item style={{ width: 120 }}>
              <Typography>
                <IsbnLink orderLine={line} />
              </Typography>
            </Grid>
            <Grid item style={{ flex: 1, overflow: 'hidden' }}>
              <Tooltip placement="top-start" title={line._embedded.title.title} enterDelay={500}>
                <Typography noWrap>{line._embedded.title.title}</Typography>
              </Tooltip>
            </Grid>
            <Grid item style={{ width: 160 }}>
              <Typography>
                {line._embedded.title.productForm.shortLabel ||
                  line._embedded.title.productForm.label}
              </Typography>
            </Grid>
            <Grid item style={{ width: 160 }}>
              <Typography>{line.reference}</Typography>
            </Grid>
            <Grid item style={{ textAlign: 'center', width: 80 }}>
              <Typography>{line.quantity}</Typography>
            </Grid>
            <Grid item style={{ textAlign: 'right', width: 80 }}>
              <Typography>{formatEuro(line.unitPrice)}</Typography>
            </Grid>
            <Grid item style={{ textAlign: 'right', width: 80 }}>
              <Typography>{formatEuro(line.total)}</Typography>
            </Grid>
            <Grid item style={{ textAlign: 'center', width: 160 }}>
              <Typography>{t('orderline_status', { context: line.stateBookstore })}</Typography>
            </Grid>
          </Grid>
          <Box marginTop={1} marginBottom={1}>
            <Divider />
          </Box>
        </React.Fragment>
      ))}
    </>
  );
};

export default OrderBookstoreDetailTable;
