import { Theme } from '@mui/material';
import Divider from '@mui/material/Divider';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { WithStyles, createStyles, withStyles } from '@mui/styles';
import classNames from 'classnames';
import join from 'ramda/es/join';
import map from 'ramda/es/map';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Avatar from '../../../../components/Avatar';
import { Organisation, User } from '../../../../config/api/types';

type Props = WithStyles<typeof styles> & {
  handleClose: () => void;
  anchorEl: HTMLElement;
  onLogout: () => void;
  user: User;
  organisation?: Organisation;
};

const styles = (theme: Theme) =>
  createStyles({
    root: {
      marginTop: 50,
      minWidth: 250,
    },
    header: {
      display: 'flex',
      pointerEvents: 'none',
    },
    headerText: {
      margin: 0,
      maxWidth: 250,
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
    divider: {
      margin: '16px 0 16px 0',
    },
    bold: {
      fontWeight: theme.typography.fontWeightMedium,
    },
  });

const SettingsMenu = ({ classes, handleClose, anchorEl, onLogout, user, organisation }: Props) => {
  const { t } = useTranslation();
  const fullName = `${user.firstName} ${user.lastName}`;
  const mappedRoles = user && user.roles ? map((role) => t(`${role}`))(user.roles) : [];

  return (
    <Menu
      classes={{
        paper: classes.root,
      }}
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <MenuItem
        classes={{
          root: classes.header,
        }}
      >
        <Avatar>{fullName}</Avatar>
        <div>
          <p className={classNames(classes.headerText, classes.bold)}>{fullName}</p>
          <p className={classes.headerText}>{user.email}</p>
        </div>
      </MenuItem>
      {/*<MenuItem>Persoonlijke gegevens</MenuItem>*/}
      {/*<MenuItem>Wachtwoord wijzigen</MenuItem>*/}
      <MenuItem onClick={onLogout}>Afmelden</MenuItem>
      {organisation && (
        <div>
          <Divider className={classes.divider} />
          <MenuItem
            classes={{
              root: classes.header,
            }}
          >
            <Avatar>{organisation.name}</Avatar>
            <div>
              <p className={classNames(classes.headerText, classes.bold)}>{organisation.name}</p>
              <p className={classes.headerText}>{join('/')(mappedRoles)}</p>
            </div>
          </MenuItem>
          {/*<MenuItem>Gegevens Organisatie</MenuItem>*/}
          {/*<MenuItem>Gebruikers</MenuItem>*/}
          {/*<MenuItem>Voorkeuren</MenuItem>*/}
        </div>
      )}
    </Menu>
  );
};

export default withStyles(styles)(SettingsMenu);
