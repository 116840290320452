import { themaFields } from '../../domain';
import React from 'react';

type Props = {
  [key: string]: any;
  innerComponent?: React.ReactNode;
  name: keyof { [key in typeof themaFields[number]]: true };
};

const ThemaField = ({ component: Component, innerComponent, ...other }: Props) => (
  <Component component={innerComponent} {...other} />
);

export default ThemaField;
