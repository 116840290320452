import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { FC } from 'react';
import Placeholder from '../../../../components/Placeholder';
import { MediaCover } from '../../../../config/api/types';

const useStyles = makeStyles((theme: Theme) => ({
  placeholder: {
    height: '100%',
    background: theme.palette.background.appbar,
    display: 'flex',
    alignItems: 'center',
    maxHeight: 176,
  },
}));

type Props = {
  title: string;
  frontCover?: MediaCover;
};
const SmallFrontCover: FC<Props> = ({ title, frontCover }) => {
  const classes = useStyles();

  return frontCover ? (
    <img src={frontCover.thumbnail || frontCover.thumbnailSmall} alt={`Cover ${title}`} />
  ) : (
    <div className={classes.placeholder}>
      <Placeholder alt={`Cover ${title}`} />
    </div>
  );
};

export default SmallFrontCover;
