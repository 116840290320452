import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useFetch } from '@react-redux-fetch/hooks';
import { useSelector } from 'react-redux';
import { getBookstoreGroupsRequest } from '../../../api';
import { getBookstoreGroups } from '../../selectors';
import BaseReactSelectField from '../../../../../components/Form/BaseReactSelectField';
import { BookstoreGroup } from '../../../../../config/api/models/dataSets';

type Props = {
  url: string;
};

const BookstoreGroupField = ({ url }: Props) => {
  const { t } = useTranslation();
  const [, fetchBookstoreGroups] = useFetch(getBookstoreGroupsRequest);
  const bookstoreGroups = useSelector(getBookstoreGroups);

  useEffect(() => {
    if (!bookstoreGroups) {
      fetchBookstoreGroups(url);
    }
  }, [bookstoreGroups, fetchBookstoreGroups, url]);

  if (!bookstoreGroups) {
    return null;
  }

  return (
    <BaseReactSelectField
      label={t('form_bookstore_group')}
      name="group"
      options={bookstoreGroups}
      getOptionLabel={(option: BookstoreGroup) => option.name}
      getOptionValue={(option: BookstoreGroup) => option.code}
      isClearable
    />
  );
};

export default BookstoreGroupField;
