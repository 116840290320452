import {
  NormalizedOrganisations,
  NormalizedOrgLogins,
  OrganisationRef,
  OrgLogin,
  OrgLoginId,
  OrgLogins,
} from '../../config/api/models/organisation';
import { RootState } from '../../config/store/types';
import {
  Library,
  Maybe,
  Organisation,
  Organisations,
  PublishersList,
} from '../../config/api/types';

const getAllOrganisations = (state: RootState): Maybe<NormalizedOrganisations> =>
  state.data.organisations;

export const getLibraries = (state: RootState): Maybe<Library[]> => state.data.libraries;
export const getPublishersList = (state: RootState): Maybe<PublishersList> => state.data.publishers;

export const getOrganisations = (state: RootState): Maybe<Organisations> =>
  state.data.organisationSearch;

export const getOrganisationByRef = (organisationRef: OrganisationRef) => (
  state: RootState
): Maybe<Organisation> => {
  return (getAllOrganisations(state) || {})[organisationRef];
};

export const getOrganisation = (state: RootState): Maybe<Organisation> => state.data.organisation;

const getAllOrgLogins = (state: RootState): Maybe<NormalizedOrgLogins> => state.data.orgLogins;

export const getOrgLogin = (orgLoginRef: OrgLoginId) => (state: RootState): Maybe<OrgLogin> => {
  return (getAllOrgLogins(state) || {})[orgLoginRef];
};

export const getPagedOrgLogins = (state: RootState): Maybe<OrgLogins> => {
  const orgLoginsPage = state.data.orgLoginsPage;
  const allOrgLogins = getAllOrgLogins(state);

  if (!orgLoginsPage || !allOrgLogins) {
    return null;
  }

  return {
    ...orgLoginsPage,
    _embedded: {
      items: orgLoginsPage._embedded.items.map((orgLoginRef) => allOrgLogins[orgLoginRef]),
    },
  };
};
