import React from 'react';
import { useTranslation } from 'react-i18next';
import { SelectField as Select } from '../../../../../components/Form/index';
import {
  STATUS_EMPTY,
  STATUS_FAILED_CLIENT,
  STATUS_FAILED_SERVER,
  STATUS_PROCESSED,
  STATUS_PROCESSED_WARNING,
  STATUS_PROCESSING,
  STATUS_UPLOADED,
  StatusType,
} from '../../../../../config/api/types';
import { SelectFieldProps } from '../../../../../components/Form/SelectField';

type Props = {
  onChange: SelectFieldProps['onChange'];
  defaultValue?: string;
};

type Items = { [status in StatusType]: string };

const StatusFilter = ({ onChange, defaultValue }: Props) => {
  const { t } = useTranslation();

  const items: Items = {
    [STATUS_UPLOADED]: t('pending'),
    [STATUS_PROCESSING]: t('processing'),
    [STATUS_PROCESSED]: t('processed'),
    [STATUS_PROCESSED_WARNING]: t('processed_warning'),
    [STATUS_FAILED_CLIENT]: t('processing_error'),
    [STATUS_FAILED_SERVER]: t('processing_error_server'),
    [STATUS_EMPTY]: t('empty'),
  };

  return (
    <Select items={items} onChange={onChange} label={t('filter_result')} value={defaultValue} />
  );
};

export default StatusFilter;
