import Grid, { GridProps } from '@mui/material/Grid/Grid';
import React from 'react';

type Props = {
  children: GridProps['children'];
};

const FormFieldWrapper = ({ children }: Props) => (
  <Grid container spacing={1}>
    <Grid item xs={12}>
      {children}
    </Grid>
  </Grid>
);

export default FormFieldWrapper;
