import { Box, Divider, Grid, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ConstrainedPageContent from '../../../components/ConstrainedPageContent';
import PageTitle from '../../../components/PageTitle';
import { ReactComponent as M4BLogo } from '../../../config/assets/pictures/meta4books_logo.svg';
import { EMPLOYEES } from '../../../config/data/employees';
import { EXTERNAL_LINKS } from '../../../config/data/external_links';
import ExternalLink from '../../app/components/ExternalLink';
import EmployeeCard from '../components/EmployeeCard';

export const ContactPage = () => {
  const { t } = useTranslation();

  return (
    <ConstrainedPageContent center={false} lg>
      <Box pb={3}>
        <PageTitle>{t('nav_help_contact')}</PageTitle>
      </Box>
      <Grid container spacing={4}>
        {EMPLOYEES.map((employee) => (
          <Grid item xs={12} lg={6}>
            <EmployeeCard
              fullName={employee.fullName}
              emailAddress={employee.emailAddress}
              phoneNumber={employee.phoneNumber}
              descriptionI18nId={employee.descriptionI18nId}
            />
          </Grid>
        ))}
      </Grid>
      <Box py={4}>
        <Divider />
      </Box>
      <Box display="flex" alignItems="center">
        <ExternalLink href={EXTERNAL_LINKS['m4b-home']}>
          <Box height={80}>
            <M4BLogo />
          </Box>
        </ExternalLink>
        <Box pl={4}>
          <Typography>
            Meta4books vzw <br />
            Vestinglaan 57 bus 9 <br />
            2650 Edegem (België)
          </Typography>
          <ExternalLink href={EXTERNAL_LINKS['m4b-home']}>www.meta4books.be</ExternalLink>
        </Box>
      </Box>
    </ConstrainedPageContent>
  );
};
