import React from 'react';
import { RouteComponentProps } from 'react-router';
import Header from '../../../../../components/Header';
import Detail from '../containers/Detail';
import { useTranslation } from 'react-i18next';

type Params = { id: string };
type Props = RouteComponentProps<Params> & {};

const ProcessResult = (props: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <Header title={t('page_title_titlefile_process_result')} goBack />
      <Detail {...props} />
    </>
  );
};

export default ProcessResult;
