import React, { FC, useCallback } from 'react';
import FacetWrapper from '../../components/Facet/FacetWrapper';
import { useTranslation } from 'react-i18next';
import AgeRangeGroupField from '../../../admin/title/containers/fields/AgeRangeGroupField';
import useApiRoute from '../../../../../config/api/useApiRoute';
import { Form } from 'react-final-form';
import AutoSave from '../../../../../components/Form/AutoSave';
import { AgeRangeGroup } from '../../../../../config/api/models/dataSets';
import SetInitialValueWhenLoaded from '../../../../../components/Form/SetInitialValueWhenLoaded';
import { getAgeRangeGroups } from '../../../admin/title/selectors';
import { QueryObjectValue } from '../../../../../helpers/elasticQueryDSL';

type Props = {
  onFilter: (ageRangeGroup?: string) => void;
  filters: {
    ageRangeGroup?: QueryObjectValue;
  };
};

type FormValues = {
  ageRangeGroup?: AgeRangeGroup;
};

const AgeRangeFacet: FC<Props> = ({ onFilter, filters }) => {
  const { t } = useTranslation();
  const url = useApiRoute('titleAgeRangeGroups');

  const ageRangeGroup = filters?.ageRangeGroup;
  const findAgeRangeGroupValue = useCallback((agr: AgeRangeGroup) => agr.value === ageRangeGroup, [
    ageRangeGroup,
  ]);

  if (!url) {
    return null;
  }

  return (
    <FacetWrapper
      title={t('facet_ageRange')}
      collapsible={false}
      active={Boolean(filters.ageRangeGroup)}
    >
      <Form
        onSubmit={(values: FormValues) => {
          onFilter(values.ageRangeGroup?.value);
        }}
      >
        {({ form: { submit } }) => (
          <>
            <AutoSave save={submit} />
            <AgeRangeGroupField
              url={url}
              blurInputOnSelect
              onClearValue={submit}
              placeholder={t('facet_ageRange_placeholder')}
            />
            {ageRangeGroup && (
              <SetInitialValueWhenLoaded
                name="ageRangeGroup"
                dataSelector={getAgeRangeGroups}
                findValue={findAgeRangeGroupValue}
              />
            )}
          </>
        )}
      </Form>
    </FacetWrapper>
  );
};
export default AgeRangeFacet;
