import React from 'react';
import { useTranslation } from 'react-i18next';
import PageTitle from '../../../../components/PageTitle';
import PrefixApplicationOverviewWrapper from '../containers/PrefixApplicationOverviewWrapper';

const PrefixApplicationOverview = () => {
  const { t } = useTranslation();
  return (
    <>
      <PageTitle>{t('page_title_prefix_application_overview')}</PageTitle>
      <PrefixApplicationOverviewWrapper />
    </>
  );
};

export default PrefixApplicationOverview;
