import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translations from './translations';
import moment from 'moment';
import 'moment/locale/nl-be';

moment.locale('nl-be');

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      nl: {
        translation: translations,
      },
    },
    debug: false,
    lng: 'nl',
    fallbackLng: 'nl',
    interpolation: {
      escapeValue: false,
    },
  });
