import TitlePage from './scenes/TitlePage';
import AddTitlePage from './scenes/AddTitlePage';
import NewTitlePage from './scenes/NewTitlePage';
import SearchTitleField from './containers/fields/SearchTitleField';

const title = {
  scenes: {
    TitlePage,
    AddTitlePage,
    NewTitlePage,
  },
  components: {
    SearchTitleField,
  },
};

export default title;
