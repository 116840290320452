import React, { FC } from 'react';

type Props = {
  children: string;
};

const Multiline: FC<Props> = ({ children }) => {
  return (
    <>
      {children.split('\n').map((item, index) => (
        <React.Fragment key={index}>
          {item}
          <br />
        </React.Fragment>
      ))}
    </>
  );
};

export default Multiline;
