import { Grid, Theme, Typography } from '@mui/material';
import { WithStyles, createStyles, withStyles } from '@mui/styles';
import classNames from 'classnames';
import React from 'react';
import { useFormState } from 'react-final-form';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Field from '../../../components/Form';
import SubTitle from '../../../components/SubTitle';
import { User } from '../../../config/api/types';
import ROUTE_KEY from '../../../config/routes/routeKeys';
import usePath from '../../../config/routes/usePath';

type Props = WithStyles<typeof styles> & {
  user?: User;
  EmailUniqueError?: boolean;
  formType: 'isbn' | 'prefix';
};

const styles = (theme: Theme) =>
  createStyles({
    formText: {
      margin: 0,
    },
    bold: {
      fontWeight: theme.typography.fontWeightMedium,
    },
    hidden: {
      display: 'none',
    },
  });

const UserForm = ({ user, classes, EmailUniqueError, formType }: Props) => {
  const { t } = useTranslation();
  const passwordResetLink = usePath(ROUTE_KEY.FORGOT_PW);
  const {
    values: {
      login: { email },
    },
  } = useFormState();

  return (
    <Grid container spacing={3} direction="column">
      {user ? (
        <Grid item>
          <SubTitle>{t('form_check_personal_info_title')}</SubTitle>
          <Typography>{t(`form_${formType}_check_personal_info`)}</Typography>
        </Grid>
      ) : (
        <Grid item>
          <SubTitle>{t('form_isbn_personal_info_title')}</SubTitle>
          <Typography>{t('form_isbn_personal_info')}</Typography>
        </Grid>
      )}

      {user && (
        <Grid item container direction="column" spacing={1}>
          <Grid item>
            <Typography className={classNames(classes.formText, classes.bold)}>
              {t('form_firstName_and_lastName')}
            </Typography>
            <Typography className={classes.formText}>
              {user?.firstName} {user?.lastName}
            </Typography>
          </Grid>

          <Grid item>
            <Typography className={classNames(classes.formText, classes.bold)}>
              {t('form_email')}
            </Typography>
            <Typography className={classes.formText}>{user?.email}</Typography>
          </Grid>
        </Grid>
      )}

      <Grid item container direction="column" className={user ? classes.hidden : ''}>
        <Grid item>
          <Field label={t('form_firstName')} type="text" name="login.firstName" />
        </Grid>
        <Grid item className={user ? classes.hidden : ''}>
          <Field label={`${t('form_lastName')} (*)`} type="text" name="login.lastName" />
        </Grid>
        <Grid item>
          <Field
            label={`${t('form_email')} (*)`}
            type="text"
            name="login.email"
            helperText={t('form_personal_email_helpertext')}
            customError={
              EmailUniqueError ? (
                <Trans
                  i18nKey="email_not_unique"
                  components={[
                    <Link
                      to={{
                        pathname: passwordResetLink,
                        state: {
                          email,
                        },
                      }}
                    />,
                  ]}
                />
              ) : undefined
            }
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(UserForm);
