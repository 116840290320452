import CloseIcon from '@mui/icons-material/Close';
import InsertDriveIcon from '@mui/icons-material/InsertDriveFileOutlined';
import { Theme } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import { WithStyles, createStyles, withStyles } from '@mui/styles';
import length from 'ramda/es/length';
import React from 'react';
import { DropzoneState } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import Button from '../../../../components/Button';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'flex-start',
    },
    icon: {
      height: 70,
      width: 70,
      color: theme.palette.buttons.lightDisabled,
    },
    currentItem: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    progressBar: {
      marginTop: theme.spacing(0.5),
      marginBottom: theme.spacing(0.5),
      width: '100%',
      height: 10,
      borderRadius: 5,
    },
    progressRoot: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(2),
      alignSelf: 'center',
      minWidth: 400,
    },
    barColor: {
      backgroundColor: theme.palette.buttons.primaryGreen,
    },
    backBarColor: {
      backgroundColor: 'rgba(242, 242, 242, 1)',
    },
    button: {
      alignSelf: 'center',
    },
    closeIcon: {
      cursor: 'pointer',
    },
    successMessage: {
      fontWeight: 600,
    },
  });

type Props = WithStyles<typeof styles> & {
  sendXhr: (files: File[]) => void;
  acceptedFiles?: DropzoneState['acceptedFiles'];
  progress: number;
  filenameText: string;
  itemsFinished: number;
  cancelCallback: () => void;
};

const FileUploadStatus = ({
  classes,
  sendXhr,
  acceptedFiles,
  progress,
  filenameText,
  itemsFinished,
  cancelCallback,
}: Props) => {
  const { t } = useTranslation();
  const allFinished = progress === 100;

  return (
    <>
      {acceptedFiles && (
        <div className={classes.root}>
          <InsertDriveIcon className={classes.icon} />
          <div className={classes.progressRoot}>
            <div className={classes.currentItem}>
              <div>
                {allFinished ? (
                  <Typography className={classes.successMessage}>
                    {t('form_files_success')}
                  </Typography>
                ) : (
                  <Typography>{filenameText}</Typography>
                )}
              </div>
              <CloseIcon onClick={cancelCallback} className={classes.closeIcon} />
            </div>
            <LinearProgress
              variant="determinate"
              value={progress}
              className={classes.progressBar}
              classes={{
                root: classes.backBarColor,
                barColorPrimary: classes.barColor,
              }}
            />
            <Typography>
              {t('form_files_uploaded', { count: itemsFinished, total: length(acceptedFiles) })}
            </Typography>
          </div>
          <Button
            className={classes.button}
            onClick={() => sendXhr(acceptedFiles)}
            disabled={progress !== 0}
          >
            {t('form_upload_files')}
          </Button>
        </div>
      )}
    </>
  );
};

export default withStyles(styles)(FileUploadStatus);
