import { useCallback } from 'react';
import getFileExtension from '../../../../../helpers/getFileExtension';

type Props = {
  fileName: string;
  onload?: (base64: string) => void;
  onBeforeLoad?: (file: File) => void;
};

const useRenameBeforeUpload = ({ fileName, onload, onBeforeLoad }: Props) => {
  return useCallback(
    (file: File) => {
      if (onBeforeLoad) {
        onBeforeLoad(file);
      }
      if (onload) {
        const reader = new FileReader();
        reader.onload = () => {
          const photo = reader.result as string;
          onload(photo);
        };
        reader.readAsDataURL(file);
      }
      const extension = getFileExtension(file.name);
      return {
        file,
        fileName: `${fileName}.${extension}`,
      };
    },
    [fileName, onload, onBeforeLoad]
  );
};
export default useRenameBeforeUpload;
