import { Grid } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Pagination from '../../../../components/Pagination';
import { QueryObject } from '../../../../helpers/elasticQueryDSL';
import useQueryParams from '../../../../helpers/useQueryParams';
import NothingFiltered from '../components/NothingFiltered';
import TitleList from '../components/TitleList';
import useFetchTitles, { NOT_PRODUCT_TYPE } from '../useFetchTitles';
import Facets from './Facets';

type QueryParams = {
  [key: string]: string;
} & {
  q?: string;
};

const filtersAreEmpty = (filters: QueryObject) => {
  const filtersToCheck = {
    ...filters,
    [NOT_PRODUCT_TYPE]: undefined,
  };

  return Object.values(filtersToCheck).filter(Boolean).length === 0;
};

const Catalog = () => {
  const { search } = useLocation();
  const {
    queryObj: { q, ...facets },
  } = useQueryParams<QueryParams>(search);

  const [facetVersion, setFacetVersion] = useState(1);
  const { loading, titles, params, changeParams, filters, onFilter } = useFetchTitles(
    decodeURIComponent(q ?? ''),
    facets
  );

  const hasActiveFilters = !filtersAreEmpty(filters);
  const hasActiveFiltersAndSearch = hasActiveFilters || Boolean(params.q);
  const handleClear = useCallback(() => {
    onFilter();
    // A little hacky but the fastest way to clear the form values
    setFacetVersion((prevState) => prevState + 1);
  }, [onFilter]);

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={4} sm={3} md={2}>
          <Facets
            filters={filters}
            onFilter={onFilter}
            changeParams={changeParams}
            onClear={handleClear}
            hasActiveFilters={hasActiveFilters}
            key={facetVersion}
          />
        </Grid>
        <Grid item xs={8} sm={9} md={10}>
          {hasActiveFiltersAndSearch ? (
            <TitleList
              titles={titles}
              changeSort={(order, orderDir) => changeParams({ order, orderDir })}
              order={params?.order}
              orderDir={params?.orderDir}
              loading={loading}
              hasActiveFilters={hasActiveFiltersAndSearch}
              changeParams={changeParams}
            />
          ) : (
            <NothingFiltered />
          )}
        </Grid>
      </Grid>
      {hasActiveFiltersAndSearch && titles && titles.total > titles.limit && (
        <Grid container sx={{ justifyContent: 'center' }}>
          <Pagination
            boundaryCount={2}
            count={titles.pages}
            onChange={(ev, page) => {
              changeParams({ page });
              scrollToTop();
            }}
            page={titles.page}
          />
        </Grid>
      )}
    </>
  );
};

export default Catalog;
