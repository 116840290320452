import { FormControlLabel } from '@mui/material';
import { useFetch } from '@react-redux-fetch/hooks';
import difference from 'lodash/difference';
import { prop, sortBy, without } from 'ramda';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import ShowMoreButton from '../../../../../components/ShowMoreButton';
import useApiRoute from '../../../../../config/api/useApiRoute';
import { QueryObjectValue } from '../../../../../helpers/elasticQueryDSL';
import { getProductFormGroupRequest } from '../../api';
import { FacetCheckBox } from '../../components/Facet/FacetCheckBox';
import FacetWrapper from '../../components/Facet/FacetWrapper';
import { getProductFormGroups, getTitleAggregationsFor } from '../../selectors';

type Props = {
  onFilter: (values: string[]) => void;
  filters?: QueryObjectValue;
};

type FacetItem = {
  code: string;
  label: string;
  docCount: number;
};

const MAX_ITEMS = 5;
const sortByDocCount = sortBy(prop('docCount'));

const ProductFormGroupFacet: FC<Props> = ({ onFilter, filters }) => {
  const { t } = useTranslation();
  const url = useApiRoute('titleProductFormGroups');
  const [numItemsToShow, setNumItemsToShow] = useState(MAX_ITEMS);
  const [isNewMount, setIsNewMount] = useState<boolean>(true);
  useFetch(() => (url ? getProductFormGroupRequest(url) : null), { eager: Boolean(url) });
  const productFormGroups = useSelector(getProductFormGroups);
  const aggregations = useSelector(getTitleAggregationsFor('productForm.group.code'));

  const handleShowMore = () => setNumItemsToShow(numItemsToShow + MAX_ITEMS);
  const handleShowLess = () => setNumItemsToShow(MAX_ITEMS);

  const arrFilters = useMemo(
    () => (Array.isArray(filters) ? (filters as string[]) : filters ? [filters as string] : []),
    [filters]
  );

  const facetItems: FacetItem[] = useMemo(
    () =>
      Boolean(productFormGroups && aggregations)
        ? sortByDocCount(
            productFormGroups!
              .map((productFormGroup) => ({
                code: productFormGroup.code,
                label: productFormGroup.label,
                docCount: (
                  aggregations!.keys.find((facet) => facet.key === productFormGroup.code) || {
                    docCount: 0,
                  }
                ).docCount,
              }))
              .filter(
                (productFormGroup) =>
                  productFormGroup.docCount !== 0 || arrFilters.includes(productFormGroup.code)
              )
          ).reverse()
        : [],
    [aggregations, arrFilters, productFormGroups]
  );

  useEffect(() => {
    if (isNewMount && facetItems.length) {
      let allFiltersShown = false;
      let shownItems = 0;
      do {
        shownItems = shownItems + MAX_ITEMS;
        const slicedFacetItems = facetItems.slice(0, shownItems).map((facetItem) => facetItem.code);
        allFiltersShown = difference(arrFilters, slicedFacetItems).length === 0;
      } while (!allFiltersShown);
      setNumItemsToShow(shownItems);
      setIsNewMount(false);
    }
  }, [facetItems, arrFilters, isNewMount]);

  if (!url || !productFormGroups || !aggregations) {
    return null;
  }

  return (
    <FacetWrapper title={t('facet_productFormGroup')} active={Boolean(arrFilters.join(''))}>
      {facetItems.slice(0, numItemsToShow).map((item) => (
        <div key={item.code}>
          <FormControlLabel
            control={
              <FacetCheckBox
                onChange={(e, checked) =>
                  onFilter(checked ? [...arrFilters, item.code] : without([item.code], arrFilters))
                }
                checked={arrFilters.includes(item.code)}
              />
            }
            label={`${item.label} (${item.docCount ?? 0})`}
          />
        </div>
      ))}
      {facetItems.length > MAX_ITEMS && (
        <>
          {numItemsToShow >= facetItems.length ? (
            <ShowMoreButton onClick={handleShowLess} invert />
          ) : (
            <ShowMoreButton onClick={handleShowMore} />
          )}
        </>
      )}
    </FacetWrapper>
  );
};

export default ProductFormGroupFacet;
