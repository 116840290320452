import { useFetch } from '@react-redux-fetch/hooks';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import RemoteAutocomplete from '../../../../../components/Form/RemoteAutocomplete';
import { Organisation } from '../../../../../config/api/types';
import useApiRoute from '../../../../../config/api/useApiRoute';
import { updateQueryParameters } from '../../../../../helpers/hateoas';
import { getOrganisationsSearchRequest } from '../../../../organisation/api';
import { getOrganisations } from '../../../../organisation/selectors';
import { ROLE } from '../../../../security/model';
import FacetTitle from '../../../catalog/components/Facet/FacetTitle';

type Props = {
  isDataConsumer?: boolean;
};

const DistributorFilter = ({ isDataConsumer }: Props) => {
  const { t } = useTranslation();
  const url = useApiRoute(isDataConsumer ? 'distributorsDataConsumer' : 'organisations') || '';
  const [promiseState, getDistributors] = useFetch(getOrganisationsSearchRequest);
  const distributors = useSelector(getOrganisations);

  const createUrl = useCallback(
    (term: string) => {
      return updateQueryParameters(url, { role: ROLE.DISTRIBUTOR, q: term });
    },
    [url]
  );

  if (!url) return null;

  return (
    <>
      <FacetTitle>{t('facet_distributor')}</FacetTitle>
      <RemoteAutocomplete
        label=" "
        blurInputOnSelect
        isClearable
        name="distributor"
        createUrlFromInput={createUrl}
        promiseState={promiseState}
        makeRequest={getDistributors}
        options={distributors?._embedded.items || []}
        getOptionValue={(distributor: Organisation) => distributor}
        getOptionLabel={(distributor: Organisation) => distributor.name}
        wideDropdown
      />
    </>
  );
};

export default DistributorFilter;
