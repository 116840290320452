const getIdFromUrl = (url?: string) => {
  if (!url) {
    return '';
  }

  const matches = url.match(/[0-9a-zA-Z]*-[0-9a-zA-Z]*-[0-9a-zA-Z]*-[0-9a-zA-Z]*-[0-9a-zA-Z]*/);
  if (!matches) {
    throw new Error(`Url ${url} doesn't contain an ID!`);
  }
  return matches[0];
};

export default getIdFromUrl;
