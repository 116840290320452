import React from 'react';
import { useTranslation } from 'react-i18next';
import BaseReactSelectField, {
  BaseReactSelectFieldProps,
} from '../../../../../components/Form/BaseReactSelectField';
import { Department, Departments, Maybe } from '../../../../../config/api/types';

type Props = Partial<BaseReactSelectFieldProps> & {
  departments: Maybe<Departments>;
};

const DepartmentField = ({ departments, isDisabled, ...other }: Props) => {
  const { t } = useTranslation();

  const getValue = (value: Department | string) => {
    if (!departments || typeof value !== 'string') {
      return value;
    }

    return departments.find((department) => department.departmentId === value);
  };

  return (
    <BaseReactSelectField
      label={t('form_department')}
      placeholder={t('form_department')}
      name="department_id"
      options={departments}
      getOptionValue={(option: Department) => option.departmentId}
      getOptionLabel={(option: Department) => option.title}
      isDisabled={isDisabled || !departments}
      parse={(value: Department | null) => value?.departmentId}
      format={getValue}
      blurInputOnSelect
      {...other}
    />
  );
};

export default DepartmentField;
