import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Theme,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useFetch } from '@react-redux-fetch/hooks';
import React, { useState } from 'react';
import { Form } from 'react-final-form';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Button from '../../../../../components/Button';
import Field from '../../../../../components/Form';
import SubTitle from '../../../../../components/SubTitle';
import { DistributorLine as DistributorLineModel } from '../../../../../config/api/models/shop';
import ROUTE_KEY from '../../../../../config/routes/routeKeys';
import usePath from '../../../../../config/routes/usePath';
import formatEuro from '../../../../../helpers/formatEuro';
import security from '../../../../security';
import { getConfirmOrderRequest } from '../../api';
import DepartmentAndBookstoreFields from './DepartmentAndBookstoreFields';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
}));

type Props = {
  distributorLines?: DistributorLineModel[];
  userLines?: Record<string, DistributorLineModel>;
  onClose: () => void;
  confirmationUrl?: string;
  redirectToTitles?: boolean;
  forLibrary?: boolean;
};

type FormValues = {
  orderReference?: string;
  bookstoreId?: string;
  departmentId?: string;
};

const distributorTranslations = {
  title: 'page_title_shopping_cart_confirmation',
  totalPrice: 'cart_total',
  placeOrder: 'cart_place_order',
  confirmedTitle: 'cart_order_confirmed_title',
  confirmedText: 'cart_order_confirmed_text',
  referenceHelperText: 'form_reference_help',
  changeOrder: 'cart_change_order',
};

const libraryTranslations = {
  title: 'page_title_library_shopping_cart_confirmation',
  totalPrice: 'cart_library_total',
  placeOrder: 'cart_place_library_order',
  confirmedTitle: 'cart_library_order_confirmed_title',
  confirmedText: 'cart_library_order_confirmed_text',
  referenceHelperText: 'form_library_reference_help',
  changeOrder: 'cart_change_library_order',
};

const mergeDistributorsCB = (lines?: DistributorLineModel[]) => {
  return lines?.reduce<DistributorLineModel[]>((acc, obj) => {
    if (obj.isCbMember) {
      // Find the merged object for `isCbMember` if it exists
      let mergedObj = acc.find(({ isCbMember }) => isCbMember);
      if (mergedObj) {
        // Add lines from the current object to the existing merged object's lines array
        mergedObj.lines = [...(mergedObj.lines || []), ...(obj.lines || [])];
      } else {
        // If no merged object exists, add a new merged object to the array
        acc.push({ ...obj, lines: obj.lines || [] });
      }
    } else {
      // Add objects with `isCbMember` set to false directly to the result array
      acc.push(obj);
    }
    return acc;
  }, []);
};

const Confirmation = ({
  distributorLines,
  userLines,
  onClose,
  confirmationUrl,
  redirectToTitles,
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const userRoles = useSelector(security.selectors.getUserRole);

  const titlesPath = usePath(ROUTE_KEY.TITLES);

  const [confirmOrderFetch, confirmOrder] = useFetch(getConfirmOrderRequest);

  const forLibrary = userRoles.includes('ROLE_LIBRARY');
  const translations = forLibrary ? libraryTranslations : distributorTranslations;

  const userLineValues = userLines ? Object.values(userLines) : undefined;

  const confirmDone = isSubmitting && confirmOrderFetch && confirmOrderFetch.fulfilled;
  const errorMessages =
    confirmOrderFetch?.rejected && confirmOrderFetch.reason && confirmOrderFetch.reason.cause;
  const referenceErrorMessage = (
    (errorMessages && errorMessages.find((m: any) => m.property_path === 'orderReference')) ||
    {}
  ).message;
  const disableSubmit = (values: FormValues) =>
    Boolean((confirmOrderFetch && confirmOrderFetch.pending) || (userLines && !values.bookstoreId));

  if (confirmDone) {
    return (
      <Dialog open fullWidth maxWidth="sm" onClose={onClose}>
        <DialogTitle style={{ textAlign: 'center' }}>{t(translations.confirmedTitle)}</DialogTitle>
        <DialogContent>
          <Typography align="center">
            <Trans i18nKey={translations.confirmedText} />
          </Typography>
        </DialogContent>
        <DialogActions>
          <Box textAlign="center" width="100%">
            <Button link={redirectToTitles ? titlesPath : undefined} onClick={onClose}>
              {t('cart_order_confirmed_return')}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    );
  }

  const orderTotal = distributorLines
    ? distributorLines.reduce((total, line) => total + line.resaleValue, 0)
    : Object.values(userLines || {}).reduce(
        (total, line) => total + line.lines!.reduce((lineSum, order) => lineSum + order.total, 0),
        0
      );

  return (
    <Dialog
      className={classes.root}
      open
      fullWidth
      maxWidth={forLibrary ? 'md' : 'sm'}
      onClose={onClose}
    >
      <Form
        onSubmit={(values) => {
          if (confirmationUrl) {
            confirmOrder(confirmationUrl, {
              ...values,
              orderReference: values.orderReference === '' ? undefined : values.orderReference,
            });
            setIsSubmitting(true);
          }
        }}
        initialValues={
          {
            orderReference: undefined,
            bookstoreId: undefined,
            departmentId: undefined,
          } as FormValues
        }
      >
        {({ handleSubmit, values }) => (
          <form onSubmit={handleSubmit}>
            <DialogTitle>{t(translations.title)}</DialogTitle>

            <DialogContent>
              {mergeDistributorsCB(distributorLines)?.map((distributorLine, index) => (
                <Box
                  marginBottom={index < distributorLines!.length - 1 ? 4 : 0}
                  key={distributorLine._embedded.organisation.name}
                >
                  <SubTitle>
                    {distributorLine.isCbMember ? (
                      <Trans i18nKey={'cart_order_at_cb_short'} />
                    ) : (
                      <>
                        {t('cart_order_at')}{' '}
                        <strong>{distributorLine._embedded.organisation.name}</strong>
                      </>
                    )}
                  </SubTitle>
                  <Divider />
                  {distributorLine.lines?.map((cartLine) => (
                    <React.Fragment key={cartLine.titleId}>
                      <Box paddingTop={0.5} paddingBottom={0.5}>
                        <Grid container>
                          <Grid item style={{ width: 140 }}>
                            {cartLine._embedded.title.gtin13}
                          </Grid>
                          <Grid item style={{ flex: 1 }}>
                            {cartLine._embedded.title.title}
                          </Grid>
                          <Grid item>{cartLine.quantity}</Grid>
                        </Grid>
                      </Box>
                      <Divider />
                    </React.Fragment>
                  ))}
                </Box>
              ))}

              {userLines &&
                Object.keys(userLines)?.map((key, index) => {
                  const userLine = userLines[key];
                  const user = userLine.lines ? userLine.lines[0]._embedded.login : undefined;
                  const userFullname = user ? [user?.firstName, user?.lastName].join(' ') : '';
                  return (
                    <Box marginBottom={index < userLineValues!.length - 1 ? 4 : 0} key={key}>
                      <SubTitle>
                        {t('added_by')} <strong>{userFullname}</strong>
                      </SubTitle>
                      <Divider />
                      {userLine.lines?.map((cartLine) => (
                        <React.Fragment key={cartLine.titleId + cartLine.userId}>
                          <Box paddingTop={0.5} paddingBottom={0.5}>
                            <Grid container>
                              <Grid item style={{ width: 140 }}>
                                {cartLine._embedded.title.gtin13}
                              </Grid>
                              <Grid item style={{ flex: 1 }}>
                                {cartLine._embedded.title.title}
                              </Grid>
                              <Grid item>{cartLine.quantity}</Grid>
                            </Grid>
                          </Box>
                          <Divider />
                        </React.Fragment>
                      ))}
                    </Box>
                  );
                })}
            </DialogContent>

            <DialogActions>
              <Box
                overflow="hidden"
                paddingTop={1}
                paddingBottom={1}
                paddingLeft={2}
                paddingRight={2}
                width="100%"
              >
                <Box marginBottom={2}>
                  <Typography variant="subtitle1">
                    {t(translations.totalPrice)} {formatEuro(orderTotal)}
                  </Typography>
                </Box>
                <Grid item container spacing={2} direction="column">
                  <Grid item container direction="row-reverse" spacing={1}>
                    {forLibrary && <DepartmentAndBookstoreFields />}
                    <Grid item xs={forLibrary ? 12 : 9}>
                      <Field
                        name="orderReference"
                        label={t('form_reference')}
                        helperText={t(translations.referenceHelperText)}
                        type="text"
                        customError={referenceErrorMessage}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    container
                    spacing={1}
                    sx={{ justifyContent: 'flex-end', alignItems: 'flex-end' }}
                  >
                    <Grid item>
                      <Button onClick={onClose} variant="outlined">
                        {t(translations.changeOrder)}
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button type="submit" disabled={disableSubmit(values)}>
                        {t(translations.placeOrder)}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </DialogActions>
          </form>
        )}
      </Form>
    </Dialog>
  );
};

export default Confirmation;
