import { Typography } from '@mui/material';
import React from 'react';
// import { makeStyles } from '@mui/styles';
//
// const useStyles = makeStyles((theme: Theme) => ({
//   root: {},
// }));

type Props = { children: string };

const ListHeading = ({ children }: Props) => {
  // const classes = useStyles();

  return (
    <Typography variant="subtitle2" gutterBottom>
      {children}
    </Typography>
  );
};

export default ListHeading;
