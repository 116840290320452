import React, { useEffect } from 'react';
import { SelectField as Select } from '../../../../../components/Form';
import { SelectFieldProps } from '../../../../../components/Form/SelectField';
import { useTranslation } from 'react-i18next';
import { useFetch } from '@react-redux-fetch/hooks';
import { getBillingTypesRequest } from '../../../api';
import { useSelector } from 'react-redux';
import { getBillingTypes } from '../../../selectors';

type Props = {
  onChange: SelectFieldProps['onChange'];
  initialValue?: SelectFieldProps['value'];
};

const BillingTypeFilter = ({ onChange, initialValue }: Props) => {
  const { t } = useTranslation();
  const [, fetchBillingTypes] = useFetch(getBillingTypesRequest);
  const billingTypes = useSelector(getBillingTypes);

  useEffect(fetchBillingTypes, [fetchBillingTypes]);

  const items = () => {
    const result: Record<string, string> = {};
    if (billingTypes) {
      billingTypes.forEach((type) => (result[type.code] = type.label));
    }
    return result;
  };

  if (!billingTypes) {
    return null;
  }

  return (
    <Select
      items={items()}
      onChange={onChange}
      label={t('filter_billingType')}
      value={initialValue}
    />
  );
};

export default BillingTypeFilter;
