import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PromiseState } from 'react-redux-fetch';
import Button from '../../../../../components/Button';
import Table from '../../../../../components/Table/index';
import { CellType, DataType } from '../../../../../components/Table/types';
import { TitleFiles } from '../../../../../config/api/types';
import getFilename from '../../../../../helpers/getFilename';
import getIdFromUrl from '../../../../../helpers/getIdFromUrl';
import { getLink } from '../../../../../helpers/hateoas';
import StatusCell from './StatusCell';

type Props = {
  titleFilesFetch: PromiseState<TitleFiles>;
  dispatchTitleFilesGet: (url?: string) => void;
  loading: boolean;
};

const TitleTable = ({ titleFilesFetch, dispatchTitleFilesGet, loading }: Props) => {
  const { t } = useTranslation();
  const pagedTitleFiles = titleFilesFetch.value;

  const headers = [
    { label: t('table_headers_filename') },
    { label: t('table_headers_through') },
    { label: t('table_headers_uploaded') },
    { label: t('table_headers_processed') },
    { label: t('table_headers_result') },
    { label: '' },
  ];

  if (!pagedTitleFiles) {
    return null;
  }

  const data: DataType = {
    resource: 'titleFiles',
    rows: pagedTitleFiles._embedded.items.map((titleFile) => ({
      id: getLink(titleFile, 'self') || titleFile.path,
      cells: [
        {
          type: CellType.Text,
          data: getFilename(titleFile.path),
        },
        {
          type: CellType.Text,
          data: titleFile.source,
        },
        {
          type: CellType.Text,
          data: titleFile.uploadedAt,
        },
        {
          type: CellType.Text,
          data: titleFile.processedAt,
        },
        {
          type: CellType.Text,
          data: (
            <StatusCell
              success={titleFile.numSuccess}
              total={titleFile.numTotal}
              status={titleFile.status}
            />
          ),
        },
        {
          type: CellType.Action,
          data: (
            <Button
              variant="text"
              secondary
              link={`/beheer-titels/titelbestand/${getIdFromUrl(getLink(titleFile, 'self') || '')}`}
              disabled={!Boolean(titleFile.processedAt)}
            >
              {t('table_btn_result')} <ChevronRightIcon />
            </Button>
          ),
        },
      ],
    })),
  };

  return (
    <Table
      data={data}
      headers={headers}
      limit={pagedTitleFiles.limit}
      page={pagedTitleFiles.page}
      pages={pagedTitleFiles.pages}
      total={pagedTitleFiles.total}
      dispatchFn={dispatchTitleFilesGet}
      pagedResource={pagedTitleFiles}
      loading={loading}
    />
  );
};

export default TitleTable;
