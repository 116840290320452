import React from 'react';
import { RouteComponentProps } from 'react-router';
import ConstrainedPageContent from '../../../../components/ConstrainedPageContent';
import Header from '../../../../components/Header';
import Detail from '../containers/Detail';

type Props = RouteComponentProps<{ id: string }, any, any>;

const DetailPage = ({ match, history }: Props) => {
  return (
    <ConstrainedPageContent>
      <Header goBack goBackPath={history.location.state && history.location.state.from} />
      <Detail id={match.params.id} />
    </ConstrainedPageContent>
  );
};

export default DetailPage;
