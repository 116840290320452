import memoizeOne from 'memoize-one';
import { RootState } from '../../config/store/types';
import { AuthState } from './model';
import { Maybe, User, UserRole } from '../../config/api/types';

export const getAuthResponseToken = (state: RootState): Maybe<string> => state.data.token;

export const getUser = (state: RootState): User | undefined =>
  state.security.impersonate || (state.repository.user && state.repository.user.value);

export const getUserRole = (state: RootState): UserRole[] =>
  state.security.impersonate?.roles || state.repository.user?.value?.roles || [];

export const getFirstUserRole = (state: RootState): UserRole | undefined =>
  getUser(state) && state.repository.user.value.roles[0];

export const isAuthenticated = (state: RootState) =>
  state.security.authState === AuthState.AUTHENTICATED;

export const getFullName = memoizeOne((state: RootState) => {
  const user = getUser(state);
  if (!user) {
    return '';
  }

  return `${user.firstName} ${user.lastName}`;
});

export const getJwtToken = (state: RootState) => state.security.token;

export const getImpersonate = (state: RootState) => state.security.impersonate;

export const getUserOrganisation = (state: RootState) => getUser(state)?._embedded?.organisation;

export const getUserOrganisationUrl = (state: RootState) =>
  // impersonate
  getUser(state)?._embedded?.organisation?._links?.self?.href ||
  // or user
  getUser(state)?._links?.organisation?.href;
