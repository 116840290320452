import { Add } from '@mui/icons-material';
import { useFetch } from '@react-redux-fetch/hooks';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Button from '../../../../../components/Button';
import { Library, Maybe } from '../../../../../config/api/types';
import { useAsyncValidation } from '../../../../../helpers/finalFormAsyncValidation';
import { getLink } from '../../../../../helpers/hateoas';
import { createDepartmentRequest, getDepartmentsRequest } from '../../../api';
import FormTitle from '../../components/FormTitle';
import DepartmentForm from '../../components/Library/DepartmentForm';
import DepartmentsList from '../../components/Library/DepartmentsList';
import { getDepartments } from '../../selectors';

type Props = {
  library: Maybe<Library>;
};

const Departments = ({ library }: Props) => {
  const { t } = useTranslation();
  const [showDeparmentForm, setShowDepartmentForm] = useState<boolean>(false);

  const departmentsApiUrl = library ? getLink(library, 'departments') : '';
  const createDepartmentApiUrl = library ? getLink(library, 'addDepartment') : '';

  const [createDepartmentResponse, createDepartment] = useFetch(createDepartmentRequest);
  const [, fetchDepartments] = useFetch(getDepartmentsRequest);
  const { createSubmissionPromise } = useAsyncValidation(createDepartmentResponse);

  const departments = useSelector(getDepartments);

  useEffect(() => {
    if (departmentsApiUrl) {
      fetchDepartments(departmentsApiUrl);
    }
  }, [departmentsApiUrl, fetchDepartments]);

  return (
    <>
      <FormTitle title={t('organisation_edit_library_departments_title')} />
      <DepartmentsList departments={departments} />

      {showDeparmentForm ? (
        <DepartmentForm
          onSubmit={(values) => {
            if (createDepartmentApiUrl) {
              createDepartment(createDepartmentApiUrl, values);
              return createSubmissionPromise();
            }
          }}
          onClose={() => setShowDepartmentForm(false)}
        />
      ) : (
        <Button variant="text" onClick={() => setShowDepartmentForm(true)}>
          <Add /> {t('form_department_create')}
        </Button>
      )}
    </>
  );
};

export default Departments;
