import CircularProgress from '@mui/material/CircularProgress/CircularProgress';
import Grid from '@mui/material/Grid/Grid';
import React, { FC, ReactNode, useEffect, useState } from 'react';

type Props = {
  data: any;
  delay?: number;
  children?: ReactNode;
};

const DelayedLoader: FC<Props> = ({ children, data, delay = 50 }) => {
  const [showLoading, setShowLoading] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setShowLoading(true), delay);

    return () => {
      clearTimeout(timer);
    };
  }, [delay]);

  if (!showLoading) {
    return null;
  }

  if (showLoading && !Boolean(data)) {
    return (
      <Grid container justifyContent="center" alignItems="center" style={{ marginTop: 20 }}>
        <CircularProgress />
      </Grid>
    );
  }

  return <>{children}</>;
};

export default DelayedLoader;
