import { useFetch } from '@react-redux-fetch/hooks';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import ROUTE_KEY from '../../../../config/routes/routeKeys';
import usePath from '../../../../config/routes/usePath';
import { updateQueryParameters } from '../../../../helpers/hateoas';
import { getOrdersLinesRequest } from '../../api';
import { getPagedOrderLines } from '../../selectors';

export const useOrderHistoryLinesFetch = (
  routeParams: Record<string, string | number> = {},
  requestKey: string
) => {
  const orderPath = usePath(ROUTE_KEY.ORDER_DETAIL);
  const orderBookstorePath = usePath(ROUTE_KEY.ORDER_DETAIL_BOOKSTORE);
  const pagedOrderLinesMap = useSelector(getPagedOrderLines);
  const pagedOrderLines = pagedOrderLinesMap?.[requestKey];
  const url = updateQueryParameters(
    pagedOrderLines?._links.self.href || '/elastic/orderlines',
    routeParams
  );

  const [orderLinesFetch, fetchOrderLines, cancelFetch] = useFetch(
    getOrdersLinesRequest(requestKey)
  );

  useEffect(() => {
    fetchOrderLines(url);
  }, [fetchOrderLines, url]);

  return {
    orderPath,
    orderBookstorePath,
    pagedOrderLines: url === pagedOrderLines?._links.self.href ? pagedOrderLines : undefined,
    orderLinesFetch,
    fetchOrderLines,
    cancelFetch,
  };
};
