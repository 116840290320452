import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { SelectFieldProps } from '../../../../../../components/Form/SelectField';
import { SelectField as Select } from '../../../../../../components/Form/index';

type Props = {
  onChange: SelectFieldProps['onChange'];
  initialValue?: SelectFieldProps['value'];
  value?: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  select: {
    // marginLeft: theme.spacing(2)
  },
}));

const RoleEnabledFilter = ({ onChange, value }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const items = {
    true: t('filter_option_active'),
    false: t('filter_option_inactive'),
  };
  return (
    <div className={classes.select}>
      <Select items={items} onChange={onChange} label={t('filter_role_enabled')} value={value} />
    </div>
  );
};

export default RoleEnabledFilter;
