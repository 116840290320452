import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useFetch } from '@react-redux-fetch/hooks';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Button from '../../../../components/Button';
import { SearchField } from '../../../../components/Form';
import { Organisation } from '../../../../config/api/types';
import useApiRoute from '../../../../config/api/useApiRoute';
import { updateQueryParameter } from '../../../../helpers/hateoas';
import { getOrganisationsSearchRequest } from '../../../organisation/api';
import { getOrganisations } from '../../../organisation/selectors';
import OrganisationMenuItem from './OrganisationMenuItem';

type Props = {
  onCancel: () => void;
  onSubmit: (organisation: Organisation) => void;
  publishersOnly?: boolean;
};

const useStyles = makeStyles(() => ({
  list: {
    height: 400,
    overflowY: 'auto',
  },
}));

const SelectNewOrganisation = ({ onCancel, onSubmit, publishersOnly = false }: Props) => {
  const { t } = useTranslation();
  const orgUrl = useApiRoute('organisations') || '';
  const publishersUrl = publishersOnly
    ? updateQueryParameter(orgUrl, 'role', 'ROLE_PUBLISHER')
    : null;
  const classes = useStyles();
  const [selectedOrg, setSelectedOrg] = useState<Organisation | undefined>(undefined);

  const [, fetchPublishers] = useFetch(getOrganisationsSearchRequest);
  const organisations = useSelector(getOrganisations);

  const handleSearch = (value: string, event?: React.ChangeEvent<any>) => {
    event?.preventDefault();
    const url = updateQueryParameter(publishersUrl ?? orgUrl, 'q', value);
    fetchPublishers(url);
  };

  useEffect(() => {
    fetchPublishers(publishersUrl ?? orgUrl);
  }, [fetchPublishers, publishersUrl, orgUrl]);

  return (
    <Grid container direction="column" spacing={1}>
      <Grid item xs={12}>
        <SearchField
          placeholder={t('form_placeholder_organisation_search')}
          searchCallback={handleSearch}
          isClearable
        />
        <div className={classes.list}>
          {organisations &&
            organisations._embedded.items.map((org) => (
              <OrganisationMenuItem
                organisation={org}
                selected={org._links.self.href === selectedOrg?._links.self.href}
                onSelect={setSelectedOrg}
                key={org.name}
              />
            ))}
        </div>
      </Grid>
      <Grid item container spacing={1} sx={{ justifyContent: 'flex-end' }}>
        <Grid item>
          <Button onClick={onCancel} variant="text">
            {t('form_cancel')}
          </Button>
        </Grid>
        <Grid item>
          <Button onClick={() => selectedOrg && onSubmit(selectedOrg)} disabled={!selectedOrg}>
            {t('form_action_save')}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SelectNewOrganisation;
