import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {
  ClickAwayListener,
  Grow,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography,
  styled,
} from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { EXTERNAL_LINKS } from '../../../config/data/external_links';
import ROUTE_KEY from '../../../config/routes/routeKeys';
import usePath from '../../../config/routes/usePath';

const Icon = styled(Typography)({
  fontSize: '1.4rem',
  width: 32,
  height: 32,
  fontWeight: 500,
});

export const HelpMenuItem = () => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const contactPath = usePath(ROUTE_KEY.CONTACT);
  const faqPath = usePath(ROUTE_KEY.FAQ);

  return (
    <>
      <IconButton color="inherit" size="small" onClick={handleClick}>
        <Icon>?</Icon>
      </IconButton>

      {anchorEl && (
        <Popper
          open={true}
          anchorEl={anchorEl}
          role={undefined}
          transition
          disablePortal
          placement="bottom-end"
        >
          {({ TransitionProps, placement }) => (
            <Grow {...TransitionProps}>
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList>
                    <MenuItem component={Link} to={faqPath} onClick={handleClose}>
                      {t('nav_help_faq')}
                    </MenuItem>
                    <MenuItem
                      component="a"
                      href={EXTERNAL_LINKS['documentation']}
                      target="_blank"
                      rel="noreferrer"
                      onClick={handleClose}
                    >
                      <ListItemText>{t('nav_help_guide')}</ListItemText>
                      <ListItemIcon style={{ justifyContent: 'flex-end' }}>
                        <OpenInNewIcon fontSize="small" style={{ verticalAlign: 'middle' }} />
                      </ListItemIcon>
                    </MenuItem>
                    <MenuItem component={Link} to={contactPath} onClick={handleClose}>
                      {t('nav_help_contact')}
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      )}
    </>
  );
};
