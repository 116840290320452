import memoizeOne from 'memoize-one';

const allCountries = [
  {
    name: 'Afghanistan',
    short_name: 'AF',
    calling_code: '93',
  },
  {
    name: 'Åland',
    short_name: 'AX',
    calling_code: '358',
  },
  {
    name: 'Albanië',
    short_name: 'AL',
    calling_code: '355',
  },
  {
    name: 'Algerije',
    short_name: 'DZ',
    calling_code: '213',
  },
  {
    name: 'Amerikaanse Maagdeneilanden',
    short_name: 'VI',
    calling_code: '1-340',
  },
  {
    name: 'Amerikaans-Samoa',
    short_name: 'AS',
    calling_code: '1-684',
  },
  {
    name: 'Andorra',
    short_name: 'AD',
    calling_code: '376',
  },
  {
    name: 'Angola',
    short_name: 'AO',
    calling_code: '244',
  },
  {
    name: 'Anguilla',
    short_name: 'AI',
    calling_code: '1-264',
  },
  {
    name: 'Antarctica',
    short_name: 'AQ',
    calling_code: '672',
  },
  {
    name: 'Antigua en Barbuda',
    short_name: 'AG',
    calling_code: '1-268',
  },
  {
    name: 'Argentinië',
    short_name: 'AR',
    calling_code: '54',
  },
  {
    name: 'Armenië',
    short_name: 'AM',
    calling_code: '374',
  },
  {
    name: 'Aruba',
    short_name: 'AW',
    calling_code: '297',
  },
  {
    name: 'Australië',
    short_name: 'AU',
    calling_code: '61',
  },
  {
    name: 'Azerbeidzjan',
    short_name: 'AZ',
    calling_code: '994',
  },
  {
    name: "Bahama's",
    short_name: 'BS',
    calling_code: '1-242',
  },
  {
    name: 'Bahrein',
    short_name: 'BH',
    calling_code: '973',
  },
  {
    name: 'Bangladesh',
    short_name: 'BD',
    calling_code: '880',
  },
  {
    name: 'Barbados',
    short_name: 'BB',
    calling_code: '1-246',
  },
  {
    name: 'België',
    short_name: 'BE',
    calling_code: '32',
  },
  {
    name: 'Belize',
    short_name: 'BZ',
    calling_code: '501',
  },
  {
    name: 'Benin',
    short_name: 'BJ',
    calling_code: '229',
  },
  {
    name: 'Bermuda',
    short_name: 'BM',
    calling_code: '1-441',
  },
  {
    name: 'Bhutan',
    short_name: 'BT',
    calling_code: '975',
  },
  {
    name: 'Bolivia',
    short_name: 'BO',
    calling_code: '591',
  },
  {
    name: 'Bonaire, Sint Eustatius en Saba',
    short_name: 'BQ',
    calling_code: '599',
  },
  {
    name: 'Bosnië en Herzegovina',
    short_name: 'BA',
    calling_code: '387',
  },
  {
    name: 'Botswana',
    short_name: 'BW',
    calling_code: '267',
  },
  {
    name: 'Bouvet',
    short_name: 'BV',
    calling_code: '47',
  },
  {
    name: 'Brazilië',
    short_name: 'BR',
    calling_code: '55',
  },
  {
    name: 'Britse Maagdeneilanden',
    short_name: 'VG',
    calling_code: '1-284',
  },
  {
    name: 'Brits Indische Oceaanterritorium',
    short_name: 'IO',
    calling_code: '246',
  },
  {
    name: 'Brunei',
    short_name: 'BN',
    calling_code: '673',
  },
  {
    name: 'Bulgarije',
    short_name: 'BG',
    calling_code: '359',
  },
  {
    name: 'Burkina Faso',
    short_name: 'BF',
    calling_code: '226',
  },
  {
    name: 'Burundi',
    short_name: 'BI',
    calling_code: '257',
  },
  {
    name: 'Cambodja',
    short_name: 'KH',
    calling_code: '855',
  },
  {
    name: 'Canada',
    short_name: 'CA',
    calling_code: '1',
  },
  {
    name: 'Centraal-Afrikaanse Republiek',
    short_name: 'CF',
    calling_code: '236',
  },
  {
    name: 'Chili',
    short_name: 'CL',
    calling_code: '56',
  },
  {
    name: 'China',
    short_name: 'CN',
    calling_code: '86',
  },
  {
    name: 'Christmaseiland',
    short_name: 'CX',
    calling_code: '61',
  },
  {
    name: 'Cocoseilanden',
    short_name: 'CC',
    calling_code: '61',
  },
  {
    name: 'Colombia',
    short_name: 'CO',
    calling_code: '57',
  },
  {
    name: 'Comoren',
    short_name: 'KM',
    calling_code: '269',
  },
  {
    name: 'Congo-Brazzaville',
    short_name: 'CG',
    calling_code: '242',
  },
  {
    name: 'Congo-Kinshasa',
    short_name: 'CD',
    calling_code: '243',
  },
  {
    name: 'Cookeilanden',
    short_name: 'CK',
    calling_code: '682',
  },
  {
    name: 'Costa Rica',
    short_name: 'CR',
    calling_code: '506',
  },
  {
    name: 'Cuba',
    short_name: 'CU',
    calling_code: '53',
  },
  {
    name: 'Curaçao',
    short_name: 'CW',
    calling_code: '599',
  },
  {
    name: 'Cyprus',
    short_name: 'CY',
    calling_code: '357',
  },
  {
    name: 'Denemarken',
    short_name: 'DK',
    calling_code: '45',
  },
  {
    name: 'Djibouti',
    short_name: 'DJ',
    calling_code: '253',
  },
  {
    name: 'Dominica',
    short_name: 'DM',
    calling_code: '1-767',
  },
  {
    name: 'Dominicaanse Republiek',
    short_name: 'DO',
    calling_code: '1-809,1-829,1-849',
  },
  {
    name: 'Duitsland',
    short_name: 'DE',
    calling_code: '49',
  },
  {
    name: 'Ecuador',
    short_name: 'EC',
    calling_code: '593',
  },
  {
    name: 'Egypte',
    short_name: 'EG',
    calling_code: '20',
  },
  {
    name: 'El Salvador',
    short_name: 'SV',
    calling_code: '503',
  },
  {
    name: 'Equatoriaal-Guinea',
    short_name: 'GQ',
    calling_code: '240',
  },
  {
    name: 'Eritrea',
    short_name: 'ER',
    calling_code: '291',
  },
  {
    name: 'Estland',
    short_name: 'EE',
    calling_code: '372',
  },
  {
    name: 'Ethiopië',
    short_name: 'ET',
    calling_code: '251',
  },
  {
    name: 'Faeröer',
    short_name: 'FO',
    calling_code: '298',
  },
  {
    name: 'Falklandeilanden',
    short_name: 'FK',
    calling_code: '500',
  },
  {
    name: 'Fiji',
    short_name: 'FJ',
    calling_code: '679',
  },
  {
    name: 'Filipijnen',
    short_name: 'PH',
    calling_code: '63',
  },
  {
    name: 'Finland',
    short_name: 'FI',
    calling_code: '358',
  },
  {
    name: 'Frankrijk',
    short_name: 'FR',
    calling_code: '33',
  },
  {
    name: 'Franse Zuidelijke en Antarctische Gebiede',
    short_name: 'TF',
    calling_code: '262',
  },
  {
    name: 'Frans-Guyana',
    short_name: 'GF',
    calling_code: '594',
  },
  {
    name: 'Frans-Polynesië',
    short_name: 'PF',
    calling_code: '689',
  },
  {
    name: 'Gabon',
    short_name: 'GA',
    calling_code: '241',
  },
  {
    name: 'Gambia',
    short_name: 'GM',
    calling_code: '220',
  },
  {
    name: 'Georgië',
    short_name: 'GE',
    calling_code: '995',
  },
  {
    name: 'Ghana',
    short_name: 'GH',
    calling_code: '233',
  },
  {
    name: 'Gibraltar',
    short_name: 'GI',
    calling_code: '350',
  },
  {
    name: 'Grenada',
    short_name: 'GD',
    calling_code: '1-473',
  },
  {
    name: 'Griekenland',
    short_name: 'GR',
    calling_code: '30',
  },
  {
    name: 'Groenland',
    short_name: 'GL',
    calling_code: '299',
  },
  {
    name: 'Guadeloupe',
    short_name: 'GP',
    calling_code: '590',
  },
  {
    name: 'Guam',
    short_name: 'GU',
    calling_code: '1-671',
  },
  {
    name: 'Guatemala',
    short_name: 'GT',
    calling_code: '502',
  },
  {
    name: 'Guernsey',
    short_name: 'GG',
    calling_code: '44',
  },
  {
    name: 'Guinee',
    short_name: 'GN',
    calling_code: '224',
  },
  {
    name: 'Guinee-Bissau',
    short_name: 'GW',
    calling_code: '245',
  },
  {
    name: 'Guyana',
    short_name: 'GY',
    calling_code: '592',
  },
  {
    name: 'Haïti',
    short_name: 'HT',
    calling_code: '509',
  },
  {
    name: 'Heard en McDonaldeilanden',
    short_name: 'HM',
    calling_code: '672',
  },
  {
    name: 'Honduras',
    short_name: 'HN',
    calling_code: '504',
  },
  {
    name: 'Hongarije',
    short_name: 'HU',
    calling_code: '36',
  },
  {
    name: 'Hongkong',
    short_name: 'HK',
    calling_code: '852',
  },
  {
    name: 'Ierland',
    short_name: 'IE',
    calling_code: '353',
  },
  {
    name: 'IJsland',
    short_name: 'IS',
    calling_code: '354',
  },
  {
    name: 'India',
    short_name: 'IN',
    calling_code: '91',
  },
  {
    name: 'Indonesië',
    short_name: 'ID',
    calling_code: '62',
  },
  {
    name: 'Irak',
    short_name: 'IQ',
    calling_code: '964',
  },
  {
    name: 'Iran',
    short_name: 'IR',
    calling_code: '98',
  },
  {
    name: 'Israël',
    short_name: 'IL',
    calling_code: '972',
  },
  {
    name: 'Italië',
    short_name: 'IT',
    calling_code: '39',
  },
  {
    name: 'Ivoorkust',
    short_name: 'CI',
    calling_code: '225',
  },
  {
    name: 'Jamaica',
    short_name: 'JM',
    calling_code: '1-876',
  },
  {
    name: 'Japan',
    short_name: 'JP',
    calling_code: '81',
  },
  {
    name: 'Jemen',
    short_name: 'YE',
    calling_code: '967',
  },
  {
    name: 'Jersey',
    short_name: 'JE',
    calling_code: '44',
  },
  {
    name: 'Jordanië',
    short_name: 'JO',
    calling_code: '962',
  },
  {
    name: 'Kaaimaneilanden',
    short_name: 'KY',
    calling_code: '1-345',
  },
  {
    name: 'Kaapverdië',
    short_name: 'CV',
    calling_code: '238',
  },
  {
    name: 'Kameroen',
    short_name: 'CM',
    calling_code: '237',
  },
  {
    name: 'Kazachstan',
    short_name: 'KZ',
    calling_code: '7',
  },
  {
    name: 'Kenia',
    short_name: 'KE',
    calling_code: '254',
  },
  {
    name: 'Kirgizië',
    short_name: 'KG',
    calling_code: '996',
  },
  {
    name: 'Kiribati',
    short_name: 'KI',
    calling_code: '868',
  },
  {
    name: 'Kleine afgelegen eilanden van de Verenigde Staten',
    short_name: 'UM',
    calling_code: '1',
  },
  {
    name: 'Koeweit',
    short_name: 'KW',
    calling_code: '965',
  },
  {
    name: 'Kosovo',
    short_name: 'XK',
    calling_code: '383',
  },
  {
    name: 'Kroatië',
    short_name: 'HR',
    calling_code: '385',
  },
  {
    name: 'Laos',
    short_name: 'LA',
    calling_code: '856',
  },
  {
    name: 'Lesotho',
    short_name: 'LS',
    calling_code: '266',
  },
  {
    name: 'Letland',
    short_name: 'LV',
    calling_code: '371',
  },
  {
    name: 'Libanon',
    short_name: 'LB',
    calling_code: '961',
  },
  {
    name: 'Liberia',
    short_name: 'LR',
    calling_code: '231',
  },
  {
    name: 'Libië',
    short_name: 'LY',
    calling_code: '218',
  },
  {
    name: 'Liechtenstein',
    short_name: 'LI',
    calling_code: '423',
  },
  {
    name: 'Litouwen',
    short_name: 'LT',
    calling_code: '370',
  },
  {
    name: 'Luxemburg',
    short_name: 'LU',
    calling_code: '352',
  },
  {
    name: 'Macau',
    short_name: 'MO',
    calling_code: '853',
  },
  {
    name: 'Macedonië',
    short_name: 'MK',
    calling_code: '389',
  },
  {
    name: 'Madagaskar',
    short_name: 'MG',
    calling_code: '261',
  },
  {
    name: 'Malawi',
    short_name: 'MW',
    calling_code: '265',
  },
  {
    name: 'Maldiven',
    short_name: 'MV',
    calling_code: '960',
  },
  {
    name: 'Maleisië',
    short_name: 'MY',
    calling_code: '60',
  },
  {
    name: 'Mali',
    short_name: 'ML',
    calling_code: '223',
  },
  {
    name: 'Malta',
    short_name: 'MT',
    calling_code: '356',
  },
  {
    name: 'Man',
    short_name: 'IM',
    calling_code: '44',
  },
  {
    name: 'Marokko',
    short_name: 'MA',
    calling_code: '212',
  },
  {
    name: 'Marshalleilanden',
    short_name: 'MH',
    calling_code: '692',
  },
  {
    name: 'Martinique',
    short_name: 'MQ',
    calling_code: '596',
  },
  {
    name: 'Mauritanië',
    short_name: 'MR',
    calling_code: '222',
  },
  {
    name: 'Mauritius',
    short_name: 'MU',
    calling_code: '230',
  },
  {
    name: 'Mayotte',
    short_name: 'YT',
    calling_code: '269',
  },
  {
    name: 'Mexico',
    short_name: 'MX',
    calling_code: '52',
  },
  {
    name: 'Micronesia',
    short_name: 'FM',
    calling_code: '691',
  },
  {
    name: 'Moldavië',
    short_name: 'MD',
    calling_code: '373',
  },
  {
    name: 'Monaco',
    short_name: 'MC',
    calling_code: '377',
  },
  {
    name: 'Mongolië',
    short_name: 'MN',
    calling_code: '976',
  },
  {
    name: 'Montenegro',
    short_name: 'ME',
    calling_code: '382',
  },
  {
    name: 'Montserrat',
    short_name: 'MS',
    calling_code: '1-164',
  },
  {
    name: 'Mozambique',
    short_name: 'MZ',
    calling_code: '258',
  },
  {
    name: 'Myanmar',
    short_name: 'MM',
    calling_code: '95',
  },
  {
    name: 'Namibië',
    short_name: 'NA',
    calling_code: '264',
  },
  {
    name: 'Nauru',
    short_name: 'NR',
    calling_code: '674',
  },
  {
    name: 'Nederland',
    short_name: 'NL',
    calling_code: '31',
  },
  {
    name: 'Nepal',
    short_name: 'NP',
    calling_code: '977',
  },
  {
    name: 'Nicaragua',
    short_name: 'NI',
    calling_code: '505',
  },
  {
    name: 'Nieuw-Caledonië',
    short_name: 'NC',
    calling_code: '687',
  },
  {
    name: 'Nieuw-Zeeland',
    short_name: 'NZ',
    calling_code: '64',
  },
  {
    name: 'Niger',
    short_name: 'NE',
    calling_code: '227',
  },
  {
    name: 'Nigeria',
    short_name: 'NG',
    calling_code: '234',
  },
  {
    name: 'Niue',
    short_name: 'NU',
    calling_code: '683',
  },
  {
    name: 'Noordelijke Marianen',
    short_name: 'MP',
    calling_code: '1-670',
  },
  {
    name: 'Noord-Korea',
    short_name: 'KP',
    calling_code: '850',
  },
  {
    name: 'Noorwegen',
    short_name: 'NO',
    calling_code: '47',
  },
  {
    name: 'Norfolk',
    short_name: 'NF',
    calling_code: '672',
  },
  {
    name: 'Oeganda',
    short_name: 'UG',
    calling_code: '256',
  },
  {
    name: 'Oekraïne',
    short_name: 'UA',
    calling_code: '380',
  },
  {
    name: 'Oezbekistan',
    short_name: 'UZ',
    calling_code: '998',
  },
  {
    name: 'Oman',
    short_name: 'OM',
    calling_code: '968',
  },
  {
    name: 'Oostenrijk',
    short_name: 'AT',
    calling_code: '43',
  },
  {
    name: 'Oost-Timor',
    short_name: 'TL',
    calling_code: '670',
  },
  {
    name: 'Pakistan',
    short_name: 'PK',
    calling_code: '92',
  },
  {
    name: 'Palau',
    short_name: 'PW',
    calling_code: '680',
  },
  {
    name: 'Palestina',
    short_name: 'PS',
    calling_code: '970',
  },
  {
    name: 'Panama',
    short_name: 'PA',
    calling_code: '507',
  },
  {
    name: 'Papua-Nieuw-Guinea',
    short_name: 'PG',
    calling_code: '675',
  },
  {
    name: 'Paraguay',
    short_name: 'PY',
    calling_code: '595',
  },
  {
    name: 'Peru',
    short_name: 'PE',
    calling_code: '51',
  },
  {
    name: 'Pitcairneilanden',
    short_name: 'PN',
    calling_code: '649',
  },
  {
    name: 'Polen',
    short_name: 'PL',
    calling_code: '48',
  },
  {
    name: 'Portugal',
    short_name: 'PT',
    calling_code: '351',
  },
  {
    name: 'Puerto Rico',
    short_name: 'PR',
    calling_code: '1-787,1-939',
  },
  {
    name: 'Qatar',
    short_name: 'QA',
    calling_code: '974',
  },
  {
    name: 'Réunion',
    short_name: 'RE',
    calling_code: '262',
  },
  {
    name: 'Roemenië',
    short_name: 'RO',
    calling_code: '40',
  },
  {
    name: 'Rusland',
    short_name: 'RU',
    calling_code: '7',
  },
  {
    name: 'Rwanda',
    short_name: 'RW',
    calling_code: '250',
  },
  {
    name: 'Saint-Barthélemy',
    short_name: 'BL',
    calling_code: '590',
  },
  {
    name: 'Saint Kitts en Nevis',
    short_name: 'KN',
    calling_code: '1-869',
  },
  {
    name: 'Saint Lucia',
    short_name: 'LC',
    calling_code: '1-758',
  },
  {
    name: 'Saint-Pierre en Miquelon',
    short_name: 'PM',
    calling_code: '508',
  },
  {
    name: 'Saint Vincent en de Grenadines',
    short_name: 'VC',
    calling_code: '1-784',
  },
  {
    name: 'Salomonseilanden',
    short_name: 'SB',
    calling_code: '677',
  },
  {
    name: 'Samoa',
    short_name: 'WS',
    calling_code: '685',
  },
  {
    name: 'San Marino',
    short_name: 'SM',
    calling_code: '378',
  },
  {
    name: 'Saoedi-Arabië',
    short_name: 'SA',
    calling_code: '966',
  },
  {
    name: 'Sao Tomé en Principe',
    short_name: 'ST',
    calling_code: '239',
  },
  {
    name: 'Senegal',
    short_name: 'SN',
    calling_code: '221',
  },
  {
    name: 'Servië',
    short_name: 'RS',
    calling_code: '381',
  },
  {
    name: 'Seychellen',
    short_name: 'SC',
    calling_code: '248',
  },
  {
    name: 'Sierra Leone',
    short_name: 'SL',
    calling_code: '232',
  },
  {
    name: 'Singapore',
    short_name: 'SG',
    calling_code: '65',
  },
  {
    name: 'Sint-Helena, Ascension en Tristan da Cunha',
    short_name: 'SH',
    calling_code: '290',
  },
  {
    name: 'Sint-Maarten',
    short_name: 'MF',
    calling_code: '590',
  },
  {
    name: 'Sint Maarten',
    short_name: 'SX',
    calling_code: '1-721',
  },
  {
    name: 'Slovenië',
    short_name: 'SI',
    calling_code: '386',
  },
  {
    name: 'Slowakije',
    short_name: 'SK',
    calling_code: '421',
  },
  {
    name: 'Soedan',
    short_name: 'SD',
    calling_code: '249',
  },
  {
    name: 'Somalië',
    short_name: 'SO',
    calling_code: '252',
  },
  {
    name: 'Spanje',
    short_name: 'ES',
    calling_code: '34',
  },
  {
    name: 'Spitsbergen en Jan Mayen',
    short_name: 'SJ',
    calling_code: '47',
  },
  {
    name: 'Sri Lanka',
    short_name: 'LK',
    calling_code: '94',
  },
  {
    name: 'Suriname',
    short_name: 'SR',
    calling_code: '597',
  },
  {
    name: 'Swaziland',
    short_name: 'SZ',
    calling_code: '268',
  },
  {
    name: 'Syrië',
    short_name: 'SY',
    calling_code: '963',
  },
  {
    name: 'Tadzjikistan',
    short_name: 'TJ',
    calling_code: '992',
  },
  {
    name: 'Taiwan',
    short_name: 'TW',
    calling_code: '886',
  },
  {
    name: 'Tanzania',
    short_name: 'TZ',
    calling_code: '255',
  },
  {
    name: 'Thailand',
    short_name: 'TH',
    calling_code: '66',
  },
  {
    name: 'Togo',
    short_name: 'TG',
    calling_code: '288',
  },
  {
    name: 'Tokelau',
    short_name: 'TK',
    calling_code: '690',
  },
  {
    name: 'Tonga',
    short_name: 'TO',
    calling_code: '676',
  },
  {
    name: 'Trinidad en Tobago',
    short_name: 'TT',
    calling_code: '1-868',
  },
  {
    name: 'Tsjaad',
    short_name: 'TD',
    calling_code: '235',
  },
  {
    name: 'Tsjechië',
    short_name: 'CZ',
    calling_code: '420',
  },
  {
    name: 'Tunesië',
    short_name: 'TN',
    calling_code: '216',
  },
  {
    name: 'Turkije',
    short_name: 'TR',
    calling_code: '90',
  },
  {
    name: 'Turkmenistan',
    short_name: 'TM',
    calling_code: '993',
  },
  {
    name: 'Turks- en Caicoseilanden',
    short_name: 'TC',
    calling_code: '1-649',
  },
  {
    name: 'Tuvalu',
    short_name: 'TV',
    calling_code: '688',
  },
  {
    name: 'Uruguay',
    short_name: 'UY',
    calling_code: '598',
  },
  {
    name: 'Vanuatu',
    short_name: 'VU',
    calling_code: '678',
  },
  {
    name: 'Vaticaanstad',
    short_name: 'VA',
    calling_code: '379',
  },
  {
    name: 'Venezuela',
    short_name: 'VE',
    calling_code: '58',
  },
  {
    name: 'Verenigde Arabische Emiraten',
    short_name: 'AE',
    calling_code: '971',
  },
  {
    name: 'Verenigde Staten',
    short_name: 'US',
    calling_code: '1',
  },
  {
    name: 'Verenigd Koninkrijk',
    short_name: 'GB',
    calling_code: '44',
  },
  {
    name: 'Vietnam',
    short_name: 'VN',
    calling_code: '84',
  },
  {
    name: 'Wallis en Futuna',
    short_name: 'WF',
    calling_code: '681',
  },
  {
    name: 'Westelijke Sahara',
    short_name: 'EH',
    calling_code: '212',
  },
  {
    name: 'Wit-Rusland',
    short_name: 'BY',
    calling_code: '375',
  },
  {
    name: 'Zambia',
    short_name: 'ZM',
    calling_code: '260',
  },
  {
    name: 'Zimbabwe',
    short_name: 'ZW',
    calling_code: '263',
  },
  {
    name: 'Zuid-Afrika',
    short_name: 'ZA',
    calling_code: '27',
  },
  {
    name: 'Zuid-Georgia en de Zuidelijke Sandwicheilanden',
    short_name: 'GS',
    calling_code: '500',
  },
  {
    name: 'Zuid-Korea',
    short_name: 'KR',
    calling_code: '82',
  },
  {
    name: 'Zuid-Soedan',
    short_name: 'SS',
    calling_code: '211',
  },
  {
    name: 'Zweden',
    short_name: 'SE',
    calling_code: '46',
  },
  {
    name: 'Zwitserland',
    short_name: 'CH',
    calling_code: '41',
  },
];

export const getCountryNameByShortName = memoizeOne((shortName: string) => {
  const country = allCountries.find((c) => c.short_name === shortName);
  return country ? country.name : '';
});

export default allCountries;

export type countryType = {
  name: string;
  short_name: string;
  calling_code: string;
};
