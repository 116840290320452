import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { Grid } from '@mui/material';
import { XYCoord } from 'dnd-core';
import React, { FC, useRef, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { Contributor } from '../../../../../../../config/api/types';
import EditContributorField from './EditContributorField';

type Props = {
  contributor: Contributor;
  onMove: (dragIndex: number, hoverIndex: number) => void;
  onRemove: (index: number) => void;
  onToggleContributor: (index: number, isCorporate: boolean) => void;
  index: number;
  isBibliographicalVerified: boolean;
};
interface DragItem {
  index: number;
  id: string;
  type: string;
}
const DraggableContributorField: FC<Props> = ({
  contributor,
  onRemove,
  onMove,
  index,
  onToggleContributor,
  isBibliographicalVerified,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  const [, drop] = useDrop<DragItem>({
    accept: 'contributor',
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      onMove(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [isEditing, setIsEditing] = useState(!Boolean(contributor.titleId));

  const [{ isDragging }, drag] = useDrag({
    type: 'contributor',
    item: { type: 'contributor', id: contributor.titleContributorId, index },
    canDrag: !isEditing,
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0.2 : 1;
  drag(drop(ref));

  return (
    <Grid
      container
      ref={isBibliographicalVerified ? undefined : ref}
      style={{ opacity }}
      spacing={1}
      alignContent="center"
    >
      {!isBibliographicalVerified && (
        <Grid item ref={drag} style={{ cursor: isDragging ? 'grabbing' : 'grab' }}>
          <DragIndicatorIcon fontSize="small" color={isEditing ? 'disabled' : 'action'} />
        </Grid>
      )}
      <Grid item>
        <EditContributorField
          onRemove={() => onRemove(index)}
          onToggleContributor={(isCorporate) => onToggleContributor(index, isCorporate)}
          contributor={contributor}
          name={`contributors[${index}]`}
          index={index}
          isBibliographicalVerified={isBibliographicalVerified}
          isEditing={isEditing}
          setIsEditing={setIsEditing}
        />
      </Grid>
    </Grid>
  );
};

export default DraggableContributorField;
