import { Add as AddIcon } from '@mui/icons-material';
import InfoIcon from '@mui/icons-material/Info';
import { Box, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import Button, { ButtonProps } from '../../../../components/Button';
import { Title } from '../../../../config/api/types';
import ROUTE_KEY from '../../../../config/routes/routeKeys';
import usePath from '../../../../config/routes/usePath';
import getIdFromUrl from '../../../../helpers/getIdFromUrl';
import { getLink } from '../../../../helpers/hateoas';

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    marginLeft: theme.spacing(1),
  },
}));

type Props = Partial<Omit<ButtonProps, 'title'>> & {
  title: Title;
  redirectPath?: string;
};

const CopyButton: FC<Props> = ({ title, redirectPath, ...btnProps }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { push } = useHistory();

  const pathname = usePath(ROUTE_KEY.MANAGE_TITLE_ADD_NEW);

  const handleClick = () => {
    push(pathname, {
      path: redirectPath,
      copyFromId: getIdFromUrl(getLink(title, 'self') || ''),
    });
  };

  return (
    <Box
      flexDirection={'column'}
      display="inline-flex"
      alignItems={'flex-start'}
      style={{ gap: '4px' }}
    >
      <Button onClick={handleClick} size="small" {...btnProps}>
        {t('title_copy_btn')} <AddIcon className={classes.icon} fontSize="small" />
      </Button>
      <Box display={'flex'} alignItems={'flex-start'} style={{ gap: '2px' }}>
        <InfoIcon style={{ fontSize: '15px' }} />
        <Typography
          style={{ maxWidth: '210px', fontSize: '12px', whiteSpace: 'normal', lineHeight: 1.2 }}
        >
          {t('title_copy_btn_info')}
        </Typography>
      </Box>
    </Box>
  );
};

export default CopyButton;
