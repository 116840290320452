import React from 'react';
import { Form } from 'react-final-form';
import { PromiseState, ReduxFetch } from 'react-redux-fetch';
import { Organisation } from '../../../../../config/api/types';
import createFinalFormAsyncValidation from '../../../../../helpers/finalFormAsyncValidation';
import { getLink } from '../../../../../helpers/hateoas';
import { ApiConfig } from '../../../api';
import { FtpAccount } from '../../components/Users/index';

type Props = {
  organisation: Organisation;
};

type ReduxFetchRenderProps = {
  organisationStatusFetch: PromiseState;
  dispatchOrganisationStatusPut: (url?: string) => void;
};

const FTPAccounts = ({ organisation }: Props) => {
  const organisationFTPLink = getLink(organisation, 'ftp');

  const { createSubmissionPromise, handleFulfil, handleReject } =
    createFinalFormAsyncValidation('organisationStatusFetch');

  return (
    <>
      {organisationFTPLink && (
        <ReduxFetch
          config={[ApiConfig.updateOrganisationStatus(organisationFTPLink, true)]}
          onFulfil={handleFulfil}
          onReject={handleReject}
        >
          {({ dispatchOrganisationStatusPut, organisationStatusFetch }: ReduxFetchRenderProps) => {
            const ftpData =
              organisationStatusFetch.value?.account === organisation?.ftpLogin
                ? organisationStatusFetch.value
                : { account: organisation?.ftpLogin, password: '' };
            return (
              <Form
                onSubmit={() => {
                  dispatchOrganisationStatusPut();
                  return createSubmissionPromise();
                }}
              >
                {({ handleSubmit, submitting }) => (
                  <form>
                    <FtpAccount
                      handleSubmit={handleSubmit}
                      organisationStatus={ftpData}
                      submitting={submitting}
                    />
                  </form>
                )}
              </Form>
            );
          }}
        </ReduxFetch>
      )}
    </>
  );
};

export default FTPAccounts;
