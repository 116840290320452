import { TextField, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import cxs from 'classnames';
import React, { HTMLAttributes, forwardRef } from 'react';
import { ControlProps } from 'react-select';
import { SelectOption } from '../../../packages/title/catalog/components/Search/types';

const useStyles = makeStyles((theme: Theme) => ({
  input: {
    display: 'flex',
    padding: 0,
    height: 'auto',
    background: theme.palette.background.default,
  },
}));

type InputComponentProps = HTMLAttributes<HTMLDivElement>;

const inputComponent = forwardRef<HTMLDivElement, InputComponentProps>((props, ref) => {
  return <div ref={ref} {...props} />;
});

export function Control(props: ControlProps<SelectOption, any>) {
  const classes = useStyles();
  return (
    <TextField
      fullWidth
      variant="outlined"
      //@ts-ignore
      error={props.selectProps.meta && props.selectProps.meta.submitError}
      disabled={props.isDisabled}
      InputProps={{
        inputComponent,
        inputProps: {
          className: cxs(
            classes.input
            // classes.inputField,
            // props.selectProps.margin === 'normal' && classes.inputMarginNormal
          ),
          ref: props.innerRef,
          children: props.children,
          ...(props.innerProps as any),
        },
      }}
      // @ts-ignore
      {...(props.selectProps.TextFieldProps as any)}
    />
  );
}
