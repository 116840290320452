import { LinkProps, ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import React from 'react';
import { useLocation } from 'react-router';
import { HashLink as Link } from 'react-router-hash-link';
import { SectionTitle } from '../domain';
import Score from './Score';

type Props = {
  score: number;
  title: string;
  hasErrors?: boolean;
  section: SectionTitle;
  activeSection: SectionTitle;
  onClick: (section: SectionTitle) => void;
};

const SectionNavItem = ({ title, score, hasErrors, section, activeSection, onClick }: Props) => {
  const { state } = useLocation();
  const active = section === activeSection;

  return (
    <MenuItem
      selected={active}
      component={React.forwardRef<HTMLAnchorElement, Partial<LinkProps>>((props, ref) => {
        return (
          // @ts-ignore
          <Link
            ref={ref}
            smooth
            to={{ hash: section, state }}
            {...props}
            onClick={() => {
              onClick(section);
            }}
          />
        );
      })}
    >
      <ListItemIcon style={{ minWidth: 40 }}>
        <Score score={score} hasErrors={hasErrors} />
      </ListItemIcon>
      <ListItemText>{title}</ListItemText>
    </MenuItem>
  );
};

export default React.memo(SectionNavItem);
