import SearchIcon from '@mui/icons-material/Search';
import React from 'react';

export function DropdownIndicator(props: any) {
  return (
    <div className={props.selectProps.classes.searchIcon}>
      <SearchIcon className={props.selectProps.classes.icon} />
    </div>
  );
}
