import React, { useEffect, useState } from 'react';
import ShowMoreButton from './ShowMoreButton';

type Props<TItem> = {
  initialLength: number;
  increaseBy?: number;
  renderListItem: (item: TItem, index: number) => React.ReactChild;
  items: TItem[];
};

const ExpandableList = <TItem extends any>({
  initialLength,
  increaseBy = 5,
  renderListItem,
  items,
}: Props<TItem>) => {
  const [itemsToShow, setItemsToShow] = useState<number>(initialLength);

  useEffect(() => {
    setItemsToShow(initialLength);
  }, [initialLength]);

  const handleShowMore = () => {
    setItemsToShow((currentValue) => currentValue + increaseBy);
  };

  const handleShowLess = () => {
    setItemsToShow((currentValue) => currentValue - increaseBy);
  };

  return (
    <>
      {items.slice(0, itemsToShow).map((item, index) => renderListItem(item, index))}

      {items.length > initialLength &&
        (itemsToShow >= items.length ? (
          <ShowMoreButton onClick={handleShowLess} invert />
        ) : (
          <ShowMoreButton onClick={handleShowMore} />
        ))}
    </>
  );
};

export default ExpandableList;
