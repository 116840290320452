import CatalogPage from './scenes/CatalogPage';
import Search from './containers/Search';

const catalog = {
  containers: {
    Search,
  },
  scenes: {
    CatalogPage,
  },
};

export default catalog;
