export type QueryObjectValue = string | number | string[] | number[] | boolean;
export type QueryObject = Record<string, QueryObjectValue>;

const trim = (v?: unknown) => (typeof v === 'string' ? v.trim() : v);

const stringify = (queryObj: QueryObject): string => {
  return Object.keys(queryObj)
    .reduce((agg: string[], key) => {
      const value = queryObj[key];
      // @ts-ignore
      const valueText = Array.isArray(value) ? value.map(trim).join(' OR ') : trim(value);
      if (!valueText) {
        return agg;
      }
      return [...agg, `${key}:(${valueText})`];
    }, [])
    .join(' || ');
};

const parse = (queryStr: string): QueryObject => {
  const parts = queryStr.split(' || ');

  return parts.reduce((agg, part) => {
    const [, key, value] = part.match(/(.*):\((.*)\)/) || [];
    return {
      ...agg,
      [key]: value.includes('OR') ? value.split(' OR ') : value,
    };
  }, {});
};

const elasticQueryDSL = {
  stringify,
  parse,
};

export default elasticQueryDSL;
