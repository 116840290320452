import { useFetch } from '@react-redux-fetch/hooks';
import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import RemoteAutocomplete from '../../../../../../components/Form/RemoteAutocomplete';
import useApiRoute from '../../../../../../config/api/useApiRoute';
import { updateQueryParameters } from '../../../../../../helpers/hateoas';
import { getTitleKeywordsRequest } from '../../api';
import { getTitleKeywords } from '../../selectors';

type Props = {
  label: string;
  name: string;
  disabled?: boolean;
};

export const KeywordsField: FC<Props> = ({ label, name, disabled }) => {
  const { t } = useTranslation();
  const apiRoute = useApiRoute('titleKeywords') || '';
  const [keywordFetch, fetchKeywords] = useFetch(getTitleKeywordsRequest);
  const keywords = useSelector(getTitleKeywords);

  const createUrl = useCallback(
    (term: string) => {
      return updateQueryParameters(apiRoute, { q: term });
    },
    [apiRoute]
  );

  if (!apiRoute) return null;

  return (
    <>
      <RemoteAutocomplete
        label={label}
        name={name}
        createUrlFromInput={createUrl}
        makeRequest={fetchKeywords}
        options={
          keywords?._embedded.items.map(({ keyword }) => ({ label: keyword, value: keyword })) || []
        }
        promiseState={keywordFetch}
        // getOptionValue={(keyword: TitleKeyword) => keyword.keyword}
        // getOptionLabel={(keyword: TitleKeyword) => keyword.keyword}
        isMulti
        isCreatable
        isDisabled={disabled}
        formatCreateLabel={(inputValue: string) => t('title_keywords_create_label', { inputValue })}
      />
    </>
  );
};
