import DetailPage from './scenes/DetailPage';
import TitleStatus from './components/TitleStatus';
import SmallFrontCover from './components/SmallFrontCover';
import PriceTagIcon from '../../../components/PriceTagIcon';

const titleDetail = {
  TitleStatus,
  PriceTagIcon,
  SmallFrontCover,
  scenes: {
    DetailPage,
  },
};

export default titleDetail;
