import React, { FC, useCallback } from 'react';
import ExpandableList from '../../../../../../components/ExpandableList';
import { Award } from '../../../../../../config/api/types';
import { FormValues } from '../../domain';
import EditAwardField from './EditAwardField';

type Props = {
  formAwards: Award[];
  onChange: (name: keyof FormValues, value?: any) => void;
};
const AwardsField: FC<Props> = ({ formAwards, onChange }) => {
  const removeAward = useCallback(
    (index: number) => {
      let copyAwards = [...formAwards];
      copyAwards.splice(index, 1);
      onChange('awards', copyAwards);
    },
    [formAwards, onChange]
  );

  return (
    <ExpandableList
      initialLength={formAwards.length}
      increaseBy={formAwards.length}
      renderListItem={(award, index) => {
        return (
          <EditAwardField
            onRemove={() => removeAward(index)}
            award={award}
            name={`awards[${index}]`}
            index={index}
            key={`awards[${index}]`}
          />
        );
      }}
      items={formAwards}
    />
  );
};

export default AwardsField;
