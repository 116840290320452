const baseAPIRoute = process.env.REACT_APP_API_PATH; // || 'http://api.meta-4-books.calidev.in:8080';

const apiRoutes = {
  routes: () => `${baseAPIRoute}/routes`,
  create: (url = '') => `${baseAPIRoute}/${url.replace(/^\//, '')}`.replace(/\/$/, ''),
  get: (url = '') => `${baseAPIRoute}/${url.replace(/^\//, '')}`.replace(/\/$/, ''),
  user: () => `${baseAPIRoute}/login`,
  login: () => `${baseAPIRoute}/login_check`,
  setPassword: (token: string) => `${baseAPIRoute}/password?token=${token}`,
  resetPassword: () => `${baseAPIRoute}/password-reset`,
  // TODO get from /routes 👇
  organisation: (id?: string) => `/organisations${id ? '/' + id : ''}`,
  organisations: () => `${baseAPIRoute}/organisations`,
  titleFiles: () => `${baseAPIRoute}/title_files`,
  titleFile: (id?: string) => `/title_files${id ? '/' + id : ''}`,
  processResults: (id?: string) => `/title_files${id ? '/' + id : ''}/title_import_results`,
  uploadFile: () => `${baseAPIRoute}/title_files`,
  roles: () => `${baseAPIRoute}/roles`,
  billingTypes: () => `${baseAPIRoute}/prefix_billing_types`,
};

export default apiRoutes;
