import { Pagination as MuiPagination, PaginationProps, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';

const buttonStyles = (theme: Theme) => ({
  '& .MuiPaginationItem-root': {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    color: theme.palette.text.primary,
    fontWeight: 500,
    '&.Mui-selected, &:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.getContrastText(theme.palette.primary.main),
    },
    '&.MuiPaginationItem-ellipsis': {
      backgroundColor: 'transparent',
      color: theme.palette.text.primary,
    },
  },
});

const useStyles = makeStyles((theme: Theme) => ({
  root: buttonStyles(theme),
}));

type Props = PaginationProps & {};

const Pagination = (props: Props) => {
  const classes = useStyles();

  return <MuiPagination {...props} classes={classes} shape="rounded" />;
};

export default Pagination;
