import { Box, Divider, Grid, Tooltip, Typography } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { RetourLine } from '../../../config/store/retour';
import getIdFromUrl from '../../../helpers/getIdFromUrl';
import organisation from '../../organisation';
import { IsbnLink } from '../../title';
import { DeliveryNoteIcon } from './DeliveryNoteIcon';

type Props = {
  lines?: RetourLine[];
};

const RetourDetailTable: FC<Props> = ({ lines }) => {
  const { t } = useTranslation();

  return (
    <>
      {lines?.map((line) => (
        <React.Fragment key={line.id}>
          <Grid container spacing={1}>
            <Grid item style={{ width: 120 }}>
              <Typography>
                <IsbnLink
                  orderLine={{
                    titleId: getIdFromUrl(line._embedded.title._links.self.href),
                    ...line,
                  }}
                />
              </Typography>
            </Grid>
            <Grid item style={{ flex: 1, overflow: 'hidden' }}>
              <Tooltip placement="top-start" title={line._embedded.title.title} enterDelay={500}>
                <Typography noWrap>{line._embedded.title.title}</Typography>
              </Tooltip>
            </Grid>
            <Grid item style={{ textAlign: 'center', width: 80 }}>
              <Typography>{line.quantity}</Typography>
            </Grid>
            <Grid item style={{ width: 200 }}>
              <organisation.components.OrganisationDialog
                organisationUrl={line._links.organisation?.href}
              >
                <Typography>
                  {line._embedded.organisation.name}{' '}
                  {line._embedded.distributor?.isCbMember ? t('cb') : ''}
                </Typography>
              </organisation.components.OrganisationDialog>
            </Grid>
            <Grid item style={{ width: 200 }}>
              <Tooltip placement="top-start" title={line.reference} enterDelay={500}>
                <Typography noWrap>{line.lineReference}</Typography>
              </Tooltip>
            </Grid>
            <Grid item style={{ width: 120 }}>
              <Typography>{line._embedded.retourReasons[line.reason]?.shortLabel}</Typography>
            </Grid>
            <Grid item style={{ width: 80, textAlign: 'center' }}>
              {line.comments && <DeliveryNoteIcon note={line.comments} />}
            </Grid>
          </Grid>
          <Box marginTop={1} marginBottom={1}>
            <Divider />
          </Box>
        </React.Fragment>
      ))}
    </>
  );
};

export default RetourDetailTable;
