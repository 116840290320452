import { Alert } from '@mui/lab';
import { Grid, Typography } from '@mui/material';
import { useFetch } from '@react-redux-fetch/hooks';
import React from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import Button from '../../../components/Button';
import Field from '../../../components/Form';
import useApiRoute from '../../../config/api/useApiRoute';
import { useAsyncValidation } from '../../../helpers/finalFormAsyncValidation';
import { requestNewPasswordRequest } from '../api';

type LocationState = {
  email?: string;
};

const ForgotPasswordForm = () => {
  const { t } = useTranslation();
  const { state } = useLocation<LocationState>();
  const apiPath = useApiRoute('passwordResetRequest');
  const [requestNewPasswordFetch, requestNewPassword] = useFetch(
    requestNewPasswordRequest(apiPath || '')
  );
  const { createSubmissionPromise } = useAsyncValidation(requestNewPasswordFetch);

  const disabled = requestNewPasswordFetch?.pending;

  return (
    <Grid container spacing={2} direction="column">
      <Grid item>
        <Typography variant="h2">{t('security_pw_forgotten')}</Typography>
      </Grid>
      <Grid item>
        <Typography>{t('security_pw_forgotten_text')}</Typography>
      </Grid>
      {requestNewPasswordFetch?.fulfilled && (
        <Grid item>
          <Alert severity="success">{t('security_pw_forgotten_success')}</Alert>
        </Grid>
      )}
      <Grid item>
        <Form
          onSubmit={(formValues) => {
            if (formValues.email) {
              requestNewPassword(formValues.email);
              return createSubmissionPromise();
            }
          }}
          initialValues={{ email: state?.email }}
        >
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Field
                name="email"
                type="text"
                label={t('security_email')}
                autoComplete="off"
                margin="normal"
              />
              <Button type="submit" disabled={disabled}>
                {t('security_pw_forgotten_submit')}
              </Button>
            </form>
          )}
        </Form>
      </Grid>
    </Grid>
  );
};

export default ForgotPasswordForm;
