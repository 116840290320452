import LoopIcon from '@mui/icons-material/Loop';
import { Theme } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Fab from '@mui/material/Fab';
import Typography from '@mui/material/Typography';
import { createStyles, withStyles, WithStyles } from '@mui/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import FormTitle from '../FormTitle';

type Props = WithStyles<typeof styles> & {
  handleSubmit: () => void;
  organisationStatus: { account: string; password: string };
  submitting: boolean;
};

const styles = (theme: Theme) =>
  createStyles({
    contentContainer: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    divider: {
      marginBottom: 16,
    },
    card: {
      marginRight: 24,
      flex: 1,
      minWidth: 300,
    },
    bold: {
      flexBasis: '150px',
      fontWeight: 600,
    },
    button: {
      fontSize: 12,
    },
    line: {
      marginBottom: '10px',
      display: 'flex',
    },
  });

const FtpAccount = ({ classes, handleSubmit, organisationStatus, submitting }: Props) => {
  const { t } = useTranslation();
  const organisationStatusPassword =
    (organisationStatus && organisationStatus.password) || 'XXXXXXXX';
  const organisationStatusAccount = organisationStatus && organisationStatus.account;

  return (
    <>
      <FormTitle title="FTP Account" />

      <div className={classes.contentContainer}>
        <Card className={classes.card}>
          <CardContent>
            <Typography variant="h5" gutterBottom>
              {t('page_subtitle_ftp')}
            </Typography>
            <div className={classes.line}>
              <Typography className={classes.bold}>{t('form_login')}:</Typography>
              <Typography>{organisationStatusAccount}</Typography>
            </div>
            <div className={classes.line}>
              <Typography className={classes.bold}>{t('form_pass')}:</Typography>
              <Typography>{organisationStatusPassword}</Typography>
            </div>
            <div className={classes.line}>
              <Typography className={classes.bold}>{t('form_address')}:</Typography>
              <Typography>{process.env.REACT_APP_FTP_PATH}</Typography>
            </div>
          </CardContent>
        </Card>
        <Fab
          variant="extended"
          onClick={handleSubmit}
          disabled={submitting}
          className={classes.button}
        >
          <LoopIcon /> {t('form_reset_account')}
        </Fab>
      </div>
    </>
  );
};

export default withStyles(styles)(FtpAccount);
