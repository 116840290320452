import React, { FC } from 'react';
import { QueryObjectValue } from '../../../../../helpers/elasticQueryDSL';
import { useThemas } from '../../../../thema';
import { ThemaBaseFacet } from './ThemaBaseFacet';

type Props = {
  value?: QueryObjectValue;
  onFilter: (themas: string[]) => void;
};

export const ThemaFacet: FC<Props> = ({ value, onFilter }) => {
  const { themas } = useThemas();

  if (!themas) return null;

  return <ThemaBaseFacet onFilter={onFilter} treeData={themas} value={value} />;
};
