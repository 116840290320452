import React from 'react';
import { RouteComponentProps } from 'react-router';
import ConstrainedPageContent from '../../../components/ConstrainedPageContent';
import OrderDetail from '../components/orderDetail/OrderDetail';

type Props = RouteComponentProps<{ id: string }, any, any>;

const OrdersProcessedPage = ({ match, history }: Props) => {
  return (
    <ConstrainedPageContent center={false}>
      <OrderDetail
        orderId={match.params.id}
        goBackPath={history.location.state && history.location.state.from}
      />
    </ConstrainedPageContent>
  );
};

export default OrdersProcessedPage;
