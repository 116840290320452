import { Grid, GridProps, Typography } from '@mui/material';
import React from 'react';

export const Row = (props: GridProps) => <Grid container {...props} />;
export const Label = ({ children, ...props }: GridProps) => (
  <Grid item style={{ width: 136 }} {...props}>
    <Typography gutterBottom>{children}</Typography>
  </Grid>
);
export const Value = ({ children, ...props }: GridProps) => (
  <Grid item style={{ flex: '1' }} {...props}>
    <Typography gutterBottom>{children}</Typography>
  </Grid>
);
