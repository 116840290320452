import React, { FC, useEffect, useState } from 'react';
import { getOrdersRequest } from '../../api';
import { Orders } from '../../../../config/api/models/shop';
import useApiRoute from '../../../../config/api/useApiRoute';
import { RouteResourceNames } from '../../../../config/api/types';
import { useFetch } from '@react-redux-fetch/hooks';
import { useSelector } from 'react-redux';
import { createGetPagedOrders } from '../../selectors';
import DelayedLoader from '../../../../components/DelayedLoader';
import { setQueryParameters } from '../../../../helpers/hateoas';

type Props = {
  component: React.FC<{
    pagedOrders: Orders;
    fetchOrders: (url: string) => void;
    isLoading: boolean;
  }>;
  apiRouteName: RouteResourceNames;
  apiRouteParams?: Record<string, string | number>;
};

const OrdersHistory: FC<Props> = ({ component, apiRouteName, apiRouteParams = {} }) => {
  const route = useApiRoute(apiRouteName);
  const routeWithOptions =
    route && apiRouteParams
      ? setQueryParameters(route, { order_dir: 'desc', ...apiRouteParams })
      : route;

  const [isRefetching, setIsRefetching] = useState(false);

  const [ordersFetch, fetchOrders, cancelFetch] = useFetch(getOrdersRequest);
  const getPagedOrders = useSelector(createGetPagedOrders);
  const pagedOrders = getPagedOrders(routeWithOptions || '');
  const prevUrl = pagedOrders?._links.self.href;
  const Component = component;

  const refetch = (url: string, baseUrl: string) => {
    setIsRefetching(true);
    cancelFetch();
    fetchOrders(url, baseUrl);
  };
  useEffect(() => {
    if (ordersFetch?.fulfilled && !ordersFetch?.pending) {
      setIsRefetching(false);
    }
  }, [ordersFetch]);

  useEffect(() => {
    if (routeWithOptions) {
      fetchOrders(prevUrl || routeWithOptions, routeWithOptions);
    }
  }, [routeWithOptions, fetchOrders, prevUrl]);

  return (
    <DelayedLoader data={pagedOrders}>
      {pagedOrders && (
        <Component
          pagedOrders={pagedOrders}
          fetchOrders={(url: string) => {
            if (url !== prevUrl) {
              refetch(url, routeWithOptions || '');
            }
          }}
          isLoading={isRefetching}
        />
      )}
    </DelayedLoader>
  );
};

export default OrdersHistory;
