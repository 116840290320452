import React, { useEffect } from 'react';
import { ApiConfig } from '../api';
import useApiRoute from '../../../../config/api/useApiRoute';
import { TitleFull } from '../../../../config/api/types';
import DelayedLoaderForFetch from '../../../../components/DelayedLoaderForFetch';
import Title from '../components/Title';
import { Redirect } from 'react-router';
import useFetch from '../../../../helpers/useFetch';

type Props = {
  id: string;
};

const Detail = ({ id }: Props) => {
  const apiRoute = useApiRoute('titles', id);
  const config = ApiConfig.getTitle(apiRoute || '');
  const [titleFetch, fetchTitle] = useFetch<TitleFull>(config);

  useEffect(() => {
    fetchTitle();
  }, [fetchTitle]);

  if (!apiRoute) {
    return <Redirect to="/" />;
  }

  if (!titleFetch) {
    return null;
  }

  return (
    <DelayedLoaderForFetch fetchObj={titleFetch}>
      {titleFetch.value ? <Title title={titleFetch.value} /> : null}
    </DelayedLoaderForFetch>
  );
};

export default Detail;
